import React from "react";
import { mainLoaderToggle } from "../../../modules/helpers"; 
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import KIPServices from "../../../Service/KIP_Pages";
import GLOBAL_CONFIG from "../../../config/config";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Aux from "../../../hoc/Auxiliare";

class Q4Confirmation extends React.Component {

    componentDidMount() {
        //this.LoadCampaign();
        mainLoaderToggle('hide');
    }

    render() {
        return(
            <div className="weltmeister-confirmation">
                {this.headingSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <Aux>
                <div className="confirmation-wrapper">
                    <div className="confirmation-text">
                        <h1>
                            Vielen Dank für deine Teilnahme!
                        </h1>
                        <p>Wir drücken dir die Daumen, dass du zu den weltmeisterlichen Gewinnern gehörst. Die Gewinnermittlung erfolgt unter allen Teilnehmern per Zufallsprinzip nach Ende des Aktionszeitraums am 03.01.2023. Die Gewinner werden im Anschluss innerhalb von 48 Stunden per E-Mail informiert.<br/>
                        Übrigens: Denk bitte daran, den Original-Kaufbeleg gut aufzubewahren.</p>
                        <p>Wir wünschen dir viel Glück!</p>
                    </div>
                </div>

                <div className="s-content-end">
                    <div className="end-element">
                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/weltmeister/teaser/box-1.png" />
                            <div className="end-element__copy">
                                    <h3>Mmmax Fußball Sonderedition</h3>
                                    <p>Die Bundesliga ist spannend wie eh und je - versüße dir die aufregenden Spiele mit den Mmmax Tafeln der Milka Fußball Sonderedition - nur für kurze Zeit erhältlich.</p>
                                
                                    <button className="btn__container btn--secondary-lilac"><a href="https://www.fcmilka.de/account" target="_blank"> JETZT PROBIEREn </a></button>
                                
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/weltmeister/teaser/box-2.png" />
                            <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                        Verpasse keine Neuigkeiten mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                    </p>
                                <a href="https://www.milka.de/shop" target="_blank">
                                    <button className="btn__container btn--secondary-lilac">NEUES ENTDECKEN</button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/weltmeister/teaser//box-3.png" />
                            <div className="end-element__copy">
                                    <h3>Weihnachten mit Milka</h3>
                                    <p>
                                    Auch in diesem Jahr gibt es wieder zarte Neuprodukte rund um Weihnachten – entdecke das vielfältige Sortiment
                                    </p>
                                <a href=" https://www.milka.de/neues" target="_blank">
                                    <button className="btn__container btn--secondary-lilac">WEIHNACHTSPRODUKTE</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footersocial /> */}
            </Aux>
        );
    }

    LoadCampaign() {
        KIPServices.Globus()
        .then( response => {
            var { status, success, message, placeholder } = response.data;

            if(success) {
                switch (status) {
                    case _STATUS_CAMPAIGN.open:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.globus
                        });
                        break;

                    default:
                        mainLoaderToggle('hide');
                        break;
                }
            }
        })
        .catch( () => {
            mainLoaderToggle('hide');
        })
        .finally( () => {
            _SCROLL_PAGE.ScrollToBanner()
        })
    }
}

export default Q4Confirmation;