import React from "react";
import { NavLink } from 'react-router-dom';
//import BuzzerSlider from "./Slider";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import ValidateForm from "../../../modules/validate";
import ParticipationService from "../../../Service/Participation";
import { withCookies } from 'react-cookie';
import Loader from "../../../components/Overlays/Loader";
import Checkbox from "../../../components/Controls/Checkbox";
import Aux from "../../../hoc/Auxiliare";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import KIPServices from "../../../Service/KIP_Pages";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import GLOBAL_CONFIG from "../../../config/config";

class HoldingPennyGondelkopf extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEmailAlredySent: false,
            simpleLoader: false
        }
        this.ProcessForm = this.ProcessForm.bind(this);


    }

    componentDidMount() {
        mainLoaderToggle('hide');
        const { cookies } = this.props;
        if (cookies.get("_isPress") == "true") {
            this.setState({ isEmailAlredySent: true });
        }
        this.scrollToTop();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    scrollToTop(){
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    render() {
        return (
            <div className="penny">
                {this.headingSection()}
                <Footersocial />
                {
                    this.state.simpleLoader ? <Loader /> : null
                }
            </div>
        );
    }

    headingSection() {
        return (
            <div className="content-holding__heading">
                <div className="content-home endpage-rewe">
                    <div className="spaceTile-intropage">
                        <div className="campaign-heading">
                            <h2>
                                Mitmachen & Match Attax Multipack erhalten
                            </h2>
                            <p>
                                Ab dem 05.09.2022 kannst du mit Milka und PENNY dein Match Attax Multipack erhalten. Lass dich einfach und bequem per Mail an unsere neue Aktion erinnern und schon sicherst du dir deine Chance.
                            </p>
                        </div>
                    </div>
                    <form id="frm_holding"
                            name="frm_holding"
                            method="post" action="/"
                            ref={form => this._FORM_DETAILS = form}
                            onSubmit={this.SubmitHandler.bind(this)} noValidate>
                        <div className={`remindMe-container ${ this.state.isEmailAlredySent ? "sent" : ""}`}>
                                <div className="form__container">
                                    <p>
                                        Du möchtest den Start dieser FC Milka Aktion auf keinen Fall verpassen? Kein Problem. Wir erinnern dich rechtzeitig per E-Mail!
                                    </p>
                                    <div id="email-container" className="input-container">
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="email">Email*</label>
                                            <input className="form-input__input js-event-type-in js-event-ab"
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="E-Mail-Adresse eingeben"
                                                data-require="true"
                                                data-type="regex"
                                                data-error-target="#error-email"
                                                data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                                data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                                data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                                data-event-fieldname="Email"
                                                data-event="form-click"
                                                data-category="Form Action"
                                                data-action="Email"
                                                data-label="Type In"
                                                ref={input => this._DETAILS_email = input}
                                            />
                                            <span id="error-email" className="form-input__error active"></span>
                                        </div>
                                    </div>
                                </div>
                            
                            <div className="img__container">
                                <picture>
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo.png" media="(min-width: 1024px)" />
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 481px)" />
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 200px)" />
                                    <img src="/resources/imagesBundesliga/buzzer/FCMilka-logo.png" alt="FC Milka Logo" />
                                </picture>
                            </div>
                            {
                                !this.state.isEmailAlredySent ?
                                    (
                                        <Aux>
                                            <Checkbox id="terms"
                                                required={true}
                                                errorMessage={""}
                                                text='Ich möchte den regelmäßigen Newsletter mit Informationen zu Milka Produkten, Rezepten und Aktionen per E-Mail von der Mondelez Deutschland Services GmbH & Co. KG erhalten. In diesem Zusammenhang wird auch mein Kauf- und Klickverhalten auf dieser Milka-Website analysiert. Diese Einwilligung kann jederzeit mit zukünftiger Wirkung hier widerrufen werden. Bitte beachten Sie auch unsere <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzerklärung</a> für weitere Informationen.'
                                                onChangeHandler={ !this.state.isEmailAlredySent ? this.CheckboxChangeHandler.bind(this) : ()=>{}}
                                            />
                                            <button type="submit" className={this.state.isEmailAlredySent ? "btn__container btn--lilac btn-desabled" : "btn__container btn--lilac"} disabled={this.state.isEmailAlredySent}> ERINNERE MICH </button>
                                        </Aux>
                                    )

                                : 
                                    (
                                        <p className="email-sent">Deine Mail Adresse wurde erfolgreich gespeichert. Wir erinnern dich, sobald die FC Milka Aktion beginnt.</p>
                                    )
                            }
                            
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    CheckboxChangeHandler(event, _ref) {
        if (_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if (_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    SubmitHandler(event) {
        event.preventDefault();
        if (this.state.isEmailAlredySent) {
            return null;
        }
        const isValid = ValidateForm.validateForm('frm_holding');

        if (isValid) {
            this.ProcessForm();
        } else {
            /**
             * Scroll To first error
             */
            let firstError = this._FORM_DETAILS.querySelector(".notvalid");
            if (!!firstError) {
                const yOffset = - 110,
                    y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }
    }

    ProcessForm() {
        const { cookies } = this.props;
        let _data = {
            email: this._DETAILS_email.value,
            retailer: "Penny"
        }
        this.setState({
            simpleLoader: true
        })
        ParticipationService.SendMailPennyMicroSite(_data)
            .then((response) => {
                let { success } = response.data;
                this.setState({
                    simpleLoader: false
                })
                console.log("response :", response);
                if (success) {
                    console.log("OK");
                    this.setState({ isEmailAlredySent: true });
                    const expireDate = new Date();
                    expireDate.setMonth(expireDate.getMonth() + 3);
                    cookies.set("_isPress", "true", { path: "/", expires: expireDate });
                } else {
                    console.log("KO");
                }
            })
            .catch(() => {
                this.setState({
                    simpleLoader: false
                })
                console.log("catch : KO");
            });
    }

    sliderSection() {
        return (
            // <div>
            //     <BuzzerSlider sliderName="Page.Holding.sliderBuzzer" />
            // </div>
            <div>
                <picture>
                    <source srcSet="/resources/imagesBundesliga/buzzer/kaufland/Slider-Prize-1-desktop.png" media="(min-width: 800px)" />
                    <source srcSet="/resources/imagesBundesliga/buzzer/kaufland/Slider-Prize-1-mobile.png" media="(min-width: 481px)" />
                    <source srcSet="/resources/imagesBundesliga/buzzer/kaufland/Slider-Prize-1-mobile.png" media="(min-width: 200px)" />
                    <img className="slider__image" src="/resources/imagesBundesliga/buzzer/kaufland/Slider-Prize-1-mobile.png" alt="Banner" />
                </picture>
            </div>
        );
    }

    footerSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <p>
                    *Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga / 2. Bundesliga. Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen, und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID-19-Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation. Maximal ein Hauptpreis pro Person.
                </p>

                <NavLink to="/" exact className="small-only no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                    <button className="btn__container btn--red"> MEHR ENTDECKEN </button>
                </NavLink>
            </div>
        )
    }

}

export default withCookies(HoldingPennyGondelkopf);