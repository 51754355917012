import React from 'react';
import FooterLogo from './FooterContent/FooterLogo';
import FooterProduct from './FooterContent/FooterProduct';
import FooterShop from './FooterContent/FooterShop';
import FooterNews from './FooterContent/FooterNews';
import FooterAbout from './FooterContent/FooterAbout';
import FooterLinks from './FooterContent/FooterLinks';
import SocialMedia from './FooterContent/SocialMedia';
import FooterSocial from './FooterContent/FooterSocial';
import FooterNewsletter from './FooterContent/FooterNewsletter';
import FooterCopyright from './FooterContent/FooterCopyright';
import FooterMilka from './FooterContent/FooterFCMilka';
import appGoogleTracking from '../../modules/googletracking';
import GLOBAL_CONFIG from '../../config/config';
import { NavLink } from 'react-router-dom';
import { isDoppeltPromotion, isReihePromotion, isTeaser, isTicketsPromotion, isq2Promotion , isHeimtrikotPromotion} from '../../modules/helpers';

const Footer = (props) => {

    function Tag(label) {
        if (isDoppeltPromotion() || isTicketsPromotion() || isq2Promotion() || isTeaser()  || isReihePromotion() || isHeimtrikotPromotion())  {
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'footer_menu',
                'dataCategory': 'Footer',
                'dataAction': 'Select Menu',
                'dataLabel': label
            });
        } else {
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'info-click',
                'dataCategory': 'Informational Action',
                'dataAction': 'Footer Link',
                'dataLabel': label
            });
        }

    }

    function fancenterLink () {
        const hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf('fcmilka.de') > -1 ? true : false,
        isLocal = hostName.toLowerCase().indexOf('localhost') > -1 ? true : false,
        isStage = hostName.toLowerCase().indexOf('fcmilka-de') > -1 ? true : false
        if (isReihePromotion()) {
            return 'https://fcmilka.de/fancenter'
        }
        if (isProd) {
            return GLOBAL_CONFIG.Route.fanCenter.main
        } else if (isLocal){
            return GLOBAL_CONFIG.Route.fanCenter.main
        } else if (isStage){
            return GLOBAL_CONFIG.Route.fanCenter.main
        }
        return 'https://fcmilka.de/fancenter'
    }
    function overviewLink () {
        const hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf('fcmilka.de') > -1 ? true : false,
        isLocal = hostName.toLowerCase().indexOf('localhost') > -1 ? true : false,
        isStage = hostName.toLowerCase().indexOf('fcmilka-de') > -1 ? true : false
        if(isReihePromotion()){
            return 'https://fcmilka.de/alle-aktionen'
        }
        if (isProd) {
            return GLOBAL_CONFIG.Route.intropage
        } else if (isLocal){
            return GLOBAL_CONFIG.Route.intropage
        } else if (isStage){
            return GLOBAL_CONFIG.Route.intropage
        }
        return 'https://fcmilka.de/alle-aktionen'
    }

    const oldFooter = (
        <footer className="footer__container">
            <div className="grid-row footer__head">
                <div className="footer__top js-scroll-top">
                    <img src="/resources/images/icons/Footer/Uplift_Button.svg" alt="To Top" />
                </div>
                <FooterLogo />
                <FooterProduct />
                <FooterNews />
                <FooterMilka />
                <FooterShop />
                <FooterAbout />
            </div>
            <div className="footer__external">
                <FooterLinks />
                <SocialMedia />
            </div>
            <div className="grid-row footer__foot">
                <picture>
                    <source srcSet="/resources/images/Pages/lila-curve-footer-DESK.png" media="(min-width: 1024px)" />
                    <source srcSet="/resources/images/Pages/milka-relaunch-2020-footer-mobile.png" media="(min-width: 481px)" />
                    <source srcSet="/resources/images/Pages/milka-relaunch-2020-footer-mobile.png" media="(max-width: 480px)" />
                    <img src="/resources/images/Pages/milka-relaunch-2020-footer-mobile.png" alt="Default ALT" height="100" width="100" />
                </picture>
                <div className="grid-row footer__foot-content">
                    <FooterNewsletter />
                    <FooterCopyright />
                </div>
            </div>
        </footer>
    );

    return (
        
        <footer className="footer__container">
            <div className="footer__top js-scroll-top" style={{ display: "none" }}>
                <img src="/resources/images/icons/Footer/Uplift_Button.svg" alt="To Top" />
            </div>
            <div className="footer__container--content">
                <div className="footer__container--links">
                    
                    <h4><a href='https://www.milka.de/alle-produkte' target='_blank' exact /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="product_page" */ onClick={() => Tag('PRODUKTE')}>PRODUKTE</a></h4>
                    <h4><a href={'https://fcmilka.de/account'} exact /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="account_page" */ onClick={() => Tag('FC MILKA ACCOUNT')}>FC Milka Account</a></h4>
                    {/* <h4><a href='https://www.milka.de/shop' target='_blank' exact onClick={() => Tag('SHOP')}>SHOP</a></h4> */}

                    <h4><a href='https://fcmilka.de/' exact /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="home_page" */ onClick={() => Tag('FC MILKA')}>FC MILKA</a></h4>

                    <h4><a href='https://www.milka.de/neues' target='_blank' exact /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="neues_page" */ onClick={() => Tag('NEUES')}>NEUES</a></h4>

                    <h4><a href='https://www.milka.de/uber-milka' target='_blank' exact /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="aboutmilka_page" */ onClick={() => Tag('ÜBER MILKA')}>ÜBER MILKA</a></h4>
                    <h4><a href={overviewLink()} exact /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="allactions_page" */ onClick={() => Tag('ALLE-AKTIONEN')}>ALLE-AKTIONEN</a></h4>
                    <h4><a href={fancenterLink()} exact /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="fancenter_page" */ onClick={() => Tag('FAN-CENTER')}>Fan-Center </a></h4>
                    <h4><a href='https://www.milka.de/newsletter' target='_blank' exact /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="newsletter_page" */ onClick={() => Tag('NEWSLETTER')}>NEWSLETTER</a></h4>
                    
                </div>
                <FooterLinks />
            </div>
            <FooterCopyright />
        </footer>
    );
};

export default Footer;