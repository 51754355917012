import React from "react";
import { NavLink } from 'react-router-dom';

import { mainLoaderToggle } from "../../../modules/helpers";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Aux from "../../../hoc/Auxiliare";
import ParticipationNavigation from "./ParticipationNavigation";
import Q4PopUpV1 from "../Weltmeister/Q4PopUpV1";
import WhiteSectionText from "../../../components/Content/Section/WhiteSectionText";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
class BuzzerPrizes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            gameStatus: {}
        }
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        _SCROLL_PAGE.ScrollToTop();
        const gameState = this.props.gameState;
        this.setState({ gameStatus: gameState });
    }

    componentWillMount() {
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    toggleModal() {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    }

    render() {
        return (
            <div id="buzzer-prizes" className="buzzer-game">
                {this.state.gameStatus.gameIsLive ? <WhiteSectionText
                    paragraph={'Der FC Milka sorgt für ein unvergessliches Erlebnis. Nutze deine Chance, Rudi Völler persönlich kennenzulernen. Zusätzlich kannst du dir ein original Heimtrikot der Bundesliga / 2. Bundesliga der Saison 2022/23 sichern.'}
                    textButton={'JETZT MITMACHEN'}
                    handleChild={this.toggleModal.bind(this)}
                    dataEvent={"buzzer_begin_participation"}
                    dataCategory={"Prize Page"}
                    dataAction={"Click on Start"}
                    dataLabel={"BUZZER_PRIZE_OTHER"}
                /> : null}
                {this.midSection()}

                <Q4PopUpV1 modal={this.state.modal} toggleModal={() => { this.toggleModal(); }} />
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <Aux>
                <div className="navigation-newsletter">
                    <a id="js-newsletter-optins" href="https://www.milka.de/newsletter?utm_source=von-milka-fuer-dich.milka.de&utm_medium=website&utm_campaign=timetogiveback" target="_blank" rel="noopener noreferrer" data-event="button-click" data-category="Click Action" data-action="Newsletter Optin" onClick={appGoogleTracking.processEventCTANavLink}>Newsletter abonnieren</a>
                    <img src="/resources/images/icons/newsletter-icon.png" width="48" height="48" alt="Newsletter icon" />
                </div>
                <div className="banner-edeka">
                    <img src="/resources/imagesBundesliga/buzzer/prize.png" />
                </div>
                <ParticipationNavigation />
                <div className="content-home endpage-rewe">
                    <TitleLogo classModifier="desktop" />
                    <div className="campaign-heading">
                        <h2>Mit dem FC Milka Star-Gewinnspiel tolle Fußball-Preise gewinnen!</h2>
                        <h3 className="recoleta-medium">
                            Deine Chance: ein VIP-Tag als Bundesliga Star und viele attraktive Tickets der Bundesliga / 2. Bundesliga warten auf dich.
                        </h3>
                    </div>
                </div>
            </Aux>
        );
    }

    midSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <div className="campaign-heading">
                    <h2>{this.state.gameStatus.gameIsLive ? "KRÖNE DEINE FUSSBALL-SAISON 2022/23 UND BUZZERE DICH ZUM GLÜCK" : "KRÖNE DEINE FUSSBALL-SAISON 2022/23"}</h2>
                </div>
                <div className="prizeRewe-card">
                    <div className="prizeRewe-card__primary">
                        <img src="/resources/imagesBundesliga/buzzer_23/prizes/Goldbarren.png" alt="Gold im Wert" />
                        <h3>ES GIBT NUR EINEN RUDI VÖLLER</h3>
                        <p>Unter allen Teilnehmenden wird die Fußball-Saison 2022/23 mit einem Manager-Tag mit Rudi Völler für 2 Personen gekrönt. Wirst du nach dem Aktionszeitraum ausgelost, triffst du mit deiner Begleitperson Fußball Legende Rudi Völler und erlebst die Legende hautnah. 
                            Dein exklusives Paket beinhaltet außerdem An- und Abreise, 
                            2x Übernachtung im 5-Sterne-Hotel, Verpflegung und attraktives Rahmenprogramm bei einem Bundesliga Club in Deutschland.</p>
                    </div>

                    <div className="prizeRewe-card__secondary">
                        <img src="/resources/imagesBundesliga/buzzer_23/prizes/Jerseys.png" alt="Dauerkarten der Saison" />
                        <h3 className="sub-prize">{this.state.gameStatus.gameIsLive ? "BUNDESLIGA BUZZER" : "1 VON 1.000 ORIGINAL HEIMTRIKOTS"}</h3>
                        <p>Nutze deine Chance auf 1 von 1.000 original Heimtrikots der Bundesliga / 2.Bundesliga der Saison 2022/23{this.state.gameStatus.gameIsLive ? ", wenn du das richtige Spieltag-Ergebnis buzzerst" : ""}.</p>
                    </div>
                </div>

                {/*                 <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                    <button className="btn__container btn--lilac"> JETZT MITMACHEN </button>
                </NavLink> */}
            </div>
        );
    }

    footerSection() {
        return (
            <div className="campaign-footer campaign-prize-footer">
                <p>
                    Die Gewinner werden schriftlich per E-Mail über den Gewinn benachrichtigt.
                    <br />
                    Übrigens: Während des Aktionszeitraums vom 11.04.2022 bis 05.06.2022 kannst du mehrfach teilnehmen. Pro Kaufbeleg eine Teilnahme möglich.
                </p>
                <br />
                <p>
                    *Kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga. Ausschluss oder Einschränkungen von Gewinnen aus Gründen der vorherrschenden Corona Lage vorbehalten. Es gelten zudem die jeweils aktuell gültigen Zutrittsregelungen der Clubs, über die sich der Gewinner informieren muss. Barauszahlung und anderweitige Kompensation sowie Rechtsweg ausgeschlossen.
                </p>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    gameState: state.game
});

export default withTranslation()(withRouter(connect(mapStateToProps, null)(BuzzerPrizes)));