import React, { Component } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';               //Routing component - to display the corresponding containers
import GLOBAL_CONFIG from '../../../config/config';		        //Global Settings that contains URL etc
import Aux from '../../../hoc/Auxiliare';                          //Vitual container 
import MetaHeader from '../../../components/Content/MetaHeader/MetaHeader';
import Header from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import Banner from '../../../components/Layout/Banner';  //End Campaign page content
import Error from '../../Error/Error';                         //Error page content  
//import Cookie from '../../Cookie/Cookie';                      //Cookie page content
import Cookie from '../../Campaign/Trikot/Cookie';

import FootballGame from '../../Game/FootballGame';
import Fairplay from '../../Fairplay/Fairplay';

import BuzzerIntroPage from '../../Campaign/Buzzer/Intro';
/* Ticket Promotion Q1 */


/* Trikot Promotion Q3 */
import TrikotHolding from '../../Campaign/Trikot/TrikotHolding';
import TrikotTerms from '../../Campaign/Trikot/TrikotTerms';


import FancenterTerms from '../../Campaign/Buzzer/FancenterTerms';
import _STATUS from '../../../Models/ParticipationSteps';

import KIPServices from '../../../Service/KIP_Pages';
import _SCROLL_PAGE from '../../../modules/ScrollPage';

import { mainLoaderToggle } from '../../../modules/helpers';
import TicketsRoutes from '../fancenter/TicketsRoutes';
import { connect } from "react-redux";
import { setIsBannerShown } from "../../../store/banner";

import getPageRouting from '../PagesRouting';
import Main from '../../Campaign/Sommer/Main';
import SommerParticipation from '../../Campaign/Sommer/SommerParticipation';
import ThankYou from '../../Campaign/Sommer/ThankYou';
import End from '../../Campaign/Sommer/End';
import Faq from '../../Campaign/Sommer/Faq';
import Q2Terms from '../../Campaign/Sommer/Q2Terms';
import ResetPassword from '../../ResetPassword/ResetPassword';
import PasswordChangeEmail from '../../PasswordChangeEmail/PasswordChangeEmail';


class Q2Layout extends Component {

    constructor() {
        super();

        this.state = {
            ParticipationStatus: _STATUS.noParticipation,
            FancenterStatus: _STATUS.Fancenter.noCodeEntry,
            ParticipationData: JSON.stringify({}),
            gameIsLive: false,
            campaignStatus: 'main'
        }

        this._LOCAL = {
            status: "__STAT",
            statusFan: "__STAT_FAN",
            data: "__DAT"
        }

        this.ParticipationUpdate = this.ParticipationUpdate.bind(this);
        this.SetParticipation = this.SetParticipation.bind(this);
        // this.loadSommerCampaign = this.loadSommerCampaign.bind(this);
    }

    componentWillMount() {
        this.SetParticipation();
        this.SetFancenter();
    }

    componentDidMount() {
        KIPServices.Sommer()
            .then(response => {
                var { success } = response.data;
                if (success) {
                    console.log("test", response.data);
                    this.setState({ gameIsLive: response.data.gameIsLive, campaignStatus: response.data.status });
                    //this.setState({ gameIsLive: true });
                }
            })
            .catch(() => {

            })
            .finally(() => {
                _SCROLL_PAGE.ScrollToTop();
                mainLoaderToggle('hide');
            })
        // }
    }

    render() {
        let RouteRender = null,
            NavContent = null,
            { history } = this.props;

        this.props.setIsBannerShown(true);

        this.pagesRouting = getPageRouting({ context: this, props: { isQ4: true, CampaignStatus: this.props.CampaignStatus } });

        if (this.props.campaign === true) {

            if (this.state.campaignStatus === 'main') {

                RouteRender = (
                    <Switch>
                        <Route path={GLOBAL_CONFIG.Route.sommer.main} exact component={() => <Main game={this.state.gameIsLive} />} />
                        <Route path={GLOBAL_CONFIG.Route.participation} exact component={() => <SommerParticipation />} />
                        <Route path={GLOBAL_CONFIG.Route.sommer.thankyou} exact component={() => <ThankYou />} />
                        <Route path={GLOBAL_CONFIG.Route.sommer.faq} exact component={() => <Faq />} />
                        <Route path={GLOBAL_CONFIG.Route.sommer.terms} exact component={() => <Q2Terms />} />
                        <Route path={GLOBAL_CONFIG.Route.sommer.resetPassword} exact component={() => <PasswordChangeEmail />} />
                        <Route path={GLOBAL_CONFIG.Route.sommer.cookie} exact component={Cookie} />
                    </Switch>
                );
            } else if (this.state.campaignStatus === 'holding') {
                RouteRender = (
                    <Switch>
                        <Route path={GLOBAL_CONFIG.Route.holding} exact component={TrikotHolding} />
                        <Route path={GLOBAL_CONFIG.Route.intropage} exact component={BuzzerIntroPage} />
                        <Route path={GLOBAL_CONFIG.Route.sommer.cookie} exact component={Cookie} />

                        {/*<Route path = {GLOBAL_CONFIG.Route.prize} exact component={Prize} />*/}
                        <Route path={GLOBAL_CONFIG.Route.footballGame} exact component={FootballGame} />
                        <Route path={GLOBAL_CONFIG.Route.fairplay} exact component={Fairplay} />
                        <Route path={GLOBAL_CONFIG.Route.terms} exact component={TrikotTerms} />

                        <Route path={GLOBAL_CONFIG.Route.fancenterTerms} exact component={FancenterTerms} />

                        {/* FANCENTER SCENARIO C */}
                        <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.baseName} component={() => (<TicketsRoutes UpdateFancenterStatus={this.FancenterStatusUpdate.bind(this)} />)} />
                        <Route component={Error} />
                    </Switch>
                );
            } else if (this.state.campaignStatus === 'end') {
                RouteRender = (
                    <Switch>
                        <Route path={GLOBAL_CONFIG.Route.end} exact component={End} />
                        <Route component={Error} />
                        <Route path={GLOBAL_CONFIG.Route.sommer.cookie} exact component={Cookie} />
                    </Switch>
                );

                NavContent = null;
            }
        }


        const ChangeTracker = () => {
            const { pathname } = useLocation();
            const pathnameLower = pathname.toLowerCase().replace(/[\\/\\\\]+$/, '');

            const whiteClassMap = new Map([
                ['/confirm', 'main-content main-content--white'],
                ['/cookies', 'main-content main-content--white'],
                ['/teilnahmebedingungen', 'main-content main-content--white'],
                ['/fancenter/teilnahmebedingungen', 'main-content main-content--white'],
                ['/Lieferadressen', 'main-content main-content--white'],
                ['/game', 'main-content main-content--white'],
                ['/passwortzuruecksetzen', 'main-content main-content--white'],
                ['/endrewe', 'main-content main-content--white'],
                ['/endkaufland', 'main-content main-content--white'],
                ['/endedeka', 'main-content main-content--white'],
                ['/dein-gewinn', 'main-content main-content--white'],
                ['/fancenter/confirm', 'main-content main-content--white'],
                ['/endnetto', 'main-content main-content--white'],
                ['/endpenny', 'main-content main-content--white'],
                ['/fairplay', 'main-content main-content--white fairplay'],
                ['/account', 'main-content main-content--white account-layout'],
                ['/register', 'main-content main-content--white register-layout'],
                ['/loginDetail', 'main-content main-content--white login-detail_layout'],
                ['/resetPassword', 'main-content main-content--white reset-password_layout'],
                ['/passwordChangeEmail', 'main-content main-content--white password-change-email_layout'],
                ['/AccountInformationen', 'main-content main-content--white account-info_layout'],
                ['/confirmationAccount', 'main-content account-confirmation_layout'],
                ['/no-product-code', 'main-content no-product-code_layout'],
                [GLOBAL_CONFIG.Route.sommer.cookie.toLowerCase(), 'main-content main-content--white'],
                [GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.main.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.codeEntry.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.teamSelection.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.jerseySizeSelection.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.jerseyUserDetails.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.ticketSelection.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.ticketOptions.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.confirmation.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.addressForm.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.fanCenter.confirmation.user.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.fanCenter.confirmation.companion.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.fanCenter.giftChoice.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.codeEntry.user.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.codeEntry.companion.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.userDetails.user.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.userDetails.companion.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase(), 'main-content main-content--white fancenter-layout main-fancenter'],
                [GLOBAL_CONFIG.Route.participation.toLowerCase(), 'main-content--secondary-lilac'],
                ['', 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.home.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.game.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.prize.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.faq.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.end.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.holding.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingkaufland.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingEdeka.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingPenny.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingnetto.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingGlobus.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.main.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.participation.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.confirmation.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.end.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.faq.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.product.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase(), 'main-content main-content--dark-lilac'],
            ]);

            const whiteClass = whiteClassMap.get(pathnameLower) || 'main-content';

            return (
                <main className={whiteClass + " sommer"} id="main" >
                    <Banner campaignStatus={this.props.campaignStatus} promotionCampaign={this.props.promotionCampaign} isq2Promotion={true} />
                    {NavContent}
                    {RouteRender}
                </main>
            );
        }

        const ChangeMeta = () => {
            return (
                <MetaHeader
                    title="Gewinne den Fußballsommer deines Lebens"
                    description="Milka krönt die letzten Spieltage der Saison der Bundesliga und 2. Bundesliga mit Preisen im Wert von 350.000 €. Sei dabei und erlebe unvergessliche Momente"
                    og_title="Gewinne den Fußballsommer deines Lebens"
                    og_description="Ein Aktionsprodukt von Milka und/oder TUC kaufen und mit etwas Glück attraktive Preise gewinnen"
                    og_sitename="www.sommer.fcmilka.de"
                />
            );
        }

        return (
            <Aux>
                <ChangeMeta campaignStatus={this.props.campaignStatus} />
                <Header campaignStatus={this.props.campaignStatus} />
                <ChangeTracker campaignStatus={this.props.campaignStatus} />
                <Footer campaignStatus={this.props.campaignStatus} />
            </Aux>
        );
    }

    ParticipationDataUpdate(data) {
        window.localStorage.setItem(this._LOCAL.data, JSON.stringify(data))

        this.setState({
            ParticipationData: JSON.stringify(data)
        })
    }

    SetParticipation() {
        let status = window.localStorage.getItem(this._LOCAL.status),
            data = window.localStorage.getItem(this._LOCAL.data);

        if (!!status) {
            this.setState({
                ParticipationStatus: status
            })
        }

        if (!!data) {
            this.setState({
                ParticipationData: data
            })
        }
    }

    SetFancenter() {
        let status = window.sessionStorage.getItem(this._LOCAL.statusFan);

        if (!!status) {
            this.setState({
                FancenterStatus: status
            })
        }
    }

    ParticipationUpdate(status) {
        window.localStorage.setItem(this._LOCAL.status, status)

        this.setState({
            ParticipationStatus: status
        })
    }

    FancenterStatusUpdate(status) {
        window.sessionStorage.setItem(this._LOCAL.statusFan, status)

        if (status === _STATUS.Fancenter.noCodeEntry) {
            window.sessionStorage.clear();
        }

        this.setState({
            FancenterStatus: status
        })
    }
}

const mapDispatchToProps = {
    setIsBannerShown
}

export default withRouter(connect(null, mapDispatchToProps)(Q2Layout));