import React, { useEffect, useState } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import "./Carousel.scss";

const images = [
  { src: '/resources/imagesBundesliga/reihe/kits-carousel/image1.png', alt: 'Slide 1' },
  { src: '/resources/imagesBundesliga/reihe/kits-carousel/image2.png', alt: 'Slide 2' },
  { src: '/resources/imagesBundesliga/reihe/kits-carousel/image3.png', alt: 'Slide 3' },
  { src: '/resources/imagesBundesliga/reihe/kits-carousel/image4.png', alt: 'Slide 4' },
  { src: '/resources/imagesBundesliga/reihe/kits-carousel/image5.png', alt: 'Slide 5' },
  { src: '/resources/imagesBundesliga/reihe/kits-carousel/image6.png', alt: 'Slide 6' },
  { src: '/resources/imagesBundesliga/reihe/kits-carousel/image7.png', alt: 'Slide 7' },
  { src: '/resources/imagesBundesliga/reihe/kits-carousel/image8.png', alt: 'Slide 8' },
];

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const elems = document.querySelectorAll('.carousel');
    const instances = M.Carousel.init(elems, {
      fullWidth: false,
      indicators: false,
      onCycleTo: (currentItem, dragged) => {
        const currentIndex = Array.from(currentItem.parentNode.children).indexOf(currentItem);
        setActiveIndex(currentIndex);
      },
    });

    const interval = setInterval(() => {
      instances.forEach(instance => instance.next());
    }, 5000); // Change slide every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getClassName = (index) => {
    if (index === activeIndex) return 'carousel-item active';
    if (index === (activeIndex + 1) % images.length) return 'carousel-item active-right';
    if (index === (activeIndex - 1 + images.length) % images.length) return 'carousel-item active-left';
    return 'carousel-item';
  };

  return (
    <div className="heimtrikot">
        <div className="profile">
      <div className="background">
        <img src="/resources/imagesBundesliga/reihe/kits-carousel/background1.png" alt="Background" />
      </div>

      <div className="carousel">
        {images.map((image, index) => (
          <div key={index} className={getClassName(index)}>
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>
    </div>
    </div>
  
  );
};

export default Carousel;
