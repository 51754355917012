import React from "react";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";

import StepContent from "../../../components/Content/StepContent/StepContent";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import TicketsPromoPopUp from "./TicketsPromoPopUp";
import { isTicketsPromotion } from "../../../modules/helpers";
import _LOCAL from "../../../Models/LocalStorageKeys";
class TicketsPromoMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      modal: false,
      baseNamePath: null,
    };
    this.resize = this.resize.bind(this);
    this.isTicketsPromotion = isTicketsPromotion();
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  resize() {
    // set isMobile to true if the device is a mobile and false otherwise
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    window.addEventListener("resize", this.resize);
    this.resize();
    //_SCROLL_PAGE.ScrollToTop();
    this.productCodeLink();
    let baseNamePath = "";
    if (this.isTicketsPromotion) {
      baseNamePath = "/tickets";
    } else {
      baseNamePath = "";
    }

    this.setState({
      baseNamePath: baseNamePath,
    });
  }

  componentWillMount() {
    //_SCROLL_PAGE.ScrollToTop();
    /*         localStorage.removeItem(_LOCAL.PlayerValue);
        localStorage.removeItem(_LOCAL.ProductCode); */
    localStorage.clear();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const kipGlobus = urlParams.get("kip");
    const poupOpen = urlParams.get("status");

    if (kipGlobus) {
      localStorage.setItem(_LOCAL.CurrentKip, "Globus");
    }

    if (poupOpen) {
      this.setState({
        modal: true,
      });
    }
    console.log("poupOpen:", poupOpen)
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    return (
      <div id="main-buzzer" className="weltmeister-main tickets-main">
        {this.headingSection(this.props)}
        {this.footerSection()}
        <Footersocial />
      </div>
    );
  }

  productCodeLink() {
    const product_code_link = document.getElementById("product-code-link");
    product_code_link.addEventListener("click", () => {
      this.setState({ modal: true });
    });
  }

  modalTracking(event) {
    this.setState({ modal: true });
    appGoogleTracking.processEventCTANavLink(event);
  }

  headingSection(props) {
    return (
      <div>
        <div className="content-home">
          <div className="spaceTile-intropage">
            <div className="campaign-heading">
              <p>
              Gib jetzt deinen Milka Produktcode ein und schon bist du im Lostopf! Freue dich auf 1.000 x 2 Tickets für Spiele der Bundesliga und 2. Bundesliga sowie auf ein exklusives Bundesliga VIP-Erlebnis mit Lothar Matthäus für 2 Personen.
              </p>
            </div>
            <div id="participate"></div>
            <h2 className="sub-heading">so einfach geht’s:</h2>
            {/* <StepContent
              step1={props.t("Page.Home.Steps.Buzzer-Main-Tickets.1")}
              step1sm={props.t("Page.Home.Steps.Buzzer-Main-Tickets.1")}
              step1Text={parse(
                'Kaufe zwischen dem <span class="text--wheat">09.01.2023</span> und dem <span class="text--wheat">19.03.2023</span> ein Aktionsprodukt von Milka und/oder Wunderbar.'
              )}
              step2={props.t("Page.Home.Steps.Buzzer-Main-Weltmeister.2")}
              step2sm={props.t("Page.Home.Steps.Buzzer-Main-Weltmeister.2")}
              step2Text={parse(
                `Gib <a id="product-code-link" style="text-decoration:undeline;">hier</a> bis zum 02.04.2023 den <span class="text--wheat"> Produktcode </span>von der Produktverpackung ein*`
              )}
              step3={props.t("Page.Home.Steps.Buzzer-Main-Tickets.3")}
              step3sm={props.t("Page.Home.Steps.Buzzer-Main-Tickets.3")}
              step3Text={parse(
                `Fülle das Teilnahmeformular aus, um großartige <span class="text--wheat">Ticket-Preise</span> zu <span class="text--wheat">gewinnen</span>.`
              )}
              step4={props.t("Page.Home.Steps.Buzzer-Main-Weltmeister.4")}
              step4sm={props.t("Page.Home.Steps.Buzzer-Main-Weltmeister.4")}
              step4Text={parse(
                `Die <span class="text--wheat">Gewinnermittlung</span> der <span class="text--wheat">Ticketgewinne</span> erfolgt per Zufallsprinzip unter allen Teilnehmern innerhalb des Gewinnspielzeitraums. Die <span class="text--wheat">Gewinnermittlung</span> der <span class="text--wheat">5 FC Milka Meister Tickets</span> am Aktionsende unter allen Teilnehmern.`
              )}
              modifier="step-weltmeister step-tickets-promo"
            /> */}

            <div class="step-container">      
              <div class="step-content"> 
                
                              
                <div class="step-item">
                  <h2>so einfach geht’s:</h2>
                  <img src="/resources/imagesBundesliga/doppeltgewinnen/steps/1.png" alt="Milka product" />

                  <div class="step__text">                  
                    <p>
                    Kaufe zwischen dem <strong>02.01.2024</strong> und dem <strong>31.03.2024</strong> ein Milka Produkt.
                    </p>  
                  </div>
                                
                </div>
                <div class="step-item"> 
                  <img className="step_image step_image--website" src="/resources/imagesBundesliga/doppeltgewinnen/steps/2.png" alt="Milka product" />
                  
                  <div class="step__text">                  
                    <p>
                      Gib <span id="product-code-link"  
                        data-event="begin_participation_link"
                        data-category="Start Page"
                        data-action="Click on Link"
                        data-label="GAME_SP_OTHER" 
                        onClick={(e) => {this.modalTracking(e)}}
                        style={{ textDecoration: 'underline' }}>hier
                      </span> bis zum <strong>31.03.2024</strong> den Produktcode von der Produktverpackung ein*.           
                    </p>  
                  </div>
                               
                </div>
                <div class="step-item">
                  <img className="step_image step_image--ball" src="/resources/imagesBundesliga/doppeltgewinnen/steps/3.png" alt="Milka product" />
                  <div class="step__text">
                    <p> 
                      Mitmachen und mit etwas Glück gewinnen.
                    </p>    
                  </div>          
                </div>
              </div>
            </div>

{/*             <p className="note-promo-code">
              *Hinweis: Befindet sich kein Produktcode auf dem Artikel, müssen
              die Zahlen des Barcodes eingegeben werden. Mehrfachteilnahmen mit
              neuem Produktcode innerhalb der Aktionsprodukte erlaubt. Bitte Original-Kaufbeleg als Nachweis aufbewahren.
            </p> */}

            <div className="middle-button-wrapper">            
              <div className="cta-container middle">
                <button
                  className="btn__container btn--secondary-lilac"
                  data-event="begin_participation_middle"
                  data-category="Start Page"
                  data-action="Click on Join Now"
                  data-label="GAME_SP_OTHER"
                  onClick={(e) => {
                    this.modalTracking(e)
                  }}
                >
                  JETZT MITMACHEN{" "}
                </button>
              </div>
              <TicketsPromoPopUp
                modal={this.state.modal}
                toggleModal={() => {
                  this.toggleModal();
                }}
              />

              
                <p id="edeka-terms">
                  Hier findest du die{" "}
                  <a
                    href={`${GLOBAL_CONFIG.Route.terms}`}
                    target="_blank"
                    style={{ color: "white" }}
                    onClick={appGoogleTracking.processEventCTA}
                    data-event="click_conditions"
                    data-category="Start Page"
                    data-action="Click on Conditions of Participation"
                    data-label="ENG_SP_OTHER"
                  >
                    {" "}
                    Teilnahmebedingungen
                  </a>{" "}
                  und{" "}
                  <a
                    href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/"
                    target="_blank"
                    style={{ color: "white" }}
                    onClick={appGoogleTracking.processEventCTA}
                    data-event="click_privacy_policy"
                    data-category="Start Page"
                    data-action="Click on Data Protection Regulations"
                    data-label="ENG_SP_OTHER"
                  >
                    {" "}
                    Datenschutzbestimmungen
                  </a>
                  .
                </p>
              
            </div>
            
          </div>
        </div>
      </div>
    );
  }

  footerSection() {
    return (
      <div className="ticket-promo-container">
        <h3>die bundesliga hautnah erleben</h3>
        {/* <div className="ticket-promo-container-wrapper">
          <div className="ticket-promo campaign-footer">
            <div className="ticket-promo-card">
              <div className="ticket-promo__content primary">
                <img
                  src="/resources/imagesBundesliga/tickets-promo/card/img-tickets.png"
                  alt="Gold im Wert"
                />
                <p>
                  Unter allen Teilnehmern verlosen wir 700 x 2 Cat2-Tickets-
                  Saison 2022/23 der Bundesliga / 2. Bundesliga für ein Spiel
                  des Clubs der Wahl, 100 x 2 VIP-Tickets - Saison 2022/23 der
                  Bundesliga / 2. Bundesliga für ein Spiel des Clubs der Wahl,
                  120 x Dauerkarten für die Saison 2023/24 sowie 5 x 2
                  VIP-Tickets für den Supercup inkl. Anreise und Übernachtung.
                </p>
              </div>
            </div>
          </div>
          <div className="ticket-promo campaign-footer">
            <div className="ticket-promo-card">
              <div className="ticket-promo__content primary">
                <img
                  src="/resources/imagesBundesliga/tickets-promo/card/img-prize.png"
                  alt="Gold im Wert"
                />
                <p>
                  Dazu verlosen wir 5 FC Milka Meister Tickets am Aktionsende
                  unter allen Teilnehmern. Gewinne pro Meister Ticket 2x
                  VIP-Tickets (Saison 2022/23 für ein Spiel der Bundesliga oder
                  2. Bundesliga), 2x VIP-Tickets für den Supercup inkl.
                  Übernachtung und Anreise für 2 Personen und 2 Dauerkarten
                  (Cat2) für die Saison 2023/24. Erlebe deine Fußballhelden
                  hautnah in Aktion.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="ticket-teaser-wrapper">
          <div className="ticket-teaser_container primary">
            <div className="ticket-teaser-card">
              <div className="ticket-teaser primary">
                <img
                  src="/resources/imagesBundesliga/tickets-promo/bundeliga-tickets.png"
                  alt="Gold im Wert"
                />
                <h1>
                Exklusives Bundesliga VIP-Erlebnis
                </h1>
                <p>
                Als Hauptgewinn verlosen wir unter allen Teilnehmern ein exklusives Bundesliga
VIP-Erlebnis mit Lothar Matthäus für dich und deine Begleitung. Die An- und Abreise, eine Übernachtung, die Verpflegung sowie ein attraktives Rahmenprogramm sind inklusive.
                </p>
              </div>
            </div>
          </div>
          <div className="ticket-teaser_container">
            <div className="ticket-teaser-card">
              <div className="ticket-teaser secondary">
                <img
                  src="/resources/imagesBundesliga/tickets-promo/vip-tickets.png"
                  alt="Gold im Wert"
                />
                <h1>
                  Bundesliga Tickets
                </h1>
                <p>
                Stadionluft atmest du mit 1x 2 Cat2-Tickets für Spiele der Bundesliga und 2. Bundesliga Lass dich von der packenden Atmosphäre mitreißen und feuere deinen Lieblingsclub live an. Wir verlosen insgesamt 1.000 x 2 Cat2-Tickets. (Werte variieren je nach Club, Auswahl nach Verfügbarkeit über Milka Fan Center)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="cta-container bottom">
          <button
            className="btn__container btn--secondary-lilac"
            data-event="begin_participation_bottom"
            data-category="Start Page"
            data-action="Click on Join Now"
            data-label="GAME_SP_OTHER"
            onClick={(e) => {
              this.modalTracking(e)
            }}
          >
            JETZT MITMACHEN
          </button>
        </div>

        <p className="legal-text">
          Abbildungen unverbindlich
        </p>
      </div>
    );
  }
}

export default withTranslation()(TicketsPromoMain);
