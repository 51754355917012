import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import { mainLoaderToggle } from '../../modules/helpers';


class MenuBarAccount extends Component {
    componentDidMount(){
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');

         //Virtual page
        
    }

    componentWillUnmount(){
        //Show mainloader 
        mainLoaderToggle('show');
    }

    render() {
        let menu;
        const Changemenu = ()=>{
            const location = useLocation();
            let pathname =location.pathname;
            if(pathname=="/loginDetail"){
                pathname="/mein account";
            }
            else if(pathname=="/resetPassword"){
                pathname="Login details"
            }
            let strPathname = pathname.split('/').join(' / ');
        return(
            <div className="menuAccount">
                start <span>{strPathname}</span>
            </div>
        ) 
        }
        return(
            <Changemenu/>
        )
       
    }
}
export default MenuBarAccount;