export default class Match {
    constructor(options) {
        this.home = options.home;
        this.away = options.away;
        this.date = options.date;
        this.id = options.id;
        this.outStock = options.outStock;
        this.matchDay = `${options.matchDay}`;
        this.location = `${options.location}`;
        this.weekendDate = options.weekendDate;
        this.gameweek = options.gameweek;
        this.isSingleTicket = options.isSingleTicket;
    }
}