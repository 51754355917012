import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { mainLoaderToggle } from '../../../../modules/helpers';
import TitleLogo from '../../../../components/Content/TitleLogo/TitleLogo';
import Footersocial from '../../../../components/Layout/FooterContent/FooterSocial';
import GLOBAL_CONFIG from '../../../../config/config';
import JerseyNavigation from '../JerseyNavigation';
import _TICKET from '../../../../Models/TicketImages';
import _LOCAL from '../../../../Models/LocalStorageKeys';
import _SCROLL_PAGE from '../../../../modules/ScrollPage';
import _STATUS from '../../../../Models/ParticipationSteps';
import _USER_TYPES from '../../../../Models/UserTypes';
import _TICKET_TYPES from '../../../../Models/TicketTypes';
import { withRouter } from 'react-router-dom';

class UserConfirmation extends Component {
    constructor() {
        super();

        this.state = {
            CovidInfo: false,
            WinnerName: "Winner Name",
            MatchDate: "Sa. 01.01.2021",
            MatchLocation: "Match Location",
            HomeTeam: "Home Team",
            AwayTeam: "Away Team",
            UserType: "",
            Gift: _TICKET.STD,
            GiftCategory: localStorage.getItem(_LOCAL.GiftCategory)
        }
    }
    componentDidMount() {
         if(localStorage.getItem(_LOCAL.UserDetails) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user)
        }
        let UserDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));

        if(!!UserDetails) {
            this.setState({
                UserType: UserDetails.UserType,
                Gift: _TICKET[localStorage.getItem(_LOCAL.GiftCategory)] || _TICKET.STD
            })

            if(!!UserDetails.Match) {
                this.setState({
                    MatchDate: UserDetails.Match.date,
                    MatchLocation: UserDetails.Match.location,
                    HomeTeam: UserDetails.Match.home,
                    AwayTeam: UserDetails.Match.away,
                    WinnerName: UserDetails.Name,
                });
            }
        }

        _SCROLL_PAGE.ScrollToBanner();
        mainLoaderToggle('hide');
    }


    componentWillUnmount() {
        localStorage.clear()
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-jersey lottery" id="confirmation">
                { this.HeadingSection() }
                { this.FooterSection() }

                {/* <div id="Covid-Details">
                    {
                        this.state.CovidInfo ? 
                        <div className="back--red">
                            <p>
                                Auf Grund der aktuellen COVID-19 Situation erhalten wir die Tickets sehr kurzfristig vor dem Spieltag von den Clubs.
                                <br />
                                Das Milka Team hat keinen Einfluss auf die Ticketausstellung, das wird seitens der einzelnen Clubs geregelt. Es ist uns darum nicht möglich, nähere Angaben zum genauen Versandzeitpunkt zu machen.
                                Bitte berücksichtige auch, dass einige Clubs insbesondere bei steigenden Inzidenzzahlen überlegen, den Zugang zum Stadion nur vollständig Geimpften oder genesenen Zuschauern zu gestatten. Diese Entscheidung und Verantwortung obliegt ausschließlich den jeweiligen Clubs.
                                Wir bitten um deine Geduld und dein Verständnis. Bitte informiere dich vor dem Stadionbesuch bei den entsprechenden Stellen.
                            </p>
                        </div> : null
                    }
                </div> */}

                <Footersocial />
            </div>
        )
    }

    HeadingSection() {
        const Gift = this.state.Gift ? this.state.Gift : _TICKET.CAT2;
        const desktopImage = typeof Gift === 'string' ? Gift : Gift.desktop ;
        const mobileImage = typeof Gift === 'string' ? Gift : Gift.mobile;
        let ticketImage = {
            desktop : desktopImage,
            mobile : mobileImage,
        };

        return (
            <div className="spaceTile-intropage">
                <div className="campaign-heading">
                    <h2>
                        Glückwunsch! Jetzt kannst du dich auf deinen FC Milka Gewinn freuen – viel Spaß damit.
                    </h2>
                    <h4>
                        Dein ausgewähltes Ticket wird dir in Kürze zugeschickt. 
                    </h4>
{/*                     <picture>
                        <source srcSet={ticketImage.desktop} media="(min-width: 1024px)" />
                        <source srcSet={ticketImage.desktop} media="(min-width: 481px)" />
                        <source srcSet={ticketImage.mobile} media="(min-width: 200px)" />
                        <img id="ticket-img" src={ticketImage.desktop} alt="Ticket"/>
                    </picture> */}
                </div>
            </div>
        );
    }

    FooterSection() {
        return (
            <div className="s-content-end">
                <div className="end-element">
                    <div className="end-element__item">
                        <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                        <div className="end-element__copy">
                                <h3>FC Milka Mitglied werden</h3>
                                <p>Jetzt kostenlos registrieren und viele Vorteile genießen. Sei dabei und melde dich an.</p>
                            <a href="https://fcmilka.de/account" target="_blank">
                                <button className="btn__container btn--secondary-lilac"> REGISTRIEREN </button>
                            </a>
                        </div>
                    </div>


                    <div className="end-element__item">
                        <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                        <div className="end-element__copy">
                                <h3>Nervennahrung nötig?</h3>
                                <p>
                                    Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                                </p>
                            <a href="https://www.milka.de/shop" target="_blank">
                                <button className="btn__container btn--secondary-lilac"> SCHOKI SHOPPEN </button>
                            </a>
                        </div>
                    </div>


                    <div className="end-element__item">
                        <img src="/resources/imagesBundesliga/buzzer/end/fancenter-box-3.png" />
                        <div className="end-element__copy">
                                <h3>Neues von Milka</h3>
                                <p>
                                    Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                </p>
                            <a href="https://www.milka.de/neues" target="_blank">
                                <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    ScrollCovidInfo(e) {
        this.setState({
            CovidInfo: true
        })

        _SCROLL_PAGE.ScrollSection(document.getElementById("Covid-Details"))
    } 

}

export default withRouter(UserConfirmation);