import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

class DropdownClub extends React.Component {
    constructor(props) {
        super(props);
    
        this.imagePath = "/resources/imagesBundesliga/team-picture";
        this.state = {
            isTeamDefaultSet : false,
            TeamDefault : "team-default",
            TeamPictureSource: this.imagePath+"/team-default.png"
        }

        this.setTeamDefault = this.setTeamDefault.bind(this);
    }
    
    setTeamDefault(defaultImg){
        this.setState({
            isTeamDefaultSet : true,
            TeamDefault : defaultImg,
            TeamPictureSource : this.imagePath+"/"+defaultImg+".png"
        })
    }

    render() {
        const {title, divClass, id, team, noErrorMsg, defaultValue, content, PreFill, noImage, required, defaultImg} = this.props;
        let requiredValue =  required;
        if(requiredValue == null){
            requiredValue = true;
        }
        var imageSource = this.state.TeamPictureSource;

        if(!!defaultImg && defaultImg.length > 0 && !!this._SELECT && !this.state.isTeamDefaultSet) {
            this._SELECT.value = "default";
            imageSource = `${this.defaultImg}/${defaultImg}.png`;
            this.setTeamDefault(defaultImg);
        }

        if(!!PreFill && PreFill.length > 0 && !!this._SELECT) {
            this._SELECT.value = PreFill;

            imageSource = `${this.imagePath}/${PreFill}.png`;
        }
        return(
            <div className="space-bottom">
                <p className='hint'>*Pflichtfelder</p>
                <p>{title}</p>
                {
                    noImage ?
                    null :
                    <div id="teamPicture" className={this.props.class}>
                        <img src={imageSource} alt="Team Picture" />
                    </div>
                }
                <div className={divClass}>
                    <select ref={select => this._SELECT = select} className="form-input__input js-event-type-in js-event-ab" id={id}
                        onChange={this.TeamChange.bind(this)} type="text"
                        name="team" placeholder="Sv Werder Bremen"
                        data-require={requiredValue} data-type="ddl"
                        data-error-target="#error-team" data-event-fieldname={team} data-event="form-click" data-required-message={noErrorMsg ? "" : "Bitte wähle deinen Lieblingsverein."} data-category="Form Action" data-action="Product Retailer" data-label="Type In">
                        {defaultValue ? <option value={defaultValue}>{defaultValue}</option> : <option value="default">Lieblingsclub.</option> }
                        
                        {content}
                    </select>
                    <label className="form-input__label" htmlFor="product">Arende*</label>
                    <span id="error-team" className="form-input__error active"></span>
                </div>
            </div>
        );
    }

    TeamChange(event) {
        var value = event.target.value,
            imgPath = `${this.imagePath}/${value}.png`;

        let { setFavouriteTeam } = this.props;

        if (typeof setFavouriteTeam === "function") {
            if (value == "default") {
                setFavouriteTeam(null);

                imgPath = `${this.imagePath}/${this.state.TeamDefault}.png`;

                this.setState({
                    TeamPictureSource: imgPath
                })
            } else {
                setFavouriteTeam(value);
                
                this.setState({
                    TeamPictureSource: imgPath
                })
            }
        }
    }
}
export default DropdownClub;