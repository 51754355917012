//Milka Christmas POS
var hostName = window.location.hostname,
    isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false,
    fanCenterBase = isProd ? "/dein-gewinn" : "/fancenter";
const GLOBAL_CONFIG = {
    /*
        URL for webservices
    */
    ServiceSettings: {
        //Webservices
        stagingURL: 'https://milka-bundesliga-content-qa.azurewebsites.net',
        stagingFancenterURL: 'https://milka-bundesliga-content-stage.azurewebsites.net',
        LocalstagingURL: 'https://localhost:3000',
        prodURL: 'https://fcmilka.de',
        prodURL90min: 'https://90min.fcmilka.de',
        prodURLWeltmeister: 'https://weltmeister.fcmilka.de',
        prodURLTickets: 'https://tickets.fcmilka.de',
        prodURLBuzzer: 'https://buzzer.fcmilka.de',
        prodURLGlobus: 'https://einlaufkinder.fcmilka.de',
        prodURLTrikot: 'https://trikot.fcmilka.de',
        prodURLDoppelt: 'https://doppeltgewinnen.fcmilka.de',
        prodServiceURLGlobus: 'https://api.fcmilka.de/',
        prodURLWithWWW: 'https://www.fcmilka.de',
        prodURLWithWWWBuzzer: 'https://www.buzzer.fcmilka.de',
        prodURLWithWWWTrikot: 'https://www.trikot.fcmilka.de',
        prodURLWithWWWDoppelt: 'https://www.doppeltgewinnen.fcmilka.de',
        prodURLWithWWWTickets: 'https://www.tickets.fcmilka.de',
        hiddenProdURL: 'https://proximitybbdo-fcmilka-de-v25ef3pzhq-ey.a.run.app',
        hiddenProdTrikotURL: 'https://proximitybbdo-trikot-fcmilka-de-v25ef3pzhq-ey.a.run.app',
        stagingServiceURL: 'https://api-fcmilka-de.mdlzapps.cloud/',
        LocalstagingServiceURL: 'https://localhost:8084/stage/',
        prodServiceURL: 'https://api.fcmilka.de/',
        prodServiceURLWWW: 'https://api.fcmilka.de/',

        // hiddenProdserviceURL: 'https://proximitybbdo-fcmilka-de-api-v25ef3pzhq-ey.a.run.app/',
        hiddenProdserviceURL: 'https://api.fcmilka.de/',
        stagingFancenterServiceURL: 'https://api-fcmilka-de.mdlzapps.cloud/',
        campaignURL: 'Campaignstatus',
        campaignURL90min: 'CampaignStatus90mins',
        campaignURLPenny: 'CampaignStatusPennyGondelkopf',
        campaignURLWeltmeister: 'CampaignStatusWeltmeister',
        campaignURLTickets: 'CampaignStatusTickets',
        campaignURLBuzzer: 'CampaignStatusBuzzer',
        campaignURLTrikot: 'CampaignStatusTrikot',
        campaignURLDoppelt: 'CampaignStatusGewinnen',
        particationURL: 'Participation/Submit',
        loginUrl: 'UserManagement/LoginPromo',
        loginDetailUrl: 'UserManagement/GetProfileInfoPromo',
        registerUrl: 'UserManagement/Registration',
        resetPasswordUrl: 'UserManagement/ResetPassword',
        updateProfileUrl: 'UserManagement/UpdateProfileInfo',
        deleteProfileUrl: 'UserManagement/DeleteAccount',
        forgetPasswordUrl: 'UserManagement/passwortZuruecksetzen',
        passwordChangeEmailUrl: 'UserManagement/TriggerPasswordReset',
        finalizeResetPasswordUrl: 'UserManagement/FinalizeResetPassword',
        reweCampaignUrl: 'campaignStatusrewe',
        KauflandCampaignUrl: 'CampaignstatusKaufland',
        validateWinningCodeUrl: 'fancenter/submit',
        confirmAddressUrl: 'fancenter/confirm',
        ticketTeamUrl: 'fancenter/select',
        fanCenterProfileUrl: 'fancenter/selectsize',
        edekaCampaignUrl: 'campaignStatusedeka',
        nettoCampaignUrl: 'campaignstatusnetto',
        pennyCampaignUrl: 'campaignStatuspenny',
        upgradeAccountUrl: 'UserManagement/UpgradeAccount',
        getSupermarketPromotionUrl: 'participation/retailers'



    },

    /*
        URLs for the different page
    */
    Route: {
        home: '/',
        insta: '/insta',
        holding: '/',
        end: '/',
        prize: '/gewinne',                     // prize
        participation: '/mitmachen',                   //particpation
        charity: '/charity',
        gift: '/gift',                        //gift
        thankyou: '/danke',                     //Thank you
        product: '/produkte',                    //product
        faq: '/faq',                         //faq
        terms: '/teilnahmebedingungen',        //terms
        fancenterTerms: '/fancenter/teilnahmebedingungen',        //fancenter terms
        cookie: '/cookie',                      //cookie
        game: '/confirm',                       //game
        gamification: '/gamifikation',
        account: '/account',                      //account
        register: '/register',
        loginDetail: '/loginDetail',
        confirmationAccount: '/confirmationAccount',
        resetPassword: '/resetPassword',
        intropageHolding: '/holding/alle-aktionen',
        intropage: '/alle-aktionen',
        announcement: '/announcement',
        changePassword: '/passwortzuruecksetzen',
        address: '/Lieferadressen',
        accountInformation: '/AccountInformationen',
        passwordChangeEmail: '/passwordChangeEmail',
        accountDeletion: '/deleteAccount',
        finalizeResetPassword: 'finalizeResetPassword',
        registerDOI: '/danke-account-bestatigung',
        confeti: '/confeti',
        rewe: '/rewe',
        endpageRewe: '/endRewe',
        kaufland: '/kaufland',
        endKaufland: '/endKaufland',
        holdingKaufland: '/announcementKaufland',
        fanCenterLink: '/fanCenter',
        codeEntry: '/codeEntry',
        chooseJersey: '/dein-gewinn-',
        thankyouFanCenter: '/fancenter/confirm',
        edeka: '/edeka',
        endEdeka: '/endEdeka',
        netto: '/netto',
        endNetto: '/endNetto',
        penny: '/penny',
        endPenny: '/endPenny',
        footballGame: '/fußballspiel',
        fairplay: '/fairplay',
        ProductCodeVerification: '/product-code-verification',
        NoProductCode: '/no-product-code',
        noParticipation: '/no-participation',
        fanCenter: {
            main: "/fancenter",
            giftChoice: `${fanCenterBase}/choice`,
            codeEntry: {
                user: isProd ? "/dein-gewinn" : "/fancenter/code-entry",
                companion: `${fanCenterBase}/code-entry/friend`
            },
            jersey: {
                teamSelection: `${fanCenterBase}/jersey`,
                sizeSelection: `${fanCenterBase}/jersey/size`,
                errorJersey: `${fanCenterBase}/jersey/error`,
            },
            userDetails: {
                jersey: `${fanCenterBase}/user-details`,
                companion: `${fanCenterBase}/user-details/ticket/friend`,
                user: `${fanCenterBase}/user-details/ticket`
            },
            confirmation: {
                jersey: `${fanCenterBase}/confirmation`,
                user: `${fanCenterBase}/confirmation/ticket`,
                companion: `${fanCenterBase}/confirmation/ticket/friend`
            },
            ticket: {
                teamSelection: `${fanCenterBase}/ticket`,
                userDetails: `${fanCenterBase}/ticket/details`,
                confirmation: {
                    user: "",
                    companion: ""
                },
                errorTickets: `${fanCenterBase}/ticket/error`,
            },
            tickets: {
                baseName: `${fanCenterBase}/tickets/*`,
                forMeOrFriend: `${fanCenterBase}/tickets/choice`,
                favoriteClub: `${fanCenterBase}/tickets/club`,
                teamSelection: `${fanCenterBase}/tickets/team`,
                userDetails: `${fanCenterBase}/tickets/details`,
                confirmation: `${fanCenterBase}/tickets/confirmation`,
                confirmationFriend: `${fanCenterBase}/tickets/friend/confirmation`,
                companion: {
                    userDetails: `${fanCenterBase}/tickets/companion/details`,
                    confirmation: `${fanCenterBase}/tickets/companion/confirmation`,
                },
                faq: {
                    faq: `${fanCenterBase}/tickets/faq`,
                    faqjersey: `${fanCenterBase}/jerseys/faq`,
                },
            },
        },
        jersey: {
            main: "/jersey/main",
            codeEntry: "/jersey/code-entry",
            teamSelection: "/jersey/team-selection",
            jerseySizeSelection: "/jersey/size",
            jerseyUserDetails: "/jersey/details",
            ticketSelection: "/jersey/ticket/selection",
            ticketOptions: "/jersey/ticket/options",
            confirmation: "/jersey/confirmation",
            addressForm: "/jersey/form/address"
        },
        sommer: {
            main: "/",
            thankyou: '/danke',
            faq: '/faq',
            terms: '/teilnahmebedingungen',
            participation: '/mitmachen',
            resetPassword: '/passwordChangeEmail',
            cookie: '/cookies',
        },
        reihe:{
            main: "/",
            holding: "/",
            end: "/",
            faq:"/faq",
            cookie: '/cookie',
            participation: '/mitmachen',
            confirmation: '/confirmation',
            terms:'/teilnahmebedingungen',
            resetPassword:'/passwordChangeEmail'
        },
        heimtrikot:{
            main: "/",
            holding: "/",
            end: "/",
            faq:"/faq",
            cookie: '/cookie',
            participation: '/mitmachen',
            confirmation: '/confirmation',
            terms:'/teilnahmebedingungen',
            resetPassword:'/passwordChangeEmail'
        },
        buzzer: {
            holding: `/Holding`,
            closing: `/Closing`,
            main: `/`,
            participate: "/mitmachen",
            participation: `/mitmachen`,
            game: `/Game`,
            randomDraw: `/Auslosung`,
            confirmation: "/confirmation",
            prize: `/gewinne`,
            product: `/produkte`,
            intro: `/alle-aktionen`,
            faq: `/faq`,
            terms: `/teilnahmebedingungen`,
            end: `/`,
            netto: "/Netto",
            endnetto: "/Netto/End",
            holdingnetto: "/Netto/holding",
            kaufland: "/Kaufland",
            holdingkaufland: "/Kaufland/holding",
            endkaufland: "/Kaufland/End",
            edeka: "/Edeka",
            holdingEdeka: "/Edeka/holding",
            endEdeka: "/Edeka/end",
            rewe: "/Rewe",
            holdingRewe: "/Rewe/holding",
            endRewe: "/Rewe/end",
            penny: "/Penny",
            holdingPenny: "/Penny/holding",
            endPenny: "/Penny/end",
            globus: "/Globus",
            globusTerms: "/Globus/teilnahmebedingungen",
            holdingGlobus: "/Globus/holding",
            endGlobus: "/buzzer/Globus/end",
            selection: "/selection"
        },
        selfie: {
            game: "/selfie/game",
            confirmation: "/selfie/confirmation"
        },
        globusMicroSite: {
            main: "/einlaufkinder",
            participation: "/einlaufkinder/mitmachen",
            confirmation: "/einlaufkinder/confirmation",
            end: "/einlaufkinder/end",
            faq: "/einlaufkinder/faq"
        },
        pennyMicrosite: {
            holding: "/penny-topps/holding",
            main: "/penny-topps",
            participation: "/penny-topps/mitmachen",
            prize: "/penny-topps/gewinne",
            faq: "/penny-topps/faq",
            terms: "/penny-topps/teilnahmebedingungen",
            confirmation: "/penny-topps/confirmation",
            end: "/penny-topps/end"
        }

    },

    /* 
        Google recaptcha config
    */
    Captcha: {
        default: '6Ldal_QZAAAAAPUfbbQX9ApvYFjjEHpvE7PQPXRb',
        dev: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        publish: '6Lc9Ud4mAAAAAHNRdiY0xhlSqaChg_G0tbGDRBBa',
        siteKeyRewe: '6Lc9Ud4mAAAAAHNRdiY0xhlSqaChg_G0tbGDRBBa',         //Dev : 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI   PROD : 6LfWEs8ZAAAAABdXUIRvUbcVHid8KRgbfAQGvZtA
        siteKeyKaufland: '6Lc9Ud4mAAAAAHNRdiY0xhlSqaChg_G0tbGDRBBa',         //Prod : 
        siteKeyMuller: '6Lc9Ud4mAAAAAHNRdiY0xhlSqaChg_G0tbGDRBBa',         //Prod : 
        language: isProd ? 'de' : 'en',
        sommerCaptcha: '6LdUpMUpAAAAAGGquxkzG3n0QH41tDPuTvrmFaT7'
    },

    SeasonActive: false,

    /*
        GTM ID : Google gtm scripts
    */
    GTM: {
        gtmTag: true,                       //true or false 
        gtmIDRewe: 'GTM-57KW9HW',       //need to put the correct one - dev GTM-000000
        gtmIDKaufland: 'GTM-57KW9HW',         //GTM-M76THVK
        gtmIDMuller: 'GTM-57KW9HW'
    },

    Session: {
        userstatus: 'userstatus',
        loginstatus: 'loginstatus',
        tokenstatus: 'tokenstatus',
        tshirtSelection: 'tshirtSelection',
        ticketfor: 'ticketfor',
        matchDate: 'macthDate',
        teamsFanceneter: 'teamFancenter',
        userID: 'userID',
        teamSelected: 'teamSelected',
        teamAvailable: 'teamAvailable',
        giftCategory: 'giftCategory',
        giftType: 'giftType',
        footballClubId: 'footballClubId',
        codeEntered: 'codeEntered',
        emailCode: 'emailCode',
        availableTshirt: 'availableTshirt',
        size: 'size',
        fancenterConsumerID: 'fancenterConsumerID',
        matchAvailable: 'matchAvailable',
        matchSelectedSm: 'matchSelectedSm',
        matchDateSm: 'matchDatesm',
        supermarketPromotion: 'supermarketPromotion'
    },

    JSON: {
        rewe: '/resources/localisation/rewe_localisation.json',
        kaufland: '/resources/localisation/kaufland_localisation.json',
        muller: '/resources/localisation/muller_localisation.json'
    },

    Date: {
        rewe: {
            start: '12.04.2021',
            end: '30.06.2021'
        },
        kaufland: {
            start: '12.04.2021 ',
            end: '30.06.2021'
        },
        muller: {
            start: '12.04.2021',
            end: '30.06.2021'
        }
    },

    DomainName: window.location.hostname,



}

export default GLOBAL_CONFIG;