import React from "react";

class JerseyStepIndicitor extends React.Component {
    constructor(props) {
        super(props);

        this.StepList = props.Steps;

        this.completedModifier = "navigation-text-completed";
    }
    render() {
        return(
            <div className="fancenter__steps">
                {
                    this.StepList.map( (step, index) => {
                        let { display, isInProgress, isComplete } = step,
                            classModifier = "";

                        if(isInProgress) { classModifier = "in-progress"; }
                        if(isComplete) { classModifier = "complete"; }

                        return (
                            <div key={index} className={classModifier}>
                                <span>{display}</span>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default JerseyStepIndicitor;