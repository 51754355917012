import React, { Component, useCallback } from 'react';
import { withTranslation } from "react-i18next";
import GLOBAL_CONFIG from '../../config/config'; 
import NavigationAccount from '../NavigationAccount/NavigationAccount';
import { servicePath, mainLoaderToggle,optionListConstruct, getCookie } from '../../modules/helpers';
import axios from 'axios'; 
import MenuBarAccount from '../MenuBarAccount/MenuBarAccount';
import { withRouter } from 'react-router';

class LoginDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {                   
           milka:true,
           tokenLogin:null,
           street1:null,
           streetNumber:null,
           zipcode:null,
           city:null,
           address:null,
           land:"Deutschland"
        }; 
    }
    componentWillMount(){
        console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
        
        if(getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null){
            let getLoginDetailURL = servicePath({
                ...GLOBAL_CONFIG.ServiceSettings,
                status: 'details'
            });
            let tokenInfo = getCookie(GLOBAL_CONFIG.Session.tokenstatus)
            
            console.log(tokenInfo);
            axios({
                method: 'POST',
                url: getLoginDetailURL,
                data: {
                    "token":tokenInfo
                },
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                console.log(response)
                let _data = response.data;
                if(_data.success) {
                    this.setState({
                        street1:response.data.street1,
                        streetNumber:response.data.streetnumber,
                        zipcode:response.data.zipcode,
                        city:response.data.city,
                        address:response.data.street2,
                        tokenLogin:response.data.token,
                    });
                }    
            })
            .catch((response) => {
            // this.setState({
            //     sreet1:"response.data.street1",
            //     streetNumber:'response.data.streetnumber',
            //     zipcode:'response.data.zipcode',
            //     city:'response.data.city',
            //     tokenLogin:'response.data.token',
                
            // })
            console.log("address not found")
            this.props.history.push({pathname: GLOBAL_CONFIG.Route.address});
        });
    } 
    }
    // getimageTeam= (name)=>{
    //     let image=name;
    // }
    componentDidMount(){
        mainLoaderToggle('hide');
       
    }
    componentWillUnmount(){
        mainLoaderToggle('show');
        
    }
    logout= ()=>{
        if(getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null){
            sessionStorage.setItem(GLOBAL_CONFIG.Session.tokenstatus,null);
            document.cookie = GLOBAL_CONFIG.Session.tokenstatus + '=' + null + ';domain=' + GLOBAL_CONFIG.DomainName;
            console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
            this.props.history.push({pathname: GLOBAL_CONFIG.Route.account});

        }
        this.props.history.push({pathname: GLOBAL_CONFIG.Route.account});
    }
    render() {
        let milka = null;
        const { t } = this.props;  
        let footballClubContent= optionListConstruct(t('Page.Participation.form.footballClub.footballClubListName', { returnObjects: true })); 
            milka=(
                <div>
                     <div className="profile_detail">
                    <div className="left"> <NavigationAccount/></div>
                    <div className="form-container grid-5-m  grid-s-2 logout-address">
                        <div className="left">
                            <button className="btnLogout" onClick={this.logout}>
                            <span className="btn__logout">Ausloggen</span>  
                            </button>
                        </div>                     
                    </div>
                    <div className="right">
                        <div className="content-upload-receipt form-line row ">
                        <div className="form-container grid-5-m  grid-s-2"> 
                        <div className="choose_team dropdown_space">
                            <h2>Lieferadressen</h2>         
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="register">
                <div className="s-content-partication account-info">
                    <form className="bg-lilac" id="frm_registration" name="frm_registration" method="post" action="/" onSubmit={this.onSubmitHandler} noValidate>
                    <div className="form-line row">
                                <div className="form-container form-mobile grid-6-m grid-m-6-m grid-s-2">
                                    <div className="input-container">
                                        <div className="streetLbl">
                                            <div className="form-input__container ">
                                                <label className="form-input__label" htmlFor="street_name">Straße*</label>
                                                <input className="form-input__input js-event-type-in js-event-ab" type="text" name="street_name" readOnly="true" id="street_name" value={this.state.street1} data-require="true" data-type="text" data-error-target="#error-street-number" data-required-message="Street number is required." data-event-fieldname="Street number" data-event="form-click" data-category="Form Action" data-action="Street number" data-label="Type In"  />
                                            </div>
                                        </div>
                                        <div className="numberLbl">
                                            <div className="form-input__container">
                                                <label className="form-input__label" htmlFor="street_number">Hausnummer*</label>
                                                <input className="form-input__input js-event-type-in js-event-ab" type="text" name="street_number" readOnly="true" id="street_number" value={this.state.streetNumber} data-require="true" data-type="text" data-error-target="#error-house-number" data-required-message="House number is required." data-event-fieldname="House number" data-event="form-click" data-category="Form Action" data-action="House number" data-label="Type In" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="form-line row">
                                <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                    <div className="input-container">
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="adress">Adresszusatz*</label>
                                            <input className="form-input__input js-event-type-in js-event-ab" type="text" name="address" id="address" readOnly="true" value={this.state.address} data-require="falsel" data-type="text" data-error-target="#error-address" data-required-message="Address is required." data-event-fieldname="Address" data-event="form-click" data-category="Form Action" data-action="Address" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="form-line row">
                                <div className="form-container form-mobile grid-6-m grid-m-6-m grid-s-2">
                                    <div className="input-container">
                                    <div className="zipLbl">
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="zip_code">PLZ*</label>
                                            <input className="form-input__input js-event-type-in js-event-ab js-zip-code" type="text" name="zip_code" id="zip_code" readOnly="true" value={this.state.zipcode} maxLength="5" data-require="true" data-type="regex" data-error-target="#error-zip-code" data-regex-pattern="^[0-9]{5}$" data-required-message="Zip code is required." data-pattern-message="Zip code not valid." data-event-fieldname="Zip code" data-event="form-click" data-category="Form Action" data-action="Zip code" disabled />
                                        </div>
                                    </div>
                                    <div className="cityLbl">
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="city">Stadt*</label>
                                            <input className="form-input__input js-event-type-in js-event-ab" type="text" name="city" id="city" value={this.state.city} readOnly="true" data-require="true" data-type="text" data-error-target="#error-city" data-required-message="City is required." data-event-fieldname="City" data-event="form-click" data-category="Form Action" data-action="City" disabled />
                                            <label className="form-input__label" htmlFor="city">Stadt</label>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-line row">
                                <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                    <div className="input-container space_hr">
                                        <div className="form-input__container dropdown-input__container active">
                                            <input className="form-input__input" type="text" name="country" id="country" placeholder="Deutschland" value="Deutschland" readOnly="true" data-require="false" disabled />
                                            <label className="form-input__label" htmlFor="country">Land*</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </form>
                            </div>
                            </div>
                </div>
               )
        return(
            <div>
               <MenuBarAccount/>
                {milka}
            </div>
        )
    }
}
export default withTranslation()(withRouter(LoginDetail));