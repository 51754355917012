const _RES = "/resources/imagesBundesliga/teams-pictures-buzzer";
const BundesligaTeams = {
    "1. FC Heidenheim 1846": { 
        img: `${_RES}/1. FC Heidenheim 1846.png`
    },
    "1. FC Kaiserslautern": { 
        img: `${_RES}/1. FC Kaiserslautern.png`
    },
    "1. FC Köln": { 
        img: `${_RES}/1. FC Köln.png`
    },
    "1. FC Magdeburg": { 
        img: `${_RES}/1. FC Magdeburg.png`
    },
    "1. FC Nürnberg": { 
        img: `${_RES}/1. FC Nürnberg.png`
    },
    "1. FC Union Berlin": { 
        img: `${_RES}/1. FC Union Berlin.png`
    },
    "1. FSV Mainz 05": { 
        img: `${_RES}/1.FSV Mainz 05.png`
    },
    "1.FSV Mainz 05": { 
        img: `${_RES}/1.FSV Mainz 05.png`
    },
    "DSC Arminia Bielefeld": { 
        img: `${_RES}/DSC Arminia Bielefeld.png`
    },
    "Bayer Leverkusen": { 
        img: `${_RES}/Bayer 04 Leverkusen.png`
    },
    "Bayer 04 Leverkusen": { 
        img: `${_RES}/Bayer 04 Leverkusen.png`
    },
    "Borussia Dortmund": { 
        img: `${_RES}/Borussia Dortmund.png`
    },
    "Borussia Mönchengladbach": { 
        img: `${_RES}/Borussia Mönchengladbach.png`
    },
    "Borussia M'gladbach": { 
        img: `${_RES}/Borussia Mönchengladbach.png`
    },
    "Dynamo Dresden": { 
        img: `${_RES}/Dynamo Dresden.png`
    },
    "Eintracht Braunschweig": { 
        img: `${_RES}/Eintracht Braunschweig.png`
    },
    "Eintracht Frankfurt": { 
        img: `${_RES}/Eintracht Frankfurt.png`
    },
    "Erzgebirge Aue": { 
        img: `${_RES}/Erzgebirge Aue.png`
    },
    "FC Erzgebirge Aue": { 
        img: `${_RES}/Erzgebirge Aue.png`
    },
    "FC Augsburg": { 
        img: `${_RES}/FC Augsburg.png`
    },
    "FC Bayern München": { 
        img: `${_RES}/FC Bayern München.png`
    },
    "FC Ingolstadt 04": { 
        img: `${_RES}/FC Ingolstadt 04.png`
    },
    "FC Schalke 04": { 
        img: `${_RES}/FC Schalke 04.png`
    },
    "FC St. Pauli": { 
        img: `${_RES}/FC St. Pauli.png`
    },
    "Fortuna Düsseldorf": { 
        img: `${_RES}/Fortuna Düsseldorf.png`
    },
    "Hamburger SV": { 
        img: `${_RES}/Hamburger SV.png`
    },
    "Hannover 96": { 
        img: `${_RES}/Hannover 96.png`
    },
    "Hansa Rostock": { 
        img: `${_RES}/Hansa Rostock.png`
    },
    "FC Hansa Rostock": { 
        img: `${_RES}/Hansa Rostock.png`
    },
    "F.C. Hansa Rostock": { 
        img: `${_RES}/Hansa Rostock.png`
    },
    "Hertha BSC Berlin": { 
        img: `${_RES}/Hertha BSC Berlin.png`
    },
    "Hertha BSC": { 
        img: `${_RES}/Hertha BSC Berlin.png`
    },
    "Holstein Kiel": { 
        img: `${_RES}/Holstein Kiel.png`
    },
    "Jahn Regensburg": { 
        img: `${_RES}/Jahn Regensburg.png`
    },
    "SSV Jahn Regensburg": { 
        img: `${_RES}/Jahn Regensburg.png`
    },
    "Karslruher SC": { 
        img: `${_RES}/Karslruher SC.png`
    },
    "Karlsruher SC": { 
        img: `${_RES}/Karlsruher SC.png`
    },
    "RB Leipzig": { 
        img: `${_RES}/RB Leipzig.png`
    },
    "Sport-Club Freiburg": { 
        img: `${_RES}/Sport-Club Freiburg.png`
    },
    "SC Paderborn 07": { 
        img: `${_RES}/SC Paderborn 07.png`
    },
    "SpVgg Greuther Fürth": { 
        img: `${_RES}/SpVgg Greuther Fürth.png`
    },
    "SV Darmstadt 98": { 
        img: `${_RES}/SV Darmstadt 98.png`
    },
    "SV Sandhausen": { 
        img: `${_RES}/SV Sandhausen.png`
    },
    "SV Werder Bremen": { 
        img: `${_RES}/SV Werder Bremen.png`
    },
    "TSG 1899 Hoffenheim": { 
        img: `${_RES}/TSG 1899 Hoffenheim.png`
    },
    "TSG Hoffenheim": { 
        img: `${_RES}/TSG 1899 Hoffenheim.png`
    },
    "VFB Stuttgart": { 
        img: `${_RES}/VFB Stuttgart.png`
    },
    "VfB Stuttgart": { 
        img: `${_RES}/VFB Stuttgart.png`
    },
    "VFL Bochum": { 
        img: `${_RES}/VFL Bochum.png`
    },
    "Vfl Bochum 1848": { 
        img: `${_RES}/VFL Bochum.png`
    },
    "VfL Bochum 1848": { 
        img: `${_RES}/VFL Bochum.png`
    },
    "VFL Bochum 1848": { 
        img: `${_RES}/VFL Bochum.png`
    },
    "VFL Wolfsburg": { 
        img: `${_RES}/VFL Wolfsburg.png`
    },
    "Vfl Wolfsburg": { 
        img: `${_RES}/VFL Wolfsburg.png`
    },
    "VfL Wolfsburg": { 
        img: `${_RES}/VFL Wolfsburg.png`
    },
}

export default BundesligaTeams;