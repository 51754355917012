import React from "react";
import parse from 'html-react-parser';
import { mainLoaderToggle } from "../../../../modules/helpers";
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import StepContent from "../component/StepContent/StepContent";
import TitleContent from "../component/TitleContent/TitleContent";
import Affiche from "../component/Affiche/Affiche";
import '../style/main.scss';
import Button from '../component/Button/Button';
import appGoogleTracking from "../../../../modules/googletracking";
import PromoPopup from "../component/Popup/PromoPopup";


class SommerMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            modal: false,
            gameStatus: {},
            openModal: false
        }
        this.resize = this.resize.bind(this);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        window.addEventListener("resize", this.resize);
        this.resize();
        _SCROLL_PAGE.ScrollToTop();
        const gameState = this.props.gameState;

        const params = new URLSearchParams(window.location.search);
        if (params.get("status")?.includes("on")) {
            this.setState({ modal: true })
        }
    }

    componentWillMount() {
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }
    handleChild(callback) {
        // Here, you have the function from the child.
        callback();
    };
    handleModal() {
        appGoogleTracking.dataLayerPush({
            'dataEvent': "join_now",
            'dataCategory': "Start Page",
            'dataAction': "Click on Join Now",
            'dataLabel': "GAME_SP_PART"
        });
        this.setState({openModal: true});
    }

    render() {
        return (
            <div>
                <div className="container_main" >
                    <div>
                        <div className='bannerBtn' onClick={() => this.handleModal()}>
                        </div>
                        <PromoPopup modal={this.state.openModal} toggleModal={() => this.toggleModal()}></PromoPopup>
                    </div>
                    <TitleContent heading='OLÉ, OLÉ, OH WOW! WAS FÜR PREISE' subheading='Die Bundesliga & 2. Bundesliga starten in die neue Saison! Und dafür hat sich der FC Milka wieder ein absolutes Highlight einfallen lassen: Nutze deine Chance & verfolge zusammen mit 10 Freunden ein Spiel des FC Bayern München oder von Borussia Dortmund in Reihe 1.'/>
                    <Affiche></Affiche>
                    <StepContent modalHaut={this.state.openModal}/>
                </div >
                <Footersocial />
            </div>
        );
    }

    toggleModal() {
        this.setState((prevState) => ({
            openModal: !prevState.openModal,
        }));
    }

}
const mapStateToProps = state => ({
    gameState: state.game
});
/* export default withTranslation()(SommerMain); */
export default withTranslation()(withRouter(connect(mapStateToProps, null)(SommerMain)));