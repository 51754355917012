import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import _SCROLL_PAGE from "../../../modules/ScrollPage";

class Q3Faq extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div id="buzzer-faqs">
                { this.faqContent(this.props) }
                {/* {this.footerSection()} */}
                <Footersocial />
            </div>
        );
    }

    faqContent(props) {
        const iconPlus = "/resources/imagesBundesliga/buzzer/plus.png";
        const iconMinus = "/resources/imagesBundesliga/buzzer/minus.png";

        return (
            <div className="content-faq">
                <div className="content-faq__item">
                    <h6>Wie läuft das Gewinnspiel ab?</h6>
                    <img
                        src={iconMinus}
                        className="content-faq__img shown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content show">
                        <ul>
                            <li>
                                Kaufe innerhalb des Zeitraumes vom 11.07. bis 10.09.2022 ein Aktionsprodukt von Milka,
                                Wunderbar und/oder TUC.
                            </li>
                            <li>
                                Gib auf{" "}
                                <NavLink
                                    to={GLOBAL_CONFIG.Route.home}
                                    exact
                                    data-event="info-click"
                                    data-category="Informational Action"
                                    data-action="Header Tab"
                                    data-label="Mitmachen"
                                    onClick={appGoogleTracking.processEventCTANavLink}
                                    className="js-ham-close"
                                >
                                    {" "}
                                    dieser Seite{" "}
                                </NavLink>{" "}
                                den Produktcode bis zum 10.09.2022 sowie deine Kontaktdaten ein. (Hinweis: Befindet sich kein
                                Produktcode auf der Produktverpackung, so müssen die Zahlen beim Barcode eingegeben werden.)
                                <p></p>
                                Zusätzlich benötigen wir noch die Angabe des Händlers, dein Einkaufsdatum und die
                                Aktionsmarke, die du eingekauft hast. Online-Registrierung erforderlich.
                            </li>
                            <li>
                                Nach Eingabe des Produktcodes erhältst du eine Bestätigungsmail, in der dir das 90 Minuten
                                Gewinn-Fenster am darauffolgenden Tag genannt wird.
                            </li>
                            <li>
                                Am Tag des 90 Minuten Gewinn-Fensters erhältst du eine E-Mail mit einem Gewinnlink für die
                                Teilnahme am Gewinnspiel innerhalb des 90 Minuten Gewinn-Fensters. Mit Klick auf den Link
                                nimmst du anschließend am Gewinnspiel teil und erfährst direkt, ob du gewonnen hast oder
                                nicht. Gewinnermittlung per Zufallsgenerator.
                            </li>
                            <li>Bitte bewahre den Original-Kaufbeleg gut auf.</li>
                        </ul>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Mit welchen Produkten kann ich teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Die Aktion ist gültig für alle Produkte der Marken Milka, Wunderbar und/oder TUC.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wo finde ich die Produkte für das Gewinnspiel?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                        Du findest die Produkte überall dort im Handel, wo du auch sonst deine
Lieblingsprodukte von Milka, Wunderbar und/oder TUC findest.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich mehrere Produktcodes hochladen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                        Ja, Mehrfachteilnahmen mit jeweils neuem Produktcode sind innerhalb der
Aktionsprodukte erlaubt. Max. ein Gewinn pro Tag/Person.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie lange kann ich an der Aktion teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Du hast vom 11.07. bis 10.09.2022 die Möglichkeit, deinen Produktcode einzugeben.
Es nehmen aber nur Aktionsprodukte teil, die im Zeitraum vom 11.07. bis 10.09.2022
gekauft wurden.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann jeder an der Aktion teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Teilnehmen kann jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland.</p>
                        <p>Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen
sowie jeweils deren Angehörige.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>In welchen Ländern kann man teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Die Teilnahme ist in Deutschland möglich.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Was kann gewonnen werden?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Insgesamt gibt es 5.490 Gewinne im Aktionszeitraum:</p>
                        <ul>
                            <li>
                            244 x 2 Cat2-Tickets Saison 2022/23 für ein Spiel der Bundesliga oder 2.
Bundesliga des Clubs der Wahl*
                            </li>
                        </ul>
                        <p>(Werte variieren je nach Club, Auswahl nach Verfügbarkeit über Milka Fan-Center)</p>
                        <ul>
                            <li>
                            183 x 1 signiertes original Bundesliga Heimtrikot
                            </li>
                            <li>
                            183 x 1 original Bundesliga Heimtrikot
                            </li>
                        </ul>
                            <p>(Werte der Trikots variieren je nach Club, Auswahl nach Verfügbarkeit über Milka
Fan-Center)</p>
                        <ul>
                            <li>
                            4.880 x Bundesliga Match Attax Starterpacks
                            </li>
                        </ul>
                        <p>(Wert ca. 8,99 €, einzulösen über digitalen Code auf topps.com)</p>
                        <p>*Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga.
Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann
ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der
Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie
insbesondere die derzeitige COVID-19-Situation und sich aus ihr ergebende
Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht
verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat
in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige
Kompensation.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie erfahre ich, ob und was ich gewonnen habe?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Mit Klick auf den Link nimmt der Teilnehmer am Gewinnspiel teil und erfährt direkt, ob
er gewonnen hat oder nicht.</p>
                        <p>Gewinnermittlung per Zufallsgenerator.</p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Kann ich meine Daten löschen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Nach Ende der Aktion werden alle Teilnehmerdaten gelöscht. Soweit diese aus
gesetzlichen Gründen nicht gelöscht werden dürfen, werden deine Daten gesperrt
und nach Ablauf der handels- und steuerrechtlichen Aufbewahrungsfrist gelöscht.
Aber falls du deine Daten bereits während des Aktionszeitraums löschen möchtest, ist
das natürlich möglich. Dazu wendest du dich bitte direkt an
unseren <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank">Verbraucherservice*</a>. Eine Teilnahme am Gewinnspiel ist dann leider nicht
mehr möglich.</p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>An wen kann ich mich wenden, wenn ich weitere Fragen habe?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Vielleicht hast du noch weitere Fragen, die hier nicht beantwortet worden sind. Dann
wende dich am besten direkt an unseren <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank">Verbraucherservice*</a>.</p>
                        <p>*Falls der hier hinterlegte Link zum Verbraucherservice nicht funktioniert, kannst du über den Kontaktlink im Bereich „Service“ den Verbraucherservice erreichen.</p>
                    </div>
                </div>
            </div>
        );
    }

    showItemContent(e, iconMinus, iconPlus) {
        let classes = Array.from(e.target.classList);
        let itemContent = e.target.parentNode.children[2];

        if(classes.includes("notshown")){
            e.target.setAttribute( 'src', iconMinus);
            e.target.classList.remove("notshown");
            e.target.classList.add("shown");
            itemContent.classList.add("show");
        }else{
            e.target.setAttribute( 'src', iconPlus);
            e.target.classList.remove("shown");
            e.target.classList.add("notshown");
            itemContent.classList.remove("show");
        }
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <div className="content-separator">
                    <h2>
                        Mit deiner Lieblingsschokolade von Milka gewinnen!
                    </h2>
                    <h3 className="special">
                        Welches ist dein Lieblingsprodukt von Milka? Bist du Fan der zarten Milka Alpenmilch Schokolade? Stehst du auf die Milka Mmmax mit knackigen Erdnüssen und cremigem Karamell? Kannst du den luftig gebackenen Milka Waffelinis nicht widerstehen? Sind unsere Milka Cookie Sensations mit OREO Creme-Füllung vielleicht dein Favorit? Oder naschst du doch am liebsten aus unserem Milka Zarte Momente Mix – damit du dich nie für eine Sorte entscheiden musst?
                    </h3>
                
                    <img src="\resources\imagesBundesliga\buzzer\products.png" alt="Products" />
                
                    <div className="cta-container">
                        <a href="https://www.milka.de/alle-produkte" target="_blank" className="btn--anchor no-decoration">
                            <button className="btn__container btn--lilac">
                                MILKA SCHOKOLADE ENTDECKEN
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
    
export default withTranslation()(Q3Faq);