import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import GLOBAL_CONFIG from '../../config/config'; 
import { servicePath, mainLoaderToggle, getCookie } from '../../modules/helpers';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import axios from 'axios';                                                          //Axios library to handle AJAX request                                              //Parse HTML data from string
import ValidateForm from '../../modules/validate';
import { withRouter } from 'react-router';

class ResetPassword extends Component {

    // componentDidMount(){
    //     mainLoaderToggle('hide');
       
    // }
    // componentWillUnmount(){
    //     mainLoaderToggle('show');
        
    // }
    // state = {
    //     tokenState: '',
    // }
    constructor(props) {
        super(props);

        // this.handleUnload = this.handleUnload.bind(this);       //Binding to check for Abondanment form for Google tracking
        
    
        this.state = {                   
               //User need to be at least 18 - use in flapickr
            loaderSubmit: false,
            loaderError: false,
            loaderGeneralError: false,
            message:false,
            tokenState: null,
            authorization:null,
            email:null,
            token:null,
            wrongOLdPassword:null,
            passwordSuccessChange:null
        };
    }
    componentWillMount(){
        // this.setState({
        //     // tokenState: getCookie(GLOBAL_CONFIG.Session.tokenstatus)
        //     tokenState: "jikjioj",
            
        // });
        // console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
        // if(getCookie(GLOBAL_CONFIG.Session.tokenstatus) === null){
        //     //Go to particaption page
        //     this.props.history.push({pathname: GLOBAL_CONFIG.Route.account});
        // } else {
        //      //Get session state
        //    this.setState({
        //         tokenState: getCookie(GLOBAL_CONFIG.Session.tokenstatus)
                
        //     });
        //     console.log(this.state.tokenState);
           
        // }

    }

    componentDidMount(){
        const { t } = this.props; 
        //Remove main loader
        mainLoaderToggle('hide');
        
    }

    //Close error message popup
    onClosePopupHandler = () => {
        this.setState({
            loaderError: false,
            loaderGeneralError: false,
            authorization:false,
            passwordSuccessChange:false
        });
    }

    //Form submit event
    onSubmitHandler = (event) => {
        // console.log("sds");
        event.preventDefault();
        
        // //Validation of fields
        let validateFormInfo = ValidateForm.validateForm('frm_resetPassword');
    
        if(validateFormInfo === true){
            //Check if file is valid
            console.log("1111");
            this.retrieveToken();

        } else {
            console.log("njh");
        }
    }

    messageError=()=>{
        console.log("1251");
        this.setState({
            message:true
        });
        
    }
    retrieveToken = () =>{
        if(window.location.hash !=null) {
            let details=JSON.parse(decodeURIComponent(window.location.hash).substring(1));
            let email=details.email;
            let token=details.token;
           
            //console.log(email.length); 
            var resultemail = email.replace(/\u200B/g,'');
            var resulttoken = token.replace(/\u200B/g,'');
            //console.log(resultemail.length); 

            // if(email.match("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$)")){
                this.setState({
                    email:email,
                    token: token
                });
                this.processFormData(resultemail,resulttoken);
            // }
            
        } 
       
        
    }
    //Process form when Captcha is OK
    processFormData = (resultemail,resulttoken) => {
        //Open loader
        this.setState({
            loaderSubmit: true
        });
        // token=sessionStorage.getItem(GLOBAL_CONFIG.Session.loginStatus) 
        //Process form data
        let data = {
            // fileinput:             document.getElementById('file_to_upload').files[0],
            email: resultemail,  
            token: resulttoken,             
        };
        if(document.getElementById('password').value!=document.getElementById('confirmPassword').value){
            this.messageError();
        }
        else{
            console.log(data);
            data.newPassword=document.getElementById('password').value; 
 
        let formData = new FormData();

        for(let key in data){
            formData.append(key, data[key]);
        }
    
        //Get webservice path
        let getForgetPasswordURL = servicePath({
            ...GLOBAL_CONFIG.ServiceSettings,
            campaign: this.state.promotionCampaign,
            status: 'finalizeResetPassword'
        });

        //Ajax call via axios
        axios({
            method: 'post',
            url: getForgetPasswordURL,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' }
        })
        .then((response) => {
            console.log(response);
            if(response.status === 200 || response.status === 201){
                if(response.data.success === true){
                    //Set user participation
                    // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, 'participate'); 
                    // let userStatus = response.data.data.IsLogin? 'win' : 'lose';

                    let loginstatus;
                    //Set user as Winner or Loser
                    sessionStorage.setItem(GLOBAL_CONFIG.Session.loginstatus, loginstatus);   

                    // //SET Participation ID
                    // window.PARTICIPATION_ID = response.data.ParticipationId;

                    // Datalayer push for Success participation
                    // this.triggerDatalayers({
                    //     status: 'success',
                    //     participationID: response.data.ParticipationId
                    // });
                    this.setState({
                       passwordSuccessChange:true
                    });
                    //Go to Thank you page
                    //this.props.history.push({pathname: GLOBAL_CONFIG.Route.participation});

                } else {
                    let errorStatus = response.data.data.Error[0];
                   
                    if(errorStatus === 'Authorization has been denied for this request.'){
                        console.log('Participation error');

                        this.setState({
                            loaderSubmit: false,
                            authorization:true
                        });

                        // this.triggerDatalayers({
                        //     status: 'error',
                        //     type: 'participate'
                        // });
                    } else {
                        if(errorStatus === 'OldPassword is wrong'){
                            console.log('Captcha error message');
                            this.setState({
                                wrongOLdPassword:true
                            })
                            //Datalayer push for Error
                            // this.triggerDatalayers({
                            //     status: 'error',
                            //     type: 'general'
                            // });
                        } else if(errorStatus === 'AN_ERROR_HAS_OCCURED'){
                            console.log('An error has occured');
                        }

                        this.setState({
                            loaderSubmit: false,
                            loaderGeneralError: true
                            
                        });
                    }

                    // this.recaptcha.reset();     //Reset captcha when error occurs - deploy
                }
            } else {
                //Show error messages
                console.log('Error 404 or 500');
                //Close Loader
                this.setState({
                    loaderSubmit: false,
                    loaderGeneralError: true
                });

                // this.recaptcha.reset();     //Reset captcha when error occurs
            }
        })
        .catch((response) => {
            //this.props.history.push({pathname: GLOBAL_CONFIG.Route.changePassword});
            //handle error
            // console.log(response);
            // this.retrieveToken()           // //Close Loader
            this.setState({
                loaderSubmit: true,
                loaderGeneralError: true,
            });

            // this.recaptcha.reset();     //Reset captcha when error occurs
        });
    }
    }
    render() {
        //Loader rendering for form submit and error handling
        let submitLoader = null,
            loaderGeneralError = null,
            errorLoaderGeneral = null,
            siteKey = null,
            loaderSubmit = null,
            message=null,
            wrongOLdPassword=null,
            authorization=null,
            passwordSuccessChange=null
            //paraElement = null;
        if(this.state.message=== true){
           message=(
            <div>
                <p className="error-msg">Passwörter stimmen nicht überein!</p>
            </div>);
        }
        if(this.state.wrongOLdPassword=== true){
            wrongOLdPassword=(
             <div>
                 <p className="error-msg">falsches Passwort</p>
             </div>);
         }
        if(this.state.loaderSubmit === true){
            submitLoader = (
                <div className="simple-loader__container active">
                    <div className="simple-loader__indicator"></div>
                </div>  
            );
        }
        
        if(this.state.loaderGeneralError === true){ 
            loaderGeneralError = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                        <p id="js-error-msg">Ein Fehler ist aufgetreten. Bitte versuchen <br />Sie es später noch einmal.</p>
                    </div>
                </div>
            );
        }
        if(this.state.passwordSuccessChange === true){ 
            passwordSuccessChange = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                        <p id="js-error-msg">Dein Passwort wurde
erfolgreich geändert.</p>
                    </div>
                </div>
            );
        }
        if(this.state.authorization === true){ 
            authorization = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                        <p id="js-error-msg">
                            Die Autorisierung für diese Anfrage wurde verweigert</p>
                    </div>
                </div>
            );
        }
        return(
            <div>
                 <div className="profile_detail password_change_email">
                    <div className="account content-upload-receipt form-line row ">
                        <div className="content-upload-receipt form-container form-line row ">
                            <div className="form-container">
                            <form id="frm_resetPassword" name="frm_resetPassword" method="post" action="/" onSubmit={this.onSubmitHandler} noValidate>
                                <div className="input-container resetPassword">
                                    <h2 className="title">Passwort vergessen</h2>
                                    <div className="input-container">
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="password">Passwort*</label>
                                            <input className="form-input__input js-event-type-in js-event-ab" type="password" name="password" id="password" placeholder="Passwort*" data-require="true" data-type="regex" data-error-target="#error-password" data-regex-pattern=".{8,}"  data-required-message="Passwort wird benötigt" data-pattern-message="Das Passwort muss aus 8 Zeichen bestehen" data-event-fieldname="Password" data-event="form-click" data-category="Form Action" data-action="Password" data-label="Type In" />
                                            <span id="error-password" className="form-input__error active"></span>
                                        </div>
                                    </div>
                                    <div className="input-container">
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="confirmPassword">Confirm Passwort*</label>
                                            <input className="form-input__input js-event-type-in js-event-ab" type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm Passwort*" data-require="true" data-type="regex" data-error-target="#error-confirmPassword" data-regex-pattern="" data-required-message="Passwort wird benötigt" data-pattern-message="" data-event-fieldname="Password" data-event="form-click" data-category="Form Action" data-action="Password" data-label="Type In" />
                                            <span id="error-confirmPassword" className="form-input__error active"></span>
                                        </div>
                                    </div>
                                    {message}
                                    <div className="form-button">
                                        <button className="btn__wrapper" type="submit">
                                            <div className="btn__container">
                                                <span className="btn__text">Passwort zurücksetzen</span>
                                            </div>
                                            <p>&nbsp;</p>
                                        </button>
                                    </div>      
                                </div>
                                </form>
                            </div>
                        </div>
                </div>   
               
               {authorization}
               {loaderGeneralError}
               {loaderSubmit}
               {passwordSuccessChange}
               </div>
            </div>
        );
     }
}

export default withTranslation()(withRouter(ResetPassword));