import React from "react";
import BuzzerSlider from "../Buzzer/Slider";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { mainLoaderToggle } from "../../../modules/helpers";
import ValidateForm from "../../../modules/validate";
import ParticipationService from "../../../Service/Participation";
import { withCookies } from 'react-cookie';
import Loader from "../../../components/Overlays/Loader";
import Checkbox from "../../../components/Controls/Checkbox";
import Aux from "../../../hoc/Auxiliare";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import appGoogleTracking from '../../../modules/googletracking';

class TicketsPromoHolding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEmailAlredySent: false,
            simpleLoader: false,
            isMobile: false,
            newsletterCheckbox: null
        }
        this.ProcessForm = this.ProcessForm.bind(this);
        this.resize = this.resize.bind(this);

    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
        mainLoaderToggle('hide');
        const { cookies } = this.props;
        if (cookies.get("_isPress") == "true") {
            this.setState({ isEmailAlredySent: true });
        }
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div>
                {this.headingSection()}
                <Footersocial />
                {
                    this.state.simpleLoader ? <Loader /> : null
                }
            </div>
        );
    }

    headingSection() {
        return (
            <div className="content-holding__heading q3-announment trikot-holding">
                <div className="content-home endpage-rewe">
                    <div className="spaceTile-intropage">
                        <div className="campaign-heading">
                            <h1>Gewinne Bundesliga Tickets</h1>
                            <p>
                                Fußball ist dein Leben und das des FC Milka! Deshalb kannst du ab dem 02.01.2024 mit dem FC Milka 1.000 x 2 Tickets für die Spiele der Bundesliga / 2. Bundesliga gewinnen. Zusätzlich wird ein Bundesliga VIP-Erlebnis für 2 Personen mit Lothar Matthäus inklusive attraktivem Rahmenprogramm verlost.
                            </p>
                        </div>
                    </div>
                    <form id="frm_holding"
                        name="frm_holding"
                        method="post" action="/"
                        ref={form => this._FORM_DETAILS = form}
                        onSubmit={this.SubmitHandler.bind(this)} noValidate>
                        <div className={`remindMe-container ${this.state.isEmailAlredySent ? "sent" : ""}`}>
                            <div className="form__container">
                                <p>
                                Du möchtest den Start des FC Milka Gewinnspiels auf keinen Fall verpassen? Wir erinnern dich rechtzeitig per E-Mail!
                                </p>
                                <div id="firstName" className="input-container">
                                    <div className="form-input__container">
                                        <label className="form-input__label" htmlFor="firstname">Vorname*</label>
                                        <input className="form-input__input js-event-type-in js-event-ab"
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            placeholder="Vorname"
                                            data-require="true"
                                            data-type="regex"
                                            data-error-target="#error-firstname"
                                            data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                                            data-required-message="Bitte gib deinen Vornamen ein."
                                            data-pattern-message="Vorname ungültig."
                                            data-event-fieldname="Firstname"
                                            data-event="custom_event_click"
                                            data-category="form_action"
                                            data-action="first_name"
                                            data-label="type_in_announcement"
                                            ref={input => this._DETAILS_firstName = input}
                                            /* onClick={appGoogleTracking.processEventCTANavLink} */
                                        />
                                        <span id="error-firstname" className="form-input__error active"></span>
                                    </div>
                                </div>
                                <div id="email-container" className="input-container">
                                    <div className="form-input__container">
                                        <label className="form-input__label" htmlFor="email">Email*</label>
                                        <input className="form-input__input js-event-type-in js-event-ab"
                                            type="text"
                                            name="email"
                                            id="email"
                                            placeholder="E-Mail-Adresse eingeben"
                                            data-require="true"
                                            data-type="regex"
                                            data-error-target="#error-email"
                                            data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                            data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                            data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                            data-event-fieldname="Email"
                                            data-event="custom_event_click"
                                            data-category="form_action"
                                            data-action="email"
                                            data-label="email_announcement"
                                            ref={input => this._DETAILS_email = input}
                                            /* onClick={appGoogleTracking.processEventCTANavLink} */
                                        />
                                        <span id="error-email" className="form-input__error active"></span>
                                    </div>
                                </div>
                            </div>

                            <div className="img__container">
                                <picture>
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo.png" media="(min-width: 1024px)" />
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 481px)" />
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 200px)" />
                                    <img src="/resources/imagesBundesliga/buzzer/FCMilka-logo.png" alt="FC Milka Logo" />
                                </picture>
                            </div>
                            {
                                !this.state.isEmailAlredySent ?
                                    (
                                        <Aux>
                                            <Checkbox id="terms"
                                                required={true}
                                                errorMessage={""}
                                                text='Ich möchte den regelmäßigen Newsletter mit zukünftigen Neuigkeiten, Gewinnspielen und Angeboten von Milka per E-Mail erhalten. Damit die Kommunikation noch besser auf meine Bedürfnisse abgestimmt werden kann, bin ich neben der Anmeldung für den Newsletter auch damit einverstanden, dass meine Daten inklusive meiner verschlüsselten E-Mail-Adresse für Folgendes verwendet werden: Bildung von Profilen, Analyse-Zwecke, Verknüpfung und Anreicherung mit Browserdaten sowie auf mich abgestimmte Werbung. Zu diesen Zwecken dürfen meine Daten von Milka auch an weitere Tochtermarken von Mondelez International (u.a. Oreo, Philadelphia, TUC, Miracel Whip u.a.), Partner, Dienstleister oder Plattformen Dritter weitergegeben werden, welche die Daten ausschließlich für Mondelez International und nach deren Weisung verarbeiten.<br><br>
Wir schätzen Dein Vertrauen, wenn Du Deine persönlichen Daten mit uns teilst. Wir behandeln Deine Daten stets fair und respektvoll, beschränkt auf den oben genannten Zweck. Wenn Du mehr darüber erfahren möchten, wie wir mit Deinen Daten umgehen, lies bitte unsere <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzerklärung</a>. 
                                                '
                                                onChangeHandler={!this.state.isEmailAlredySent ? this.CheckboxChangeHandler.bind(this) : () => { }}
                                                /* onClick={()=>{
                                                    appGoogleTracking.dataLayerPush({
                                                        dataEvent: "custom_event_click",
                                                        dataCategory: "click_action",
                                                        dataAction: "give_consent",
                                                        dataLabel: "newsletter_consent_announcement",
                                                    });
                                                }} */
                                            />
                                            <button 
                                                type="submit" 
                                                className={this.state.isEmailAlredySent ? "btn__container btn--lilac btn-desabled" : "btn__container btn--lilac"} 
                                                disabled={this.state.isEmailAlredySent}
                                                data-event="custom_event_click"
                                                data-category="click_action"
                                                data-action="subscribe"
                                                data-label="newsletter_subscription_announcement"
                                                /* onClick={appGoogleTracking.processEventCTANavLink} */
                                            >
                                                ERINNERE MICH 
                                            </button>
                                        </Aux>
                                    )
                                    :
                                    (
                                        <p className="email-sent">Deine Mail Adresse wurde erfolgreich gespeichert. Wir erinnern dich, sobald die FC Milka Aktion beginnt.</p>
                                    )
                            }
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    CheckboxChangeHandler(event, _ref) {
        if (_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if (_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
            this.setState({ newsletterCheckbox: true });
        }else {
            this.setState({ newsletterCheckbox: null });
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    SubmitHandler(event) {
        event.preventDefault();
        if (this.state.isEmailAlredySent) {
            return null;
        }
        const isValid = ValidateForm.validateForm('frm_holding');

        if (isValid) {
            this.ProcessForm();
        } else {
            /**
             * Scroll To first error
             */
            let firstError = this._FORM_DETAILS.querySelector(".notvalid");
            if (!!firstError) {
                const yOffset = - 110,
                    y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }
    }

    ProcessForm() {
        const { cookies } = this.props;
        let _data = {
            email: this._DETAILS_email.value,
            firstName: this._DETAILS_firstName.value,
            retailer: null
        }
        this.setState({
            simpleLoader: true
        })
        ParticipationService.SendMailTickets(_data)
            .then((response) => {
                let { success } = response.data;
                this.setState({
                    simpleLoader: false
                })
                console.log(response);
                if (success) {
                    this.setState({ isEmailAlredySent: true });
                    const expireDate = new Date();
                    expireDate.setMonth(expireDate.getMonth() + 3);
                    cookies.set("_isPress", "true", { path: "/", expires: expireDate });
                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 'remind_me',
                        'dataCategory': 'Announcement Page',
                        'dataAction': 'Click on Remind Me',
                        'dataLabel': 'ENG_AP_FORM'
                    });
                    this.state.newsletterCheckbox && appGoogleTracking.dataLayerPush({
                        'dataEvent': 'newsletter_checkbox',
                        'dataCategory': 'Announcement Page',
                        'dataAction': 'Click on Checkbox',
                        'dataLabel': 'ENG_AP_FORM'
                    });
                } else {
                    console.log("KO");
                }
            })
            .catch((error) => {
                this.setState({
                    simpleLoader: false
                })
                console.log("catch : KO", error);
            });
    }

    sliderSection() {
        return (
            <div>
                <BuzzerSlider sliderName="Page.Holding.sliderBuzzer" />
            </div>
        );
    }

    footerSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <p>
                    *Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga / 2. Bundesliga. Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen, und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID-19-Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation. Maximal ein Hauptpreis pro Person.
                </p>

                {
                    this.state.isMobile && (
                        <a href="https://www.milka.de/alle-produkte" target="_blank">
                            <button className="btn__container btn--red"> MEHR ENTDECKEN </button>
                        </a>
                    )
                }

            </div>
        )
    }
}

export default withCookies(TicketsPromoHolding);