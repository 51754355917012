import React from 'react';
import GLOBAL_CONFIG from '../../../config/config';
import { isPennyMicrosite } from '../../../modules/helpers';
import SupercupGlobus from '../../Campaign/Supercup/Globus/Main';
import SupercupFAQGlobus from '../../Campaign/Supercup/Globus/Faq';
import _STATUS from '../../../Models/ParticipationSteps';
import SupercupParticipationGlobus from '../../Campaign/Supercup/Globus/Participation';
import SupercupConfimrationGlobus from '../../Campaign/Supercup/Globus/Confirmation';
import SupercupEndGlobus from '../../Campaign/Supercup/Globus/End';

const getGlobusMicroSiteRouting = (context, props) => {
  const pagesMap = [
    {
      path: GLOBAL_CONFIG.Route.globusMicroSite.main,
      Component: () => <SupercupGlobus  loadGlobusCampaign={()=> { context.loadGlobusCampaign(true) }} />
    },
    {
      path: GLOBAL_CONFIG.Route.globusMicroSite.faq,
      Component: () => <SupercupFAQGlobus  loadGlobusCampaign={()=> { context.loadGlobusCampaign(true) }}/>,
    },
    {
      path: GLOBAL_CONFIG.Route.globusMicroSite.participation,
      Component: () => <SupercupParticipationGlobus 
        loadGlobusCampaign={()=> {
            context.loadGlobusCampaign(true)
        }}
        UpdateParticipationStatus={() => {
            context.ParticipationUpdate(_STATUS.MicroGlobus.participated);
            props.history.push(GLOBAL_CONFIG.Route.globusMicroSite.confirmation);
        }}/>,
    },
    {
        path: GLOBAL_CONFIG.Route.globusMicroSite.end,
        Component: () => <SupercupEndGlobus loadGlobusCampaign={()=> { context.loadGlobusCampaign(true) }} />,
    },
  ];

  if(context.state.ParticipationStatus === _STATUS.MicroGlobus.participated){
    pagesMap.push(
        {
            path: GLOBAL_CONFIG.Route.globusMicroSite.confirmation,
            Component: () => <SupercupConfimrationGlobus />,
        }
    );
  }
  
  console.log(pagesMap);

  return pagesMap;
}

export default getGlobusMicroSiteRouting;
