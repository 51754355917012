import React from "react";
import { NavLink } from "react-router-dom";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import { mainLoaderToggle } from "../../../modules/helpers";
import JerseyNavigation from "./JerseyNavigation";

class JerseyMain extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-jersey">
                { this.HeadingSection() }
                <hr className="separator" />
                { this.FooterSection() }
                <Footersocial />
            </div>
        )
    }

    HeadingSection() {
        return (
            <div>
                {/* <JerseyNavigation />
                <TitleLogo /> */}
                <div className="spaceTile-intropage fancenter-main">
                    <div className="campaign-heading">
                        <h3 className="lilac recoleta-medium">Mögliche Gewinne</h3>
                        {/* <picture>
                            <source srcSet="/resources/imagesBundesliga/fanCenter/prizes.png" media="(min-width: 1024px)" />
                            <source srcSet="/resources/imagesBundesliga/fanCenter/prizes.png" media="(min-width: 481px)" />
                            <source srcSet="/resources/imagesBundesliga/fanCenter/prizes-mobile.png" media="(min-width: 200px)" />
                            <img src="/resources/imagesBundesliga/fanCenter/prizes.png" alt="Prizes" />
                        </picture> */}
                        
                        <div className="ticket-wrapper">
                            <div className="ticket-card">
                                <img src="/resources/imagesBundesliga/fanCenter/vip-tickets.png" alt="vip-tickets"></img>
                                <h2>VIP-Tickets</h2>
                            </div>
                            <div className="ticket-card">
                                <img src="/resources/imagesBundesliga/fanCenter/stardard-ticket.png" alt="vip-tickets"></img>
                                <h2>CAT2 Tickets</h2>
                            </div>
                            <div className="ticket-card">
                                <img src="/resources/imagesBundesliga/fanCenter/Trikots-2023.png" alt="tickets"></img>
                                <h2>Signierte und unsignierte original Heimtrikots der Bundesliga / 2. Bundesliga</h2>
                            </div>
                        </div>


                        <p className="lilac">
                            Wenn zarte Milka Alpenmilch Schokolade auf spannende Bundesliga Spiele trifft,
                            dann heißt das für dich: Volltreffer! Denn zum Anpfiff unserer Partnerschaft mit der
                            Bundesliga / 2. Bundesliga hast du mit dem FC Milka die Chance auf diese ganz
                            besonderen Gewinne.                        
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    FooterSection() {
        return (
            <div className="prizeRewe campaign-footer fancenter-main-footer">
                <h2>Du hast schon einen Gewinncode?</h2>
                <h3>Wow! Wir gratulieren dir zu deinem FC Milka Fußball-Gewinn. Du bist nur noch wenige Klicks von deinem Gewinn entfernt. </h3>
                <NavLink className="no-decoration" to={GLOBAL_CONFIG.Route.fanCenter.codeEntry.user}>
                    <button className="btn__container btn--red">
                        Code einlösen
                    </button>
                </NavLink>
            </div>
        );
    }
}

export default JerseyMain;