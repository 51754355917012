import React from "react";
import GLOBAL_CONFIG from "../../../config/config";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { mainLoaderToggle } from "../../../modules/helpers";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import {withRouter} from 'react-router-dom';
import Aux from "../../../hoc/Auxiliare";
import WinnerLayout from "../../WinnerLayout/WinnerLayout";
import PopUp from "../../../components/Content/PopUp/PopUp";
import _LOCAL from "../../../Models/LocalStorageKeys";

class Q3Confirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile : false,
            isWinner : null,
            isAnimationEnd : true,
            isPressed : false,
            winnerType : null,
            rewePopup: false
        }

        this.resize = this.resize.bind(this);
        this.reloadBuzzerVideo = this.reloadBuzzerVideo.bind(this);
        this.playGame = this.playGame.bind(this);
        this.headingSection = this.headingSection.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getSubTitle = this.getSubTitle.bind(this);
        this.getParagraphe = this.getParagraphe.bind(this);
        this.participationConfirmation = this.participationConfirmation.bind(this);
        this.noWin = this.noWin.bind(this);
        this.headingSection90min = this.headingSection90min.bind(this);
        this.winnersection = this.winnersection.bind(this);
    }

    componentDidMount() {
        const _LOCAL = {
            status: "__STAT",
            statusFan: "__STAT_FAN",
            data: "__DAT"
        }
        mainLoaderToggle('hide');
        window.addEventListener("resize", ()=> {
            this.resize();
        });
        this.resize();
        this.displayPopup();
        if(localStorage.getItem(_LOCAL.data) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.home);
        }else{
            const data = JSON.parse(localStorage.getItem(_LOCAL.data));
            this.setState({
                isWinner : data.Winner,
                winnerType : data.PrizeWon
            });
        }
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    displayPopup(){
        setTimeout(() => {
          this.setState({
            rewePopup: true
          })
        }, 2000)
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 1023 });
        this.reloadBuzzerVideo();
    }

    reloadBuzzerVideo () {
        const video = document.getElementById("buzzer-video");
        if(video){
            video.pause();
            video.load();
        }
    }

    playGame() {
        if(this.state.isPressed){
            return;
        }
        this.setState({
            isPressed : true
        });

        var video = document.getElementById("buzzer-video");
        video.play();
        setTimeout(()=>{
            this.setState({
                isAnimationEnd : true
            });
        }, 3000);
    }

    headingSection () {
        const path = "/resources/imagesBundesliga/buzzer_22/video/";
        const videos = {
            winner:{
                desktop : path + "winner_main.mp4",
                mobile : path + "winner_main-sm.mp4"
            },
            loser : {
                desktop : path + "loser_main.mp4",
                mobile : path + "loser_main-sm.mp4"
            }
        }
        let videoSrc = this.state.isWinner ? videos.winner.desktop : videos.loser.desktop;
        if(this.state.isMobile){
            videoSrc = this.state.isWinner ? videos.winner.mobile : videos.loser.mobile;
        }
    
        return (
            <Aux>
                <div className={`content-home endpage-rewe content-game ${this.state.isAnimationEnd && !this.state.isWinner ? "loser" : null}`}>
                    <video playsinline id="buzzer-video" onClick={()=>{
                        this.playGame();
                    }}>
                        <source src={ videoSrc } type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                    <div className="content-game__copy" onClick={()=>{
                        this.playGame();
                    }}>
                        <div className="content-game__text">
                            {this.getTitle()}
                            {this.getSubTitle()}
                            {this.getParagraphe()}
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }

    headingSection90min () {
        if(this.state.isWinner && this.state.winnerType){
            return (
                this.winnersection(this.state.winnerType)
            );
        }else if(this.state.isWinner === false){
            return (
                this.noWin()
            );
        }
    
        return (
            this.participationConfirmation()
        );
    }

    getTitle (){
        if(this.state.isAnimationEnd && this.state.isWinner){
            return (
                <h1>
                    Herzlichen Glückwunsch!
                </h1>
            );
        }else if(this.state.isAnimationEnd && !this.state.isWinner){
            return (
                <h1>
                    Oh, so ein Pech, leider hast du keine Meisterschale gefunden, diesmal hast du leider nichts gewonnen.
                </h1>
            );
        }
    
        return (
            <h1>
                FINDE DIE MEISTERSCHALE!
            </h1>
        );
    }

    getSubTitle () {
        if(this.state.isAnimationEnd && this.state.isWinner){
            return (
                <h2>Du hast die Meisterschale gefunden.</h2>
            );
        }else if(this.state.isAnimationEnd && !this.state.isWinner){
            return (
                <h2>Aber sei nicht traurig, du kannst dein Glück nochmal versuchen!</h2>
            );
        }
    
        return (
            <h2>
                Unter dem Kreis steckt die Auflösung.
            </h2>
        );
    }

    getParagraphe () {
        if(this.state.isAnimationEnd && this.state.isWinner){
            return (
                <p>
                    Du bist ein Glücksgewinner! Damit sicherst du dir eine Dauerkarte für die Saison 2022/23 für die Bundesliga / 2. Bundesliga. Bitte gib uns 48 Stunden Zeit, deinen Kaufbeleg zu überprüfen. Wenn alles seine Richtigkeit hat, benachrichtigen wir dich per E-Mail mit allen Infos zu deinem Gewinn.
                </p>
            );
        }else if(this.state.isAnimationEnd && !this.state.isWinner){
            return (
                <p>
                    Wir drücken dir die Daumen, dass es beim nächsten Mal klappt. Im Kampagnenzeitraum kannst du mit unterschiedlichen Kassenbons mehrfach teilnehmen. <strong>Darum versuche dein Glück nochmal mit einem neuen Kassenbon!</strong><br/>
                    Und nicht vergessen: Du hast automatisch die zusätzliche Chance am Ende der Aktion 25.000€ in Gold zu gewinnen! Wir wünschen dir viel Glück!
                </p>
            );
        }
        return (
            <p>
                Versuche jetzt dein Glück. Finde die Meisterschale und sichere dir eine Dauerkarte für die Saison 2022/23 der Bundesliga / 2. Bundesliga.
                <br/>  
                <br/>
                Viel Erfolg wünscht Dein FC Milka Team
            </p>
        );
    }

    participationConfirmation () {
        const currentKip = localStorage.getItem(_LOCAL.CurrentKip);
        return (
            <>
                { !!currentKip && currentKip.toLowerCase() === "rewe" && this.state.rewePopup ? <PopUp desktopImg = "/resources/imagesBundesliga/90_min/pop-up.png" mobileImg="/resources/imagesBundesliga/90_min/pop-up-sm.png"/> : null}
                <div className="confirmation-wrapper">
                    <div className="confirmation-text">
                        <h2>
                            Vielen Dank für deine Teilnahme!
                        </h2>
                        <p>
                        Die Spannung steigt! Du hast einen richtigen Produktcode eingegeben. Damit bist du vielen tollen Bundesliga Preisen einen großen Schritt näher. Du erhältst jetzt von uns eine Bestätigungsmail, in der wir dir dein 90 Minuten Gewinn-Zeitfenster für morgen nennen. 
                        </p>
                        <p>Wir wünschen dir viel Glück!</p>
                    </div>
                </div>
                {this.confirmationPageBoxes()}
            </>
        )
    }

    noWin () {
        return (
            <>
                <div className="no-win-wrapper">
                    <div className="no-win-text">
                        <h2>
                            Heute hast du leider nicht gewonnen!
                        </h2>
                        <p>
                            Sei aber nicht traurig. Mit einem neuen Produktcode hast du wieder die Chance, an unserer Aktion teilzunehmen. Wir drücken dir die Daumen, dass es beim nächsten Mal klappt. 
                        </p>
                    </div>
                </div>
                { this.prizesBoxes() }
            </>

        )
    }
    
    winnersection (winnertype) {
        switch(winnertype) {
            case "CAT2 tickets":
              return (
                <>
                    <WinnerLayout
                        title= {<>Du hast zwei Tickets der Kategorie 2 (Cat2) für ein Spiel der
                        Bundesliga / 2. Bundesliga der Saison 2022/23 gewonnen.* Wir schicken dir umgehend eine Mail mit Informationen zu deinem Gewinn.</>}
                        subtitle="*(Werte variieren je nach Club. Auswahl nach Verfügbarkeit über das
                            FC Milka Fan-Center. Es besteht kein Anspruch auf Clubauswahl
                            und/oder Bundesliga oder 2. Bundesliga)"
                        imgSrc='/resources/imagesBundesliga/90_min/prizes/layout/ticket.png'
                    />
                    { this.prizesBoxes() }
                </>          

              );
            case "Tricot signed":
              return (
                <>
                    <WinnerLayout
                        title= {<>Du hast ein signiertes original Bundesliga Heimtrikot gewonnen.* Wir schicken dir umgehend eine Mail mit Informationen zu deinem Gewinn.</>}
                        subtitle="*(Werte der Trikots variieren je nach Club. Auswahl nach Verfügbarkeit über das FC Milka Fan-Center)."
                        imgSrc='/resources/imagesBundesliga/90_min/prizes/layout/trikot.png'
                    />
                    { this.prizesBoxes() }
                </>          
              );
            case "Tricot unsigned":
              return (
                <>
                    <WinnerLayout
                        title= {<>Herzlichen Glückwunsch. Du hast ein original Bundesliga Heimtrikot gewonnen.* Wir schicken dir umgehend eine Mail mit Informationen zu deinem Gewinn.</>}
                        subtitle="*(Werte der Trikots variieren je nach Club. Auswahl nach Verfügbarkeit über das FC Milka Fan-Center)."
                        imgSrc='/resources/imagesBundesliga/90_min/prizes/layout/trikot.png'
                    />
                    { this.prizesBoxes() }
                </>          
              );
            case "Bundesliga Match Attax Starter Pack":
              return (
                <>
                    <WinnerLayout
                        title= {<>Herzlichen Glückwunsch. Du hast ein <span class="winner-code">Bundesliga Match Attax Starterpack</span> gewonnen.* Wir schicken dir umgehend eine Mail mit Informationen zu deinem Gewinn.</>}
                        subtitle="*(Wert ca. 8,99 €, einzulösen über digitalen Code auf topps.com)"
                        imgSrc='/resources/imagesBundesliga/90_min/prizes/layout/starter_pack.png'
                    />
                    { this.prizesBoxes() }
                </>          
              );
            default:
              return null;
          }

    }

    confirmationPageBoxes () {
        return (
            <div className="s-content-end">
                <div className="end-element">
                    <div className="end-element__item">
                        <img src="/resources/imagesBundesliga/buzzer/end/fancenter-box-3.png" />
                        <div className="end-element__copy">
                                <h3>Neues von Milka</h3>
                                <p>Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.</p>
                            <a href="https://www.fcmilka.de/account" target="_blank">
                                <button className="btn__container btn--secondary-lilac">NEUES ENTDECKEN</button>
                            </a>
                        </div>
                    </div>


                    <div className="end-element__item">
                        <img src="/resources/imagesBundesliga/90_min/confirmation/box-2.png" />
                        <div className="end-element__copy">
                                <h3>Zeit für mehr Empathie!</h3>
                                <p>
                                Mit Milka wird die Welt noch zarter. Schicke kleine Botschaften, die von Herzen kommen. 
                                </p>
                            <a href="https://www.fcmilka.de/account" target="_blank">
                                <button className="btn__container btn--secondary-lilac">ZARTE BOTSCHAFT</button>
                            </a>
                        </div>
                    </div>


                    <div className="end-element__item">
                        <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                        <div className="end-element__copy">
                                <h3>Nervennahrung nötig?</h3>
                                <p>
                                Dann schau doch mal in unserem Shop vorbei.
                                </p>
                            <a href="https://www.fcmilka.de/account" target="_blank">
                                <button className="btn__container btn--secondary-lilac">ZUM SHOP</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    prizesBoxes() {
        return (
            <div className="s-content-end">
                <div className="end-element">
                    <div className="end-element__item">
                        <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                        <div className="end-element__copy">
                                <h3>FC Milka Mitglied werden</h3>
                                <p>Jetzt kostenlos registrieren und viele Vorteile genießen. Sei dabei und melde dich an.</p>
                            <a href="https://www.fcmilka.de/account" target="_blank">
                                <button className="btn__container btn--secondary-lilac"> REGISTRIEREN </button>
                            </a>
                        </div>
                    </div>


                    <div className="end-element__item">
                        <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                        <div className="end-element__copy">
                                <h3>Nervennahrung nötig?</h3>
                                <p>
                                    Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                                </p>
                            <a href="https://www.fcmilka.de/account" target="_blank">
                                <button className="btn__container btn--secondary-lilac"> SCHOKI SHOPPEN </button>
                            </a>
                        </div>
                    </div>


                    <div className="end-element__item">
                        <img src="/resources/imagesBundesliga/buzzer/end/fancenter-box-3.png" />
                        <div className="end-element__copy">
                                <h3>Neues von Milka</h3>
                                <p>
                                    Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                </p>
                            <a href="https://www.fcmilka.de/account" target="_blank">
                                <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    render(){
        return (
            <div id="game-buzzer">
                { this.headingSection90min() }
                <Footersocial />
            </div>
        );
    }
}

export default withRouter(Q3Confirmation);