import React, { Component } from 'react'; 
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import GLOBAL_CONFIG from '../../../config/config';
import appGoogleTracking from '../../../modules/googletracking';
import { mainLoaderToggle } from '../../../modules/helpers';
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial';

class DoppeltEnd extends Component {
    
    componentDidMount(){
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');

        //Virtual page
/*         appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': GLOBAL_CONFIG.Route.end,
                'pageTitle': t('Meta.title')
            }
        }); */
    }

    componentWillUnmount(){
        //Show mainloader
        mainLoaderToggle('show');
    }

    render() { 

        const { t } = this.props;

        return (
            <div className="content-holding__heading closing weltmeister-end trikot-end doppelt-end">
                <div className="content-end__text">
                    <h1>
                        diese aktion ist beendet.
                    </h1>
                    <p>
                    Freu dich auf viele weitere FC Milka Aktionen, die in Kürze  kommen werden!
                    </p>
                </div>
                <div className="s-content-end">

                    
                    <div className="end-element">
                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/doppeltgewinnen/end/teaser-1.png" />
                            <div className="end-element__copy">
                                    <h3>100% Genuss</h3>
                                    <p>Milka Mmmax Großtafeln bieten dir mehr Schokolade, großzügige Zutaten und multidimensionale Geschmackserlebnisse.</p>
                                <a href="https://fcmilka.de/shop" target="_blank" exact data-event="buy_online" data-category="End Page" data-action="Go to Page" data-label="ENG_EP_OTHER" onClick={appGoogleTracking.processEventCTANavLink}>
                                    <button className="btn__container btn--secondary-lilac"> JETZT ENTDECKEN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/doppeltgewinnen/end/teaser-2.png" />
                            <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                    Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.</p>
                                <a href="https://www.milka.de/neues" target="_blank" exact data-event="explore_new" data-category="End Page" data-action="Go to Page" data-label="ENG_EP_OTHER" onClick={appGoogleTracking.processEventCTANavLink}>
                                    <button className="btn__container btn--secondary-lilac">NEUES ENTDECKEN</button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/doppeltgewinnen/end/teaser-3.png" />
                            <div className="end-element__copy">
                                    <h3>Milka Engagement</h3>
                                    <p>
                                    Du möchtest mehr über das Engagement von Milka im Kakaoanbau erfahren? Oder wissen, warum Milka so zart ist?</p>
                                <a href="https://www.milka.de/uber-milka" target="_blank" exact data-event="more_about_MILKA" data-category="End Page" data-action="Go to Page" data-label="ENG_EP_OTHER" onClick={appGoogleTracking.processEventCTANavLink}>
                                    <button className="btn__container btn--secondary-lilac">MEHR ÜBER MILKA</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
{/*                 <div class="prizes-question-faq">              
                    <h2>
                    Noch Fragen zu FC Milka?
                    </h2>
                    <p>
                    Hier geht es zu den FAQ
                    </p>
                </div> */}
                <Footersocial />
            </div>
        );
    }
  
}

export default withTranslation()(DoppeltEnd);