import React from 'react';
import Aux from '../../../hoc/Auxiliare';
import { NavLink, useLocation } from 'react-router-dom';
import GLOBAL_CONFIG from '../../../config/config';
import { isGlobusMicrosite } from '../../../modules/helpers';
/*
    Contains the step that a user must do to participate in the campaign
*/

const StepContent = (props) => {

    let contenStep = null,
        _CAMPAIGN_STATUS = window.PROMOTION;
    const location = useLocation();

    let pathname = location.pathname,
        pathnameLower = pathname.toLocaleLowerCase();

        pathnameLower = pathnameLower.replace(/[\/\\]+$/,'');
    
    /**
     * Buzzer Steps
     */
     if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase()
     || pathnameLower === GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase()
     || pathnameLower === GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase()
     || (pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.main.toLowerCase() 
        || (pathnameLower === GLOBAL_CONFIG.Route.home.toLowerCase() && isGlobusMicrosite() )
        || (pathnameLower === "" && isGlobusMicrosite() )
        )
     || pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.participation.toLowerCase()
     || pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase()
     || pathnameLower === GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase()
     || pathnameLower === GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase()
     || pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.participation.toLowerCase()
     
 ) {
     contenStep = (
         <Aux>
             <div className="s-content-steps__item first">
                 <picture>
                     <source srcSet={props.step1} media="(min-width: 1024px)" />
                     <source srcSet={props.step1sm} media="(min-width: 481px)" />
                     <source srcSet={props.step1sm} media="(min-width: 200px)" />
                     <img width="119px" src={props.step1} alt="Milka product" />
                 </picture>
                 <p>
                     {props.step1Text}
                 </p>
             </div>
             <div className="s-content-steps__item second">
                 <picture>
                     <source srcSet={props.step2} media="(min-width: 1024px)" />
                     <source srcSet={props.step2sm} media="(min-width: 481px)" />
                     <source srcSet={props.step2sm} media="(min-width: 200px)" />
                     <img width="119px" className="step_image step_image--website" src={props.step2} alt="Milka product" />
                 </picture>
                 <p>
                     {props.step2Text}    
                 </p>
             </div>
             <div className="s-content-steps__item third">
                 <picture>
                     <source srcSet={props.step3} media="(min-width: 1024px)" />
                     <source srcSet={props.step3sm} media="(min-width: 481px)" />
                     <source srcSet={props.step3sm} media="(min-width: 200px)" />
                     <img width="119px" className="step_image step_image--ball" src={props.step3} alt="Milka product" />
                 </picture>
                 <p> 
                     {props.step3Text}
                 </p>
             </div>
             {
                 props.step4 && (
                     <div className="s-content-steps__item fourth">
                         <picture>
                             <source srcSet={props.step4} media="(min-width: 1024px)" />
                             <source srcSet={props.step4sm} media="(min-width: 481px)" />
                             <source srcSet={props.step4sm} media="(min-width: 200px)" />
                             <img width="119px" className="step_image step_image--thumb" src={props.step4} alt="Milka product" />
                         </picture>
                         <p>
                             {props.step4Text}
                         </p>
                     </div>
                 )
             }
         </Aux>
     )
    } else  if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.participate.toLowerCase()
        || pathnameLower === GLOBAL_CONFIG.Route.participation.toLowerCase()
    ) {
        contenStep = (
            <Aux>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step1} media="(min-width: 1024px)" />
                        <source srcSet={props.step1sm} media="(min-width: 481px)" />
                        <source srcSet={props.step1sm} media="(min-width: 200px)" />
                        <img width="119px" src={props.step1} alt="Milka product" />
                    </picture>
                    <p>
                        Kaufe zwischen dem <span className="text--wheat">11.04.2022</span> und dem <span className="text--wheat">05.06.2022</span> ein Milka Aktionsprodukt. <strong>(Bitte den gut lesbaren <span className="text--wheat">Kaufbeleg</span> aufbewahren.)</strong>
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step2} media="(min-width: 1024px)" />
                        <source srcSet={props.step2sm} media="(min-width: 481px)" />
                        <source srcSet={props.step2sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--website" src={props.step2} alt="Milka product" />
                    </picture>
                    <p>
                    Lade <a href={GLOBAL_CONFIG.Route.buzzer.participate}>hier</a> bis zum <span className="text--wheat">03.07.2022</span> den <span className="text--wheat">Kaufbeleg</span> hoch. (Ein Kaufbeleg pro Teilnahme)                
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step3} media="(min-width: 1024px)" />
                        <source srcSet={props.step3sm} media="(min-width: 481px)" />
                        <source srcSet={props.step3sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--ball" src={props.step3} alt="Milka product" />
                    </picture>
                    <p> 
                    Danach kannst du am Suchspiel teilnehmen. Mit etwas Glück findest du die Meisterschale und gewinnst eine <span className="text--wheat">Dauerkarte</span> für die Bundesliga / 2. Bundesliga.
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step4} media="(min-width: 1024px)" />
                        <source srcSet={props.step4sm} media="(min-width: 481px)" />
                        <source srcSet={props.step4sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--thumb" src={props.step4} alt="Milka product" />
                    </picture>
                    <p>
                        Die Verlosung des Hauptpreises erfolgt am <span className="text--wheat">05.07.2022</span> unter allen übrigen Teilnehmern. 
                        Der oder die Gewinner*in von <span className="text--wheat">1x Gold im Wert von 25.000€</span> wird per Zufallsgenerator ermittelt.
                    </p>
                </div>
            </Aux>
        )
    }
    else if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.main.toLowerCase() 
                || pathnameLower === ""
                || pathnameLower === GLOBAL_CONFIG.Route.home.toLowerCase()
    ) {
        contenStep = (
            <Aux>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step1} media="(min-width: 1024px)" />
                        <source srcSet={props.step1sm} media="(min-width: 481px)" />
                        <source srcSet={props.step1sm} media="(min-width: 200px)" />
                        <img width="119px" src={props.step1} alt="Milka product" />
                    </picture>
                    <p>
                        {props.step1Text}
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step2} media="(min-width: 1024px)" />
                        <source srcSet={props.step2sm} media="(min-width: 481px)" />
                        <source srcSet={props.step2sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--website" src={props.step2} alt="Milka product" />
                    </picture>
                    <p>
                        {props.step2Text}             
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step3} media="(min-width: 1024px)" />
                        <source srcSet={props.step3sm} media="(min-width: 481px)" />
                        <source srcSet={props.step3sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--ball" src={props.step3} alt="Milka product" />
                    </picture>
                    <p> 
                        {props.step3Text}
                    </p>
                </div>
                {props.step4 && (
                    <div className="s-content-steps__item">
                        <picture>
                            <source srcSet={props.step4} media="(min-width: 1024px)" />
                            <source srcSet={props.step4sm} media="(min-width: 481px)" />
                            <source srcSet={props.step4sm} media="(min-width: 200px)" />
                            <img width="119px" className="step_image step_image--thumb" src={props.step4} alt="Milka product" />
                        </picture>
                        <p>
                            {props.step4Text}
                        </p>
                    </div>
                 )}
            </Aux>
        )
    } else if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.faq.toLowerCase()) {
        contenStep = (
            <Aux>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step1} media="(min-width: 1024px)" />
                        <source srcSet={props.step1sm} media="(min-width: 481px)" />
                        <source srcSet={props.step1sm} media="(min-width: 200px)" />
                        <img width="119px" src={props.step1} alt="Milka product" />
                    </picture>
                    <p>
                    

                        Kaufe zwischen dem <span className="text--wheat"> 03.01.2022</span> und dem <span className="text--wheat">27.02.2022</span> ein Milka Produkt <strong>(Bitte Kaufbeleg aufbewahren).</strong>
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step2} media="(min-width: 1024px)" />
                        <source srcSet={props.step2sm} media="(min-width: 481px)" />
                        <source srcSet={props.step2sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--website" src={props.step2} alt="Milka product" />
                    </picture>
                    <p>
                        Gib <a href={GLOBAL_CONFIG.Route.buzzer.participate}>hier</a> den <span className="text--wheat">Produktcode</span> ein. (max. 3 Teilnahmen pro Person/Tag)                    
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step3} media="(min-width: 1024px)" />
                        <source srcSet={props.step3sm} media="(min-width: 481px)" />
                        <source srcSet={props.step3sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--ball" src={props.step3} alt="Milka product" />
                    </picture>
                    <p> 
                        Erstelle – wenn du möchtest – dein persönliches <span className="text--wheat">Star-Selfie</span>.
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step4} media="(min-width: 1024px)" />
                        <source srcSet={props.step4sm} media="(min-width: 481px)" />
                        <source srcSet={props.step4sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--thumb" src={props.step4} alt="Milka product" />
                    </picture>
                    <p>
                            Schon bist du im Gewinner-Lostopf! Nach Ablauf des Gewinnspiels am <span className="text--wheat">27.03.2022</span> teilen wir dir <span className="text--wheat"> anschließend per E-Mail </span> mit, ob du gewonnen hast.
                    </p>
                </div>
            </Aux>
        )
    }
    else {
        contenStep = (
            <Aux>
                <div className="s-content-steps__item">
                    <div className="s-content-steps__item-image oreo-image" >
                        <img src={props.step1} className="" alt="Step 1" />
                    </div>
                    <p className="step-title">Kaufe zwischen dem 12.04. und dem 30.06.2021  in einem Kaufakt <span className="text--wheat"> Milka und/oder OREO Produkte  </span> im Wert von mindestens <span className="text--wheat"> 5 €</span> </p>
                </div>
                <div className="s-content-steps__item step_image__item--website">
                    <div className="s-content-steps__item-image ">
                        <img src={props.step2} className="step_image step_image--website" alt="Step 3" />
                    </div>
                    <p className="step-title">Lade den <span className="text--wheat"> Kassenbon </span>bis zum 30.06.2021 hoch und gib deine persönlichen Daten ein.</p>
                </div>
                <div className="s-content-steps__item step_image__item--ball">
                    <div className="s-content-steps__item-image">
                        <img src={props.step3} className="step_image step_image--ball" alt="Step 3" />
                    </div>
                    <p className="step-title"><span className="text--wheat">Rubbellos</span> auswählen, freirubbeln und direkt erfahren, ob du zu den Champions gehörst.</p>
                </div>
                <div className="s-content-steps__item step_image__item--thumb">
                    <div className="s-content-steps__item-image">
                        <img src={props.step4} className="step_image step_image--thumb" alt="Step 3" />
                    </div>
                    <p className="step-title">Innerhalb von 24 Stunden prüfen wir deinen Kassenbon und teilen dir per <span className="text--wheat">E-Mail</span> mit, was du gewonnen hast. </p>
                </div>
            </Aux>
        );
    }

    if (pathnameLower === GLOBAL_CONFIG.Route.intropage.toLowerCase()) {
        contenStep = (
            <Aux>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step1} media="(min-width: 1024px)" />
                        <source srcSet={props.step1sm} media="(min-width: 481px)" />
                        <source srcSet={props.step1sm} media="(min-width: 200px)" />
                        <img width="119px" src={props.step1} alt="Milka product" />
                    </picture>
                    <p>
                        Kaufe zwischen dem <span className="text--wheat"> 09.08. und dem 17.10.2021 </span>ein Produkt von Milka, TUC und/oder Wunderbar. 
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step2} media="(min-width: 1024px)" />
                        <source srcSet={props.step2sm} media="(min-width: 481px)" />
                        <source srcSet={props.step2sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--website" src={props.step2} alt="Milka product" />
                    </picture>
                    <p>
                        Gebe <a href="#">hier</a> den <span className="text--wheat">Produktcode</span> ein.                    
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step3} media="(min-width: 1024px)" />
                        <source srcSet={props.step3sm} media="(min-width: 481px)" />
                        <source srcSet={props.step3sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--ball" src={props.step3} alt="Milka product" />
                    </picture>
                    <p> 
                        Wähle ein <span className="text--wheat">Bundesliga Spiel</span> des kommenden Spieltages (Samstags- oder Sonntagsspiel). 
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step4} media="(min-width: 1024px)" />
                        <source srcSet={props.step4sm} media="(min-width: 481px)" />
                        <source srcSet={props.step4sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--thumb" src={props.step4} alt="Milka product" />
                    </picture>
                    <p>
                        Drücke den <span className="text--wheat">Buzzer</span> und wähle aus drei zufallsbasierten Spielergebnissen deinen Tipp aus.
                    </p>
                </div>
                <div className="s-content-steps__item">
                    <picture>
                        <source srcSet={props.step5} media="(min-width: 1024px)" />
                        <source srcSet={props.step5sm} media="(min-width: 481px)" />
                        <source srcSet={props.step5sm} media="(min-width: 200px)" />
                        <img width="119px" className="step_image step_image--thumb" src={props.step5} alt="Milka product" />
                    </picture>
                    <p>
                        Ist dein Ergebnis korrekt, bist du im Volltreffer-Lostopf. Nach Ablauf des jeweiligen Spieltages teilen wir dir per <span className="text--wheat">E-Mail</span> mit, ob du gewonnen hast.
                    </p>
                </div>
            </Aux>
        )
    }
    if (pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.main.toLowerCase()) {
        contenStep = (
            <Aux>
             <div className="s-content-steps__item first">
                 <picture>
                     <source srcSet={props.step1} media="(min-width: 1024px)" />
                     <source srcSet={props.step1sm} media="(min-width: 481px)" />
                     <source srcSet={props.step1sm} media="(min-width: 200px)" />
                     <img width="119px" src={props.step1} alt="Milka product" />
                 </picture>
                 <p>
                     {props.step1Text}
                 </p>
             </div>
             <div className="s-content-steps__item second">
                 <picture>
                     <source srcSet={props.step2} media="(min-width: 1024px)" />
                     <source srcSet={props.step2sm} media="(min-width: 481px)" />
                     <source srcSet={props.step2sm} media="(min-width: 200px)" />
                     <img width="119px" className="step_image step_image--website" src={props.step2} alt="Milka product" />
                 </picture>
                 <p>
                     {props.step2Text}    
                 </p>
             </div>
             <div className="s-content-steps__item third">
                 <picture>
                     <source srcSet={props.step3} media="(min-width: 1024px)" />
                     <source srcSet={props.step3sm} media="(min-width: 481px)" />
                     <source srcSet={props.step3sm} media="(min-width: 200px)" />
                     <img width="119px" className="step_image step_image--ball" src={props.step3} alt="Milka product" />
                 </picture>
                 <p> 
                     {props.step3Text}
                 </p>
             </div>
             {
                 props.step4 && (
                     <div className="s-content-steps__item fourth">
                         <picture>
                             <source srcSet={props.step4} media="(min-width: 1024px)" />
                             <source srcSet={props.step4sm} media="(min-width: 481px)" />
                             <source srcSet={props.step4sm} media="(min-width: 200px)" />
                             <img width="119px" className="step_image step_image--thumb" src={props.step4} alt="Milka product" />
                         </picture>
                         <p>
                             {props.step4Text}
                         </p>
                     </div>
                 )
             }
         </Aux>
        )
    }

    return (
        <div className={`s-content-steps ${props.modifier}`}>
            {contenStep}
        </div>
    );
}

export default StepContent;