import React, { Component } from "react";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import parse from "html-react-parser";

class Q4Product extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    return (
      <>
        <div className="content-holding__heading closing product-wrapper weltmeister-product">
          <div className="content-end__text">
            <h1>
                Weltmeisterlicher Schokoladengenuss
            </h1>
            <p>Zarte Vielfalt für deinen Geschmack: Ob Milka Tafelschokolade, Riegel, Pralinen oder vieles mehr – bei Milka findest du bestimmt das Richtige. OREO – Liebe auf den ersten Biss. Der knackige Keks mit den zwei besseren Hälften in vielfältigen Geschmacksrichtungen.</p>
          </div>
          <div className="s-content-end">
            <div className="end-element">
              <div className="end-element__item">
                <img src="/resources/imagesBundesliga/weltmeister/teaser/product/box-1.png" />
                <div className="end-element__copy">
                  <h3>Milka Schokoladenvielfalt</h3>
                  <p>
                  Ganz neu im zarten Sortiment: die Mmmax Fußball Sonderedition Champiolade - nur für kurze Zeit erhältlich.
                  </p>
                  <a href="https://fcmilka.de/account" target="_blank">
                    <button className="btn__container btn--secondary-lilac">
                    mehr von MILKA
                    </button>
                  </a>
                </div>
              </div>

              <div className="end-element__item">
                <img src="/resources/imagesBundesliga/weltmeister/teaser/product/box-2.png" />
                <div className="end-element__copy">
                  <h3>Entdecke unsere OREO Produkte</h3>
                  <p>Hol dir unsere leckeren OREO Produkte mit Kakao Keksen und leckeren Cremefüllung.</p>
                  <a href="https://www.milka.de/shop" target="_blank">
                    <button className="btn__container btn--secondary-lilac">
                    mehr von oreo
                    </button>
                  </a>
                </div>
              </div>

            </div>
          </div>
          <Footersocial />
        </div>
      </>
    );
  }
}

export default Q4Product;
