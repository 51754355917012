import React, { Component } from "react";
class Video extends Component {
  constructor(props) {
    super(props);
    this.vidRef = React.createRef();
    this.state = { playingVideo: false };
  }

  playVideo = () => {
    this.setState({ playingVideo: true });
    this.vidRef.current.play();
  };

  render() {
    return (
      <>
        <picture
          className="Slider__left__wrapper__picture"
          style={{ display: this.state.playingVideo ? "none" : "" }}
        >
          <source media="(max-width: 480px)" srcSet={this.props.imageMobile} />
          <source srcSet={this.props.imageDesktop} />
          <img
            src={this.props.imageDefault}
            alt=""
            class="Slider__left__wrapper__image"
          />
        </picture>
        <video
          preload="auto"
          style={{ display: this.state.playingVideo ? "" : "none" }}
          class="Slider__left__wrapper__video"
          ref={this.vidRef}
          controls="controls"
          src={this.props.videoLink}
          type="video/mp4"
        />
        <button
          style={{ display: this.state.playingVideo ? "none" : "" }}
          onClick={() => this.playVideo()}
          class="Slider__left__wrapper__overlay"
        ></button>
      </>
    );
  }
}

export default Video;
