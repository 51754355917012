import React from "react";
import { NavLink } from "react-router-dom";
import { mainLoaderToggle } from "../../../modules/helpers";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import PopUp from "./PopUp";
import { isProd } from "../../../modules/helpers";

class Charity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    _SCROLL_PAGE.ScrollToTop();
  }

  componentWillMount() {
    setTimeout(() => {
      _SCROLL_PAGE.ScrollToTop();
    }, 200);
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    return (
      <>
        <div id="buzzer-prizes" className="trikot-prizes doppelt-prizes charity">
          <div className="trikot-content-prizes">
            <div className="trikot-promo-container">
              <h3>Doppelt gewinnen – für dich und den guten Zweck</h3>

              <div className="charity-icon">
                <img src="/resources/imagesBundesliga/\doppeltgewinnen/charity-icon.png" alt="charity-icon"/>
              </div>
              <div className="player-wrapper">   
                <div className="player-wrapper__content">   
                  <img src="/resources/imagesBundesliga/\doppeltgewinnen/rudi.png" alt="rudi"/>
                  <h4>
                    Rudi Völlers Charity-Projekt
                  </h4>
                  {/* <p>
                    Doppelt gewinnen – für dich und einen guten Zweck. Dank deiner Teilnahme unterstützen der FC Milka und Rudi Völler die Fußball-Ferien-Freizeiten der DFB-Stiftung Egidius Braun. Für das Freizeitjahr 2024 wird die Völkerverständigung das Schwerpunktthema sein. 1.000 Teilnehmende werden an 18 abwechslungsreichen Programmen teilnehmen und neben verschiedenen Fußballangeboten, Ausflüge in Kletterparks, Besuche verschiedener Bundesliga-Klubs, Wertedialoge sowie Besuche prominenter Gäste erleben.
                  </p> */}
                  <p>
                  Doppelt gewinnen – für dich und einen guten Zweck. Dank deiner Teilnahme
unterstützen der FC Milka und Rudi Völler ein Projekt für einen guten Zweck.
                  </p>
                  <img className="logo-association" src="/resources/imagesBundesliga/\doppeltgewinnen/FuFeFrei_Logo.png" alt="FuFeFrei_Logo"/>
                </div>
                <div class="player-wrapper__content">   
                  <img src="/resources/imagesBundesliga/\doppeltgewinnen/lukas.png" alt="lukas"/>
                  <h4>
                  Lukas Podolskis Charity-Projekt
                  </h4>
                  {/* <p>
                    Doppelt gewinnen – für dich und einen guten Zweck. Dank deiner Teilnahme unterstützt der FC Milka zusammen mit der Lukas Podolski Stiftung das Projekt „Mutmacher“ der Arche. Dieses schenkt Jugendlichen Mut und Hoffnung. Durch Bildung, Workshops und persönliche Betreuung wird das Selbstvertrauen gestärkt und die Selbständigkeit erhöht. Lukas Podolski & Milka schaffen so neue Möglichkeiten für junge Menschen und unterstützen die Chancengleichheit in Deutschland.
                  </p> */}
                  <p>
                  Doppelt gewinnen – für dich und einen guten Zweck. Dank deiner Teilnahme
unterstützt der FC Milka, zusammen mit der Lukas Podolski Stiftung, das Projekt
„Mutmacher“ der Arche.
                  </p>
                  <img className="logo-association" src="/resources/imagesBundesliga/\doppeltgewinnen/LP_Stiftung.png" alt="LP_Stiftung"/>
                </div> 
              </div>

              <div className="cta-container">
                <button
                  className="btn__container btn--secondary-lilac"
                  data-event="begin_participation_bottom"
                  data-category="Charity Page"
                  data-action="Click on Join Now"
                  data-label="GAME_CHARITY_OTHER"
                  onClick={(e) => {
                    this.modalTracking(e);
                  }}
                >
                  jetzt mitmachen
                </button>
              </div>
              <PopUp
                modal={this.state.modal}
                toggleModal={() => {
                  this.toggleModal();
                }}
              />
            </div>
          </div>
          <Footersocial />
        </div>
      </>
    );
  }

  modalTracking(event) {
    this.setState({ modal: true });
    appGoogleTracking.processEventCTANavLink(event);
  }
}

export default Charity;
