import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { timeStamp } from "../../../modules/helpers";
import Countdown, { zeroPad } from "react-countdown";
import GLOBAL_CONFIG from "../../../config/config";
import axios from 'axios';
import queryString from "query-string";
import appGoogleTracking from "../../../modules/googletracking";
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

export class CountDownBanner extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            countdownData: [],
            consumerId : null,
            isWin : false,
            gameLiveRedirection : GLOBAL_CONFIG.Route.ProductCodeVerification
        }

        this.checkConsumerId = this.checkConsumerId.bind(this);
        this.gameLive = this.gameLive.bind(this);
        this.gameNotLive = this.gameNotLive.bind(this);
        this.onClickGameLiveButton = this.onClickGameLiveButton.bind(this);  
    }

    componentDidMount() {
        localStorage.clear();
        const gameState = this.props.gameState;
        this.setState({countdownData: gameState });
        this.checkConsumerId(gameState);
    }

/*     async getCoutdown () {
        const res = await axios.get('/resources/localisation/game_countdown.json');
        const data = await res.data;
        return data;
    }
 */
    checkConsumerId(data){
        const QueryString = queryString.parse(window.location.search);

        if(data.gameIsLive && Object.keys(QueryString).includes("consumerId")) {
            // simulate winner or loser if needed
            const isWinner = QueryString.consumerId === "abcd";
            this.setState({
                consumerId : QueryString.consumerId,
                isWin : isWinner,
                gameLiveRedirection : GLOBAL_CONFIG.Route.buzzer.confirmation
            });
        }
    }

    onClickGameLiveButton(event){
        event.preventDefault();
        const { UpdateParticipationData, UpdateParticipationStatus} = this.props;
        appGoogleTracking.processEventCTANavLink(event);
        if(this.state.consumerId){
            const _data = {
                Winner: this.state.isWin,
                ConsumerId: this.state.consumerId,
            };
            UpdateParticipationData(_data);
            UpdateParticipationStatus();
        } else {
            this.props.history.push(this.state.gameLiveRedirection);
        }   
    }

    gameNotLive ({ hours, minutes, seconds, completed }) {
        if (completed) {
          return window.location.reload();
        } else {
          return (
            <div className= "game-text__container">
                <div className= "game-text__content">
                    <div className="game-title__content">
                        <h1>
                            Kaufen und gewinnen
                        </h1>
                        <h2>
                            Tolle Preise der Bundesliga / 2. Bundesliga warten auf dich.
                        </h2>
                    </div>
                    <div className="coutdown-wrapper">
                        <div className="timer">
                            <span className="stunden">
                                {zeroPad(hours)}
                                <br />
                                <p>stunden</p>
                            </span>
                            :
                            <span className="minuten">
                                {zeroPad(minutes)} 
                                <br />
                                <p>minuten</p>
                            </span>
                            :
                            <span className="sekunden">
                                {zeroPad(seconds)}
                                <br />
                                <p>sekunden</p>
                            </span>
                        </div>
                    </div>
                    { window.LAST_DAY_90MIN ? null : 
                        <div className= "form-button form-button-main">
                            <NavLink to={GLOBAL_CONFIG.Route.participation} exact
                                className= "participate-section" 
                                data-event= "button-click" 
                                data-category= "Click Action" 
                                data-action= "Participate" 
                                data-label={timeStamp()} 
                                onClick={appGoogleTracking.processEventCTANavLink}>

                                <button className= "btn__container btn--secondary-lilac"> 
                                    JETZT MITMACHEN
                                </button>
                            </NavLink>
                        </div>
                    }
                </div>
            </div>
            
          );
        }
    };

    gameLive ({ hours, minutes, seconds, completed }) {
        if (completed) {
            return window.location.reload();
        } else {
          return (
            <div className= "live-game-text__container">
                <div className="coutdown-dark__wrapper">
                    <div className="timer-dark">
                        <span>
                            {zeroPad((hours * 60 ) + minutes)}:{zeroPad(seconds)}
                        </span>
                    </div>
                </div>
                <div className="live-game-title__content">
                    <h1>
                        Kaufen und gewinnen
                    </h1>
                    <h2>
                        Tolle Preise der Bundesliga / 2. Bundesliga warten auf dich.
                    </h2>
                    <p>
                        Du hast bereits ein Milka, Wunderbar und/oder TUC Produkt gekauft und deinen Code am Vortag eingegeben? Dann hast du hier die Chance auf deinen Gewinn!
                    </p>
                </div>
                <div className= "form-button-participate">
                    {/* if condumer id present redirect to instant win */}
                    <NavLink to={this.state.gameLiveRedirection} exact
                        className= "participate-section" 
                        data-event= "button-click" 
                        data-category= "Click Action" 
                        data-action= "Participate" 
                        data-label={timeStamp()} 
                        onClick={this.onClickGameLiveButton}>

                        <button className= "btn__container btn--secondary-lilac"> 
                            ZUM GEWINN
                        </button>
                    </NavLink>
                </div>
            </div>
          );
        }
    };

    render() {
        return (
            <div className= "banner__container q3-game">            
                <div className = "image__container">
                    <div className= "image__img">

                        {this.state.countdownData.countdown && <Countdown date={Date.now() + this.state.countdownData.countdown} renderer={ this.state.countdownData.gameIsLive ? this.gameLive : this.gameNotLive} /> }               

                        {this.state.countdownData.gameIsLive ? 
                        
                        <picture>
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/game-live-banner.png" media="(min-width: 1024px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main-live-sm.png" media="(min-width: 481px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main-live-sm.png" media="(min-width: 200px)" />
                            <img className="banner__image" src="/resources/imagesBundesliga/90_min/banner/game-live-banner.png" alt="Milka Christmas" />
                        </picture> 
                        : 
                        <picture>
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main.png" media="(min-width: 1024px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main-sm.png" media="(min-width: 481px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main-sm.png" media="(min-width: 200px)" />
                            <img className="banner__image" src="/resources/imagesBundesliga/90_min/banner/banner-main.png" alt="Milka Christmas" />
                        </picture>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    gameState: state.game
});


export default withTranslation()(withRouter(connect(mapStateToProps, null)(CountDownBanner)));