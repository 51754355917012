import React from 'react'
import Match from '../../../../Models/Match'
import BundesligaTeams from '../../../../Models/Teams'

class MobileBuzzerGame2023 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      SelectedMatch: '',
      matches: {},
    }
  }

  componentDidMount() {
    /**
     * Selecting first Item visually
     */
    let { matches } = this.props,
      index = 0,
      selectIndex = null

    if (selectIndex) {
      let itemId = `${matches[0].home + matches[0].away}`,
        radiobtn = document.getElementById(`${itemId}`)

      radiobtn.checked = true

      this.setState({
        SelectedMatch: `${itemId}`,
        [itemId]: true,
      })
    }
  }

  render() {
    let { matches, selectionAllowed, suffix } = this.props
    matches = !!matches ? matches : []

    const matchNum = matches.length

    return (
      <div
        className="mobile buzzer-mobile2023"
        onChange={this.HandleChange.bind(this)}
      >
        {matches.map((item, index) => {
          return (
            <div
              key={`${item.home + item.away}`}
              className={`match-container ${item.outStock ? 'out-stock' : ''}`}
              onClick={this.props.checkedOneMatch}
            >
              {item.outStock ? (
                <div className="date-mobile">
                    <p>Nicht mehr verfügbar</p>
                </div>
              ) : (
                <label className="date-mobile" htmlFor={`${item.home + item.away}`}>
                    {item.weekendDate ? (
                    <span>{item.date}</span>
                    ) : (
                    <span>{item.date} {suffix || ''}</span>
                    )}
                </label>
              )}
              <input
                type="radio"
                name="match"
                id={`${item.home + item.away}`}
                value={`${item.home + item.away}`}
                data-home={`${item.home}`}
                data-away={`${item.away}`}
                data-date={`${item.date}`}
                data-gameweek={`${item.gameweek}`}
                data-weekend-date={`${item.weekendDate}`}
                data-match-single-ticket={item.isSingleTicket}
                data-location={`${item.location}`}
                data-day={`${item.matchDay}`}
                data-id={`${item.id}`}
                disabled={!selectionAllowed}
                hidden
                onClick={(event) => {
                  this.HandleRadio(event)
                }}
              />
              {item.outStock ? (
                <div className="date">
                  <p>Nicht mehr verfügbar</p>
                </div>
              ) : (
                <label className="date" htmlFor={`${item.home + item.away}`}>
                  <span
                    className="checkmark"
                    id={`${item.home + item.away}-check`}
                    data-checked={
                      this.state[`${item.home + item.away}`] ? 'true' : 'false'
                    }
                  ></span>
                  {item.weekendDate ? (
                    <span className="date-match">{item.date}</span>
                  ) : (
                    <span className="date-match">{item.date} {suffix || ''}</span>
                  )}
                </label>
              )}
              <label className="match" htmlFor={`${item.home + item.away}`}>
                <div className="team-container">
                    <div className="team-container__home">
                        <img src={ BundesligaTeams[item.home.replace(/\r?\n|\r/g, '')]?.img } />
                        <div className="team-home">
                            <span>{item.home}</span>
                        </div>
                    </div>
                  <span className="separator"></span>
                    <div className="team-container__away">
                        <img src={ BundesligaTeams[item.away.replace(/\r?\n|\r/g, '')]?.img } />
                        <div className="team-away">
                            <span>{item.away}</span>
                        </div>
                    </div>
                </div>
              </label>

              {matchNum === index + 1 ? null : <hr className="separator" />}
            </div>
          )
        })}
      </div>
    )
  }

  HandleRadio = (event) => {
    let id = event.target.getAttribute('id'),
      value = event.target.value,
      previousMatch = this.state.SelectedMatch

    this.setState(
      {
        [value]: event.target.checked,
        [previousMatch]: false,
        SelectedMatch: value,
      },
      () => {
        document
          .querySelector('[id="' + id + '-check"]')
          .setAttribute('data-checked', 'true');
        if(previousMatch && value != previousMatch){
            document
            .querySelector('[id="' + previousMatch + '-check"]')
            .setAttribute('data-checked', 'false')
        }
      },
    )

    var { selectionUpdate } = this.props
    selectionUpdate(
      new Match({
        id: event.target.getAttribute('data-id'),
        home: event.target.getAttribute('data-home'),
        away: event.target.getAttribute('data-away'),
        matchDay: event.target.getAttribute('data-day'),
        location: event.target.getAttribute('data-location'),
        date: event.target.getAttribute('data-date'),
        gameweek: event.target.getAttribute('data-gameweek'),
        weekendDate: event.target.getAttribute('data-weekend-date'),
        isSingleTicket: event.target.getAttribute('data-match-single-ticket'),
      }),
    )
  }

  HandleChange(event) {
    let id = event.target.getAttribute('id'),
      value = event.target.value,
      previousMatch = this.state.SelectedMatch

    this.setState({
      [value]: event.target.checked,
      [previousMatch]: false,
      SelectedMatch: value,
    })

    var { selectionUpdate } = this.props
    selectionUpdate(
      new Match({
        id: event.target.getAttribute('data-id'),
        home: event.target.getAttribute('data-home'),
        away: event.target.getAttribute('data-away'),
        matchDay: event.target.getAttribute('data-day'),
        location: event.target.getAttribute('data-location'),
        date: event.target.getAttribute('data-date'),
        gameweek: event.target.getAttribute('data-gameweek'),
        weekendDate: event.target.getAttribute('data-weekend-date'),
        isSingleTicket: event.target.getAttribute('data-match-single-ticket'),
      }),
    )
  }
}

export default MobileBuzzerGame2023
