import React from "react";
import parse from 'html-react-parser';
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Q4PopUpV1 from "../../../containers/Campaign/Weltmeister/Q4PopUpV1"
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import StepContent from "./component/StepContent/StepContent";
import FirstContent from "./component/FirstContent/FirstContent";
import Price from "./component/Price/Price";
// import PromoPopup from "./component/Popup/PromoPopup";
class SommerMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            modal: false,
            gameStatus: {}
        }
        this.resize = this.resize.bind(this);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        window.addEventListener("resize", this.resize);
        this.resize();
        _SCROLL_PAGE.ScrollToTop();
        const gameState = this.props.gameState;

        const params = new URLSearchParams(window.location.search);
        if (params.get("status")?.includes("on")) {
            this.setState({ modal: true })
        }
    }

    componentWillMount() {
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }
    handleChild(callback) {
        // Here, you have the function from the child.
        callback();
    };

    render() {
        return (
            <div>
                {/* <div id="main-buzzer" className="buzzer23-main" style={{ backgroundColor: 'red' }}> */}
                {this.footerSection()}
                <Q4PopUpV1 modal={this.state.modal}
                    toggleModal={() => {
                        this.toggleModal();
                    }} />
                <Footersocial />
            </div>
        );
    }

    footerSection() {
        return (
            <div style={{ backgroundColor: '#7D69AC', paddingBottom: '50px' }}>
                <FirstContent game={this.props.game}></FirstContent>
                <StepContent />
                <Price t={this.props.t} game={this.props.game} />
            </div >
        )
    }
    toggleModal() {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    }

}
const mapStateToProps = state => ({
    gameState: state.game
});
/* export default withTranslation()(SommerMain); */
export default withTranslation()(withRouter(connect(mapStateToProps, null)(SommerMain)));