import React from "react";
import { NavLink } from "react-router-dom";
import { mainLoaderToggle } from "../../../modules/helpers";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import TicketsPromoPopUp from "./TicketsPromoPopUp";

class TicketsPromoPrize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    _SCROLL_PAGE.ScrollToTop();
  }

  componentWillMount() {
    setTimeout(() => {
      _SCROLL_PAGE.ScrollToTop();
    }, 200);
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    return (
      <>
        <div id="buzzer-prizes" className="weltmeister-prizes tickets-prizes">
          {this.headingSection()}
          {this.footerSection()}
          <Footersocial />
        </div>
      </>
    );
  }

  modalTracking(event) {
    this.setState({ modal: true });
    appGoogleTracking.processEventCTANavLink(event);
  }

  headingSection() {
    return (
      <div className="campaign-prize-header">
        <h1>GEWINNE BUNDESLIGA TICKETS</h1>
        <p>Der FC Milka verlost 1.000 x 2 Tickets für die Bundesliga / 
2. Bundesliga sowie ein Bundesliga VIP-Erlebnis mit Lothar Matthäus für 2 Personen. Sichere dir deine Chance!
        </p>
      </div>
    );
  }

  footerSection() {
    return (
      <div className="weltmeister campaign-footer tickets">
        <div className="ticket-promo-container">
        <h3>DIESE PREISE WARTEN AUF DICH</h3>

        <div className="ticket-teaser-wrapper">
          <div className="ticket-teaser_container primary">
            <div className="ticket-teaser-card">
              <div className="ticket-teaser primary">
                <img
                  src="/resources/imagesBundesliga/tickets-promo/bundeliga-tickets.png"
                  alt="Gold im Wert"
                />
                <h1>1x Bundesliga VIP-Erlebnis für je 2 Personen mit Lothar Matthäus</h1>
                <p>
                Wir machen dich zum Fußball VIP.<br/>
                Genieße mit deiner Begleitperson ein unvergessliches Bundesliga VIP-Erlebnis. Standesgemäß werdet ihr aus eurem Hotel zum Stadion gebracht und erlebt die packende Atmosphäre bei einem Bundesliga Spiel gemeinsam mit Lothar Matthäus im exklusiven VIP-Bereich. Eine Profianalyse des Spiels und der Spieler natürlich inklusive<br/>
                (Inklusive An- und Abreise, einer Übernachtung, Verpflegung und attraktivem Rahmenprogramm.)
                </p>
              </div>
            </div>
          </div>
          <div className="ticket-teaser_container">
            <div className="ticket-teaser-card">
              <div className="ticket-teaser secondary">
                <img
                  src="/resources/imagesBundesliga/tickets-promo/vip-tickets.png"
                  alt="Gold im Wert"
                />
                <h1>1.000 x 2 Cat2-Tickets</h1>
                <p>
                Lass dich bei aufregenden Partien vom Stadion-Sitz reißen und sei live dabei, wenn dein Lieblingsclub alles gibt. Auf dich warten 1.000 x 2 Cat2-Tickets für Spiele der Bundesliga und 2. Bundesliga. (Werte variieren je nach Club, Auswahl nach Verfügbarkeit über Milka Fan-Center)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="cta-container">
          <button
            className="btn__container btn--secondary-lilac"
            data-event="begin_participation_bottom"
            data-category="Prize Page"
            data-action="Click on Join Now"
            data-label="GAME_PRIZE_OTHER"
            onClick={(e) => {
              this.modalTracking(e);
            }}
          >
            JETZT MITMACHEN{" "}
          </button>
        </div>
        <TicketsPromoPopUp
          modal={this.state.modal}
          toggleModal={() => {
            this.toggleModal();
          }}
        />
        <p className="info">
          Die Gewinner werden schriftlich per E-Mail über den Gewinn
          benachrichtigt.
        </p>
        <p className="legal-text">
          Abbildungen unverbindlich
        </p>
      </div>
      </div>
    );
  }

}

export default TicketsPromoPrize;
