import React, { useEffect, useState } from 'react';
import "./CardThankYou.scss";
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import GLOBAL_CONFIG from '../../../../../config/config';
import appGoogleTracking from '../../../../../modules/googletracking';

const CardThankYou = (props) => {

    const [isMobile, setIsMobile] = useState(false);
    

    useEffect(() => {
        const checkIsMobile = () => {
            const mobileWidthThreshold = 768;
            setIsMobile(window.innerWidth < mobileWidthThreshold);
        };
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return (
        <div className='card_thank-you'>
            <div className='img'>
                <img alt='card image' src={props.img}/>
            </div>
            <div className='text'>
                <h4 className='title'>{props.title}</h4>
                <p className='paragraphe'>{props.text}</p>
                <a href={props.link} target="_blank" rel="noopener noreferrer" onClick={() => appGoogleTracking.dataLayerPush({
                    'dataEvent': props.tagging.event,
                    'dataCategory': props.tagging.category,
                    'dataAction': props.tagging.action,
                    'dataLabel': props.tagging.label
                })}>
                <button class="btn__container btn--secondary-lilac buzzer-button">{props.btn}</button>
                </a>
            </div>

        </div>
    );
}

export default CardThankYou;
