import React from "react";
import BuzzerSlider from "../Buzzer/Slider";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { mainLoaderToggle } from "../../../modules/helpers";
import ValidateForm from "../../../modules/validate";
import ParticipationService from "../../../Service/Participation";
import { withCookies } from 'react-cookie';
import Loader from "../../../components/Overlays/Loader";
import Checkbox from "../../../components/Controls/Checkbox";
import Aux from "../../../hoc/Auxiliare";
import _SCROLL_PAGE from "../../../modules/ScrollPage";

class Q3Holding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEmailAlredySent: false,
            simpleLoader: false,
            isMobile: false
        }
        this.ProcessForm = this.ProcessForm.bind(this);
        this.resize = this.resize.bind(this);

    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
        mainLoaderToggle('hide');
        const { cookies } = this.props;
        if (cookies.get("_isPress") == "true") {
            this.setState({ isEmailAlredySent: true });
        }
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div>
                {this.headingSection()}
                <Footersocial />
                {
                    this.state.simpleLoader ? <Loader /> : null
                }
            </div>
        );
    }

    headingSection() {
        return (
            <div className="content-holding__heading q3-announment">
                <div className="content-home endpage-rewe">
                    <div className="spaceTile-intropage">
                        <div className="campaign-heading">
                            <h1>Gewinne mit FC Milka tolle Preise der Bundesliga / 2. Bundesliga. </h1>
                            <p>
                            Der Ball ist rund und ein Spiel dauert 90 Minuten. Genau darum geht es im neuen FC Milka Gewinnspiel ab dem 11.07.2022.<br/>
                            Sei schnell und nutze deine Chance, in 90 Minuten Tickets und Trikots der Bundesliga / 2. Bundesliga zu gewinnen. 
                            </p>
{/*                             <p><span>Alternative:</span> Sei schnell und nutze deine Chance, in 90 Minuten einen von über 5000 Bundesliga Preisen zu gewinnen. </p> */}
                        </div>
                    </div>
                    <form id="frm_holding"
                            name="frm_holding"
                            method="post" action="/"
                            ref={form => this._FORM_DETAILS = form}
                            onSubmit={this.SubmitHandler.bind(this)} noValidate>
                        <div className={`remindMe-container ${ this.state.isEmailAlredySent ? "sent" : ""}`}>
                                <div className="form__container">
                                    <p>
                                        Du möchtest den Start des neuen FC Milka Gewinnspiels auf keinen Fall verpassen? Kein Problem. Wir erinnern dich rechtzeitig per E-Mail!
                                    </p>
                                    <div id="email-container" className="input-container">
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="email">Email*</label>
                                            <input className="form-input__input js-event-type-in js-event-ab"
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="E-Mail-Adresse eingeben"
                                                data-require="true"
                                                data-type="regex"
                                                data-error-target="#error-email"
                                                data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                                data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                                data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                                data-event-fieldname="Email"
                                                data-event="form-click"
                                                data-category="Form Action"
                                                data-action="Email"
                                                data-label="Type In"
                                                ref={input => this._DETAILS_email = input}
                                            />
                                            <span id="error-email" className="form-input__error active"></span>
                                        </div>
                                    </div>
                                </div>
                            
                            <div className="img__container">
                                <picture>
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo.png" media="(min-width: 1024px)" />
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 481px)" />
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 200px)" />
                                    <img src="/resources/imagesBundesliga/buzzer/FCMilka-logo.png" alt="FC Milka Logo" />
                                </picture>
                            </div>
                            {
                                !this.state.isEmailAlredySent ?
                                    (
                                        <Aux>
                                            <Checkbox id="terms"
                                                required={true}
                                                errorMessage={""}
                                                text='Ich möchte den regelmäßigen Newsletter mit Informationen zu Milka Produkten, Rezepten und Aktionen per E-Mail von der Mondelez Deutschland Services GmbH & Co. KG oder von verbundenen Unternehmen der Mondelez International Unternehmensgruppe erhalten. In diesem Zusammenhang wird auch mein Kauf- und Klickverhalten auf dieser Milka-Website analysiert. Diese Einwilligung kann jederzeit mit zukünftiger Wirkung hier widerrufen werden. Bitte beachten Sie auch unsere <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzerklärung</a> für weitere Informationen.'
                                                onChangeHandler={ !this.state.isEmailAlredySent ? this.CheckboxChangeHandler.bind(this) : ()=>{}}
                                            />
                                            <button type="submit" className={this.state.isEmailAlredySent ? "btn__container btn--lilac btn-desabled" : "btn__container btn--lilac"} disabled={this.state.isEmailAlredySent}> ERINNERE MICH </button>
                                        </Aux>
                                    )
                                :
                                    (
                                        <p className="email-sent">Deine Mail Adresse wurde erfolgreich gespeichert. Wir erinnern dich, sobald die FC Milka Aktion beginnt.</p>
                                    )
                            }
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    CheckboxChangeHandler(event, _ref) {
        if (_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if (_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    SubmitHandler(event) {
        event.preventDefault();
        if (this.state.isEmailAlredySent) {
            return null;
        }
        const isValid = ValidateForm.validateForm('frm_holding');

        if (isValid) {
            this.ProcessForm();
        } else {
            /**
             * Scroll To first error
             */
            let firstError = this._FORM_DETAILS.querySelector(".notvalid");
            if (!!firstError) {
                const yOffset = - 110,
                    y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }
    }

    ProcessForm() {
        const { cookies } = this.props;
        let _data = {
            email: this._DETAILS_email.value,
            retailer: null
        }
        this.setState({
            simpleLoader: true
        })
        ParticipationService.SendMail(_data)
            .then((response) => {
                let { success } = response.data;
                this.setState({
                    simpleLoader: false
                })
                console.log(response);
                if (success) {
                    this.setState({ isEmailAlredySent: true });
                    const expireDate = new Date();
                    expireDate.setMonth(expireDate.getMonth() + 3);
                    cookies.set("_isPress", "true", { path: "/", expires: expireDate });
                } else {
                    console.log("KO");
                }
            })
            .catch((error) => {
                this.setState({
                    simpleLoader: false
                })
                console.log("catch : KO", error);
            });
    }

    sliderSection() {
        return (
            <div>
                <BuzzerSlider sliderName="Page.Holding.sliderBuzzer" />
            </div>
        );
    }

    footerSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <p>
                    *Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga / 2. Bundesliga. Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen, und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID-19-Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation. Maximal ein Hauptpreis pro Person.
                </p>
                
                {
                    this.state.isMobile && (
                        <a href="https://www.milka.de/alle-produkte" target="_blank">
                            <button className="btn__container btn--red"> MEHR ENTDECKEN </button>
                        </a>
                    )
                }
                
            </div>
        )
    }
}

export default withCookies(Q3Holding);