import React, { useState, useEffect } from 'react';
import './circle.css';

const AnimationCircle = ({ phase }) => {
    const [imageIndex, setImageIndex] = useState(0);
    const [hide64, setHide64] = useState(false);
    const images = ['resources/imagesBundesliga/sommer/one.png', 'resources/imagesBundesliga/sommer/two.png'];
    const imagesPhase = ['resources/imagesBundesliga/sommer/one1.png', 'resources/imagesBundesliga/sommer/two.png'];

    const [textIndex, setTextIndex] = useState(0); // État pour suivre l'index du texte actuel
    const textes = [
        ['64x'],
        ['2 DAUERKARTEN FÜR DIE SAISON 2024/2025', 'TRIFF DEINEN FUSSBALLSTAR'],
    ];
    const textePhase = [
        ['VIP FAN-EVENT'],
        ['FÜR DICH & 4 FREUNDE', 'TRIFF DEINEN FUSSBALLSTAR'],
    ]

    useEffect(() => {
        const intervalAlternerTextes = setInterval(() => {
            setImageIndex(prevIndex => (prevIndex === 0 ? 1 : 0)); // Alterner entre 0 et 1 pour les images
            setTextIndex(prevIndex => (prevIndex === 0 ? 1 : 0)); // Alterner entre 0 et 1 pour les textes
            setHide64(prevHide64 => !prevHide64);
        }, 5000);
        return () => {
            clearInterval(intervalAlternerTextes);
        };
    }, []); // useEffect s'exécute une seule fois au montage du composant

    return (
        <div>
            <div className='containerAnimation'>

                {!hide64 ? (
                    <div className={phase ? 'ticketHaut rectifHaut' : 'ticketHaut'}>
                        <p className={phase ? 'textTickets_' : 'textTickets_ t64'}>{phase ? textePhase[0] : textes[0]}</p>
                    </div>
                ) : null}


                {/* //mic gestion height image*/}
                {phase ?
                    <img className={'zoomFadingElement'} src={imagesPhase[imageIndex]} alt="sommerPhase2" />
                    :
                    <img className={!hide64 ? 'zoomFadingElementExeption' : 'zoomFadingElement'} src={images[imageIndex]} alt="sommerPhase1" />
                }

                <div className={phase ? 'ticketBas rectifBas' : 'ticketBas'}>
                    <p className='textTickets_'>{phase ? textePhase[1][textIndex] : textes[1][textIndex]}</p>
                </div>
            </div>

            {imageIndex === 0 ? (
                <div className="containerBulle">
                    <div className="solidWhiteBox"></div>
                    <div className="whiteBorderBox"></div>
                </div>
            ) : (
                <div className="containerBulle">
                    <div className="whiteBorderBox"></div>
                    <div className="solidWhiteBox"></div>
                </div>
            )}
        </div>
    );
};

export default AnimationCircle;
