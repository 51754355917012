import React from "react";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { NavLink } from "react-router-dom";
// import Aux from '../../hoc/Auxiliare';

import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import StepContent from "../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import ParticipationNavigation from "../Buzzer/ParticipationNavigation";

class PennyFaq extends React.Component {
    componentDidMount() {
        mainLoaderToggle("hide");
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    componentWillUnmount() {
        mainLoaderToggle("show");
    }

    render() {
        return (
            <div id="buzzer-faqs" className="penny-faqs">
                {this.faqContent(this.props)}
                {this.footerSection()}
                <Footersocial />
            </div>
        );
    }

    faqContent(props) {
        const iconPlus = "/resources/imagesBundesliga/buzzer/plus.png";
        const iconMinus = "/resources/imagesBundesliga/buzzer/minus.png";

        return (
            <div className="content-faq">
                <div className="content-faq__item">
                    <h6>Wie läuft die Aktion ab?</h6>
                    <img
                        src={iconMinus}
                        className="content-faq__img shown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content show">
                        <p>
                            Kaufe innerhalb des Zeitraumes vom 05.09. - 17.09.2022 Milka, TUC oder Wunderbar Produkte im
                            Gesamtwert von mindestens 6€. Lade bis zum 18.09.2022 den Kaufbeleg auf fcmilka.de/penny-topps
                            hoch und gib deine Kontaktdaten auf dieser Seite ein. Online Registrierung erforderlich.
                            <br />
                            Zusätzlich benötigen wir noch das Datum deines Einkaufs bei Penny.
                            <br />
                            Wir prüfen im Anschluss deinen Kaufbeleg und senden dir nach erfolgreicher Prüfung den Code für
                            dein Match Attax Multipack per E-Mail zu. Jeder Teilnehmer kann beliebig oft an der Aktion
                            teilnehmen. Jeder Teilnehmer kann allerdings nur einen Code pro Kaufbeleg erhalten. Bitte bewahre
                            den Original-Kaufbeleg gut auf.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Mit welchen Produkten kann ich teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Die Aktion ist gültig für Milka, TUC oder Wunderbar Produkte.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wo finde ich die Produkte für die Aktion?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Du findest die Aktionsprodukte überall bei Penny.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich mehrere Kaufbelege hochladen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                            Ja, der Teilnehmer kann beliebig oft an der Aktion teilnehmen. Jeder Teilnehmer kann allerdings
                            nur einen Code pro Kaufbeleg erhalten.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie lange kann ich an der Aktion teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                            Du hast vom 05.09. - 17.09.2022 die Möglichkeit, Aktionsprodukte zu kaufen und bis zum 18.09.2022
                            den Kaufbeleg hochzuladen.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann jeder an der Aktion teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                            Teilnehmen kann jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland. Ausgenommen sind
                            Mitarbeiter der beteiligten Unternehmen und jeweils deren Angehörige.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>In welchen Ländern kann man teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Die Teilnahme ist in Deutschland möglich.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Was kann ich bei der Aktion erhalten?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                            Für jede erfolgreiche Teilnahme erhältst du einen digitalen Code für ein Match Attax Multipack
                            zur Einlösung auf topps.com. Jedes Match Attax Multipack enthält 30 Karten und 1 limitierte
                            Karte.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie erfahre ich, ob ich erfolgreich teilgenommen habe?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                            Nach dem Hochladen des Kaufbelegs, prüfen wir diesen. Im Anschluss senden wir dir nach
                            erfolgreicher Prüfung den Code für dein Match Attax Multipack per E-Mail zu.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie kann ich den Code für das Match Attax Multipack einlösen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                            Der Code kann bis zum 30.09.2023 auf topps.com eingelöst werden. Das Match Attax Multipack wird
                            dem Teilnehmer in der Regel innerhalb von 7 Tagen an die bei der Registrierung angegebene Adresse
                            zugesendet.
                        </p>
                    </div>
                </div>
               
                <div className="content-faq__item">
                    <h6>Kann ich meine Daten löschen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                            Nach Ende der Aktion werden alle Teilnehmerdaten gelöscht. Soweit diese aus gesetzlichen Gründen
                            nicht gelöscht werden dürfen, werden deine Daten gesperrt und nach Ablauf der handels- und
                            steuerrechtlichen Aufbewahrungsfrist gelöscht. Aber falls du deine Daten bereits während des
                            Aktionszeitraums löschen möchtest, ist das natürlich möglich. Dazu wendest du dich bitte direkt
                            an unseren{" "}
                            <a
                                href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D"
                                target="_blank"
                            >
                                Verbraucherservice*
                            </a>
                            . Eine Teilnahme am Gewinnspiel ist dann leider nicht mehr möglich.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>An wen kann ich mich wenden, wenn ich weitere Fragen habe?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                            Vielleicht hast du noch weitere Fragen, die hier nicht beantwortet worden sind. Dann wende dich
                            am besten direkt an unseren{" "}
                            <a
                                href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D"
                                target="_blank"
                            >
                                Verbraucherservice*
                            </a>
                            .
                            <br />
                            *Falls der hier hinterlegte Link zum Verbraucherservice nicht funktioniert, kannst du über den
                            Kontaktlink im Bereich „Service“ den Verbraucherservice erreichen.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    showItemContent(e, iconMinus, iconPlus) {
        let classes = Array.from(e.target.classList);
        let itemContent = e.target.parentNode.children[2];

        if (classes.includes("notshown")) {
            e.target.setAttribute("src", iconMinus);
            e.target.classList.remove("notshown");
            e.target.classList.add("shown");
            itemContent.classList.add("show");
        } else {
            e.target.setAttribute("src", iconPlus);
            e.target.classList.remove("shown");
            e.target.classList.add("notshown");
            itemContent.classList.remove("show");
        }
    }

    footerSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <div className="content-separator">
                    <h2>Mit deiner Lieblingsschokolade von Milka gewinnen!</h2>
                    <h3 className="special">
                        Welches ist dein Lieblingsprodukt von Milka? Bist du Fan der zarten Milka Alpenmilch Schokolade?
                        Stehst du auf die Milka Mmmax mit knackigen Erdnüssen und cremigem Karamell? Kannst du den luftig
                        gebackenen Milka Waffelinis nicht widerstehen? Sind unsere Milka Cookie Sensations mit OREO
                        Creme-Füllung vielleicht dein Favorit? Oder naschst du doch am liebsten aus unserem Milka Zarte
                        Momente Mix – damit du dich nie für eine Sorte entscheiden musst?
                    </h3>

                    <img src="\resources\imagesBundesliga\buzzer\products.png" alt="Products" />

                    <div className="cta-container">
                        <a href="https://www.milka.de/alle-produkte" target="_blank" className="btn--anchor no-decoration">
                            <button className="btn__container btn--lilac">MILKA SCHOKOLADE ENTDECKEN</button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PennyFaq);
