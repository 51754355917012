import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TitleContent from "../../../components/Content/TitleContent/TitleContent";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import { connect } from "react-redux";
import { setIsBannerShown } from "../../../store/banner";

class DoppeltTerms extends Component {
  componentDidMount() {
    const { t, setIsBannerShown } = this.props;
    setIsBannerShown(false);
    //Remove main loader
    mainLoaderToggle("hide");

    //Virtual page
    /*         appGoogleTracking.dataLayerPush({
                    customEvent: {
                        event: "virtual-page",
                        virtualUri: GLOBAL_CONFIG.Route.terms,
                        pageTitle: t("Meta.title"),
                    },
                }); */
  }

  componentWillUnmount() {
    //Show mainloader
    mainLoaderToggle("show");
  }

  displayFullVersion() {
    const { t } = this.props;
    return (
      <div className="buzzer-terms"/*  style={{ backgroundColor: "#7D69AC" }} */>
        <div className="terms-container">
          {/* <TitleContent heading="Teilnahmebedingungen" /> */}
          <div className="s-content-copy">
            <div className="s-content-copy__item">
              <p className="headings first">Impressum</p>
              <p>
                Mondelez Deutschland GmbH <br />
                Konsul-Smidt-Str. 21 <br />
                28217 Bremen
              </p>
              <p>
                Telefon: 00800 83 00 00 36 (kostenfrei) <br />
                Fax:{" "}
                <a
                  href="fax: 0421 3770 8100"
                  onClick={appGoogleTracking.processEventCTA}
                  data-event="go_to_fax"
                  data-category="Terms and Conditions Page"
                  data-action="Go to Fax"
                  data-label="ENG_TC_OTHER_"
                >
                  0421 3770 8100
                </a>{" "}
                <br />
                E-Mail:{" "}
                <a
                  href="mailto:Verbraucherservice@mdlz.com"
                  onClick={appGoogleTracking.processEventCTA}
                  data-event="go_to_email_top"
                  data-category="Terms and Conditions Page"
                  data-action="Go to Email"
                  data-label="ENG_TC_OTHER"
                >
                  Verbraucherservice@mdlz.com
                </a>
              </p>
              <p>
                Sitz: Bremen, AG Bremen HRB 11373 <br />
                Umsatzsteueridentifikationsnummer: DE 114397121 <br />
                Geschäftsführer: Martin Kaufmann, Hartmut Wilkens
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings">
                I. Teilnahmebedingungen der Gewinnspielaktion „Doppelt gewinnen
                – Für Dich und einen guten Zweck“
              </p>
              <p>
                Eine Teilnahme an dem Gewinnspiel{" "}
                <strong>
                  „Doppelt gewinnen – Für Dich und einen guten Zweck“
                </strong>{" "}
                ist ausschließlich nach Maßgabe der nachfolgend aufgeführten
                Teilnahmebedingungen möglich. Mit der Teilnahme erkennt der
                Teilnehmer diese Teilnahmebedingungen ausdrücklich an. Bei einem
                Verstoß gegen diese Teilnahmebedingungen behält sich Mondelez
                Deutschland das Recht vor, Personen vom Gewinnspiel
                auszuschließen und/ oder Gewinne abzuerkennen.
              </p>
              <p>
                Mit der Durchführung und Abwicklung des Gewinnspiels wurde die
                Baudek & Schierhorn Shopper Marketing GmbH, Schellerdamm 16,
                21079 Hamburg (Agentur) beauftragt.
              </p>
              <p>
                Aus Gründen der besseren Lesbarkeit verwenden wir nachstehend
                einheitlich die Bezeichnung Teilnehmer oder Gewinner. Es sind
                hiervon selbstverständlich alle Geschlechter (m/w/d) umfasst.
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings sub">1. Veranstalter</p>
              <p>
                Veranstalterin des Milka Gewinnspiels „Doppelt gewinnen – Für
                Dich und einen guten Zweck“ ist die
              </p>
              <p>
                Mondelez Deutschland GmbH <br />
                Konsul Smidt Str. 21 <br />
                28217 Bremen
              </p>
              <p>
                (im Folgenden „Mondelez“ oder „Veranstalter“ genannt). Weitere
                Informationen zu dem Veranstalter entnehmen Sie bitte den
                Impressums-Angaben oben.
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings sub">2. Teilnahmeberechtigung</p>
              <p>
                Die Teilnehmer an dieser Werbeaktion müssen natürliche Personen
                sein, einen Wohnsitz in Deutschland haben und mindestens 18
                Jahre alt sein. Mitarbeiter von Mondelez, der mit Mondelez
                verbundenen Unternehmen und Mitarbeiter von in dieses
                Gewinnspiel involvierten Unternehmen sowie deren unmittelbare
                Familienangehörige sind von der Teilnahme ausgeschlossen. <br />
                Pro gekauftem Produkt / Produktcode ist nur eine Teilnahme erlaubt. 
                <br />
                Mehrfachteilnahmen sind erlaubt, jedoch kann pro Teilnehmer nur ein Sachpreis   (siehe 3.2. Gewinne) gewonnen werden sowie nur ein Gewinn pro Familie. Maximal sind 5 Teilnahmen pro Person/Tag erlaubt. Teilnahmen derselben Person und Adresse mit einer anderen als der bei der ersten Teilnahme angegebenen E-Mail-Adresse werden nicht berücksichtigt. Eine weitere Teilnahme derselben natürlichen Person unter einer neuen/anderen E-Mail-Adresse, als die bei der ersten Teilnahme angegebene E-Mail-Adresse, wird als Verstoß gegen diese Teilnahmebedingungen angesehen. Sollte dem Teilnehmer aufgrund einer weiteren Teilnahme bereits ein/mehrere Preise zuerkannt oder bereits übergeben worden sein, kann der Veranstalter den Preis/die Preise aberkennen und auf Kosten der Teilnehmer zurückverlangen.
              </p>
              <p>
              Bitte den Original-Kaufbeleg zum Nachweis aufbewahren. Der Veranstalter behält sich eine Überprüfung des Kaufbelegs nach seinem Ermessen vor. Nicht teilnehmende Produkte auf dem Kaufbeleg unkenntlich machen.
              </p>
              <p>
              Alle Einsendungen müssen direkt von der Person vorgenommen werden, die sich an der Werbeaktion beteiligt. Masseneinsendungen durch gewerbliche Absender, Verbrauchergruppen oder Dritte werden nicht akzeptiert. Unvollständige oder unleserliche Einsendungen, Einsendungen durch oder über Dritte oder Syndikate, Einsendungen durch Makros oder andere automatisierte Methoden (einschließlich programmierbare Teilnahme-Systeme) sowie Einsendungen, die den Vorgaben dieser Teilnahmebedingungen nicht vollumfänglich entsprechen, werden von der Teilnahme ausgeschlossen und nicht berücksichtigt. Stellt sich heraus, dass ein Teilnehmer einen oder mehrere Computer zur Umgehung dieser Bestimmungen nutzt, beispielsweise durch den Einsatz von „Skripten“, die „Brute-Force“-Methode, die Verschleierung seiner Identität durch Manipulation von IP-Adressen, willkürliches Erraten von Codes oder sonstige automatisierte Mittel, die die Anzahl seiner Einsendungen für diese Werbeaktion auf eine Weise erhöhen sollen, die nicht mit dem Geist dieser Werbeaktion im Einklang steht, werden die Einsendungen dieser Person von der Teilnahme ausgeschlossen und jeder eventuelle Gewinn wird für nichtig erklärt und zurückgefordert. Dasselbe gilt für Beiträge, die den Teilnahmevorgang manipulieren und/oder sonst in unfairer und/oder unlauterer Weise versuchen, den Teilnahmevorgang zu beeinflussen
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings sub">3. Aufgabe & Durchführung: Gewinne</p>
              <p className="headings sub small">3.1 Aufgabe & Durchführung</p>
              <p>
              Die Teilnahme erfolgt durch Kauf eines Aktionsproduktes von Milka im Zeitraum vom  16.10. – 31.12.2023 sowie Eingabe des Produktcodes (auf der Produktverpackung), Angabe der Aktionsmarke, des Händlers/Kaufdatums auf www.doppeltgewinnen.fcmilka.de bis zum 31.12.2023. 
                <br />
                Der Teilnehmer erklärt  sich zur Gewinnbenachrichtigung per E-Mail von Mondelez einverstanden.
                <br />
                <br />
                Ein Internetzugang, eine Online-Registrierung, eine Telefonnummer und eine gültige E-Mail-Adresse sind erforderlich, um die zur Verfügung gestellten Gewinne abwickeln und organisieren zu können und den Gewinnern ihre Gewinne auch übergeben zu können. Die mit der Gewinnspielabwicklung beauftragte Agentur wird ggf. im Bedarfsfall Gewinner kontaktieren.
                <br />
                Die Gewinnermittlung erfolgt während des Gewinnspielzeitraumes per Zufallsgenerator.
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings sub">3.2 Gewinne</p>
              <p>
                Insgesamt gibt es folgende Gewinne:
                <br />
                <strong>100× 2 VIP-Tickets </strong> Saison 2023/24 für ein Spiel der Bundesliga oder 2. Bundesliga des Clubs der Wahl (Auswahl nach Verfügbarkeit über FC Milka Fan-Center unter www.fcmilka.de. Es besteht kein Anspruch auf Clubauswahl/Spielkombination in der gewonnenen Kategorie).
                <br />
                Die Gewinner der 2 VIP-Tickets Saison 2023/24 erhalten die Gewinnbestätigung direkt nach Gewinn per E-Mail mit dem Hinweis, dass Mondelez sich am 04.01.2024 wieder melden wird.
              </p>
              <p>
              Den Zugang zum FC Milka Fan-Center erhalten alle VIP Ticket-Gewinner erst nach Gewinnspielende ab voraussichtlich KW 01 / 2024. Die Gewinner werden hierüber per E-Mail in KW 01 / 2024   von unserer Agentur informiert und erhalten einen Zugangslink. Hierzu haben die Gewinner 7 Tage Zeit, <strong>ansonsten verfällt der Gewinn.</strong>
Der Weiterverkauf von Tickets ist untersagt.

              </p>
              <p>
              Der Begünstigte bestellt bei seiner Ticketbestellung 2 Tickets über das Milka Fan-Center. 
              </p>
              <p>
              Wenn Tickets für das gewünschte Spiel nicht mehr vorrätig sind, kann man Tickets für ein anderes verfügbares Spiel weiterhin auswählen. 
              </p>
              <p>
              Beim Ticket-Gewinn gelten neben unseren Teilnahmebedingungen und Datenschutzhinweisen die AGB und Datenschutzbestimmungen des jeweiligen Clubs, welche auf dem Ticket und dem Online-Auftritt des Clubs zu entnehmen sind. Das/die einmal ausgestellte/n Tickets dürfen/darf unter keinen Umständen an Dritte verkauft werden.
              </p>

              <p>
              Die Tickets werden für gewöhnlich per Mail als e-Ticket und sehr kurzfristig vor dem Spieltag versendet. Mit einer Ausnahme: Für den Club RB Leipzig erhalten die Gewinner die Tickets auf die entsprechende RB Leipzig App.
              </p>
              <p>
              Der Veranstalter / das Milka Team hat keinen Einfluss auf die Ticketausstellung, dieses wird seitens der einzelnen Clubs geregelt. Es ist dem Veranstalter daher nicht möglich, nähere Angaben zum genauen Versandzeitpunkt zu machen.
              </p>
              <p>
                <strong>100x Merchandise Gutscheine à 100 Euro für den Club der Wahl</strong>
              </p>
              <p>
              Die Gewinner der Merchandise Gutscheine erhalten die Gewinnbestätigung direkt nach Gewinn per E-Mail mit dem Hinweis, dass Mondelez sind in KW 01/2024 wieder melden wird. Der Gewinn wird nach Gewinnspielende, ab voraussichtlich KW 03/2024 an die Gewinner     per Post oder per E-Mail von unserer Agentur versendet.
              </p>
              <p>
                <strong>
                Der Gewinner erhält einen Merchandise Gutschein à 100 EURO von dem Club, den er auf der Microsite bei der Abfrage „verrate uns deinen Lieblingsclub“ ausgewählt hat.
                </strong>
              </p>
              <p>
              Wenn Merchandise Gutscheine des gewünschten Fußball Clubs der Bundesliga / 2. Bundesliga nicht verfügbar sind, kann der Gewinner einen anderen Fußball Club der Bundesliga / 2. Bundesliga auswählen. 
              </p>
              <p>
              Die Auswahl erfolgt nach Verfügbarkeit. Es besteht kein Anspruch auf Clubauswahl.
              </p>

              <p>
              Beim Gewinn eines Merchandise Gutscheins gelten neben unseren Teilnahmebedingungen und Datenschutzhinweisen die AGB und Datenschutzbestimmungen des jeweiligen Clubs, der den Gutschein ausstellt. Die per Gutschein erworbenen Merchandise Artikel dürfen unter keinen Umständen an Dritte verkauft werden.
              </p>
              <p>
              Wenn der Gewinn bei einem Postversand bei der 1. Zustellung an den Gewinner nicht angenommen wird und der Merchandise Gutschein an die Agentur Baudek & Schierhorn retourniert wird, wird innerhalb von 6 Wochen ein zweiter Zustellversuch unternommen. Wenn dieser Versuch ebenfalls scheitert, behält Mondelez sich vor, den Gewinn abzuerkennen.
              </p>
              <p>
              <strong>15.000 Ausgaben des kicker eMagazins</strong> (montags, Wert 2,99 €; donnerstags, Wert 1,99 €)
              </p>
              <p>
              Der Gewinner kann mithilfe eines Codes in der eMagazine-App des kicker die zum jeweiligen Zeitraum aktuelle Ausgabe lesen und downloaden.
              </p>
              <p>
              Der Download der eMagazine-App ist zwingend erforderlich.
              </p>
              <p>
              Die Gewinner der Einzelausausgaben des kicker eMagazines erhalten eine Gewinnbestätigung sowie den Code direkt nach Gewinn per E-Mail. 
              </p>
              <p>
              Die Codes sind bis zum 30.06.2024 einlösbar. Alle weiteren Einlösebedingungen sind der Gewinnbestätigung zu entnehmen. 
              </p>
              <p>
                <strong>
                150x Jahres-Abonnements à 133,20€ des kicker eMagazines
                </strong>
              </p>
              <p>
              Der Gewinner kann mittels des Gewinns eines der 150 Jahres-Abonnements des eMagazines in der eMagazine-App des kickers für 1 Jahr kostenfrei beziehen.
              </p>
              <p>
              Der Download der eMagazine-App ist zwingend erforderlich.
              </p>
              <p>
              Die Gewinner des Jahres-Abonnements des kicker eMagazines erhalten eine Gewinnbestätigung und die Nachricht, dass kicker sich direkt mit dem Gewinner innerhalb der nächsten 7 Tage zur weiteren Abwicklung in Verbindung setzt.
Die Gewinnerdaten werden im System von kicker angelegt und damit automatisch per Auftragsbestätigung über den weiteren Ablauf und die Einlösung des Gewinnes in Form der Jahres-Abonnements des eMagazines informiert. Das jeweilige Jahres Abonnement kann bis spätestens zum 30.06.2024 eingelöst werden können. 

              </p>
              <p>
              <strong>1.000x Bundesliga Brillant Replica Fußbälle im Wert von 37,99€</strong>  der Bundesliga und 2. Bundesliga, Saison 2023/2024 
              </p>
              <p>
              Die Gewinner des Bundesliga Brilliant Replica Fußballs erhalten die Gewinnbestätigung direkt nach Gewinn per E-Mail. Der Gewinnversand erfolgt wöchentlich ab Lager von Mondelez.
              </p>
              <p>
              Wenn der Gewinn bei der ersten Zustellung an den Gewinner nicht angenommen wird und der Bundesliga Brillant Replica Fußball an das Lager von Mondelez retourniert wird, wird innerhalb von sechs Wochen ein zweiter Zustellversuch unternommen. Wenn dieser Versuch ebenfalls scheitert, behält Mondelez sich vor, den Gewinn abzuerkennen.
              </p>
              <p>
              <strong>10.000x 20% Rabattgutscheine</strong> von 11teamsports einzulösen über die 11teamsports-Website. Die Rabattgutscheine gelten auch auf reduzierte Ware und sind einlösbar auf alle Artikel der Kategorie Fanshop → Bundesliga & 2. Bundesliga – bis zum 31.10.2024 
              </p>
              <p>
              Die Gewinner des 20% Rabattgutscheins erhalten eine Gewinnbestätigung sowie den Code direkt nach Gewinn per E-Mail. Alle Einlösebedingungen sind der Gewinnbestätigung zu entnehmen.
              </p>
              <p>
              2x 50.000€ Spendenaktion: Im Rahmen des Gewinnspiels „Doppelt gewinnen – für dich und einen guten Zweck“ spendet Mondelez jeweils 50.000€ für Projekte der folgenden Stiftungen  : 
              </p>
              <p>
              1.)	DFB-Stiftung Egidius Braun, Sövener Straße 50, 53773 Hennef
Mondelez und Rudi Völler unterstützen die Fußball-Ferien-Freizeiten der DFB-Stiftung Egidius Braun. Für das jahr 2024 wird die Völkerverständigung und Integration das Schwerpunktthema sein. 1.000 Teilnehmende werden an 18 abwechslungsreichen Programmen teilnehmen und neben verschiedenen Fußballangeboten, Ausflüge in Kletterparks, Besuche verschiedener Bundesliga-Clubs, Wertedialoge sowie Besuche prominenter Gäste erleben
              </p>
              <p>
              2.)	Lukas Podolski – Vermarktung von Persönlichkeitsrechten, Inhaber Lukas Podolski, Robert-Perthel-Straße 45, 50739 Köln
Mondelez unterstützt zusammen mit der Lukas Podolski Stiftung das Projekt „Mutmacher“ der Arche. Dieses schenkt Jugendlichen Mut und Hoffnung. Durch Bildung, Workshops und persönliche Betreuung wird das Selbstvertrauen gestärkt und die Selbständigkeit erhöht. Lukas Podolski & Milka schaffen so neue Möglichkeiten für junge Menschen und unterstützen die Chancengleichheit in Deutschland.

              </p>
              <p>
              <strong>Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation. Barauszahlung und Rechtsweg sind ausgeschlossen.</strong>
              </p>
              <p>
              Einsendungen, die als nicht mit diesen Bedingungen übereinstimmend betrachtet werden, werden verworfen. Eine Teilnahme ist in diesen Fällen ausgeschlossen.
              </p>
              <p>
                <ul>
                    <li>
                    -	Darüber hinaus gilt auch: In dem Fall, dass ein Gewinner entweder 
                    </li>
                    <li>
                    -	diese Teilnahmebedingungen nicht erfüllt, 
                    </li>
                    <li>
                    -	seine <u>Kontaktdaten</u> nicht vollständig oder unrichtig angibt 
                    </li>
                    <li>
                    -	oder der Gewinn unter der von dem Gewinner angegebenen Adresse auch nach zweifachem Versuch binnen 6 Wochen nach dem ersten Zustellversuch nicht zustellbar ist,
                    </li>
                </ul>
                verfällt der Gewinn und kann an einen anderen Teilnehmer übermittelt werden, der unter denselben Bedingungen ermittelt wurde.
              </p>
              <p>
              Der Gewinn wird nicht in bar ausgezahlt und ist vom Umtausch ausgeschlossen. 
              </p>


              <p className="headings sub">3.3.</p>
              <p>
              Zusätzlich verlost Mondelez weitere Gewinne in Abstimmung mit seinen Handelspartnern. Je nach Handelspartner variiert die Anzahl und die Wertigkeit der Gewinne. Für teilnehmende Handelspartner gelten daher zusätzlich zu den in diesen Teilnahmebedingungen zum Gewinnspiel „Doppelt gewinnen – für Dich und einen guten Zweck“ und in den Unterlagen des jeweiligen Händlers ausgelobten Modalitäten zu der Promotion die folgenden Teilnahmebedingungen:
                <br />
                <br />
                Die Verlosung der zusätzlichen Gewinne bei den Handelspartnern wird über eine Anzeige in einem Werbemittel der Handelspartner angekündigt. Der jeweilige Teilnahmezeitraum der Zusatzverlosung bestimmt sich nach der entsprechenden Ankündigung des jeweiligen Händlers.
                <br />
                <br />
                Sofern im Teilnahmezeitraum der teilnehmenden Händler ein Produkt von Milka gekauft und der Produktcode eingegeben wurde, nimmt der Teilnehmer automatisch an dem Gewinnspiel „Doppelt gewinnen – für Dich und einen guten Zweck“ teil.
                <br />
                <br />
                Die Ermittlung der Gewinner der Zusatzverlosungen bestimmt sich nach der entsprechenden Ankündigung des jeweiligen Händlers sowie spätestens eine Woche nach Ende der Promotion und unter der jeweiligen Gewinnermittlungsmechanik, die in den Teilnahmebedingungen zur Zusatzverlosung der jeweiligen Händler vorgesehen ist. 
                <br />
                <br />
                Die Gewinnbenachrichtigung erfolgt per E-Mail an die bei der Registrierung (s. Punkt 3.1) angegebene E-Mail-Adresse innerhalb einer Woche nach der Ziehung. Maximal ein Hauptgewinn pro Person. Der Teilnehmer erklärt sich zur Gewinnbenachrichtigung von Mondelez einverstanden.
                <br />
                <br />
                Im Falle des Gewinnes eines Einkaufsgutscheines gelten ausschließlich die jeweiligen Allgemeinen Geschäftsbedingungen des jeweiligen Anbieters. Ein Verweis auf die AGB des jeweiligen Händlers erhält der Gewinner in seinem Gewinnanschreiben.
                <br />
                <br />
                Auch hier gilt: Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann ihn auch durch einen anderen, gleichwertigen Gewinn ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere aber nicht beschränkt auf die derzeitige COVID 19 Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation.
                <br />
                <br />
                Es gelten im Übrigen die allgemeinen Teilnahmebedingungen des Gewinnspiels „Doppelt gewinnen – für Dich und einen guten Zweck“ für die Zusatzverlosungen, sowie die jeweils auf den entsprechenden Websites und Anzeigen dargestellten Teilnahmebedingungen der Zusatzverlosungen.
              </p>
            </div>

            <div className="s-content-copy__item">
              <p className="headings sub">4. Gewährleistung, Haftung</p>
              <p>
              Für einen Mangel oder mehrere Mängel an einem Gewinn übernimmt Mondelez weder eine Gewährleistung noch stehen dem Gewinner im Falle eines Mangels oder mehrerer Mängel an dem Gewinn Umtausch- oder Rückgaberechte zu, es sei denn, es liegt ein Mondelez Deutschland zurechenbarer Fall von Vorsatz oder arglistiger Täuschung vor.  <br />
              Der Veranstalter haftet auch nicht für Produkte oder Dienstleistungen Dritter. Bei der Inanspruchnahme der Leistungen Dritter gelten deren Allgemeine Geschäftsbedingungen.  <br />
              Der Veranstalter haftet auch nicht im Falle einer Insolvenz des Dritten und leistet keinen Ersatz im Falle einer in der Zukunft liegenden, derzeit nicht absehbaren Eröffnung eines Insolvenzverfahrens über das Vermögen des Dritten.
                <br />
                Der Veranstalter übernimmt keine Verantwortung für verspätet eingehende, unvollständige, inkorrekt eingesandte, schadhafte oder falsch zugestellte Einsendungen, Ansprüche oder Mitteilungen, unabhängig davon, ob diese durch Fehler, Unterlassung, Veränderung, Manipulation, Löschung, Diebstahl, Zerstörung, Übertragungsunterbrechung, Kommunikationsausfall oder anderweitig bedingt wurden.  <br />
                Der Veranstalter haftet nicht für Schäden aufgrund von technischen Störungen, für Verzögerungen oder Unterbrechungen von Übertragungen oder für Schäden, die im Zusammenhang mit der Teilnahme an dem Gewinnspiel stehen, es sei denn der Veranstalter handelt vorsätzlich oder grob fahrlässig. Hiervon unberührt bleiben etwaige Ersatzansprüche aufgrund von Produkthaftung und der Verletzung von Leben, Körper und Gesundheit sowie von wesentlichen Vertragspflichten. Im Fall der Verletzung von wesentlichen Vertragspflichten, d.h. solcher Pflichten, die die ordnungsgemäße Durchführung des Vertrages erst ermöglichen und auf deren Erfüllung der Nutzer vertrauen darf, ist die Haftung auf den vertragstypisch vorhersehbaren Schaden begrenzt.    <br />

               Die Teilnehmer erkennen durch ihre Teilnahme an, dass die Beteiligung an dieser Werbeaktion auf alleiniges Risiko des Teilnehmers erfolgt. Der Veranstalter, seine Organmitglieder, Direktoren, Beschäftigten und Agenten geben, soweit rechtlich zulässig, keinerlei Garantien oder Zusicherungen über die Richtigkeit oder Vollständigkeit der Inhalte auf www.milka.com oder auf mit dieser Website verlinkten Seiten ab und übernehmen keine Haftung oder Verantwortung für:
                <br />
                <ul>
                  <li>
                  a.	Fehler, Irrtümer oder Ungenauigkeiten von Inhalten;
                  </li>
                  <li>
                  b.	Sachschäden jeglicher Art, die aus der Teilnahme an der Werbeaktion herrühren;
                  </li>
                  <li>
                  c.	Todesfälle oder Personenschäden, die sich als direkte Folge der Teilnahme an der Werbeaktion ergeben, sofern diese nicht durch zumindest Fahrlässigkeit von Mondelez International, seinen Organmitgliedern, Direktoren, Beschäftigten und Agenten verursacht werden;
                  </li>
                  <li>
                  d.	Unterbrechungen oder Abbrüche der Übertragung an oder von www.milka.com;
                    {/* <a
                      href="https://www.milka.com"
                      target="_blank"
                      onClick={appGoogleTracking.processEventCTA}
                      data-event="go_to_milka"
                      data-category="Terms and Conditions Page"
                      data-action="Go to Page"
                      data-label="MAIN_SITE_TC_OTHER"
                    >
                      www.milka.com;
                    </a> */}
                  </li>
                  <li>
                  e.	Softwarefehler, Viren usw., die an oder durch www.milka.com an einen Dritten übertragen werden, und/oder
                  </li>
                  <li>
                  f.	Verluste oder Schäden jeglicher Art, die sich aus der Nutzung geposteter, per E-Mail versandter, übertragener oder anderweitig über www.milka.com zur Verfügung gestellter Inhalte ergeben.
                  </li>
                </ul>
                <br></br>
                Gewinne können nicht gegen Bargeld oder sonstige Formen der Entschädigung eingelöst werden, sofern nichts anderes angegeben wurde. Falls der Nutzer einen Teil eines Gewinns aus irgendeinem Grund nicht innerhalb des vom Veranstalter vorgegebenen Zeitrahmens einlöst, verfällt dieses Gewinnelement für den Nutzer ersatzlos, und es wird keine Geldzahlung oder sonstige Form von Entschädigung als Ersatz für dieses Element geliefert, soweit nichts anderes vereinbart wurde.
                <br />
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings sub">
              5.	Vorzeitige Beendigung des Gewinnspiels /Änderung der Teilnahmebedingungen
              </p>
              <p>
              Mondelez behält sich vor, dieses Gewinnspiel zu jedem Zeitpunkt ohne Vorankündigung und ohne Angabe von weiteren Gründen abzubrechen oder zu beenden, insbesondere bei Infizierung durch Computerviren, Netzwerkausfällen, Softwarefehlern, Manipulationen, unbefugten Eingriffen, Betrug, technischem Versagen oder Ursachen außerhalb der Kontrolle des Veranstalters, die die Verwaltung, Sicherheit, Fairness, Integrität oder ordnungsgemäße Durchführung dieser Werbeaktion stören oder beeinträchtigen.
                <br />
                Mondelez kann diese Bedingungen jederzeit und ohne weitere Benachrichtigungen ändern. .
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings sub">6. Sonstiges</p>
              <p>
              Mit erfolgreicher Registrierung gem. Ziffer 3.1 nimmt der Teilnehmer automatisch an dem Gewinnspiel teil und erklärt sich mit diesen Teilnahmebedingungen einverstanden. 
              </p>
              <p>
              Sollten einzelne dieser Teilnahmebedingungen ungültig sein oder werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen hiervon unberührt. Eine unwirksame Bestimmung ist durch eine solche zu ersetzen, die rechtlich möglich ist und der unwirksamen inhaltlich am nächsten kommt. Entsprechendes gilt für eventuelle Regelungslücken.
              </p>
              <p>
              Diese Teilnahmebedingungen unterliegen in Deutschland einschlägigem deutschem Recht, wobei alle Streitigkeiten bezüglich der Auslegung dieser Teilnahmebedingungen der ausschließlichen Zuständigkeit der deutschen Gerichte unterliegen.
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings sub">
                7. Angaben zu EU Streitschlichtungsverfahren
              </p>
              <p>
              Gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG) weisen wir darauf hin, dass wir nicht verpflichtet und nicht bereit sind, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                <br />
                Sie können sich aber jederzeit an unseren Verbraucherservice wenden.
              </p>
              <p>
              Informationen zur Online-Streitbeilegung:
                <br />
                Die EU-Kommission stellt unter folgendem Link eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog. „OS-Plattform“) bereit, die als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen, dient: https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings sub">8. Abstandserklärung</p>
              <p>
              Das Gewinnspiel steht in keiner Verbindung zu Facebook und Instagram und wird in keiner Weise von Facebook gesponsert, unterstützt oder organisiert. Für keinen der im Rahmen dieser Aktion veröffentlichten Inhalte ist Facebook oder Instagram verantwortlich.
              </p>
              <p>
              Der Empfänger der vom Teilnehmer bereitgestellten Informationen ist nicht Facebook oder Instagram, sondern Mondelez Deutschland. Mondelez Deutschland stellt Facebook oder Instagram von berechtigten Ansprüchen Dritter im Zusammenhang mit diesem Gewinnspiel frei. Sämtliche Fragen, Kommentare oder Beschwerden zur Aktion sind nicht an Facebook oder Instagram zu richten, sondern an Mondelez Deutschland unter 
              </p>
            </div>
            <div className="s-content-copy__item">
              <p className="headings">II. Datenschutzhinweise</p>
              <p>
              Verantwortliche Stelle für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen dieses Gewinnspiels ist die Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen (Mondelez Deutschland). Mit der Durchführung des Gewinnspiels sind die folgenden Agenturen beauftragt: Consultix GmbH, Wachtstr. 17-24 28195 Bremen Germany, PROXIMITY BBDO, 52 AVENUE EMILE ZOLA, 92100 BOULOGNE BILLANCOURT, France,
Baudek & Schierhorn Shopper Marketing GmbH, Schellerdamm 16, 21079 Hamburg, D&K Brand Activation, Gasstraße 2c, 22761 Hamburg und die bürozwei GmbH & Co KG, Theodor-Heuss-Allee 21 28215 Bremen auf einem Server in Deutschland. Die Consultix GmbH, Wachtstr. 17-24 28195 Bremen Germany, PROXIMITY BBDO, 52 AVENUE EMILE ZOLA, 92100 BOULOGNE BILLANCOURT, France, Baudek & Schierhorn Shopper Marketing GmbH, Schellerdamm 16, 21079 Hamburg, D&K Brand Activation, Gasstraße 2c, 22761 Hamburg, Olympia-Verlag GmbH, Badstraße 4-6, 90402 Nürnberg und die bürozwei GmbH & Co KG, Theodor-Heuss-Allee 21 28215 Bremen unterliegen sämtlichen anwendbaren Datenschutzgesetzen.

              </p>

              <p>
              Die genannten Agenturen sind im Rahmen einer Auftragsverarbeitung für uns tätig und an die Anforderungen entsprechender Verträge gebunden. 
              </p>
              <p>
              Eine Weitergabe Ihrer personenbezogenen Daten an in diesem Datenschutzhinweis nicht genannte Dritte erfolgt nicht.
              </p>
              <p>
              Wir speichern personenbezogene Daten von Ihnen nach Maßgabe der rechtlichen Vorschriften und ausschließlich zum Zweck der Abwicklung dieses Gewinnspiels (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. fb) der Datenschutz-Grundverordnung EU 2016/679, DS-GVO) und geben diese gegebenenfalls und sofern Landesvorschriften dies erforderlich machen zur Nachverfolgung von COVID-19 Infektionen im Zusammenhang mit der Ticketeinlösungen aufgrund rechtlicher Vorschriften (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. c) der Datenschutz-Grundverordnung EU 2016/679, DS-GVO) an den Verein weiter. 
              </p>
              <p>
              Es werden die E-Mailadresse, der Name, die Telefonnummer und die Adressdaten der Teilnehmer, sowie das Geburtsdatum von den Gewinnern erfasst und ausschließlich zur Verifizierung der Teilnahme/ Gewinner gespeichert. 
              </p>

              <p>
              Im Rahmen der Covid 19-Pandemie kann es von den regionalen Gesundheitsämtern zu besonderen Auflagen bei der Vergabe von Tickets und beim Stadionbesuch kommen. Mondelez Deutschland wird die Daten der betreffenden Gewinner (Vorname, Nachname und Adresse) an den Verein, bei dem der Gewinner ein Ticket einlösen möchte, weitergeben. Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 c) DSGVO i.V.m. mit der einschlägigen Corona-Schutzverordnung des jeweiligen Bundeslandes. Die Datenverarbeitung durch den Verein liegt außerhalb der Verantwortung von Mondelez Deutschland. Die Hoheit bei der Vergabe der Karten sowie bei der Umsetzung der behördlichen Anordnungen in den Stadien liegt vielmehr bei den Vereinen. Aus diesem Grund wird es personalisierte Tickets geben, bei denen zwangsläufig die Personalien der Zuschauer registriert und an die Vereine bzw. die ortsansässigen Gesundheitsbehörden weitergeben werden. Die Gewinner werden zusätzlich aufgefordert, diese zusätzlichen Allgemeinen Geschäftsbedingungen, Teilnahmebedingungen und Datenschutzhinweise des jeweiligen Vereins im Gewinnfall zusätzlich zu akzeptieren und der Datenweitergabe durch die Vereine an die zuständigen Gesundheitsämter zum Zwecke der Nachverfolgung einer möglichen COVID-19 Infektion zuzustimmen.
              </p>

              <p>
              Zum Zweck der öffentlichen Berichterstattung über die Fußballspiele oder der jeweiligen Veranstaltung können Bild- und Bildtonaufnahmen von den Gewinnern sowie den Begleitpersonen von Mondelez, der DFL, den mit ihnen nach § 15 f. AktG verbundenen Unternehmen, den Fußballclubs/ ihren jeweiligen Verbänden und sonst befugten Dritten (z.B. Presse, Rundfunk) verarbeitet, verwertet und veröffentlicht werden. Sofern Mondelez  Bild- und Bildtonaufnahmen und Fotos der Veranstaltung für die Nachberichterstattung für rein interne Zwecke sowie zu Werbezwecken nutzen möchte, holt Mondelez hierzu eine gesonderte Einwilligungserklärung der Teilnehmer ein. Die Rechtsgrundlage für die benannten Veröffentlichungen/ Nutzungen stellt das berechtigte Interesse der jeweiligen Unternehmen dar (Art. 6 Abs. 1 S. 1 Buchstabe f der DSGVO). Bei weitergehenden Nutzungen, die nicht von einem berechtigten Interesse abgedeckt sind, behält sich Mondelez vor, bei den Gewinnern, ihren Begleitpersonen sowie den jeweiligen 
              </p>

              <p>
              Erziehungsberechtigten eine entsprechende Einwilligung (Art. 6 Abs. 1 S. 1 Buchstabe a der DSGVO) anzufragen. 
              </p>
              <p>
              Sie können der Verarbeitung Ihrer personenbezogenen Daten jederzeit ohne Angabe von Gründen widersprechen. Hierzu genügt eine formlose E-Mail an verbraucherservice@mdlz.com. Im Falle eines Widerspruchs werden Ihre personenbezogenen Daten selbstverständlich gelöscht, und eine Teilnahme an dem Gewinnspiel ist nicht mehr möglich.  
              </p>
              <p>Soweit keine gesetzlichen Aufbewahrungspflichten bestehen, erfolgt eine umgehende Löschung der Daten, nachdem das Gewinnspiel abgewickelt ist. Eine umgehende Löschung erfolgt bezüglich der Daten der Nichtgewinner. Gewinnerdaten sind aus gesetzlichen und buchhalterischen Gründen nach Maßgabe der §§ 257 HGB, 147 AO, 14b UStG aufzubewahren. Für diese Speicherung der Gewinnerdaten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. c) DS-GVO, da die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der Mondelez Deutschland unterliegt.   </p>

              <p>
              Sie sind unter anderem berechtigt (i) Auskunft darüber zu erhalten, ob und welche personenbezogenen Daten wir über Sie gespeichert haben und Kopien dieser Daten zu erhalten, (ii) die Berichtigung, Ergänzung oder das Löschen Ihrer personenbezogenen Daten, die falsch sind oder nicht rechtskonform verarbeitet werden, zu verlangen, (iii) von uns zu verlangen, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken, und (iv) <strong>der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen,</strong> (v) Datenübertragbarkeit zu verlangen, (vi) ihre Einwilligung in die Datenverarbeitung jederzeit mit Wirkung für die Zukunft zu widerrufen (vii) in Deutschland bei der deutschen (für weitere Informationen, siehe https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html Beschwerde zu erheben.
              </p>

              <p>
                Unsere Kontaktdaten:
                <br />
                Sollten Sie zu der Verarbeitung Ihrer personenbezogenen Daten Fragen oder Anliegen haben, wenden Sie sich bitte an uns:
                <br />
                Mondelez Deutschland GmbH, Konsul Smidt Str. 21, 28217 Bremen, Deutschland
                <br />
                {/* E-Mail:{" "}
                <a
                  href="mailto:Verbraucherservice@mdlz.com"
                  onClick={appGoogleTracking.processEventCTA}
                  data-event="go_to_email_bottom"
                  data-category="Terms and Conditions Page"
                  data-action="Go to Email"
                  data-label="ENG_TC_OTHER_"
                >
                  Verbraucherservice@mdlz.com
                </a> */}
                E-Mail: Verbraucherservice@mdlz.com
                <br />
                Unseren Datenschutzbeauftragten in Deutschland können Sie postalisch unter: Mondelez Deutschland Services GmbH & Co. KG, zu Händen des Datenschutzbeauftragten Herrn Uwe Struckmeier, Konsul Smidt Str. 21, 28217 Bremen, Deutschland
                <br />
                oder per E-Mail unter: Datenschutz@mdlz.com erreichen.<br />
{/*                 <a
                  href="mailto:Datenschutz@mdlz.com"
                  onClick={appGoogleTracking.processEventCTA}
                  data-event="go_to_email_data_protection"
                  data-category="Terms and Conditions Page"
                  data-action="Go to Email"
                  data-label="ENG_TC_OTHER_"
                >
                  Datenschutz@mdlz.com
                </a> */}
                Bitte lesen Sie unsere im Übrigen gültigen ausführlichen Datenschutzhinweise für die Datenverarbeitung im Rahmen der Nutzung dieser Webseite unter
                <br />
                DE:
                <br />
                https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D.
{/*                 <a
                  href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D."
                  target="_blank"
                  onClick={appGoogleTracking.processEventCTA}
                  data-event="go_to_mondelez"
                  data-category="Terms and Conditions Page"
                  data-action="Go to Page"
                  data-label="ENG_TC_OTHER"
                >
                  https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D.
                </a> */}
              </p>

              <p>
              Die Webseiten unter www.facebook.com und www.instagram.com und die auf dieser Seite vorgehaltenen Dienste werden angeboten von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Es kann nicht ausgeschlossen werden, dass Facebook die hochgeladenen Daten selbstständig speichert und verarbeitet.
              </p>

              <p>
              Wir haben keinen Einfluss auf den Umfang der Erhebung und die weitere Verwendung dieser Daten durch Facebook. Informationen des Drittanbieters zum Datenschutz können Sie der nachfolgenden Webseite von Facebook und Instagram entnehmen: 
              </p>
              <p>https://www.facebook.com/about/privacy</p>
              <p>https://help.instagram.com/519522125107875</p>
              <p>
              Es ist nicht ausgeschlossen, dass durch Facebook Ihre Daten auch in an die Muttergesellschaft der Facebook Ltd., die Facebook Inc. mit Sitz in den USA weitergeleitet werden. 
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.displayFullVersion();
  }
}

const mapStateToProps = (state) => ({
  bannerState: state.banner,
});

const mapDispatchToProps = {
  setIsBannerShown,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DoppeltTerms)
);
