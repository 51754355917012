
import React from "react";
import { Route, Switch, withRouter } from 'react-router-dom';     
import GLOBAL_CONFIG from "../../../config/config";
import Error from "../../Error/Error";
import BuzzerNetto from '../../Campaign/Buzzer/Netto';
import BuzzerKaufland from '../../Campaign/Buzzer/Kaufland';
import BuzzerEdeka from '../../Campaign/Buzzer/Edeka';
import BuzzerEndNetto from '../../Campaign/Buzzer/EndNetto';
import BuzzerEndKaufland from '../../Campaign/Buzzer/EndKaufland';
import BuzzerEndRewe from '../../Campaign/Buzzer/EndRewe';
import BuzzerEndPenny from '../../Campaign/Buzzer/EndPenny';
import BuzzerRewe from '../../Campaign/Buzzer/Rewe';
import BuzzerPenny from '../../Campaign/Buzzer/Penny';
import BuzzerGlobus from '../../Campaign/Buzzer/Globus';
import BuzzerEndEdeka from '../../Campaign/Buzzer/EndEdeka';
import HoldingEdeka from '../../Campaign/Buzzer/HoldingEdeka';
import HoldingKaufland from '../../Campaign/Buzzer/HoldingKaufland';
import HoldingKewe from '../../Campaign/Buzzer/HoldingKewe';
import HoldingPenny from '../../Campaign/Buzzer/HoldingPenny';
import HoldingNetto from '../../Campaign/Buzzer/HoldingNetto';
import BuzzerEndGlobus from '../../Campaign/Buzzer/EndGlobus';
import HoldingGlobus from "../../Campaign/Buzzer/HoldingGlobus";
import GlobusTerms from "../../Campaign/Buzzer/GlobusTerms";

const KipsRoutes = (props) => {
    return (
        <Switch>
            {/* NEW KIP PAGE ROUTINGS */}
            <Route path={GLOBAL_CONFIG.Route.buzzer.netto} exact component={()=>(<BuzzerNetto {...props} />)}/>
            <Route path={GLOBAL_CONFIG.Route.buzzer.endnetto} exact component={()=>(<BuzzerEndNetto {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.holdingnetto} exact component={()=>(<HoldingNetto {...props} />)} />

            <Route path={GLOBAL_CONFIG.Route.buzzer.kaufland} exact component={()=>(<BuzzerKaufland {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.endkaufland} exact component={()=>(<BuzzerEndKaufland {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.holdingkaufland} exact component={()=>(<HoldingKaufland {...props} />)} />

            <Route path={GLOBAL_CONFIG.Route.buzzer.edeka} exact component={()=>(<BuzzerEdeka {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.endEdeka} exact component={()=>(<BuzzerEndEdeka {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.holdingEdeka} exact component={()=>(<HoldingEdeka {...props} />)} />

            <Route path={GLOBAL_CONFIG.Route.buzzer.rewe} exact component={()=>(<BuzzerRewe {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.endRewe} exact component={()=>(<BuzzerEndRewe {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.holdingRewe} exact component={()=>(<HoldingKewe {...props} />)} />

            <Route path={GLOBAL_CONFIG.Route.buzzer.penny} exact component={()=>(<BuzzerPenny {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.endPenny} exact component={()=>(<BuzzerEndPenny {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.holdingPenny} exact component={()=>(<HoldingPenny {...props} />)} />

            <Route path={GLOBAL_CONFIG.Route.buzzer.globus} exact component={()=>(<BuzzerGlobus {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.endGlobus} exact component={()=>(<BuzzerEndGlobus {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.holdingGlobus} exact component={()=>(<HoldingGlobus {...props} />)} />
            <Route path={GLOBAL_CONFIG.Route.buzzer.globusTerms} exact component={()=>(<GlobusTerms {...props} />)} />
            <Route path="*" component={Error} />
        </Switch>
    );
}

export default withRouter(KipsRoutes);