import React, { useState } from "react";

const PopUp = props => {
  const {desktopImg, mobileImg} = props;
  const [modal, setModal] = useState(true);

  const toggleModal = () => {
    setModal(!modal);
  };

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
{/*       <button onClick={toggleModal} className="btn-modal">
        Open
      </button>
 */}
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="popup-overlay"></div>
          <div className="modal-content">
            <div className="modal-img__container">
                <picture>
                    <source srcSet={desktopImg} media="(min-width: 1024px)" />
                    <source srcSet={mobileImg} media="(min-width: 481px)" />
                    <source srcSet={mobileImg} media="(min-width: 200px)" />
                    <img src={desktopImg} alt="FC Milka Logo" />
                </picture>
            </div>
            <div onClick={toggleModal} className="close-modal">
                <img  src="/resources/imagesBundesliga/buzzer_22/ham-close.png" alt="Popup Close"/>
            </div>
          </div>
        </div>
      )}
      
    </>
  );
}

export default PopUp