import React from "react";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import PopUp from "./PopUp";
import { isDoppeltPromotion } from "../../../modules/helpers";
import _LOCAL from "../../../Models/LocalStorageKeys";
import { isProd } from "../../../modules/helpers";

const IMG_PRIZE={
  begin:"/resources/imagesBundesliga/doppeltgewinnen/banner/1.gif",
  beginMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/1-mobile.gif",
  // TICKETS PRIZE
  ticket:"/resources/imagesBundesliga/doppeltgewinnen/banner/2-tickets.gif",
  ticketMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/2-tickets-sm.gif",
  ticketLukas:"/resources/imagesBundesliga/doppeltgewinnen/banner/2-Lukas.png",
  ticketLukasMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/2-Lukas-Mobile.png",
  ticketRudi:"/resources/imagesBundesliga/doppeltgewinnen/banner/2-Rudi.png",
  ticketRudiMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/2-Rudi-Mobile.png",
   // SHIRT PRIZE
  gutschein:"/resources/imagesBundesliga/doppeltgewinnen/banner/3-gutschein.gif",
  gutscheinMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/3-gutschein-mobile.gif",
  gutscheinLukas:"/resources/imagesBundesliga/doppeltgewinnen/banner/3-Lukas.png",
  gutscheinLukasMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/3-Lukas-Mobile.png",
  gutscheinRudi:"/resources/imagesBundesliga/doppeltgewinnen/banner/3-Rudi.png",
  gutscheinRudiMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/3-Rudi-Mobile.png",
   // BALL PRIZE
  ball:"/resources/imagesBundesliga/doppeltgewinnen/banner/4-ball.gif",
  ballMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/4-ball-mobile.gif",
  ballLukas:"/resources/imagesBundesliga/doppeltgewinnen/banner/4-Lukas.png",
  ballLukasMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/4-Lukas-Mobile.png",
  ballRudi:"/resources/imagesBundesliga/doppeltgewinnen/banner/4-Rudi.png",
  ballRudiMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/4-Rudi-Mobile.png",
   // MAGAZINE ABONNEMENT PRIZE
  magazineAbonnement:"/resources/imagesBundesliga/doppeltgewinnen/banner/5-ek-magazine-abonnement.gif",
  magazineAbonnementMobile:"resources/imagesBundesliga/doppeltgewinnen/banner/5-ek-magazine-abonnement-mobile.gif",
  magazineAbonnementLukas:"/resources/imagesBundesliga/doppeltgewinnen/banner/5-Lukas.png",
  magazineAbonnementLukasMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/5-Lukas-Mobile.png",
  magazineAbonnementRudi:"/resources/imagesBundesliga/doppeltgewinnen/banner/5-Rudi.png",
  magazineAbonnementRudiMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/5-Rudi-Mobile.png",
   // MAGAZINE KICKER PRIZE
  magazineKicker:"/resources/imagesBundesliga/doppeltgewinnen/banner/6-ek-magazine-kicker.gif",
  magazineKickerMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/6-ek-magazine-kicker-mobile.gif",
  magazineKickerLukas:"/resources/imagesBundesliga/doppeltgewinnen/banner/6-Lukas.png",
  magazineKickerLukasMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/6-Lukas-Mobile.png",
  magazineKickerRudi:"/resources/imagesBundesliga/doppeltgewinnen/banner/6-Rudi.png",
  magazineKickerRudiMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/6-Rudi-Mobile.png",
   // VOUCHER PRIZE
  voucher:"/resources/imagesBundesliga/doppeltgewinnen/banner/7-voucher.gif",
  voucherMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/7-voucher-mobile.gif",
  voucherLukas:"/resources/imagesBundesliga/doppeltgewinnen/banner/7-Lukas.png",
  voucherLukasMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/7-Lukas-Mobile.png",
  voucherRudi:"/resources/imagesBundesliga/doppeltgewinnen/banner/7-Rudi.png",
  voucherRudiMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/7-Rudi-Mobile.png",
   // LEIDER NICHT PRIZE
  leiderNicht:"/resources/imagesBundesliga/doppeltgewinnen/banner/8-Leider-nicht-gewonnen.gif",
  leiderNichtMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/8-Leider-nicht-gewonnen-mobile.gif",
  leiderNichtLukas:"/resources/imagesBundesliga/doppeltgewinnen/banner/8-Lukas.png",
  leiderNichtLukasMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/8-Lukas-Mobile.png",
  leiderNichtRudi:"/resources/imagesBundesliga/doppeltgewinnen/banner/8-Rudi.png",
  leiderNichtRudiMobile:"/resources/imagesBundesliga/doppeltgewinnen/banner/8-Rudi-Mobile.png",
}

const GAME_FLOW={
  BEGIN:"0",
  OPEN_FIRST:"1",
  OPEN_SECOND:"2",
  END:"3"
}
const WIN_INSTITUION={
  LUKAS:"1",
  RUDI:"2"
}
const WINNER_TIER={
  loose:"0",
  ball:"1",
  tickets:"2",
  magazineAbonnement:"3",
  kickerMagazine:"4",
  gutschein:"5",
  voucher:"6"
}

const CARD_DETAILS_LEFT={
  tickets:{
    img:{
      desktop:"/resources/imagesBundesliga/doppeltgewinnen/prize/tickets.png",
      mobile:"/resources/imagesBundesliga/doppeltgewinnen/prize/tickets-mobile.png",
    },
    title:"2 x VIP-Tickets",
    paragraph:"Freue dich auf ein Spiel der Bundesliga / 2. Bundesliga der Saison 2023/24 für dich und eine weitere Person. Seid live und hautnah dabei – für einen unvergesslichen Stadion-Besuch. "
  },
  gutschein:{
    img:{
      desktop:"/resources/imagesBundesliga/doppeltgewinnen/prize/shirt.png",
      mobile:"/resources/imagesBundesliga/doppeltgewinnen/prize/shirt-mobile.png",
    },
    title:"Die perfekte Fußsball-Ausstattung",
    paragraph:"Herzlichen Glückwunsch zum Gewinn eines Fanshop Gutscheins à 100€ der Bundesliga / 2. Bundesliga für einen Club deiner Wahl."
  },
  ball:{
    img:{
      desktop:"/resources/imagesBundesliga/doppeltgewinnen/prize/derbystar.png",
      mobile:"/resources/imagesBundesliga/doppeltgewinnen/prize/derbystar-mobile.png",
    },
    title:"DEIN BUNDESLIGA BALL VON DERBYSTAR",
    paragraph:"Gewinne einen <strong>Bundesliga Ball</strong>, Modell Bundesliga Brilliant Replica, Saison 23/24 von DERBYSTAR. Dein perfekter Begleiter für eine Fußball Partie mit Freunden."
  },
  magazineAbonnement:{
    img:{
      desktop:"/resources/imagesBundesliga/doppeltgewinnen/prize/kmagazine.png",
      mobile:"/resources/imagesBundesliga/doppeltgewinnen/prize/kmagazine-mobile.png",
    },
    title:"Dein KICKER EMAGAZINE JAHRESABO",
    paragraph:"Mit dem kicker bist du über alles, was in der Fußballwelt passiert, bestens informiert. Herzlichen Glückwunsch zum Gewinn eines von 150 digitalen Jahresabos in der kicker eMagazine App."
  },
  magazineKicker:{
    img:{
      desktop:"/resources/imagesBundesliga/doppeltgewinnen/prize/kmagazine.png",
      mobile:"/resources/imagesBundesliga/doppeltgewinnen/prize/kmagazine-mobile.png",
    },
    title:"DEINE KICKER EINZELAUSGABE",
    paragraph:"Mit dem kicker bist du über alles, was in der Fußballwelt passiert, bestens informiert. Herzlichen Glückwunsch zum Gewinn einer von 15.000 digitalen Einzelausgaben in der kicker eMagazine App."
  },
  voucher:{
    img:{
      desktop:"/resources/imagesBundesliga/doppeltgewinnen/prize/voucher.png",
      mobile:"/resources/imagesBundesliga/doppeltgewinnen/prize/voucher-mobile.png",
    },
    title:"Dein 11teamsports Gutschein",
    paragraph:"Hier schlägt das Fußballherz höher. Ein Rabattgutschein in Höhe von 20% für 11teamsports wartet auf dich. Dort wirst du garantiert fündig, um deine Ausrüstung zu komplettieren."
  },
  leiderNicht:{
    img:{
      desktop:"/resources/imagesBundesliga/doppeltgewinnen/prize/Leider-nicht-gewonnen.png",
      mobile:"/resources/imagesBundesliga/doppeltgewinnen/prize/Leider-nicht-gewonnen-mobile.png",
    },
    title:"Versuche erneut dein Glück!",
    paragraph:"Immer am Ball bleiben. Mit jedem neuen Kauf eines Milka Produktes kannst du wieder an unserer Aktion teilnehmen. Wir drücken dir ganz fest die Daumen!"
  },
}
const CARD_DETAILS_RIGHT={
  lukas:{
    img:{
      desktop:"/resources/imagesBundesliga/doppeltgewinnen/prize/Lukas.png",
      mobile:"/resources/imagesBundesliga/doppeltgewinnen/prize/Lukas-mobile.png",
    },
    title:"Gutes tun mit Lukas Podolski",
    paragraph:"Doppelt gewinnen – für dich und einen guten Zweck. Dank deiner Teilnahme unterstützt der FC Milka zusammen mit der Lukas Podolski Stiftung das Projekt „Mutmacher“ der Arche. Dieses schenkt Jugendlichen Mut und Hoffnung. Durch Bildung, Workshops und persönliche Betreuung wird das Selbstvertrauen gestärkt und die Selbständigkeit erhöht. Lukas Podolski & Milka schaffen so neue Möglichkeiten für junge Menschen und unterstützen die Chancengleichheit in Deutschland.",
    logoAssociation:"/resources/imagesBundesliga/doppeltgewinnen/prize/LP_Stiftung.png"
  },
  rudi:{
    img:{
      desktop:"/resources/imagesBundesliga/doppeltgewinnen/prize/Rudi.png",
      mobile:"/resources/imagesBundesliga/doppeltgewinnen/prize/Rudi-mobile.png",
    },
    title:"Gutes tun mit Rudi Völler",
    paragraph:"Doppelt gewinnen – für dich und einen guten Zweck. Dank deiner Teilnahme unterstützen der FC Milka und Rudi Völler die Fußball-Ferien-Freizeiten der DFB-Stiftung Egidius Braun. Für das Jahr 2024 wird die Völkerverständigung und Integration das Schwerpunktthema sein. 1.000 Teilnehmende werden an 18 abwechslungsreichen Programmen teilnehmen und neben verschiedenen Fußballangeboten, Ausflüge in Kletterparks, Besuche verschiedener Bundesliga-Clubs, Wertedialoge sowie Besuche prominenter Gäste erleben.",
    logoAssociation:"/resources/imagesBundesliga/doppeltgewinnen/prize/FuFeFrei.png"
  },
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      modal: false,
      baseNamePath: null,
      gameFlow:GAME_FLOW.BEGIN,
      bannerImg:{
        desktop:IMG_PRIZE.begin,
        mobile:IMG_PRIZE.beginMobile
      },
      isWin:"-1",
      winInstitution:"0",
      winTier:"-1"

    };
    this.resize = this.resize.bind(this);
    this.handleAnimation = this.handleAnimation.bind(this);
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  resize() {
    // set isMobile to true if the device is a mobile and false otherwise
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    window.addEventListener("resize", this.resize);
    this.resize();
    _SCROLL_PAGE.ScrollToTop();
  }

  componentWillMount() {
    if(localStorage.getItem(_LOCAL.DoppeltWinPrize) === null) {
      this.props.history.push(GLOBAL_CONFIG.Route.buzzer.main);
    }else{
        let {IsWin,WinTier,WinInstitution} = JSON.parse(localStorage.getItem(_LOCAL.DoppeltWinPrize));
        this.setState({isWin:IsWin})
        this.setState({winTier:WinTier});
        this.setState({winInstitution:WinInstitution})
        localStorage.removeItem(_LOCAL.DoppeltParticipationDetails)
        localStorage.removeItem(_LOCAL.DoppeltWinPrize)
        
    }
    // this.setState({winTier: WINNER_TIER.kickerMagazine})
    // this.setState({winInstitution:WIN_INSTITUION.RUDI})
 
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  handleAnimation(){

    let gameflow = "";
    let bannerImg = {desktop:"",mobile:""}
    console.log(this.state.gameFlow);
    appGoogleTracking.dataLayerPush({
      'dataEvent': 	'open_door_select',
      'dataCategory': 'Gamification Page',
      'dataAction': 	'Click on Open The Doors',
      'dataLabel': 	'GAME_GM_OTHER'
    });
 
    if(this.state.gameFlow === GAME_FLOW.BEGIN){
      gameflow = GAME_FLOW.OPEN_FIRST;

      switch(this.state.winTier){
        case WINNER_TIER.ball:
          bannerImg ={desktop: IMG_PRIZE.ball, mobile:IMG_PRIZE.ballMobile }
          break
        case WINNER_TIER.tickets:
          bannerImg ={desktop: IMG_PRIZE.ticket, mobile:IMG_PRIZE.ticketMobile }
          break
        case WINNER_TIER.kickerMagazine:
          bannerImg ={desktop: IMG_PRIZE.magazineKicker, mobile:IMG_PRIZE.magazineKickerMobile }
          break
        case WINNER_TIER.magazineAbonnement:
          bannerImg ={desktop: IMG_PRIZE.magazineAbonnement, mobile:IMG_PRIZE.magazineAbonnementMobile }
          break
        case WINNER_TIER.gutschein:
          bannerImg ={desktop: IMG_PRIZE.gutschein, mobile:IMG_PRIZE.gutscheinMobile }
          break
        case WINNER_TIER.voucher:
          bannerImg ={desktop: IMG_PRIZE.voucher, mobile:IMG_PRIZE.voucherMobile }
          break
        case WINNER_TIER.loose:
          bannerImg ={desktop: IMG_PRIZE.leiderNicht, mobile:IMG_PRIZE.leiderNichtMobile }
          break
      }
    }
    if(this.state.gameFlow === GAME_FLOW.OPEN_FIRST){
      gameflow = GAME_FLOW.OPEN_SECOND;
      if(this.state.winInstitution === WIN_INSTITUION.LUKAS){
        switch(this.state.winTier){
          case WINNER_TIER.ball:
            bannerImg ={desktop: IMG_PRIZE.ballLukas, mobile:IMG_PRIZE.ballLukasMobile }
            break
          case WINNER_TIER.tickets:
            bannerImg ={desktop: IMG_PRIZE.ticketLukas, mobile:IMG_PRIZE.ticketLukasMobile }
            break
          case WINNER_TIER.kickerMagazine:
            bannerImg ={desktop: IMG_PRIZE.magazineKickerLukas, mobile:IMG_PRIZE.magazineKickerLukasMobile }
            break
          case WINNER_TIER.magazineAbonnement:
            bannerImg ={desktop: IMG_PRIZE.magazineAbonnementLukas, mobile:IMG_PRIZE.magazineAbonnementLukasMobile }
            break
          case WINNER_TIER.gutschein:
            bannerImg ={desktop: IMG_PRIZE.gutscheinLukas, mobile:IMG_PRIZE.gutscheinLukasMobile }
            break
          case WINNER_TIER.voucher:
            bannerImg ={desktop: IMG_PRIZE.voucherLukas, mobile:IMG_PRIZE.voucherLukasMobile }
            break
          case WINNER_TIER.loose:
            bannerImg ={desktop: IMG_PRIZE.leiderNichtLukas, mobile:IMG_PRIZE.leiderNichtLukasMobile }
            break
        }
      }else{
        switch(this.state.winTier){
          case WINNER_TIER.ball:
            bannerImg ={desktop: IMG_PRIZE.ballRudi, mobile:IMG_PRIZE.ballRudiMobile }
            break
          case WINNER_TIER.tickets:
            bannerImg ={desktop: IMG_PRIZE.ticketRudi, mobile:IMG_PRIZE.ticketRudiMobile }
            break
          case WINNER_TIER.kickerMagazine:
            bannerImg ={desktop: IMG_PRIZE.magazineKickerRudi, mobile:IMG_PRIZE.magazineKickerRudiMobile }
            break
          case WINNER_TIER.magazineAbonnement:
            bannerImg ={desktop: IMG_PRIZE.magazineAbonnementRudi, mobile:IMG_PRIZE.magazineAbonnementRudiMobile }
            break
          case WINNER_TIER.gutschein:
            bannerImg ={desktop: IMG_PRIZE.gutscheinRudi, mobile:IMG_PRIZE.gutscheinRudiMobile }
            break
          case WINNER_TIER.voucher:
            bannerImg ={desktop: IMG_PRIZE.voucherRudi, mobile:IMG_PRIZE.voucherRudiMobile }
            break
            case WINNER_TIER.loose:
              bannerImg ={desktop: IMG_PRIZE.leiderNichtRudi, mobile:IMG_PRIZE.leiderNichtRudiMobile }
              break
        }
      }
      window.scrollTo({
        top: 500,
        behavior: 'smooth'
      });
    }
    if(this.state.gameFlow === GAME_FLOW.BEGIN || this.state.gameFlow === GAME_FLOW.OPEN_FIRST ){

      this.setState( {gameFlow : gameflow})
      this.setState( { bannerImg:bannerImg})
    }

    
  }

  
  
  render() {
    return (
      <div  className="weltmeister-game">
        <div className="weltmeister-game__banner">
        <picture>
              <source srcSet={this.state.bannerImg.desktop} media="(min-width: 1024px)" />
              <source srcSet={this.state.bannerImg.mobile} media="(min-width: 481px)" />
              <source srcSet={this.state.bannerImg.mobile} media="(min-width: 200px)" />
              <img className="banner__image" src={this.state.bannerImg.desktop}  onClick={this.handleAnimation} alt="Doppeltgewinnen banner" />
          </picture> 
        </div>
        {this.state.gameFlow == GAME_FLOW.OPEN_SECOND &&
          <div className="weltmeister-game__bottom">
            {this.lastSection()}
            {this.cardSection()}
          </div>
        }
        
       
       
      </div>
    );
  }

  modalTracking(event) {
    this.setState({ modal: true });
    appGoogleTracking.processEventCTANavLink(event);
  }

 
  lastSection() {
    let leftContent={
      title:"",
      image:"",
      paragraph:""
    }
    let rightContent={
      title:"",
      image:"",
      paragraph:"",
      logoAssociation:""
    }

    switch(this.state.winTier){
      case WINNER_TIER.tickets:
        leftContent ={
            image:CARD_DETAILS_LEFT.tickets.img,
            title:CARD_DETAILS_LEFT.tickets.title,
            paragraph:<p dangerouslySetInnerHTML={{ __html: CARD_DETAILS_LEFT.tickets.paragraph }} /> }
        break
      case WINNER_TIER.ball:
        leftContent ={
          image:CARD_DETAILS_LEFT.ball.img,
          title:CARD_DETAILS_LEFT.ball.title,
          paragraph:<p dangerouslySetInnerHTML={{ __html: CARD_DETAILS_LEFT.ball.paragraph }} /> }
        break
      case WINNER_TIER.kickerMagazine:
        leftContent ={
          image:CARD_DETAILS_LEFT.magazineKicker.img,
          title:CARD_DETAILS_LEFT.magazineKicker.title,
          paragraph:<p dangerouslySetInnerHTML={{ __html: CARD_DETAILS_LEFT.magazineKicker.paragraph }} /> }
        break
      case WINNER_TIER.magazineAbonnement:
        leftContent ={
          image:CARD_DETAILS_LEFT.magazineAbonnement.img,
          title:CARD_DETAILS_LEFT.magazineAbonnement.title,
          paragraph:<p dangerouslySetInnerHTML={{ __html: CARD_DETAILS_LEFT.magazineAbonnement.paragraph }} /> }
        break
      case WINNER_TIER.gutschein:
        leftContent ={
          image:CARD_DETAILS_LEFT.gutschein.img,
          title:CARD_DETAILS_LEFT.gutschein.title,
          paragraph:<p dangerouslySetInnerHTML={{ __html: CARD_DETAILS_LEFT.gutschein.paragraph }} /> }
        break
      case WINNER_TIER.voucher:
        leftContent ={
          image:CARD_DETAILS_LEFT.voucher.img,
          title:CARD_DETAILS_LEFT.voucher.title,
          paragraph:<p dangerouslySetInnerHTML={{ __html: CARD_DETAILS_LEFT.voucher.paragraph }} /> }
        break
      case WINNER_TIER.loose:
        leftContent ={
          image:CARD_DETAILS_LEFT.leiderNicht.img,
          title:CARD_DETAILS_LEFT.leiderNicht.title,
          paragraph:<p dangerouslySetInnerHTML={{ __html: CARD_DETAILS_LEFT.leiderNicht.paragraph }} /> }
        break
    }
    console.log(this.state.winInstitution)
    switch(this.state.winInstitution){
      case WIN_INSTITUION.LUKAS:
        rightContent= {
          image:CARD_DETAILS_RIGHT.lukas.img,
          title:CARD_DETAILS_RIGHT.lukas.title,
          paragraph:CARD_DETAILS_RIGHT.lukas.paragraph,
          logoAssociation:CARD_DETAILS_RIGHT.lukas.logoAssociation
        }
        break
      case WIN_INSTITUION.RUDI:
        rightContent= {
          image:CARD_DETAILS_RIGHT.rudi.img,
          title:CARD_DETAILS_RIGHT.rudi.title,
          paragraph:CARD_DETAILS_RIGHT.rudi.paragraph,
          logoAssociation:CARD_DETAILS_RIGHT.rudi.logoAssociation
        }
        break
    }
    return (
      <div className="q4-game">
        <div className="player-wrapper">   
          <div className="player-wrapper__content">   
          <picture>
              <source srcSet={leftContent.image.desktop} media="(min-width: 1024px)" />
              <source srcSet={leftContent.image.mobile} media="(min-width: 481px)" />
              <source srcSet={leftContent.image.mobile} media="(min-width: 200px)" />
              <img  src={leftContent.image.desktop}   alt="Prize info" />
          </picture> 
            <h4>
             {leftContent.title}
            </h4>
            {leftContent.paragraph}
          </div>
          <div class="player-wrapper__content">   
          <picture>
              <source srcSet={rightContent.image.desktop} media="(min-width: 1024px)" />
              <source srcSet={rightContent.image.mobile} media="(min-width: 481px)" />
              <source srcSet={rightContent.image.mobile} media="(min-width: 200px)" />
              <img  src={rightContent.image.desktop} className="winInstitution"    alt="lukas" />
          </picture> 
            <h4>
            {rightContent.title}
            </h4>
            <p>
            {rightContent.paragraph}
            </p>
            <img className="logo-association" src={rightContent.logoAssociation} alt="LP_Stiftung"/>
          </div> 
        </div>
      </div>
    )
  }

  cardSection(){
    return(
      <>
    <div className="s-content-end">
    <div className="end-element">
        <div className="end-element__item">
            <img src="/resources/imagesBundesliga/doppeltgewinnen/end/teaser-1.png" />
            <div className="end-element__copy">
                    <h3>100% Genuss</h3>
                    <p>Milka Mmmax Großtafeln bieten dir mehr Schokolade, großzügige Zutaten und multidimensionale Geschmackserlebnisse.</p>
                <a href="https://fcmilka.de/shop" target="_blank" exact data-event="buy_online" data-category="Gamification Page" data-action="Go to Page" data-label="MILKA_GM_OTHER" onClick={appGoogleTracking.processEventCTANavLink}>
                    <button className="btn__container btn--secondary-lilac"> JETZT VERSCHENKEN </button>
                </a>
            </div>
        </div>
      <div className="end-element__item">
          <img src="/resources/imagesBundesliga/doppeltgewinnen/end/teaser-2.png" />
          <div className="end-element__copy">
                  <h3>Neues von Milka</h3>
                  <p>
                  Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.</p>
              <a href="https://www.milka.de/neues" target="_blank" exact data-event="explore_new" data-category="Gamification Page" data-action="Go to Page" data-label="MILKA_GM_OTHER" onClick={appGoogleTracking.processEventCTANavLink}>
                  <button className="btn__container btn--secondary-lilac">NEUES ENTDECKEN</button>
              </a>
          </div>
      </div>
    <div className="end-element__item">
        <img src="/resources/imagesBundesliga/doppeltgewinnen/end/teaser-3.png" />
        <div className="end-element__copy">
                <h3>Milka Engagement</h3>
                <p>
                Du möchtest mehr über das Engagement von Milka im Kakaoanbau erfahren? Oder wissen, warum Milka so zart ist?</p>
            <a href="https://www.milka.de/uber-milka" target="_blank" exact data-event="more_about_MILKA" data-category="Gamification Page" data-action="Go to Page" data-label="MILKA_GM_OTHER" onClick={appGoogleTracking.processEventCTANavLink}>
                <button className="btn__container btn--secondary-lilac">MEHR ÜBER MILKA</button>
            </a>
        </div>
    </div>
</div>
</div>
    </>
    )
  }
}

export default withTranslation()(Game);
