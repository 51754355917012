const _TICKET = {
    CAT2: {
        desktop : "/resources/imagesBundesliga/fancenter/gifts/CAT-ticket.png",
        mobile : "/resources/imagesBundesliga/fancenter/gifts/CAT-ticket-sm.png"
    },
    VIP: "/resources/imagesBundesliga/fancenter/gifts/VIP-ticket.png",
    Q1: {
        CAT: "/resources/imagesBundesliga/fancenter/gifts/CAT2-Ticket-Q1.png",
        VIP: "/resources/imagesBundesliga/fancenter/gifts/VIP-Ticket-Q1.png",
    },
    Q2: {
        CAT: "",
        VIP: "",
    },
    Q3: {
        CAT: "/resources/imagesBundesliga/fancenter/gifts/CAT2-Ticket-Q3.png",
        VIP: "/resources/imagesBundesliga/fancenter/gifts/VIP-Ticket-Q3.png",
    }
}


export default _TICKET;