const { CodeKeyList } = require('../Models/CodeKeys');
var CodeRules = require('./CodeRules').default;

export const Code = {
    //Code validation from Oreo Style
    customCodeValidation: function(locale, value){
        const regUA = /^[34679ACDEFGHJKLMNPQRTUXWY]{8}$/;
        const regOP = /^OP[0-9A-Za-hk-np-z]{8}.[0-9A-Za-hk-np-z]{2}.[0-9A-Za-hk-np-z]{5}$/;
        const regOT = /^OTM41(0|9)[\d]{3}(1|2).[\dA-Z]{5}$/;
        const regOV = /^OVI(04|05)(0|9)[\d]{3}(1|2).[\dA-Z]{5}$/;
        const regOVI1 = /^OVI[\dA-Z ]{7,13}$/;
        const regOVI2 = /^OVI[\dA-Za-z- ]{12,16}$/;
        const regOTM1 = /^OTM[\dA-Z ]{7,13}$/;
        const regOTM2 = /^OTM[\dA-Za-z- ]{12,16}$/;
 
    
        // check if is locale iz Germany
        const de = locale.includes('de');
        // check if is locale iz Ukrain or khazak
        const kzOrUa = (locale.toLowerCase().includes('ua') || locale.toLowerCase().includes('kz'));
    
        //validation only for germany
        const deValidation = (_code, _prefix) => {
            const regOPA1 = /^OP[\dA-Z ]{8,13}$/;
            const regOVI1 = /^OVI[\dA-Z ]{7,13}$/;
            const regOTM1 = /^OTM[\dA-Z ]{7,13}$/;
            const regOPA2 = /^OP[\dA-Za-z- ]{14,16}$/;
            const regOVI2 = /^OVI[\dA-Za-z- ]{12,16}$/;
            const regOTM2 = /^OTM[\dA-Za-z- ]{12,16}$/;
 
            switch(_prefix){
                case 'OP':
                   return (regOPA1.test(_code) || regOPA2.test(_code) || regUA.test(_code));
                case 'OT':
                    return (regOTM1.test(_code) || regOTM2.test(_code) || regUA.test(_code));
                case 'OV':
                    return (regOVI1.test(_code) || regOVI2.test(_code) || regUA.test(_code));
                default:
                return regUA.test(_code);
            }
        }
    
        if(kzOrUa){ // if locale is UA ok KZ
            
            return regOP.test(value) || regUA.test(value);
        }
        else {
            if(value.length <= 1){
                return false;
            } else {// if typed value is more than 2 characters
                if(value.toUpperCase().startsWith('OP')){
                    return de ? deValidation(value, "OP") : regOP.test(value);
                }
                else if(value.toUpperCase().startsWith('OT')){
                    return de ? deValidation(value, "OT") : (regOTM1.test(value) || regOTM2.test(value) || regUA.test(value));
                }
                else if(value.toUpperCase().startsWith('OV')){
                    return de ? deValidation(value, "OV") : (regOVI1.test(value) || regOVI2.test(value) || regUA.test(value));
                }
                else {
                    return regUA.test(value);
                }
            }
        }
    },
    Validate: (value) => {
        var keyList = [],
            isDate = value.replace(/\s/g, '').indexOf("/") > -1 || value.replace(/\s/g, '').indexOf(":") > -1 ? true : false,
            isText = value.replace(/\s/g, '').length > 4 ? true : false;

        if(isDate) {
            keyList = CodeKeyList.date
        } else if (isText) {
            keyList = CodeKeyList.text
        }

        return ParticipationCodeValidation(value.replace(/\s/g, ''), keyList);
    }
}

function ParticipationCodeValidation (codeValue, validationKeys = []) {
    const validationRules = [...validationKeys, "default", "CONTAINS"];

    const validationResponse = validationRules.map( (ruleKey) => {
        if(validateCode( codeValue, CodeRules[ruleKey])) {
            return true;
        }
    });

    if(validationResponse.indexOf(true) != -1) {
        return true;
    }

    return false;
}

//#region UNIT TEST
// console.log( ParticipationCodeValidation("OOT78", ["AAA-D{2}"]) )      // true
// console.log( ParticipationCodeValidation("IOUSADSN016789") )           // true
// console.log( ParticipationCodeValidation("4017040223807") )            // true
// console.log( ParticipationCodeValidation("4017040223807q") )           // false
// console.log( ParticipationCodeValidation("4017040223807q") )           // false
//#endregion 

/**
 * 
 * @param {string} code code inserted by user
 * @param {Objext} validationObj Validation object from CodeRules
 * @returns Boolean
 */
function validateCode( code, validationObj ) {
    if(!!validationObj) {
        const _TYPE = validationObj.type;
        var isValid = false,
        response = Object.keys(validationObj).forEach( (key) => {
            if(key === "type") {
                return false;
            }

            if(!isValid) {
                switch (_TYPE) {
                    case "REGEX":
                        if(validationObj[key].test(code)) {
                            isValid = true;
                        }
                        break;
                    case "CONTAINS":
                        if(code.toLowerCase().indexOf(validationObj[key].toLowerCase()) != -1) {
                            isValid = true;
                        }
                        break;
                    case "EXACT":
                        if(code === validationObj[key]) {
                            isValid = true;
                        }
                        break;

                    default:
                        break;
                }
            }

            return true

        })

        return isValid;
    }
    
    return false;
}

// module.exports = Code;