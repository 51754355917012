import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import _SCROLL_PAGE from "../../../modules/ScrollPage";

class Q4Faq extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div id="buzzer-faqs" className="weltmeister-faqs">
                { this.faqContent(this.props) }
                {/* {this.footerSection()} */}
                <Footersocial />
            </div>
        );
    }

    faqContent(props) {
        const iconPlus = "/resources/imagesBundesliga/buzzer/plus.png";
        const iconMinus = "/resources/imagesBundesliga/buzzer/minus.png";

        return (
            <div className="content-faq">
                <div className="content-faq__item">
                    <h6>Wie läuft das Gewinnspiel ab?</h6>
                    <img
                        src={iconMinus}
                        className="content-faq__img shown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content show">
                        <ul>
                            <li>
                            Kaufe innerhalb des Aktionszeitraumes vom 03.10.2022 bis 18.12.2022
Aktionsprodukte von Milka und/oder OREO.
                            </li>
                            <li>
                            Gib den Produktcode (auf der Produktverpackung) auf dieser Seite bis zum
31.12.2022 ein und gib deine Kontaktdaten an. (Hinweis: Befindet sich kein
Produktcode auf der Produktverpackung, so müssen die Zahlen beim Barcode
eingegeben werden.)
                                <p></p>
                                Zusätzlich benötigen wir noch die Angabe des Händlers, dein Einkaufsdatum
und die Aktionsmarke, die du eingekauft hast. Online-Registrierung
erforderlich.
                            </li>
                            <li>
                            Nach Eingabe des Produktcodes erhältst du eine Bestätigungsnachricht, dass
deine Registrierung erfolgreich war.
                            </li>
                            <li>
                            Die Gewinner werden nach Ende des Aktionszeitraums am 03.01.2023 unter
allen Teilnehmern per Zufallsprinzip ermittelt. Die Gewinner werden im
Anschluss innerhalb von 48 Stunden per E-Mail informiert.
                            </li>
                            <li>Bitte bewahre den Original-Kaufbeleg gut auf.</li>
                        </ul>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Mit welchen Produkten kann ich teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Die Aktion ist gültig für alle Produkte der Marken Milka und/oder OREO.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wo finde ich die Produkte für das Gewinnspiel?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                        Du findest die Produkte überall dort im Handel, wo du auch sonst deine
Lieblingsprodukte von Milka und/oder OREO findest.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich mehrere Produktcodes hochladen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>
                        Ja, Mehrfachteilnahmen mit jeweils neuem Produktcode sind innerhalb des
Aktionszeitraums erlaubt. Maximal ein Gewinn pro Tag/Person.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie lange kann ich an der Aktion teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Du hast vom 03.10.2022 bis 31.12.2022 die Möglichkeit, deinen Produktcode
einzugeben. Es nehmen nur Aktionsprodukte teil, die im Zeitraum vom 03.10.2022 bis
18.12.2022 gekauft wurden.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann jeder an der Aktion teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Es können natürliche Personen ab 18 Jahren mit Wohnsitz in Deutschland teilnehmen.
Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen
sowie jeweils deren Angehörige</p>
                        <p>*Ausschluss, Änderungen oder Einschränkungen des Gewinns insbesondere aus
Gründen der vorherrschenden Pandemie- und Kriegssituation vorbehalten. Der
Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine
anderweitige Kompensation, Rechtsweg ist ausgeschlossen. Nicht kombinierbar mit
anderen Aktionen</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie erfahre ich, ob und was ich gewonnen habe?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Die Gewinnermittlung erfolgt unter allen Teilnehmern per Zufallsprinzip nach Ende
des Aktionszeitraums am 03.01.2023. Die Gewinner werden im Anschluss innerhalb
von 48 Stunden per E-Mail informiert.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich meine Daten löschen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Nach Ende der Aktion werden alle Teilnehmerdaten gelöscht. Soweit diese aus
gesetzlichen Gründen nicht gelöscht werden dürfen, werden deine Daten gesperrt
und nach Ablauf der handels- und steuerrechtlichen Aufbewahrungsfrist gelöscht.
Aber falls du deine Daten bereits während des Aktionszeitraums löschen möchtest, ist
das natürlich möglich. Dazu wendest du dich bitte direkt an
unseren <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank">Verbraucherservice</a>*. Eine Teilnahme am Gewinnspiel ist dann leider nicht
mehr möglich.</p>
                    
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>An wen kann ich mich wenden, wenn ich weitere Fragen habe?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        onClick={(e) => {
                            this.showItemContent(e, iconMinus, iconPlus);
                        }}
                    />
                    <div className="item-content">
                        <p>Vielleicht hast du noch weitere Fragen, die hier nicht beantwortet worden sind. Dann
wende dich am besten direkt an unseren <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank">Verbraucherservice</a>*.</p>
                        <p>*Falls der hier hinterlegte Link zum Verbraucherservice nicht funktioniert, kannst du
über den Kontaktlink im Bereich „Service“ den Verbraucherservice erreichen.</p>
                    </div>
                </div>
                
                
            </div>
        );
    }

    showItemContent(e, iconMinus, iconPlus) {
        let classes = Array.from(e.target.classList);
        let itemContent = e.target.parentNode.children[2];

        if(classes.includes("notshown")){
            e.target.setAttribute( 'src', iconMinus);
            e.target.classList.remove("notshown");
            e.target.classList.add("shown");
            itemContent.classList.add("show");
        }else{
            e.target.setAttribute( 'src', iconPlus);
            e.target.classList.remove("shown");
            e.target.classList.add("notshown");
            itemContent.classList.remove("show");
        }
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <div className="content-separator">
                    <h2>
                        Mit deiner Lieblingsschokolade von Milka gewinnen!
                    </h2>
                    <h3 className="special">
                        Welches ist dein Lieblingsprodukt von Milka? Bist du Fan der zarten Milka Alpenmilch Schokolade? Stehst du auf die Milka Mmmax mit knackigen Erdnüssen und cremigem Karamell? Kannst du den luftig gebackenen Milka Waffelinis nicht widerstehen? Sind unsere Milka Cookie Sensations mit OREO Creme-Füllung vielleicht dein Favorit? Oder naschst du doch am liebsten aus unserem Milka Zarte Momente Mix – damit du dich nie für eine Sorte entscheiden musst?
                    </h3>
                
                    <img src="\resources\imagesBundesliga\buzzer\products.png" alt="Products" />
                
                    <div className="cta-container">
                        <a href="https://www.milka.de/alle-produkte" target="_blank" className="btn--anchor no-decoration">
                            <button className="btn__container btn--lilac">
                                MILKA SCHOKOLADE ENTDECKEN
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
    
export default withTranslation()(Q4Faq);