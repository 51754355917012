import React from "react";
import { NavLink } from 'react-router-dom';
import { mainLoaderToggle } from "../../../modules/helpers";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import _LOCAL from "../../../Models/LocalStorageKeys";
class Q4Selection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lukas: null,
            rudi: null,
            PlayerValue: null,
            lukasImage: "/resources/imagesBundesliga/weltmeister/selection-amimation/lukas_select-me.gif",
            rudiImage : "/resources/imagesBundesliga/weltmeister/selection-amimation/rudi_select-me.gif"
        }

        this.mateSelection = this.mateSelection.bind(this);
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        if(localStorage.getItem(_LOCAL.ProductCode) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.home);
        }
        localStorage.removeItem(_LOCAL.PlayerValue);
        this.mateSelection();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    setPlayerValue() {
        localStorage.setItem(_LOCAL.PlayerValue, this.state.PlayerValue);
    }

    mateSelection() {
        const mate_1 = document.getElementById("star_1");
        const mate_2 = document.getElementById("star_2");

        const divMate1 = document.getElementById("mate_1");
        const divMate2 = document.getElementById("mate_2");

        mate_1.addEventListener('click', () => {
            this.setState({lukas:true});
            this.setState({rudi:false});
            this.setState({PlayerValue:1});
            this.setState({lukasImage:"/resources/imagesBundesliga/weltmeister/selection-amimation/lukas_selected.gif"});
            this.setState({rudiImage:"/resources/imagesBundesliga/weltmeister/selection-amimation/rudi_not-selected.gif"});
            /* mate_1.src ="/resources/imagesBundesliga/weltmeister/selection-amimation/lukas_selected.gif";
            mate_2.src ="/resources/imagesBundesliga/weltmeister/selection-amimation/rudi_not-selected.gif"; */
            divMate1.classList.add("lightUp");
            divMate2.classList.remove("lightUp");
        }
        ); 

        mate_2.addEventListener('click', () => {
            this.setState({lukas:false});
            this.setState({rudi:true});
            this.setState({PlayerValue:2});
            this.setState({lukasImage:"/resources/imagesBundesliga/weltmeister/selection-amimation/lukas_not-selected.gif"});
            this.setState({rudiImage:"/resources/imagesBundesliga/weltmeister/selection-amimation/rudi_selected.gif"});
            /* mate_1.src ="/resources/imagesBundesliga/weltmeister/selection-amimation/lukas_not-selected.gif";
            mate_2.src ="/resources/imagesBundesliga/weltmeister/selection-amimation/rudi_selected.gif"; */
            divMate1.classList.remove("lightUp");
            divMate2.classList.add("lightUp");
        }); 
    }

    render() {
        return(
            <>  
                <div className="selection-wrapper">
                    { this.headingSection() }
                </div>
                <div className="campaign-selection-header-mob">
                    <p>Mit dem FC Milka hast du jetzt die einmalige Chance Rudi Völler in Rom oder Lukas Podolski in Köln persönlich kennenzulernen.</p>
                </div>
                <Footersocial />
            </>
        );
    }

    headingSection() {
        return (
            <>
                <div className="campaign-selection-header">
                    <h1>
                    Für wen entscheidest du dich?
                    </h1>
                    <p>Mit dem FC Milka hast du jetzt die einmalige Chance Rudi Völler in Rom oder Lukas Podolski in Köln persönlich kennenzulernen.</p>
                </div>
                <div className="mate-selection-wrapper">

                    <div id="mate_1" className="mate"><img src={this.state.lukasImage} alt="stars" id="star_1" className={`stars ${this.state.lukas === false ? "unselect" : ""}`}/><img src="/resources/imagesBundesliga/weltmeister/selection-amimation/Plinth.png" alt="plinth" className="plinth"/></div>

                    <div id="mate_2" className="mate"><img src={this.state.rudiImage} alt="stars" id="star_2" className={`stars ${this.state.rudi === false ? "unselect" : ""}`}/><img src="/resources/imagesBundesliga/weltmeister/selection-amimation/Plinth.png" alt="plinth" className="plinth"/></div>

                </div>
                {this.state.PlayerValue ? 
                
                <div className="cta-container">
                    <button className="btn__container btn--secondary-lilac" onClick={this.setPlayerValue()}><NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}> JETZT TEILNEHMEN</NavLink></button>
                </div>
                
                :
                
                <div className="cta-container">
                    <button className="btn__container btn--secondary-lilac unselect" >Weltmeister auswählen</button>
                </div>
                
                }

            </>
        );
    }
}

export default Q4Selection;