import React from "react";
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import KIPServices from "../../../Service/KIP_Pages";
import GLOBAL_CONFIG from "../../../config/config";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Aux from "../../../hoc/Auxiliare";
import appGoogleTracking from "../../../modules/googletracking";

class TicketsPromoConfirmation extends React.Component {
  componentDidMount() {
    //this.LoadCampaign();
    mainLoaderToggle("hide");
  }

  render() {
    return (
      <div className="weltmeister-confirmation tickets-confirmation">
        {/* this.headingSection() */}
        {this.mainSection()}
        {this.footerSection()}
        <Footersocial />
      </div>
    );
  }

  headingSection() {
    return (
      <Aux>
        <div className="confirmation-wrapper">
          <div className="confirmation-text">
            <h1>Vielen Dank für deine Teilnahme!</h1>
            <p>
              Deine Teilnahme ist bei uns eingegangen. Wir drücken dir die
              Daumen, dass du gewinnst.
            </p>
            <p>
              Die Gewinnermittlung der Ticketgewinne erfolgt per Zufallsprinzip
              unter allen Teilnehmern innerhalb des Gewinnspielzeitraums. Die
              Gewinnermittlung der 5 FC Milka Meister Tickets am Aktionsende
              unter allen Teilnehmern.
            </p>
            <br />
            <p>
              Übrigens: Denke daran, den Original-Kaufbeleg gut aufzubewahren.
            </p>
          </div>
        </div>
        {/* <Footersocial /> */}
      </Aux>
    );
  }

  mainSection() {
    return (
      <Aux>
        <div className="main-confirmation">
          <div className="confirmation-wrapper">
            <div className="confirmation-text">
              <h2>Vielen Dank</h2>
              <p>
              Du hast erfolgreich an der Aktion „Gewinne Bundesliga Tickets“ teilgenommen. Die <span>Gewinnermittlung</span> der <span>Cat2-Ticketgewinne</span> erfolgt per Zufallsprinzip unter allen Teilnehmern innerhalb des Gewinnspielzeitraums. Die <span>Gewinnermittlung</span> des <span>Bundesliga VIP-Erlebnis</span> am Aktionsende unter allen Teilnehmern.
              <br/>Übrigens: Denke daran, den <span>Original-Kaufbeleg</span> gut aufzubewahren.<br/>
              Die Gewinner werden per E-Mail benachrichtigt.
              </p>
            </div>
          </div>
        </div>
      </Aux>
    );
  }

  footerSection() {
    return (
      <Aux>
        <div className="s-content-end">
          <div className="end-element">
            <div className="end-element__item">
              <img src="/resources/imagesBundesliga/tickets-promo/teaser/1.png" />
              <div className="end-element__copy">
                <h3>Aufregend dunkel …</h3>
                <p>
                Entdecke die Vielfalt von zarter dunkler Alpenmilchschokolade. Diese sechs aufregenden Milka Dark Milk Sorten warten darauf, von dir probiert zu werden:
Milka Darkmilk Dunkle Alpenmilch, Kakao Splitter, Gesalzenes Karamell, Himbeere und Haselnuss. Und jetzt ganz neu auch in der Sorte Nougat-Crème.
                </p>

                <button className="btn__container btn--secondary-lilac">
                  <a
                    href="https://www.milka.de/neues/darkmilk"
                    target="_blank"
                    exact data-event="discover_more" 
                    data-category="Thank You Page" 
                    data-action="Go to Page" 
                    data-label="ENG_TY_OTHER" 
                    onClick={appGoogleTracking.processEventCTANavLink}
                  >
                    milka darkmilk
                  </a>
                </button>
              </div>
            </div>

            <div className="end-element__item">
              <img src="/resources/imagesBundesliga/tickets-promo/teaser/2.png" />
              <div className="end-element__copy">
                <h3>Milka zum Snacken</h3>
                <p>
                Milka Favourites ist dein perfekter Begleiter für gesellige Runden. Hier ist von fruchtigem Erdbeer- und Cheesecake, Karamell und Haselnuss, knusprigen Waffeln und leckeren Milka Oreos sowie klassischen Milkinis für jeden Geschmack etwas dabei.
                </p>
                <a 
                    href="https://favourites.milka.de/" 
                    target="_blank"
                    exact data-event="go_to_products" 
                    data-category="Thank You Page" 
                    data-action="Go to Page" 
                    data-label="ENG_TY_OTHER" 
                    onClick={appGoogleTracking.processEventCTANavLink}>
                  <button className="btn__container btn--secondary-lilac">
                  MILKA FAVOURITES
                  </button>
                </a>
              </div>
            </div>

            <div className="end-element__item">
              <img src="/resources/imagesBundesliga/tickets-promo/teaser/3.png" />
              <div className="end-element__copy">
                <h3>Nachhaltigkeit bei Milka</h3>
                <p>
                  Wusstest du, dass Milka mit Cacao Life ein globales Kakao-Nachhaltigkeitsprogramm hat? Und dass Milka sich gegen Kinderarbeit stark macht? Denn Milka will die Lebens- und Arbeitsbedingungen von Kakaobauern und ihren Familien langfristig verbessern.
                </p>
                <a 
                    href="https://www.milka.de/uber-milka/engagement" 
                    target="_blank"
                    exact data-event="go_to_explore_new" 
                    data-category="Thank You Page" 
                    data-action="Go to Page" 
                    data-label="ENG_TY_OTHER" 
                    onClick={appGoogleTracking.processEventCTANavLink}>
                  <button className="btn__container btn--secondary-lilac">
                    MILKAS ENGAGEMENT
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }

  LoadCampaign() {
    KIPServices.Globus()
      .then((response) => {
        var { status, success, message, placeholder } = response.data;

        if (success) {
          switch (status) {
            case _STATUS_CAMPAIGN.open:
              this.props.history.push({
                pathname: GLOBAL_CONFIG.Route.buzzer.globus,
              });
              break;

            default:
              mainLoaderToggle("hide");
              break;
          }
        }
      })
      .catch(() => {
        mainLoaderToggle("hide");
      })
      .finally(() => {
        _SCROLL_PAGE.ScrollToBanner();
      });
  }
}

export default TicketsPromoConfirmation;
