
import React from 'react';

import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../../config/config';
import appGoogleTracking from '../../../modules/googletracking';
class ParticipationNavigation extends React.Component {

    render() {
        let homeLink = this.getHomeLink();
        return(
            <div className="intropage_menu">
                <div className="page__menu">
                    <ul className="navigation intropage_nav">
                        <li>
                            <NavLink to={homeLink} exact data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink}>Start</NavLink>
                        </li>
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.participation} exact data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink}>Mitmachen</NavLink>
                        </li>
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.prize} exact data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink}>Gewinne</NavLink>
                        </li>
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.faq} exact data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink}>FAQ</NavLink>
                        </li>
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.footballGame} exact data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="FootballGame" onClick={appGoogleTracking.processEventCTANavLink}>11-METER-SCHIEßEN</NavLink>
                        </li>
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.fairplay} exact data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink}>Fairplay</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    getHomeLink(){
        let homeLink = GLOBAL_CONFIG.Route.home;
        let pathnameLower = window.location.pathname.toLowerCase();
        if(pathnameLower == GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase()){
            homeLink = GLOBAL_CONFIG.Route.buzzer.penny;
        }else if(pathnameLower == GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase()){
            homeLink = GLOBAL_CONFIG.Route.buzzer.rewe;
        }else if(pathnameLower == GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase()){
            homeLink = GLOBAL_CONFIG.Route.buzzer.edeka;
        }else if(pathnameLower == GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase()){
            homeLink = GLOBAL_CONFIG.Route.buzzer.netto;
        }else if(pathnameLower == GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase()){
            homeLink = GLOBAL_CONFIG.Route.buzzer.kaufland;
        }
        return homeLink;
    }
}

export default ParticipationNavigation;