import React from "react";
import Match from "../../../../Models/Match";

class MobileBuzzerGame extends React.Component {
    constructor() {
        super();

        this.home = "";
        this.away = "";

        this.state = {
            home: "",
            away: ""
        }
    }

    componentDidMount() {
        var { selectionUpdate, selectionAllowed } = this.props;

        var slectedContainer = document.querySelector(".buzzer-drop-down .selected a span"),
            selectedAnchor = document.querySelector(".buzzer-drop-down .selected a");

        //SELECT OPTIONS AND HIDE OPTION AFTER SELECTION
        let listAnchors = document.querySelectorAll(".buzzer-drop-down .options ul li.stock a"),
            list = document.querySelector(".buzzer-drop-down .options ul");

        selectedAnchor.addEventListener("click", event => {
            event.preventDefault();
            if(list.style.display != "block") {
                list.style.display = "block";
            } else {
                list.style.display = "none"
            }
        })

        listAnchors.forEach((anchor, index) => {

            if(index == 0) {
                var date = anchor.getAttribute("data-match-date");
                    slectedContainer.innerHTML = date;
                    list.style.display = "none";

                anchor.parentNode.parentNode.setAttribute("data-match-selected", "true")

                var home = anchor.getAttribute("data-match-home"),
                    away = anchor.getAttribute("data-match-away"),
                    matchDay = anchor.getAttribute("data-match-day"),
                    matchLocation = anchor.getAttribute("data-match-location"),
                    id = anchor.getAttribute("data-match-id"),
                    gameweek = anchor.getAttribute("data-match-gameweek"),
                    weekendDate = anchor.getAttribute("data-match-weekend-date"),
                    isSingleTicket = anchor.getAttribute("data-match-single-ticket");

                    
                this.setState({
                    home: home,
                    away: away,
                    id: id,
                    matchDay: matchDay,
                    location: matchLocation
                })

                selectionUpdate(
                    new Match({
                        id: id,
                        matchDay: matchDay,
                        home: home,
                        away: away,
                        location: matchLocation,
                        date: date,
                        gameweek : gameweek,
                        weekendDate : weekendDate,
                        isSingleTicket : isSingleTicket
                    })
                )
            }

            anchor.addEventListener("click", event => {
                event.preventDefault();
                var date = anchor.getAttribute("data-match-date");
                    slectedContainer.innerHTML = date;
                    list.style.display = "none";

                var prevouslySelected = document.querySelector("[data-match-selected]");

                if(!!prevouslySelected) {
                    prevouslySelected.removeAttribute("data-match-selected")
                }

                anchor.parentNode.parentNode.setAttribute("data-match-selected", "true")

                var home = anchor.getAttribute("data-match-home"),
                    away = anchor.getAttribute("data-match-away"),
                    matchDay = anchor.getAttribute("data-match-day"),
                    matchLocation = anchor.getAttribute("data-match-location"),
                    id = anchor.getAttribute("data-match-id"),
                    gameweek = anchor.getAttribute("data-match-gameweek"),
                    weekendDate = anchor.getAttribute("data-match-weekend-date");
                this.setState({
                    home: home,
                    away: away,
                    id: id,
                    matchDay: matchDay,
                    location: matchLocation
                })

                selectionUpdate(
                    new Match({
                        id: id,
                        matchDay: matchDay,
                        home: home,
                        away: away,
                        location: matchLocation,
                        date: date,
                        gameweek : gameweek,
                        weekendDate : weekendDate
                    })
                )
            })
        });


        //HIDE OPTIONS IF CLICKED ANYWHERE ELSE ON PAGE
        document.addEventListener("click", event => {
            var clicked = event.target,
                parent = document.querySelector(".buzzer-drop-down"),
                ischild = false;

            var node = clicked.parentNode;
            while (node != null) {
                if (node == parent) {
                    ischild = true;
                }
                node = node.parentNode;
            }

            if(!ischild) {
                list.style.display = "none"
            }
        })
    }

    render() {
        let { matches } = this.props;
        matches = !!matches ? matches : [];
        const defText = "Bitte wähle dein Bundesliga Spiel aus.";

        return (
            <div className="tablet buzzer-mobile">
                {
                    this.state.home.length > 0 ?
                    <p>
                        { this.state.home }
                        <span className="separator"> </span>
                        { this.state.away }
                    </p>
                    : 
                    <p></p>
                }
                <div className="buzzer-drop-down">
                    <div className="selected">
                        <a href="#"><span>{defText}</span></a>
                    </div>
                    <div className="options">
                        <ul>
                            {
                                matches.map( (match, index) => {
                                    let { home, away, date, id, matchDay, location, outStock, gameweek, weekendDate, isSingleTicket } = match;
                                    const value = home + " " + away;

                                    return (
                                        <li key={index} className={outStock ? "out-stock" : "stock"} onClick={this.props.checkedOneMatch}>
                                            <p>
                                                <a href="#" 
                                                    data-match-single-ticket={isSingleTicket} 
                                                    data-match-gameweek={gameweek} 
                                                    data-match-weekend-date={weekendDate} 
                                                    data-match-date={date} 
                                                    data-match-id={id} 
                                                    data-match-home={home} 
                                                    data-match-away={away} 
                                                    data-match-day={matchDay}
                                                    data-match-location={location}
                                                    style={outStock ? {pointerEvents: "none"} : null}
                                                    >

                                                    { 
                                                        outStock ? 
                                                        <strong>Nicht mehr verfügbar</strong>
                                                        :
                                                        <strong>{date}</strong>
                                                    }
                                                    <br />
                                                    {home} - {away}
                                                </a>
                                            </p>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileBuzzerGame;