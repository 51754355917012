import React from 'react';
import parse from 'html-react-parser';
/* 
    Title content on all pages - to display the first part of all pages
    This gives uniformity in all pages in terms of CSS and HTML codes
*/

const TitleContent = (props) => {

    let pModifier = !!props.pClass ? props.pClass : "";
    
    let heading = props.heading;
    if(typeof heading === 'string'){
        heading = parse(heading);
    }

    let RenderHeading = (props.heading !== undefined && props.heading !== null) ? <h1 className="heading_dark-lilac">{heading}</h1> : null,
        RenderSubheading = (props.subheading !== undefined && props.subheading !== null) ? <p className="subheading heading_dark-lilac">{props.subheading}</p> : null,
        RenderParaText = (props.paragraph !== undefined && props.paragraph !== null) ? <p className={`heading_dark-lilac space ${pModifier}`}>{props.paragraph}</p> : null,
        RenderParaText2 = (props.paragraph !== undefined && props.paragraph !== null) ? <p className="heading_dark-lilac">{props.paragraph2}</p> : null;

    return (
        <div className="s-content-title">
            {RenderHeading}
            {RenderSubheading}
            {RenderParaText}
            {RenderParaText2}
        </div>
    );
}

export default TitleContent;
