import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import { withTranslation } from "react-i18next";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import StepContent from "../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../config/config";
import KIPServices from "../../../Service/KIP_Pages";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import _LOCAL from "../../../Models/LocalStorageKeys";

class BuzzerRewe extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isProd90min: false,
            isProd: false,
            baseNamePath: null,
        }
        
        this.headingSection = this.headingSection.bind(this);
        this.footerSection = this.footerSection.bind(this);
        this.foundationSection = this.foundationSection.bind(this);
        
    }
    
    componentDidMount(){
        if(this.props.isQ3){
            window.location = "https://fcmilka.de/rewe";
        }

        let hostName = window.location.hostname,
        isProd90min = hostName.toLowerCase().indexOf("90min.fcmilka") > -1 ? true : false,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false,
        baseNamePath = "";
        
        if(isProd90min){
            baseNamePath = "";
        } else if(isProd){
            baseNamePath = "https://90min.fcmilka.de";
        } else {
            baseNamePath = "/90min";
        }
    
        this.setState({
            isProd90min : isProd90min,
            isProd : isProd,
            baseNamePath : baseNamePath
        });
    }

    componentWillMount() {
        this.LoadCampaign();
        localStorage.setItem(_LOCAL.CurrentKip, "Rewe");
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-netto kaufland rewe">
                {this.headingSection(this.props)}
                {this.foundationSection()}
                {this.footerSection()}
                <Footersocial />
            </div>
        );
    }


    headingSection(props) {
        return (
            <div className="content-home endpage-rewe">
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <div className="title-container__v2">
                            <h2>
                                Exklusiv mit Milka bei REWE für die Lukas Podolski Stiftung spenden
                            </h2>
                            <p>
                                Im Aktionszeitraum vom 11.07.2022 bis 10.09.2022 ein Produkt von Milka, TUC oder Wunderbar in deinem Rewe Markt kaufen, Produktcode eingeben auf <a href="https://90min.fcmilka.de/rewe" target="_blank"> www.90min.fcmilka.de/rewe </a>und MILKA und REWE spenden 1€ an die Lukas Podolski Stiftung.
                            </p>
                        </div>
                        <div className="step-container step-container__v2">
                            <h3 className="step-title step-title__v2">
                                Du willst auch unterstützen?
                            </h3>
                            <h3 className="step-title step-title__v2">
                                Mitmachen ist ganz einfach:
                            </h3>

                            <StepContent
                                step1 = "/resources/imagesBundesliga/90_min/rewe/step_1.png"
                                step1sm = "/resources/imagesBundesliga/90_min/rewe/step_1.png"
                                step1Text = {parse("Kaufe zwischen dem <span class=\"text--wheat\">11.07.2022</span> und dem <span class=\"text--wheat\">10.09.2022</span> ein Produkt von Milka, TUC oder Wunderbar<br><strong>(Bitte den gut lesbaren Kaufbeleg aufbewahren.)</strong>")}
                                step_arrow = "/resources/imagesBundesliga/Edeka/icons/arrow_right.png"

                                step2 = "/resources/imagesBundesliga/90_min/rewe/step_2.png"
                                step2sm = "/resources/imagesBundesliga/90_min/rewe/step_2.png"
                                step2Text = {parse("Als Rewe-Teilnehmer bei der Spendenaktion registrieren und den Produktcode auf der Website eingeben. ")}

                                step3 = "/resources/imagesBundesliga/90_min/rewe/step_3.png"
                                step3sm = "/resources/imagesBundesliga/90_min/rewe/step_3.png"
                                step3Text = {parse("Klasse, dass du mithilfst! Dank deiner Teilnahme spenden MILKA und REWE 1 Euro für ein Projekt der Lukas Podolski Stiftung.")}
                            />
                            {
                                this.state.isProd90min ?
                                    <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                                    <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                                </NavLink>
                                :
                                <a href={`${this.state.baseNamePath+GLOBAL_CONFIG.Route.buzzer.participate}?currentKip=Rewe`} className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                                    <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                                </a>
                            }
                            


                            <p className="step-container--terms-link">
                                Hier findest du die
                                {
                                    this.state.isProd90min ?
                                        <NavLink to={GLOBAL_CONFIG.Route.terms}> Teilnahmebedingungen </NavLink>
                                    :
                                        <a href={`${this.state.baseNamePath}${GLOBAL_CONFIG.Route.terms}`}> Teilnahmebedingungen </a>
                                }
                                 und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a>.
                            </p>
                            <p className="step-container--terms-link">
                                *Spendensumme max. 40.000€ 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    foundationSection() {
        return (
            <div className="foundation-container">
                <h2 className="foundation-title">Darum geht es bei der Lukas Podolski Stiftung</h2>

                <div className="foundation-content">
                    <div className="foundation-img__container">
                        <picture>
                            <source srcSet="/resources/imagesBundesliga/90_min/rewe/foundation_1.png" media="(min-width: 768px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/rewe/foundation_1.png" media="(min-width: 481px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/rewe/foundation-sm_1.png" media="(min-width: 200px)" />
                            <img className="foundation__image" src="/resources/imagesBundesliga/90_min/rewe/foundation_1.png" alt="Lukas Podolski foundation" />
                        </picture>
                    </div>
                    <div className="foundation-text__right">
                        <p>
                        Lukas Podolski ist sich dessen bewusst, dass er mit einer Karriere als Fussballprofi den Traum vieler lebt. Aber auch Lukas Podolski ist nicht mit goldenen Schuhen an den Füßen auf die Welt gekommen und seine Karriere war kein Zufall. Familiärer Rückhalt und Unterstützung an der richtigen Stelle haben ihm viel ermöglicht. Aus diesem Bewusstsein heraus entsteht der Wunsch gezielt da zu helfen, wo es an Rückhalt und Unterstützung fehlt. Im Jahr 2010 wird die Lukas Podolski Stiftung gegründet.
                        </p>
                        <p>
                        Besonders Kinder aus sozial schwächeren Familien liegen Lukas Podolski am Herzen. Mit seiner Stiftung will er helfen, Sport- und Bildungsprojekte für benachteiligte Kinder und Jugendliche zu realisieren.
                        </p>
                    </div>
                </div>
                <p className="foundation-text__left">Die Lukas Podolski Stiftung ist auch das Angebot an all die, die ebenfalls ihre Unterstützung anbieten können und wollen, um mit einer Spende die Projekte der Stiftung zu fördern. Das Engagement von Lukas Podolski kennt dabei keine Grenzen — so unterstützt er beispielsweise seit 2013 die „RheinFlanke“ und ist Schirmherr für diese gemeinnützige GmbH, die sozialbenachteiligten Kindern hilft - Schlüssel ist dabei der Sport. </p>

                <div className="more-about__content">
                    <p className="more-about__text">Mehr über die Lukas Podolski Stiftung unter: </p>
                    <a href="https://www.lukas-podolski-stiftung.de/vom-bolzplatz-ins-leben/" target="_blank">
                    <picture>
                        <source srcSet="/resources/imagesBundesliga/90_min/rewe/lukas_podolski.png" media="(min-width: 768px)" />
                        <source srcSet="/resources/imagesBundesliga/90_min/rewe/lukas_podolski.png" media="(min-width: 481px)" />
                        <source srcSet="/resources/imagesBundesliga/90_min/rewe/lukas_podolski.png" media="(min-width: 200px)" />
                        <img className="foundation__image" src="/resources/imagesBundesliga/90_min/rewe/lukas_podolski.png" alt="Banner" />
                    </picture>
                    </a>
                    <p className="foundation-text__bottom">Die Spendensumme dieser Aktion geht an das gemeinsame Schul- und Jugendprojekt “Starke Lernorte - starke Kids” der Lukas Podolski Stiftung mit der RheinFlanke und der Stadt Köln in der STRASSENKICKER BASE in Köln. Weitere Informationen dazu findest du hier: <a href="https://www.lukas-podolski-stiftung.de/vom-bolzplatz-ins-leben/" target="_blank">www.lukas-podolski-stiftung.de/vom-bolzplatz-ins-leben/</a></p>
                </div>
            </div>
        )
    }

    cardSection() {
        return (
            <div className="card-container">
                <div className="card-container__card">
                    <div className="card-container__card__primary">
                        <img src="/resources/imagesBundesliga/buzzer_22/rewe/card/supercup.png" alt="Gold im Wert"/>
                        <p>(inkl. attraktivem Rahmenprogramm, An- und Abreise, Übernachtung und Verpflegung.)</p>
                    </div>
                    <div className="card-container__card__secondary">
                        <img src="/resources/imagesBundesliga/buzzer_22/rewe/card/jersey.png" alt="Dauerkarten der Saison"/>
                        <h3>Gewinne STRASSENKICKER-Gutschein</h3>
                        <p>
                            Mit etwas Glück hast du die Chance auf einen von 125x 100€ Gutscheinen für die STRASSENKICKER-Produkte von Lukas Podolski, online einzulösen unter
                        </p>
                        <p><a href="www.11ts.shop/strassenkicker"><strong>www.11ts.shop/strassenkicker</strong></a>.</p>
                    </div>
                </div>
            </div>
        );
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <h2>
                    Gewinne mit deiner Spende
                </h2>
                <h3 className="prizeRewe-subtitle">
                    Neben deiner Spende für die Lukas Podolski Stiftung nimmst du automatisch an unserer Aktion “Gewinne in 90 Minuten” teil und hast somit die Chance auf tolle Fußball-Gewinne. Freue dich auf Tickets für die Bundesliga / 2. Bundesliga der Saison 2022/23, original Heimtrikots oder ein Bundesliga Match Attax Starterpack.
                </h3>
                {
                    this.state.isProd90min ?
                        <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                            <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                        </NavLink>
                    :
                        <a href={`${this.state.baseNamePath+GLOBAL_CONFIG.Route.buzzer.participate}?currentKip=Rewe`} className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                            <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                        </a>
                }

                <p className="terms-link">
                    Hier findest du die 
                    {
                        this.state.isProd90min ?
                        <NavLink to={GLOBAL_CONFIG.Route.terms}> Teilnahmebedingungen </NavLink>
                    :
                        <a href={`${this.state.baseNamePath}${GLOBAL_CONFIG.Route.terms}`}> Teilnahmebedingungen </a>
                    }
                    und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a>.
                </p>
                <p className="terms-faq">
                    Noch Fragen zur Aktion? Hier geht es zu den 
                    {
                        this.state.isProd90min ?
                        <NavLink to={GLOBAL_CONFIG.Route.buzzer.faq}> FAQ. </NavLink>
                    :
                        <a href={`${this.state.baseNamePath}${GLOBAL_CONFIG.Route.buzzer.faq}`}> FAQ. </a>
                    }
                </p>
                <h3 className="terms-title">
                    Teilnahmebedingungen
                </h3>
                <h4 className="terms-subtitle">
                    Ergänzend zu den vollständigen <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzhinweisen</a> zu dem Gewinnspiel „Gewinne jeden Tag in 90 Minuten“ gelten für die Spendenaktion bei REWE die folgenden Teilnahmebedingungen:
                </h4>

                <p className="instruction">
                    <strong>Veranstalter:</strong> Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen. Teilnahmeberechtigt: natürliche Personen ab 18 Jahren mit Wohnsitz in Deutschland. Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen sowie jeweils deren Angehörige. Teilnahme durch Kauf eines Produktes von Milka, Wunderbar oder TUC im Zeitraum vom 11.07. bis 10.09.2022 sowie Eingabe des Produktcodes (auf der Produktverpackung), Angabe der Aktionsmarke, des Händlers/Kaufdatums auf www.90min.fcmilka.de/rewe bis zum 10.09.2022. 
                </p>
                <p className="instruction">
                    <strong>Spendenaktion REWE:</strong> Für jede gültige Teilnahme wird ein 1€ von Milka und Rewe an ein ausgewähltes Projekt der Lukas Podolski Stiftung gespendet, max. 40.000€. Online-Registrierung erforderlich.
                </p>
                <p className="instruction">
                    <strong>Nationales Gewinnspiel:</strong> Original-Kaufbeleg zur späteren vorbehaltlichen Prüfung aufbewahren. Mehrfachteilnahmen mit neuem Produktcode innerhalb der Produkte erlaubt. Max. ein Gewinn pro Tag/Person. Der Teilnehmer erhält am Tag der Registrierung eine Bestätigungsmail, in der ihm das 90-Minuten-Gewinnfenster am darauffolgenden Tag genannt wird. Am Tag des 90-Minuten-Gewinnfensters erhält der Teilnehmer eine E-Mail mit seinem Gewinnlink für die Teilnahme am Gewinnspiel innerhalb des 90-Minuten-Gewinnfensters. Mit Klick auf den Link nimmt der Teilnehmer abschließend am Gewinnspiel teil und erfährt direkt, ob er gewonnen hat oder nicht. Gewinnermittlung per Zufallsgenerator.
                </p>
                <p className="instruction">
                    Weitere Informationen, vollständige Teilnahmebedingungen und Datenschutzhinweise unter<br/> <a href="https://90min.fcmilka.de/teilnahmebedingungen" target="_blank">www.90min.fcmilka.de/<span className="hide"><br/></span>teilnahmebedingungen.</a>
                </p>
            </div>
        )
    }

    LoadCampaign() {
        KIPServices.Rewe()
        .then( response => {
            var { status, success, message, placeholder } = response.data;

            if(success) {
                switch (status) {
                    case _STATUS_CAMPAIGN.holding:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.holdingRewe
                        });
                        break;
                    case _STATUS_CAMPAIGN.closed:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.endRewe
                        });
                        break;
                
                    default:
                        mainLoaderToggle('hide');
                        break;
                }
            }
        })
        .catch( () => {
            mainLoaderToggle('hide');
        })
        .finally( () => {
            _SCROLL_PAGE.ScrollToTop();
        })
    }

}

export default withTranslation()(BuzzerRewe);