import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import TitleContent from '../../../components/Content/TitleContent/TitleContent'
import GLOBAL_CONFIG from '../../../config/config'
import appGoogleTracking from '../../../modules/googletracking'
import { mainLoaderToggle } from '../../../modules/helpers'

class FancenterTerms extends Component {
  componentDidMount() {
    const { t } = this.props
    //Remove main loader
    mainLoaderToggle('hide')

    //Virtual page
    appGoogleTracking.dataLayerPush({
      customEvent: {
        event: 'virtual-page',
        virtualUri: GLOBAL_CONFIG.Route.fancenterTerms,
        pageTitle: t('Meta.title'),
      },
    })
  }

  componentWillUnmount() {
    //Show mainloader
    mainLoaderToggle('show')
  }

  displayFullVersion() {
    const { t } = this.props
    return (
      <div className="container medium-copy terms fancenter-terms">
        <TitleContent heading="FC Milka Fan-Center: Einlösebedingungen" />
        <div className="s-content-copy">
          <div className="s-content-copy__item">
            <p className="headings">§ 1 Anbieter</p>
            <p>
              Anbieter und inhaltlich Verantwortlicher <br />
              Mondelez Deutschland Services GmbH & Co. KG <br />
              Konsul-Smidt-Straße 21 <br />
              28217 Bremen
            </p>
            <p>
              Telefon: (0421) 3770-6000 <br />
              E-Mail: Verbraucherservice@mdlz.com
            </p>
            <p>
              Umsatzsteuer-ID: DE 262120326 <br />
              Geschäftsführer: Kerstin Fischer, Kerstin Picker-Münch, Jan Trichterborn <br />
              (nachstehend „Mondelez“, „Veranstalter“ oder „Milka“ genannt)
            </p>
          </div>

          <div className="s-content-copy__item">
            <p className="headings">§ 2 Anwendungsbereich</p>
            <p>
              Seit Januar 2021 ist Milka offizieller Partner der Bundesliga und
              2. Bundesliga sowie des Supercups und veranstaltet regelmäßig
              online Gewinn-Aktionen im Bereich{' '}
              <a href="https://fcmilka.de/">FC Milka</a>. Die nachstehenden
              Vorschriften dieser Einlösebedingungen regeln, unter welchen
              Bedingungen der Milka Fan-Shop genutzt werden kann.
            </p>
            <p>
              Um an einer FC Milka Gewinn-Aktion teilnehmen zu können, müssen
              Personen mindestens 18 Jahre alt sein sowie ihren Wohnsitz in
              Deutschland haben. Auf der Seite{' '}
              <a href="https://www.fcmilka.de/mitmachen">
                https://www.fcmilka.de/mitmachen
              </a>{' '}
              registrieren sich die Teilnehmer im Zuge der Gewinnspielteilnahme.
              Alternativ können sich Teilnehmer beim FC Milka kostenlos
              registrieren und Mitglied werden und sich dann mit ihren
              Accountdaten bei den Gewinn-Aktionen einloggen. Die genauen
              Teilnahmebedingungen der jeweiligen Gewinn-Aktion sind den
              jeweiligen Teilnahmebedingungen zu entnehmen.
            </p>
          </div>
          <div className="s-content-copy__item">
            <p className="headings">§ 3 Übergabe des Gewinn-Links an Gewinner</p>
            <p>
              Im Gewinnfall wird dem Gewinner einer FC Milka Gewinn-Aktion der
              Gewinn-Link zu den Tickets für einen Stadionbesuch oder ein Original
              Heimtrikot per E-Mail zugeschickt. Dies gilt auch für Preise von TUC, OREO und Wunderbar. Die
              Gewinne können im{' '}
              <a
                href="https://www.fcmilka.de/fancenter"
                rel="noopener noreferrer"
                target="_blank"
              >
                FC Milka Fan-Center
              </a>{' '}
              eingelöst werden.
            </p>
          </div>

          <div className="s-content-copy__item">
            <p className="headings">
              § 4 Einlösung des Gewinns im FC Milka Fan-Center
            </p>
            <p className="headings sub">Ticket:</p>
            <ul>
              <li>
              Im Fall eines Ticket Gewinns erhalten die Gewinner per E-Mail einen Gewinn-Link für 1x 2 Tickets für 1 Spiel zur Einlösung. Dieser führt ins Milka Fan-Center. 
              </li>
              <li>
              Der Gewinner meldet sich explizit mit Namen und E-Mail-Adresse im FC Milka Fan-Center an, damit ihm nach Auswahl der entsprechenden Tickets diese per E-mail oder per Club App (abhängig vom Bundesliga Verein) zugesendet werden können.
              </li>
              <li>
              Wenn keine Tickets für das gewünschte Spiel mehr vorrätig sind, muss ein anderes Spiel ausgewählt werden.
              </li>
              <li>Der Gewinner ist als Einziger berechtigt, den Gewinn einzulösen. Gewonnene Tickets dürfen an Familie oder Freunde weitergegeben werden, der Weiterverkauf an Dritte ist jedoch ausdrücklich untersagt und wird vorbehaltlich rechtlich verfolgt. </li>
              <li>Ticket Gewinne müssen sieben Tage nach Erhalt des Gewinn-Links per E-Mail im FC Milka Fan-Center eingelöst werden, ansonsten verfällt der Gewinn ersatzlos.</li>
              <li>Wird der Gewinn nach Erhalt des Links und trotz freigeschalteter Auswahl innerhalb von 7 Tagen nicht eingelöst, verfällt er und ist auch nicht in die neue Saison übertragbar.</li>
              <li>Beim Ticket-Gewinn gelten zusätzlich auch die AGB der jeweiligen Bundesliga-Vereine. Die einmal ausgestellten Tickets dürfen insbesondere nicht an Dritte verkauft werden und sind vom Umtausch ausgeschlossen. Für die Inanspruchnahme der Tickets gelten die auf den Tickets formulierten Einlösebedingungen der jeweiligen Clubs sowie die AGB der jeweiligen Bundesliga-Vereine und die Bedingungen für die Einlösung der Tickets. Diese liegen außerhalb der Verantwortung des Veranstalters.</li>
            </ul>
            <p className="headings sub">Trikot:</p>
            <ul>
              <li>
              Im Fall eines Trikot-Gewinns erhalten die Gewinner per E-Mail einen Gewinn-Link für ein original Heimtrikot. Dieser führt ins Milka Fan-Center. 
              </li>
              <li>
              Der Gewinner meldet sich explizit mit Namen und Postadresse im FC Milka Fan-Center an, damit ihm nach Auswahl des entsprechenden Trikots dieses postalisch zugesandt werden kann.
              </li>
              <li>
              Trikot Gewinne müssen 30 Tage nach Erhalt des Gewinn-Links per E-Mail im FC Milka Fan-Center eingelöst werden, ansonsten verfällt der Gewinn ersatzlos.
              </li>
              <li>Kann der Gewinner unter der bei der Gewinn-Aktion angegebenen Adresse auch nach zweifachem Versuch binnen 6 Wochen nach dem ersten Zustellversuch nicht erreicht werden, verfällt der Gewinn ersatzlos und kann nach dem Ermessen von Mondelez entweder gestrichen oder an einen anderen Teilnehmer übermittelt werden, der unter denselben Bedingungen ermittelt wurde.</li>
              <li>Der Gewinner ist als Einziger berechtigt, den Gewinn einzulösen. Gewonnene Trikots dürfen an Familie oder Freunde weitergegeben werden, der Weiterverkauf an Dritte ist jedoch ausdrücklich untersagt und wird vorbehaltlich rechtlich verfolgt.</li>
            </ul>

            <p className="headings sub">Grundsätzlich gilt:</p>
            <ul>
              <li>
              Der Gewinn-Link ist nur im Rahmen der FC Milka Gewinn-Aktionen im FC Milka Fan-Center einlösbar.
              </li>
              <li>
              Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga. 
              </li>
              <li>
              Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen oder ersatzlos streichen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation. Maximal ein Hauptpreis pro Person. Barauszahlung und Rechtsweg sind ausgeschlossen. Nicht kombinierbar mit anderen Aktionen.
              </li>
              
            </ul>
          </div>

          <div className="s-content-copy__item">
            <p className="headings sub">Überschreitung des Einlösezeitraums</p>
            <p>
              Werden die Gewinn-Links nicht im jeweils entsprechenden Zeitraum
              im FC Milka Fan-Center eingelöst, verfällt der Gewinn ersatzlos.
            </p>
          </div>
          <div className="s-content-copy__item">
            <p className="headings sub">Mehrfachanwendung</p>
            <p>Mehrfacheinlösungen eines Gewinn-Links sind ausgeschlossen.</p>
          </div>
          <div className="s-content-copy__item">
            <p className="headings sub">Gewinn-Erstattung</p>
            <p>
            Der Gewinn wird nicht erstattet, wenn der Gewinner die gewonnene Ware im Rahmen seines gesetzlichen Widerrufsrechts zurückgibt. Geht der Gewinn-Link verloren, ist keine Erstattung möglich.
            </p>
          </div>

          <div className="s-content-copy__item">
            <p className="headings">5 Gewährleistung und Haftung</p>
            <p>
            Für einen Mangel oder mehrere Mängel an einem Gewinn übernimmt Mondelez weder eine Gewährleistung noch stehen dem Gewinner im Falle eines Mangels oder mehrerer Mängel an dem Gewinn Umtausch- oder Rückgaberechte zu, es sei denn, es liegt ein Mondelez zurechenbarer Fall von Vorsatz oder arglistiger Täuschung vor. 
            </p>
            <p>
            Der Veranstalter haftet auch nicht für Produkte oder Dienstleistungen Dritter. Bei der Inanspruchnahme der Leistungen Dritter gelten deren Allgemeine Geschäftsbedingungen. 
            </p>
            <p>
            Der Veranstalter haftet auch nicht im Falle einer Insolvenz des Dritten und leistet keinen Ersatz im Falle einer in der Zukunft liegenden, derzeit nicht absehbaren Eröffnung eines Insolvenzverfahrens über das Vermögen des Dritten.
            </p>
            <p>
            Der Veranstalter übernimmt keine Verantwortung für verspätet eingehende, unvollständige, inkorrekt eingesandte, schadhafte oder falsch zugestellte Einsendungen, Ansprüche oder Mitteilungen, unabhängig davon, ob diese durch Fehler, Unterlassung, Veränderung, Manipulation, Löschung, Diebstahl, Zerstörung, Übertragungsunterbrechung, Kommunikationsausfall oder anderweitig bedingt wurden. 
            </p>
            <p>
            Der Veranstalter haftet nicht für Schäden aufgrund von technischen Störungen, für Verzögerungen oder Unterbrechungen von Übertragungen oder für Schäden, die im Zusammenhang mit der Teilnahme an dem Gewinnspiel stehen, es sei denn der Veranstalter handelt vorsätzlich oder grob fahrlässig. Hiervon unberührt bleiben etwaige Ersatzansprüche aufgrund von Produkthaftung und der Verletzung von Leben, Körper und Gesundheit sowie von wesentlichen Vertragspflichten. Im Fall der Verletzung von wesentlichen Vertragspflichten, d.h. solcher Pflichten, die die ordnungsgemäße Durchführung des Vertrages erst ermöglichen und auf deren Erfüllung der Nutzer vertrauen darf, ist die Haftung auf den vertragstypisch vorhersehbaren Schaden begrenzt.   
            </p>
            <p>
            Die Teilnehmer erkennen durch ihre Teilnahme an, dass die Beteiligung an dieser Werbeaktion auf alleiniges Risiko des Teilnehmers erfolgt. Der Veranstalter, seine Organmitglieder, Direktoren, Beschäftigten und Agenten geben, soweit rechtlich zulässig, keinerlei Garantien oder Zusicherungen über die Richtigkeit oder Vollständigkeit der Inhalte auf {' '}
              <a
                href="https://fcmilka.de/"
                rel="noopener noreferrer"
                target="_blank"
              >
                fcmilka.de
              </a>{' '}
              oder auf mit dieser Website verlinkten Seiten ab und übernehmen keine Haftung oder Verantwortung für:
            </p>
            <ol type="a" className="list-type-reset">
              <li>Fehler, Irrtümer oder Ungenauigkeiten von Inhalten;</li>
              <li>
                Sachschäden jeglicher Art, die aus der Teilnahme an der
                Werbeaktion herrühren;
              </li>
              <li>
              Todesfälle oder Personenschäden, die sich als direkte Folge der Teilnahme an der Werbeaktion ergeben, sofern diese nicht durch zumindest Fahrlässigkeit von Mondelez International, seinen Organmitgliedern, Direktoren, Beschäftigten und Agenten verursacht werden;
              </li>
              <li>
                Unterbrechungen oder Abbrüche der Übertragung an oder von{' '}
                <a
                  href="http://www.fcmilka.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.fcmilka.de
                </a>{' '}
                ;
              </li>
              <li>
                Softwarefehler, Viren usw., die an oder durch{' '}
                <a
                  href="http://www.fcmilka.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.fcmilka.de
                </a>{' '}
                an einen Dritten übertragen werden, und/oder
              </li>
              <li>
                Verluste oder Schäden jeglicher Art, die sich aus der Nutzung
                geposteter, per E-Mail versandter, übertragener oder anderweitig
                über www.fcmilka.de zur Verfügung gestellter Inhalte ergeben.
              </li>
            </ol>
          </div>
          <div className="s-content-copy__item">
            <p>
            Gewinne können nicht gegen Bargeld oder sonstige Formen der Entschädigung   eingelöst werden, sofern nichts anderes angegeben wurde. Falls der Nutzer einen Teil eines Gewinns aus irgendeinem Grund nicht innerhalb des vom Veranstalter vorgegebenen Zeitrahmens einlöst, verfällt dieses Gewinnelement für den Nutzer ersatzlos, und es wird keine Geldzahlung oder sonstige Form von Entschädigung als Ersatz für dieses Element geliefert, soweit nichts anderes vereinbart wurde.
            </p>
          </div>

          <div className="s-content-copy__item">
            <p className="headings">
              § 6 Vorzeitige Beendigung von Gewinnspielen /Änderung der
              Teilnahmebedingungen
            </p>
            <p>
              Mondelez behält sich vor, Gewinnspiele zu jedem Zeitpunkt ohne
              Vorankündigung und ohne Angabe von weiteren Gründen abzubrechen
              oder zu beenden, insbesondere bei Infizierung durch Computerviren,
              Netzwerkausfällen, Softwarefehlern, Manipulationen, unbefugten
              Eingriffen, Betrug, technischem Versagen oder Ursachen außerhalb
              der Kontrolle des Veranstalters, die die Verwaltung, Sicherheit,
              Fairness, Integrität oder ordnungsgemäße Durchführung dieser
              Werbeaktion stören oder beeinträchtigen.
            </p>
            <p>
              Mondelez kann diese Bedingungen jederzeit und ohne weitere
              Benachrichtigungen ändern.
            </p>
          </div>

          <div className="s-content-copy__item">
            <p className="headings sub">
              § 7 Angaben zu EU Streitschlichtungsverfahren
            </p>
            <p>
              Gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG) weisen wir
              darauf hin, dass wir nicht verpflichtet und nicht bereit sind, an
              einem Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle teilzunehmen.
            </p>
            <p>
              Sie können sich aber jederzeit an unseren Verbraucherservice
              wenden.
            </p>
            <p>Informationen zur Online-Streitbeilegung:</p>
            <p>
              Die EU-Kommission stellt unter folgendem Link eine
              Internetplattform zur Online-Beilegung von Streitigkeiten (sog.
              „OS-Plattform“) bereit, die als Anlaufstelle zur
              außergerichtlichen Beilegung von Streitigkeiten betreffend
              vertragliche Verpflichtungen, die aus Online-Kaufverträgen
              erwachsen, dient:
              <br />
              <a
                href="https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks
              </a>
            </p>
          </div>

          <div className="s-content-copy__item">
            <p className="headings sub">§ 8 Support/Kontakt</p>
            <p>
              Sämtliche Fragen, Kommentare oder Beschwerden zum Gewinnspiel sind
              an Mondelez Deutschland zu richten. Fernmündlich mitgeteilte
              oder verspätete Beschwerden können nicht bearbeitet werden.
            </p>
          </div>

          <div className="s-content-copy__item">
            <p className="headings sub">§ 9 Sonstiges</p>
            <p>
              Sollten einzelne Bestimmungen dieser Teilnahmebedingungen
              unwirksam, unzulässig oder undurchführbar sein oder werden, so
              lässt dies die Wirksamkeit der Teilnahmebedingungen im Übrigen
              unberührt. An die Stelle der unwirksamen, unzulässigen oder
              undurchführbaren Klausel treten Regelungen, deren Wirkungen der
              wirtschaftlichen Zielsetzung möglichst nahekommen. Dies gilt
              jedoch nur dann, wenn das Festhalten an dem gesamten Vertrag, auch
              unter Berücksichtigung dieser vorgesehenen Änderung, nicht eine
              unzumutbare Härte für eine Vertragspartei darstellen würde
            </p>
            <p>
              Etwaige Rechte aus dem Vertragsverhältnis, das diesen
              Teilnahmebedingungen zugrunde liegt, sind für Sie nicht auf Dritte
              übertragbar.
            </p>
            <p>Es gilt das Recht der Bundesrepublik Deutschland.</p>
          </div>

          <div className="s-content-copy__item">
            <p className="headings sub">§ 10 Abstandserklärung</p>
            <p>
              Gewinnspiele stehen in keiner Verbindung zu Facebook und Instagram
              und werden in keiner Weise von Facebook gesponsert, unterstützt
              oder organisiert. Für keinen der im Rahmen dieser Aktion
              veröffentlichten Inhalte ist Facebook oder Instagram
              verantwortlich.
            </p>
            <p>
              Der Empfänger der vom Teilnehmer bereitgestellten Informationen
              ist nicht Facebook oder Instagram, sondern Mondelez Deutschland.
              Mondelez Deutschland stellt Facebook oder Instagram von
              berechtigten Ansprüchen Dritter im Zusammenhang mit diesem
              Gewinnspiel frei. Sämtliche Fragen, Kommentare oder Beschwerden
              zur Aktion sind nicht an Facebook oder Instagram zu richten,
              sondern an Mondelez Deutschland unter verbraucherservice@mdlz.com
            </p>
          </div>
          <div className="s-content-copy__item">
            <p>§ 10 Datenschutzhinweise</p>
            <p>
              Verantwortliche Stelle für die Verarbeitung Ihrer
              personenbezogenen Daten im Rahmen von Gewinnspielen ist die
              Mondelez Deutschland Services GmbH & Co. KG, Konsul-Smidt-Str. 21,
              D-28217 Bremen (Mondelez Deutschland). Mit der Durchführung des
              Gewinnspiels sind die folgenden Agenturen beauftragt: Baudek und
              Schierhorn (B&S){' '}
              <a
                href="https://www.baudek-schierhorn.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.baudek-schierhorn.de/
              </a>{' '}
              sowie Consultix{' '}
              <a
                href="https://www.consultix.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.consultix.net/
              </a>
            </p>
            <p>
              Baudek und Schierhorn (B&S){' '}
              <a
                href="https://www.baudek-schierhorn.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.baudek-schierhorn.de/
              </a>{' '}
              sowie Consultix{' '}
              <a
                href="https://www.consultix.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.consultix.net/
              </a>{' '}
              unterliegen sämtlichen anwendbaren Datenschutzgesetzen.
            </p>
            <p>
              Die genannten Agenturen sind im Rahmen einer Auftragsverarbeitung
              für uns tätig und an die Anforderungen entsprechender Verträge
              gebunden.
            </p>
            <p>
              Eine Weitergabe Ihrer personenbezogenen Daten an in diesem
              Datenschutzhinweis nicht genannte Dritte erfolgt nicht.
            </p>
            <p>
              Wir speichern personenbezogene Daten von Ihnen nach Maßgabe der
              rechtlichen Vorschriften und ausschließlich zum Zweck der
              Abwicklung von Gewinnspielen (Rechtsgrundlage: Art. 6 Abs. 1
              Buchst. f) der Datenschutz-Grundverordnung EU 2016/679, DS-GVO)
            </p>
            <p>Es werden die E-Mailadresse, der Name, die Telefonnummer und die Adressdaten der Teilnehmer von den Gewinnern erfasst und ausschließlich zur Verifizierung der Teilnahme/ Gewinner gespeichert. Die Datenverarbeitung durch den Verein liegt außerhalb der Verantwortung von Mondelez Deutschland. Die Gewinner werden zusätzlich aufgefordert, diese zusätzlichen Allgemeinen Geschäftsbedingungen, Teilnahmebedingungen und Datenschutzhinweise des jeweiligen Vereins im Gewinnfall zusätzlich zu akzeptieren 
            </p>
            <p>
            Zum Zweck der öffentlichen Berichterstattung über die Fußballspiele oder der   jeweiligen Veranstaltung können Bild- und Bildtonaufnahmen von den Gewinnern sowie den Begleitpersonen von Mondelez, der DFL, den mit ihnen nach § 15 f. AktG verbundenen Unternehmen, den Fußballclubs/ ihren jeweiligen Verbänden und sonst befugten Dritten (z.B. Presse, Rundfunk) verarbeitet, verwertet und veröffentlicht werden. Mondelez kann diese Bild- und Bildtonaufnahmen und Fotos auch für die Nachberichterstattung für rein interne Zwecke nutzen. Mondelez kann diese Bild- und Bildtonaufnahmen und Fotos für die Nachberichterstattung für interne Zwecke sowie zu Werbezwecken nutzen. Hierzu holt Mondelez eine gesonderte Einwilligungserklärung der Teilnehmer ein. Die Rechtsgrundlage für die benannten Veröffentlichungen/ Nutzungen stellt das berechtigte Interesse der jeweiligen Unternehmen dar (Art. 6 Abs. 1 S. 1 Buchstabe f der DSGVO). Bei weitergehenden Nutzungen, die nicht von einem berechtigten Interesse abgedeckt sind, behält sich Mondelez vor, bei den Gewinnern, ihren Begleitpersonen sowie den jeweiligen Erziehungsberechtigten eine entsprechende Einwilligung (Art. 6 Abs. 1 S. 1 Buchstabe a der DSGVO) anzufragen.
            </p>

            <p>
Sie können der Verarbeitung Ihrer personenbezogenen Daten jederzeit ohne Angabe von Gründen widersprechen. Hierzu genügt eine formlose E-Mail an v{' '}
              <a href="mailto:verbraucherservice@mdlz.com">
                verbraucherservice@mdlz.com
              </a>{' '} . Im Falle eines Widerspruchs werden Ihre personenbezogenen Daten selbstverständlich gelöscht und eine Teilnahme an dem Gewinnspiel ist nicht mehr möglich.
            </p>
            <p>
              
Soweit keine gesetzlichen Aufbewahrungspflichten bestehen, erfolgt eine umgehende Löschung der Daten, nachdem das Gewinnspiel abgewickelt ist. Eine umgehende Löschung erfolgt bezüglich der Daten der Nichtgewinner. Gewinnerdaten sind aus gesetzlichen und buchhalterischen Gründen nach Maßgabe der §§ 257 HGB, 147 AO, 14b UStG aufzubewahren. Für diese Speicherung der Gewinnerdaten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. c) DS-GVO, da die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der Mondelez Deutschland unterliegt.
            </p>
            <p>Ihre Rechte im Zusammenhang mit personenbezogenen Daten:</p>
            <p>
              Sie sind unter anderem berechtigt (i) zu überprüfen, ob und welche
              personenbezogenen Daten wir über Sie gespeichert haben und Kopien
              dieser Daten zu erhalten, (ii) die Berichtigung, Ergänzung oder
              das Löschen Ihrer personenbezogenen Daten, die falsch sind oder
              nicht rechtskonform verarbeitet werden, zu verlangen, (iii) von
              uns zu verlangen, die Verarbeitung Ihrer personenbezogenen Daten
              einzuschränken, und (iv) der Verarbeitung Ihrer personenbezogenen
              Daten zu widersprechen, (v) Datenübertragbarkeit zu verlangen,
              (vi) in Deutschland bei der deutschen (für weitere Informationen,
              siehe{' '}
              <a
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                target="_blank"
              >
                https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
              </a>{' '}
              Beschwerde zu erheben.
            </p>
            <p>Unsere Kontaktdaten:</p>
            <p>
              Sollten Sie zu der Verarbeitung Ihrer personenbezogenen Daten
              Fragen oder Anliegen haben, wenden Sie sich bitte an uns:
            </p>
            <p>
              Mondelez Deutschland Services GmbH & Co. KG, Konsul Smidt Str. 21,
              28217 Bremen, Deutschland
            </p>
            <p>
              E-Mail:{' '}
              <a href="mailto:Verbraucherservice@mdlz.com">
                Verbraucherservice@mdlz.com
              </a>
            </p>
            <p>
              Unseren Datenschutzbeauftragten in Deutschland können Sie
              postalisch unter: Mondelez Deutschland Services GmbH & Co. KG, zu
              Händen des Datenschutzbeauftragten Herrn Uwe Struckmeier, Konsul
              Smidt Str. 21, 28217 Bremen, Deutschland
            </p>
            <p>
              oder per E-Mail unter:{' '}
              <a href="mailto:Datenschutz@mdlz.com">Datenschutz@mdlz.com</a>{' '}
              erreichen.
            </p>
            <p>
              Bitte lesen Sie unsere im Übrigen gültigen ausführlichen
              Datenschutzhinweise für die Datenverarbeitung im Rahmen der
              Nutzung dieser Webseite unter
            </p>
            <p>DE:</p>
            <p>
              <a
                href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jS
                            EtgtqGQHH57lZw%3D%3D."
                target="_blank"
              >
                https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jS
                EtgtqGQHH57lZw%3D%3D.
              </a>
            </p>
            <p>
              Die Webseiten unter www.facebook.com und www.instagram.com und die
              auf dieser Seite vorgehaltenen Dienste werden angeboten von der
              Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland.
              Es kann nicht ausgeschlossen werden, dass Facebook die
              hochgeladenen Daten selbstständig speichert und verarbeitet.
            </p>
            <p>
              Wir haben keinen Einfluss auf den Umfang der Erhebung und die
              weitere Verwendung dieser Daten durch Facebook. Informationen des
              Drittanbieters zum Datenschutz können Sie der nachfolgenden
              Webseite von Facebook und Instagram entnehmen:
            </p>
            <p>https://www.facebook.com/about/privacy</p>
            <p>https://help.instagram.com/519522125107875</p>
            <p>
              Es ist nicht ausgeschlossen, dass durch Facebook Ihre Daten auch
              in an die Muttergesellschaft der Facebook Ltd., die Facebook Inc.
              mit Sitz in den USA weitergeleitet werden.
            </p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.displayFullVersion()
  }
}

export default withTranslation()(FancenterTerms)
