import React from 'react';
import "./priceComponent.scss";

const PriceComponent = (props) => {

    return (
        <div className='price-component'>
            <img src={props.img} alt='price' />
            <h3 className='title'>{props.title}</h3>
            <p className='texte'>{props.text}</p>
        </div>
    );
}

export default PriceComponent;
