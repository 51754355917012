import React, { useState} from 'react';

class DynamicButton extends React.Component {
     
    constructor(props) {
        super(props);
         this.state = {
        active : this.props.isActive,
      }
    
    }

    click = (event) =>{
      this.setActiveButton(event)
      this.props.onClick()
      console.log('isActive -> '+this.props.isActive);
    }

    setActiveButton = event => {
      switch (event.detail) {
        case 1: {
            this.setState({ active: true });
            break;
                  }
        case 2: {
            this.setState({ active: false });
            break;
        }
        default: {
          break;
        }
      }
    };
 

    render() {
        return (
          <div className="dynamic-button">
            <div id={this.props.id} className={this.state.active ? 'dynamic-button__child-active' :'dynamic-button__child'} onClick={this.click}>
              <button id={`bouton-dynamic-${this.props.id}`} className={this.state.active ? 'dynamic-button__child__second' : 'dynamic-button__child__first'} >{ this.props.text}</button>
            </div>
          </div>
      )
}
}
DynamicButton.defaultProps = {
  text: 'default',
};
export default DynamicButton;