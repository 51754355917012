import React from 'react'
import GLOBAL_CONFIG from '../../../config/config'
import JerseyMain from '../../FanCenter/ChooseJersey/Main'
import UserCodeEntry from '../../FanCenter/ChooseJersey/CodeEntry/User'
import CompanionUserDetails from '../../FanCenter/ChooseJersey/UserDetails/Companion';
import FancenterScenario from '../../FanCenter/ChooseJersey/GiftChoice';
import JerseyTeamSelection from '../../FanCenter/ChooseJersey/Jersey/TeamSelection';
import JerseySizeSelection from '../../FanCenter/ChooseJersey/Jersey/SizeSelection';
import TicketTeamSelection from '../../FanCenter/ChooseJersey/Ticket/TeamSelection';
import JerseyUserDetails from '../../FanCenter/ChooseJersey/Jersey/UserDetails';
import UserDetails from '../../FanCenter/ChooseJersey/UserDetails/User';
import CompanionConfirmation from '../../FanCenter/ChooseJersey/Confirmation/TicketFriend';
import UserConfirmation from '../../FanCenter/ChooseJersey/Confirmation/TicketUser';
import JerseyConfirmation from '../../FanCenter/ChooseJersey/Confirmation/Jersey';
import ErrorJersey from '../../FanCenter/ChooseJersey/Jersey/ErrorJersey';
import ErrorTickets from '../../FanCenter/Tickets/ErrorTickets';
import Faq from '../../FanCenter/Faq/Faq';
import Home23 from '../../Home/Home-23';

const getFancenterJerseyRouting = (context, props) => {
  const pagesMap = [
    {
      path: GLOBAL_CONFIG.Route.fanCenter.userDetails.companion,
      Component: () => <CompanionUserDetails UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.giftChoice,
      Component: () => <FancenterScenario UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection,
      Component: () => <JerseyTeamSelection UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection,
      Component: () => <JerseySizeSelection UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection,
      Component: () => <TicketTeamSelection UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey,
      Component: () => <JerseyUserDetails UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.userDetails.user,
      Component: () => <UserDetails UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.userDetails.companion,
      Component: () => <CompanionUserDetails UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.confirmation.companion,
      Component: () => <CompanionConfirmation UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.confirmation.user,
      Component: () => <UserConfirmation UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey,
      Component: () => <JerseyConfirmation UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.jersey.errorJersey,
      Component: () => <ErrorJersey UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.ticket.errorTickets,
      Component: () => <ErrorTickets UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faqjersey,
      Component: () => <Faq UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    },
    {
      path: GLOBAL_CONFIG.Route.fanCenter.main,
      Component: () => <Home23 UpdateFancenterStatus={() => { context.FancenterStatusUpdate.bind(context) }} />,
    }
  ];

  console.log(pagesMap);

  return pagesMap;
}

export default getFancenterJerseyRouting;
