import React, { useEffect, useState } from 'react';
import "./price.scss";
import PriceComponent from '../PriceComponent/PriceComponent';
import Button from '../Button/Button';
import PromoPopup from '../Popup/PromoPopup';
import appGoogleTracking from '../../../../../modules/googletracking';

const Price = (props) => {

    const [isMobile, setIsMobile] = useState(false);
    const [openModal, setopenModal] = useState(false);
    function toggleModal() {
        setopenModal(!openModal)
    }
    function handleModal() {
        appGoogleTracking.dataLayerPush({
            'dataEvent': "join_now",
            'dataCategory': "Start Page",
            'dataAction': "Click on Join Now",
            'dataLabel': "GAME_SP_PART"
        });
        setopenModal(true);
    }

    useEffect(() => {
        const checkIsMobile = () => {
            const mobileWidthThreshold = 768;
            setIsMobile(window.innerWidth < mobileWidthThreshold);
        };
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return (
        <div id='priceSection' className='price'>
            <PromoPopup modal={openModal} toggleModal={() => toggleModal()}></PromoPopup>
            <h1>Diese Preise warten auf dich</h1>
            <div className='price-banner'>
                {props.game ?
                    <img className="imagebanner" src={isMobile ? '/resources/imagesBundesliga/sommer/circle/price_banner3_sm.png' : '/resources/imagesBundesliga/sommer/circle/price_banner3.png'} alt='price banner' /> :
                    <img src={isMobile ? '/resources/imagesBundesliga/sommer/circle/price_banner1_sm.png' : '/resources/imagesBundesliga/sommer/circle/price_banner1.png'} alt='price banner' />
                }
                {props.game ? 
                    <div className='text-content'>
                        <h3 className='title'>VIP Fan-Event in Berlin</h3>
                        <p className='text'>Schnapp dir deine 4 Freunde für ein Fan-Erlebnis der besonderen Art! Gemeinsam erlebt ihr einen exklusiven VIP Fan-Event in Berlin. Genießt gemeinsam den Fußballsommer. Zwei Hotelübernachtungen mit Verpflegung, die An- und Abreise sowie ein attraktives Rahmenprogramm runden euren Fußballtrip in die Hauptstadt ab. </p>
                        <p className='expli'></p>
                    </div> 
                :
                    <div className='text-content'>
                        <h3 className='title'>Bundesliga Dauerkarten</h3>
                        <p className='text'>Erlebe elektrisierende Stadion-Stimmung. Verfolge gemeinsam mit deiner
                            Begleitung deinen Club der Bundesliga oder 2. Bundesliga bei allen Heimspielen der
                            Saison 2024/25. Freut euch auf viele gemeinsame Stadionbesuche.</p>
                        <p className='expli'>(Werte aller Tickets variieren je nach Club, Auswahl nach Verfügbarkeit; kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga).</p>
                    </div>
                }
            </div>
            <div className='price-content'>
                {props.game ? <PriceComponent title={props.t('Page.Home.Price.7.title')} img={props.t('Page.Home.Price.7.img')} text={props.t('Page.Home.Price.7.text')} /> : null}
                <PriceComponent title={props.t('Page.Home.Price.1.title')} img={props.t('Page.Home.Price.1.img')} text={props.t('Page.Home.Price.1.text')} />
                {props.game ? null : <PriceComponent title={props.t('Page.Home.Price.2.title')} img={props.t('Page.Home.Price.2.img')} text={props.t('Page.Home.Price.2.text')} />}
                <PriceComponent title={props.t('Page.Home.Price.3.title')} img={props.t('Page.Home.Price.3.img')} text={props.t('Page.Home.Price.3.text')} />
                <PriceComponent title={props.t('Page.Home.Price.4.title')} img={props.t('Page.Home.Price.4.img')} text={props.t('Page.Home.Price.4.text')} />
                <PriceComponent title={props.t('Page.Home.Price.5.title')} img={props.t('Page.Home.Price.5.img')} text={props.t('Page.Home.Price.5.text')} />
                <PriceComponent title={props.t('Page.Home.Price.6.title')} img={props.t('Page.Home.Price.6.img')} text={props.t('Page.Home.Price.6.text')} />
            </div>

            <Button onClick={() => handleModal()} >Jetzt mitmachen</Button>
        </div>
    );
}

export default Price;
