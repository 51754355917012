import React, { Component } from 'react';
class TitleLogo extends Component {
    render() {
        let logo1 = {
            desktop: '/resources/imagesBundesliga/banner/logoBundesliga.png',
            mobile: '/resources/imagesBundesliga/banner/logoBundesliga-sm.png',
        };

        return(
            <div>
                <div className="heading__image">
                        <picture>
                        <a className="" data-event="Header Tab" data-category="Informational Action" data-action="Header Tab" data-label="SHOP" target="_blank" rel="noopener noreferrer" href="https://www.bundesliga.com/en/bundesliga"> 
                            <source srcSet={logo1.desktop} media="(min-width: 1024px)" />
                            <source srcSet={logo1.mobile} media="(min-width: 481px)" />
                            <source srcSet={logo1.mobile} media="(min-width: 200px)" />
                            <img className={`logo__image1 ${this.props.classModifier}`} src={logo1.desktop} alt="Milka Christmas product" />
                        </a>
                        </picture>
                </div>
            </div>
        );
    }
}
export default TitleLogo;