import React from 'react'
import { withTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import flatpickr from 'flatpickr'
import ReCAPTCHA from 'react-google-recaptcha'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
//import './styles.css';

// User defined components
import StepContent from '../../../components/Content/StepContent/StepContent'
import TitleLogo from '../../../components/Content/TitleLogo/TitleLogo'
import {
  mainLoaderToggle,
  optionListConstruct,
  ServerDate,
} from '../../../modules/helpers'
import appGoogleTracking from '../../../modules/googletracking'
import AccountServices from '../../../Service/Account'
import AccountModel from '../../../Models/Account'
import LoginForm from '../../../components/Account/LoginForm'
import AccountModels from '../../../Models/Account'
import Salutations from '../../../Models/Salutation'
import Checkbox from '../../../components/Controls/Checkbox'
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial'
import Loader from '../../../components/Overlays/Loader'

// Date selector STYLES
import 'flatpickr/dist/flatpickr.css'
import { Code } from '../../../modules/Code'
import ValidateForm from '../../../modules/validate'
import GLOBAL_CONFIG from '../../../config/config'
import ParticipationService from '../../../Service/Participation'
import DropdownClub from '../../../components/Content/DropdownClub/DropdownClub'
import ParticipationNavigation from '../Buzzer/ParticipationNavigation'
import _ERROR_MESSAGES from '../../../Models/ErrorMessages'
import _LOCAL from '../../../Models/LocalStorageKeys'
import _BUZZER_MESSAGE from '../../../Models/BuzzerErrorMessage'
import _SCROLL_PAGE from '../../../modules/ScrollPage'
import KIPServices from '../../../Service/KIP_Pages'
import _STATUS_CAMPAIGN from '../../../Models/CampaignStatus'

SwiperCore.use([Navigation, Pagination])

class PennyParticipation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      LoginForm: {
        IsLoggedIn: false,
        PopinMessage: AccountModel.ErrorHTML._DEFAULT,
      },
      DisplayLogin: true,
      SimpleLoader: false,
      CodeIsValid: true,
      ShowMoreTerms: false,
      FavouriteTeam: '',
      ErrorMessage: '',
      teamImage: '',
      isMobile: false,
      otherCheckbox: null,
      currentKip: null,
      brands: [],
      brandError: '',
    }
    var limit = new Date()
    limit.setFullYear(limit.getFullYear() - 18)
    this.maxDOB = limit

    var now = new Date()
    now.setHours(0)
    now.setMinutes(0)
    now.setSeconds(0)
    now.setMilliseconds(0)
    var lowerLimit = new Date(2022, 8, 1)
    lowerLimit = now < lowerLimit ? now : lowerLimit
    var maxLimit = new Date()
    this.minPurchaseDate = lowerLimit
    this.maxPurchaseDate = now < maxLimit ? now : maxLimit

    this.FormFiller = this.FormFiller.bind(this)
    this.ErrorHandler = this.ErrorHandler.bind(this)
    this.resize = this.resize.bind(this)
    this.checkMinimumAge = this.checkMinimumAge.bind(this)
    this.ProcessForm = this.ProcessForm.bind(this)
    this.IsFavouriteTeamValid = this.IsFavouriteTeamValid.bind(this)
    window.FILE_STATUS = false
  }

  resize() {
    // set isMobile to true if the device is a mobile and false otherwise
    this.setState({ isMobile: window.innerWidth <= 768 })
  }

  componentDidMount() {
    mainLoaderToggle('hide')
    this.FlatpikerDateInit(this._PRODUCT_DATE, {
      max: this.maxPurchaseDate,
      min: this.minPurchaseDate,
    })
    window.addEventListener('resize', this.resize)
    this.resize()
    _SCROLL_PAGE.ScrollToTop()
    let currentKip = localStorage.getItem(_LOCAL.CurrentKip)
    if (currentKip) {
      this.setState({
        currentKip: currentKip.toLowerCase(),
      })
      if (currentKip.toLowerCase() === 'globus') {
        this.setState({
          otherCheckbox: currentKip.toLowerCase(),
        })
      }
    }
  }


  componentWillUnmount() {
    mainLoaderToggle('show')
  }

  render() {
    return (
      <div id="buzzer-participation" className="penny-participation">
        {this.HeadingSection(this.props)}
        <div
          className={`content-participation__login ${
            this.state.DisplayLogin ? '' : 'none'
          }`}
        >
          <div className="buzzer-form">
            {this.state.DisplayLogin ? this.LoginSection() : null}
          </div>
        </div>
        <div className="content-participation__form">
          {this.DetailsSection(this.props)}

          <p className="special">
          *Gib deine persönlichen Daten sowie deine E-Mail-Adresse ein und lade den Kaufbeleg hoch. Lies dir bitte vorher unsere{' '}
            <a
              href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/"
              target="_blank"
              style={{ color: 'white' }}
            >
              Datenschutzerklärung
            </a>{' '}
            aufmerksam durch, damit du weißt, wie wir mit deinen
            personenbezogenen Daten umgehen und welche Rechte dir zustehen.
          </p>
        </div>
        <Footersocial />
        {this.state.SimpleLoader ? <Loader /> : null}
      </div>
    )
  }

  HeadingSection(props) {
    return (
      <div className="content-home penny-participation">
        <div className="spaceTile-intropage">
          <div className="campaign-heading">
            <StepContent
              step1={props.t('Page.Home.Steps.Buzzer-Main-Penny.1')}
              step1sm={props.t('Page.Home.Steps.Buzzer-Main-Penny.1')}
              step1Text={parse(
                'Zwischen dem <span class="text--wheat">05.09.</span> und dem <span class="text--wheat">17.09.2022</span> Milka, TUC und/oder Wunderbar Produkte im Gesamtwert von mindestens 6€ bei PENNY kaufen.',
              )}
              step_arrow="/resources/imagesBundesliga/Edeka/icons/arrow_right.png"
              step2={props.t('Page.Home.Steps.Buzzer-Main-Penny.2')}
              step2sm={props.t('Page.Home.Steps.Buzzer-Main-Penny.2')}
              step2Text={parse(
                '<a href="/penny-topps/mitmachen">Hier</a> bis zum <span class="text--wheat">18.09.2022</span> den <span class="text--wheat">Kaufbeleg</span> hochladen. (Ein Kaufbeleg pro Teilnahme)',
              )}
              step3={props.t('Page.Home.Steps.Buzzer-Main-Penny.3')}
              step3sm={props.t('Page.Home.Steps.Buzzer-Main-Penny.3')}
              step3Text={parse('Wir prüfen jetzt den Kaufbeleg.')}
              step4={props.t('Page.Home.Steps.Buzzer-Main-Penny.4')}
              step4sm={props.t('Page.Home.Steps.Buzzer-Main-Penny.4')}
              step4Text={parse(
                '<span class="text--wheat">Code</span> für ein Match Attax Multipack erhalten und unter de.topps.com einlösen.',
              )}
            />
          </div>
        </div>
      </div>
    )
  }

  LoginSection() {
    return (
      <LoginForm
        PreRequest={() => this.setState({ SimpleLoader: true })}
        SuccessHandler={this.LoginSuccess.bind(this)}
        ErrorHandler={this.LoginError.bind(this)}
        ServiceFailedHandler={this.LoginServiceError.bind(this)}
      />
    )
  }

  DetailsSection(props) {
    return (
      <form
        id="frm_participation"
        name="frm_participation"
        method="post"
        action="/"
        ref={(form) => (this._FORM_DETAILS = form)}
        onSubmit={this.SubmitHandler.bind(this)}
        noValidate
      >
        <div className="buzzer-form" id="participant-info">
          <h2>Kein FC Milka Mitglied?</h2>
          <h3>Dann hier ohne Mitgliedschaft teilnehmen</h3>

          <div className="form-container" id="userDetails">
            <div id="salutation" className="input-container">
              <p>*Pflichtfelder</p>
              <div className="form-input__container form-input__dropdown">
                <select
                  ref={(select) => (this._DETAILS_salutation = select)}
                  className="form-input__input js-event-type-in js-event-ab"
                  type="text"
                  name="salutation"
                  id="salutation"
                  placeholder="Arende*"
                  data-require="true"
                  data-type="ddl"
                  data-error-target="#error-salutation"
                  data-required-message="Anrede ist erforderlich."
                  data-event-fieldname="Salutation"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="Salutation"
                  data-label="Type In"
                >
                  {Object.keys(Salutations.dropdown).map((key) => {
                    var i = 0
                    return (
                      <option value={key} key={`${i++}-${key}`}>
                        {Salutations.dropdown[key]}
                      </option>
                    )
                  })}
                </select>
                <label className="form-input__label" htmlFor="salutation">
                  {Salutations.dropdown['default']}
                </label>
              </div>
            </div>

            <div id="firstName" className="input-container">
              <div className="form-input__container">
                <label className="form-input__label" htmlFor="firstname">
                  Vorname*
                </label>
                <input
                  className="form-input__input js-event-type-in js-event-ab"
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="Vorname*"
                  data-require="true"
                  data-type="regex"
                  data-error-target="#error-firstname"
                  data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                  data-required-message="Firstname is required."
                  data-pattern-message="Firstname not valid."
                  data-event-fieldname="Firstname"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="Firstname"
                  data-label="Type In"
                  ref={(input) => (this._DETAILS_firstName = input)}
                />
              </div>
            </div>

            <div id="lastName" className="input-container">
              <div className="form-input__container">
                <label className="form-input__label" htmlFor="lastname">
                  Nachname*
                </label>
                <input
                  className="form-input__input js-event-type-in js-event-ab"
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Nachname*"
                  data-require="true"
                  data-type="regex"
                  data-error-target="#error-lastname"
                  data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                  data-required-message="Lastname is required."
                  data-pattern-message="Lastname not valid."
                  data-event-fieldname="Lastname"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="Lastname"
                  data-label="Type In"
                  ref={(input) => (this._DETAILS_lastName = input)}
                />
              </div>
            </div>
            <p id="error-email" style={{ color: 'red' }}></p>
            <div id="email-container" className="input-container">
              <div className="form-input__container">
                <label className="form-input__label" htmlFor="email">
                  Email*
                </label>
                <input
                  className="form-input__input js-event-type-in js-event-ab"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="E-Mail*"
                  data-require="true"
                  data-type="regex"
                  data-error-target="#error-email"
                  data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                  data-required-message=""
                  data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift"
                  data-event-fieldname="Email"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="Email"
                  data-label="Type In"
                  ref={(input) => (this._DETAILS_email = input)}
                />
                <span
                  id="error-email"
                  className="form-input__error active"
                ></span>
              </div>
            </div>
              <div
                id="dob-container"
                className={'input-container'}
              >
                <Checkbox
                  id="birth-terms"
                  required={true}
                  errorMessage="Ungültiges Alter"
                  text="Bitte bestätige hier, dass du mindestens 18 Jahre alt bist.*"
                  onChangeHandler={this.CheckboxChangeHandler.bind(this)}
                />
              </div>
          </div>
        </div>

        <hr className="separator" />

        <div className="team-retailer__form">
          <div className="buzzer-form retailer-container__form">
            {this.DisplayRecieptForm(props)}
            {this.RetailerSection(props)}
          </div>
          {this.TeamSection(props)}
        </div>

        {this.ConsentSection(props)}

        {/* <div className="form-container centered captcha">
          <ReCAPTCHA
            ref={(ref) => (this._CAPTCHA = ref)}
            hl={'de'}
            size="invisible"
            sitekey={GLOBAL_CONFIG.Captcha.default}
            onChange={this.OnCaptchaResolved.bind(this)}
            onExpired={this.OnCaptchaExpired.bind(this)}
            onErrored={this.OnCaptchaError.bind(this)}
          />
        </div> */}

        {this.state.ErrorMessage.length > 0 ? (
          <p className="buzzer-error">{this.state.ErrorMessage}</p>
        ) : null}

        <button type="submit" className="btn__container btn--secondary-lilac">
          JETZT TEILNEHMEN
        </button>
      </form>
    )
  }

  DisplayRecieptForm(t) {
    const messageFileRequired = `Du hast noch keinen Kaufbeleg hochgeladen. Wähle die entsprechende Datei aus und lade deinen Kassenbon hoch. Bitte denke dabei daran, die nicht an der Aktion teilnehmenden Artikel auf dem Kassenbon unkenntlich zu machen.`
    return (
      <div className="campaign-form-receipt">
        <h3>Kaufbeleg hochladen*</h3>
        <img
          src="/resources/images/upload-receipt-imgage.png"
          width="156"
          height="173"
          alt="upload receipt"
        />
        <label
          className="btn__container btn--secondary-lilac btn--hover custom-file-btn"
          htmlFor="file_to_upload"
        >
          <span>DATEI AUSWÄHLEN</span>
          <input
            ref={(fileInput) => (this._RECEIPT_file = fileInput)}
            type="file"
            id="file_to_upload"
            name="file_to_upload"
            data-require="true"
            data-file-required="true"
            data-type="file"
            data-error-target="#error-file"
            data-required-message={messageFileRequired}
            data-oversize-error="Ungültige Dateigröße. Bitte Dateigröße Vorgabe beachten."
            data-format-error="Ungültiges Dateiformat. Bitte nur gültiges Format hochladen."
            onChange={(e) => {
              ValidateForm.checkfileUpload(e.target)
            }}
          />
        </label>
        <p className="info-receipt">
          Du kannst deinen Kaufbeleg als .jpg, .png oder .pdf hochladen.
        </p>
        <div className="form-container results">
          <span className="form-input__error js-file-error">Error</span>
          <span className="form-input__success js-file-success">Success</span>
        </div>
      </div>
    )
  }

  ValidParticipantInfo(e) {
    const isValid = ValidateForm.validateForm('participant-info')
    if (isValid) {
      _SCROLL_PAGE.ScrollSection(document.querySelector('.team-retailer__form'))
    } else {
      let firstError = this._FORM_DETAILS.querySelector('.notvalid')

      if (!!firstError) {
        const yOffset = -110,
          y =
            firstError.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        })
      }
    }
  }

  TeamSection(props) {
    const imagePath = '/resources/imagesBundesliga/team-picture/'
    let footballClubContent = props.t(
      'Page.Participation.form.footballClub.footballClubListObject',
      { returnObjects: true },
    )
    let swiperSlides = []
    let maxTeamToShow = this.state.isMobile ? 2 : 8
    let count = 0
    let teamsTemp = []
    for (let i = 0; i < footballClubContent.length; i++) {
      teamsTemp.push(
        <div
          className={`team ${
            this.state.FavouriteTeam === footballClubContent[i].id
              ? 'active'
              : ''
          }`}
          key={'team' + i}
          onClick={() => {
            const favoriteTeamError = document.getElementById(
              'favorite-team-error',
            )
            favoriteTeamError.style.display = 'none'
            favoriteTeamError.classList.remove('notvalid')
            this.FavouriteClubSelection(footballClubContent[i].id)
          }}
        >
          <div className="team-logo">
            <img
              src={imagePath + footballClubContent[i].name + '.png'}
              alt={footballClubContent[i].name}
            />
          </div>
          <p>{footballClubContent[i].name}</p>
        </div>,
      )
      count++
      if (count === maxTeamToShow || i === footballClubContent.length - 1) {
        const teamsSlide = teamsTemp
        swiperSlides.push(
          <SwiperSlide
            key={'team-slide-' + footballClubContent[i].name}
            tag="li"
            style={{ listStyle: 'none' }}
          >
            <div className="chooseTeam--team-container">{teamsSlide}</div>
          </SwiperSlide>,
        )
        teamsTemp = []
        count = 0
      }
    }

    return (
      <div className="chooseTeam buzzer-form team-container__form">
        <h2 className="heading">Verrate uns deinen Lieblingsclub*</h2>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation
          pagination
          wrapperTag="ul"
          tag="section"
          onSlideNextTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: 'info-click',
              dataCategory: 'Informational Action',
              dataAction: 'Carousel Click',
              dataLabel: 'Right',
            })
          }}
          onSlidePrevTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: 'info-click',
              dataCategory: 'Informational Action',
              dataAction: 'Carousel Click',
              dataLabel: 'Left',
            })
          }}
        >
          {swiperSlides}
        </Swiper>
        <p id="favorite-team-error">Verpflichtend !</p>
      </div>
    )
  }

  RetailerSection(props) {
    let retailerList = props.t(
        'Page.Participation.form.retailerList.retailers',
        { returnObjects: true },
      ),
      brandList = props.t('Page.Participation.form.brands', {
        returnObjects: true,
      })
    const defaultRetrailer = retailerList.shift()
    retailerList.sort((a, b) => a.display.localeCompare(b.display))
    retailerList.unshift(defaultRetrailer)

    return (
      <div className="form-container">
        <Checkbox
          id="product-min-price"
          required={true}
          errorMessage={'Bitte auswählen'}
          text="Ich habe Produkte von Milka, TUC und/oder Wunderbar im Gesamtwert von mindestens 6€ bei PENNY gekauft.*"
          onChangeHandler={this.CheckboxChangeHandler.bind(this)}
        />
        {/* <h6 className="centered">Wo hast du das Produkt gekauft?*</h6>
                <div className="input-container">
                    <div className="form-input__container form-input__dropdown">
                        <select ref={select => this._PRODUCT_retailer = select} className="form-input__input js-event-type-in js-event-ab"
                            type="text" name="brand"
                            id="brand"
                            placeholder="Arende*"
                            data-require="true"
                            data-type="ddl"
                            data-error-target="#error-salutation"
                            data-required-message="Salutation is required."
                            data-event-fieldname="Salutation"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Salutation"
                            data-label="Type In"
                            onChange={(e) => {
                                if(     e.target.value.toLowerCase() === "globus" 
                                    || e.target.value.toLowerCase() === "globus haus halle" 
                                ){
                                    this.setState({
                                        otherCheckbox : "globus"
                                    });
                                }else {
                                    this.setState({
                                        otherCheckbox : null
                                    });
                                }
                            }}>

                            {
                                
                                retailerList.map(retailer => {
                                    let { value, display, limit } = retailer;
                                    if (!!limit) {
                                        let { upper, lower } = limit,
                                            currentDate = new Date(),
                                            lowerLimitDate = new Date(lower.year, lower.month - 1, lower.day - 1),
                                            upperLimitDate = new Date(upper.year, upper.month - 1, upper.day + 1);

                                        if (currentDate > lowerLimitDate && currentDate < upperLimitDate) {
                                            return <option value={value} key={value}>{display}</option>
                                        } else {
                                            return null;
                                        }
                                    }
                                    let selected = false;
                                    let currentKip = localStorage.getItem(_LOCAL.CurrentKip);
                                    if(currentKip && value.toLowerCase() == currentKip.toLowerCase()){
                                        selected = true;
                                    }else if(currentKip != null && currentKip.toLowerCase() == "netto" && value.toLowerCase() == "Netto Marken-Discount / Süd".toLowerCase()){
                                        selected = true;
                                    }
                                    return <option value={value} key={value} selected={selected}>{display}</option>
                                })
                            }
                        </select>
                        <label className="form-input__label" htmlFor="salutation">{Salutations.dropdown["default"]}</label>
                    </div>
                </div> */}

        <h6 className="centered">
        Wann hast du die Produkte
          <br />
          gekauft?*
        </h6>
        <div className="input-container">
          <div className="form-input__container active js-datepicker-dob">
            <input
              className="form-input__input js-event-type-in js-event-ab"
              type="text"
              name="product-purchase"
              id="product-purchase"
              placeholder="TT.MM.JJJJ"
              autoComplete="off"
              readOnly
              data-require="true"
              data-type="regex"
              data-error-target="#product-purchase-error"
              data-regex-pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$"
              data-required-message="Datum ist erforderlich."
              data-pattern-message="Um an der Aktion teilnehmen zu können, musst du zwischen dem 11.04.2022 und 05.06.2022 ein Milka Produkt gekauft haben."
              data-event-fieldname="Date"
              data-event="form-click"
              data-category="Form Action"
              data-action="Date"
              data-label="Type In"
              ref={(input) => (this._PRODUCT_DATE = input)}
              onBlur={(e) => {
                const dateStr = e.target.value.split('.')
                const date = new Date(dateStr[2], dateStr[1] - 1, dateStr[0])

                if (
                  +date < +this.minPurchaseDate ||
                  +date > +this.maxPurchaseDate
                ) {
                  this.unvalidInput(e.target)
                } else {
                  this.validInput(e.target)
                }
              }}
            />
          </div>
          <p id="product-purchase-error" style={{ color: '#D93333' }}></p>
        </div>

        <h6 className="centered">
          Welches Milka, TUC und/oder Wunderbar Produkt hast du gekauft?*
        </h6>
        {this.state.brandError && (
          <p style={{ color: '#D93333' }}>{this.state.brandError}</p>
        )}
        <Checkbox
          id="brand-tuc"
          className="brand"
          required={false}
          errorMessage={''}
          text="TUC"
          onChangeHandler={(event, _ref) => {
            this.CheckBrand(_ref, 'tuc')
            this.CheckboxChangeHandler(event, _ref)
          }}
          ref={(input) => (this._PRODUCT_type_TUC = input)}
        />
        <Checkbox
          id="brand-milka"
          className="brand"
          required={false}
          errorMessage={''}
          text="Milka"
          onChangeHandler={(event, _ref) => {
            this.CheckBrand(_ref, 'milka')
            this.CheckboxChangeHandler(event, _ref)
          }}
          ref={(input) => (this._PRODUCT_type_Milka = input)}
        />
        <Checkbox
          id="brand-wunderbar"
          className="brand"
          required={false}
          errorMessage={''}
          text="Wunderbar"
          onChangeHandler={(event, _ref) => {
            this.CheckBrand(_ref, 'wunderbar')
            this.CheckboxChangeHandler(event, _ref)
          }}
          ref={(input) => (this._PRODUCT_type_Wunderbar = input)}
        />

        {/* <div className="input-container">
                    <div className="form-input__container">
                        <label className="form-input__label" htmlFor="product_type">Produktart</label>
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="product_type"
                            id="product_type"
                            placeholder="Produktart"
                            data-require="false"
                            data-type="text"
                            maxLength="50"
                            data-error-target="#error-product-type"
                            data-required-message="Product Type is required."
                            data-event-fieldname="Product Type is required."
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="House number"
                            data-label="Type In"
                            ref={input => this._PRODUCT_type = input}
                        />
                    </div>
                </div> */}
      </div>
    )
  }

  CodeEntrySection() {
    /** onBlur = { this.ValidateCode.bind(this) } */
    return (
      <div className="campaign-code">
        <h4>Bitte gib deinen Produktcode ein.*</h4>
        <p>Du ﬁndest den Produktcode auf der Packungsrückseite</p>
        <div className="campaign-code-container">
          <picture>
            <source
              srcSet="/resources/imagesBundesliga/buzzer/code-notice-big.png"
              media="(min-width: 1024px)"
            />
            <source
              srcSet="/resources/imagesBundesliga/buzzer/code-notice-big.png"
              media="(min-width: 481px)"
            />
            <source
              srcSet="/resources/imagesBundesliga/buzzer/code-notice.png"
              media="(min-width: 200px)"
            />
            <img
              src="/resources/imagesBundesliga/buzzer/code-notice.png"
              alt="Produktcode"
            />
          </picture>
          <p id="info">
            Der Produktcode besteht aus Zahlen und Buchstaben. (Hinweis:
            Befindet sich kein Produktcode auf dem Artikel, so müssen die Zahlen
            beim Barcode eingegeben werden.)
          </p>
          <input
            className={
              this.state.CodeIsValid
                ? 'campaign-code-input'
                : 'campaign-code-input notvalid'
            }
            type="text"
            ref={(input) => (this._CODE_input = input)}
          />
        </div>
      </div>
    )
  }

  ConsentSection(props) {
    return (
      <div className="campaign-consent">
        <Checkbox
          id="terms"
          required={true}
          errorMessage={_ERROR_MESSAGES.Terms}
          text='Ich habe die  <a class="js-event-cta" href="/penny-topps/teilnahmebedingungen" target="_blank" rel="noopener noreferrer" title="Teilnahmebedingungen" data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="Dark Milk">Teilnahmebedingungen</a> gelesen und akzeptiert.*'
          onChangeHandler={this.CheckboxChangeHandler.bind(this)}
        />
        {this.state.ShowMoreTerms ? (
          <p className="buzzer-tcs">
            Wir schätzen dein Vertrauen in unseren Umgang mit deinen
            personenbezogenen Daten. Verantwortliche Stelle für die Verarbeitung
            deiner personenbezogenen Daten im Rahmen dieses Gewinnspiels ist die
            Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen
            (Mondelez Deutschland). Mit der Durchführung des Gewinnspiels sind
            die folgenden Agenturen beauftragt: Consultix GmbH, Wachtstr. 17–24,
            28195 Bremen, Baudek & Schierhorn Shopper Marketing GmbH,
            Hermann-Maul-Str. 2, 21073 Hamburg und bürozwei GmbH & Co KG,
            Theodor-Heuss-Allee 21, 28215 Bremen auf einem Server in
            Deutschland. Die Agenturen unterliegen sämtlichen anwendbaren
            Datenschutzgesetzen. Sie sind im Rahmen einer Auftragsverarbeitung
            für uns tätig und an die Anforderungen entsprechender Verträge
            gebunden. Eine darüber hinausgehende Weitergabe deiner
            personenbezogenen Daten an Dritte erfolgt nicht.
            <br />
            <br />
            Wir speichern deine personenbezogenen Daten nach Maßgabe der
            rechtlichen Vorschriften und ausschließlich zum Zweck der Abwicklung
            dieses Gewinnspiels (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. b) der
            Datenschutz-Grundverordnung EU 2016/679, DS-GVO). Es werden die
            E-Mail-Adresse, der Name, Adressdaten und das Geburtsdatum der
            Teilnehmer erfasst und ausschließlich zur Verifizierung der
            Teilnahme/Gewinner gespeichert. Du kannst deine Teilnahme an dem
            Gewinnspiel jederzeit ohne Angabe von Gründen durch Widerruf
            aufheben. Hierzu genügt eine formlose E-Mail an{' '}
            <a href="mailto:verbraucherservice@mdlz.com">
              verbraucherservice@mdlz.com
            </a>
            .
            <br />
            <br />
            Im Falle eines Widerrufs werden deine personenbezogenen Daten
            selbstverständlich gelöscht.
            <br />
            <br />
            Soweit keine gesetzlichen Aufbewahrungspflichten bestehen, erfolgt
            eine umgehende Löschung der Daten, nachdem das Gewinnspiel
            abgeschlossen ist. Eine umgehende Löschung erfolgt bezüglich der
            Daten der Nichtgewinner. Gewinnerdaten sind aus gesetzlichen und
            buchhalterischen Gründen nach Maßgabe der §§ 257 HGB, 147 AO, 14b
            UStG aufzubewahren. Für diese Speicherung der Gewinnerdaten ist die
            Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. c) DS-GVO, da die
            Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich ist, der Mondelez Deutschland unterliegt. Weitere
            Informationen zu dem Umgang von Mondelez Deutschland mit deinen
            personenbezogenen Daten und zu deinen gesetzlichen Rechten und dazu,
            wie du diese ausüben kannst, findest du in unserer{' '}
            <a
              href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/"
              target="_blank"
            >
              Datenschutzerklärung
            </a>
            .
          </p>
        ) : (
          <a
            id="moreInfo"
            href="#"
            style={{ textDecoration: 'underline' }}
            onClick={(event) => {
              event.preventDefault()
              this.setState({ ShowMoreTerms: true })
            }}
          >
            <strong>{'„Mehr Informationen >>“'}</strong>
          </a>
        )}

        {typeof this.state.otherCheckbox === 'string' &&
          this.state.otherCheckbox !== null && (
            <Checkbox
              id="globus-terms"
              required={false}
              errorMessage={_ERROR_MESSAGES.Terms}
              text={props.t(
                `Page.Participation.form.Terms.${this.state.otherCheckbox}`,
              )}
              onChangeHandler={this.CheckboxChangeHandler.bind(this)}
            />
          )}

        {!this.state.LoginForm.IsLoggedIn ? (
          <Checkbox
            id="member"
            text="Ich möchte kostenlos FC Milka Mitglied werden."
            onChangeHandler={this.CheckboxChangeHandler.bind(this)}
          />
        ) : null}

        {this.state.member ? (
          <div id="passWordReg">
            <h4>Bitte gib dein Passwort ein</h4>
            <div className="row">
              <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                <div className="input-container">
                  <label
                    className="form-input__label"
                    htmlFor="passwordRegister"
                  >
                    Passwort*
                  </label>
                  <input
                    ref={(input) => (this._DETAILS_pswd = input)}
                    className="form-input__input js-event-type-in js-event-ab"
                    type="password"
                    name="passwordRegister"
                    id="passwordRegister"
                    placeholder="Passwort*"
                    data-require="true"
                    data-type="regex"
                    data-error-target="#error-password"
                    data-regex-pattern=""
                    data-required-message="Passwort erforderlich"
                    data-pattern-message=""
                    data-event-fieldname="PasswordRegister"
                    data-event="form-click"
                    data-category="Form Action"
                    data-action="PasswordRegister"
                    data-label="Type In"
                  />
                </div>
              </div>
              <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                <div className="input-container">
                  <label
                    className="form-input__label"
                    htmlFor="confirmPassword"
                  >
                    Passwort wiederholen*
                  </label>
                  <input
                    onChange={this.ConfirmPassword.bind(this)}
                    ref={(input) => (this._DETAILS_confirm_pswd = input)}
                    className="form-input__input js-event-type-in js-event-ab"
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Passwort wiederholen*"
                    data-require="true"
                    data-type="regex"
                    data-error-target="#error-passwordConfirm"
                    data-regex-pattern=""
                    data-required-message="Passwort erforderlich"
                    data-pattern-message=""
                    data-event-fieldname="Password"
                    data-event="form-click"
                    data-category="Form Action"
                    data-action="Password"
                    data-label="Type In"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <Checkbox
          id="newsletter"
          text={props.t('Page.Participation.form.newsletter')}
          onChangeHandler={this.CheckboxChangeHandler.bind(this)}
        />
      </div>
    )
  }

  ConfirmPassword() {
    let password = this._DETAILS_pswd.value,
      confirm = this._DETAILS_confirm_pswd.value

    if (password.length < 1 || password !== confirm) {
      this._DETAILS_confirm_pswd.classList.add('notvalid')
      return false
    }

    this._DETAILS_confirm_pswd.classList.remove('notvalid')
    return true
  }

  CheckBrand = (ref, value) => {
    if (ref.checked && !this.state.brands.includes(value)) {
      let br = this.state.brands
      br.push(value)
      this.setState({
        brands: br,
      }, () => {
        if(this.state.brands.length > 0){
          this.setState({
            brandError: ""
          })
        }else{
          this.setState({
            brandError: "Wählen Sie mindestens eine Marke aus"
          })
        }
      })
    } else if (!ref.checked && this.state.brands.includes(value)) {
      let br = this.state.brands
      br.splice(br.indexOf(value),1)
      this.setState({
        brands: br,
      }, () => {
        if(this.state.brands.length > 0){
          this.setState({
            brandError: ""
          })
        }else{
          this.setState({
            brandError: "Wählen Sie mindestens eine Marke aus"
          })
        }
      })
    }
  }

  CheckboxChangeHandler(event, _ref) {
    if (_ref.hasAttribute('data-require') && !_ref.checked) {
      ValidateForm.error_message(
        'error',
        _ref,
        _ref.getAttribute('data-error-target'),
        _ref.getAttribute('data-required-message'),
        _ref.getAttribute('id'),
      )
      _ref.parentNode.classList.add('notvalid')
    }

    if (_ref.checked) {
      _ref.parentNode.classList.remove('notvalid')
    }

    this.setState({
      [_ref.getAttribute('id')]: _ref.checked,
    })
  }

  FavouriteClubSelection(team) {
    this.setState({
      FavouriteTeam: team,
    })
  }

  FormFiller(UserData) {
    var {
      salutation,
      firstname,
      lastname,
      street1,
      street2,
      zipcode,
      city,
      email,
      birthday,
      streetnumber,
      favclub,
      isFcAccUser,
      phoneNumber,
    } = UserData

    if (isFcAccUser) {
      switch (salutation) {
        case '1':
          salutation = Salutations._MALE
          break
        case '2':
          salutation = Salutations._FEMALE
          break
        case '10':
          salutation = Salutations._OTHERS
          break

        default:
          break
      }

      if(salutation != null && salutation != undefined && salutation != ""){
        this._DETAILS_salutation.value = salutation;
        this._DETAILS_salutation.disabled = true;
      }
      this._DETAILS_email.value = email;
      this._DETAILS_email.readOnly = true;
      this._DETAILS_firstName.value = firstname;
      this._DETAILS_firstName.readOnly = true;
      this._DETAILS_lastName.value = lastname;
      this._DETAILS_lastName.readOnly = true;
    }

    this.setState({
      SimpleLoader: false,
      DisplayLogin: false,
    })
  }

  LoginSuccess({ LoginToken, FavouriteClub }) {
    AccountServices.UserDetails({ LoginToken })
      .then((response) => {
        const { success, isFcAccUser } = response.data
        if (success) {
          var preLoginForm = { ...this.state.LoginForm }
          preLoginForm.IsLoggedIn = true
          console.log('UserDetail : ', response.data)
          this.FormFiller(response.data)

          this.setState({
            LoginForm: preLoginForm,
            SimpleLoader: false,
          })
        }
      })
      .catch((error) => {})
  }

  LoginError(ErrorMessage, { emailRef, passRef }) {
    switch (ErrorMessage) {
      case AccountModels.ErrorStatus._INVALID_DATA:
        emailRef.classList.add('notvalid')
        passRef.classList.add('notvalid')
        return AccountModels.ErrorStatus._INVALID_DATA

      case AccountModels.ErrorStatus._INVALID_CAPTCHA:
        console.log('Captcha error message')

        //Datalayer push for Error
        this.triggerDatalayers({
          status: 'error',
          type: 'general',
        })
        return AccountModels.ErrorStatus._INVALID_CAPTCHA

      case AccountModels.ErrorStatus._GENERIC_ERROR:
        return AccountModels.ErrorStatus._GENERIC_ERROR

      case AccountModel.ErrorStatus._INACTIVE:
        return _ERROR_MESSAGES.Account.INACTIVE

      default:
        break
    }

    this.setState({
      SimpleLoader: false,
    })
  }

  LoginServiceError() {
    this.setState({
      SimpleLoader: false,
    })
  }

  calculateAge(birthday) {
    const ageDifMs = Date.now() - birthday
    const ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  validInput(...list) {
    for (let i = 0; i < list.length; i++) {
      list[i].classList.add('valid')
      list[i].classList.remove('notvalid')
      if (list[i].hasAttribute('data-error-target')) {
        const element = document.querySelector(
          list[i].getAttribute('data-error-target'),
        )
        if (element) {
          element.innerHTML = ''
        }
      }
    }
  }

  unvalidInput(...list) {
    for (let i = 0; i < list.length; i++) {
      list[i].classList.add('notvalid')
      list[i].classList.remove('valid')

      if (list[i].hasAttribute('data-error-target')) {
        const element = document.querySelector(
          list[i].getAttribute('data-error-target'),
        )
        if (element) {
          element.innerHTML = list[i].getAttribute('data-pattern-message')
        }
      }
    }
  }

  checkMinimumAge(e, regex) {
    if (!e.target.value.match(regex)) {
      this.unvalidInput(e.target)
      return
    } else {
      this.validInput(e.target)
    }

    let birthday = null
    let inputs = []

    if (this._DETAILS_dob) {
      let date = this._DETAILS_dob.value.split('.')
      birthday = new Date(date[2], date[1] - 1, date[0])
      inputs = [e.target]
    } else {
      const day = document.querySelector('input#day'),
        month = document.querySelector('input#month'),
        year = document.querySelector('input#year')
      inputs = [day, month, year]
      const dayValue = day.value,
        monthValue = month.value,
        yearValue = year.value
      if (dayValue && monthValue && yearValue) {
        birthday = new Date(yearValue, monthValue - 1, dayValue)
        day.value =
          birthday.getDate() < 10
            ? '0' + birthday.getDate()
            : birthday.getDate()
        month.value =
          birthday.getMonth() + 1 < 10
            ? '0' + (birthday.getMonth() + 1)
            : birthday.getMonth() + 1
        year.value = birthday.getFullYear()
      }
    }

    if (birthday != null) {
      const age = this.calculateAge(birthday)
      if (age < 18) {
        this.unvalidInput(...inputs)
      } else {
        this.validInput(...inputs)
      }
    }
  }

  redirectToSelfieGame(UpdateParticipationStatus, UpdateParticipationData) {
    UpdateParticipationStatus()
    UpdateParticipationData(null)
  }

  IsFavouriteTeamValid() {
    const favoriteTeamError = document.getElementById('favorite-team-error')
    if (this.state.FavouriteTeam == '') {
      favoriteTeamError.style.display = 'block'
      favoriteTeamError.classList.add('notvalid')
      return false
    }
    favoriteTeamError.style.display = 'none'
    favoriteTeamError.classList.remove('notvalid')
    return true
  }

  IsFileInputValid(){
    var emailErrorSelector = document.querySelector('.js-file-error');
    if(emailErrorSelector.classList.contains('active')){
      return false;
    }
    return true;
  }

  SubmitHandler(event) {
    event.preventDefault()

    const isValid = ValidateForm.validateForm('frm_participation'),
      isAccepted = this.state.terms,
      isFavouriteTeamValid = this.IsFavouriteTeamValid(),
      hasSelectedBrand = this.state.brands.length > 0,
      passwordValid = this.state.member ? this.ConfirmPassword() : true,
      IsFileInputValid = this.IsFileInputValid(),
      { UpdateParticipationStatus, UpdateParticipationData } = this.props
    if (!hasSelectedBrand) {
      this.setState({
        brandError: 'Wählen Sie mindestens eine Marke aus',
      })
    } else {
      this.setState({
        brandError: '',
      });
    }
    if (isAccepted && isValid && passwordValid && isFavouriteTeamValid && hasSelectedBrand && IsFileInputValid) {
      // this.TriggerCaptcha()
      this.ProcessForm(null);
    } else {
      /**
       * Scroll To first error
       */
      let firstError = this._FORM_DETAILS.querySelector('.notvalid')

      if (window.FILE_STATUS === false) {
        firstError = this._FORM_DETAILS.querySelector('.campaign-form-receipt')
      }

      if (!!firstError) {
        const yOffset = -110,
          y =
            firstError.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        })
      }
    }
  }

  ProcessForm(token) {
    let salutation = this._DETAILS_salutation.value,
      { UpdateParticipationStatus, UpdateParticipationData } = this.props

    switch (salutation) {
      case 'Herr':
        salutation = '1'
        break
      case 'Frau':
        salutation = '2'
        break
      case 'Divers':
        salutation = '10'
        break

      default:
        break
    }

    //Process form data
    let _data = {
      Receipt: this._RECEIPT_file.files[0],
      salutation: salutation,
      firstname: this._DETAILS_firstName.value,
      lastname: this._DETAILS_lastName.value,
      email: this._DETAILS_email.value,
      birthday: '1999-03-20',
      favouriteTeam: this.state.FavouriteTeam,
      newsletter: this.state.newsletter ? '1' : '0',
      brand: this.state.brands,
      purchaseDate: ServerDate(this._PRODUCT_DATE.value),
      captcha: token,
      referrer: 'Website',
      register: this.state.member ? '1' : '0',
      password: !!this._DETAILS_pswd ? this._DETAILS_pswd.value : '',
      islogin: this.state.LoginForm.IsLoggedIn ? '1' : '0',
      productMinPrice: this.state['product-min-price'] ? '1' : '0',
    }

    this.setState({
      SimpleLoader: true,
    })
    // this._CAPTCHA.reset()

    let _Data = new FormData()

    for (let key in _data) {
      _Data.append(key, _data[key])
    }

    ParticipationService.ValidateCodePennyMicroSite(_Data)
      .then((response) => {
        let { status, success, data } = response.data
        this.setState({
          SimpleLoader: false,
        })

        if (success) {
          /**
           * Sucess handler
           */
          localStorage.setItem(_LOCAL.Register, _data.register)
          if (typeof UpdateParticipationData === 'function') {
            UpdateParticipationData({ ...data, ..._data })
          }

          if (typeof UpdateParticipationStatus === 'function') {
            UpdateParticipationStatus()
          }
        } else {
          if (!!data.Error && data.Error.length > 0) {
            this.ErrorHandler(data.Error[0])
          } else {
            this.setState({
              ErrorMessage: 'SERVER ERROR',
            })
          }
        }
      })
      .catch(() => {
        this.setState({
          SimpleLoader: false,
        })
      })
  }

  ErrorHandler(Message) {
    switch (Message) {
      case _BUZZER_MESSAGE.PARTICIPATION_LIMIT:
        this.setState({
          ErrorMessage: _ERROR_MESSAGES.Buzzer.CODE_LIMIT,
        })
        break
      case _BUZZER_MESSAGE.CODE_INVALID:
        this.setState({
          CodeIsValid: false,
          ErrorMessage: _ERROR_MESSAGES.Buzzer.INVALID_CODE,
        })
        break
      case _BUZZER_MESSAGE.INVALID_CAPTCHA:
        this.setState({
          ErrorMessage: Message,
        })
        break
      case _BUZZER_MESSAGE.REGISTRATION_FAILED:
        this.setState({
          ErrorMessage: Message,
        })
        break

      default:
        this.setState({
          ErrorMessage: 'SERVER ERROR',
        })
        break
    }
  }

  OnCaptchaResolved(token) {
    this.setState({
      SimpleLoader: true,
    })
    this.ProcessForm(token)
  }

  OnCaptchaError() {
    this._CAPTCHA.reset()
    this.setState({
      SimpleLoader: false,
    })
    this.setState({
      ErrorMessage: 'CAPTCHA ERROR',
    })
  }

  OnCaptchaExpired() {
    this._CAPTCHA.reset()
    this.setState({
      SimpleLoader: false,
    })
    this.setState({
      ErrorMessage: 'CAPTCHA EXPIRED', //ABGELAUFEN
    })
  }

  TriggerCaptcha() {
    this._CAPTCHA.execute()
  }

  FlatpikerDateInit(_REF, { max, min }, _def = null) {
    flatpickr(_REF, {
      dateFormat: 'd.m.Y',
      maxDate: max,
      minDate: min,
      disableMobile: true,
      defaultDate: !!_def ? new Date(_def) : null,
    })
  }
}

export default withTranslation()(PennyParticipation)
