import React from "react";
import { NavLink } from "react-router-dom";
import { mainLoaderToggle } from "../../../modules/helpers";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import PopUp from "./PopUp";
import { isProd } from "../../../modules/helpers";

class Prize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    _SCROLL_PAGE.ScrollToTop();
  }

  componentWillMount() {
    setTimeout(() => {
      _SCROLL_PAGE.ScrollToTop();
    }, 200);
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    return (
      <>
        <div id="buzzer-prizes" className="trikot-prizes doppelt-prizes">
          <div className="trikot-content-prizes">
            <div className="trikot-promo-container">
              <h3>Über 25.000 Preise warten auf dich</h3>

              <div className="trikot-promo-container">
                <div className="trikot-promo-container-wrapper">
                  <div className="trikot-promo-card">
                    <div className="trikot-promo__content primary">
                      <h4>
                        <span class="good-headline-gold">100 x</span> 2
                        VIP-Tickets
                      </h4>
                      <p>
                        Sei beim Spiel-Anpfiff hautnah dabei und lass dich von
                        der Live-Atmosphäre mitreißen. Mit dem VIP-Ticket für
                        ein Spiel der Bundesliga / 2. Bundesliga der Saison
                        2023/24 für dich und eine weitere Person sorgst du für
                        einen unvergesslichen Stadionbesuch.
                      </p>
                    </div>
                    <div className="trikot-promo__content secondary">
                      <img
                        src="/resources/imagesBundesliga/\doppeltgewinnen/card/tickets.png"
                        alt="tickets"
                      />
                    </div>
                  </div>
                </div>

                <div className="trikot-promo-container-wrapper">
                  <div className="trikot-promo-card reverse">
                    <div className="trikot-promo__content primary">
                      <h4>Die perfekte Fußsball-Ausstattung</h4>
                      <p>
                        Hier schlägt das Fußballherz höher: Gewinne 1 von 100
                        Fanshop Gutscheinen à 100€ der Bundesliga / 2.
                        Bundesliga für einen Club deiner Wahl. Des Weiteren
                        wartet auf dich 1 von 10.000 Rabattgutscheinen in Höhe
                        von 20 % für 11teamsports. Dort wirst du garantiert
                        fündig, um deine Ausrüstung zu komplettieren.
                      </p>
                    </div>
                    <div className="trikot-promo__content secondary">
                      <img
                        src="/resources/imagesBundesliga/\doppeltgewinnen/card/t-shirt.png"
                        alt="t-shirt"
                      />
                    </div>
                  </div>
                </div>

                <div className="trikot-promo-container-wrapper">
                  <div className="trikot-promo-card">
                    <div className="trikot-promo__content primary">
                      <h4>
                        <span class="good-headline-gold">1.000 x</span> 1
                        Bundesliga Ball von DERBYSTAR
                      </h4>
                      <p>
                        Gewinne einen Bundesliga Brilliant Replica Fußball der
                        Bundesliga-Saison 2023/24 von DERBYSTAR. Dein perfekter
                        Begleiter für eine Fußball-Partie mit Freunden.
                      </p>
                    </div>
                    <div className="trikot-promo__content secondary">
                      <img
                        src="/resources/imagesBundesliga/\doppeltgewinnen/card/derbystar.png"
                        alt="derbystar"
                      />
                    </div>
                  </div>
                </div>
                <div className="trikot-promo-container-wrapper last">
                  <div className="trikot-promo-card reverse">
                    <div className="trikot-promo__content primary">
                      <h4>
                        KICKER EMAGAZINE JAHRESABO ODER EINZELAUSGABEN GEWINNEN
                      </h4>
                      <p>
                        Mit dem kicker bist du über alles, was in der
                        Fußballwelt passiert, bestens informiert. Gewinne jetzt
                        1 von 150 digitalen Jahresabos oder 1 von 15.000
                        digitalen Einzelausgaben in der kicker eMagazine App.
                      </p>
                    </div>
                    <div className="trikot-promo__content secondary">
                      <img
                        src="/resources/imagesBundesliga/\doppeltgewinnen/card/magazine.png"
                        alt="k eMagazine"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="cta-container">
                <button
                  className="btn__container btn--secondary-lilac"
                  data-event="begin_participation_bottom"
                  data-category="Prizes Page"
                  data-action="Click on Join Now"
                  data-label="GAME_PRIZE_OTHER"
                  onClick={(e) => {
                    this.modalTracking(e);
                  }}
                >
                  jetzt mitmachen
                </button>
              </div>
              <PopUp
                modal={this.state.modal}
                toggleModal={() => {
                  this.toggleModal();
                }}
              />
            </div>
          </div>
          <Footersocial />
        </div>
      </>
    );
  }

  modalTracking(event) {
    this.setState({ modal: true });
    appGoogleTracking.processEventCTANavLink(event);
  }
}

export default Prize;
