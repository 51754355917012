const _GIFT_MESSAGES = {
    NotWinner: "EMAIL_NOT_WINNER",
    Used: "CODE_ALREADY_USED",
    Expired: "CODE_EXPIRED",
    CodeInvalid: "CODE_NOT_MATCH",
    CaptchaError: "INVALID_RECAPTCHA_RESPONSE",
    OUT_OF_STOCK: "GIFT_OUT_OF_STOCK",
    FRIEND_DETAIL_NOT_UNIQUE: "FRIEND_DETAIL_NOT_UNIQUE",
    WINNER_EMAIL_DUPLICATE: "WINNER_EMAIL_DUPLICATE",
    NOT_FRIEND: "NOT_FRIEND_CODE",
    TICKET_GAMEWEEK_EXPIRED: "TICKET_GAMEWEEK_EXPIRED",
};

export default _GIFT_MESSAGES;