import React, { useState } from 'react';
import "./stepContent.scss";
import Button from '../Button/Button';
import PromoPopup from '../Popup/PromoPopup';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import GLOBAL_CONFIG from '../../../../../config/config';
import appGoogleTracking from '../../../../../modules/googletracking';

const StepContent = (props) => {
    const [openModal, setopenModal] = useState(false)
    function toggleModal() {
        setopenModal(!openModal)
    }
    function handleModal() {
        appGoogleTracking.dataLayerPush({
            'dataEvent': "join_now",
            'dataCategory': "Start Page",
            'dataAction': "Click on Join Now",
            'dataLabel': "GAME_SP_PART"
        });
        setopenModal(true);
    }

    function handleModalHier() {
        appGoogleTracking.dataLayerPush({
            'dataEvent': "upload_receipt link",
            'dataCategory': "Start Page",
            'dataAction': "Click on Link",
            'dataLabel': "GAME_SP_PART"
        });
        setopenModal(true);
    }
    return (
        <div className='stepcontentheimtrikot'>
            <PromoPopup modal={openModal} toggleModal={() => toggleModal()}></PromoPopup>
            <h1>So einfach geht’s:</h1>
            <div className="step_content">
                <div className='step one'>
                    <img src='resources\imagesBundesliga\sommer\step\step4_sm.png' alt='step 1' />
                    <p>1. Kaufe zwischen dem <span className='date'>01.11.2024</span> und dem <span className='date'>29.12.2024</span> ein Milka und/oder OREO Produkt.</p>
                </div>
                <div className='step two'>
                    <img src='resources\imagesBundesliga\sommer\step\step2_sm.png' alt='step 2' />
                    <p>2. Gib <a
                        onClick={() => handleModalHier()}
                    >
                        hier
                    </a> bis zum <span className='date'>29.12.2024</span> den <span className='date'>Produktcode</span> von der Produktverpackung ein*.</p>
                </div>
                <div className='step three'>
                    <img src='resources\imagesBundesliga\sommer\step\step3_sm.png' alt='step 3' />
                    <p>3. Mitmachen und mit etwas Glück gewinnen.</p>
                </div>
            </div>
            <p className='para'>*Hinweis: Befindet sich kein Produktcode auf dem Artikel, müssen die Zahlen des Barcodes eingegeben werden. Mehrfachteilnahmen mit neuem Produktcode innerhalb der Aktionsprodukte erlaubt. Bitte Original-Kaufbeleg als Nachweis aufbewahren.</p>

            <Button onClick={() => handleModal()} >JETZT MITMACHEN</Button>
            <p className='txt'>Hier findest du die <a rel="noreferrer" target="_blank"
                    href={GLOBAL_CONFIG.Route.terms}
                    data-event="click_conditions"
                    data-category="Start Page"
                    data-action="Click on Conditions of Participation"
                    data-label="ENG_SP_OTHER"
                    onClick={appGoogleTracking.processEventCTANavLink}
                > Teilnahmebedingungen
                </a> und <a rel="noreferrer" target="_blank"
                    href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/"
                    data-event="click_privacy_policy"
                    data-category="Start Page"
                    data-action="Click on Data Protection Regulations"
                    data-label="ENG_SP_OTHER"
                    onClick={appGoogleTracking.processEventCTANavLink}
                > Datenschutzbestimmungen</a>
                </p>
        </div>
    );
}

export default StepContent;
