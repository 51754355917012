import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import flatpickr from "flatpickr";
import { withRouter } from 'react-router-dom';

import TitleLogo from "../../../../components/Content/TitleLogo/TitleLogo";
import Loader from "../../../../components/Overlays/Loader";
import GLOBAL_CONFIG from "../../../../config/config";
import Salutations from "../../../../Models/Salutation";
import { mainLoaderToggle, ServerDate } from "../../../../modules/helpers";
import ValidateForm from "../../../../modules/validate";
import JerseyNavigation from "../JerseyNavigation";
import Footersocial from '../../../../components/Layout/FooterContent/FooterSocial';
import Checkbox from "../../../../components/Controls/Checkbox";
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import FanCenterServices from "../../../../Service/FanCenter";
import _LOCAL from "../../../../Models/LocalStorageKeys";
import _GIFT from "../../../../Models/GiftTypes";
import _STATUS from "../../../../Models/ParticipationSteps";
import _USER_TYPES from "../../../../Models/UserTypes";
import JerseyStepIndicitor from "../StepIndicator";
import _ERROR_MESSAGES from "../../../../Models/ErrorMessages";
import _GIFT_MESSAGES from "../../../../Models/GiftErrorMessages";

class CompanionUserDetails extends React.Component {
    constructor() {
        super();

        var limit = new Date();
        this.maxDOB = limit;

        this.state = {
            ConvidInfo: false,
            SimpleLoader: false,
            ErrorMessage: "",
            UserType: "",
            WinnerName: "Winner Name",
            MatchDate: "Sa. 01.01.2021",
            MatchLocation: "Match Location",
            HomeTeam: "Home Team",
            AwayTeam: "Away Team",
        }
        
        this.StepList = [
            {
                display: "Code-Eingabe",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "Lieblingsclub & Gewinnauswahl",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "Daten-Check",
                isInProgress: true,
                isComplete: false,
            }
        ];

        this.ProcessError = this.ProcessError.bind(this);
    }

    componentDidMount() {
        if(localStorage.getItem(_LOCAL.UserDetails) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user)
        }
        this.FlatpikerDateInit(this._DETAILS_dob, {max: this.maxDOB});
        let UserDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));

        console.log("UserDetails : ", UserDetails);
        
        if(!!UserDetails
            && !!UserDetails.UserType
            && !!UserDetails.Match) {
            this.setState({
                UserType: UserDetails.UserType,
                MatchDate: UserDetails.Match.weekendDate ? `Spieltag ${UserDetails.Match.gameweek} (${UserDetails.Match.weekendDate})` : `${UserDetails.Match.date} Uhr`,
                MatchLocation: UserDetails.Match.location,
                HomeTeam: UserDetails.Match.home,
                AwayTeam: UserDetails.Match.away,
                WinnerName: UserDetails.Name
            });

            switch (UserDetails.UserType) {
                case _USER_TYPES.Comp1:
                    // this.maxDOB.setFullYear(this.maxDOB.getFullYear() - 18);
                    // this.FlatpikerDateInit(this._DETAILS_dob, {max: this.maxDOB});
                    break;
            
                default:
                    break;
            }
        }


        _SCROLL_PAGE.ScrollToBanner();
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div className="buzzer-jersey">
                {this.HeadingSection()}
                
                <form id="frm_user_details_ticket"
                    ref = { form => this._DETAILS_COMPANION = form }
                    name="frm_user_details_ticket" 
                    method="post" 
                    action="/"
                    onSubmit={this.SubmitHandler.bind(this)} >

                { this.UserDetailsSection() }

                <ReCAPTCHA
                    ref={ref => this._CAPTCHA = ref}
                    hl={GLOBAL_CONFIG.Captcha.language}
                    size="invisible"
                    sitekey={GLOBAL_CONFIG.Captcha.publish}
                    onChange={this.OnCaptchaResolved.bind(this)}
                    onExpired={this.OnCaptchaExpired.bind(this)}
                    onErrored={this.OnCaptchaError.bind(this)}
                />

                </form>

                <div id="Covid-Details">
                    {
                        this.state.CovidInfo ? 
                        <div className="back--red">
                            <p>
                                Auf Grund der aktuellen COVID-19 Situation erhalten wir die Tickets sehr kurzfristig vor dem Spieltag von den Clubs.
                                <br />
                                Das Milka Team hat keinen Einfluss auf die Ticketausstellung, das wird seitens der einzelnen Clubs geregelt. Es ist uns darum nicht möglich, nähere Angaben zum genauen Versandzeitpunkt zu machen.
                                Bitte berücksichtige auch, dass einige Clubs insbesondere bei steigenden Inzidenzzahlen überlegen, den Zugang zum Stadion nur vollständig Geimpften oder genesenen Zuschauern zu gestatten. Diese Entscheidung und Verantwortung obliegt ausschließlich den jeweiligen Clubs.
                                Wir bitten um deine Geduld und dein Verständnis. Bitte informiere dich vor dem Stadionbesuch bei den entsprechenden Stellen.
                            </p>
                        </div> : null
                    }
                </div>


                {
                    this.state.SimpleLoader ? <Loader /> : null
                }

                <Footersocial />
            </div>
        );
    }

    HeadingSection() {
        return (
            <div>
                <br/>
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                    <button className="btn__container btn--secondary-dark" onClick={this.ScrollCovidInfo.bind(this)}>COVID-19 Infos</button>
                        <JerseyStepIndicitor Steps={this.StepList} /> 
                        {
                            this.state.UserType === _USER_TYPES.Comp1 ?
                            <h3 className="lilac recoleta-medium">Wohin sollen wir dein Ticket schicken?</h3>
                            : <h3 className="lilac recoleta-medium">Hallo lieber Fußballfan,</h3>
                        }
                        {
                            this.state.UserType === _USER_TYPES.Comp1 ?
                            <p className="lilac">
                                Bitte gib hier deine Daten ein, damit wir dein personalisiertes Ticket verschicken können. 
                                Nach dem Absenden erhältst du eine E-Mail mit einem weiteren Code und dem Link zum Fan-Center. 
                                Leite diese Mail bitte unbedingt schnellstmöglich an deine Begleitperson weiter. 
                                Deine Begleitperson muss selbst ihre Adressdaten eingeben (Datenschutz), damit anschließend eure beiden Tickets von der DFL personalisiert und verschickt werden können. 
                                Ansonsten verfällt leider das zweite Ticket.
                            </p>
                            :
                            <p className="lilac">
                                du hast die tolle Gelegenheit, <span id="winner-name"><strong>{this.state.WinnerName}</strong></span> am <strong>{this.state.MatchDate}</strong> in <strong>{this.state.MatchLocation}</strong> für die Begegnung <strong>{this.state.HomeTeam}</strong> gegen <strong>{this.state.AwayTeam}</strong> ins Stadion zu begleiten.
                                <br/>
                                Bitte hab Verständnis dafür, dass der DFL e.V. corona-bedingt nur personalisierte Tickets vergeben kann. 
                                Dafür brauchen wir ein paar Angaben von dir. Bitte fülle dafür das Adressformular vollständig aus. 
                                Du bekommst dann dein personalisiertes Ticket zugeschickt.
                            </p>
                        }
                        {
                            this.state.UserType === _USER_TYPES.Comp1 ?
                            <p className="lilac">
                                Nicht vergessen: Lies dir bitte vorher unsere <a href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank" rel="noopener noreferrer">Datenschutzhinweise</a> aufmerksam durch, damit du weißt, wie wir mit deinen personenbezogenen Daten umgehen und welche Rechte dir zustehen.
                            </p>
                            :
                            <p className="lilac">
                                Nicht vergessen: Lies dir bitte vorher unsere <a href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank" rel="noopener noreferrer">Datenschutzhinweise</a> aufmerksam durch, damit du weißt, wie wir mit deinen personenbezogenen Daten umgehen und welche Rechte dir zustehen.
                            </p>
                        }
                    </div>
                </div>
            </div>
        );
    }

    UserDetailsSection() {
        return(
            <div className="buzzer-form">
                <div className="form-container"  id="companionUserDetails">
                    <p>*Pflichtfelder</p>
                    <div id="salutation" className="input-container">
                        <div className="form-input__container form-input__dropdown">
                            <select ref={ select => this._DETAILS_salutation = select} className="form-input__input js-event-type-in js-event-ab" 
                                type="text" name="salutation" 
                                id="salutation" 
                                placeholder="Arende*" 
                                data-require="true" 
                                data-type="ddl" 
                                data-error-target="#error-salutation" 
                                data-required-message="Salutation is required." 
                                data-event-fieldname="Salutation" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Salutation" 
                                data-label="Type In">
                                {
                                    Object.keys(Salutations.dropdown).map( key => {
                                        var i = 0;
                                        return <option value={ key } key={ `${i++}-${key}`} >{ Salutations.dropdown[key] }</option>
                                    })
                                }
                            </select>
                            <label className="form-input__label" htmlFor="salutation">{ Salutations.dropdown["default"] }</label>
                        </div>
                    </div>

                    <div id="firstName" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="firstname">Vorname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="firstname" 
                                id="firstname" 
                                placeholder="Vorname*" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-firstname" 
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$" 
                                data-required-message="Firstname is required." 
                                data-pattern-message="Firstname not valid." 
                                data-event-fieldname="Firstname" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Firstname" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_firstName = input }
                                />
                        </div>
                    </div>

                    <div id="lastName" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="lastname">Nachname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="lastname" 
                                id="lastname" 
                                placeholder="Nachname*" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-lastname" 
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$" 
                                data-required-message="Lastname is required." 
                                data-pattern-message="Lastname not valid." 
                                data-event-fieldname="Lastname" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Lastname" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_lastName = input } 
                                />
                        </div>
                    </div>

                    <div id="street" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="street_name">Straße*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="street_name" 
                                id="street_name" 
                                placeholder="Straße*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-street-number" 
                                data-required-message="Street number is required." 
                                data-event-fieldname="Street number" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Street number" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_street_name = input } 
                                />
                        </div>
                    </div>

                    <div id="houseNumber" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="street_number">Hausnummer*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="street_number" 
                                id="street_number" 
                                placeholder="Hausnummer*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-house-number" 
                                data-required-message="House number is required." 
                                data-event-fieldname="House number" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="House number" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_street_number = input } 
                                />
                        </div>
                    </div>

                    <div id="address-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="adress">Adresszusatz</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="address" 
                                id="address" 
                                placeholder="Zusätzliche Adressinformation (optional)" 
                                data-require="false" 
                                data-event-fieldname="Address" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Address" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_address = input }
                                />
                        </div>
                    </div>

                    <div id="dob-container" className="input-container">
                        <div className="form-input__container active js-datepicker-dob">
                            <label className="form-input__label" htmlFor="dob">Geburtsdatum*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="dob" id="dob" 
                                placeholder="TT.MM.JJJJ" 
                                autoComplete="off" 
                                readOnly 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-dob" 
                                data-regex-pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" 
                                data-required-message="Birthday is required." 
                                data-pattern-message="Birthday is not valid." 
                                data-event-fieldname="Birthday" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Birthday" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_dob = input } 
                                />
                        </div>
                    </div>

                    <div id="zipCode" className="input-container">
                        <div className="form-input__container space-right">
                            <label className="form-input__label" htmlFor="zip_code">PLZ*</label>
                            <input className="form-input__input js-event-type-in js-event-ab js-zip-code" 
                                type="text" 
                                name="zip_code" 
                                id="zip_code" 
                                placeholder="PLZ*" 
                                maxLength="5" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-zip-code" 
                                data-regex-pattern="^[0-9]{5}$" 
                                data-required-message="Zip code is required." 
                                data-pattern-message="Zip code not valid." 
                                data-event-fieldname="Zip code" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Zip code" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_zipCode = input }
                                />
                        </div>
                    </div>

                    <div id="city-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="city">Stadt*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="city" 
                                id="city" 
                                placeholder="Stadt*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-city" 
                                data-required-message="City is required." 
                                data-event-fieldname="City" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="City" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_city = input }
                                />
                        </div>
                    </div>

                    <div id="email-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="Email">Nachname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                ref = { input => this._DETAILS_email = input } 
                                type="email"
                                name="Email" 
                                id="Email" 
                                placeholder="Deine E-Mail-Adresse*" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-emailname" 
                                data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$" 
                                data-required-message="" 
                                data-pattern-message="" 
                                data-event-fieldname="Email" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Email" 
                                data-label="Type In"
                                />
                                <span className="information">Bitte verwende für deine Begleitperson eine andere E-Mail-Adresse als für dich.</span>
                        </div>
                    </div>

                    <div id="telephone-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="city">Telefonnummer*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="tel" 
                                name="tel" 
                                id="tel" 
                                placeholder="Telefonnummer*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-tel" 
                                data-required-message="" 
                                data-event-fieldname="Telephone" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Telephone" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_tel = input }
                                />
                        </div>
                    </div>

                </div>

                <Checkbox id="terms"
                    required={true}
                    errorMessage={_ERROR_MESSAGES.Terms}
                    text='Ich habe die <a class="js-event-cta" href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" rel="noopener noreferrer">Datenschutzbestimmungen</a> gelesen und akzeptiert.*'
                    onChangeHandler={this.CheckboxChangeHandler.bind(this)}
                />

                {
                    this.state.ErrorMessage.length > 0 ?
                    <p className="buzzer-error">{this.state.ErrorMessage}</p>
                    : null
                }

                <button type="submit" className="btn__container btn--red">ABSENDEN</button>
            </div>
        );
    }

    CheckboxChangeHandler(event, _ref) {
        if(_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if(_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    OnCaptchaResolved (token) {
        this.setState({
            SimpleLoader: true
        })
        this.ProcessForm(token)
    }

    ProcessForm(CaptchaToken) {
        let salutation = this._DETAILS_salutation.value,
            giftCategory = localStorage.getItem(_LOCAL.GiftCategory),
            userDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails))
        switch (salutation) {
            case "Herr":
                salutation = "1";
                break;
            case "Frau":
                salutation = "2";
                break;
            case "Divers":
                salutation = "10";
                break;
        
            default:
                break;
        }

        var _data = {
            captcha: CaptchaToken,
            CodeEntered: userDetails.Code,
            GiftCode: userDetails.OrderCode,
            GiftType: userDetails.Type,
            ConsumerId: userDetails.Id,
            TicketType: giftCategory,
            GiftCategory: giftCategory,
            MatchId: userDetails.Match.id,
            MatchUp: `${userDetails.Match.home} − ${userDetails.Match.away}`,
            GameDate:  userDetails.Match.weekendDate ? `Spieltag ${userDetails.Match.gameweek} (${userDetails.Match.weekendDate})` : userDetails.Match.date,
            Email: this._DETAILS_email.value,
            City: this._DETAILS_city.value,
            Firstname:  this._DETAILS_firstName.value,
            Lastname: this._DETAILS_lastName.value,
            Street1: this._DETAILS_street_name.value,
            Streetnumber: this._DETAILS_street_number.value,
            Street2: this._DETAILS_address.value,
            Zipcode: this._DETAILS_zipCode.value,
            Telephone: this._DETAILS_tel.value,
            Birthday: ServerDate(this._DETAILS_dob.value),
            Salutation: salutation,
        }

        if(this.state.UserType === _USER_TYPES.Comp2) {
            FanCenterServices.UserDetails.Companion(_data)
            .then(response => {
                var {success, message, data} = response.data;
    
                if(success) {
                    switch (userDetails.Type.toLowerCase()) {
                        case _GIFT.Ticket:
                            /**
                             * Check if user or companion
                             */
                            var _new_data = {
                                ...JSON.parse(localStorage.getItem(_LOCAL.UserDetails)),
                                Name: `${_data.Firstname} ${_data.Lastname}`
                            }
                            localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(_new_data))
    
                            this.props.UpdateFancenterStatus(_STATUS.Fancenter.detailsInserted)
                            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.confirmation.companion);
                            break;
                            
                        default:
                            this.props.UpdateFancenterStatus(_STATUS.Fancenter.detailsInserted)
                            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey);
                            break;
                    }
                } else {
                    this.ProcessError(message);
                }
            })
            .catch( () => {
                if(!!this._CAPTCHA) {
                    this._CAPTCHA.reset()
                }
            })
            .finally( () => {
                if(!!this._CAPTCHA) {
                    this._CAPTCHA.reset();
                }
    
                if(!!this._DETAILS_COMPANION) {
                    this._DETAILS_COMPANION.reset();
                }
    
                this.setState({
                    SimpleLoader: false
                });
            })
        }
        
        if(this.state.UserType === _USER_TYPES.Comp1) {
            _data = {
                ..._data,
                ConsumerId: userDetails.ConsumerId
            }
            FanCenterServices.UserDetails.FirstCompanion(_data)
            .then( response => {
                var {success, message, data} = response.data;

                if(success) {
                    this.props.UpdateFancenterStatus(_STATUS.Fancenter.detailsInserted)
                    switch (userDetails.Type.toLowerCase()) {
                        case _GIFT.Ticket:
                            /**
                             * Check if user or companion
                             */
                            var _data_new = {
                                ...JSON.parse(localStorage.getItem(_LOCAL.UserDetails)),
                                Name: `${_data.Firstname} ${_data.Lastname}`
                            }
                            localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(_data_new))

                            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.confirmation.companion);
                            break;
                            
                        default:
                            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey);
                            break;
                    }
                } else {
                    this.ProcessError(message);
                }
            })
            .catch()
            .finally( () => {
                if(!!this._CAPTCHA) {
                    this._CAPTCHA.reset();
                }
    
                if(!!this._DETAILS_TICKET) {
                    this._DETAILS_TICKET.reset();
                }
    
                this.setState({
                    SimpleLoader: false
                });
            })

        }
    }

    ProcessError(ErrorMessage) {
        if(!!ErrorMessage) {
            switch (ErrorMessage) {
                case _GIFT_MESSAGES.OUT_OF_STOCK:
                    this.setState({
                        ErrorMessage: _ERROR_MESSAGES.Ticket.Unvailable
                    })
                    break;
                case _GIFT_MESSAGES.FRIEND_DETAIL_NOT_UNIQUE:
                    this.setState({
                        ErrorMessage: _ERROR_MESSAGES.Details.NotUnique
                    })
                    break;
                case _GIFT_MESSAGES.WINNER_EMAIL_DUPLICATE:
                    this.setState({
                        ErrorMessage: _ERROR_MESSAGES.Details.EmailDuplicate
                    })
                    break;
            
                default:
                    this.setState({
                        ErrorMessage
                    })
                    break;
            }

        } else {
            this.setState({
                ErrorMessage: "SERVER ERROR"
            })
        }
    }

    OnCaptchaExpired () {
        this._CAPTCHA.reset();
    }

    OnCaptchaError () {
        this._CAPTCHA.reset();
    }

    FlatpikerDateInit( _REF, {max}, _def = null ) {
        flatpickr(_REF, {
            dateFormat: "d.m.Y",
            maxDate: max,
            disableMobile: true,
            defaultDate: _def
        });
    }

    SubmitHandler(event) {
        event.preventDefault();
        var isValid = ValidateForm.validateForm('frm_user_details_ticket'),
            isAccepted = this.state.terms

        if(isValid && isAccepted) { 
            this._CAPTCHA.execute();
        } else {
            /**
             * Display Error
             */
        }
    }

    ScrollCovidInfo(e) {
        this.setState({
            CovidInfo: true
        })

        _SCROLL_PAGE.ScrollSection(document.getElementById("Covid-Details"))
    } 
}

export default withRouter(CompanionUserDetails);