import i18n from "i18next";                                             //Use for localization of text
import LanguageDetector from "i18next-browser-languagedetector";
import axios from "axios";
import GLOBAL_CONFIG from "../config/config";
// import { campaignSettings } from '../modules/helpers';

//Get campaign status -> rewe, kaufland or muller
// const PROMOTION_SETTINGS = campaignSettings(GLOBAL_CONFIG);

let CONTENT__ALL = null,
    jsonPath = '';

//Set JSON file to retrieve
// if(PROMOTION_SETTINGS.campaignStatus === 'rewe'){
//     jsonPath = GLOBAL_CONFIG.JSON.rewe;
// } else if(PROMOTION_SETTINGS.campaignStatus === 'kaufland'){
//     jsonPath = GLOBAL_CONFIG.JSON.kaufland;
// } else if(PROMOTION_SETTINGS.campaignStatus === 'muller'){
//     jsonPath = GLOBAL_CONFIG.JSON.muller;
// }
jsonPath = GLOBAL_CONFIG.JSON.kaufland;
//Get JSON localisation
const getLocalisation = async () => {
    try {
        let response = await axios.get(jsonPath);
        return response;
    } catch (e) {
        return 'error';
    }
}

const initLocalisation = () => {
    i18n.use(LanguageDetector).init({
        // we init with resources
        resources: {
            de: CONTENT__ALL
        },
        fallbackLng: "de",
        debug: false,
    
        // have a common namespace used around the full app
        ns: ["data"],
        defaultNS: "data",
    
        keySeparator: ".",
    
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ","
        },
        cache: {
            enabled: false
        },
    
        react: {
            wait: false
        }
    });
}

//Build the localisation process
const buildLocalisedContent = async () => {
    try {
        CONTENT__ALL = await getLocalisation();

        initLocalisation();
    } catch (e) {
        console.error(e);
    }
}

//Initialize the localisation process to gather the JSON data
buildLocalisedContent();

export default i18n;


