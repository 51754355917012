import React from "react";
import { NavLink } from 'react-router-dom';
import { mainLoaderToggle } from "../../../modules/helpers";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import CountDownBanner from "../../../components/Content/CountDownBanner/CountDownBanner";

class Q3Prize extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillMount(){
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <>  
                <CountDownBanner/>
                <div id="buzzer-prizes">
                    { this.headingSection() }
                    { this.midSection() }
                    { this.footerSection() }
                    <Footersocial />
                </div>
            </>
        );
    }

    headingSection() {
        return (
            <div className="campaign-prize-header">
                <p>Der Ball ist rund und ein Spiel dauert 90 Minuten. Genau darum geht es im neuen FC Milka Gewinnspiel ab dem 11.07.2022. Sei schnell und nutze deine Chance, in nur 90 Minuten einen von knapp 5.500 Preisen der Bundesliga / 2. Bundesliga zu gewinnen.</p>
            </div>
        );
    }

    midSection() {
        return (
                        <div className="prizeRewe campaign-footer reward_90min">
                        <div className="prizeRewe-card">
                            <div className="prizeRewe-card__primary">
                                <img src="/resources/imagesBundesliga/90_min/prizes/tickets.png" alt="Gold im Wert"/>
                                <p>Abbildung unverbindlich</p>
                                <h3>Tickets für die Saison 2022/23</h3>
                                <p>Erlebe mit dem FC Milka die einmalige Live-Atmosphäre vor Ort im Stadion. Gewinne aus 244 x 2 CAT2-Tickets Plätze für dich und deine Begleitperson für die Saison 2022/23 für die Bundesliga / 2. Bundesliga. (Werte variieren je nach Club, Auswahl nach Verfügbarkeit. Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga.)</p>
                            </div>
                            <div className="prizeRewe-card__wrapper">
                                <div className="prizeRewe-card__secondary">
                                    <div className="prizeRewe-card__img">
                                        <img src="/resources/imagesBundesliga/90_min/prizes/trikots.png" alt="Dauerkarten der Saison"/>
                                        <p>Abbildung unverbindlich</p>
                                    </div>
                                    <div className="prizeRewe-card__text">
                                        <h3>Originale Heimtrikots</h3>
                                        <p>Fußball geht nicht nur unter, sondern auch über die Haut. Nutze deine Chance auf eins von 183 <span className="bold-text">signierten</span> original Bundesliga Heimtrikots oder
        1 von 183 original Bundesliga Heimtrikots.</p>
                                    </div>
                                </div>
                                <div className="prizeRewe-card__secondary secon">
                                    <div className="prizeRewe-card__img">
                                        <img src="/resources/imagesBundesliga/90_min/prizes/starter_pack.png" alt="Dauerkarten der Saison"/>
                                        <p>Abbildung unverbindlich</p>
                                    </div>
                                    
                                    <div className="prizeRewe-card__text">
                                        <h3>Bundesliga Starterpack</h3>
                                        <p>Sammeln, tauschen und die Bundesliga immer dabeihaben. Gewinne eins von 4.880 Match Attax Starterpacks. Dein spannendes Trading Card Game inklusive Sammelmappe, Sammlermagazin und Spielfeld. </p>
                                    </div>
                                </div>                                  
                            </div>
                        </div>
                    </div>
        );
    }

    footerSection() {
        return(
            <div className="campaign-footer campaign-prize-footer">
                <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                    <button className="btn__container btn--lilac"> JETZT MITMACHEN </button>
                </NavLink>
                <p>
                    Die Gewinner werden schriftlich per E-Mail über den Gewinn benachrichtigt.
                </p>
                <p>
                    Übrigens: Während des Aktionszeitraums vom 11.07.2022 bis 10.09.2022 kannst du mehrfach teilnehmen. Pro Produktcode ist aber nur eine Teilnahme möglich.
                </p>
                <p>
                    *Kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga. Ausschluss oder Einschränkungen von Gewinnen aus Gründen der vorherrschenden Corona-Lage vorbehalten. Es gelten zudem die jeweils aktuell gültigen Zutrittsregelungen der Clubs, über die sich der Gewinner informieren muss. Barauszahlung und anderweitige Kompensation sowie Rechtsweg ausgeschlossen.
                </p>
            </div>
        )
    }
}

export default Q3Prize;