import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config'; 
import { mainLoaderToggle } from '../../modules/helpers';
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import TitleLogo from '../../components/Content/TitleLogo/TitleLogo';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import appGoogleTracking from '../../modules/googletracking';

class Account extends Component {

    componentDidMount(){
        mainLoaderToggle('hide');
       
    }
    componentWillUnmount(){
        mainLoaderToggle('show');
        
    }

    render() {
        return(
            <div className="main-confirmation_account">
                <TitleContent 
                    heading="Dein FC Milka Account – Nur noch ein Schritt"
                />
                <div className="confirmation_account">
                    <div className="confirmation_account-text">
                        <TitleContent
                            paragraph="Schön, dass du Mitglied beim FC Milka bist! In Kürze erhältst du eine E-Mail mit einem Link zur Bestätigung. Bitte klicke diesen Link, um deine Registrierung beim FC Milka Club abzuschließen."
                            paragraph2="Es warten viele spannende Aktionen von Milka und der deutschen Bundesliga auf dich."
                        />
                        <img src="/resources/imagesBundesliga/fcAccount/ShieldFlat.png"></img>
                        <div className="form-button">
                        {/* <button> */}
                            <div className="btn__container">
                                <NavLink to={GLOBAL_CONFIG.Route.intropage} exact data-event="info-click" data-category="Informational Action" data-action="T&amp;C" 
                                    onClick={() => this.googleTag('go_to_FCMilka', 'Confirmation Page', 'Go to Page', 'MAIN_SITE_CP_OTHER')}>
                            <span className="btn__text">FC Milka</span></NavLink>
                                
                            </div>
                        {/* </button> */}
                        </div>  
                        
                    </div>
               
                </div>
                {/* <TitleLogo/> */}
                <Footersocial/>
            </div>
        )
    }
    googleTag = (event, category, action, label) => {
        appGoogleTracking.dataLayerPush({
            'dataEvent': event,
            'dataCategory': category,
            'dataAction': action,
            'dataLabel': label
        })
    }
    }
        export default withTranslation()(withRouter(Account));