import React from 'react';
import parse from 'html-react-parser';
import appGoogleTracking from '../../../modules/googletracking';
/* 
    White section with text and button
*/

const WhiteSectionText = (props) => {
    const func = () => {
        console.log("child function");
        
    };

    let pModifier = !!props.pClass ? props.pClass : "";

    let heading = props.heading;
    if (typeof heading === 'string') {
        heading = parse(heading);
    }

    let RenderParaText = (props.paragraph !== undefined && props.paragraph !== null) ? <p className={`text-section-heading__paragraph `}>{props.paragraph}</p> : null,
        RenderedButton = (props.textButton !== undefined && props.textButton !== null) ? <button type="submit" className="btn__container btn--lilac" onClick={(e)=>{props.handleChild();appGoogleTracking.processEventCTANavLink(e)}} 
        data-event= {props.dataEvent} 
        data-category= {props.dataCategory} 
        data-action= {props.dataAction}  
        data-label= {props.dataLabel} >{props.textButton}</button> : null;



    return (
        <div className="text-section-heading">
            {RenderParaText}
            {RenderedButton}
        </div>
    );
}

export default WhiteSectionText;
