import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import { mainLoaderToggle  } from '../../../modules/helpers';
import { connect } from "react-redux";
import { setIsBannerShown  } from "../../../store/banner";
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial';
import _LOCAL from '../../../Models/LocalStorageKeys';

class ErrorTickets extends Component {

    constructor() {
        super();
        this.state = {
            ticketError: localStorage.getItem(_LOCAL.ErrorTickets) || 'cat2',
            ticketErrorType: localStorage.getItem(_LOCAL.ErrorTicketsType) || 'late'
         };
    }

    componentDidMount(){
        const { t, setIsBannerShown } = this.props;
        setIsBannerShown(false);

        //Remove main loader
 
        //   if (localStorage.getItem(_LOCAL.ErrorTickets)) {
        //     this.setState({ticketError : });
        // }
        mainLoaderToggle('hide');
    }

    componentWillUnmount(){
        //Show mainloader
        mainLoaderToggle('show');
        setIsBannerShown(true);
      
    }

    render() {
        return (
            <div >
                 {this.errorBanner()}
                <Footersocial/>
            </div>
        );
    }
    errorBanner() {
        const basePath = "/resources/imagesBundesliga/fanCenter/banner/tickets";
        let bannerType = `${this.state.ticketError.toLowerCase()}-${this.state.ticketErrorType.toLowerCase()}`;
        let desktopImage = `${ basePath }/ticketerror/banner-${bannerType}.png`;
        let mobileImage =  `${ basePath }/ticketerror/banner-${bannerType}-sm.png`;
        return (
        <div className="fancenter-banner">
            <picture>
                <source srcSet={ desktopImage } media= "(min-width: 1024px)" />
                <source srcSet={ mobileImage } media= "(min-width: 481px)" />
                <source srcSet={ mobileImage } media= "(min-width: 200px)" />
                <img className="fancenter-banner__image"src={ mobileImage } alt= "Milka FanCenter" />
            </picture>
        </div>
    );
    }
}

const mapStateToProps = state => ({
    bannerState: state.banner
});

const mapDispatchToProps = {
	setIsBannerShown
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ErrorTickets));