import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import flatpickr from "flatpickr";
import { withRouter } from 'react-router-dom';
import appGoogleTracking from "../../../modules/googletracking";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import Loader from "../../../components/Overlays/Loader";
import GLOBAL_CONFIG from "../../../config/config";
import Salutations from "../../../Models/Salutation";
import { mainLoaderToggle, ServerDate } from "../../../modules/helpers";
import ValidateForm from "../../../modules/validate";
import JerseyNavigation from "../ChooseJersey/JerseyNavigation";
import JerseyStepIndicitor from "../ChooseJersey/StepIndicator";
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial';
import Checkbox from "../../../components/Controls/Checkbox";
import _GIFT from "../../../Models/GiftTypes";
import _LOCAL from "../../../Models/LocalStorageKeys";
import FanCenterServices from "../../../Service/FanCenter";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import _STATUS from "../../../Models/ParticipationSteps";
import _ERROR_MESSAGES from "../../../Models/ErrorMessages";
import _GIFT_MESSAGES from "../../../Models/GiftErrorMessages";
import Aux from "../../../hoc/Auxiliare";
import FanCenterSCBanner from "../Banner/FanCenterSCBanner";
import CardValidation from "../../../components/Content/CardValidation/CardValidation";
import CardTableau, { onChangeCheckboxBtn } from "../../CardTableau/CardTableau";
import MatchCard from "../../../components/Content/MatchCard/MatchCard";
import _TICKET_TYPES from "../../../Models/TicketTypes";
import Button from "../../../components/Content/Button/Button";

class UserDetails extends React.Component {
    constructor(props) {
        super(props);

        var limit = new Date();
        limit.setFullYear(limit.getFullYear() - 18);
        this.maxDOB = limit;

        this.state = {
            ConvidInfo: false,
            SimpleLoader: false,
            ErrorMessage: "",
            GiftType: _GIFT.Ticket,
            IsRemainingTicket : false,
            IsUserDataAlreadyReady : false,
            TicketChoice : "",
            ProfileUser : null,
            Email : null,
            MatchSelected: null,
            TypeTicket: ""
        }

        this.StepList = [
            {
                display: "lieblingsclub",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "ticketauswahl",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "daten-check",
                isInProgress: true,
                isComplete: false,
            }
        ];

        var limit = new Date();
        limit.setFullYear(limit.getFullYear() - 18);
        this.maxDOB = limit;
        this.ProcessError = this.ProcessError.bind(this);
        this.ProcessForm = this.ProcessForm.bind(this);  
        this.useDetailsForm = this.useDetailsForm.bind(this);  
        this.Order = this.Order.bind(this);
    }

    getProfilUser(profileList){
        const user = {};
            profileList.map((item) => {
                user[item.name] = item.value;
            });
        return user;
    }

    TicketButtonTag = (dataLayerOption, dataLayerOptionVip) => {
        if(this.state.TypeTicket === _TICKET_TYPES.CAT2) {
            appGoogleTracking.dataLayerPush(dataLayerOption)
            console.log(dataLayerOption);
        }
        else if (this.state.TypeTicket === _TICKET_TYPES.VIP) {
            appGoogleTracking.dataLayerPush(dataLayerOptionVip)
            console.log(dataLayerOptionVip);
        }
    }

    componentDidMount() {
        _SCROLL_PAGE.ScrollToTop();
        let UserDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));
        let SelectedMatch = JSON.parse(localStorage.getItem(_LOCAL.TicketSelected));
        console.log(UserDetails);
        console.log(SelectedMatch);
        if(UserDetails){
            this.setState({
                IsRemainingTicket : UserDetails.IsRemainingTicket,
                TicketChoice : UserDetails.TicketChoice,
                Email : UserDetails.Email, 
            });
            if(UserDetails.ProfileUser){
                this.setState({
                    ProfileUser : UserDetails.ProfileUser,
                    IsUserDataAlreadyReady : true
                });
            }
        } else {
            UserDetails = {};
        }

        if(!!SelectedMatch){
            this.setState({
                MatchSelected: SelectedMatch
            }, () => {
                console.log('MATCH SELECTED',this.state.MatchSelected);
            });
        }else{
            SelectedMatch = {};
        }

        UserDetails.Type = _GIFT.Ticket;

        if(!!UserDetails && UserDetails.Type.toLowerCase() === _GIFT.Ticket) {
            this.StepList = [
                {
                    display: "lieblingsclub",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "gewinnauswahl",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "daten-check",
                    isInProgress: true,
                    isComplete: false,
                }
            ];
        } else {
            this.StepList = [
                {
                    display: "lieblingsclub",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "gewinnauswahl",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "daten-check",
                    isInProgress: true,
                    isComplete: false,
                }
            ];
        }

        mainLoaderToggle('hide');
    }

    componentWillMount() {
        this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType) || _TICKET_TYPES.CAT2});
        if(localStorage.getItem(_LOCAL.UserDetails) === null) {
            // this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user)
        }
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <Aux>
                <FanCenterSCBanner category="-daten-check" />
                <div className="buzzer-jersey ticket-user-details">
                    {this.HeadingSection()}
                    
                    <form id="frm_user_details_ticket"
                        ref = { form => this._DETAILS_TICKET = form }
                        name="frm_user_details_ticket" 
                        method="post" 
                        action="/"
                        onSubmit={this.SubmitHandler.bind(this)} >
                  
                    { this.UserDetailsSection() }

                    <ReCAPTCHA
                        ref={ref => this._CAPTCHA = ref}
                        hl={GLOBAL_CONFIG.Captcha.language}
                        size="invisible"
                        sitekey={GLOBAL_CONFIG.Captcha.publish}
                        onChange={this.OnCaptchaResolved.bind(this)}
                        onExpired={this.OnCaptchaExpired.bind(this)}
                        onErrored={this.OnCaptchaError.bind(this)}
                    />

                    </form>

                    <div id="Covid-Details">
                        {
                            this.state.CovidInfo ? 
                            <div className="back--red">
                                <p>
                                    Auf Grund der aktuellen COVID-19 Situation erhalten wir die Tickets sehr kurzfristig vor dem Spieltag von den Clubs.
                                    <br />
                                    Das Milka Team hat keinen Einfluss auf die Ticketausstellung, das wird seitens der einzelnen Clubs geregelt. Es ist uns darum nicht möglich, nähere Angaben zum genauen Versandzeitpunkt zu machen.
                                    Bitte berücksichtige auch, dass einige Clubs insbesondere bei steigenden Inzidenzzahlen überlegen, den Zugang zum Stadion nur vollständig Geimpften oder genesenen Zuschauern zu gestatten. Diese Entscheidung und Verantwortung obliegt ausschließlich den jeweiligen Clubs.
                                    Wir bitten um deine Geduld und dein Verständnis. Bitte informiere dich vor dem Stadionbesuch bei den entsprechenden Stellen.
                                </p>
                            </div> : null
                        }
                    </div>


                    {
                        this.state.SimpleLoader ? <Loader /> : null
                    }

                    <Footersocial />
                </div>
            </Aux>
        );
    }

    HeadingSection() {
        return (
            <div>
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        {
                            this.state.GiftType === _GIFT.Jersey ?
                            <h2 className="lilac">An diese Adresse schicken wir dein<br /> original Bundesliga Heimtrikot</h2>
                            : null
                        }
                        {
                            this.state.GiftType === _GIFT.SignedJersey ?
                            <h2 className="lilac">An diese Adresse schicken wir dein<br /> handsigniertes original Bundesliga Heimtrikot</h2>
                            : null
                        }
                        <JerseyStepIndicitor Steps={this.StepList} /> 
                        
                        <h2 className="gobold-bold paragraph-heading">
                            an diese e-mail-adresse schicken wir<br/>die { this.state.TypeTicket === _TICKET_TYPES.VIP ? "vip-tickets" : "tickets"} deiner wahl.
                        </h2> 
                        <p className="lilac paragraph  good-headline-pro">
                            Nicht vergessen: Lies dir bitte vorher unsere Datenschutzhinweise aufmerksam durch, damit du weißt, wie wir mit deinen personenbezogenen Daten umgehen.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    useDetailsTable(){
        return this.state.IsUserDataAlreadyReady && (
             
            <div className={`user-details-table`}>
               
                <div className="user-details-table__content">
                    <div className="my-info">
                        <div className="my-info__content">
                            <h4 className="my-info__title">
                                Meine Informationen
                            </h4>
                            <p>
                                {this.state.ProfileUser["Email"]}
                            </p>
                            <p>
                                {this.state.ProfileUser["MobilePrivate"]} {this.state.ProfileUser["MobilePrivate"] && this.state.ProfileUser["PhonePrivate"] && ("/")} {this.state.ProfileUser["PhonePrivate"]}
                            </p>
                        </div>
                    </div>
                    <div className="shipping-address">
                        <div className="shipping-address__content">
                            <h4 className="shipping-address__title">
                                Lieferadresse
                            </h4>
                            <p>
                            {this.state.ProfileUser["Firstname"]} {this.state.ProfileUser["Lastname"]}
                            </p>
                            <p>
                                {this.state.ProfileUser["Street1"]} {this.state.ProfileUser["Streetnumber"]}
                            </p>
                            <p>
                                {this.state.ProfileUser["Zipcode"]} {this.state.ProfileUser["City"]}
                            </p>
                            <p>
                                Deutschland
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    useDetailsForm(){
        return !this.state.IsUserDataAlreadyReady && (
            <Aux>
            <div className={`form-container ${ this.state.IsUserDataAlreadyReady ? "noneImportant" : ""}`}  id="companionUserDetails">
                    <div id="salutation" className="input-container">
                        <div className="form-input__container form-input__dropdown">
                            <select ref={ select => this._DETAILS_salutation = select} className="form-input__input js-event-type-in js-event-ab" 
                                type="text" name="salutation" 
                                id="salutation" 
                                placeholder="Arende*" 
                                data-require="true" 
                                data-type="ddl" 
                                data-error-target="#error-salutation" 
                                data-required-message="" 
                                data-event-fieldname="Salutation" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Salutation" 
                                data-label="Type In">
                                {
                                    Object.keys(Salutations.dropdown).map( key => {
                                        var i = 0;
                                        return <option value={ key } key={ `${i++}-${key}`} >{ Salutations.dropdown[key] }</option>
                                    })
                                }
                            </select>
                            <label className="form-input__label" htmlFor="salutation">{ Salutations.dropdown["default"] }</label>
                        </div>
                    </div>

                    <div id="firstName" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="firstname">Vorname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="firstname" 
                                id="firstname" 
                                placeholder="Vorname*" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-firstname" 
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$" 
                                data-required-message="" 
                                data-pattern-message="" 
                                data-event-fieldname="Firstname" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Firstname" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_firstName = input }
                                />
                        </div>
                    </div>

                    <div id="lastName" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="lastname">Nachname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="lastname" 
                                id="lastname" 
                                placeholder="Nachname*" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-lastname" 
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$" 
                                data-required-message="" 
                                data-pattern-message="" 
                                data-event-fieldname="Lastname" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Lastname" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_lastName = input } 
                                />
                        </div>
                    </div>

                    <div id="street" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="street_name">Straße*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="street_name" 
                                id="street_name" 
                                placeholder="Straße*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-street-number" 
                                data-required-message="" 
                                data-event-fieldname="Street number" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Street number" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_street_name = input } 
                                />
                        </div>
                    </div>

                    <div id="houseNumber" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="street_number">Hausnummer*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="street_number" 
                                id="street_number" 
                                placeholder="Hausnummer*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-house-number" 
                                data-required-message="" 
                                data-event-fieldname="House number" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="House number" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_street_number = input } 
                                />
                        </div>
                    </div>

                    <div id="address-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="adress">Adresszusatz</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="address" 
                                id="address" 
                                placeholder="Adressinformation (optional)" 
                                data-require="false" 
                                data-event-fieldname="Address" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Address" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_address = input }
                                />
                        </div>
                    </div>

                    <div id="zipCode" className="input-container">
                        <div className="form-input__container space-right">
                            <label className="form-input__label" htmlFor="zip_code">PLZ*</label>
                            <input className="form-input__input js-event-type-in js-event-ab js-zip-code" 
                                type="text" 
                                name="zip_code" 
                                id="zip_code" 
                                placeholder="PLZ*" 
                                maxLength="5" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-zip-code" 
                                data-regex-pattern="^[0-9]{5}$" 
                                data-required-message="" 
                                data-pattern-message="" 
                                data-event-fieldname="Zip code" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Zip code" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_zipCode = input }
                                />
                        </div>
                    </div>

                    <div id="city-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="city">Stadt*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="city" 
                                id="city" 
                                placeholder="Stadt*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-city" 
                                data-required-message="" 
                                data-event-fieldname="City" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="City" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_city = input }
                                />
                        </div>
                    </div>

                    <div id="telephone-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="city">Telefonnummer*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="tel" 
                                name="tel" 
                                id="tel" 
                                placeholder="Telefonnummer*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-tel" 
                                data-required-message="" 
                                data-event-fieldname="Telephone" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Telephone" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_tel = input }
                                />
                        </div>
                    </div>

                    <div id="email-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="Email">Nachname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                ref = { input => this._DETAILS_email = input } 
                                type="email"
                                name="Email" 
                                id="Email" 
                                value={this.state.Email}
                                readOnly={ this.state.Email !=null ? true : false }
                                placeholder="E-Mail*" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-emailname" 
                                data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$" 
                                data-required-message="" 
                                data-pattern-message="" 
                                data-event-fieldname="Email" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Email" 
                                data-label="Type In"
                                />
                        </div>
                    </div>
                </div>
            <div className={`${ this.state.IsUserDataAlreadyReady ? "resize" : ""}`}>
                <Checkbox 
                    id="terms"
                    required={true}
                    errorMessage={_ERROR_MESSAGES.Terms}
                    text='Ich habe die <a class="js-event-cta" href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" rel="noopener noreferrer">Teilnahmebedingungen </a> & <a class="js-event-cta" href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank" rel="noopener noreferrer">Datenschutzbestimmungen</a> gelesen und akzeptiert.*'
                    onChangeHandler={this.CheckboxChangeHandler.bind(this)}
                />
            </div>
            {
                !!this.state && this.state.ErrorMessage.length > 0 ?
                <p className="buzzer-error">{this.state.ErrorMessage}</p>
                : null
            }

            </Aux>
        );
    }

    shownSalutation(salutation){
        let result;
        if(salutation === 1) result = "Herr"
        else if(salutation === 2) result = "Frau"
        else if(salutation === 10) result = "Divers"
        else result = "Herr/Frau"
        return result;
    }
    
    UserDetailsSection() {
        return(
            <div className="buzzer-form">
                  
                <div className="details">
                  
                    <CardTableau 
                      anrede={this.shownSalutation(JSON.parse(localStorage.getItem(_LOCAL.UserDetails))?.salutation)} 
                      vorname={JSON.parse(localStorage.getItem(_LOCAL.UserDetails))?.firstname}
                      nachname={JSON.parse(localStorage.getItem(_LOCAL.UserDetails))?.name}
                      email={JSON.parse(localStorage.getItem(_LOCAL.UserDetails))?.email}
                      checkboxId="tickets-details-checkbox"
                      texteParagraphe={ this.state.TypeTicket === _TICKET_TYPES.VIP ? "Diese Daten benötigen wir um dir deine VIP-Tickets zuschicken zu können." : "Diese Daten benötigen wir um dir deine Tickets zuschicken zu können."}
                    />
                     
                    
                    <div className="matchcard__container">
                        <div className="titre-matchcard">Deine Spielauswahl</div>
                        <MatchCard whiteBg={true} match={JSON.parse(localStorage.getItem(_LOCAL.TicketSelected))} cta={false} 
                        withText="Aktuell ist das genaue Datum deines ausgewählten Spiels nicht bekannt. Bitte informiere dich rechtzeitig bei deinem Lieblingsverein."
                        />
                    </div>
                </div>
                <div className="card__validation display-desktop"><CardValidation buttonText="Jetzt Gewinn einlösen" onClick={()=>{this.Order();/* this.TicketButtonTag({
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "redeem_prize",
                        dataLabel: "redeem_prize_standard"}
                        ,{
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "redeem_prize",
                        dataLabel: "redeem_prize_vip"}) */}} /></div>
                <div className="card__validation display-mobile"><CardValidation buttonText="Gewinn einlösen" onClick={()=>{this.Order();/* this.TicketButtonTag({
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "redeem_prize",
                        dataLabel: "redeem_prize_standard"}
                        ,{
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "redeem_prize",
                        dataLabel: "redeem_prize_vip"}) */}} /></div>
            </div>
        );
    }

    

    checkTheCheckbox = () => {
        const checkboxElement = document.getElementById("tickets-details-checkbox");
        return onChangeCheckboxBtn(null, checkboxElement);
    }

    checkAllInputs = () => {
        // Add the inputs checker here 
        return this.checkTheCheckbox();
    };

    Order = () => {
      if(!this.checkAllInputs()){
        return;
      }

      let userDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));
      let uid = userDetails?.external_guid; 
      let MatchSelected = JSON.parse(localStorage.getItem(_LOCAL.TicketSelected));
      let _data = {uid: uid, itemID: MatchSelected.ID.toString(), privacyPolicy: this.checkAllInputs()}

       FanCenterServices.Order(_data)
        .then((response) => {
            let {message, details} = response.data;

            if(message === "OK"){
                localStorage.removeItem(_LOCAL.FavoriteClub);
                localStorage.removeItem(_LOCAL.TicketSelected);
                this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.confirmation);
                this.TicketButtonTag({
                    dataEvent: "custom_event_click",
                    dataCategory: "click_action",
                    dataAction: "redeem_prize",
                    dataLabel: "redeem_prize_standard"}
                    ,{
                    dataEvent: "custom_event_click",
                    dataCategory: "click_action",
                    dataAction: "redeem_prize",
                    dataLabel: "redeem_prize_vip"})
            }
            else{
                console.log('Error on place order: ', message);
            }
        })
        .catch(() => {
            console.log('Error on placeholder');
        }).finally(() => {
            console.log('Completed');
            this.setState({
                SimpleLoader: false
            })
        })

    }

    OnCaptchaResolved (token) {
        this.setState({
            SimpleLoader: true
        })
        this.ProcessForm(token)
    }

    ProcessForm(CaptchaToken) {
        let giftCategory = localStorage.getItem(_LOCAL.GiftCategory),
            userDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));

        if(!!userDetails) {
            let _data = {};
            if(this.state.IsUserDataAlreadyReady){
                _data = {
                    captcha: CaptchaToken,
                    Email: this.state.ProfileUser["Email"],
                    City: this.state.ProfileUser["City"],
                    Firstname:  this.state.ProfileUser["Firstname"],
                    Salutation: this.state.ProfileUser["Salutation"],
                    Lastname: this.state.ProfileUser["Lastname"],
                    Street1: this.state.ProfileUser["Street1"],
                    Street2: this.state.ProfileUser["Street2"],
                    Streetnumber: this.state.ProfileUser["Streetnumber"],
                    Zipcode: this.state.ProfileUser["Zipcode"],
                    CodeEntered: userDetails.Code || "default-code",
                    MatchUp: `${userDetails.Match.home} − ${userDetails.Match.away}`,
                    TicketType: giftCategory,
                    ConsumerId: userDetails.Id || "default-id",
                    MatchId: userDetails.Match.id,
                    GiftType: userDetails.Type || "default-type",
                    GiftCategory: giftCategory,
                    GameDate: userDetails.Match.date,
                    //Birthday: ServerDate(this._DETAILS_dob.value),
                    Gameweek : userDetails.GameWeek,
                    NumberOfTickets : this.state.TicketChoice
                }
            } else {
                let salutation = this._DETAILS_salutation.value;
                switch (salutation) {
                    case "Herr":
                        salutation = "1";
                        break;
                    case "Frau":
                        salutation = "2";
                        break;
                    case "Divers":
                        salutation = "10";
                        break;
                
                    default:
                        break;
                }
                _data = {
                    captcha: CaptchaToken,
                    Email: this._DETAILS_email.value,
                    City: this._DETAILS_city.value,
                    Firstname:  this._DETAILS_firstName.value,
                    Salutation: salutation,
                    Lastname: this._DETAILS_lastName.value,
                    Street1: this._DETAILS_street_name.value,
                    Street2: this._DETAILS_address.value,
                    Streetnumber: this._DETAILS_street_number.value,
                    Zipcode: this._DETAILS_zipCode.value,
                    CodeEntered: userDetails.Code || "default-code",
                    MatchUp: `${userDetails.Match.home} − ${userDetails.Match.away}`,
                    TicketType: giftCategory,
                    ConsumerId: userDetails.Id || "default-id",
                    MatchId: userDetails.Match.id,
                    GiftType: userDetails.Type || "default-type",
                    GiftCategory: giftCategory,
                    GameDate: userDetails.Match.date,
                    //Birthday: ServerDate(this._DETAILS_dob.value),
                    Gameweek : userDetails.GameWeek,
                    Telephone: this._DETAILS_tel.value,
                    NumberOfTickets : this.state.TicketChoice
                }
            }
            const { IsRemainingTicket } = this.state;
            const userDetailsAPI = IsRemainingTicket ? FanCenterServices.UserDetails.UserTicket : FanCenterServices.UserDetails.UserTicketSC ;
            
            userDetailsAPI(_data).then( response => {
                var {success, message, data} = response.data;
    
                if(success) {
                    this.props.UpdateFancenterStatus(_STATUS.Fancenter.detailsInserted)
                    switch (userDetails.Type.toLowerCase()) {
                        case _GIFT.Ticket:
                            /**
                             * Check if user or companion
                             */
                            var _data_new = {
                                ...JSON.parse(localStorage.getItem(_LOCAL.UserDetails)),
                                Name: `${_data.Firstname} ${_data.Lastname}`
                            }
                            localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(_data_new))
    
                            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.confirmation);
                            break;
                            
                        default:
                            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user);
                            break;
                    }
                } else {
                    this.ProcessError(message);
                }
            })
            .catch( () => {
                if(!!this._CAPTCHA) {
                    this._CAPTCHA.reset()
                }
            })
            .finally( () => {
                if(!!this._CAPTCHA) {
                    this._CAPTCHA.reset();
                }
    
                if(!!this._DETAILS_TICKET) {
                    this._DETAILS_TICKET.reset();
                }
    
                this.setState({
                    SimpleLoader: false
                });
            })
        } else {
            this.setState({
                SimpleLoader: false
            });
        }
    }

    ProcessError(ErrorMessage) {
        if(!!ErrorMessage) {
            switch (ErrorMessage) {
                case _GIFT_MESSAGES.OUT_OF_STOCK:
                    this.setState({
                        ErrorMessage: _ERROR_MESSAGES.Ticket.Unvailable
                    })
                    break;
            
                default:
                    this.setState({
                        ErrorMessage
                    })
                    break;
            }

        } else {
            this.setState({
                ErrorMessage: "SERVER ERROR"
            })
        }
    }

    OnCaptchaExpired () {
        this._CAPTCHA.reset();
        this.setState({
            SimpleLoader: false,
            ErrorMessage: "CAPTCHA ERROR"
        })
    }

    OnCaptchaError () {
        this._CAPTCHA.reset();
        this.setState({
            SimpleLoader: false,
            ErrorMessage: "CAPTCHA ERROR"
        })
    }

    FlatpikerDateInit( _REF, {max}, _def = null ) {
        flatpickr(_REF, {
            dateFormat: "d.m.Y",
            maxDate: max,
            disableMobile: true,
            defaultDate: _def
        });
    }

    CheckboxChangeHandler(event, _ref) {
        if(_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if(_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    SubmitHandler(event) {
        event.preventDefault();
        // if(this.state.IsUserDataAlreadyReady){
        //     const form = document.getElementById("frm_user_details_ticket");
        //     const termsInput = form.querySelector("#terms");
        //     const isValid = ValidateForm.validateProcess(termsInput);
        //     if(isValid){
        //         this._CAPTCHA.execute();
        //     }
        // } else {
        //     var isValid = ValidateForm.validateForm('frm_user_details_ticket');
            
        //     if(isValid ) {
        //         this._CAPTCHA.execute();
        //     } else {
        //         /**
        //          * Display Error
        //          */
        //     }
        // }       
    }

    ScrollCovidInfo(e) {
        this.setState({
            CovidInfo: true
        })

        _SCROLL_PAGE.ScrollSection(document.getElementById("Covid-Details"))
    } 
}

export default withRouter(UserDetails);