import React from 'react'
import '../Affiche/Affiche.scss'
import Carousel from '../Carousel/Carousel'
import appGoogleTracking from '../../../../../modules/googletracking'
export default function Affiche(props) {


    return (
        <div className='heimtrikot'>
            <div className='container_Affiche'>
            <div className='container_cardAffiche' >
                {/* <div className='cardImage'>
                    <picture className='srcimage'>
                        <source srcSet="resources\imagesBundesliga\reihe\affiche1.svg" media="(min-width: 768px)" />
                        <img className="product-code-instruction" src='resources\imagesBundesliga\reihe\affiche1_sm.png' media="(max-width: 767px)" alt="product instruction" />
                    </picture>
                </div> */}
                {/* <div className='cardText'>
                    <h3>VIP SCHON VOR DEM ANPFIFF</h3>
                    <p>Spüre den Windzug bei jedem Pass. Hör das tiefe Durchatmen der Spieler vor jedem Freistoß. Sei auf Augenhöhe mit den Topstars der Liga: All das erlebst du plus 10 Freunde bei einem unvergesslichen VIP Fußballerlebnis. Sichere dir jetzt deine Gewinnchance und freu dich auf:<br></br>
                        + An- und Abreise für dich & 10 Freunde<br></br>
                        + Front-Row-Seats bei einem Spiel des FC Bayern München oder Borussia Dortmund<br></br>
                        + Verpflegung + eine Übernachtung im Hotel<br></br>
                        (Werte variieren je nach Club, Auswahl nach Verfügbarkeit) </p>
                </div> */}
            </div>
            <div className='container_cardAffiche' >
                {/* <div className='cardImage' style={{marginTop: '-40px'}}>
                    <picture className='srcimage'>
                        <source srcSet="resources\imagesBundesliga\reihe\affiche3.png" media="(min-width: 768px)" />
                        <img className="product-code-instruction" src='resources\imagesBundesliga\reihe\affiche3_sm.png' media="(max-width: 767px)" alt="product instruction" />
                    </picture>
                </div> */}
                <Carousel />
                <div className='cardText1'>
                    <h3>DER STOFF FÜR DEINE GÄNSEHAUT</h3>
                    <p>Zeige, für welchen Verein dein Fußballherz schlägt. Mit den Heimtrikots der neuen
                        Saison aller Teams der Bundesliga und 2. Bundesliga der Saison 2024/2025.  <span className='para'>(Werte variieren je nach Club, Auswahl nach Verfügbarkeit über Milka Fan-Center unter 
                            {" "}
                            <a href='https://www.fcmilka.de'
                            target='_blank'
                            style={{textDecoration : "underline"}}
                            onClick={appGoogleTracking.processEventCTA}
                            data-event = "go_to_milka"
                            data-category="Start Page"
                            data-action="Go to Page"
                            data-label="MILKA_SP_OTHER"
                            >www.fcmilka.de</a>
                        )</span></p>
                </div>
            </div>
        </div>
        </div>
        
    )
}
