import React, { Component } from "react";
import { mainLoaderToggle, timeStamp } from "../../modules/helpers";
import Footersocial from "../../components/Layout/FooterContent/FooterSocial";
import CountDownBanner from "../../components/Content/CountDownBanner/CountDownBanner";
import parse from "html-react-parser";

class Product extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    const _CAMPAING = window.PROMOTION;
    console.log("_CAMPAING", _CAMPAING);
    return (
      <>
        {_CAMPAING === "main" && <CountDownBanner/>}
        <div className="content-holding__heading closing product-wrapper">
          <div className="content-end__text">
            <h2>
              Sie machen dein Triple perfekt
            </h2>
            <p>Zarte Vielfalt für deinen Geschmack: Ob Milka Tafelschokolade, Riegel, Pralinen oder vieles mehr – bei Milka findest du bestimmt das Richtige. TUC – einfach Cracker. Der kultige Knabberspaß bietet Abwechslung für jede Gelegenheit. Mit Milchschokolade umhüllt schmeckt dieser Riegel genauso wie er heißt: wunderbar.</p>
          </div>
          <div className="s-content-end">
            <div className="end-element">
              <div className="end-element__item">
                <img src="/resources/imagesBundesliga/90_min/product/milka-schokolade.png" />
                <div className="end-element__copy">
                  <h3>Nervennahrung nötig?</h3>
                  <p>
                  Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                  </p>
                  <a href="https://www.milka.de/shop" target="_blank">
                    <button className="btn__container btn--secondary-lilac">
                    mehr von MILKA
                    </button>
                  </a>
                </div>
              </div>

              <div className="end-element__item">
                <img src="/resources/imagesBundesliga/90_min/product/tuc-box.png" />
                <div className="end-element__copy">
                  <h3>Entdecke unsere TUC Produkte</h3>
                  <p>Hol dir unsere leckeren TUC Produkte mit knackigen Keksen und zarter Milka Schokolade.</p>
                  <a href="https://www.milka.de/shop" target="_blank">
                    <button className="btn__container btn--secondary-lilac">
                    mehr von TUC
                    </button>
                  </a>
                </div>
              </div>

              <div className="end-element__item">
                <img src="/resources/imagesBundesliga/90_min/product/wunderbar-box.png" />
                <div className="end-element__copy">
                  <h3>Wunderbar ist wunderbar</h3>
                  <p>
                  Du kennst unsere Wunderbar Produkte noch nicht? Jetzt probieren!
                  </p>
                  <a href="https://www.milka.de/neues" target="_blank">
                    <button className="btn__container btn--secondary-lilac">
                    mehr von wunderbar
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Footersocial />
        </div>
      </>
    );
  }
}

export default Product;
