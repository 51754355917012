import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { mainLoaderToggle } from "../../../modules/helpers";
import ParticipationService from "../../../Service/Participation";
import Loader from "../../../components/Overlays/Loader";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import {withRouter} from 'react-router-dom';
import Aux from "../../../hoc/Auxiliare";

class BuzzerGame extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile : false,
            isWinner : false,
            isAnimationEnd : false,
            isPressed : false
        }

        this.resize = this.resize.bind(this);
        this.reloadBuzzerVideo = this.reloadBuzzerVideo.bind(this);
        this.playGame = this.playGame.bind(this);
        this.headingSection = this.headingSection.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getSubTitle = this.getSubTitle.bind(this);
        this.getParagraphe = this.getParagraphe.bind(this);
    }

    componentDidMount() {
        const _LOCAL = {
            status: "__STAT",
            statusFan: "__STAT_FAN",
            data: "__DAT"
        }
        mainLoaderToggle('hide');
        window.addEventListener("resize", ()=> {
            this.resize();
        });
        this.resize();
        if(localStorage.getItem(_LOCAL.data) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.home);
        }else{
            const data = JSON.parse(localStorage.getItem(_LOCAL.data));
            this.setState({
                isWinner : data.Winner
            });
        }
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 1023 });
        this.reloadBuzzerVideo();
    }

    reloadBuzzerVideo () {
        const video = document.getElementById("buzzer-video");
        if(video){
            video.pause();
            video.load();
        }
    }

    playGame() {
        if(this.state.isPressed){
            return;
        }
        this.setState({
            isPressed : true
        });

        var video = document.getElementById("buzzer-video");
        video.play();
        setTimeout(()=>{
            this.setState({
                isAnimationEnd : true
            });
        }, 3000);
    }

    headingSection () {
        const path = "/resources/imagesBundesliga/buzzer_22/video/";
        const videos = {
            winner:{
                desktop : path + "winner_main.mp4",
                mobile : path + "winner_main-sm.mp4"
            },
            loser : {
                desktop : path + "loser_main.mp4",
                mobile : path + "loser_main-sm.mp4"
            }
        }
        let videoSrc = this.state.isWinner ? videos.winner.desktop : videos.loser.desktop;
        if(this.state.isMobile){
            videoSrc = this.state.isWinner ? videos.winner.mobile : videos.loser.mobile;
        }
    
        return (
            <Aux>
                <div className={`content-home endpage-rewe content-game ${this.state.isAnimationEnd && !this.state.isWinner ? "loser" : null}`}>
                    <video playsinline id="buzzer-video" onClick={()=>{
                        this.playGame();
                    }}>
                        <source src={ videoSrc } type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                    <div className="content-game__copy" onClick={()=>{
                        this.playGame();
                    }}>
                        <div className="content-game__text">
                            {this.getTitle()}
                            {this.getSubTitle()}
                            {this.getParagraphe()}
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }

    getTitle (){
        if(this.state.isAnimationEnd && this.state.isWinner){
            return (
                <h1>
                    Herzlichen Glückwunsch!
                </h1>
            );
        }else if(this.state.isAnimationEnd && !this.state.isWinner){
            return (
                <h1>
                    Oh, so ein Pech, leider hast du keine Meisterschale gefunden, diesmal hast du leider nichts gewonnen.
                </h1>
            );
        }
    
        return (
            <h1>
                FINDE DIE MEISTERSCHALE!
            </h1>
        );
    }

    getSubTitle () {
        if(this.state.isAnimationEnd && this.state.isWinner){
            return (
                <h2>Du hast die Meisterschale gefunden.</h2>
            );
        }else if(this.state.isAnimationEnd && !this.state.isWinner){
            return (
                <h2>Aber sei nicht traurig, du kannst dein Glück nochmal versuchen!</h2>
            );
        }
    
        return (
            <h2>
                Unter dem Kreis steckt die Auflösung.
            </h2>
        );
    }

    getParagraphe () {
        if(this.state.isAnimationEnd && this.state.isWinner){
            return (
                <p>
                    Du bist ein Glücksgewinner! Damit sicherst du dir eine Dauerkarte für die Saison 2022/23 für die Bundesliga / 2. Bundesliga. Bitte gib uns 48 Stunden Zeit, deinen Kaufbeleg zu überprüfen. Wenn alles seine Richtigkeit hat, benachrichtigen wir dich per E-Mail mit allen Infos zu deinem Gewinn.
                </p>
            );
        }else if(this.state.isAnimationEnd && !this.state.isWinner){
            return (
                <p>
                    Wir drücken dir die Daumen, dass es beim nächsten Mal klappt. Im Kampagnenzeitraum kannst du mit unterschiedlichen Kassenbons mehrfach teilnehmen. <strong>Darum versuche dein Glück nochmal mit einem neuen Kassenbon!</strong><br/>
                    Und nicht vergessen: Du hast automatisch die zusätzliche Chance am Ende der Aktion 25.000€ in Gold zu gewinnen! Wir wünschen dir viel Glück!
                </p>
            );
        }
        return (
            <p>
                Versuche jetzt dein Glück. Finde die Meisterschale und sichere dir eine Dauerkarte für die Saison 2022/23 der Bundesliga / 2. Bundesliga.
                <br/>  
                <br/>
                Viel Erfolg wünscht Dein FC Milka Team
            </p>
        );
    }
    
    
    render(){
        return (
            <div id="game-buzzer">
                { this.headingSection() }
                { this.state.isAnimationEnd && (
                    <div className="s-content-end">
                        <div className="end-element">
                            <div className="end-element__item">
                                <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                                <div className="end-element__copy">
                                        <h3>FC Milka Mitglied werden</h3>
                                        <p>Jetzt kostenlos registrieren und viele Vorteile genießen. Sei dabei und melde dich an.</p>
                                    <a href="https://www.fcmilka.de/account" target="_blank">
                                        <button className="btn__container btn--secondary-lilac"> REGISTRIEREN </button>
                                    </a>
                                </div>
                            </div>


                            <div className="end-element__item">
                                <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                                <div className="end-element__copy">
                                        <h3>Nervennahrung nötig?</h3>
                                        <p>
                                            Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                                        </p>
                                    <a href="https://www.fcmilka.de/account" target="_blank">
                                        <button className="btn__container btn--secondary-lilac"> SCHOKI SHOPPEN </button>
                                    </a>
                                </div>
                            </div>


                            <div className="end-element__item">
                                <img src="/resources/imagesBundesliga/buzzer/end/box-3.png" />
                                <div className="end-element__copy">
                                        <h3>Neues von Milka</h3>
                                        <p>
                                            Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                        </p>
                                    <a href="https://www.fcmilka.de/account" target="_blank">
                                        <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Footersocial />
            </div>
        );
    }
}

export default withRouter(BuzzerGame);