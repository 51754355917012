export const _STATUS = {
    noParticipation: "NO_PARTICIPATION_RECORDER",
    participated: "PARTICIPATED_SHOULD_BUZZ",
    playedBuzzer: "JUST_BUZZED",
    playedSelfie: "JUST_SELFIE",
    Fancenter: {
        noCodeEntry: "_NO_CODE",
        codeEntered: "_NO_TEAM",
        teamSelected: "_NO_DETAILS",
        detailsInserted: "_CONFIRMATION"
    },
    MicroGlobus : {
        participated: "PARTICIPATED_GLOBUS_MICROSITE",
    }
}

export default _STATUS;