import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config'; 
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle } from '../../modules/helpers';
import TitleLogo from '../../components/Content/TitleLogo/TitleLogo';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import { servicePath, campaignSettings } from '../../modules/helpers';			//Helpers for Service path
import axios from 'axios';  								//Ajax library 
import _SCROLL_PAGE from "../../modules/ScrollPage";

class footballGame extends Component {
    constructor(props) {
		super(props);
    }
    
    componentWillMount(){
    }
    componentDidMount(){
        mainLoaderToggle('hide');
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }
    componentWillUnmount(){
        mainLoaderToggle('show');   
    }
    render() {
        const { t } = this.props;
        //Footer content options
      

        return(
            <div>
                <br/>
                <br/>
                <TitleLogo/>
                <div className="title-game">
                    <div className="s-content-title"  >
                    <h1 className="heading_dark-lilac">Lande deinen Volltreffer! </h1>
                    <p className="subheading heading_dark-lilac" id="endpage-Text">Stelle deine 11-Meter-Fertigkeiten unter Beweis! Du hast 5 Schüsse auf das Tor – wie viele versenkst du?</p>
                    <p id="endpage-Text">Du kannst das 11-Meter-Schießen beliebig oft spielen, viel Spaß!</p>
                    </div>
                </div>
                    <div className="football-game">
                    {/* <iframe width="100%" height="900"src="/gameTest/index.html"></iframe> */}
                    <iframe src="/gameTest/index.html" className="iframe_game"></iframe>
                </div>
                <Footersocial/>
            </div>
        )
    }
}
export default withTranslation()(footballGame);