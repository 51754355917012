import React from "react";
import { withCookies } from 'react-cookie';
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import { mainLoaderToggle, ServicesUrl } from "../../../../modules/helpers";
import TitleContent from "../component/TitleContent/TitleContent";
import ValidateForm from "../../../../modules/validate";
import Aux from '../../../../hoc/Auxiliare';                          //Vitual container 
import Checkbox from "../../../../components/Controls/Checkbox";
import '../style/Holding.scss';
import axios from "axios";
import appGoogleTracking from "../../../../modules/googletracking";
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";



class Holding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEmailAlredySent: false,
            simpleLoader: false,
            isMobile: false,
            checkBox: '0'
        }
        this.resize = this.resize.bind(this);
        this.SubmitHandler=this.SubmitHandler.bind(this);

    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
        mainLoaderToggle('hide');
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
        ValidateForm.validateField('frm_holding')
    }

    ProcessForm(){
        this.setState({simpleLoader:true})
        // const consumerId = localStorage.getItem('ConsumerId');
        // if (consumerId) {
        //     console.log('ConsumerId retrieved from local storage:', consumerId);
        //     return;
        // }

        let data = {
            email: this._DETAILS_email.value,
            firstname: this._DETAILS_firstname.value,
            thirdParty: this.state.checkBox
        }
        const _SERVICE_URL = ServicesUrl();
        axios({
            method: 'post',
            url: `${_SERVICE_URL}/Reihe1/NewsletterReg`,
            data: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => {

                console.log('Success:', response);
                if(response.data.success){
                    // localStorage.setItem('ConsumerId', response.data.data.ConsumerId);
                    this.setState({ isEmailAlredySent :true})
                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 'remind_me',
                        'dataCategory': 'Announcement Page ',
                        'dataAction': 'Click on Remind Me',
                        'dataLabel': 'ENG_AP_FORM'
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(()=>{
                this.setState({simpleLoader:false})
            })
    }

    SubmitHandler(event) {
        event.preventDefault();
        if (this.state.isEmailAlredySent) {
            return null;
        }
        const isValid = ValidateForm.validateForm('frm_holding');

        if (isValid) {
            
            this.ProcessForm();
        } else {
            /**
             * Scroll To first error
             */
            let firstError = this._FORM_DETAILS.querySelector(".notvalid");
            if (!!firstError) {
                const yOffset = - 110,
                    y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    CheckboxChangeHandler(event, _ref) {
        appGoogleTracking.dataLayerPush({
            'dataEvent': 'click_conditions_remind_me',
            'dataCategory': 'Announcement Page ',
            'dataAction': 'Click on Conditions',
            'dataLabel': 'ENG_AP_FORM'
        });
        if (_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if (_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
            this.setState({
                checkBox: '1'
            })
        }else{
            this.setState({
                checkBox: '0'
            })
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    render() {
        return (
            <div>
            <div className="container_holding-reihe">
                        {this.state.simpleLoader?
                            <div className="simple-loader__container active">
                                <div className="simple-loader__indicator"></div>
                            </div>
                            :null
                        }
                   
                    <TitleContent heading='Gewinne ein Stadion-Erlebnis für 11 Personen' subheading='Du willst mit deinen Freunden in der neuen Bundesliga Saison ganz vorne mit dabei sein? Der FC Milka macht’s möglich! Wir sichern dir und deinen 10 Freunden Plätze in der 1. Reihe bei einem Spiel von Borussia Dortmund oder dem FC Bayern München. Die An- und Abreise zum Spiel sowie die Übernachtung und ein attraktives Rahmenprogramm sind dabei inklusive. Außerdem warten 1.000 brandneue original Heimtrikots der Bundesliga/ 2. Bundesliga auf dich. Lass dir das nicht entgehen! '></TitleContent>
                <form id="frm_holding"
                    name="frm_holding"
                    method="post" action="/"
                    ref={form => this._FORM_DETAILS = form}
                    onSubmit={this.SubmitHandler.bind(this)} noValidate>
                    <div className={`remindMe-container ${this.state.isEmailAlredySent ? "sent" : ""}`}>
                        <div className="form__container">
                            <p className="paragraphe">
                                Du möchtest den Start des neuen FC Milka Gewinnspiels auf keinen Fall verpassen? Wir erinnern dich rechtzeitig per E-Mail!
                            </p>

                            <div id="email-container" className="input-container taille">
                                <div className="form-input__container">
                                    <label className="form-input__label" htmlFor="email">Vorname*</label>
                                    <input className="form-input__input js-event-type-in js-event-ab"
                                        type="text"
                                        name="vorname"
                                        id="vorname"
                                        placeholder="Vorname"
                                        data-require="true"
                                        data-type="text"
                                        data-error-target="#error-vorname"
                                        data-regex-pattern=""
                                        data-required-message="Bitte gib deinen Vornamen ein."
                                        data-pattern-message="Bitte gib deinen Vornamen ein."
                                        data-event-fieldname="Email"
                                        data-event="form-click"
                                        data-category="Form Action"
                                        data-action="Email"
                                        data-label="Type In"
                                        ref={input => this._DETAILS_firstname = input}
                                    />
                                    <span id="error-vorname" className="form-input__error active"></span>
                                </div>
                            </div>
                            <div id="email-container" className="input-container taille">
                                <div className="form-input__container">
                                    <label className="form-input__label" htmlFor="email">Email*</label>
                                    <input className="form-input__input js-event-type-in js-event-ab"
                                        type="text"
                                        name="email"
                                        id="email"
                                        placeholder="E-Mail-Adresse eingeben"
                                        data-require="true"
                                        data-type="regex"
                                        data-error-target="#error-email"
                                        data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                        data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                        data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                        data-event-fieldname="Email"
                                        data-event="form-click"
                                        data-category="Form Action"
                                        data-action="Email"
                                        data-label="Type In"
                                        ref={input => this._DETAILS_email = input}
                                    />
                                    <span id="error-email" className="form-input__error active"></span>
                                </div>
                            </div>
                        </div>

                        <div className="img__container">
                            <picture>
                                <source srcSet="/resources/imagesBundesliga/reihe/FCMilka-Logo.png" media="(min-width: 1024px)" />
                                <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 481px)" />
                                <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 200px)" />
                                <img src="/resources/imagesBundesliga/reihe/FCMilka-logo.png" alt="FC Milka Logo" />
                            </picture>
                        </div>
                        {
                            !this.state.isEmailAlredySent ?
                                (
                                    <Aux>
                                        <button type="submit" className="btn__container btn--lilac normal" disabled={this.state.isEmailAlredySent}> ERINNERE MICH </button>
                                        <Checkbox id="terms"
                                            required={true}
                                            errorMessage={""}
                                            text='Ich möchte per E-Mail den regelmäßigen Newsletter mit Informationen zu Milka Produkten, Rezepten und Aktionen von der Mondelez Deutschland Services GmbH & Co. KG oder von verbundenen Unternehmen der Mondelez International Unternehmensgruppe erhalten. In diesem Zusammenhang wird auch mein Kaufund Klickverhalten auf dieser Milka Website analysiert. Diese Einwilligung kann ich jederzeit mit zukünftiger Wirkung hier widerrufen. Bitte beachte auch unsere Datenschutzerklärung für weitere Informationen.'
                                            onChangeHandler={!this.state.isEmailAlredySent ? this.CheckboxChangeHandler.bind(this) : () => { }}
                                        />
                                    </Aux>
                                )
                                :
                                (
                                    <p className="email-sent email-buzzer23">Deine Mail Adresse wurde erfolgreich gespeichert. Wir erinnern dich, sobald die FC Milka Aktion beginnt.</p>
                                )
                        }
                    </div>
                </form>
            </div>
            <Footersocial />
            </div>
        );
    }

    
}

export default withCookies(Holding);