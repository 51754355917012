import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';

import StepContent from "../../../components/Content/StepContent/StepContent";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Q4PopUp from "./Q4PopUp";
import { isWeltmeisterPromotion } from '../../../modules/helpers';
import _LOCAL from "../../../Models/LocalStorageKeys";
class Q4Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            modal:false,
            baseNamePath: null,
        }
        this.resize = this.resize.bind(this);
        this.isWeltmeisterPromotion = isWeltmeisterPromotion();
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };
    
    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({isMobile: window.innerWidth <= 768});
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        window.addEventListener("resize", this.resize);
        this.resize();
        //_SCROLL_PAGE.ScrollToTop();
        this.productCodeLink();
        let baseNamePath = "";
        if(this.isWeltmeisterPromotion) {
            baseNamePath = "/weltmeister";
        } else {
            baseNamePath = "";
        }

        this.setState({
            baseNamePath : baseNamePath
        });
    }

    componentWillMount(){
        //_SCROLL_PAGE.ScrollToTop();
/*         localStorage.removeItem(_LOCAL.PlayerValue);
        localStorage.removeItem(_LOCAL.ProductCode); */
        localStorage.clear();

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const kipGlobus = urlParams.get('kip')
        if(kipGlobus) {
            localStorage.setItem(_LOCAL.CurrentKip, "Globus");
        }
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div id="main-buzzer" className="weltmeister-main">
                {this.headingSection( this.props )}
                {this.footerSection()}
                <Footersocial />
            </div>
        );
    }

    productCodeLink() {
        const product_code_link = document.getElementById("product-code-link");
        product_code_link.addEventListener('click', () => {
            this.setState({modal:true});
        }); 
    }

    headingSection(props) {
        return (
            <div>
               <div className="content-home">
                    <div className="spaceTile-intropage">
                        <div className="campaign-heading">
                        
                            <h1>Gewinne ein Treffen mit deinem Weltmeister: Lukas Podolski oder Rudi Völler? Du entscheidest!</h1>
                        </div>
                        <div id="participate"></div>
                        <StepContent
                                step1 = { props.t('Page.Home.Steps.Buzzer-Main-Weltmeister.1') }
                                step1sm = { props.t('Page.Home.Steps.Buzzer-Main-Weltmeister.1') }
                                step1Text = {parse("Kaufe zwischen dem <span class=\"text--wheat\">03.10.2022</span> und dem <span class=\"text--wheat\">18.12.2022</span> ein Aktionsprodukt von Milka oder OREO.")}

                                step2 = { props.t('Page.Home.Steps.Buzzer-Main-Weltmeister.2') }
                                step2sm = { props.t('Page.Home.Steps.Buzzer-Main-Weltmeister.2') }
                                step2Text = {parse(`Gib <a id="product-code-link" style="text-decoration:undeline;">hier</a> bis zum 31.12.2022 den <span class="text--wheat"> Produktcode </span>von der Produktverpackung ein*`)}

                                step3 = { props.t('Page.Home.Steps.Buzzer-Main-Weltmeister.3') }
                                step3sm = { props.t('Page.Home.Steps.Buzzer-Main-Weltmeister.3') }
                                step3Text = {parse("Fülle das Teilnahmeformular aus und gewinne mit etwas Glück ein <span class=\"text--wheat\">Treffen mit deinem Weltmeister</span>.")}

                                step4 = { props.t('Page.Home.Steps.Buzzer-Main-Weltmeister.4') }
                                step4sm = { props.t('Page.Home.Steps.Buzzer-Main-Weltmeister.4') }
                                step4Text = {parse("Die Gewinnermittlung erfolgt unter allen Teilnehmern per Zufallsprinzip nach Ende des Aktionszeitraums am 03.01.2023.")}

                                modifier = "step-weltmeister"
                        />

                        <p className="note-promo-code">*(Hinweis: Befindet sich kein Produktcode auf dem Artikel, müssen die Zahlen des Barcodes eingegeben werden. Mehrfachteilnahmen mit neuem Produktcode innerhalb der Aktionsprodukte erlaubt.) </p>

                        <div className="cta-container">
                            <button className="btn__container btn--secondary-lilac" onClick={() => {
                                this.setState({modal:true});
                            }}>JETZT MITMACHEN </button>
                        </div>
                        <Q4PopUp modal={this.state.modal} toggleModal= {() => {this.toggleModal()}} />

{/*                         {
                            this.isWeltmeisterPromotion ? <p id="edeka-terms">Hier findest du die <a href={`${this.state.baseNamePath}${GLOBAL_CONFIG.Route.terms}`} target="_blank" style={{color: "white"}}> Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" style={{color: "white"}}> Datenschutzbestimmungen</a>.</p>
                            
                            : */}
                            
                            <p id="edeka-terms">Hier findest du die <a href={GLOBAL_CONFIG.Route.terms} target="_blank" style={{color: "white"}}> Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" style={{color: "white"}}> Datenschutzbestimmungen</a>.</p> 

                        
                    </div>
                </div>
            </div>
        );
    }

    footerSection() {
        return(
            <div className="weltmeister campaign-footer">
                <div className="card-heading">
                    <h1>Weltmeister Gewinne mit FC Milka warten auf dich</h1>
                </div>
                <div className="weltmeister-card">
                    <div className="weltmeister-card__content primary">
                        <img src="/resources/imagesBundesliga/weltmeister/card/Lukas.png" alt="Gold im Wert"/>
                        <h3>Verbringe einen Tag mit Lukas Podolski in Köln</h3>
                        <p>Erlebe Lukas Podolski in der Stadt Köln! Du verbringst ein Erlebnis-Wochenende für je
2 Personen (Freitag bis Sonntag, inkl. An- und Abreise nach weiterer individueller
Absprache mit dem Gewinner, 2x Übernachtung im 5* Hotel, Verpflegung, attraktivem
Rahmenprogramm). Lukas Podolski wird einen Tag am Erlebnis-Wochenende
teilnehmen. Als echtes Highlight zeigt dir Lukas Podolski an einem Tag seine ganz
persönlichen Ecken in Köln.</p>
                    </div>
                    <div className="weltmeister-card__content secondary">
                        <img src="/resources/imagesBundesliga/weltmeister/card/Rudi.png" alt="Dauerkarten der Saison"/>
                        <h3>Verbringe einen Tag mit Rudi Völler in Rom</h3>
                        <p>Du verbringst einen unvergesslichen Tag mit Rudi Völler, der dir seine schönsten
Plätze der ewigen Stadt Rom zeigt! Dich erwartet ein Erlebniswochenende für je 2
Personen (Freitag bis Sonntag, inkl. An- und Abreise nach weiterer individueller
Absprache mit dem Gewinner, 2x Übernachtung im 5* Hotel, Verpflegung, attraktivem
Rahmenprogramm).</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Q4Main);