import React from "react";
import { mainLoaderToggle } from "../../../../modules/helpers";
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import KIPServices from "../../../../Service/KIP_Pages";
import GLOBAL_CONFIG from "../../../../config/config";
import _STATUS_CAMPAIGN from "../../../../Models/CampaignStatus";
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import Aux from "../../../../hoc/Auxiliare";

class SupercupConfimrationGlobus extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div className="kaufland micro-globus micro-globus--confirmation">
                {this.headingSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <Aux>
                <div className="micro-globus__content-title">
                    <div className="micro-globus__title-container">
                        <h1>
                            Vielen Dank für deine Teilnahme.
                        </h1>
                        <p>
                            Du hast an der Milka Verlosung teilgenommen und nun die Chance, <strong>1 von 12 Familienerlebnissen beim Supercup</strong> zu gewinnen.
                            <br/>
                            Dich erwartet ein ganz besondere Familienzeit mit bis zu 5 Personen beim Supercup.
                            Inklusive Tickets, Rahmenprogramm, Übernachtung und Verpflegung. Dein Kind kann
                            als Einlauf- oder Trophäenkind die Bundesliga Stars hautnah erleben.
                        </p>
                    </div>
                    <picture>
                        <source srcSet="/resources/imagesBundesliga/micro-globus/confirmation-background-sm.png" media="(max-width: 1023px)" />
                        <img src="/resources/imagesBundesliga/micro-globus/confirmation-background.png" alt="Gold im Wert"/>
                    </picture>
                </div>

                <div className="s-content-end">
                    <div className="end-element">
                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                            <div className="end-element__copy">
                                    <h3>FC Milka Mitglied werden</h3>
                                    <p>Jetzt kostenlos registrieren und viele Vorteile genießen. Sei dabei und melde dich an.</p>
                                <a href="https://www.fcmilka.de/account" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> REGISTRIEREN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                            <div className="end-element__copy">
                                    <h3>Nervennahrung nötig?</h3>
                                    <p>
                                        Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                                    </p>
                                <a href="https://www.milka.de/shop" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> SCHOKI SHOPPEN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-3.png" />
                            <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                        Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                    </p>
                                <a href=" https://www.milka.de/neues" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footersocial /> */}
            </Aux>
        );
    }
}

export default SupercupConfimrationGlobus;