import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TitleContent from "../../../components/Content/TitleContent/TitleContent";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";

class GlobusTerms extends Component {
    componentDidMount() {
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle("hide");

        //Virtual page
        appGoogleTracking.dataLayerPush({
            customEvent: {
                event: "virtual-page",
                virtualUri: GLOBAL_CONFIG.Route.GlobusTerms,
                pageTitle: t("Meta.title"),
            },
        });
    }

    componentWillUnmount() {
        //Show mainloader
        mainLoaderToggle("show");
    }

    displayFullVersion() {
        const { t } = this.props;
        return (
            <>
                <div className="container medium-copy terms fancenter-terms">
                    <TitleContent heading="Teilnahmebedingungen" />
                    <div className="s-content-copy">
                        <div className="s-content-copy__item">
                            <p className="headings">Impressum</p>
                            <p>
                                Mondelez Deutschland GmbH <br />
                                Konsul Smidt Str. 21 <br />
                                28217 Bremen
                            </p>
                            <p>
                                Telefon: 00800 83 00 00 36 (kostenfrei) <br />
                                Fax: 0421 3770 8100 <br />
                                E-Mail: Verbraucherservice@mdlz.com
                            </p>
                            <p>
                                Sitz: Bremen, AG Bremen HRB 11373 <br />
                                Umsatzsteueridentifikationsnummer: DE 114397121 <br />
                                Geschäftsführer: Maximilian Eckardt, Martin Kaufmann, Niko Warmbrunn, Hartmut Wilkens
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings">
                                I. Teilnahmebedingungen der Gewinnspielaktion „Du & der Weltmeister“
                            </p>
                            <p>
                                Eine Teilnahme an dem Gewinnspiel <strong>„Du & der Weltmeister“</strong> ist ausschließlich nach Maßgabe der nachfolgend aufgeführten Teilnahmebedingungen möglich. Mit der Teilnahme erkennt der Teilnehmer diese Teilnahmebedingungen ausdrücklich an. Bei einem Verstoß gegen diese Teilnahmebedingungen behält sich Mondelez Deutschland das Recht vor, Personen vom Gewinnspiel auszuschließen und/ oder Gewinne abzuerkennen.
                            </p>
                            <p>
                            Mit der Durchführung und Abwicklung des Gewinnspiels wurde die Baudek & Schierhorn GmbH, Banksstraße 4, 20097 Hamburg (Agentur) und die D&K Brand Activation, Gasstraße 2c, 22761 Hamburg (Agentur) beauftragt.
                            </p>
                            <p>
                            Aus Gründen der besseren Lesbarkeit verwenden wir nachstehend einheitlich die Bezeichnung Teilnehmer oder Gewinner. Es sind hiervon selbstverständlich alle Geschlechter (m/w/d) umfasst. 
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">1. Veranstalter</p>
                            <p>Veranstalterin des Milka Gewinnspiels <strong>„Du & der Weltmeister“ ist die</strong></p>
                            <p>
                                Mondelez Deutschland GmbH <br />
                                Konsul Smidt Str. 21 <br />
                                28217 Bremen
                            </p>
                            <p>
                            (im Folgenden „Mondelez“ oder „Veranstalter“ genannt). Weitere Informationen zu dem Veranstalter entnehmen Sie bitte den Impressums-Angaben oben.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">2. Teilnahmeberechtigung</p>
                            <p>
                            Die Teilnehmer an dieser Werbeaktion müssen natürliche Personen sein, einen Wohnsitz in Deutschland haben und mindestens 18 Jahre alt sein. Mitarbeiter von Mondelez, der mit Mondelez verbundenen Unternehmen und Mitarbeiter von in dieses Gewinnspiel involvierten Unternehmen sowie deren unmittelbare Familienangehörige sind von der Teilnahme ausgeschlossen. 
                            </p>
                            <p>Pro gekauftem Produkt / Produktcode ist nur eine Teilnahme erlaubt. </p>
                            <p>
                            Maximal 3 Teilnahmen pro Person/Tag erlaubt. Mehrfachteilnahmen mit neuen Productcodes von weiteren gekauften Milka und / oder OREO Produkten sind erlaubt, jedoch wird pro Teilnehmer nur ein Gewinn (1 Wochenende – Freitag bis Sonntag - mit Lukas Podolski in Köln oder 1 Wochenende – Freitag bis Sonntag – mit Rudi Völler in Rom) vergeben, sowie nur ein Gewinn pro Familie. Bitte den Original-Kaufbeleg zum Nachweis aufbewahren. Der Veranstalter behält sich eine Überprüfung des Kaufbeleges nach seinem Ermessen vor. Nicht teilnehmende Produkte auf dem Kassenbeleg unkenntlich machen.
                            </p>
                            <p>
                            Um die o.g Teilnahmebegrenzung sicherzustellen, werden Teilnahmen derselben Person und Adresse mit einer anderen als der bei der ersten Teilnahme angegebenen E-Mail-Adresse nicht berücksichtigt. Eine weitere Teilnahme derselben natürlichen Person unter einer neuen/anderen E-Mail-Adresse als die bei der ersten Teilnahme angegebene E-Mail-Adresse wird als Verstoß gegen diese Teilnahmebedingungen angesehen. Sollte dem Teilnehmer aufgrund einer weiteren Teilnahme bereits ein/mehrere Preise zuerkannt oder bereits übergeben worden sein, kann der Veranstalter den Preis/die Preise aberkennen und auf Kosten der Teilnehmer zurückverlangen.
                            </p>
                            <p>
                            Alle Einsendungen müssen direkt von der Person vorgenommen werden, die sich an der Werbeaktion beteiligt. Masseneinsendungen durch gewerbliche Absender, Verbrauchergruppen oder Dritte werden nicht akzeptiert. Unvollständige oder unleserliche Einsendungen, Einsendungen durch oder über Dritte oder Syndikate, Einsendungen durch Makros oder andere automatisierte Methoden (einschließlich programmierbare Teilnahme-Systeme) sowie Einsendungen, die den Vorgaben dieser Teilnahmebedingungen nicht vollumfänglich entsprechen, werden von der Teilnahme ausgeschlossen und nicht berücksichtigt. Stellt sich heraus, dass ein Teilnehmer einen oder mehrere Computer zur Umgehung dieser Bestimmungen nutzt, beispielsweise durch den Einsatz von „Skripten“, die „Brute-Force“-Methode, die Verschleierung seiner Identität durch Manipulation von IP-Adressen, willkürliches Erraten von Codes oder sonstige automatisierte Mittel, die die Anzahl seiner Einsendungen für diese Werbeaktion auf eine Weise erhöhen sollen, die nicht mit dem Geist dieser Werbeaktion im Einklang steht, werden die Einsendungen dieser Person von der Teilnahme ausgeschlossen und jeder eventuelle Gewinn wird für nichtig erklärt und zurückgefordert. Dasselbe gilt für Beiträge, die den Teilnahmevorgang manipulieren und/oder sonst in unfairer und/oder unlauterer Weise versuchen, den Teilnahmevorgang zu beeinflussen
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">3. Aufgabe & Durchführung; Gewinne</p>
                            <p className="headings sub">3.1 Aufgabe & Durchführung</p>
                            <p>
                            Die Teilnahme erfolgt durch den Kauf von 1 Produkt von Milka und/oder OREO im Zeitraum vom 03.10.2022 bis 18.12.2022 sowie Eingabe des Produktcodes (auf der Produktverpackung) auf <a href="https://www.weltmeister.fcmilka.de" target="_blank">www.weltmeister.fcmilka.de</a> bis zum 31.12.2022 und Angabe des Händlers, Angabe der Aktionsmarke und des Kaufdatums auf <a href="https://www.weltmeister.fcmilka.de" target="_blank">www.weltmeister.fcmilka.de</a>.
                            </p>
                            <p>
                            Nach Eingabe seiner Daten und des gültigen Productcodes erhält der Teilnehmer die Möglichkeit auszuwählen, mit 
wem (Rudi Völler oder Lukas Podolski) er gerne einen Tag verbringen würde. Durch Eingabe der Daten und Auswahl einer der beiden Personen, nimmt der Teilnehmer an dem Gewinnspiel teil und erhält eine Bestätigungs-E-Mail hierzu.

                            </p>
                            <p>
                            Die Gewinnermittlung erfolgt unter allen Teilnehmern per Zufallsprinzip nach Ende des Aktionszeitraums am 03.01.2023. Die Gewinner werden im Anschluss innerhalb von 48 Stunden per E-Mail informiert.
                            </p>
                            <p>Ein Internetzugang, eine Online-Registrierung , und eine  gültige E-Mail-Adresse sind erforderlich, um an dem Gewinnspiel teilzunehmen.</p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">3.2 Gewinne</p>
                            <p>Insgesamt gibt es folgende Gewinne:</p>
                            <p>
                            2x 1 Erlebnis-Wochenende für je 2 Personen mit Lukas Podolski in Köln oder Rudi Völler in Rom (Freitag bis Sonntag, inkl. Abreise An- und Abreise nach weiterer individueller Absprache mit dem Gewinner, 2x Übernachtung 5 Sterne Hotel, Verpflegung, attraktivem Rahmenprogramm). Lukas Podolski oder Rudi Völler werden jeweils einen Tag am jeweiligen Erlebnis-Wochenende teilnehmen . Termine können nur nach Verfügbarkeit der Legenden vergeben werden und werden zeitnah kommuniziert. Der Teilnehmer sollte mithin flexibel sein, damit der Gewinn eingelöst werden kann.
                            </p>
                            <p>
                            Ausschluss, Änderungen oder Einschränkungen von Gewinnen, insbesondere aus Gründen der vorherrschenden Pandemie- und Kriegssituation vorbehalten. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation, Rechtsweg ist ausgeschlossen. Nicht kombinierbar mit anderen Aktionen. 
                            </p>
                            <p>
                            Einsendungen, die als nicht mit diesen Bedingungen übereinstimmend betrachtet werden, werden verworfen. Eine Teilnahme ist in diesen Fällen ausgeschlossen.
                            </p>
                            
                            <p>
                                -	Darüber hinaus gilt auch: In dem Fall, dass ein Gewinner entweder diese Teilnahmebedingungen nicht erfüllt, 
                                <br />
                                -	seine Kontaktdaten nicht vollständig oder unrichtig angibt, <br />
                                -	den Preis wie hierin beschrieben aus irgendeinem, vom Gewinner zu vertretenden Grundzu vertretendem Grunde, nicht in Anspruch nehmen kann oder möchte, hiervon gehen wir aus, wenn der Gewinner sich auf unsere Gewinnbenachrichtigung über die Teilnahme an einem Event-Gewinn nicht innerhalb von 10 Tagen zurückmeldet und die für die Organisation erforderlichen Daten und Dokumente innerhalb dieser Frist zur Verfügung stellt,
                            </p>
                            <p>
                            verfällt der Gewinn und kann an einen anderen Teilnehmer übermittelt werden, der unter denselben Bedingungen ermittelt wurde.
                            </p>
                            <p>
                            Der Gewinn wird nicht in bar ausgezahlt und ist vom Umtausch ausgeschlossen. Der Gewinn ist nicht übertragbar. 
                            </p>
                            <p>
                            Bitte beachte: Zum Zweck der öffentlichen Berichterstattung über jeweiligen Gewinn-Events können Bild- und Bildtonaufnahmen von den Gewinnern sowie den Begleitpersonen von Mondelez, der DFL, den mit ihnen nach § 15 f. AktG verbundenen Unternehmen, den Fußballclubs/ ihren jeweiligen Verbänden und sonst befugten Dritten (z.B. Presse, Rundfunk) erstellt und veröffentlicht werden. Mehr Informationen dazu findest Du unten unter II. Datenschutzhinweis. 
                            </p>

                            <p className="headings sub">
                            3.3  Zusätzlich verlost Mondelez weitere Gewinne in Abstimmung mit seinen Handelspartnern. Je nach Handelspartner variiert die Anzahl und die Wertigkeit der Gewinne. Für teilnehmende Handelspartner gelten daher zusätzlich zu den in diesen Teilnahmebedingungen zum Gewinnspiel „Du & der Weltmeister“ und in den Unterlagen des jeweiligen Händlers ausgelobten Modalitäten zu der Promotion die folgenden Teilnahmebedingungen:
                            </p>
                            <p>
                            Die Verlosung der zusätzlichen Gewinne bei den Handelspartnern wird über eine Anzeige in einem Werbemittel der Handelspartner angekündigt. Der jeweilige Teilnahmezeitraum der Zusatzverlosung bestimmt sich nach der entsprechenden Ankündigung des jeweiligen Händlers.
                            </p>
                            <p>
                            Sofern im Teilnahmezeitraum der teilnehmenden Händler ein Produkt von Milka und/oder OREO gekauft und der Produktcode eingegeben wurde, nimmt der Teilnehmer automatisch an dem Gewinnspiel „Du & der Weltmeister“ teil.
                            </p>
                            <p>
                            Die Ermittlung der Gewinner der Zusatzverlosungen erfolgt am 03.01.2023 und unter der jeweiligen Gewinnermittlungsmechanik, die in den Teilnahmebedingungen zur Zusatzverlosung der jeweiligen Händler vorgesehen ist. 
                                <br />
                                Die Gewinnbenachrichtigung erfolgt per E-Mail an die bei der Registrierung (s. Punkt 3.1) angegebene E-Mail-Adresse innerhalb einer Woche nach der Ziehung. Maximal ein Hauptgewinn pro Person. Der Teilnehmer erklärt sich zur Gewinnbenachrichtigung von Mondelez einverstanden.
                            </p>
                            <p>
                            Alle Preise werden in der Regel innerhalb von 4 Wochen nach abschließender Gewinnbestätigung per E-Mail an den Gewinner/die Gewinnerin versandt. 
                            </p>
                            <p>
                            Im Falle des Gewinnes eines handsignierten Milka Trikots   gilt es, folgendes zu beachten: Bei den signierten Trikots handelt es sich um Unikate, die persönlich unterschrieben werden. Für die Unterschrift werden i.d.R. Stifte verwendet, die auch wasserfest sind. Allerdings ist es so, dass bei nachträglich aufgetragener Farbe auf einer Sporttextilie, wie es bei den Trikots der Fall ist, keine Garantie dafür gegeben werden kann, dass diese auch einer Wäsche standhält. Daher eignen sich die Trikots, die unterschrieben worden sind, nicht für eine Wäsche und sind soweit die Unterschriften erhalten werden sollen als Sammelstück zu behandeln und nicht als herkömmliches Sporttrikot. Der Veranstalter übernimmt keine Gewährleistung für nach der Wäsche verlorengegangene / verschwundene Unterschriften.
                            </p>
                            <p>
                            Auch die signierten Milka Bälle sowie die Strassenkicker Caps und Rucksäcke sind handsigniert   und als Sammelstück zu betrachten. Der Veranstalter übernimmt keine Gewährleistung für verlorengegangene / verschwundene Unterschriften.
                            </p>
                            <p>
                            Der Gewinner von 1x VIP-Coaching mit einer Bundesliga Legende (inkl. An- & Abreise nach weiterer individueller Absprache mit dem Gewinner, attraktivem Rahmenprogramm und Legendentreffen) wird durch die vom Veranstalter mit der Ausführung des Events beauftragten Agentur telefonisch kontaktiert und muss zunächst noch einmal die bei der Teilnahme angegebenen Daten innerhalb einer Frist von 10 Tagen schriftlich bestätigen und ein Anmeldeformular ausfüllen. Bei dem Anmeldeformular müssen die Namen der teilnehmenden Freunde des Gewinners mit eingetragen werden und jeder Teilnehmer muss dies unterschreiben. Sofern der Gewinner /die Teilnehmer dies unterlassen, können Sie den Gewinn nicht mehr in Anspruch nehmen und der Veranstalter kann den Gewinn nach eigenem Ermessen verfallen lassen oder an einen weiteren Teilnehmer, der unter denselben Bedingungen ermittelt wird, vergeben. Sofern der Gewinner die Teilnahmevoraussetzungen erfüllt, d.h. die bei der Teilnahme angegebenen Daten werden fristgemäß bestätigt, werden die Gewinner bei der Organisation der Veranstaltung durch das Agenturpersonal begleitet. 
                            </p>
                            <p>
                            Auch hier gilt: Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann ihn auch durch einen anderen, gleichwertigen Gewinn ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere aber nicht beschränkt auf die derzeitige COVID 19 Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation.
                            </p>
                            <p>
                            Es gelten im Übrigen die allgemeinen Teilnahmebedingungen des Gewinnspiels „Du & der Weltmeister“ für die Zusatzverlosungen, sowie die jeweils auf den entsprechenden Websites dargestellten Teilnahmebedingungen der Zusatzverlosungen.
                            </p>
                        </div>

                        <div className="s-content-copy__item">
                            <p className="headings sub">4. Gewährleistung, Haftung</p>
                            <p>
                            Für einen Mangel oder mehrere Mängel an einem Gewinn übernimmt Mondelez weder eine Gewährleistung noch stehen dem Gewinner im Falle eines Mangels oder mehrerer Mängel an dem Gewinn Umtausch- oder Rückgaberechte zu, es sei denn, es liegt ein Mondelez Deutschland zurechenbarer Fall von Vorsatz oder arglistiger Täuschung vor. 
                            </p>
                            <p>
                            Der Veranstalter haftet auch nicht für Produkte oder Dienstleistungen Dritter. Bei der Inanspruchnahme der Leistungen Dritter gelten deren Allgemeine Geschäftsbedingungen. 
                            </p>
                            <p>
                            Der Veranstalter haftet auch nicht im Falle einer Insolvenz des Dritten und leistet keinen Ersatz im Falle einer in der Zukunft liegenden, derzeit nicht absehbaren Eröffnung eines Insolvenzverfahrens über das Vermögen des Dritten.
                            </p>
                            <p>
                            Der Veranstalter übernimmt keine Verantwortung für verspätet eingehende, unvollständige, inkorrekt eingesandte, schadhafte oder falsch zugestellte Einsendungen, Ansprüche oder Mitteilungen, unabhängig davon, ob diese durch Fehler, Unterlassung, Veränderung, Manipulation, Löschung, Diebstahl, Zerstörung, Übertragungsunterbrechung, Kommunikationsausfall oder anderweitig bedingt wurden. 
                            </p>
                            <p>
                            Der Veranstalter haftet nicht für Schäden aufgrund von technischen Störungen, für Verzögerungen oder Unterbrechungen von Übertragungen oder für Schäden, die im Zusammenhang mit der Teilnahme an dem Gewinnspiel stehen, es sei denn der Veranstalter handelt vorsätzlich oder grob fahrlässig. Hiervon unberührt bleiben etwaige Ersatzansprüche aufgrund von Produkthaftung und der Verletzung von Leben, Körper und Gesundheit sowie von wesentlichen Vertragspflichten. Im Fall der Verletzung von wesentlichen Vertragspflichten, d.h. solcher Pflichten, die die ordnungsgemäße Durchführung des Vertrages erst ermöglichen und auf deren Erfüllung der Nutzer vertrauen darf, ist die Haftung auf den vertragstypisch vorhersehbaren Schaden begrenzt.   
                            </p>
                            <p>
                            Die Teilnehmer erkennen durch ihre Teilnahme an, dass die Beteiligung an dieser Werbeaktion auf alleiniges Risiko des Teilnehmers erfolgt. Der Veranstalter, seine Organmitglieder, Direktoren, Beschäftigten und Agenten geben, soweit rechtlich zulässig, keinerlei Garantien oder Zusicherungen über die Richtigkeit oder Vollständigkeit der Inhalte auf www.milka.com oder auf mit dieser Website verlinkten Seiten ab und übernehmen keine Haftung oder Verantwortung für:
                            </p>
                            <p>a. Fehler, Irrtümer oder Ungenauigkeiten von Inhalten;</p>
                            <p>b. Sachschäden jeglicher Art, die aus der Teilnahme an der Werbeaktion herrühren;</p>
                            <p>
                                c. Todesfälle oder Personenschäden, die sich als direkte Folge der Teilnahme an der
                                Werbeaktion ergeben, sofern diese nicht durch zumindest Fahrlässigkeit von Mondelez
                                International, seinen Organmitgliedern, Direktoren, Beschäftigten und Agenten verursacht
                                werden;
                            </p>
                            <p>d. Unterbrechungen oder Abbrüche der Übertragung an oder von www.milka.com;</p>
                            <p>
                                e. Softwarefehler, Viren usw., die an oder durch www.milka.com an einen Dritten übertragen
                                werden, und/oder
                            </p>
                            <p>
                                f. Verluste oder Schäden jeglicher Art, die sich aus der Nutzung geposteter, per E-Mail
                                versandter, übertragener oder anderweitig über www.milka.com zur Verfügung gestellter Inhalte
                                ergeben.
                            </p>
                            <p>
                                Gewinne können nicht gegen Bargeld oder sonstige Formen der Entschädigung eingelöst werden,
                                sofern nichts anderes angegeben wurde. Falls der Nutzer einen Teil eines Gewinns aus
                                irgendeinem Grund nicht innerhalb des vom Veranstalter vorgegebenen Zeitrahmens einlöst,
                                verfällt dieses Gewinnelement für den Nutzer ersatzlos, und es wird keine Geldzahlung oder
                                sonstige Form von Entschädigung als Ersatz für dieses Element geliefert, soweit nichts
                                anderes vereinbart wurde.
                            </p>
                            <br />
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">
                                5. Vorzeitige Beendigung des Gewinnspiels /Änderung der Teilnahmebedingungen
                            </p>
                            <p>
                                Mondelez behält sich vor, dieses Gewinnspiel zu jedem Zeitpunkt ohne Vorankündigung und ohne
                                Angabe von weiteren Gründen abzubrechen oder zu beenden, insbesondere bei Infizierung durch
                                Computerviren, Netzwerkausfällen, Softwarefehlern, Manipulationen, unbefugten Eingriffen,
                                Betrug, technischem Versagen oder Ursachen außerhalb der Kontrolle des Veranstalters, die die
                                Verwaltung, Sicherheit, Fairness, Integrität oder ordnungsgemäße Durchführung dieser
                                Werbeaktion stören oder beeinträchtigen.
                            </p>
                            <p>Mondelez kann diese Bedingungen jederzeit und ohne weitere Benachrichtigungen ändern. .</p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">6. Sonstiges</p>
                            <p>
                            Sollten einzelne dieser Teilnahmebedingungen ungültig sein oder werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen hiervon unberührt.
                            </p>
                            <p>
                            Diese Teilnahmebedingungen unterliegen in Deutschland einschlägigem deutschem Recht, wobei alle Streitigkeiten bezüglich der Auslegung dieser Teilnahmebedingungen der ausschließlichen Zuständigkeit der deutschen Gerichte unterliegen.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">7. Angaben zu EU Streitschlichtungsverfahren</p>
                            <p>
                                Gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG) weisen wir darauf hin, dass wir nicht
                                verpflichtet und nicht bereit sind, an einem Streitbeilegungsverfahren vor einer
                                Verbraucherschlichtungsstelle teilzunehmen.
                                <br />
                                Sie können sich aber jederzeit an unseren Verbraucherservice wenden.
                            </p>
                            <p>
                                Informationen zur Online-Streitbeilegung:
                                <br />
                                Die EU-Kommission stellt unter folgendem Link eine Internetplattform zur Online-Beilegung von
                                Streitigkeiten (sog. „OS-Plattform“) bereit, die als Anlaufstelle zur außergerichtlichen
                                Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus
                                Online-Kaufverträgen erwachsen, dient:
                                <a
                                    href="https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks"
                                    target="_blank"
                                >
                                    https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks
                                </a>
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">8. Abstandserklärung</p>
                            <p>
                            Das Gewinnspiel steht in keiner Verbindung zu Facebook und Instagram und wird in keiner Weise von Facebook gesponsert, unterstützt oder organisiert. Für keinen der im Rahmen dieser Aktion veröffentlichten Inhalte ist Facebook oder Instagram verantwortlich.
                            </p>
                            <p>
                            Der Empfänger der vom Teilnehmer bereitgestellten Informationen ist nicht Facebook oder Instagram, sondern Mondelez Deutschland. Mondelez Deutschland stellt Facebook oder Instagram von berechtigten Ansprüchen Dritter im Zusammenhang mit diesem Gewinnspiel frei. Sämtliche Fragen, Kommentare oder Beschwerden zur Aktion sind nicht an Facebook oder Instagram zu richten, sondern an Mondelez Deutschland unter verbraucherservice@mdlz.com
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings">II. Datenschutzhinweise</p>
                            <p>
                                Verantwortliche Stelle für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen dieses
                                Gewinnspiels ist die Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen
                                (Mondelez Deutschland). Mit der Durchführung des Gewinnspiels sind die folgenden Agenturen
                                beauftragt: Consultix GmbH, Wachtstr. 17-24 28195 Bremen Germany, Baudek & Schierhorn Shopper
                                Marketing GmbH, Hermann-Maul-Str. 2, 21073 Hamburg, D&K Brand Activation, Gasstraße 2c, 22761
                                Hamburg und die bürozwei GmbH & Co KG, Theodor-Heuss-Allee 21 28215 Bremen auf einem Server
                                in Deutschland. Die Consultix GmbH, Wachtstr. 17-24 28195 Bremen Germany, Baudek & Schierhorn
                                Shopper Marketing GmbH, Hermann-Maul-Str. 2, 21073 Hamburg, und D&K Brand Activation, Gasstraße
                                2c, 22761 Hamburg auf einem Server in Deutschland. Die Consultix GmbH, Wachtstr. 17-24 28195 Bremen Germany, Baudek & Schierhorn Shopper Marketing GmbH, Hermann-Maul-Str. 2, 21073 Hamburg, und D&K Brand Activation, Gasstraße 2c, 22761 Hamburg unterliegen sämtlichen anwendbaren Datenschutzgesetzen.
                            </p>
                            <p>
                                Die genannten Agenturen sind im Rahmen einer Auftragsverarbeitung für uns tätig und an die
                                Anforderungen entsprechender Verträge gebunden.
                            </p>
                            <p>
                                Eine Weitergabe Ihrer personenbezogenen Daten an in diesem Datenschutzhinweis nicht genannte
                                Dritte erfolgt nicht.
                            </p>
                            <p>
                                Wir speichern personenbezogene Daten von Ihnen nach Maßgabe der rechtlichen Vorschriften und
                                ausschließlich zum Zweck der Abwicklung dieses Gewinnspiels (Rechtsgrundlage: Art. 6 Abs. 1
                                Buchst. fb) der Datenschutz-Grundverordnung EU
                            </p>
                            <p>
                                2016/679, DS-GVO) und geben diese gegebenenfalls und sofern Landesvorschriften dies
                                erforderlich machen zur Nachverfolgung von COVID-19 Infektionen im Zusammenhang mit der
                                Ticketeinlösungen aufgrund rechtlicher Vorschriften (Rechtsgrundlage: Art. 6 Abs. 1 Buchst.
                                c) der Datenschutz-Grundverordnung EU 2016/679, DS-GVO) an den Verein weiter.
                            </p>
                            <p>
                                Es werden die E-Mailadresse, der Name, die Telefonnummer und die Adressdaten der Teilnehmer,
                                sowie das Geburtsdatum von den Gewinnern erfasst und ausschließlich zur Verifizierung der
                                Teilnahme/ Gewinner gespeichert.
                            </p>
                            <p>
                                Im Rahmen der Covid 19-Pandemie kann es von den regionalen Gesundheitsämtern zu besonderen
                                Auflagen bei der Vergabe von Tickets und beim Stadionbesuch kommen. Mondelez Deutschland wird
                                die Daten der betreffenden Gewinner (Vorname, Nachname und Adresse), sofern es rechtlich notwendig ist, an den Verein, bei dem
                                der Gewinner ein Ticket einlösen möchte, weitergeben. Die Rechtsgrundlage der Verarbeitung
                                ist Art. 6 Abs. 1 c) DSGVO i.V.m. mit der einschlägigen Corona-Schutzverordnung des
                                jeweiligen Bundeslandes. Die Datenverarbeitung durch den Verein liegt außerhalb der
                                Verantwortung von Mondelez Deutschland. Die Hoheit bei der Vergabe der Karten sowie bei der
                                Umsetzung der behördlichen Anordnungen in den Stadien liegt vielmehr bei den Vereinen. Aus
                                diesem Grund wird es personalisierte Tickets geben, bei denen zwangsläufig die Personalien
                                der Zuschauer registriert und an die Vereine bzw. die ortsansässigen Gesundheitsbehörden
                                weitergeben werden. Die Gewinner werden, soweit dies rechtlich notwendig ist, zusätzlich aufgefordert, diese zusätzlichen
                                Allgemeinen Geschäftsbedingungen, Teilnahmebedingungen und Datenschutzhinweise des jeweiligen
                                Vereins im Gewinnfall zusätzlich zu akzeptieren und der Datenweitergabe durch die Vereine an
                                die zuständigen Gesundheitsämter zum Zwecke der Nachverfolgung einer möglichen COVID-19
                                Infektion zuzustimmen.
                            </p>
                            <p>
                                Zum Zweck der öffentlichen Berichterstattung der jeweiligen
                                Veranstaltung können Bild- und Bildtonaufnahmen von den Gewinnern sowie den Begleitpersonen
                                von Mondelez, der DFL, den mit ihnen nach § 15 f. AktG verbundenen Unternehmen, verarbeitet, verwertet und veröffentlicht werden. Sofern Mondelez Bild- und Bildtonaufnahmen
                                und Fotos der Veranstaltung für die Nachberichterstattung für rein interne Zwecke sowie zu
                                Werbezwecken nutzen möchte, holt Mondelez hierzu eine gesonderte Einwilligungserklärung der
                                Teilnehmer ein. Mondelez bei den Gewinnern, ihren Begleitpersonen und/ oder den jeweiligen Erziehungsberechtigten eine entsprechende gesonderte Einwilligung (Art. 6 Abs. 1 S. 1 Buchstabe a der DSGVO) anfragen.
                            </p>
                            <p>
                            Sie können der Verarbeitung Ihrer personenbezogenen Daten jederzeit ohne Angabe von Gründen widersprechen. Hierzu genügt eine formlose E-Mail an verbraucherservice@mdlz.com . Im Falle eines Widerspruchs werden Ihre personenbezogenen Daten selbstverständlich gelöscht, und eine Teilnahme an dem Gewinnspiel ist nicht mehr möglich.  
                            </p>
                            <p>
                            Soweit keine gesetzlichen Aufbewahrungspflichten bestehen, erfolgt eine umgehende Löschung der Daten, nachdem das Gewinnspiel abgewickelt ist. Eine umgehende Löschung erfolgt bezüglich der Daten der Nichtgewinner. Gewinnerdaten sind aus gesetzlichen und buchhalterischen Gründen nach Maßgabe der §§ 257 HGB, 147 AO, 14b UStG aufzubewahren. Für diese Speicherung der Gewinnerdaten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. c) DS-GVO, da die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der Mondelez Deutschland unterliegt.   
                            </p>
                            <p>Ihre Rechte im Zusammenhang mit personenbezogenen Daten:</p>
                            <p>
                            Sie sind unter anderem berechtigt (i) zu überprüfen, ob und welche personenbezogenen Daten wir über Sie gespeichert haben und Kopien dieser Daten zu erhalten, (ii) die Berichtigung, Ergänzung oder das Löschen Ihrer personenbezogenen Daten, die falsch sind oder nicht rechtskonform verarbeitet werden, zu verlangen, (iii) von uns zu verlangen, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken, und (iv) der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen, (v) Datenübertragbarkeit zu verlangen, (vi) in Deutschland bei der deutschen (für weitere Informationen, siehe https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html Beschwerde zu erheben.
                            </p>
                            <p>Unsere Kontaktdaten:</p>
                            <p>
                            Sollten Sie zu der Verarbeitung Ihrer personenbezogenen Daten Fragen oder Anliegen haben, wenden Sie sich bitte an uns:
                            </p>
                            <p>Mondelez Deutschland GmbH, Konsul Smidt Str. 21, 28217 Bremen, Deutschland</p>
                            <p>E-Mail: Verbraucherservice@mdlz.com</p>
                            <p>
                                Unseren Datenschutzbeauftragten in Deutschland können Sie postalisch unter: Mondelez
                                Deutschland Services GmbH & Co. KG, zu Händen des Datenschutzbeauftragten Herrn Uwe
                                Struckmeier, Konsul Smidt Str. 21, 28217 Bremen, Deutschland
                            </p>
                            <p>oder per E-Mail unter: Datenschutz@mdlz.com erreichen.</p>
                            <p>
                                Bitte lesen Sie unsere im Übrigen gültigen ausführlichen Datenschutzhinweise für die
                                Datenverarbeitung im Rahmen der Nutzung dieser Webseite unter
                            </p>
                            <p>
                                DE: <br />
                                <a href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D">
                                    https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D
                                </a>
                            </p>
                            <p>
                                Die Webseiten unter www.facebook.com und www.instagram.com und die auf dieser Seite
                                vorgehaltenen Dienste werden angeboten von der Facebook Ireland Limited, 4 Grand Canal
                                Square, Dublin 2, Irland. Es kann nicht ausgeschlossen werden, dass Facebook die
                                hochgeladenen Daten selbstständig speichert und verarbeitet.
                            </p>
                            <p>
                                Wir haben keinen Einfluss auf den Umfang der Erhebung und die weitere Verwendung dieser Daten
                                durch Facebook. Informationen des Drittanbieters zum Datenschutz können Sie der nachfolgenden
                                Webseite von Facebook und Instagram entnehmen:
                            </p>
                            <p>https://www.facebook.com/about/privacy</p>
                            <p>https://help.instagram.com/519522125107875</p>
                            <p>
                                Es ist nicht ausgeschlossen, dass durch Facebook Ihre Daten auch in an die Muttergesellschaft
                                der Facebook Ltd., die Facebook Inc. mit Sitz in den USA weitergeleitet werden.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    render() {
        return this.displayFullVersion();
    }
}

export default withTranslation()(GlobusTerms);
