//Set page section for each component for Tracking of Newsletter on Component Navigation menu
export function pageSection(elementID, pageSection) {
  document.getElementById(elementID).setAttribute('data-label', pageSection)
}

//Get campaign settings - all require settings for the whole campaign goes here
export function campaignSettings(param) {
  let getLocation = window.location.host,
    setting = {}

  if (getLocation.indexOf('rewe') > -1) {
    setting.campaignStatus = 'rewe'
  } else if (getLocation.indexOf('kaufland') > -1) {
    setting.campaignStatus = 'kaufland'
  } else if (getLocation.indexOf('mueller') > -1) {
    setting.campaignStatus = 'muller'
  } else {
    setting.campaignStatus = 'kaufland' //Default - to change or erase
  }

  //GTM ID + //Captcha Public key
  if (setting.campaignStatus === 'rewe') {
    setting.gtmID = param.GTM.gtmIDRewe
    setting.siteKey = param.Captcha.siteKeyRewe
  } else if (setting.campaignStatus === 'kaufland') {
    setting.gtmID = param.GTM.gtmIDKaufland
    setting.siteKey = param.Captcha.siteKeyKaufland
  } else if (setting.campaignStatus === 'muller') {
    setting.gtmID = param.GTM.gtmIDMuller
    setting.siteKey = param.Captcha.siteKeyMuller
  }

  return setting
}

//Set all paths for webservices
/* 
    Configuration with config.js
    Path for:
        - campaign status
        - participation
*/
export function servicePath(param) {
  // let returnPath = '',
  //     campaign = (param.campaign === 'muller') ? 'mueller' : param.campaign;
  //     getLocation = 'https://'+window.location.host+'/';

  // //Campaign promotion webservice path
  // if(param.status === 'global'){
  //     returnPath = '/api/'+campaign+param.campaignURL;
  // }
  // //Participation webservice path
  // else if(param.status === 'participation'){
  //     returnPath = '/api/'+campaign+param.particationURL;
  // }
  // return returnPath;
  let returnPath = '',
    path = '',
    getLocation = 'https://' + window.location.host

  //Prod
  if (
      getLocation === param.prodURL ||
      getLocation === param.prodURL90min ||
      getLocation === param.prodURLWeltmeister ||
      getLocation === param.prodURLTickets ||
      getLocation === param.prodURLBuzzer ||
      getLocation === param.prodURLTrikot ||
      getLocation === param.prodURLDoppelt
  ) {
      path = param.prodServiceURL;
  } else if (getLocation === param.prodURLGlobus) {
    path = param.prodServiceURLGlobus;
  }

  //Hidden prod
  else if (getLocation === param.hiddenProdURL || getLocation === param.hiddenProdTrikotURL) {
    path = param.hiddenProdserviceURL
  } else if (getLocation === param.prodURLWithWWW || getLocation === param.prodURLWithWWWBuzzer || getLocation === param.prodURLWithWWWTrikot || getLocation === param.prodURLWithWWWDoppelt || getLocation === param.prodURLWithWWWTickets) {
    path = param.prodServiceURLWWW
  } else if (getLocation === param.LocalstagingURL) {
    path = param.LocalstagingServiceURL
  } else if (getLocation === param.stagingFancenterURL) {
    path = param.stagingFancenterServiceURL
  }
  //Staging
  else {
    path = param.stagingServiceURL
  }
  //Campaign promotion webservice path
  if (param.status === 'global') {
    returnPath = path + param.campaignURL
  } else if (param.status === 'global90min') {
    returnPath = path + param.campaignURL90min
  } else if (param.status === 'globalPenny') {
    returnPath = path + param.campaignURLPenny
  } else if (param.status === 'globalWeltmeister') {
    returnPath = path + param.campaignURLWeltmeister
  } else if (param.status === 'globalDoppelt') {
    returnPath = path + param.campaignURLDoppelt
  } else if (param.status === 'globalTickets') {
    returnPath = path + param.campaignURLTickets
  } else if (param.status === 'globalBuzzer') {
    returnPath = path + param.campaignURLBuzzer
  } else if (param.status === 'globalTrikot') {
    returnPath = path + param.campaignURLTrikot
  }
  //Participation webservice path
  else if (param.status === 'participation') {
    returnPath = path + param.particationURL
  } else if (param.status === 'login') {
    returnPath = path + param.loginUrl
  } else if (param.status === 'details') {
    returnPath = path + param.loginDetailUrl
  } else if (param.status === 'register') {
    returnPath = path + param.registerUrl
  } else if (param.status === 'resetPassword') {
    returnPath = path + param.resetPasswordUrl
  } else if (param.status === 'updateProfile') {
    returnPath = path + param.updateProfileUrl
  } else if (param.status === 'deleteProfile') {
    returnPath = path + param.deleteProfileUrl
  } else if (param.status === 'forgetPassword') {
    returnPath = path + param.forgetPasswordUrl
  } else if (param.status === 'triggerResetPassword') {
    returnPath = path + param.passwordChangeEmailUrl
  } else if (param.status === 'finalizeResetPassword') {
    returnPath = path + param.finalizeResetPasswordUrl
  } else if (param.status === 'reweCampaign') {
    returnPath = path + param.reweCampaignUrl
  } else if (param.status === 'KauflandCampaign') {
    returnPath = path + param.KauflandCampaignUrl
  } else if (param.status === 'validateWinningCode') {
    returnPath = path + param.validateWinningCodeUrl
  } else if (param.status === 'confirmAddress') {
    returnPath = path + param.confirmAddressUrl
  } else if (param.status === 'ticketTeam') {
    returnPath = path + param.ticketTeamUrl
  } else if (param.status === 'profileFanCenter') {
    returnPath = path + param.fanCenterProfileUrl
  } else if (param.status === 'EdekaCampaign') {
    returnPath = path + param.edekaCampaignUrl
  } else if (param.status === 'upgradeAccount') {
    returnPath = path + param.upgradeAccountUrl
  } else if (param.status === 'getSupermarketPromotion') {
    returnPath = path + param.getSupermarketPromotionUrl
  } else if (param.status === 'nettoCampaign') {
    returnPath = path + param.nettoCampaignUrl
  } else if (param.status === 'pennyCampaign') {
    returnPath = path + param.pennyCampaignUrl
  }
  // //Winner load webservice path
  // else if(param.status === 'winnerload'){
  //     returnPath = path+param.winnerLoadURL;
  //     //returnPath = 'https://jsonplaceholder.typicode.com/users';
  // }
  // //Winner select webservice path
  // else if(param.status === 'winnerselect'){
  //     returnPath = path+param.winnerSelectURL;
  //     //returnPath = 'https://jsonplaceholder.typicode.com/posts';
  // }
  return returnPath
}

export function ServicesUrl() {
  var hostName = window.location.hostname,
    isProd = hostName.toLowerCase().indexOf('fcmilka.de') > -1 ? true : false,
    isHiddenProd = hostName.toLowerCase().indexOf('proximitybbdo') > -1 ? true : false,
    isQA = hostName.toLowerCase().indexOf('qa.azurewebsites.net') > -1 ? true : false,
    isLocal = hostName.toLowerCase().indexOf('localhost') > -1 ? true : false

  if (isProd) {
    return 'https://api.fcmilka.de';
  }
  else if(isHiddenProd){
    // return 'https://proximitybbdo-fcmilka-de-api-v25ef3pzhq-ey.a.run.app';
    return 'https://api.fcmilka.de';
  }
  else if(isQA){
    return 'https://api-fcmilka-de.mdlzapps.cloud';
  } else if(isLocal){
    return 'https://api-fcmilka-de.mdlzapps.cloud';
    //eto api
    return 'https://localhost:8084/stage';
  }
  return 'https://api-fcmilka-de.mdlzapps.cloud';
}

export function is90minPromotion() {
  var hostName = window.location.hostname,
    is90min =
      hostName.toLowerCase().indexOf('90min.fcmilka') > -1 ? true : false,
    isProd = hostName.toLowerCase().indexOf('fcmilka') > -1 ? true : false

  if (!isProd) {
    var pathName = window.location.pathname
    if (pathName.toLowerCase().indexOf('/90min') > -1) {
      return true
    }
  }
  return is90min
}

export function isReihePromotion() {
  var hostName = window.location.hostname,
    pathName = window.location.pathname,
    isSommer = hostName.toLowerCase().includes("reihe1.fcmilka") || hostName.toLowerCase().includes("reihe1-fcmilka"),
    isProd = hostName.toLowerCase().includes("fcmilka");

  if (!isProd && pathName.toLowerCase().includes("/reihe")) {
    return true;
  }

  return isSommer;
}

export function isHeimtrikotPromotion() {
  var hostName = window.location.hostname,
    pathName = window.location.pathname,
    isHeimtrikot = hostName.toLowerCase().includes("heimtrikot.fcmilka") || hostName.toLowerCase().includes("heimtrikot-fcmilka"),
    isProd = hostName.toLowerCase().includes("fcmilka");

  if (!isProd && pathName.toLowerCase().includes("/heimtrikot")) {
    return true;
  }

  return isHeimtrikot;
}


export function isWeltmeisterPromotion() {
    var hostName = window.location.hostname,
        isWeltmeister = hostName.toLowerCase().indexOf("weltmeister.fcmilka") > -1 ? true : false,
        isProd = hostName.toLowerCase().indexOf("fcmilka.de") > -1 ? true : false,
        isStage = hostName.toLowerCase().indexOf("fcmilka-de.mdlzapps.cloud") > -1 ? true : false;

        if(isStage){
          var pathName = window.location.pathname;
          if(pathName.toLowerCase().indexOf("/weltmeister") > -1){
              return true;
          }
        }
        if(!isProd){
            var pathName = window.location.pathname;
            if(pathName.toLowerCase().indexOf("/weltmeister") > -1){
                return true;
            }
        }
    return isWeltmeister;
}

export function isDoppeltPromotion() {
    var hostName = window.location.hostname,
        isDoppelt = hostName.toLowerCase().indexOf("doppeltgewinnen") > -1 ? true : false,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;

        if(!isProd){
            var pathName = window.location.pathname;
            if(pathName.toLowerCase().indexOf("/doppeltgewinnen") > -1){
                return true;
            }
        }
    return isDoppelt;
}

export function isGlobusMicrosite() {
  var hostName = window.location.hostname
  return hostName.toLowerCase().indexOf('einlaufkinder') > -1 ? true : false
  //return true;
}

export function isFanCenterMicrosite() {
  var hostName = window.location.hostname
  return hostName.toLowerCase().indexOf('fancenter') > -1 ? true : false
  //return true;
}


export function isPennyMicrosite() {
  var pathName = window.location.pathname
    return pathName.toLowerCase().indexOf('penny-topps') > -1 ? true : false;
}

/* export function isTicketsPromotion() {
  var hostName = window.location.hostname,
      isTickets = hostName.toLowerCase().indexOf("tickets.fcmilka") > -1 ? true : false,
      isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false,
      isStage = hostName.toLowerCase().indexOf("tickets-fcmilka-de") > -1 ? true : false;

      if(!isProd && !isStage){
          var pathName = window.location.pathname;
          if(pathName.toLowerCase().indexOf("/tickets") > -1){
              return true;
          }
      }
  return isTickets;
} */

export function isTicketsPromotion() {
  var hostName = window.location.hostname,
      isTickets = hostName.toLowerCase().indexOf("tickets") > -1 ? true : false,
      isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;

      if(!isProd){
          var pathName = window.location.pathname;
          if(pathName.toLowerCase().indexOf("/tickets") > -1){
              return true;
          }
      }
  return isTickets;
}

export function isBuzzerPromotion(){
  var hostName = window.location.hostname,
        isBuzzerGame = hostName.toLowerCase().indexOf("buzzer.fcmilka") > -1 ? true : false,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;

        if(!isProd){
            var pathName = window.location.pathname;
            if(pathName.toLowerCase().indexOf("/buzzer") > -1){
                return true;
            }
        }
    return isBuzzerGame;
}

export function isTrikotPromotion() {
  var hostName = window.location.hostname,
    pathName = window.location.pathname,
    isTrikot = hostName.toLowerCase().includes("trikot.fcmilka") || hostName.toLowerCase().includes("trikot-fcmilka"),
    isProd = hostName.toLowerCase().includes("fcmilka");

  if (!isProd && pathName.toLowerCase().includes("/trikot")) {
    return true;
  }

  return isTrikot;
}

export function isq2Promotion() {
  var hostName = window.location.hostname,
    pathName = window.location.pathname,
    isSommer = hostName.toLowerCase().includes("sommer.fcmilka") || hostName.toLowerCase().includes("sommer-fcmilka"),
    isProd = hostName.toLowerCase().includes("fcmilka");

  if (!isProd && pathName.toLowerCase().includes("/sommer")) {
    return true;
  }

  return isSommer;
}

export function isTeaser(){
  var hostName = window.location.hostname,
    pathName = window.location.pathname,
    isProd = hostName.toLowerCase() === "fcmilka.de" || hostName.toLowerCase() === "fcmilka-de.mdlzapps.cloud";

  if (!isProd && pathName.toLowerCase()=="/") {
    return true;
  }

  return isProd;
}


export function isProd() {
  var hostname = window.location.hostname;
    return hostname.toLowerCase().indexOf('fcmilka') > -1 ? true : false;
}

//Main loader - to use on all containers
export function mainLoaderToggle(status) {
  let loaderSelector = document.querySelector('.js-main-loader')

  if (status === 'show') {
    loaderSelector.classList.add('active')
  } else {
    loaderSelector.classList.remove('active')
  }
}

//Navigation menu active for Mitmachen / Participation
export function navParticipationMenu(active) {
  let menuSelector = document.querySelector('.main-nav li:nth-child(2) a')

  if (active === true) {
    if (!menuSelector.classList.contains('active')) {
      menuSelector.classList.add('active')
    }
  } else {
    //Remove active menu participation - mitmachen
    menuSelector.classList.remove('active')
  }
}

//Scroll to View Navigation - mainly use when Navlink are click on the page - function call on googletracking.js
//Can be customise for future use
export function scrollToElement(status) {
  if (status === undefined) {
    let navLetter = document.querySelector('.navigation-newsletter')

    if (!!navLetter) {
      navLetter.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  if (status === 'participate') {
    document
      .querySelector('.js-scroll-participate')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
    // document.querySelector('#frm_participation').scrollIntoView({ behavior: 'smooth', block: 'start'});
  } else if (status === 'header') {
    document
      .querySelector('.banner__container')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  } else if (status === 'faq') {
    document
      .querySelector('.navigation-newsletter')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  } else if (status === 'scracth') {
    console.log('status scratch')
    document
      .querySelector('.top-toshow')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
    // window.location.hash="#scrollTop"
  }
}

//Timestamp for Tagging plan - can be customise for different format
//Return format : MM/DD/YYYY
export function timeStamp() {
  let date = new Date(),
    day = date.getDate(),
    month = date.getMonth() + 1,
    dayformat = day < 10 ? '0' + day : day,
    monthformat = month < 10 ? '0' + month : month,
    timeStamp = monthformat + '/' + dayformat + '/' + date.getFullYear()

  return timeStamp
}

export function ServerDate(date) {
  let setDate = date.split('.')
  return setDate[2] + '-' + setDate[1] + '-' + setDate[0]
}

//Function to create option list
export function optionListConstruct(listitem, remove = null) {
  let listItemLength = listitem.length,
    listStr = ''

  for (let i = 0; i < listItemLength; i++) {
    if (remove == listitem[i]) {
      continue
    }
    if (listitem[i] === '11') {
      listStr += '<option value="' + listitem[i] + '">>10</option>'
    } else {
      listStr +=
        '<option value="' + listitem[i] + '">' + listitem[i] + '</option>'
    }
  }

  return listStr
}

export function dateConfig(param) {
  let minDate = '',
    maxDate = ''

  if (param.promotion === 'rewe') {
    minDate = param.rewe.start
    maxDate = param.rewe.end
  } else if (param.promotion === 'kaufland') {
    minDate = param.kaufland.start
    maxDate = param.kaufland.end
  } else if (param.promotion === 'muller') {
    minDate = param.muller.start
    maxDate = param.muller.end
  }

  return param.status === 'min' ? minDate : maxDate
}
export function scrollParticipate() {
  let eventSelector = document.querySelector('.js-scroll-participate'),
    heightActive = 200,
    scrollY = null

  window.addEventListener('scroll', (e) => {
    e.preventDefault()

    scrollY = window.scrollY || document.documentElement.scrollTop // ! scrollY not supported by IE

    if (scrollY > heightActive) {
      //Show
      eventSelector.style.opacity = '1'
    } else {
      eventSelector.style.opacity = '0'
    }
  })
}

function GetRandomInt(max, prevInts) {
  let randomInt = Math.floor(Math.random() * max)

  while (prevInts.includes(randomInt)) {
    randomInt = Math.floor(Math.random() * max)
  }

  return randomInt
}

export function GetScore(max, previousScore = []) {
  max = max + 1
  return GetRandomInt(max, previousScore)
}

export function getCookie(name) {
  var cookieArr = document.cookie.split(";");
  for(var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    var cookieName = cookiePair[0].trim();
    if(cookieName === name) {
      return cookiePair[1];
    }
  }
  return null;
}

export default {
  pageSection,
  campaignSettings,
  servicePath,
  mainLoaderToggle,
  navParticipationMenu,
  scrollToElement,
  timeStamp,
  optionListConstruct,
  dateConfig,
  scrollParticipate,
  GetScore,
  getCookie,
}
