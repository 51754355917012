import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import Aux from '../../../../hoc/Auxiliare';
import appGoogleTracking from '../../../../modules/googletracking';
import { mainLoaderToggle  } from '../../../../modules/helpers';
import TitleContent from '../../../../components/Content/TitleContent/TitleContent';
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import { connect } from "react-redux";
import { setIsBannerShown  } from "../../../../store/banner";
import { isPennyMicrosite, isWeltmeisterPromotion } from '../../../../modules/helpers';
import FanCenterSCBanner from '../../Banner/FanCenterSCBanner';
import Footersocial from '../../../../components/Layout/FooterContent/FooterSocial';
import _LOCAL from '../../../../Models/LocalStorageKeys';
import { param } from 'jquery';
import {useLocation} from "react-router-dom";

class ErrorJersey extends Component {
    constructor() {
        super();
        this.state = {
            jerseyErrorType: localStorage.getItem(_LOCAL.ErrorTicketsType) || "late"
        };
    }

    componentDidMount(){
        const { t, setIsBannerShown } = this.props;
        setIsBannerShown(false);

        //Remove main loader
        mainLoaderToggle('hide');
    }
    
    componentWillUnmount(){
        //Show mainloader
        mainLoaderToggle('show');
        setIsBannerShown(true);
    }

    render() {
        return (
            <div >
                 {this.errorBanner()}
                <Footersocial/>
            </div>
        );
    }
    errorBanner() {
        const basePath = "/resources/imagesBundesliga/fanCenter/banner/tickets";
        let bannerType = `${this.state.jerseyErrorType.toLowerCase()}`;
        let desktopImage = `${basePath}/jerseyerror/banner-jersey-error-${bannerType}.png`
        let mobileImage = `${basePath}/jerseyerror/banner-jersey-error-${bannerType}-sm.png`
        return (
        <div className="fancenter-banner">
            <picture>
                <source srcSet={ desktopImage } media= "(min-width: 1024px)" />
                <source srcSet={ mobileImage } media= "(min-width: 481px)" />
                <source srcSet={ mobileImage } media= "(min-width: 200px)" />
                <img className="fancenter-banner__image"src={ mobileImage } alt= "Milka FanCenter" />
            </picture>
        </div>
    );
    }
}

const mapStateToProps = state => ({
    bannerState: state.banner
});

const mapDispatchToProps = {
	setIsBannerShown
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ErrorJersey));