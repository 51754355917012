import React, { Component } from 'react';
import { mainLoaderToggle } from "../../modules/helpers";
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config'; 
import Footersocial from "../../components/Layout/FooterContent/FooterSocial";


class NoParticipation extends Component {

    componentDidMount() {
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div>
                {this.headingSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <div>
                <div className="no-participation__wrapper">
                    <h2>Teilnahme nicht mehr möglich</h2>
                    <p>Deine Teilnahme ist nicht mehr möglich. Heute ist bereits der letzte Tag der Aktion.</p>   
                </div>
            </div>
        );
    }
}

export default NoParticipation;