import React from 'react'
import GLOBAL_CONFIG from '../../../config/config' //Global Settings that contains URL etc
import { isPennyMicrosite } from '../../../modules/helpers'
import HoldingPennyGondelkopf from '../../Campaign/Penny/HoldingPennyGondelkopf'
import PennyConfirmation from '../../Campaign/Penny/PennyConfirmation'
import PennyFaq from '../../Campaign/Penny/PennyFaq'
import PennyGondelkopfEnd from '../../Campaign/Penny/PennyGondelkopfEnd'
import PennyGondelkopfMain from '../../Campaign/Penny/PennyGondelkopfMain'
import PennyParticipation from '../../Campaign/Penny/PennyParticipation'
import PennyPrize from '../../Campaign/Penny/PennyPrize'
import PennyTerms from '../../Campaign/Penny/PennyTerms'

const getPennyRouting = (props) => {
  const getHoldingRoute = () => {
    if (props.CampaignStatus === 'holding') {
      return [
        {
          path: GLOBAL_CONFIG.Route.pennyMicrosite.holding,
          Component: () => <HoldingPennyGondelkopf {...props} />,
        },
      ];
    } 
    return [];
  }

  const getMainRoute = () => {
    if (props.CampaignStatus === 'main') {
      return [
        {
          path: GLOBAL_CONFIG.Route.pennyMicrosite.main,
          Component: () => <PennyGondelkopfMain {...props} />,
        },
        {
          path: GLOBAL_CONFIG.Route.pennyMicrosite.participation,
          Component: () => <PennyParticipation {...props} />,
        },
        {
          path: GLOBAL_CONFIG.Route.pennyMicrosite.confirmation,
          Component: () => <PennyConfirmation {...props} />,
        },
      ];
    } 
    return [];
  }

  const getEndRoute = () => {
    if (props.CampaignStatus === 'end') {
      return [
        {
          path: GLOBAL_CONFIG.Route.pennyMicrosite.end,
          Component: () => <PennyGondelkopfEnd {...props} />,
        },
      ];
    }
    return [];
  }

  const pagesMap = [
    {
      path: GLOBAL_CONFIG.Route.pennyMicrosite.prize,
      Component: () => <PennyPrize {...props} />,
    },
    {
      path: GLOBAL_CONFIG.Route.pennyMicrosite.faq,
      Component: () => <PennyFaq {...props} />,
    },
    {
      path: GLOBAL_CONFIG.Route.pennyMicrosite.terms,
      Component: () => <PennyTerms {...props} />,
    },
    ...getEndRoute(),
    ...getMainRoute(),
    ...getHoldingRoute(),
  ];
  return pagesMap;
}

export default getPennyRouting
