import React from "react";
import { NavLink } from "react-router-dom";
import { mainLoaderToggle } from "../../../modules/helpers";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import TicketsPromoPopUp from "../TicketsPromo/TicketsPromoPopUp";
import { isProd } from "../../../modules/helpers";

class TrikotPromoPrize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    _SCROLL_PAGE.ScrollToTop();
  }

  componentWillMount() {
    setTimeout(() => {
      _SCROLL_PAGE.ScrollToTop();
    }, 200);
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    return (
      <>
        <div id="buzzer-prizes" className="trikot-prizes">
          <div className="trikot-content-prizes">
            <div className="trikot-promo-container">
              <h3>diese Gewinne warten auf dich</h3>
              <div className="trikot-promo-container-wrapper">
                <div className="trikot-promo-card">
                  <div className="trikot-promo__content primary">
                    <h4>
                      <span class="good-headline-gold">1.500X</span>{" "}
                      original heimtrikots der Bundesliga / 2. Bundesliga,
                      Saison 2023/24
                    </h4>
                    <p>
                      So bist du für die Bundesliga Saison 2023/24 perfekt
                      gekleidet! Schon bald kann ein original Heimtrikot der
                      Bundesliga / 2. Bundesliga dir gehören. Zeige allen,
                      welcher Club dein Herz höherschlagen lässt und dir gut
                      steht. (Werte der Trikots variieren je nach Club, Auswahl
                      nach Verfügbarkeit über FC Milka Fan-Center.)
                    </p>
                  </div>
                  <div className="trikot-promo__content secondary">
                    <img
                      src="/resources/imagesBundesliga/trikot-promo/card/T-Shirt.png"
                      alt="Gold im Wert"
                    />
                  </div>
                </div>
              </div>

              <div className="trikot-promo-container-wrapper-secondary">
                <div className="trikot-promo-card primary">
                  <img
                    src="/resources/imagesBundesliga/trikot-promo/card/secondary-one.png"
                    alt="Gold im Wert"
                  />
                  <h4>
                    <span class="good-headline-gold">150x</span> Milka
                    Produktpakete
                  </h4>
                  <p>
                    Du kannst vor Spannung gerade nicht hinsehen? Die passende
                    Nervennahrung für fesselnde Fußball-Partien bekommst du mit
                    einem Milka Produktpaket. Zum Selber-Naschen oder zum Teilen
                    mit deinen Fußballfreunden.
                  </p>
                </div>

                <div className="trikot-promo-card secondary">
                  <img
                    src="/resources/imagesBundesliga/trikot-promo/card/secondary-two.png"
                    alt="Gold im Wert"
                  />
                  <h4>
                    <span class="good-headline-gold">15.000x</span> die chance
                    auf fussball-Ausstattung
                  </h4>
                  <p>
                  Bekenne Farbe zu deinem Lieblingsverein – der Fanshop von 11teamsports bietet dir die perfekte Möglichkeit, dich im Dress deines Lieblingsclubs zu kleiden. Profitiere von einem von 15.000 Rabattgutscheinen in Höhe von 20% auf alle Artikel der Bundesliga und 2.<br/>Fussball-Bundesliga.
                  </p>
                </div>
              </div>

              <div class="prizes-question-faq">              
                <h2>
                  Noch Fragen zu FC Milka?
                </h2>
                <p>
                  Hier geht es zu den <a href={!isProd() ? `/trikot${GLOBAL_CONFIG.Route.faq}` : `${GLOBAL_CONFIG.Route.faq}`}
                target="_blank" rel="noopener noreferrer">FAQ</a>
                </p>
              </div>

{/*               <div className="cta-container">
                <button
                  className="btn__container btn--secondary-lilac"
                  data-event="custom_event_click"
                  data-category="click_action"
                  data-action="participate"
                  data-label="participate_start_page"
                  onClick={(e) => {
                    this.modalTracking(e);
                  }}
                >
                  MITMACHEN
                </button>
              </div> */}
            </div>
          </div>
          <Footersocial />
        </div>
      </>
    );
  }

  modalTracking(event) {
    this.setState({ modal: true });
    appGoogleTracking.processEventCTANavLink(event);
  }
}

export default TrikotPromoPrize;
