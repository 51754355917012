import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TitleContent from "../../../components/Content/TitleContent/TitleContent";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import _SCROLL_PAGE from '../../../modules/ScrollPage'
class PennyTerms extends Component {
    componentDidMount() {
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle("hide");
        _SCROLL_PAGE.ScrollToTop()
        //Virtual page
        appGoogleTracking.dataLayerPush({
            customEvent: {
                event: "virtual-page",
                virtualUri: GLOBAL_CONFIG.Route.Q3Terms,
                pageTitle: t("Meta.title"),
            },
        });
    }

    componentWillUnmount() {
        //Show mainloader
        mainLoaderToggle("show");
    }

    displayFullVersion() {
        const { t } = this.props;
        return (
            <>
                <div className="penny-terms__container">
                    <div className="s-content-copy">
                        <div className="s-content-copy__item">
                            <p className="headings title">Teilnahmebedingungen und Datenschutzbestimmungen</p>
                            <p className="headings sub mob">I. Teilnahmebedingungen</p>
                            <p className="headings sub mob">1. Die Aktion</p>
                            <p className="headings sub">
                                Veranstalter der Aktion „Jetzt Mitmachen und Match Attax Multipack erhalten“ in Deutschland
                                ist die Mondelez Deutschland GmbH, Konsul-Smidt-Straße 21, 28217 Bremen,
                                Deutschland (nachstehend als „Mondelez Deutschland“ abgekürzt). Weitere Informationen zu
                                Mondelez Deutschland entnehmen Sie bitte dem Impressum auf unserer Webseite
                                fcmilka.de/penny-topps.
                            </p>
                            <p className="headings sub">
                                Die Aktion „Jetzt mitmachen und Match Attax Multipack erhalten“ (im Folgenden „Aktion“)
                                findet vom 05.09. – 18.09.2022 in allen Penny Märkten in Deutschland statt.
                            </p>
                            <p className="headings sub">
                                Mit der Durchführung und Abwicklung der Aktion wurde die Consultix GmbH, Wachtstr. 17-24,
                                28195 Bremen (Agentur) und die Baudek & Schierhorn GmbH, Banksstraße 4, 20097 Hamburg
                                (Agentur) beauftragt.
                            </p>
                            <p className="headings sub">
                                Die Teilnahme an der Aktion erfolgt durch Hochladen des Kaufbeleges im Zeitraum vom
                                05.09.-18.09.2022 auf der Internetseite{" "}
                                <a href="www.fcmilka.de/penny-topps">www.fcmilka.de/penny-topps</a> und unter der Angabe des
                                Namens und der E-Mail-Adresse.
                            </p>
                            <p className="headings sub">
                                Der Teilnehmer geht mit der Teilnahme an der Aktion über die Teilnahme hinaus keine
                                vertraglichen oder sonstigen rechtlichen Verpflichtungen gegenüber Mondelez Deutschland ein.
                                Die Teilnahme an der Aktion ist kostenlos, mit Ausnahme der Übermittlungskosten, die nach dem
                                von Ihnen gewählten Tarif Ihres Mobilfunk- bzw. Internetanbieters entstehen. Die Teilnahme an
                                der Aktion unterliegt diesen Teilnahmebedingungen und Datenschutzbestimmungen.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">2. Teilnahmeberechtigung</p>
                            <p>
                                Teilnahmeberechtigt sind ausschließlich natürliche Personen, die mindestens 18 Jahre alt sind
                                und ihren Wohnsitz in der Bundesrepublik Deutschland haben. Mitarbeiter von Mondelez
                                Deutschland und seinen Konzern- und Schwestergesellschaften sowie der eingebundenen
                                Vertragspartner und ihrer unmittelbaren Familienangehörigen sind von einer Teilnahme an der
                                Aktion ausgeschlossen.
                            </p>
                            <p>
                                Mondelez Deutschland behält sich das Recht vor, Teilnehmer von der Aktion auszuschließen, die
                                gegen diese Teilnahmebedingungen, das geltende Recht oder die Regeln von Sitte und Anstand
                                verstoßen. Insbesondere Teilnehmer, die versuchen, sich durch die Verwendung unerlaubter
                                Hilfsmittel einen Vorteil zu verschaffen, werden von der Teilnahme ausgeschlossen.
                            </p>
                            <p className="headings sub">
                                Alle Einsendungen müssen direkt von der Person vorgenommen werden, die sich an der
                                Werbeaktion beteiligt. Masseneinsendungen durch gewerbliche Absender, Verbrauchergruppen oder
                                Dritte werden nicht akzeptiert. Unvollständige oder unleserliche Einsendungen, Einsendungen
                                durch oder über Dritte oder Syndikate, Einsendungen durch Makros oder andere automatisierte
                                Methoden (einschließlich programmierbare Teilnahme-Systeme) sowie Einsendungen, die den
                                Vorgaben dieser Teilnahmebedingungen nicht vollumfänglich entsprechen, werden von der
                                Teilnahme ausgeschlossen und nicht berücksichtigt. Stellt sich heraus, dass ein Teilnehmer
                                einen oder mehrere Computer zur Umgehung dieser Bestimmungen nutzt, beispielsweise durch den
                                Einsatz von „Skripten“, die „Brute-Force“-Methode, die Verschleierung seiner Identität durch
                                Manipulation von IP-Adressen, willkürliches Erraten von Codes oder sonstige automatisierte
                                Mittel, die die Anzahl seiner Einsendungen für diese Werbeaktion auf eine Weise erhöhen
                                sollen, die nicht mit dem Geist dieser Werbeaktion im Einklang steht, werden die Einsendungen
                                dieser Person von der Teilnahme ausgeschlossen und jeder eventuelle Gutschein wird für
                                ungültig erklärt.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="">3. Ablauf der Aktion</p>
                            <p className="headings sub">
                                Die Aktion beginnt am 05.09.2022 und endet am 17.09.2022 („Aktionszeitraum“). Das Hochladen
                                des Kaufbelegs unter <a href="www.fcmilka.de/penny-topps">www.fcmilka.de/penny-topps</a> ist
                                bis zum 18.09.2022 möglich.
                            </p>
                            <p className="headings sub">
                                Bei einem Kauf von Produkten der Marken Milka, TUC oder Wunderbar („Aktionsprodukte“) im Wert
                                von min. 6 € in einem Kaufakt in Penny Märkten in Deutschland erhält der Teilnehmer ein Match
                                Attax Multipack (nachfolgend „Zugabe“).
                            </p>
                            <p className="headings sub">
                                Für den Erhalt des Match Attax Multipacks muss der Kaufbeleg auf www.fcmilka.de/penny-topps
                                bis 18.09.2022 (Ausschlussfrist) hochgeladen werden. Spätere Registrierungen werden nicht
                                berücksichtigt und berechtigen nicht zum Erhalt der Zugabe. Nicht teilnehmende Produkte sind
                                auf dem Kaufbeleg unkenntlich zu machen. Mondelez behält sich das Recht vor, Kaufbelege im
                                Original anzufordern und in diese Einsicht zu nehmen, alle Registrierungen und Einsendungen
                                auf die Einhaltung dieser Teilnahmebedingungen hin zu prüfen und gegebenenfalls fehlende
                                Belege anzufordern.
                            </p>
                            <p className="headings sub">
                                Für das Hochladen des Kaufbeleges ist eine Onlineregistrierung unter Angabe des Namens, der
                                Adresse und der E-Mail-Adresse unter www.fcmilka.de/penny-topps erforderlich. Wenn alle
                                Pflichtfelder, die für die Teilnahme an der Aktion erforderlich sind, ausgefüllt worden sind
                                und die Prüfung der Teilnahme erfolgreich war, erhält der Teilnehmer per E-Mail einen
                                digitalen Code für die Zugabe. Dieser Code kann bis zum 30.09.2023 auf topps.com eingelöst
                                werden. Dort ist zur Abwicklung der Versendung die Angabe der E-Mail-Adresse sowie der
                                Anschrift erforderlich. Die Zugabe wird dem Teilnehmer in der Regel innerhalb von 7 Tagen an
                                die bei der Registrierung angegebene Adresse zugesendet.
                            </p>
                            <p className="headings sub">
                                Der Teilnehmer kann beliebig oft an der Aktion teilnehmen. Jeder Teilnehmer kann allerdings
                                nur einen Code pro Kaufbeleg erhalten. Wird der digitale Code nicht fristgerecht bis zum
                                30.09.2023 unter topps.com eingelöst, so verfällt der Anspruch auf das Match Attax Multipack.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">4. Haftung</p>
                            <p className="headings sub">
                                Für Datenverluste, insbesondere im Wege der Datenübertragung, und andere technische Defekte
                                übernimmt Mondelez Deutschland keine Haftung. Mondelez Deutschland haftet nur für Schäden,
                                welche von Mondelez Deutschland oder einem ihrer Erfüllungsgehilfen oder MitarbeiterInnen
                                vorsätzlich oder grob fahrlässig oder durch die Verletzung von Kardinalpflichten
                                (vertragswesentliche Pflichten) verursacht wurde. Vertragswesentliche Pflichten sind solche,
                                deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und
                                auf deren Einhaltung der Kunde vertrauen darf. Dies gilt nicht für Schäden durch die
                                Verletzung von Leben, Körper und/oder Gesundheit. Voranstehende Haftungsbeschränkung gilt
                                insbesondere für Schäden durch Fehler, Verzögerungen oder Unterbrechungen in der Übermittlung
                                von Daten o. ä., bei Störungen der technischen Anlagen oder des Services, unrichtigen
                                Inhalten, Verlust oder Löschung von Daten, Viren.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">5. Support/Kontakt/ Hinweise zur Streitbeilegung</p>
                            <p className="headings sub">
                                Sämtliche Fragen, Kommentare oder Beschwerden zur Aktion sind an Mondelez Deutschland{" "}
                                <a href="mailto:verbraucherservice@mdlz.com">verbraucherservice@mdlz.com</a> zu richten.
                                Fernmündlich mitgeteilte oder verspätete Beschwerden können nicht bearbeitet werden.
                            </p>
                            <p>Hinweis für Teilnehmer aus Deutschland gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG)</p>
                            <p className="headings sub">
                                Wir nehmen derzeit nicht an einem Streitbeilegungsverfahren vor einer
                                Verbraucherschlichtungsstelle teil. Sie können sich aber jederzeit an unseren
                                Verbraucherservice wenden. Informationen zur Online-Streitbeilegung:
                            </p>
                            <p>
                                Hinweis für Teilnehmer aus Österreich gemäß Bundesgesetz über Alternative Streitbeilegung in
                                Verbraucherangelegenheiten (AStG)
                            </p>
                            <p className="headings sub">
                                Mondelez Österreich ist nicht verpflichtet und nicht bereit an einem
                                Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. Sie können
                                sich aber jederzeit an unseren Verbraucherservice unter 00800 83 00 00 36 (kostenfrei)
                                wenden.
                            </p>
                            <p>Informationen zur Online-Streitbeilegung:</p>
                            <p className="headings sub">
                                Die EU-Kommission stellt eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog.
                                „OS-Plattform“) bereit. Die OS-Plattform soll als Anlaufstelle zur außergerichtlichen
                                Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus
                                Online-Kaufverträgen erwachsen, dienen. Die OS-Plattform ist unter folgendem Link erreichbar{" "}
                                <a href="http://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr</a>.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">6. Rückabwicklung</p>
                            <p className="headings sub">
                                Bei endgültiger Kaufrückabwicklung innerhalb von sechs Monaten ab Kaufdatum oder wenn sich
                                nach Erhalt der Zugabe herausstellt, dass der Teilnehmer nicht zum Erhalt der Zugabe
                                berechtigt war, ist der Teilnehmer zur Rückgabe der Zugabe an Mondelez verpflichtet. Insofern
                                eine Rückgabe nicht möglich ist, ist Wertersatz zu leisten.
                            </p>
                        </div>

                        <div className="s-content-copy__item">
                            <p className="headings sub">7. Sonstiges</p>
                            <p>
                                Mondelez Deutschland behält sich das Recht vor, die Aktion jederzeit und ohne Angaben von
                                Gründen einzustellen, abzubrechen, auszusetzen oder zu verändern.
                            </p>
                            <p className="headings sub">
                                Sollten einzelne Bestimmungen dieser Teilnahmebedingungen unwirksam, unzulässig oder
                                undurchführbar sein oder werden, so lässt dies die Wirksamkeit der Teilnahmebedingungen im
                                Übrigen unberührt. An die Stelle der unwirksamen, unzulässigen oder undurchführbaren Klausel
                                treten Regelungen, deren Wirkungen der wirtschaftlichen Zielsetzung möglichst nahekommen.
                                Dies gilt jedoch nur dann, wenn das Festhalten an dem gesamten Vertrag, auch unter
                                Berücksichtigung dieser vorgesehenen Änderung, nicht eine unzumutbare Härte für eine
                                Vertragspartei darstellen würde
                            </p>
                            <p className="headings sub">
                                Etwaige Rechte aus dem Vertragsverhältnis, das diesen Teilnahmebedingungen zugrunde liegt,
                                sind für Sie nicht auf Dritte übertragbar. Mondelez Deutschland kann diese Bedingungen
                                jederzeit und ohne weitere Benachrichtigungen ändern. Mit Registrierung bzw. mit Erhalt der
                                Zugabe erklärt sich der Teilnehmer mit diesen Teilnahmebedingungen einverstanden. Es gilt das
                                Recht der Bundesrepublik Deutschland.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">8. Abstandserklärung</p>
                            <p>
                                Die Aktion steht in keiner Verbindung zu Facebook und Instagram und wird in keiner Weise von
                                Facebook gesponsert, unterstützt oder organisiert. Für keinen der im Rahmen dieser Aktion
                                veröffentlichten Inhalte ist Facebook oder Instagram verantwortlich.
                            </p>
                            <p className="headings sub">
                                Der Empfänger der vom Teilnehmer bereitgestellten Informationen ist nicht Facebook oder
                                Instagram, sondern Mondelez Deutschland. Mondelez Deutschland stellt Facebook oder Instagram
                                von berechtigten Ansprüchen Dritter im Zusammenhang mit dieser Aktion frei. Sämtliche Fragen,
                                Kommentare oder Beschwerden zur Aktion sind nicht an Facebook oder Instagram zu richten,
                                sondern an Mondelez Deutschland unter verbraucherservice@mdlz.com.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">II. Datenschutzbestimmungen für diese Aktion:</p>
                            <p className="headings sub">
                                Verantwortliche Stelle für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen dieser
                                Aktion ist die Mondelez Deutschland Services GmbH & Co. KG, Konsul-Smidt-Str. 11, D-28217
                                Bremen (Mondelez Deutschland). Mit der Durchführung der Aktion sind die folgenden Agenturen
                                beauftragt: Consultix GmbH, Wachtstr. 17-24 28195 Bremen Germany und Baudek & Schierhorn
                                GmbH, Banksstraße 4, 20097 Hamburg (Agentur) auf einem Server in Deutschland. Die Consultix
                                GmbH, Wachtstr. 17-24 28195 Bremen Germany und die Baudek & Schierhorn GmbH, Banksstraße 4,
                                20097 Hamburg (Agentur) unterliegen sämtlichen anwendbaren Datenschutzgesetzen. Beide
                                Agenturen sind im Rahmen einer Auftragsverarbeitung für uns tätig und an die Anforderungen
                                entsprechender Verträge gebunden. Eine darüberhinausgehende Weitergabe Ihrer
                                personenbezogenen Daten an Dritte erfolgt nicht.
                            </p>
                            <p>
                                Wir speichern personenbezogene Daten von Ihnen nach Maßgabe der rechtlichen Vorschriften und
                                ausschließlich zum Zweck der Abwicklung dieser Aktion (Rechtsgrundlage: Art. 6 Abs. 1 Buchst.
                                f) der Datenschutz-Grundverordnung EU 2016/679, DS-GVO). Es wird die E-Mailadresse, der Name
                                und die Adressdaten der Teilnehmer erfasst und ausschließlich zur Verifizierung der Teilnahme
                                gespeichert. Sie können Ihre Einwilligung in die Nutzung und Speicherung Ihrer
                                personenbezogenen Daten jederzeit ohne Angabe von Gründen widerrufen. Hierzu genügt eine
                                formlose E-Mail an{" "}
                                <a href="mailto:verbraucherservice@mdlz.com">verbraucherservice@mdlz.com</a> . Im Falle eines
                                Widerrufs ist eine weitere Teilnahme an der Aktion nicht mehr möglich.
                            </p>
                            <p className="headings sub">
                                Soweit keine gesetzlichen Aufbewahrungspflichten bestehen, erfolgt eine umgehende Löschung
                                der Daten, nachdem die Aktion abgewickelt ist. Eine umgehende Löschung erfolgt bezüglich der
                                Daten der Nichtgewinner. Gewinnerdaten sind aus gesetzlichen und buchhalterischen Gründen
                                nach Maßgabe der §§ 257 HGB, 147 AO, 14b UStG aufzubewahren. Für diese Speicherung der
                                Gewinnerdaten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. c) DS-GVO, da die Verarbeitung
                                zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der Mondelez Deutschland
                                unterliegt. Weitere Informationen zu dem Umgang von Mondelez Deutschland mit Ihren
                                personenbezogenen Daten und zu Ihren gesetzlichen Rechten und wie sie diese ausüben können,
                                entnehmen Sie bitte unserer Datenschutzerklärung unter milka.de.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    render() {
        return this.displayFullVersion();
    }
}

export default withTranslation()(PennyTerms);
