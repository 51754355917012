import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import { withTranslation } from "react-i18next";
import BuzzerSlider from "./Slider";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import StepContent from "../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../config/config";
import KIPServices from "../../../Service/KIP_Pages";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import ParticipationNavigation from "./ParticipationNavigation";
import GiftContentFooter from "../../../components/Content/GiftContent/GiftContentFooter";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import _LOCAL from "../../../Models/LocalStorageKeys";

class BuzzerEdeka extends React.Component {
    componentWillMount() {
        /*this.LoadCampaign();
        localStorage.setItem(_LOCAL.CurrentKip, "Edeka");*/
        this.props.history.push({
            pathname: GLOBAL_CONFIG.Route.home
        });
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-netto kaufland edeka">
                {this.headingSection(this.props)}
                {this.cardSection()}
                {this.footerSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection(props) {
        return (
            <div className="content-home endpage-rewe">
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <div className="title-container">
                            <h2>
                                Sichere dir jetzt deine doppelte Gewinnchance mit Milka <br/> bei der <img src="/resources/imagesBundesliga/buzzer/edeka/logo.png" alt="Kaufland Logo" className="tablet-up"/> Zusatzverlosung
                            </h2>
                            <h4>
                                Im Aktionszeitraum vom 11.04.2022 bis 05.06.2022 ein Produkt von Milka in deinem Edeka Markt kaufen, Kaufbeleg bis zum 05.06.2022 auf <a href="/mitmachen" ><strong>www.gold.fcmilka.de/mitmachen</strong></a> hochladen und mit etwas Glück Familienzeit der Extraklasse oder dein original Heimtrikot gewinnen.
                            </h4>
                            <h4>
                                Deine Chance auf den doppelten Gewinn: Mit der Teilnahme an der Edeka Zusatzverlosung nimmst du automatisch am Gewinnspiel „Jetzt Meister werden!” teil und hast darüber hinaus die Chance auf zusätzliche Gewinne. 
                            </h4>
                        </div>
                        <div className="step-container">
                            <h3 className="step-title">
                                Mitmachen ist ganz einfach:
                            </h3>

                            <StepContent
                                step1 = { props.t('Page.Home.Steps.Buzzer-Main-Edeka.1') }
                                step1sm = { props.t('Page.Home.Steps.Buzzer-Main-Edeka.1') }
                                step1Text = {parse("Kaufe zwischen dem <span class=\"text--wheat\">11.04.2022</span> und dem <span class=\"text--wheat\">05.06.2022</span> ein Milka Aktionsprodukt. <strong>(Bitte den gut lesbaren Kaufbeleg aufbewahren.)</strong>")}
                                step_arrow = "/resources/imagesBundesliga/Edeka/icons/arrow_right.png"
                                step2 = { props.t('Page.Home.Steps.Buzzer-Main-Edeka.2') }
                                step2sm = { props.t('Page.Home.Steps.Buzzer-Main-Edeka.2') }
                                step2Text = {parse("Lade <a href=\"/mitmachen\">hier</a> bis zum <span class=\"text--wheat\">05.06.2022</span> den <span class=\"text--wheat\">Kaufbeleg</span> hoch. (Ein Kaufbeleg pro Teilnahme)")}
                                step3 = { props.t('Page.Home.Steps.Buzzer-Main-Edeka.3') }
                                step3sm = { props.t('Page.Home.Steps.Buzzer-Main-Edeka.3') }
                                step3Text = {parse("Schon bist du im Gewinner-Lostopf! Nach Ablauf des Gewinnspiels am <span class=\"text--wheat\">05.06.2022</span> teilen wir dir <span class=\"text--wheat\">per E-Mail</span> mit, ob du gewonnen hast.")}
                                step4 = { props.t('Page.Home.Steps.Buzzer-Main-Edeka.4') }
                                step4sm = { props.t('Page.Home.Steps.Buzzer-Main-Edeka.4') }
                                step4Text = { parse("Zusätzlich sicherst du dir über die Teilnahme am FC Milka Gewinnspiel „Jetzt Meister werden!“ die Chance auf eine Dauerkarte für die Saison 2022/23 der <span style='white-space: nowrap; font-weight: normal;'>Bundesliga / 2. Bundesliga</span> oder Gold im Wert von 25.000 €.") }
                            />

                            <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                                <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                            </NavLink>
                            <p className="step-container--terms-link">
                                Hier findest du die  <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a> für unser Gewinnspiel.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    cardSection() {
        return (
            <div className="card-container">
                <div className="card-container__card">
                    <div className="card-container__card__primary">
                        <img src="/resources/imagesBundesliga/buzzer_22/edeka/card/supercup.png" alt="Gold im Wert"/>
                        <h3>Jetzt Familienzeit beim Supercup gewinnen</h3>
                        <p>Gewinne 12x exklusive Familienerlebnisse beim Supercup: 11x Einlaufkind, 1x Trophäenkind, für bis zu 5 Personen, inkl. Tickets, Rahmenprogramm, An- und Abreise, Übernachtung und Verpflegung</p>
                    </div>
                    <div className="card-container__card__secondary">
                        <img src="/resources/imagesBundesliga/buzzer_22/edeka/card/jersey.png" alt="Dauerkarten der Saison"/>
                        <h3>Gewinne dein original Heimtrikot</h3>
                        <p>Sichere dir die Chance auf eines von 50 original Heimtrikots der Bundesliga / 2. Bundesliga der Saison 21/22.</p>
                    </div>
                    <div className="card-container__card__tertiary">
                        <div className="image-container">
                            <img src="/resources/imagesBundesliga/buzzer_22/edeka/card/supercup_logo.png" alt="Dauerkarten der Saison"/>
                        </div>
                        <div className="text-container">
                            <h3>Der Supercup</h3>
                            <p>
                                Der Supercup bildet in der Regel den Auftakt der neuen Bundesliga Saison. Teilnehmer sind der Deutsche Meister und der Gewinner des DFB-Pokals der vorangegangenen Spielzeit.
                            </p>
                            <p>
                                Im Falle eines „Double-Siegers“ tritt der Bundesliga Zweite gegen den Deutschen Meister und DFB-Pokalsieger an. Den Termin und Spielort für den Supercup legt der DFL Deutsche Fußball Liga e.V. fest.
                            </p>
                            <p>
                                Die Begegnungen erreichen nicht nur die Fans in Deutschland, sondern auch Zuschauer rund um den Globus. Seit der Supercup Wiedereinführung im Jahr 2010 ehrt die DFL den Gewinner mit einer eigens dafür geschaffenen Trophäe.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <h2>
                    Sichere dir jetzt deine doppelte Gewinnchance
                </h2>
                <h3>
                    Neben den 12x exklusiven Familienerlebnissen beim Supercup und den 50x original Heimtrikots der Bundesliga / 2. Bundesliga der Saison 21/22 warten beim FC Milka Gewinnspiel „Jetzt Meister werden!“ viele weitere tolle Fußball-Preise auf dich: 1x Gold im Wert von 25.000 € oder 120x 1 Dauerkarte der Bundesliga / 2. Bundesliga. 
                </h3>

                <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                    <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                </NavLink>
                <p className="terms-link">
                    Hier findest du die <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a>.
                </p>
                <p className="terms-faq">
                    Noch Fragen zur Aktion? Hier geht es zu den <a href={GLOBAL_CONFIG.Route.buzzer.faq}>FAQ</a>.
                </p>
                <h3 className="terms-title">
                    Teilnahmebedingungen
                </h3>
                <h4 className="terms-subtitle">
                    Ergänzend zu den vollständigen <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzhinweisen</a> zu dem Gewinnspiel „Jetzt Meister werden“ gelten für die Zusatzverlosung bei Edeka die folgenden Teilnahmebedingungen:
                </h4>
                <p className="instruction">
                    <strong>Veranstalter:</strong> Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen. Teilnahmeberechtigt Zusatzverlosung Edeka sind Eltern mit Kindern zwischen 8 und 12 Jahren mit Wohnsitz in Deutschland. Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen sowie jeweils deren Angehörige. Teilnahme durch Kauf eines Milka-Produkts im Zeitraum vom 11.04.-05.06.2022 sowie Online Registrierung und Hochladen eines Fotos des gut lesbaren, automatisch erstellten, originalen Kaufbelegs (Kaufbeleg inkl. Einkaufsdatum, -uhrzeit und Belegsumme), Angabe des Milka Produkts, des Händlers / Kaufdatums auf www.gold.fcmilka.de/edeka. Nicht teilnehmende Produkte unkenntlich machen. Kaufbeleg zur späteren Prüfung aufbewahren. Pro Kaufbeleg nur eine Teilnahme. Max. ein Gewinn pro Person. Gewinnermittlung der Zusatzverlosung bei Edeka durch Ziehung aus allen gültigen Teilnahmen am 07.06.2022. Die Gewinnbenachrichtigung erfolgt per E-Mail an die bei der Registrierung angegebenen E-Mail-Adresse innerhalb einer Woche nach Ende des Teilnahmezeitraums.
                </p>
                <p className="instruction">
                    <strong>Gewinne:</strong> 12x Supercup Familienerlebnisse (11x Einlaufkind, 1x Trophäenkind, für bis zu 5 Personen, inkl. Tickets zum Spiel, Rahmenprogramm, An- und Abreise per Bahn 2. Klasse oder Kostenerstattung durch Tankgutscheine (Höhe abhängig von Gesamtstrecke), 2x Übernachtung im Doppel-/Familienzimmer inkl. Frühstück sowie Verpflegung). Voraussetzung um an der Verlosung teilzunehmen und den Status des Einlaufkindes oder des Trophäenkindes zu erhalten, erfolgt unter Berücksichtigung der oben aufgeführten Kriterien. 50x 1 original Heimtrikot der Bundesliga / 2. Bundesliga der Saison 21/22. Auswahl nach Verfügbarkeit online im FC Milka Fan Center. 
                </p>
                <p className="instruction">
                    <strong>Heimtrikots:</strong> Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga / 2. Bundesliga. Der Gewinner hat die Möglichkeit sich aus den verfügbaren Trikots online im FC Milka Fanshop eines auszusuchen. Der Veranstalter kann die permanente Verfügbarkeit aller Trikots nicht gewährleisten, es besteht daher kein Anspruch auf ein bestimmtes Trikot.
                </p>
                <p className="instruction">
                    <strong>Supercup Familienerlebnis:</strong> Bei Nichtinanspruchnahme verfällt der Gewinn ersatzlos. Die Gewinner von Supercup Tickets erhalten von der zuständigen Agentur eine E-Mail, mit der Info, dass sie gewonnen haben sowie einem Gutschein für das Familienerlebnis Supercup als pdf-Anhang. In dem Gutschein und dem Anschreiben wird genau erläutert, bis zu welchem Zeitpunkt sich die Gewinner spätestens direkt an Liga Travel wenden müssen für die notwendigen Buchungen und die Tickets. Weiterhin erhalten die Eltern eine Einwilligungserklärung bezüglich der Teilnahme der Kinder als Einlaufkind und Trophäenkind, die unterschrieben zurückgesendet werden muss, um teilnehmen zu können.

                
                </p>
                <p className="instruction">
                    Der Gewinn steht unter der aufschiebenden Bedingung, dass der Gewinner nach Kontaktaufnahme der vom Veranstalter mit der Ausführung des Events beauftragten Agentur per Post zunächst die bei der Teilnahme angegebenen Daten bestätigt sowie das Anmeldeformular vollständig unterschrieben innerhalb einer Frist von 10 Tagen einsendet. Sofern der Gewinner dies unterlässt, kann der Gewinn nicht mehr in Anspruch genommen werden und der Veranstalter kann den Gewinn nach eigenem Ermessen verfallen lassen oder an einen weiteren Teilnehmer, der unter denselben Bedingungen ermittelt wird, vergeben. Sofern die Teilnehmer die Teilnahmevoraussetzungen erfüllen, d.h. die bei der Teilnahme angegebenen Daten werden fristgemäß bestätigt, werden die Gewinner bei der Organisation der Veranstaltung durch das Agenturpersonal begleitet.   
                </p>
                <p className="instruction">
                    Zum Zweck der öffentlichen Berichterstattung über das Fußballspiel oder der jeweiligen Sportveranstaltung und das Einlaufen der Kinder können Bild- und Bildtonaufnahmen des teilnehmenden Kindes von der DFL, den mit ihr nach § 15 f. AktG verbundenen Unternehmen, den Fußballclubs/ ihren jeweiligen Verbänden und sonst befugten Dritten (z.B. Presse, Rundfunk) verarbeitet, verwertet und veröffentlicht werden. Mondelez kann diese Bild- und Bildtonaufnahmen und Fotos für die Nachberichterstattung für interne Zwecke sowie zu Werbezwecken nutzen. Hierzu holt Mondelez eine gesonderte Einwilligungserklärung der Teilnehmer ein. Die Rechtsgrundlage für die benannten Veröffentlichungen/ Nutzungen stellt das berechtigte Interesse der jeweiligen Unternehmen dar (Art. 6 Abs. 1 S. 1 Buchstabe f der DSGVO). Bei weitergehenden Nutzungen, die nicht von einem berechtigten Interesse abgedeckt sind, behält sich Mondelez vor, bei den Erziehungsberechtigten eine entsprechende Einwilligung (Art. 6 Abs. 1 S. 1 Buchstabe a der DSGVO) anzufragen. Es besteht kein Anspruch auf Veröffentlichung von Bild- und Bildtonaufnahmen, diese liegt im alleinigen Ermessen der benannten Unternehmen. 
                </p>
                <p className="instruction">
                    Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID 19 Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation. 
                </p>
                <p className="instruction">
                    Barauszahlung und Rechtsweg ausgeschlossen. Nicht kombinierbar mit anderen Aktionen. 
                </p>
                <p className="instruction">
                    <strong>Sonstiges:</strong> Im Übrigen gelten die Teilnahmebedingungen des Gewinnspiels „Jetzt Meister werden“: Weitere Informationen, vollständige Teilnahmebedingungen und Datenschutzhinweise unter <a href={GLOBAL_CONFIG.Route.terms}>https://www.gold.fcmilka.de/teilnahmebedingungen</a>. 
                </p>
            </div>
        )
    }

    LoadCampaign() {
        KIPServices.Edeka()
        .then( response => {
            var { status, success, message, placeholder } = response.data;

            if(success) {
                switch (status) {
                    case _STATUS_CAMPAIGN.holding:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.holdingEdeka
                        });
                        break;
                    case _STATUS_CAMPAIGN.closed:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.endEdeka
                        });
                        break;
                
                    default:
                        mainLoaderToggle('hide');
                        break;
                }
            }
        })
        .catch( () => {
            mainLoaderToggle('hide');
        })
        .finally( () => {
            /*const introPage = document.querySelector(".content-home");
            if(introPage){
                _SCROLL_PAGE.ScrollSection(introPage);
            }else{
                _SCROLL_PAGE.ScrollToBanner();
            }*/

            _SCROLL_PAGE.ScrollToTop();
        })
    }

}

export default withTranslation()(BuzzerEdeka);