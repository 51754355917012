import React from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';  

import TitleLogo from "../../../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../../config/config";
import { mainLoaderToggle, optionListConstruct } from "../../../../modules/helpers";
import JerseyNavigation from "../JerseyNavigation";
import JerseyStepIndicitor from "../StepIndicator";
import DropdownClub from "../../../../components/Content/DropdownClub/DropdownClub";
import Loader from "../../../../components/Overlays/Loader";
import MobileBuzzerGame from "../../../../components/Layout/Game/Buzzer/Mobile";
import DesktopBuzzerGame from "../../../../components/Layout/Game/Buzzer/Desktop";
import Match from "../../../../Models/Match";
import _LOCAL from "../../../../Models/LocalStorageKeys";
import FanCenterServices from "../../../../Service/FanCenter";
import _GIFT from "../../../../Models/GiftTypes";
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import _STATUS from "../../../../Models/ParticipationSteps";
import _USER_TYPES from "../../../../Models/UserTypes";
import _TICKET_TYPES from "../../../../Models/TicketTypes";

class TicketTeamSelection extends React.Component {
    constructor() {
        super();

        this.StepList = [
            {
                display: "Code-Eingabe",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "Lieblingsclub & Gewinnauswahl",
                isInProgress: true,
                isComplete: false,
            },
            {
                display: "Daten-Check",
                isInProgress: false,
                isComplete: false,
            }
        ];

        this.state = {
            TeamSelected: null,
            CovidInfo: true,
            SimpleLoader: false,
            matches: [],
            SelectedMatch: null,
            isSelectedMatch : false,
            GiftCategory: localStorage.getItem(_LOCAL.GiftCategory) || _TICKET_TYPES.CAT2,
            UserType: _USER_TYPES.User,
            IsDirectTicketSelection : true,
            TicketChoice: "2",
            isSingleTicket : false
        }

        this.getMatchsDirectSelection = this.getMatchsDirectSelection.bind(this);
        this.TicketsChoice = this.TicketsChoice.bind(this);
    }

    componentDidMount() {
        let UserDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));
        if(!!UserDetails) {
            if(UserDetails.IsDirectTicketSelection){
                this.getMatchsDirectSelection();
            }
            this.setState({
                UserType: UserDetails.UserType,
                IsDirectTicketSelection: UserDetails.IsDirectTicketSelection
            });
        } else {
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user);
        }
        _SCROLL_PAGE.ScrollToBanner();
        mainLoaderToggle('hide');
    }

    getMatchsDirectSelection(){
        let _data = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));
        var matches = [];
        _data.TeamList.forEach( match => {
            let options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' },
                matchLocation = `${match.location} (${match.groundName})`;
            let matchDate = null, matchTime = null;
                if(match.weekendDate) {
                    matchDate = `Spieltag ${match.gameweek} (${match.weekendDate})`;
                    matchTime = "";
                }else{
                    matchDate = new Date(match.matchDate).toLocaleString('de-DE', options);
                    matchTime = new Date(match.matchDate).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'});
                }

            matches.push(
                new Match({
                    id: match.footballMatchId,
                    date: `${matchDate} ${matchTime}`,
                    home: match.home,
                    away: match.away,
                    outStock: match.outOfStock === "True" || match.outOfStock === true ? true : false ,
                    location: matchLocation || "Match Location",
                    matchDay: "0",
                    gameweek : match.gameweek,
                    weekendDate : match.weekendDate,
                    isSingleTicket : match.isSingleTicket
                })
            )
        });

        this.InitMatches(matches);
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        let classNameSubmit = "btn__container btn--red";
        let disabledButton = false;
        if(this.state.isSelectedMatch == false){
            classNameSubmit = classNameSubmit+ " disabled";
            disabledButton = true;
        }
        
        return (
            <div className="buzzer-jersey">
                { this.HeadingSection() }

                {
                    !this.state.IsDirectTicketSelection &&

                    <form id="frm_team_selection" className="ticket"
                        ref = { form => this._TEAM_SELECTION = form }
                        name="frm_team_selection" 
                        method="post" 
                        action="/">

                        {
                            !!this.state.TeamSelected ?
                            <img id="team-logo" src={`/resources/imagesBundesliga/team-picture/${this.state.TeamSelected}.png`} />
                            : null
                        }

                        { this.TeamSelectionSection(this.props) }

                    </form>
                }

                {
                    this.state.IsDirectTicketSelection &&

                    <form id="frm_team_selection" className="ticket"
                        ref = { form => this._TEAM_SELECTION = form }
                        name="frm_team_selection" 
                        method="post" 
                        action="/">
                        { this.TicketsChoice() }
                    </form>
                }

                {
                    !!this.state.matches.length > 0 ?
                    <div className="campaign-game">
                        <h4 className="recoleta-medium">
                            Welche Partie möchtest du live im Stadion erleben? 
                        </h4>
                        <MobileBuzzerGame matches={this.state.matches} checkedOneMatch={this.checkedOneMatch.bind(this)} selectionUpdate={this.UpdateSelected.bind(this)} selectionAllowed={true}/>

                        <DesktopBuzzerGame matches={this.state.matches} checkedOneMatch={this.checkedOneMatch.bind(this)} selectionUpdate={this.UpdateSelected.bind(this)} selectionAllowed={true} suffix="Uhr"/>    

                        <p>
                            Schnell sein lohnt sich! Deine Ticket-Auswahl ist keine Reservierung. 
                            <br />Erst nach Abschluss der gesamten Gewinnauswahlstrecke hast du dir deine Bundesliga-Tickets gesichert. 
                        </p>

                        <button onClick={ this.state.isSelectedMatch ? this.SubmitMatchChoice.bind(this) : null} className={classNameSubmit} disabled={disabledButton}>
                            WEITER
                        </button>
                    </div>
                    : null
                }

                <div id="Covid-Details">
                    {
                        this.state.CovidInfo ? 
                        <div className="back--red">
                            <p>
                                Auf Grund der aktuellen COVID-19 Situation erhalten wir die Tickets sehr kurzfristig vor dem Spieltag von den Clubs.
                                <br />
                                {/* Das Milka Team hat keinen Einfluss auf die Ticketausstellung, das wird seitens der einzelnen Clubs geregelt. Es ist uns darum nicht möglich, nähere Angaben zum genauen Versandzeitpunkt zu machen.
                                Bitte berücksichtige auch, dass einige Clubs insbesondere bei steigenden Inzidenzzahlen überlegen, den Zugang zum Stadion nur vollständig Geimpften oder genesenen Zuschauern zu gestatten. Diese Entscheidung und Verantwortung obliegt ausschließlich den jeweiligen Clubs.
                                Wir bitten um deine Geduld und dein Verständnis. Bitte informiere dich vor dem Stadionbesuch bei den entsprechenden Stellen. */}
                            </p>
                            <p>Das Milka Team hat keinen Einfluss auf die Ticketausstellung, das wird seitens der einzelnen Clubs geregelt. Es ist uns darum nicht möglich, nähere Angaben zum genauen Versandzeitpunkt zu machen. Wir bitten um deine Geduld und dein Verständnis.</p>
                        </div> : null
                    }
                </div>


                <Footersocial />
                {
                    this.state.SimpleLoader ? <Loader /> : null
                }
            </div>
        )
    }

    checkedOneMatch(){
        this.setState({
            isSelectedMatch : true
        })
    }
    HeadingSection() {
        return (
            <div>
                {/* <JerseyNavigation /> */}
                {/* <TitleLogo /> */}
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        {/* <button className="btn__container btn--secondary-dark" onClick={this.ScrollCovidInfo.bind(this)}>COVID-19 Infos</button> */}
                        <JerseyStepIndicitor Steps={this.StepList}/>
                        {
                            this.state.GiftCategory === _TICKET_TYPES.VIP ?
                            <p className="lilac">
                                Wähle hier ein Spiel deines Lieblingsclubs aus. Schnell sein lohnt sich: Ein Club kann nicht mehr ausgewählt werden, sobald die verfügbaren VIP-Tickets vergriffen sind. 
                                Falls die von dir ausgewählten VIP-Tickets am Ende der Gewinnauswahlstrecke nicht mehr verfügbar sind, wirst du für eine neue Auswahl automatisch wieder auf diese Seite geleitet.
                            </p>
                            :
                            <p className="lilac">
                                Wähle hier ein Spiel deines Lieblingsclubs aus. Schnell sein lohnt sich: Ein Club kann nicht mehr ausgewählt werden, sobald die verfügbaren Tickets vergriffen sind. Falls die von dir ausgewählten Tickets am Ende der Gewinnauswahlstrecke nicht mehr verfügbar sind, wirst du für eine neue Auswahl automatisch wieder auf diese Seite geleitet.
                            </p>
                        } 
                    </div>
                </div>
            </div>
        );
    }

    
    SetChoice(event) {
        let _DOM = event.target,
            value = _DOM.value;

        this.setState({
            TicketChoice: value
        })
    }

    TicketsChoice() {
        return (
                <div className="tickets-choice-wrapper">
                    {this.state.GiftCategory === _TICKET_TYPES.VIP ? <h2>Wähle 1 oder 2 VIP-Tickets</h2> : <h2>Wähle ein oder zwei CAT2 Tickets</h2> }
                    <div className="tickets-select js-purchase-options">
                        <label className="custom-inputs" htmlFor="ticket_1"> {this.state.GiftCategory === _TICKET_TYPES.VIP ? '1 VIP- Ticket' : "1 Ticket" }
                            <input type="radio" name="ticket" id="ticket_1" value="1" className="js-event-type-in js-event-ab" data-require="true" data-type="radio" data-event-target=".js-purchase-options"  data-error-target="#error-ticket" data-required-message="" data-event-fieldname="ticket_1" data-event="form-click" data-category="Form Action" data-action="ticket" data-label="Type In"
                                defaultChecked={this.state.TicketChoice === "1" || this.state.isSingleTicket}
                                onClick={this.SetChoice.bind(this)}
                            />
                            <span className="radiobtn"></span>
                        </label>
                        <label className={`custom-inputs ${this.state.isSingleTicket && this.state.isSelectedMatch ? "disabled" : ""}`} htmlFor="ticket_2"> {this.state.GiftCategory === _TICKET_TYPES.VIP ? '2 VIP- Tickets' : "2 Tickets" }
                            <input type="radio" name="ticket" id="ticket_2" value="2" className="js-event-type-in js-event-ab" data-require="true" data-type="radio" data-event-target=".js-purchase-options"  data-error-target="#error-purchase" data-required-message="" data-event-fieldname="ticket_2" data-event="form-click" data-category="Form Action" data-action="ticket" data-label="Type In" disabled={this.state.isSingleTicket}
                                defaultChecked={this.state.TicketChoice === "2" || !this.state.isSingleTicket}
                                onClick={!this.state.isSingleTicket && this.state.isSelectedMatch ? this.SetChoice.bind(this) : undefined }
                            />
                            <span className="radiobtn"></span>
                        </label>
                    </div>
                </div>
            );
    }

    TeamSelectionSection(props) {
        let teamArray = !!localStorage.getItem(_LOCAL.AvailableTeams) ? JSON.parse(localStorage.getItem(_LOCAL.AvailableTeams)) : [],
            footballClubContent = optionListConstruct(teamArray);

        return (
            <div className="form__container">
                <DropdownClub
                    defaultImg={"/resources/imagesBundesliga/team-picture/team-default.png"}
                    divClass={"form-input__container dropdown-input__container form-input__dropdown full-width btn-bottom"}
                    class="team-picture"
                    id="team1"
                    title=""
                    image={props.t('Page.Participation.form.footballClub.images.image1')}
                    defaultValue={props.t('Page.Participation.form.footballClub.footballClubFieldText')}
                    team="team1"
                    content={parse(footballClubContent)}
                    noImage={true}
                    setFavouriteTeam={ this.FavouriteClubSelection.bind(this)}
                />
            </div>
        );
    }

    FooterSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <h2>Du hast schon einen Gewinncode?</h2>
                <h3>Wow! Wir gratulieren dir zu deinem FC Milka Fußball-Gewinn. Du bist nur noch wenige Klicks von deinem Gewinn entfernt.</h3>
                <NavLink to={GLOBAL_CONFIG.Route.fanCenter.codeEntry.user}>
                    <button className="btn__container btn--red">
                        HIER GEHT'S WEITER
                    </button>
                </NavLink>
            </div>
        );
    }

    FavouriteClubSelection( team ) {
        this.setState({
            TeamSelected: team,
            SimpleLoader: true,
            isSelectedMatch : false
        })

        /**
         * Get Matches
         */
        var teamIDs = JSON.parse(localStorage.getItem(_LOCAL.AvailableTeamIDs)),
            _data = {
                GiftCategory: localStorage.getItem(_LOCAL.GiftCategory),
                GiftType: _GIFT.Ticket,
                TeamId: !!teamIDs ? teamIDs[team] : "id-unknown"
            }

        FanCenterServices.GetMatches(_data)
        .then( response => {
            let {success, message, data} = response.data,
                { Tickets } = data;

            if(success) {
                var matches = [];

                Tickets.forEach( match => {
                    let options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' },
                        matchLocation = `${match.location} (${match.groundName})`;
                    let matchDate = null, matchTime = null;
                        if(match.weekendDate) {
                            matchDate = `Spieltag ${match.gameweek} (${match.weekendDate})`;
                            matchTime = "";
                        }else{
                            matchDate = new Date(match.matchDate).toLocaleString('de-DE', options);
                            matchTime = new Date(match.matchDate).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'});
                        }
                    matches.push(
                        new Match({
                            id: match.footballMatchId,
                            date: `${matchDate} ${matchTime}`,
                            home: match.home,
                            away: match.away,
                            outStock: match.outOfStock === "True" || match.outOfStock === true ? true : false ,
                            location: matchLocation || "Match Location",
                            matchDay: "0",
                            gameweek : match.gameweek,
                            weekendDate : match.weekendDate
                        })
                    )
                })

                this.InitMatches(matches);
            }
        })
        .catch( () => {
            let matches = []
        })
        .finally( () => {
            this.setState({
                SimpleLoader: false
            })
        })
    }

    UpdateSelected(selected) {
        const isSingle = !!selected.isSingleTicket && (selected.isSingleTicket === "true" || selected.isSingleTicket === true) ? true : false;
        this.setState({
            SelectedMatch: selected,
            isSingleTicket : isSingle,
            TicketChoice : isSingle ? "1" : this.state.TicketChoice
        });

        if(isSingle){
            const ticket1 = document.getElementById("ticket_1");
            ticket1.checked = true;
        } else {
            const ticket2 = document.getElementById("ticket_2");
            ticket2.checked = true;
        }
    }

    SubmitMatchChoice(event) {
        var {SelectedMatch, TeamSelected, IsDirectTicketSelection, TicketChoice } = this.state;
        var _data = {
            ...JSON.parse(localStorage.getItem(_LOCAL.UserDetails)),
            Match: SelectedMatch,
            TicketChoice : TicketChoice
        }
        localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(_data));
        this.props.UpdateFancenterStatus(_STATUS.Fancenter.teamSelected)
        
        if(IsDirectTicketSelection){
            
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.userDetails.user);
        } else if(!!SelectedMatch && TeamSelected) {
            switch (this.state.UserType) {
                case _USER_TYPES.User:
                    this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.userDetails.user)
                    break;
                case _USER_TYPES.Comp1:
                    this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.userDetails.companion)
                    break;
            
                default:
                    break;
            }
        }
    }

    InitMatches(matches) {
        /**
         * Preselect first match in list & drop down
         */
        let matchList = [...matches],
            index = 0,
            selectIndex = null;

        while (selectIndex == null && index < matchList.length) {
            const {outStock} = matchList[index];

            if(!outStock) {
                selectIndex = index;
                break;
            }
            index++;
        }

        if(selectIndex) {
            this.setState({
                matches: matchList,
                selected: matchList[selectIndex]
            });
        } else {
            this.setState({
                matches: matchList,
            });
        }
    }

    ScrollCovidInfo(e) {
        this.setState({
            CovidInfo: true
        })

        _SCROLL_PAGE.ScrollSection(document.getElementById("Covid-Details"))
    } 
}

export default withTranslation()(withRouter(TicketTeamSelection));