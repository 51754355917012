import React, { Component, useCallback } from 'react';
import { withTranslation } from "react-i18next";
import GLOBAL_CONFIG from '../../config/config';
import NavigationAccount from '../NavigationAccount/NavigationAccount';
import { servicePath, mainLoaderToggle, optionListConstruct, getCookie, ServicesUrl } from '../../modules/helpers';
import axios from 'axios';
import MenuBarAccount from '../MenuBarAccount/MenuBarAccount';
import flatpickr from "flatpickr";
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import { withRouter } from 'react-router';
import AccountServices from '../../Service/Account';
import Salutations from '../../Models/Salutation';
import ValidateForm from '../../modules/validate';
import appGoogleTracking from '../../modules/googletracking';

const getSalutations = [
    { value: '0', label: 'Anrede' },
    { value: '1', label: Salutations._MALE },
    { value: '2', label: Salutations._FEMALE },
    { value: '10', label: Salutations._OTHERS },
    // ... autres salutations
];
class AccountInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            milka: true,
            tokenLogin: null,
            Token: null,
            firstname: null,
            lastname: null,
            email: null,
            birthdate: null,
            defaultDate: null,
            // phoneNumber: null,
            street1: null,
            streetNumber: null,
            zipcode: null,
            city: null,
            address: null,
            salutation: null,
            land: "Deutschland",
            messageSubmit:null,
            loaderSubmit:false,
        };
        // this.handleChange = this.handleChange.bind(this);
    }
    componentWillMount() {
        // console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));

        if (getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null) {
            let getLoginDetailURL = servicePath({
                ...GLOBAL_CONFIG.ServiceSettings,
                status: 'details'
            });
            let tokenInfo = getCookie(GLOBAL_CONFIG.Session.tokenstatus)

            // console.log(tokenInfo);
            axios({
                method: 'POST',
                url: getLoginDetailURL,
                data: {
                    "token": tokenInfo
                },
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    // console.log(response)
                    let _data = response.data;
                    if (_data.success) {
                        this.setState({
                            firstname: response.data.firstname,
                            lastname: response.data.lastname,
                            email: response.data.email,
                            salutation: response.data.salutation,
                            tokenLogin: response.data.token,
                            street1: response.data.street1,
                            streetNumber: response.data.streetnumber,
                            zipcode: response.data.zipcode,
                            city: response.data.city,
                            address: response.data.street2,
                            // phoneNumber: response.data.phoneNumber,
                            Token: sessionStorage.getItem(GLOBAL_CONFIG.Session.tokenstatus),
                        });
                    }
                })
                .catch((response) => {
                    // this.setState({
                    //     sreet1:"response.data.street1",
                    //     streetNumber:'response.data.streetnumber',
                    //     zipcode:'response.data.zipcode',
                    //     city:'response.data.city',
                    //     tokenLogin:'response.data.token',

                    // })
                    // console.log("address not found")
                    this.props.history.push({ pathname: GLOBAL_CONFIG.Route.address });
                });
        } else {
            this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
        }
    }
    // getimageTeam= (name)=>{
    //     let image=name;
    // }
    changeDate = () => {
        // console.log(this.state.defaultDate.toString())
        let dobSelector = document.getElementById('dob');
        flatpickr(dobSelector, {
            dateFormat: this.state.DateFormat,
            // maxDate: this.BirthdayDate(this.state.birthdayAge),     //function to calculate date of birth from todays date
            disableMobile: true,
            defaultDate: this.state.defaultDate
        });
    }
    componentDidMount() {
        mainLoaderToggle('hide');

    }
    componentWillUnmount() {
        mainLoaderToggle('show');

    }
    logout = () => {
        if (getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null) {
            sessionStorage.setItem(GLOBAL_CONFIG.Session.tokenstatus, null);
            document.cookie = GLOBAL_CONFIG.Session.tokenstatus + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            //document.cookie = GLOBAL_CONFIG.Session.tokenstatus + '=' + null + ';domain=' + GLOBAL_CONFIG.DomainName;
            // console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
            this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });

        }
        this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
    }

    handleChange = (e, stateName) => {
        this.setState({ [stateName]: e.target.value });
        // console.log('stqte',this.state)
    };

    onSubmitHandler = (event) => {
        event.preventDefault();

        //validation
        const isValidForm = ValidateForm.validateForm('frm_registration');
        if (isValidForm) {
            let data = {
                Token: this.state.Token,
                salutation: this.state.salutation,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                street1: this.state.street1,
                streetNumber: this.state.streetNumber,
                street2: this.state.address,
                city: this.state.city,
                zipcode: this.state.zipcode,
            }
            this.setState({
                loaderSubmit: true
            });
            AccountServices.UpdateAccount(data).then(rep => {
                //success
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'save_information',
                    'dataCategory': 'Account Information Page',
                    'dataAction': 'Click to Save',
                    'dataLabel': 'ENG_AI_FORM'
                })
                // console.log('success', rep);
                this.setState({
                    loaderSubmit: false,
                    messageSubmit: 'Deine Accountdaten wurden aktualisiert.'
                });
            }).catch(error => {
                this.setState({
                    loaderSubmit: false,
                    messageSubmit: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.'
                });
                console.log('error',);
                //error
            })

        } else {
            /**
             * Scroll To first error
             */
            let firstError = this._FORM_DETAILS.querySelector(".notvalid");

            if (!!firstError) {
                const yOffset = - 110,
                    y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }

    };

    SalutationValues = (salutation) => {
        switch (salutation) {
            case "1":
                return Salutations._MALE
            case "2":
                return Salutations._FEMALE

            case "10":
                return Salutations._OTHERS
            default:
                return "Arende *"
        }
    }

    onClosePopupHandler = () => {
        this.setState({
            messageSubmit: null,
            // loaderGeneralError: false
        });


    }

    render() {
        let milka = null
        const { t } = this.props;
        let footballClubContent = optionListConstruct(t('Page.Participation.form.footballClub.footballClubListName', { returnObjects: true }));
        milka = (
            <div>
                <div className="profile_detail account-information">
                    <div className="left">
                        <NavigationAccount />
                        <button className="btnLogout logout-lg" onClick={this.logout}>
                            <span className="btn__logout">Ausloggen</span>
                        </button>
                    </div>
                    <div className="form-container grid-5-m  grid-s-2 logout-address logout-sm">
                        <button className="btnLogout" onClick={this.logout}>
                            <span className="btn__logout">Ausloggen</span>
                        </button>
                    </div>
                    <div className="right">
                        <div className="content-upload-receipt form-line row ">
                            <div className="form-container">
                                <div className="choose_team dropdown_space">
                                    <h2>Account Informationen</h2>
                                    <div className="register">
                                        <div className="s-content-partication account-info">
                                            <form id="frm_registration" name="frm_registration" method="post" action="/" onSubmit={this.onSubmitHandler} ref={form => this._FORM_DETAILS = form} noValidate>

                                                <div className="form-line row arende">
                                                    <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                                        <div className="input-container salutation-input space_hr ">
                                                            <div className="form-input__container dropdown-input__container form-input__dropdown">

                                                                <select
                                                                    className="form-input__input js-event-type-in js-event-ab"
                                                                    type="text"
                                                                    name="salutation"
                                                                    id="salutation"
                                                                    placeholder="Arende*"
                                                                    data-require="true"
                                                                    data-type="ddl"
                                                                    data-error-target="#error-salutation"
                                                                    data-required-message="Salutation is required."
                                                                    data-event-fieldname="Salutation"
                                                                    data-event="form-click"
                                                                    data-category="Form Action"
                                                                    data-action="Salutation"
                                                                    data-label="Type In"
                                                                    onChange={(e) => this.handleChange(e, 'salutation')}
                                                                    value={this.state.salutation}
                                                                >
                                                                    {/* <option>
                                                                        {this.state.salutation ? this.SalutationValues(this.state.salutation) : 'Arende*'}
                                                                    </option> */}


                                                                    {getSalutations.map((salutation, index) => (
                                                                        <option key={index} value={salutation.value} selected={this.state.salutation === salutation.value}>
                                                                            {salutation.label}
                                                                        </option>
                                                                    ))}
                                                                    {/* <option value="1">Herr</option>
                                                                    <option value="2">Frau</option>
                                                                    <option value="10">Divers</option> */}
                                                                </select>


                                                                <label className="form-input__label" htmlFor="salutation">Arende*</label>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-line row">
                                                    <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                                        <div className="input-container space_hr">
                                                            <div className="form-input__container">
                                                                <label className="form-input__label" htmlFor="firstname">Vorname*</label>
                                                                <input
                                                                    className="form-input__input js-event-type-in js-event-ab" 
                                                                    type="text" 
                                                                    name="firstname" 
                                                                    id="firstname"
                                                                    value={this.state.firstname}
                                                                    data-require="true"
                                                                    data-type="regex"
                                                                    data-error-target="#error-firstname"
                                                                    data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                                                                    data-required-message="Firstname is required." 
                                                                    data-pattern-message="Firstname not valid."
                                                                    data-event-fieldname="Firstname"
                                                                    data-event="form-click"
                                                                    data-category="Form Action" 
                                                                    data-action="Firstname"  
                                                                    onChange={(e) => this.handleChange(e, 'firstname')}
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-line row">
                                                    <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                                        <div className="input-container space_hr">
                                                            <div className="form-input__container">
                                                                <label className="form-input__label" htmlFor="lastname">Nachname*</label>
                                                                <input 
                                                                    className="form-input__input js-event-type-in js-event-ab"
                                                                    type="text"  
                                                                    name="lastname"
                                                                    id="lastname" value={this.state.lastname}
                                                                    data-require="true"
                                                                    data-type="regex" 
                                                                    data-error-target="#error-lastname"
                                                                    data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                                                                    data-required-message="Lastname is required." 
                                                                    data-pattern-message="Lastname not valid."
                                                                    data-event-fieldname="Lastname" 
                                                                    data-event="form-click"
                                                                    data-category="Form Action" 
                                                                    data-action="Lastname"  
                                                                    onChange={(e) => this.handleChange(e, 'lastname')}
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-line row">
                                                    <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                                        <div className="input-container space_hr">
                                                            <div className="form-input__container">
                                                                <label className="form-input__label" htmlFor="email">Email*</label>
                                                                <input 
                                                                    className="form-input__input js-event-type-in js-event-ab"
                                                                    type="text"
                                                                    name="email"
                                                                    id="email"
                                                                    value={this.state.email} 
                                                                    data-require="true"
                                                                    data-type="regex"
                                                                    data-error-target="#error-email"
                                                                    data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                                                    data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail." 
                                                                    data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."
                                                                    data-event-fieldname="Email"
                                                                    data-event="form-click"  
                                                                    // onChange={(e) => this.handleChange(e, 'email')}
                                                                    disabled
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="from-line row">
                            <div className="form-container grid-6-m grid-m-6-m grid-s-2 js-datepicker-dob">
                                <div className="input-container">
                                    <div className="form-input__container active js-datepicker-dob">
                                        <label className="form-input__label" htmlFor="dob">Geburtsdatum</label>
                                        <input className="form-input__input js-event-type-in js-event-ab" type="text" name="dob" readOnly="true" id="dob" autoComplete="off" readOnly data-require="true" data-type="regex" data-error-target="#error-dob" data-regex-pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" data-required-message="Birthday is required." data-pattern-message="Birthday is not valid." data-event-fieldname="Birthday" data-event="form-click" data-category="Form Action" data-action="Birthday" disabled  />
                                    </div>
                                </div>
                            </div>
                            </div> */}
                                                <div className="form-line row">
                                                    <div className="form-container form-mobile grid-6-m grid-m-6-m grid-s-2">
                                                        <div className="input-container space_hr">
                                                            <div className="streetLbl_info">
                                                                <div className="form-input__container ">
                                                                    <label className="form-input__label" htmlFor="street_name">Straße*</label>
                                                                    <input
                                                                        className="form-input__input js-event-type-in js-event-ab"
                                                                        type="text"
                                                                        name="street_name"
                                                                        placeholder="Straße*"
                                                                        // readOnly="true"
                                                                        id="street_name"
                                                                        value={this.state.street1}
                                                                        data-require="true"
                                                                        data-type="text"
                                                                        data-error-target="#error-street-number"
                                                                        data-required-message="Street number is required."
                                                                        data-event-fieldname="Street number"
                                                                        data-event="form-click"
                                                                        data-category="Form Action"
                                                                        data-action="Street number"
                                                                        data-label="Type In"
                                                                        onChange={(e) => this.handleChange(e, 'street1')}

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="numberLbl_info">
                                                                <div className="form-input__container">
                                                                    <label className="form-input__label" htmlFor="street_number">Hausnummer*</label>
                                                                    <input
                                                                        className="form-input__input js-event-type-in js-event-ab"
                                                                        type="text"
                                                                        name="street_number"
                                                                        // readOnly="true"
                                                                        placeholder=" Hausnummer*"
                                                                        id="street_number"
                                                                        value={this.state.streetNumber}
                                                                        data-require="true"
                                                                        data-type="text"
                                                                        data-error-target="#error-house-number"
                                                                        data-required-message="House number is required."
                                                                        data-event-fieldname="House number"
                                                                        data-event="form-click"
                                                                        data-category="Form Action"
                                                                        data-action="House number"
                                                                        data-label="Type In"
                                                                        onChange={(e) => this.handleChange(e, 'streetNumber')} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-line row">
                                                    <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                                        <div className="input-container space_hr">
                                                            <div className="form-input__container">
                                                                <label className="form-input__label" htmlFor="adress">Adresszusatz</label>
                                                                <input
                                                                    className="form-input__input js-event-type-in js-event-ab"
                                                                    type="text"
                                                                    name="address"
                                                                    id="address"
                                                                    value={this.state.address}
                                                                    placeholder=" Adresszusatz"
                                                                    // data-require="true"
                                                                    data-type="text"
                                                                    data-error-target="#error-address"
                                                                    data-regex-pattern="^[0-9]{1,}$"
                                                                    data-required-message="Phone Number is required."
                                                                    data-pattern-message="Phone Number not valid."
                                                                    data-event-fieldname="Address"
                                                                    data-event="form-click"
                                                                    data-category="Form Action"
                                                                    data-action="Address"
                                                                    data-label="Type In"
                                                                    onChange={(e) => this.handleChange(e, 'address')}
                                                                // disabled
                                                                // readOnly="true"
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-line row">
                                                    <div className="form-container form-mobile grid-6-m grid-m-6-m grid-s-2">
                                                        <div className="input-container space_hr">
                                                            <div className="zipLbl_info">
                                                                <div className="form-input__container">
                                                                    <label
                                                                        className="form-input__label"
                                                                        htmlFor="zip_code"
                                                                    >
                                                                        PLZ*
                                                                    </label>
                                                                    <input
                                                                        placeholder='PLZ *'
                                                                        className="form-input__input js-event-type-in js-event-ab js-zip-code"
                                                                        type="text"
                                                                        name="zip_code"
                                                                        id="zip_code"
                                                                        value={this.state.zipcode}
                                                                        maxLength="5"
                                                                        data-require="true"
                                                                        data-type="regex"
                                                                        data-error-target="#error-zip-code"
                                                                        data-regex-pattern="^[0-9]{5}$"
                                                                        data-required-message="Zip code is required."
                                                                        data-pattern-message="Zip code not valid."
                                                                        data-event-fieldname="Zip code"
                                                                        data-event="form-click"
                                                                        data-category="Form Action"
                                                                        data-action="Zip code"
                                                                        onChange={(e) => this.handleChange(e, 'zipcode')}
                                                                    />
                                                                </div>

                                                            </div>
                                                            <div className="cityLbl_info">
                                                                <div className="form-input__container">
                                                                    <label className="form-input__label" htmlFor="city">Stadt*</label>
                                                                    <input className="form-input__input js-event-type-in js-event-ab"
                                                                        type="text"
                                                                        name="city"
                                                                        id="city"
                                                                        placeholder='Stadt*'
                                                                        value={this.state.city}
                                                                        // readOnly="true"
                                                                        data-require="true"
                                                                        data-type="text"
                                                                        data-error-target="#error-city"
                                                                        data-required-message="City is required."
                                                                        data-event-fieldname="City"
                                                                        data-event="form-click"
                                                                        data-category="Form Action"
                                                                        data-action="City"
                                                                        onChange={(e) => this.handleChange(e, 'city')}
                                                                    // disabled
                                                                    />
                                                                    <label className="form-input__label" htmlFor="city">Stadt</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-line row">
                                                    <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                                        <div className="input-container space_hr">
                                                            <div className="form-input__container dropdown-input__container active">
                                                                <input className="form-input__input" type="text" name="country" id="country" placeholder="Deutschland" value="Deutschland" readOnly="true" data-require="false" disabled />
                                                                <label className="form-input__label" htmlFor="country">Land*</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="form-line row">
                                                    <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                                        <div className="input-container">
                                                            <div className="form-input__container">
                                                                <label className="form-input__label" htmlFor="phoneNumber">Telefonnummer*</label>
                                                                <input className="form-input__input js-event-type-in js-event-ab"
                                                                    type="text"
                                                                    value={this.state.phoneNumber}
                                                                    name="phoneNumber"
                                                                    id="phoneNumber"
                                                                    placeholder=" Telefonnummer*"
                                                                    data-require="true"
                                                                    data-type="text"
                                                                    data-error-target="#error-phoneNumber"
                                                                    data-regex-pattern="^[0-9]{1,}$"
                                                                    data-required-message="Phone Number is required."
                                                                    data-pattern-message="Phone Number not valid."
                                                                    data-event-fieldname="phoneNumber"
                                                                    data-event="form-click"
                                                                    data-category="Form Action"
                                                                    data-action="phoneNumber"
                                                                    data-label="Type In"
                                                                    disabled
                                                                    readOnly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="form-line row">
                                                    <div className="form-button">
                                                        <button className="btn__container btn--lilac">
                                                            SPEICHERN
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
        return (
            <div>
                {
                    this.state.loaderSubmit ? (
                        <div className="simple-loader__container active">
                            <div className="simple-loader__indicator"></div>
                        </div>
                    ):null

                }
                {
                    this.state.messageSubmit ? (
                        <div className="js-overlay overlay overlay--black">
                            <div className="overlay__container overlay__content">
                                <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                                <p id="js-error-msg">{this.state.messageSubmit}</p>
                            </div>
                        </div>
                    ):null
                }
                <MenuBarAccount />
                {milka}
                <Footersocial />
            </div>
        )
    }
}
export default withTranslation()(withRouter(AccountInformation));