import React, { Component } from 'react'
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

export class varietyTeaserV2 extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isHovered: false,
      };
    }

    handleMouseEnter = () => { this.setState({ isHovered: true }); };
    handleMouseLeave = () => { this.setState({ isHovered: false }); };

    

    customNav(){
  
        const previousButton = () => {
          const prev = document.getElementsByClassName('owl-prev')[0];
          prev.click()
        }

        const nextButton = () => {
          const next = document.getElementsByClassName('owl-next')[0];
          next.click()
        }
  
        return(
            <div className='nav_under'>
                <div className='prev-owl' onClick={() => { previousButton() }}><span className='left__button'>‹</span></div>
                <div className='next-owl' onClick={() => { nextButton() }}><span  className='right__button'>›</span></div>
            </div>
        )
    }
    
    render() {
        const options = {
            responsiveClass: true,
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            smartSpeed: 1000,
            items: 1,
            center: true,
            responsiveClass:true,
            responsive: {
                0: {
                    stagePadding: 40,
                    margin: 20
                },
                480: {
                    stagePadding: 60,
                    margin: 30
                },
                600: {
                    stagePadding: 100,
                    margin: 30
                },
                769: {
                    stagePadding: 150,
                    margin: 30
                },
                991: {
                    stagePadding: 250,
                    margin: 30
                },
                1311: {
                    stagePadding: 330,
                    margin: 30
                },
                1600: {
                    stagePadding: 400,
                    margin: 40
                },
                1800: {
                    stagePadding: 460,
                    margin: 40
                }
            },
        };

        const { t } = this.props;
        const { isHovered } = this.state.isHovered;

        return (
            <>
                <div class="variety-wrapper-trikot"/>
                <div className="gamification-q3__prize-details">
                    <div className="s-content-title">
                            <h1 className="heading__v1 varietyTeaser2__heading">entdecke alle sorten</h1>
                            <h4 className='varietyTeaser2__paragraph'>entdecke alle milka-sorten und finde deinen favoriten.</h4>
                    </div>
                </div>
                <div className="variety__wrapper" >
                    <OwlCarousel className='owl-theme' {...options}>
                        <div className='card-item'>
                            <div className="card sorte text-center">
                                <div className='background'/>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/LU-Front.png" class="card-img-top front" />
                                <a href={'https://www.milka.de/produkte/milka-lu-kekse-87g/'} target='_blank'>
                                    <img src="/resources/imagesBundesliga/trikot-promo/slider/LU-Back.png" class="card-img-top back" />
                                </a>
                                <div class="card-img-top mobile-button"></div>
                            </div>
                        </div>
                        <div className='card-item'>
                            <div className="card sorte text-center">
                                <div className='background'/>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/TUC-Front.png" class="card-img-top front" />
                                <a href={'https://www.milka.de/produkte/milka-tuc-cracker-87g/'} target='_blank'>
                                    <img src="/resources/imagesBundesliga/trikot-promo/slider/TUC-Back.png" class="card-img-top back" />
                                </a>
                                <div class="card-img-top mobile-button"></div>
                            </div>
                        </div>
                        <div className='card-item'>
                            <div className="card sorte text-center">
                                <div className='background'/>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/Joghurt-Front.png" class="card-img-top front" />
                                <a href={'https://www.milka.de/produkte/milka-joghurt-100g-renovated/'} target='_blank'>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/Joghurt-Back.png" class="card-img-top back" />
                                </a>
                                <div class="card-img-top mobile-button"></div>
                            </div>
                        </div>
                        <div className='card-item'>
                            <div className="card sorte text-center">
                                <div className='background'/>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/Luflee-Front.png" class="card-img-top front" />
                                <a href={'https://www.milka.de/produkte/milka-luflee-100g-renovated/'} target='_blank'>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/Luflee-Back.png" class="card-img-top back" />
                                </a>
                                <div class="card-img-top mobile-button"></div>
                            </div>
                        </div>
                        <div className='card-item'>
                            <div className="card sorte text-center">
                                <div className='background'/>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/Erdbeer-Front.png" class="card-img-top front" />
                                <a href={'https://www.milka.de/produkte/milka-erdbeer-100g-renovated/'} target='_blank'>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/Erdbeer-Back.png" class="card-img-top back" />
                                </a>
                                <div class="card-img-top mobile-button"></div>
                            </div>
                        </div>
                        <div className='card-item'>
                            <div className="card sorte text-center">
                                <div className='background'/>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/Oreo_Sandwich-Front.png" class="card-img-top front" />
                                <a href={'https://www.milka.de/produkte/milka-oreo-sandwich-92g/'} target='_blank'>
                                <img src="/resources/imagesBundesliga/trikot-promo/slider/Oreo_Sandwich-Back.png" class="card-img-top back" />
                                </a>
                                <div class="card-img-top mobile-button"></div>
                            </div>
                        </div>
                    </OwlCarousel>
                    {this.customNav()}
                    <div className="variety_under under_content"></div>
                </div>
            </>
        )
    }
}

export default withTranslation()(varietyTeaserV2);