import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from 'react-router-dom';
import parse from 'html-react-parser'; 
import GLOBAL_CONFIG from '../../config/config'; 
import appGoogleTracking from '../../modules/googletracking';

import Aux from '../../hoc/Auxiliare';
import TitleLogo from '../../components/Content/TitleLogo/TitleLogo';
import { servicePath, mainLoaderToggle, timeStamp, optionListConstruct, dateConfig , getCookie } from '../../modules/helpers';
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import GiftContent from '../../components/Content/GiftContent/GiftContent';
import GiftContentFooter from '../../components/Content/GiftContent/GiftContentFooter';


import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import DropdownClub from '../../components/Content/DropdownClub/DropdownClub';
import axios from 'axios';                                                          //Axios library to handle AJAX request                                              //Parse HTML data from string
import ReCaptcha from 'react-google-invisible-recaptcha';                           //Google Recaptcha
import flatpickr from "flatpickr";                                                  //Date Picker
import ValidateForm from '../../modules/validate';
import NavigationAccount from '../NavigationAccount/NavigationAccount';    
import MenuBarAccount from '../MenuBarAccount/MenuBarAccount';
                          //Form validation module                      //Google tracking module


class ResetPassword extends Component {

    // componentDidMount(){
    //     mainLoaderToggle('hide');
       
    // }
    // componentWillUnmount(){
    //     mainLoaderToggle('show');
        
    // }
    // state = {
    //     tokenState: '',
    // }
    constructor(props) {
        super(props);

        // this.handleUnload = this.handleUnload.bind(this);       //Binding to check for Abondanment form for Google tracking
        
    
        this.state = {                   
               //User need to be at least 18 - use in flapickr
            loaderSubmit: false,
            loaderError: false,
            loaderGeneralError: false,
            message:false,
            tokenState: null,
            authorization:null,
            wrongOLdPassword:null,
            messageSent:null
        };
    }
    componentWillMount(){
        // this.setState({
        //     // tokenState: getCookie(GLOBAL_CONFIG.Session.tokenstatus)
        //     tokenState: "jikjioj",
            
        // });
    
        // if(getCookie(GLOBAL_CONFIG.Session.tokenstatus) === null){
        //     //Go to particaption page
        //     this.props.history.push({pathname: GLOBAL_CONFIG.Route.account});
        // } else {
        //      //Get session state
        //    this.setState({
        //         tokenState: getCookie(GLOBAL_CONFIG.Session.tokenstatus)
                
        //     });
        //     console.log(this.state.tokenState);
           
        // }

    }

    componentDidMount(){
        const { t } = this.props; 
        //Remove main loader
        mainLoaderToggle('hide');
        
    }

    //Close error message popup
    onClosePopupHandler = () => {
        this.setState({
            loaderError: false,
            loaderGeneralError: false,
            authorization:false,
            messageSent:false
        
        });
    }

    //Form submit event
    onSubmitHandler = (event) => {
        // console.log("sds");
        event.preventDefault();
        
        // //Validation of fields
        let validateFormInfo = ValidateForm.validateForm('frm_resetPassword');
    
        if(validateFormInfo === true){
            //Check if file is valid
            console.log("1111");
            this.processFormData();
        } 
    }

    messageError=()=>{
        this.setState({
            message:true
        });
        
    }
  
    //Process form when Captcha is OK
    processFormData = () => {
        console.log(
            "process"
        )
        //Open loader
        this.setState({
            loaderSubmit: true
        });
        // token=sessionStorage.getItem(GLOBAL_CONFIG.Session.loginStatus) 
        //Process form data
        let data = {
            // fileinput:             document.getElementById('file_to_upload').files[0],
                email:          document.getElementById('email').value,  
            // token:                 this.state.tokenState,             
        };
        console.log(data.email);
        //Get webservice path
        let getForgetPasswordURL = servicePath({
            ...GLOBAL_CONFIG.ServiceSettings,
            campaign: this.state.promotionCampaign,
            status: 'triggerResetPassword'
        });

        //Ajax call via axios
        axios({
            method: 'post',
            url: getForgetPasswordURL,
            data: data,
            headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {
            if(response.status === 200 || response.status === 201){
                if(response.data.success === true){
                    console.log("responsefggfg");

                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 'reset_password',
                        'dataCategory': 'Password Change Page',
                        'dataAction': 'Click on Reset Password',
                        'dataLabel': 'LOGIN_PC_PART'
                    })
                    //Set user participation
                    // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, 'participate'); 
                    // let userStatus = response.data.data.IsLogin? 'win' : 'lose';

                    let loginstatus;
                    //Set user as Winner or Loser
                    sessionStorage.setItem(GLOBAL_CONFIG.Session.loginstatus, loginstatus);    //win or lose

                    // //SET Participation ID
                    // window.PARTICIPATION_ID = response.data.ParticipationId;

                    // Datalayer push for Success participation
                    // this.triggerDatalayers({
                    //     status: 'success',
                    //     participationID: response.data.ParticipationId
                    // });

                    //Go to Thank you page
                    // this.props.history.push({pathname: GLOBAL_CONFIG.Route.participation});
                    this.setState({
                        messageSent:true
                    })
                    /* this.props.history.push({pathname: GLOBAL_CONFIG.Route.account}); */
                } else {
                    let errorStatus = response.data.data.Error[0];
                   
                    if(errorStatus === 'Authorization has been denied for this request.'){
                        console.log('Participation error');

                        this.setState({
                            loaderSubmit: false,
                            authorization:true
                        });

                        // this.triggerDatalayers({
                        //     status: 'error',
                        //     type: 'participate'
                        // });
                    } else {
                        if(errorStatus === 'OldPassword is wrong'){
                            console.log('Captcha error message');
                            this.setState({
                                wrongOLdPassword:true
                            })
                            //Datalayer push for Error
                            // this.triggerDatalayers({
                            //     status: 'error',
                            //     type: 'general'
                            // });
                        } else if(errorStatus === 'AN_ERROR_HAS_OCCURED'){
                            console.log('An error has occured');
                        }

                        this.setState({
                            loaderSubmit: false,
                            loaderGeneralError: true
                            
                        });
                    }

                    // this.recaptcha.reset();     //Reset captcha when error occurs - deploy
                }
            } else {
                //Show error messages
                console.log('Error 404 or 500');
                //Close Loader
                this.setState({
                    loaderSubmit: false,
                    loaderGeneralError: true
                });

                // this.recaptcha.reset();     //Reset captcha when error occurs
            }
        })
        .catch((response) => {
            this.props.history.push({pathname: GLOBAL_CONFIG.Route.passwordChangeEmail});
            //handle error          // //Close Loader
            this.setState({
                loaderSubmit: true,
                loaderGeneralError: true,
            });

            // this.recaptcha.reset();     //Reset captcha when error occurs
        });
    }
    render() {
        //Loader rendering for form submit and error handling
        let submitLoader = null,
            loaderGeneralError = null,
            errorLoaderGeneral = null,
            siteKey = null,
            loaderSubmit = null,
            message=null,
            wrongOLdPassword=null,
            authorization=null,
            messageSent= null
            //paraElement = null;
        if(this.state.message=== true){
           message=(
            <div>
                <p className="error-msg">Passwörter stimmen nicht überein!</p>
            </div>);
        }
        if(this.state.wrongOLdPassword=== true){
            wrongOLdPassword=(
             <div>
                 <p className="error-msg">falsches Passwort</p>
             </div>);
         }
        if(this.state.loaderSubmit === true){
            submitLoader = (
                <div className="simple-loader__container active">
                    <div className="simple-loader__indicator"></div>
                </div>  
            );
        }
        
        if(this.state.loaderGeneralError === true){ 
            loaderGeneralError = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                        <p id="js-error-msg">Ein Fehler ist aufgetreten. Bitte versuchen <br />Sie es später noch einmal.</p>
                    </div>
                </div>
            );
        }
        if(this.state.messageSent === true){ 
           messageSent = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                        <p id="js-error-msg">
                        Du erhältst in Kürze eine E-Mail, um im
nächsten Schritt ein neues Passwort anzulegen. </p>
                    </div>
                </div>
            );
        }
        if(this.state.authorization === true){ 
            authorization = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                        <p id="js-error-msg">
                            Die Autorisierung für diese Anfrage wurde verweigert</p>
                    </div>
                </div>
            );
        }
        return(
            <div>
                 <div className="profile_detail password_change_email">
                    <div className="account content-upload-receipt form-line row ">
                        <div className="content-upload-receipt form-container form-line row ">
                            <div className="form-container">
                            <form id="frm_resetPassword" name="frm_resetPassword" method="post" action="/" onSubmit={this.onSubmitHandler} noValidate>
                                <div className="input-container resetPassword">
                                        <h2 className="title" style={{ fontFamily: "Gobold Bold", fontFeatureSettings:"'clig' off, 'liga' off", fontSize:'40px', fontWeight:'400', lineHeight:'150%', textTransform:'uppercase'}}>Passwort vergessen?</h2>
                                        <p style={{ fontFamily: "Good Headline Pro" }}>Bitte gib die E-Mail-Adresse ein, die du bei der Einrichtung deines Accounts verwendet hast, damit wir dir einen Link zum Zurücksetzen des Passworts schicken können</p>
                                    <div className="input-container">
                                            <p style={{ fontFamily: "Good Headline Pro" }}>*Pflichtfeld</p>
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="email">Email</label>
                                            <input className="form-input__input js-event-type-in js-event-ab" type="text" name="email" id="email" placeholder="E-Mail*" data-require="true" data-type="regex" data-error-target="#error-oldPassword"  data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$" data-pattern-message="" data-event-fieldname="email" data-event="email" data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail." data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."  data-error-target="#error-emailname" data-action="email" data-label="Type In" />
                                            <span id="error-emailname" className="form-input__error active"></span>
                                        </div>
                                    </div>
                                    {message}
                                    <div className="form-button">
                                        <button type="submit">
                                            <div className="btn__container">
                                                    <span className="btn__text" style={{ fontFamily: "Good Headline Pro" }}>PASSWORT ZURÜCKSETZEN</span>
                                            </div>
                                        </button>
                                    </div>      
                                </div>
                                </form>
                            </div>
                        </div>
                </div>           
               {authorization}
               {loaderGeneralError}
               {loaderSubmit}
               {messageSent}
               </div>
               <Footersocial/>
            </div>
        );
     }
}

export default withTranslation()(withRouter(ResetPassword));