import React from "react";
import { withTranslation } from "react-i18next";
// import Aux from '../../hoc/Auxiliare';

import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import './faq.scss'

class SommerFAQ extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        }
        this.resize = this.resize.bind(this);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        window.addEventListener("resize", this.resize);
        this.resize();
        console.log(this.state.isMobile)
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div id="buzzer-faqs" className="buzzer-game">
                {this.faqContent(this.props)}
                <Footersocial />
            </div>
        );
    }

    faqContent(props) {
        const iconPlus = "/resources/imagesBundesliga/buzzer/plus.png";
        const iconMinus = "/resources/imagesBundesliga/buzzer/minus.png";
        return (
            <div className="sommer content-faq ">
                <div className="content-faq__item">
                    <h6>Wie läuft das Gewinnspiel ab?</h6>
                    <img src={iconMinus} className="content-faq__img notshown" 
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_1" 
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content show">
                        <ul>
                            <li>
                                Teilnahme erfolgt durch Kauf von Produkten der Marke Milka und/oder TUC
                                im Zeitraum 01.04.2024 bis 30.06.2024 sowie Eingabe des Produktcodes (auf
                                der Produktverpackung), des Händlers/Kaufdatums auf <a href="https://www.buzzer.fcmilka.de">www.sommer.fcmilka.de</a> bis zum 30.06.2024.
                            </li>
                            <li>
                                Bitte Original-Kaufbeleg zum Nachweis aufbewahren. Online-Registrierung
                                erforderlich.
                            </li>
                        </ul>
                    </div>
                </div>



                <div className="content-faq__item">
                    <h6>Mit welchen Produkten kann ich teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_2"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Die Aktion ist gültig für alle Produkte der Marken Milka und TUC.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Wo finde ich die Produkte für das Gewinnspiel?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_3"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Du findest die Produkte überall dort im Handel, wo du auch sonst deine Lieblingsprodukte von Milka und TUC findest.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich mehrere Produktcodes hochladen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_4"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Ja, Mehrfachteilnahmen mit neuem Produktcode innerhalb der Aktionsprodukte
                            sind erlaubt. Max. ein Preis pro Person im Gewinnspielzeitraum.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Wie lange kann ich an der Aktion teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_5"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Du hast vom 01.04.2024 bis 30.06.2024 die Möglichkeit, deinen Produktcode
                            einzugeben. Es nehmen aber nur Aktionsprodukte teil, die in diesem Zeitraum
                            gekauft wurden.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann jeder an der Aktion teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_6"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Teilnehmen kann jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland.
                            Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen
                            sowie jeweils deren Angehörige.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>In welchen Ländern kann man teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_7"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Die Teilnahme ist in Deutschland möglich.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Was kann gewonnen werden?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_8"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Insgesamt gibt es folgende Gewinne im Aktionszeitraum:
                        </p>
                        <ul>
                            <li>
                                1x Triff einen Star des FC Bayern München (inkl. An- und Abreise und
                                Übernachtung für 2 Personen mit Verpflegung und attraktivem
                                Rahmenprogramm)
                            </li>
                            <li>
                                20x 1 Fan-Erlebnis mit 4 deiner Freunde in Berlin (inkl. attraktivem
                                Rahmenprogramm, An- und Abreise, 2 Hotelübernachtungen und
                                Verpflegung)
                            </li>
                            <li>
                                64x 2 Dauerkarten Bundesliga oder 2. Bundesliga der Saison 2024/25 (Werte
                                aller Tickets variieren je nach Club, Auswahl nach Verfügbarkeit; kein Anspruch auf
                                Clubauswahl und/oder Bundesliga oder 2. Bundesliga)
                            </li>
                            <li>
                                8x 1 TUI Online-Reisegutschein im Wert von 3.000 €
                            </li>
                            <li>
                                100x 1 11teamsports Gutschein im Wert von 100 €
                            </li>
                            <li>
                                500x 1 BILDplus Jahresabonnement
                            </li>
                            <li>
                                2.300x 1 SPORT BILD Bundesliga 2024/2025 Sonderheft
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie erfahre ich, ob ich gewonnen habe?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_9"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Die Gewinnermittlung erfolgt wöchentlich innerhalb des Gewinnspielzeitraumes
                            per Zufallsprinzip. Die Gewinnermittlung des „Treffens mit einem Star“ findet am
                            Ende der Promotion unter allen Teilnehmern statt. Alle Gewinner werden per
                            E-Mail informiert.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich meine Daten löschen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_10"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Nach Ende der Aktion werden alle Teilnehmerdaten gelöscht. Soweit diese aus
                            gesetzlichen Gründen nicht gelöscht werden dürfen, werden deine Daten gesperrt
                            und nach Ablauf der handels- und steuerrechtlichen Aufbewahrungsfrist gelöscht.
                            Aber falls du deine Daten bereits während des Aktionszeitraums löschen möchtest,
                            ist das natürlich möglich. Dazu wendest du dich bitte direkt an
                            unseren <a href="https://contactus.mdlzapps.com/milka/contact/de-DE/">Verbraucherservice*</a>. Eine Teilnahme am Gewinnspiel ist dann leider nicht
                            mehr möglich.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>An wen kann ich mich wenden, wenn ich weitere Fragen habe?</h6>
                    <img src={iconPlus} className="content-faq__img notshown"
                        data-event="faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_11"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Vielleicht hast du noch weitere Fragen, die hier nicht beantwortet worden sind.
                            Dann wende dich am besten direkt an unseren <a href="https://contactus.mdlzapps.com/milka/contact/de-DE/">Verbraucherservice*</a>.
                        </p>
                        <p>
                            *Falls der hier hinterlegte Link zum Verbraucherservice nicht funktioniert, kannst
                            du über den Kontaktlink (@-Zeichen) in der Meta-Navigation auf <a href="https://milka.de/">„milka.de“</a> den
                            Verbraucherservice erreichen.
                        </p>
                    </div>
                </div>

            </div>
        );
    }

    showItemContent(e, iconMinus, iconPlus) {
        let classes = Array.from(e.target.classList);
        let itemContent = e.target.parentNode.children[2];

        if (classes.includes("notshown")) {
            e.target.setAttribute('src', iconMinus);
            e.target.classList.remove("notshown");
            e.target.classList.add("shown");
            itemContent.classList.add("show");
            appGoogleTracking.processEventCTANavLink(e);
        } else {
            e.target.setAttribute('src', iconPlus);
            e.target.classList.remove("shown");
            e.target.classList.add("notshown");
            itemContent.classList.remove("show");
        }
    }
}

export default withTranslation()(SommerFAQ);