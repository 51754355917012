import React, { useEffect, useState} from "react";
import _LOCAL from "../../../Models/LocalStorageKeys";
import _TICKET_TYPES from "../../../Models/TicketTypes";
import GLOBAL_CONFIG from "../../../config/config";

const FanCenterSCBanner = (props) => {
    const [confirmationPrefix, setConfirmationPrefix] = useState("");
    const [categorieImg, setCategorieImg] = useState(props.categorieImg ? props.categorieImg :"cat");
    const category = props.category;

    const basePath = "/resources/imagesBundesliga/fanCenter/banner/tickets";
    const images = {
        desktop : `banner${category ? category :confirmationPrefix}.png`,
        mobile : `banner${category ? category :confirmationPrefix}-sm.png`,
    }

    const banner = {
        desktopImage : `${basePath}/${categorieImg}/${images.desktop}`,
        mobileImage : `${basePath}/${categorieImg}/${images.mobile}`
    }
  

    const isConfirmationImg = () => {
        const pathname = window.location.pathname.toLowerCase();

        return (
                pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.confirmation.toLowerCase()
            ||  pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.companion.confirmation.toLowerCase()  
            ||  pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend.toLowerCase()  
        );
    };

    useEffect(()=> {
        const ticketCategory = localStorage.getItem(_LOCAL.TicketType);
        if(!!ticketCategory && ticketCategory === _TICKET_TYPES.VIP){
            setCategorieImg("vip");
        }
        if(!!ticketCategory && ticketCategory === _TICKET_TYPES.TRICKOTSIGNED){
            setCategorieImg("trickotsigned");
        }
        if(!!ticketCategory && ticketCategory === _TICKET_TYPES.TRICKOTUNISIGNED){
            setCategorieImg("trickotunsigned");
        }
        if(isConfirmationImg()){
            setConfirmationPrefix("-confirmation");
        }
    }, []);

    return (
        <div className="fancenter-banner">
            <picture>
                <source srcSet={ banner.desktopImage } media= "(min-width: 1024px)" />
                <source srcSet={ banner.mobileImage } media= "(min-width: 481px)" />
                <source srcSet={ banner.mobileImage } media= "(min-width: 200px)" />
                <img className="fancenter-banner__image"src={ banner.mobileImage } alt= "Milka FanCenter" />
            </picture>
        </div>
    );
}

export default FanCenterSCBanner;
