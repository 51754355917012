import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import GLOBAL_CONFIG from '../../config/config';
import NavigationAccount from '../NavigationAccount/NavigationAccount';
import { servicePath, mainLoaderToggle, optionListConstruct, timeStamp, getCookie } from '../../modules/helpers';
import axios from 'axios';
import DropdownClub from '../../components/Content/DropdownClub/DropdownClub';
//import MenuBarAccount from '../MenuBarAccount/MenuBarAccount';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';

import ValidateForm from '../../modules/validate';
import { _ } from 'core-js';
import { withRouter } from 'react-router';
import appGoogleTracking from '../../modules/googletracking';
class LoginDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            milka: true,
            tokenLogin: null,
            teamImage: null,
            teamName: null,
            loaderSubmit: null,
            loaderGeneralError: null,
            TeamSelected: null
        };
    }

    componentWillMount() {
        // console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
        if (getCookie(GLOBAL_CONFIG.Session.tokenstatus)) {
            let getLoginDetailURL = servicePath({
                ...GLOBAL_CONFIG.ServiceSettings,
                status: 'details'
            });
            let tokenInfo = getCookie(GLOBAL_CONFIG.Session.tokenstatus)
            console.log(tokenInfo);
            axios({
                method: 'POST',
                url: getLoginDetailURL,
                data: {
                    "token": tokenInfo
                },
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    console.log(response)
                    let _data = response.data;
                    if (_data.success) {
                        if(_data.favclub == ""){
                            _data.favclub = null;
                        }
                        this.setState({
                            tokenLogin: response.data.token,
                        });

                        // this.props.history.push({pathname: GLOBAL_CONFIG.Route.loginDetail});
                        if (_data.isFcAccUser === true) {
                            this.setState({
                                milka: true,
                                teamImage: _data.favclub,
                                // profileImage:_data.ProfilePicture,
                                profileImage: _data.profileUrl,
                                teamName: _data.favclub
                            })
                            console.log("fc account true");
                            let imgSrc = "/resources/imagesBundesliga/fcAccount/profile.png";
                            if (_data.profileUrl != "") {
                                imgSrc = _data.profileUrl;
                                console.log(_data.profileUrl);
                            }
                            if (imgSrc == "NYU") {
                                imgSrc = "/resources/imagesBundesliga/fcAccount/profile.png";
                            }
                            // else{
                            //     imgSrc = "/resources/imagesBundesliga/fcAccount/profile.png";
                            // }
                            var image = document.getElementById('profileImage');
                            image.src = imgSrc;

                        }
                        else if (_data.isFcAccUser === false) {
                            console.log("fc account true");
                            this.setState({
                                milka: false,
                                teamImage: _data.favclub,
                                // profileImage:_data.ProfilePicture,
                                profileImage: _data.profileUrl,
                                teamName: _data.favclub
                            })
                            let imgSrc = "/resources/imagesBundesliga/fcAccount/profile.png";
                            if (_data.profileUrl != "") {
                                imgSrc = _data.profileUrl;
                            }
                            if (imgSrc == "NYU") {
                                imgSrc = "/resources/imagesBundesliga/fcAccount/profile.png";
                            }
                            // else{
                            //     imgSrc = "/resources/imagesBundesliga/fcAccount/profile.png";
                            // }
                            const imageSrcLogin = document.getElementById('profileImage');
                            console.log(imageSrcLogin);
                            if(imageSrcLogin){
                                imageSrcLogin.src = imgSrc;
                            }
                            console.log("fc account false");
                            mainLoaderToggle('hide');
                            console.log(this.state.milka);
                        }
                    }

                })
                .catch((response) => {
                    console.log(response);
                    console.log("catch getprofile loginDEtails")
                    //this.props.history.push({ pathname: GLOBAL_CONFIG.Route.loginDetail });
                });
        }
        else {
            this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
        }
    }

    componentDidMount() {
        console.log('this.state.milka',this.state.milka)
        mainLoaderToggle('hide');
        // ValidateForm.validateField('frm_updateProfile'); 

    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }
    logout = () => {
        console.log("logout")
        appGoogleTracking.dataLayerPush({
            'dataEvent': 'user_logout',
            'dataCategory': 'Inline Menu',
            'dataAction': 'Click on Logout ',
            'dataLabel': 'ENG_AD_NAVBAR'
        })
        if (getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null) {
            console.log("nor null");
            sessionStorage.setItem(GLOBAL_CONFIG.Session.tokenstatus, null);
            document.cookie = GLOBAL_CONFIG.Session.tokenstatus + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            //document.cookie = GLOBAL_CONFIG.Session.tokenstatus + '=' + null + ';domain=' + GLOBAL_CONFIG.DomainName;
            console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
            this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
        }
        this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
    }
    //Close error message popup
    onClosePopupHandler = () => {
        this.setState({
            loaderError: false,
            loaderGeneralError: false,
            authorization: false
        });
    }
    //Form submit event
    updateProfile = (event) => {
        // console.log("sds");
        event.preventDefault();
        let validateFormInfo = ValidateForm.validateForm('frm_updateProfile');
        if (validateFormInfo === true) {
            this.processFormData();
        }

    }
    // //Form submit event
    // updateProfileMilka = (event) => {
    //     // console.log("sds");
    //     event.preventDefault();
    //     let validateFormInfo = ValidateForm.validateForm('frm_updateProfile');
    //     if(validateFormInfo=== true){
    //         this.processFormDataMilka();
    //     }  

    // }
    
    //huhu_validation size 
    handleBackgroundChangeProfile = (e) => {
        const file = e.target.files[0];
        const maxSizeInBytes = 3 * 1024 * 1024; // 3 Mo
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);

        const fileConditionParagraph = document.getElementById('fileCondition');

        if (!allowedExtensions.includes(fileExtension)) {
            console.log('Erreur : Type de fichier non pris en charge. Veuillez sélectionner un fichier JPG, JPEG ou PNG.');
            fileConditionParagraph.style.color = 'red';
            return;
        }
        if (file.size > maxSizeInBytes) {
            console.log('Erreur : La taille du fichier est trop grande.');
            fileConditionParagraph.style.color = 'red';
            return;
        }
        else{
            fileConditionParagraph.style.color = 'white';
            var image = document.getElementById('profileImage');
            image.src = URL.createObjectURL(file);
            if (document.getElementById('file_to_upload').files[0] != null) {
                console.log("not null")
                document.getElementById('memberFC').setAttribute("data-require", true);
            }
        }
    }
    handleBackgroundChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        // console.log(reader.readAsDataURL(file))
        var image = document.getElementById('profileImage');
        image.src = URL.createObjectURL(e.target.files[0]);
        console.log(URL.createObjectURL(e.target.files[0]));
        if (document.getElementById('file_to_upload').files[0] != null) {
            console.log("not null")
            document.getElementById('member').setAttribute("data-require", true);
        }
    }
    
    FavouriteClubSelection( team ) {
        if(this.state.milka === true){
            this.googleTag('team_select', 'Login Details Page', 'Select Team', this.state.teamName)
        }
        else{
            this.googleTag('team_select', 'Account Upgrade Page', 'Select Team', this.state.teamName)
        }

        this.setState({
            TeamSelected: team,
            ErrorMessage: ""
        });
        const team1 = document.getElementById("team1");
        if(team1){
            ValidateForm.validateProcess(team1);
        }
    }

    //Process form when Captcha is OK
    processFormData = () => {
        //Open loader
        this.setState({
            loaderSubmit: true
        });
        // token=sessionStorage.getItem(GLOBAL_CONFIG.Session.loginStatus) 
        //Process form data
        let data = {
            ProfilePicture: document.getElementById('file_to_upload').files[0],
            // team1:                 document.getElementById('team1').value,
            token: getCookie(GLOBAL_CONFIG.Session.tokenstatus),
        };
        //team1
        let team1 = document.getElementById('team1').value;
        if (team1 === "default") {
            data.team1 = document.getElementById('team1').options[0].text
        }
        else {
            data.team1 = document.getElementById('team1').value;
        }
        //Get webservice path
        let getUpdateProfileURL = servicePath({
            ...GLOBAL_CONFIG.ServiceSettings,
            campaign: this.state.promotionCampaign,
            status: 'updateProfile'
        });
        let formData = new FormData();

        for (let key in data) {
            formData.append(key, data[key]);
        }
        //Ajax call via axios
        axios({
            method: 'post',
            url: getUpdateProfileURL,
            data: formData,
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                console.log(response)
                if (response.status === 200 || response.status === 201) {
                    if (response.data.success === true) {
                        //Set user participation
                        // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, 'participate'); 
                        // let userStatus = response.data.data.IsLogin? 'win' : 'lose';
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 'save_information',
                            'dataCategory': 'Login Details Page',
                            'dataAction': 'Click to Save',
                            'dataLabel': 'ENG_LD_OTHER'
                        })
                        let loginstatus;
                        //Set user as Winner or Loser
                        sessionStorage.setItem(GLOBAL_CONFIG.Session.loginstatus, loginstatus);    //win or lose
                        this.setState({
                            loaderSubmit: false
                        })
                        //Go to Thank you page
                        this.props.history.push({ pathname: GLOBAL_CONFIG.Route.loginDetail });

                    } else {
                        let errorStatus = response.data.data.Error[0];
                        // this.recaptcha.reset();     //Reset captcha when error occurs - deploy
                    }
                } else {
                    //Show error messages
                    console.log('Error 404 or 500');
                    //Close Loader
                    this.setState({
                        loaderSubmit: false,
                        loaderGeneralError: true
                    });

                    // this.recaptcha.reset();     //Reset captcha when error occurs
                }
            })
            .catch((response) => {
                //handle error
                // //Close Loader
                this.setState({
                    loaderSubmit: false,
                    loaderGeneralError: true,
                });
                //  this.props.history.push({pathname: GLOBAL_CONFIG.Route.loginDetail});
                // this.recaptcha.reset();     //Reset captcha when error occurs
            });
    }
    upgradeProfile = (event) => {
        // console.log("sds");
        event.preventDefault();
        this.googleTag('save_information', 'Account Upgrade Page', 'Click to Save','ENG_AU_OTHER');
        // appGoogleTracking.processEventCTA(event);
        let validateFormInfo = ValidateForm.validateForm('frm_updateProfile');
        if (validateFormInfo === true) {
            this.processFormDataUpgrade();
        }

    }
    processFormDataUpgrade = () => {
        this.setState({
            loaderSubmit: true
        });
        // token=sessionStorage.getItem(GLOBAL_CONFIG.Session.loginStatus) 
        //Process form data
        let data = {
            ProfilePicture: document.getElementById('file_to_upload').files[0],
            token: getCookie(GLOBAL_CONFIG.Session.tokenstatus),
        };
        //team1
        let team1 = document.getElementById('team1').value;
        if (team1 === "default") {
            data.team1 = document.getElementById('team1').options[0].text
        }
        else {
            data.team1 = document.getElementById('team1').value;
        }
        //Get webservice path
        let getUpdateProfileURL = servicePath({
            ...GLOBAL_CONFIG.ServiceSettings,
            campaign: this.state.promotionCampaign,
            status: 'upgradeAccount'
        });
        let formData = new FormData();

        for (let key in data) {
            formData.append(key, data[key]);
        }
        //Ajax call via axios
        axios({
            method: 'post',
            url: getUpdateProfileURL,
            data: formData,
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200 || response.status === 201) {
                    if (response.data.success === true) {
                        //Set user participation
                        // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, 'participate'); 
                        // let userStatus = response.data.data.IsLogin? 'win' : 'lose';

                        let loginstatus = "1";
                        //Set user as Winner or Loser
                        sessionStorage.setItem(GLOBAL_CONFIG.Session.loginstatus, loginstatus);    //win or lose
                        this.setState({
                            loaderSubmit: false
                        })
                        //Go to Thank you page
                        this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });

                    } else {
                        let { message } = response.data;
                        let errorStatus = message;
                        // this.recaptcha.reset();     //Reset captcha when error occurs - deploy
                        this.setState({
                            loaderSubmit: false,
                            loaderGeneralError: true
                        });
                    }
                } else {
                    //Show error messages
                    console.log('Error 404 or 500');
                    //Close Loader
                    this.setState({
                        loaderSubmit: false,
                        loaderGeneralError: true
                    });

                    // this.recaptcha.reset();     //Reset captcha when error occurs
                }
            })
            .catch((response) => {
                console.log(response);
                //handle error
                // //Close Loader
                this.setState({
                    loaderSubmit: false,
                    loaderGeneralError: true,
                });
                //  this.props.history.push({pathname: GLOBAL_CONFIG.Route.loginDetail});
                // this.recaptcha.reset();     //Reset captcha when error occurs
            });
    }
    render() {
        let submitLoader = null,
            errorLoader = null,
            errorLoaderGeneral = null;

        // if(this.state.loaderSubmit === false){
        //     submitLoader = (
        //         <div className="simple-loader__container">
        //             <div className="simple-loader__indicator"></div>
        //         </div>  
        //     );
        // }

        if (this.state.loaderError === true) {
            errorLoader = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler} data-event="button-click" data-category="Click Action" data-action="receiptUpload" data-label={timeStamp()}></div>
                        <p className="overlay__title">Du hast bereits 3x an der Aktion teilgenommen.</p>
                        <p id="js-error-msg">Über den gesamten Gewinnspielzeitraum ist eine dreimalige Teilnahme mit jeweils neuem gültigem Kassenbon erlaubt. Eine weitere Teilnahme ist leider nicht möglich.</p>
                        <div className="overlay__footer">
                            <p>Du möchtest wissen, welche Neuigkeiten es bei Milka gibt?</p>
                            <a href="https://www.milka.de/neues" target="_blank" rel="noopener noreferrer">
                                <div className="btn__container btn--primary">
                                    <span className="btn__text">NEUES VON MILKA</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.loaderGeneralError === true) {
            errorLoaderGeneral = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                        <p id="js-error-msg">Ein Fehler ist aufgetreten.<br />Bitte versuche es später noch einmal.</p>
                    </div>
                </div>
            );
        }
        let milka = null
        const { t } = this.props;
        let footballClubContent = optionListConstruct(t('Page.Participation.form.footballClub.footballClubListName', { returnObjects: true }), this.state.teamImage);
        if (this.state.milka === true) {
            milka = (
                <div className="profile_detail">
                    <div className="left">
                        <NavigationAccount />
                        <button className="btnLogout logout-lg" onClick={this.logout}>
                            <span className="btn__logout">Ausloggen</span>
                        </button>
                    </div>
                    <div className="form-container grid-8-m  grid-s-2 logout-sm">
                        {/* <NavLink to={GLOBAL_CONFIG.Route.account} exact data-event="info-click" data-category="Informational Action" data-action="T&amp;C" >
                        <span className="btn__logout">Ausloggen</span>
                        </NavLink> */}
                        <button className="btnLogout" onClick={this.logout}>
                            <span className="btn__logout">Ausloggen</span>
                        </button>

                    </div>
                    <div className="right">
                        <form id="frm_updateProfile" name="frm_updateProfile" method="post" action="/" noValidate>
                            <div className="content-upload-receipt form-line row ">
                                <div className="form-container grid-8-m  grid-s-2">
                                    <div className="choose_team dropdown_space">
                                        <h2>Mein FC Milka Account</h2>
                                        <DropdownClub
                                            defaultImg={this.state.teamImage}
                                            divClass={"form-input__container dropdown-input__container form-input__dropdown full-width btn-bottom"}
                                            class="team-picture"
                                            id="team1"
                                            title="Wähle deinen Lieblingsverein*"
                                            image={t('Page.Participation.form.footballClub.images.image1')}
                                            defaultValue={this.state.teamName}
                                            team="team1"
                                            content={parse(footballClubContent)}
                                            setFavouriteTeam={ this.FavouriteClubSelection.bind(this)}
                                            //tag inside FavouriteClubSelection
                                            />
                                        <div className="notice-regardlessTeam">
                                            <p id="text-underTeam" className="text-regardlessTeam">
                                                Die Gewinnvergabe erfolgt unabhängig von deiner Clubauswahl.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                    <div className="form-container grid-8-m  grid-s-2 logout-md">
                                        <NavLink to={GLOBAL_CONFIG.Route.account} exact data-event="info-click" data-category="Informational Action" data-action="T&amp;C" >
                                    <span className="btn__logout">Ausloggen</span>
                                    </NavLink> 
                                                    <button className="btnLogout" onClick={this.logout}>
                                            <span className="btn__logout">Ausloggen</span>
                                        </button>

                                    </div>
                                </div>*/}
                            </div>
                            <div className="content-upload-receipt form-line row last">
                                <div className="form-container grid-5-m  grid-s-2">
                                    <div className="choose_team">
                                        <div className="profile_picture">
                                            <div className="profile-img_container">
                                              <img id="profileImage" src="/resources/imagesBundesliga/fcAccount/profile.png"></img>
                                            <div className="form-container">
                                                <label
                                                    className="btn__container  custom-file-btn pictureUpload"
                                                    htmlFor="file_to_upload"
                                                >
                                                    <span className="desktop-only">Bild hochladen</span>
                                                    <input
                                                    type="file"
                                                    id="file_to_upload"
                                                    
                                                    onChange={this.handleBackgroundChangeProfile}
                                                    name="file_to_upload"
                                                    data-type="file"
                                                    data-error-target="#error-file"
                                                    data-oversize-error="Ungültige Dateigröße. Bitte Dateigröße Vorgabe beachten."
                                                    data-format-error="Ungültiges Dateiformat. Bitte nur gültiges Format hochladen."
                                                    />
                                                </label>
                                            </div>
                                            </div>
                                            <div className="img-type">
                                                <p><span className="heading-choose_team">Lade dein Profilbild hoch</span></p>
                                                <p id='fileCondition'>(jpg oder png, max. 3 MB)</p>
                                            </div>
                                        </div>
                                        <div className="form-container results">
                                            <span className="form-input__error js-file-error"></span>
                                            <span className="form-input__success js-file-success"></span>
                                        </div>
                                        <div className="form-container results">
                                            <span className="form-input__error js-file-error"></span>
                                            <span className="form-input__success js-file-success"></span>
                                        </div>
                                        <div className="input-container zero_width">
                                            <label className="custom-inputs" htmlFor="memberFC">
                                                <input type="checkbox"
                                                 id="memberFC"
                                                  name="memberFC"
                                                   className="js-event-type-in js-event-ab"
                                                    data-require="false"
                                                     data-type="checkbox"
                                                      data-error-target="#error-memberFC"
                                                       data-required-message="Bitte bestätige deine Rechte an dem hochgeladenen Bild."
                                                        data-event-fieldname="memberFC" 
                                                        onChange={() => this.googleTag('checkbox_photo_disclaimer', 'Login Details Page', 'Click on Checkbox','GAME_LD_FORM')}
                                                        />
                                                <span className="text-content justify style-introvogue">
                                                    Hiermit erkläre ich in Bezug auf das Bild, dass ich der Fotograf oder Inhaber des vollumfänglichen Nutzungsrechts bin und das Bild im Rahmen dieser Aktion genutzt werden darf.
                                                </span>
                                                <span id="error-memberFC" className="form-input__error active"></span>
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className="heading__image">
                                                <img src="/resources/imagesBundesliga/fcAccount/logos.png"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="form-container grid-7-m  grid-s-2 space-up">
                                    <div className="form-container">
                                        <div className="input-container zero_width">
                                            <label className="custom-inputs" htmlFor="memberFC">
                                                <input type="checkbox" id="memberFC" name="memberFC" className="js-event-type-in js-event-ab" data-require="false" data-type="checkbox" data-error-target="#error-memberFC" data-required-message="Bitte bestätige deine Rechte an dem hochgeladenen Bild." data-event-fieldname="memberFC" data-event="form-click" data-category="Form Action" data-action="member" data-label="Type In" />
                                                <span className="text-content justify style-introvogue">
                                                    Hiermit erkläre ich in Bezug auf das Bild, dass ich der Fotograf oder Inhaber des vollumfänglichen Nutzungsrechts bin und das Bild im Rahmen dieser Aktion genutzt werden darf.
                                                </span>
                                                <span id="error-memberFC" className="form-input__error active"></span>
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className="heading__image">
                                                <img src="/resources/imagesBundesliga/fcAccount/logos.png"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="form-button updateProfile">
                                <button className="noSpace btn__container btn--red" onClick={this.updateProfile}>
                                    ANGABEN SPEICHERN
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

            )
        }
        else if (this.state.milka === false) {
            milka = (
              <div className="profile_detail account_upgrade">
                <form
                  id="frm_updateProfile"
                  name="frm_updateProfile"
                  method="post"
                  action="/"
                  noValidate
                >
                  <div className="content-upload-receipt form-line row ">
                    <div className="grid-8-m  grid-s-2">
                      <div className="milka_de_account">
                        <h1>Mein FC Milka Account</h1>
                        <p className="milka-de_account">
                          Du hast bereits einen Milka Account und möchtest nun
                          auch<br/>kostenlos Mitglied im FC Milka Club werden? Das
                          ist ganz einfach:
                        </p>
                        <ol>
                          <li>
                            Wähle deinen Lieblings-Bundesliga-Verein der 1. oder
                            2. Liga
                          </li>
                          <li>
                            Wenn du magst, lädst du noch ein Profilbild hoch
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div className=" grid-3-m  grid-s-2">
                      <img
                        className="milka-logo"
                        alt=""
                        src="/resources/imagesBundesliga/fcAccount/milkade-logo.png"
                      />
                    </div>
                  </div>
                  <div className="account spaceTeamButtom">
                    {/* <div className="s-content-partication">
                                <hr></hr>
                            </div> */}
                    <div className="separator"><hr></hr></div>
                    <div className="content-upload-receipt form-line row ">
                      <div className="form-container grid-3-m  grid-s-2">
                        <div className="choose_team dropdown_space">
                          <p>
                            <span className="heading-choose_team">
                              *Pflichtfelder
                            </span>
                          </p>
                          <DropdownClub
                            defaultImg="/resources/imagesBundesliga/team-picture/default.png"
                            divClass={
                              "form-input__container dropdown-input__container form-input__dropdown full-width-milka btn-bottom"
                            }
                            class="team-picture"
                            id="team1"
                            title="Wähle deinen Lieblingsverein*"
                            image="{t('Page.Participation.form.footballClub.images.image2')}"
                            defaultValue="Bitte wähle deinen Lieblingsverein aus."
                            team="team1"
                            content={parse(footballClubContent)}
                            setFavouriteTeam={this.FavouriteClubSelection.bind(
                              this
                            )}
                            //tag inside FavouriteClubSelection
                          />
                          <div className="notice-regardlessTeam">
                            <p
                              id="text-underTeam"
                              className="text-regardlessTeam"
                            >
                              Die Gewinnvergabe erfolgt unabhängig von deiner
                              Clubauswahl.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="separator"><hr></hr></div>
                      <div className="form-container grid-4-m  grid-s-2">
                        <div className="choose_team">
                          <div className="profile_picture">
                            <div className="profile-img_container">
                              <img
                                id="profileImage"
                                src="/resources/imagesBundesliga/fcAccount/profile.png"
                              ></img>
                              <div className="form-container">
                                <label
                                  className="btn__container  custom-file-btn pictureUpload"
                                  htmlFor="file_to_upload"
                                >
                                  <span className="desktop-only">
                                    Bild hochladen
                                  </span>
                                  <input
                                    type="file"
                                    id="file_to_upload"
                                    onChange={this.handleBackgroundChange}
                                    name="file_to_upload"
                                    data-type="file"
                                    data-error-target="#error-file"
                                    data-oversize-error="Ungültige Dateigröße. Bitte Dateigröße Vorgabe beachten."
                                    data-format-error="Ungültiges Dateiformat. Bitte nur gültiges Format hochladen."
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="img-type">
                              <p>
                                <span className="heading-choose_team">
                                  Lade dein Profilbild hoch
                                </span>
                              </p>
                                <p id='fileCondition'>(jpg oder png, max. 3 MB)</p>
                            </div>
                          </div>
                          <div className="form-container results">
                            <span className="form-input__error js-file-error"></span>
                            <span className="form-input__success js-file-success"></span>
                          </div>
                          <div className="form-container results">
                            <span className="form-input__error js-file-error"></span>
                            <span className="form-input__success js-file-success"></span>
                          </div>
                          <div className="input-container zero_width">
                            <label className="custom-inputs" htmlFor="memberFC">
                              <input
                                type="checkbox"
                                id="memberFC"
                                name="memberFC"
                                className="js-event-type-in js-event-ab"
                                data-require="false"
                                data-type="checkbox"
                                data-error-target="#error-memberFC"
                                data-required-message="Bitte bestätige deine Rechte an dem hochgeladenen Bild."
                                data-event-fieldname="memberFC"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="member"
                                data-label="Type In"
                                onChange={() => this.googleTag('checkbox_photo_disclaimer', 'Account Upgrade Page', 'Click on Checkbox', 'GAME_AU_FORM')}
                              />
                              <span className="text-content justify style-introvogue">
                                Hiermit erkläre ich in Bezug auf das Bild, dass
                                ich der Fotograf oder Inhaber des
                                vollumfänglichen Nutzungsrechts bin und das Bild
                                im Rahmen dieser Aktion genutzt werden darf.
                              </span>
                              <span
                                id="error-memberFC"
                                className="form-input__error active"
                              ></span>
                              <span className="checkmark"></span>
                            </label>
                            {/* 
    <div className="heading__image">
        <img src="/resources/imagesBundesliga/fcAccount/logos.png"></img>
    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-button">
                    <button
                      className="btn__container btn--red"
                      onClick={this.upgradeProfile}
                    >
                      ANGABEN SPEICHERN
                    </button>
                  </div>
                </form>
                <div className="account-milka-text">
                  <h4>Deine Vorteile</h4>
                  <ul>
                    <li>
                      {" "}
                      Bei allen FC Milka Aktionen kannst du dich einfach
                      einloggen – so ist die Teilnahme noch komfortabler.
                    </li>
                    <li>
                      Als Mitglied im FC Milka Club informieren wir dich
                      exklusiv über alle News!
                    </li>
                    <li>
                      Es warten jede Menge tolle Überraschungen auf dich, du
                      darfst gespannt sein.
                    </li>
                  </ul>
                </div>
              </div>
            );
        }

        return (
            <div>
                {milka}
                {submitLoader}
                {errorLoader}
                {errorLoaderGeneral}
                <Footersocial/>
            </div>
        )
    }
    googleTag = (event, category, action, label) => {
        appGoogleTracking.dataLayerPush({
            'dataEvent': event,
            'dataCategory': category,
            'dataAction': action,
            'dataLabel': label
        })
    }
}
export default withTranslation()(withRouter(LoginDetail));