import React, { useState, useEffect, useRef } from "react";
// import ValidateForm from "../../../modules/validate";

import _LOCAL from "../../../../../Models/LocalStorageKeys";
import _BUZZER_MESSAGE from "../../../../../Models/BuzzerErrorMessage";
import _ERROR_MESSAGES from "../../../../../Models/ErrorMessages";
import GLOBAL_CONFIG from '../../../../../config/config';
import { withRouter } from 'react-router-dom';
import appGoogleTracking from "../../../../../modules/googletracking";
import ValidateForm from "../../../../../modules/validate";
import ParticipationService from "../../../../../Service/Participation";
import './PromoPopup.scss';
const PromoPopup = (props) => {
    const [modal, setModal] = useState({ ...props.modal });

    const [isMobile, setIsMobile] = useState(false);

    const [SimpleLoader, setSimpleLoader] = useState(false);
    const [ErrorMessage, SetErrorMessage] = useState("");
    const [CodeIsValid, SetCodeIsValid] = useState(true);
    const _PRODUCT_type = useRef(null);

    useEffect(() => {
        setModal(props.modal);
    }, [props.modal])

    useEffect(() => {
        const checkIsMobile = () => {
            const mobileWidthThreshold = 768;
            setIsMobile(window.innerWidth < mobileWidthThreshold);
        };
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    if (modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    function SubmitHandler(event) {
        event.preventDefault();
        const isValid = ValidateForm.validateForm('frm_participation')
        if (isValid) {
            ProcessForm(null);
        }
    }

    function ErrorHandler(Message) {
        switch (Message) {
            case _BUZZER_MESSAGE.PARTICIPATION_LIMIT:
                SetErrorMessage(_ERROR_MESSAGES.Buzzer.CODE_LIMIT);
                break;
            case _BUZZER_MESSAGE.CODE_INVALID:
                SetCodeIsValid(false);
                SetErrorMessage("Leider hast du den falschen Code eingegeben. Versuch’s noch mal!");
                break;
            case _BUZZER_MESSAGE.INVALID_CAPTCHA:
                SetErrorMessage("UNGÜLTIGE RECAPTCHA-ANTWORT");
                break;
            case _BUZZER_MESSAGE.REGISTRATION_FAILED:
                SetErrorMessage("E-MAIL BEREITS VERWENDET");
                break;

            default:
                SetErrorMessage("SERVERFEHLER");
                break;
        }
    }

    function ProcessForm(token) {
        //Process form data
        let _data = {
            Code: _PRODUCT_type.current.value
        };
        setSimpleLoader(true);
        ParticipationService.submitLotCodeHeimtrikkot(_data)
            .then((response) => {
                let { status, success, data } = response.data;
                setSimpleLoader(true);
                if (success) {
                    /**
                     * Sucess handler
                     */
                    localStorage.setItem(_LOCAL.ProductCode, _data.Code);
                    props.toggleModal();

                    //redirect to player selection page
                    props.history.push({ pathname: GLOBAL_CONFIG.Route.participation });
                } else {
                    if (!!data.Error && data.Error.length > 0) {
                        ErrorHandler(data.Error[0]);
                    } else {
                        SetErrorMessage("SERVER ERROR");
                    }
                }
            })
            .catch(() => {
                setSimpleLoader(true);
            })
    }

    return (
        <>
            {modal && (
                <div className="modal penny trikot-promo" style={{ zIndex: 11 }}>
                    <div onClick={() => { props.toggleModal() }} className="popup-overlay"></div>
                    <div className="modal-content" style={{ zIndex: 12, height: 'fit-content' }}>
                        <div className="modal-wrapper">
                            <div className="modal-heading reihemodal">
                                <h1>
                                    Produktcode eingeben und gewinnen!
                                </h1>
                                <div className="heading-content">
                                    <div className="secondary-text">
                                        <p>
                                            Du findest den Produktcode auf der Packungsrückseite. Gib nur die ersten 3 Buchstaben und die Ziffern vor dem „-“ in das entsprechende Feld ein, auch wenn der Code dann mit „-“... weitergeht.
                                        </p>
                                        <p>
                                            Hinweis: Befindet sich kein Produktcode auf dem Artikel, gib alternativ die Zahlen des Barcodes ein.
                                        </p>
                                    </div>
                                    <div className="heading-content__image">
                                        <picture>
                                            <source srcSet="/resources/imagesBundesliga/\trikot-promo/instruction.png" media="(min-width: 696px)" />
                                            <source srcSet="/resources/imagesBundesliga/\trikot-promo/instruction-sm.png" media="(min-width: 200px)" />
                                            <img className="product-code-instruction" src="/resources/imagesBundesliga/\trikot-promo/instruction-sm.png" media="(min-width: 200px)" alt="product instruction" />
                                        </picture>
                                    </div>
                                </div>
                            </div>

                            <form id="frm_participation"
                                name="frm_participation"
                                method="post" action="/"
                                onSubmit={(event) => { SubmitHandler(event) }} noValidate>

                                <div className="form-container">
                                    <div className="buzzer-form retailer-container__form">
                                        <div className="form-container">
                                            <div className="input-container">
                                                <div className="form-input__container">
                                                    <label className="form-input__label" htmlFor="product_type">Produktart</label>
                                                    <input className="form-input__input js-event-type-in js-event-ab"
                                                        type="text"
                                                        name="product_type"
                                                        id="product_type"
                                                        placeholder="Produktcode*"
                                                        data-require="true"
                                                        data-type="text"
                                                        maxLength="50"
                                                        data-error-target="#error-product-code"
                                                        data-required-message="Du hast noch keinen Produktcode eingegeben."
                                                        data-event-fieldname="Product Type is required."
                                                        data-event="form-click"
                                                        data-category="Form Action"
                                                        data-action="House number"
                                                        data-label="Type In"
                                                        ref={_PRODUCT_type}
                                                    />
                                                    <span id="error-product-code" className="form-input__error active notvalid"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="error-code">{ErrorMessage}</p>
                                <div className="rectifBtn">
                                    <button type="submit" className="btn__container btn--secondary-lilac" exact /* data-event="custom_event_click" data-category="click_action" data-action="participate" data-label="participate_pop_up" onClick={appGoogleTracking.processEventCTANavLink} */>JETZT MITMACHEN
                                    </button>
                                </div>
                            </form>
                        </div>



                        <div onClick={() => {
                            {/* setModal(false) */}
                            props.toggleModal();
                            appGoogleTracking.dataLayerPush({
                                'dataEvent': 'popup_productcode_close',
                                'dataCategory': 'Start Page',
                                'dataAction': 'Click on Close',
                                'dataLabel': 'GAME_SP_OTHER'
                            });
                        }}
                            className="close-modal">
                            <img src="/resources/imagesBundesliga/buzzer_22/ham-close.png" alt="Popup Close" />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}



export default withRouter(PromoPopup);