const _LOCAL = {
    AvailableTeams: "_TEAM_NAMES",
    AvailableTeamIDs: "_TEAM_IDS",
    GiftCategory: "_GIFT_CAT",
    UserDetails: "_EMAIL_MATCH",
    SelectedTeam: "_TEAM_SEL",
    SelectedSize: "_SIZE_SEL",
    JerseySizes: "_AVAILABLE_SIZE",
    Register: "_REG_USER",
    CurrentKip : "_CURRENT_KIP",
    CurrentSeasonId: "_CURRENT_SEASON",
    PreviousSeasonId: "_PREVIOUS_SEASON",
    FavoriteClub: "_FAV_CLUB",
    ProductCode: "_PRODUCT_CODE",
    PlayerValue: "_PLAYER_VALUE",
    TicketSelected: "_TICKET_SELECTED",
    UserId: "UID",
    TicketType: "_TICKET_TYPE",
    TrikotsList: "_TRIKOTS_LIST",
    MatchesList: "_MATCHS_LIST",
    SelectedTrikot: "_TRIKOT_SEL",
    ErrorTickets: "_TICKET_ERROR",
    ErrorTicketsType: "_TICKET_ERROR_TYPE",
    MatchSelected: "_MATCH_SEL",
    BuzzerParticipationDetails: "BUZZER_PART",
    TrikotParticipationDetails: "TRIKOT_PART",
    DoppeltParticipationDetails: "DOPPELT_PART",
    DoppeltWinPrize: "DOPPELT_WINPRIZE",
}

export default _LOCAL;