import React from "react";
import { NavLink } from 'react-router-dom';
import { mainLoaderToggle } from "../../../modules/helpers";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";

class Q4Prize extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillMount(){
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <>  
                <div id="buzzer-prizes" className="weltmeister-prizes">
                    { this.headingSection() }
                    { this.footerSection() }
                    <Footersocial />
                </div>
            </>
        );
    }

    headingSection() {
        return (
            <div className="campaign-prize-header">
                <h1>
                    Mit FC Milka gewinnen!
                </h1>
                <p>Bis zum 31.12. hast du die Chance ein Treffen der Extraklasse mit Lukas Podolski oder Rudi Völler zu gewinnen. Mach mit und gewinne mit etwas Glück!</p>
            </div>
        );
    }

    footerSection() {
        return(
            <div className="weltmeister campaign-footer">
                <div className="weltmeister-card">
                    <div className="weltmeister-card__content primary">
                        <img src="/resources/imagesBundesliga/weltmeister/card/Lukas.png" alt="Gold im Wert"/>
                        <h3>Verbringe einen Tag mit Lukas Podolski in Köln</h3>
                        <p>Erlebe Lukas Podolski in der Stadt Köln! Du verbringst ein Erlebnis-Wochenende für je
2 Personen (Freitag bis Sonntag, inkl. An- und Abreise nach weiterer individueller
Absprache mit dem Gewinner, 2x Übernachtung im 5* Hotel, Verpflegung, attraktivem
Rahmenprogramm). Lukas Podolski wird einen Tag am Erlebnis-Wochenende
teilnehmen. Als echtes Highlight zeigt dir Lukas Podolski an einem Tag seine ganz
persönlichen Ecken in Köln.</p>
                    </div>
                    <div className="weltmeister-card__content secondary">
                        <img src="/resources/imagesBundesliga/weltmeister/card/Rudi.png" alt="Dauerkarten der Saison"/>
                        <h3>Verbringe einen Tag mit Rudi Völler in Rom</h3>
                        <p>Du verbringst einen unvergesslichen Tag mit Rudi Völler, der dir seine schönsten
Plätze der ewigen Stadt Rom zeigt! Dich erwartet ein Erlebniswochenende für je 2
Personen (Freitag bis Sonntag, inkl. An- und Abreise nach weiterer individueller
Absprache mit dem Gewinner, 2x Übernachtung im 5* Hotel, Verpflegung, attraktivem
Rahmenprogramm).</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Q4Prize;