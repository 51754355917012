import React from "react";
import { Route, Switch } from 'react-router-dom';     
import GLOBAL_CONFIG from "../../../config/config";
import Error from "../../Error/Error";
import ForMeOrFriend from "../../FanCenter/Tickets/ForMeOrFriend";
import FavoriteClub from "../../FanCenter/Tickets/FavoriteClub";
import TeamSelection from "../../FanCenter/Tickets/TeamSelection";
import UserDetails from "../../FanCenter/Tickets/UserDetails";
import Confirmation from "../../FanCenter/Tickets/Confirmation";

import UserDetailsCompanion from "../../FanCenter/Tickets/Companion/UserDetailsCompanion";
import ConfirmationCompanion from "../../FanCenter/Tickets/Companion/ConfirmationCompanion";
import ConfirmationFriend from "../../FanCenter/Tickets/ConfirmationFriend";
import Faq from "../../FanCenter/Faq/Faq";

const TicketsRoutes = (props) => {
    return (
        <Switch>
            <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend} component={()=> (<ForMeOrFriend {...props} />)}/>
            <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.favoriteClub} component={()=> (<FavoriteClub {...props} />)}/>
            <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.teamSelection} component={()=> (<TeamSelection {...props} />)}/>
            <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.userDetails} component={()=> (<UserDetails {...props}/>)}/>
            <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.confirmation} component={()=> (<Confirmation {...props} />)}/>
            {/** ticket for friend */}
            <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend} component={()=> (<ConfirmationFriend {...props} />)}/>
            {/** Companion */}
            <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.companion.userDetails} component={()=> (<UserDetailsCompanion {...props}/>)}/>
            <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.companion.confirmation} component={()=> (<ConfirmationCompanion {...props} />)}/>
            <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faq} component={()=> (<Faq {...props} />)}/>
            <Route path="*" component={Error} />
        </Switch>
    );
}

export default TicketsRoutes;