const _ERROR_MESSAGES = {
    NotWinner: "EMAIL_NOT_WINNER",
    Expired: "CODE_EXPIRED",
    CaptchaError: "INVALID_RECAPTCHA_RESPONSE",
    TICKET_GAMEWEEK_EXPIRED : "Ticket spielwoche abgelaufen",
    Used: "Der eingegebene Code wurde bereits verwendet.",
    CodeInvalid: "Huch – da hat sich wohl ein Fehler eingeschlichen. Überprüfe…deine Code-Eingabe und versuche es noch einmal.",
    Terms: "Bitte akzeptiere die Teilnahmebedingungen, um fortzufahren.",
    Buzzer: {
        INVALID_CODE: " „Ups. Der Code ist leider falsch – bitte gib ihn neu erneut ein. Achte gut auf die Unterscheidung zwischen der Ziffer „0“ und dem Buchstaben „O“.",
        CODE_LIMIT: "Du hast heute bereits 5x teilgenommen. Versuche es morgen mit deinem nächsten Einkauf erneut.",
        CODE_LIMIT_GAMENOTALIVE: "Vielen Dank für deine Teilnahme, dein Glück ist für heute aufgebraucht. Bitte versuche es mit deinem nächsten Einkauf ab morgen erneut."
    },
    Account: {
        INACTIVE: "E-Mail wurde nicht aktiviert"
    },
    Ticket: {
        Unvailable: "Ui, da war jemand schneller, die Tickets sind nicht mehr verfügbar. Wähle direkt ein anderes Spiel aus!"
    },
    Details: {
        EmailDuplicate: "Bitte verwende für deine Begleitperson eine andere E-Mail-Adresse als für dich.",
        NotUnique: "Please insert different Firstname/Lastname/DoB than first Winner."
    }
};

export default _ERROR_MESSAGES;