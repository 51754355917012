import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import Aux from '../../hoc/Auxiliare';
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle  } from '../../modules/helpers';
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import _SCROLL_PAGE from "../../modules/ScrollPage";
import { connect } from "react-redux";
import { setIsBannerShown  } from "../../store/banner";
import { isPennyMicrosite, isWeltmeisterPromotion, isTrikotPromotion } from '../../modules/helpers';

const ErrorModel = {

    contentTitle: {
        heading: parse('Hab noch ein wenig Geduld.<br />Die Aktion startet am 02.01.2024.')
    },
    holding: {
        contentTitle: {
            heading: parse('Hab noch ein wenig Geduld.<br />Die Aktion startet am 02.01.2024.')
        }
    }
};

class Error extends Component {

    componentDidMount(){
        const { t, setIsBannerShown } = this.props;
        setIsBannerShown(false);

        //Remove main loader
        mainLoaderToggle('hide');

        //Virtual page
        appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': '/',
                'pageTitle': t('Meta.title')
            }
        });
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    componentWillUnmount(){
        //Show mainloader
        mainLoaderToggle('show');
        setIsBannerShown(true);
    }

    render() {
        return (
            <div id="buzzer-error">
                <TitleContent 
                    heading={ window.PROMOTION === "holding" ? ErrorModel.holding.contentTitle.heading : ErrorModel.contentTitle.heading} 
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    bannerState: state.banner
});

const mapDispatchToProps = {
	setIsBannerShown
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Error));