import React from "react";
import { NavLink, withRouter } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import queryString from "query-string";
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import Loader from "../../../../components/Overlays/Loader";
import GLOBAL_CONFIG from "../../../../config/config";
import _ERROR_MESSAGES from "../../../../Models/ErrorMessages";
import _GIFT_MESSAGES from "../../../../Models/GiftErrorMessages";
import _GIFT from "../../../../Models/GiftTypes";
import _LOCAL from "../../../../Models/LocalStorageKeys";
import appGoogleTracking from "../../../../modules/googletracking";
import { mainLoaderToggle } from "../../../../modules/helpers";
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import ValidateForm from "../../../../modules/validate";
import FanCenterServices from "../../../../Service/FanCenter";
import JerseyStepIndicitor from "../StepIndicator";
import Match from "../../../../Models/Match";
import _STATUS from "../../../../Models/ParticipationSteps";
import _USER_TYPES from "../../../../Models/UserTypes";
import Aux from "../../../../hoc/Auxiliare";

class UserCodeEntry extends React.Component {
    constructor(props) {
        super();

        this.state = {
            IsMine: true,
            isSecondFriend: false,
            SimpleLoader: false,
            ErrorMessage: "",
            ConsumerId: "",
            OrderCode: "",
            isRemainingTicket : false,
            gameWeek : null
        }

        this.StepList = [
            {
                display: "Code-Eingabe",
                isInProgress: true,
                isComplete: false,
            },
            {
                display: "Lieblingsclub",
                isInProgress: false,
                isComplete: false,
            },
            {
                display: "Gewinnauswahl",
                isInProgress: false,
                isComplete: false,
            },
            {
                display: "Daten-Check",
                isInProgress: false,
                isComplete: false,
            }
        ];

        this.ProcessForm = this.ProcessForm.bind(this);
        this.ProcessDirectTicketSelection = this.ProcessDirectTicketSelection.bind(this);
        this.ProcessError = this.ProcessError.bind(this);
        
    }

    componentDidMount() {
        _SCROLL_PAGE.ScrollToBanner();
        const QueryString = queryString.parse(window.location.search);

        if(Object.keys(QueryString).includes("Ident_short")) {
            this.setState({
                gameWeek: QueryString.Ident_short,
                IsMine : true,
                isRemainingTicket : true
            });
        } else {
            this.setState({
                /**
                 * if URL contains queryString isCompanion, companion page is displayed 
                 */
                IsMine: !!QueryString.isCompanion ? !QueryString.isCompanion : true,
            });
        }

        if(Object.keys(QueryString).includes("consumerId")) {
            this.setState({
                ConsumerId: QueryString.consumerId.replaceAll("-", ""),
            })
        }

        if(Object.keys(QueryString).includes("orderCode")) {
            this.setState({
                isSecondFriend: true,
                OrderCode: QueryString.orderCode
            })
        }

        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.main);
        mainLoaderToggle('show');
    }

    render() {
        return (
            <Aux>
                <div className="buzzer-jersey">
                    <JerseyStepIndicitor Steps={this.StepList}/> 
                    <div className="code-entry-wrapper">
                        { this.HeadingSection() }
                        { this.FormSection() }
                    </div>
                    <Footersocial />
                    {
                        this.state.SimpleLoader ? <Loader /> : null
                    }
                </div>
            </Aux>
        )
    }

    HeadingSection() {
        return (
            <div className="spaceTile-intropage">
                <div className="campaign-heading">
                    {
                        this.state.IsMine ? 
                        <h3 className="lilac recoleta-medium">
                            Herzlich Glückwunsch! Du bist ein FC Milka Gewinner. 
                            Gib hier deinen Gewinncode ein, um deinen Fussball-Gewinn auszuwählen.
                        </h3>
                        :
                        <h3 className="lilac recoleta-medium">
                            Herzlich Glückwunsch! Dein Freund lädt dich ein, ein Fußballspiel der Bundesliga / 2. Bundesliga zu besuchen. Gib hier deinen Gewinncode ein.
                        </h3>
                    }
                </div>
            </div>
        );
    }

    FormSection() {
        return (
            <form id="frm_code_submit"
                ref = { form => this._CODE_SUBMISSION = form }
                name="frm_code_submit" 
                method="post" 
                action="/" 
                onSubmit={this.FormSubmitHandler.bind(this)} 
                noValidate>
                <div className="form__container">
                    <div className="small-input-container">
                        <p>*Pflichtfelder</p>
                    </div>

                    {
                        this.state.IsMine ?
                        <div className="input-container">
                            <div className="form-input__container half-width">
                                <label className="form-input__label" htmlFor="emailCode">Deine E-Mail-Adresse*</label>
                                <input className="form-input__input js-event-type-in js-event-ab" 
                                    ref = { input => this._username = input } 
                                    type="email"
                                    name="emailCode" 
                                    id="emailCode" 
                                    placeholder="Deine E-Mail-Adresse*" 
                                    data-require="true" 
                                    data-type="regex" 
                                    data-error-target="#error-emailname" 
                                    data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$" 
                                    data-required-message="" 
                                    data-pattern-message="" 
                                    data-event-fieldname="Email" 
                                    data-event="form-click" 
                                    data-category="Form Action" 
                                    data-action="Email" 
                                    data-label="Type In"
                                    onChange={this.RemoveInvalid.bind(this)}
                                    />
                                <span id="error-emailname" className="form-input__error active"></span>
                            </div>
                        </div>
                        : null
                    }

                    <div className="input-container">
                        <div className="form-input__container half-width">
                            <label className="form-input__label" htmlFor="jerseyCode">Gewinncode*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                ref = { input => this._code = input } 
                                type="text"
                                name="jerseyCode" 
                                id="jerseyCode" 
                                placeholder="Gewinncode*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-code-jersey" 
                                data-required-message="" 
                                data-event-fieldname="Code" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Code" 
                                data-label="Type In"
                                onChange={this.RemoveInvalid.bind(this)}
                                />
                            <span id="error-code-jersey" className="form-input__error active"></span>
                        </div>
                    </div>

                    <ReCAPTCHA
                        ref={ref => this._CAPTCHA = ref}
                        hl={GLOBAL_CONFIG.Captcha.language}
                        size="invisible"
                        sitekey={GLOBAL_CONFIG.Captcha.publish}
                        onChange={this.OnCaptchaResolved.bind(this)}
                        onExpired={this.OnCaptchaExpired.bind(this)}
                        onErrored={this.OnCaptchaError.bind(this)}
                    />

                    { 
                        this.state.ErrorMessage.length > 0 ?
                        <p className="buzzer-error">{this.state.ErrorMessage}</p>
                        : null
                    }

                    <button className="btn__container btn--red" type="submit">
                        WEITER
                    </button>

                    <p className="terms-link">
                        Hier findest du die <a href={GLOBAL_CONFIG.Route.fancenterTerms} target="_blank">Teilnahmebedingungen</a>.
                    </p>
                </div>
            </form>
        );
    }

    FormSubmitHandler(event) {
        event.preventDefault();
        var isValid = ValidateForm.validateForm('frm_code_submit');

        if(isValid) {
            this.setState({
                ErrorMessage: ""
            })
            //this.TriggerCaptcha();
            this.ProcessForm(null);
        }
    }

    TriggerCaptcha() {
        this._CAPTCHA.execute();
    }

    OnCaptchaResolved (token) {
        this.setState({
            SimpleLoader: true
        })
        this.ProcessForm(token)
    }

    ProcessDirectTicketSelection(token, emailValue, codeValue){
        this.setState({
            SimpleLoader: true
        });
        
        let _data = {
            winningCode: codeValue,
            Email: emailValue,
            ConsumerId: this.state.ConsumerId,
            Capcha: token,
            gameWeek : this.state.gameWeek
        }

        FanCenterServices.CodeEntry.UserTicket(_data)
        .then( response => {
            let {success, data, message} = response.data;
            if(success && data.CodeValid) {
                var {ConsumerId, GiftCategory, Teams} = data;

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'enter code',
                    'dataLabel': codeValue
                });

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'email',
                    'dataLabel': "Type In"
                });

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'participant Id',
                    'dataLabel': ConsumerId
                });

                var _data = {
                    IsMainUser: this.state.IsMine,
                    IsRemainingTicket: this.state.isRemainingTicket,
                    GameWeek: this.state.gameWeek,
                    Email: emailValue,
                    Code: codeValue,
                    Id: ConsumerId,
                    Type : _GIFT.Ticket,
                    UserType: _USER_TYPES.User,
                    TeamList : Teams
                }

                localStorage.setItem(_LOCAL.GiftCategory, GiftCategory);
                localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(_data));
                this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.teamSelection);
            } else {
                const errors = data.Error;
                let errorMessage = message;
                if(errors && errors.length > 0){
                    errorMessage = errors[0];
                }
                this.ProcessError(errorMessage);
            }
        })
        .catch( (error) => {
            this.ProcessError("SERVER ERROR")
        })
        .finally( () => {
            if(!!this._CAPTCHA) {
                this._CAPTCHA.reset();
            }

            this.setState({
                SimpleLoader: false
            })
        })
    }

    ProcessForm(token) {
        let emailValue = this._username ? this._username.value : "",
            codeValue = this._code.value,
            _data = {};

        if(this.state.isRemainingTicket){
            this.ProcessDirectTicketSelection(token, emailValue, codeValue);
        } else if(this.state.IsMine) {
            _data = {
                winningCode: codeValue,
                Email: emailValue,
                ConsumerId: this.state.ConsumerId,
                Capcha: token
            }

            FanCenterServices.CodeEntry.User(_data)
            .then( response => {
                let {success, data, message} = response.data;
    
                if(success) {
                    var {Teams, GiftCategory, ConsumerId, CodeValid, GiftType, IsScenarioC, Profile } = data;

                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 'button-click',
                        'dataCategory': 'Click Action',
                        'dataAction': 'enter code',
                        'dataLabel': codeValue
                    });
    
                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 'button-click',
                        'dataCategory': 'Click Action',
                        'dataAction': 'email',
                        'dataLabel': "Type In"
                    });
    
                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 'button-click',
                        'dataCategory': 'Click Action',
                        'dataAction': 'participant Id',
                        'dataLabel': ConsumerId
                    });
    
                    if(CodeValid) {
                        this.ProcessTeams(Teams);
                        localStorage.setItem(_LOCAL.GiftCategory, GiftCategory);
    
                        var _data = {
                            IsMainUser: this.state.IsMine,
                            Email: emailValue,
                            Code: codeValue,
                            Type: GiftType,
                            Id: ConsumerId,
                            UserType: _USER_TYPES.User,
                            ProfileUser : Profile
                        }
                        localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(_data))
    
                        if(IsScenarioC){
                            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend)
                        } else {
                            switch (GiftType.toLowerCase()) {
                                case _GIFT.Ticket:
                                    /**
                                     * Redirect To Ticket choice
                                     */
                                    this.props.UpdateFancenterStatus(_STATUS.Fancenter.codeEntered)
                                    if(this.state.IsMine) {
                                        this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.giftChoice)
                                    } else {
                                        this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.userDetails.companion)
                                    }
                                    break;
                                case _GIFT.Jersey:
                                    /**
                                     * Redirect To Jersey choice
                                     */
                                    this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection)
                                    break;
                            
                                default:
                                    break;
                            }
                        }
                    }
                } else {
                    this.ProcessError(message);
                }
            })
            .catch( (error) => {
                this.ProcessError("SERVER ERROR")
            })
            .finally( () => {
                if(!!this._CAPTCHA) {
                    this._CAPTCHA.reset();
                }
    
                this.setState({
                    SimpleLoader: false
                })
            })

        } else {
            _data = {
                ConsumerId: this.state.ConsumerId,
                VoucherCode: codeValue,
                captcha: token
            }

            if(this.state.isSecondFriend) {
                _data = {
                    ..._data,
                    GiftCode: this.state.OrderCode
                }

                FanCenterServices.CodeEntry.Companion(_data)
                .then( response => {
                    let { success, data, message } = response.data,
                        ConsumerId = this.state.ConsumerId;

                    if(success) {
                        let { FriendTeam, FriendDetail, GiftCategory, IsScenarioC, Profile } = data;

                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 'button-click',
                            'dataCategory': 'Click Action',
                            'dataAction': 'enter code',
                            'dataLabel': codeValue
                        });
        
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 'button-click',
                            'dataCategory': 'Click Action',
                            'dataAction': 'email',
                            'dataLabel': "Type In"
                        });
        
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 'button-click',
                            'dataCategory': 'Click Action',
                            'dataAction': 'participant Id',
                            'dataLabel': ConsumerId
                        });
        
                        if(FriendDetail.success && FriendTeam.length > 0) {
                            localStorage.setItem(_LOCAL.GiftCategory, GiftCategory);
    
                            let FriendName = "",
                                MainEmail = "",
                                options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
                            let matchDate = null, matchTime = null;
                            if(FriendTeam[0].weekendDate){
                                matchDate = `Spieltag ${FriendTeam[0].gameweek} (${FriendTeam[0].weekendDate})`;
                                matchTime = "";
                            }else{
                                matchDate = new Date(FriendTeam[0].matchDate).toLocaleString('de-DE', options);
                                matchTime = new Date(FriendTeam[0].matchDate).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'});
                            }
                            
                            FriendDetail.data.attributes.forEach(attribute => {
                                if(attribute.name.indexOf("name") > -1) {
                                    FriendName += ` ${attribute.value}`
                                }
                            });
    
                            var _data = {
                                IsMainUser: this.state.IsMine,
                                UserType: _USER_TYPES.Comp2,
                                Email: MainEmail,
                                Code: codeValue,
                                Type: _GIFT.Ticket,
                                Id: ConsumerId,
                                OrderCode: this.state.OrderCode,
                                Name: FriendName,
                                Match: new Match({
                                    home: FriendTeam[0].home,
                                    away: FriendTeam[0].away,
                                    location: `${FriendTeam[0].location} (${FriendTeam[0].groundName})`,
                                    date: `${matchDate} ${matchTime}`,
                                    id: FriendTeam[0].footballMatchId,
                                    gameweek : FriendTeam[0].gameweek,
                                    weekendDate : FriendTeam[0].weekendDate
                                }),
                                ProfileUser : Profile
                            }
    
                            localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(_data))
        
                            /**
                             * Redirect To Ticket choice
                             */
                            this.props.UpdateFancenterStatus(_STATUS.Fancenter.codeEntered)

                            if(IsScenarioC){
                                if(this.state.IsMine) {
                                    this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend)
                                } else {
                                    this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.companion.userDetails)
                                }
                            }else {
                                if(this.state.IsMine) {
                                    this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.giftChoice)
                                } else {
                                    this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.userDetails.companion)
                                }
                            }
                        } else {
                            this.ProcessError("MISSING DETAILS")
                        }
                    } else {
                        let {Error} = data;
                        
                        if(!!Error) {
                            this.ProcessError(Error);
                        } else {
                            this.ProcessError(message);
                        }
                    }
                })
                .catch( () => {
                    this.ProcessError("SERVER ERROR")
                })
                .finally( () => {
                    if(!!this._CAPTCHA) {
                        this._CAPTCHA.reset();
                    }
        
                    this.setState({
                        SimpleLoader: false
                    })
                })
            } else {
                FanCenterServices.CodeEntry.FirstCompanion(_data)
                .then( response => {
                    let { success, message, data } = response.data;
                    
                    if(success) {
                        var { Teams, GiftCategory, GiftType, IsScenarioC, Profile} = data;

                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 'button-click',
                            'dataCategory': 'Click Action',
                            'dataAction': 'enter code',
                            'dataLabel': codeValue
                        });

                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 'button-click',
                            'dataCategory': 'Click Action',
                            'dataAction': 'email',
                            'dataLabel': "Type In"
                        });

                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 'button-click',
                            'dataCategory': 'Click Action',
                            'dataAction': 'participant Id',
                            'dataLabel': this.state.ConsumerId
                        });

                        this.ProcessTeams(Teams);
                        localStorage.setItem(_LOCAL.GiftCategory, GiftCategory);
                        let UserDetails = {
                            Code: codeValue,
                            IsMainUser: this.state.IsMine,
                            Type: GiftType,
                            ConsumerId: this.state.ConsumerId,
                            UserType: _USER_TYPES.Comp1,
                            ProfileUser : Profile
                        }
                        localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(UserDetails));

                        const redirectToTeamSelection = IsScenarioC ? 
                                    GLOBAL_CONFIG.Route.fanCenter.tickets.favoriteClub
                                : 
                                    GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection;
                        
                        switch (GiftType.toLowerCase()) {
                            case _GIFT.Ticket:
                                this.props.UpdateFancenterStatus(_STATUS.Fancenter.codeEntered)
                                this.props.history.push(redirectToTeamSelection)
                                break;
                        
                            default:
                                this.ProcessError("-");
                                break;
                        }
                    } else  {
                        let {Error} = data;
                        
                        if(!!Error) {
                            this.ProcessError(Error);
                        } else {
                            this.ProcessError(message);
                        }
                    }
                })
                .catch( () => {
                    this.ProcessError("SERVER ERROR")
                })
                .finally( () => {
                    if(!!this._CAPTCHA) {
                        this._CAPTCHA.reset();
                    }

                    if(!!this._CODE_SUBMISSION) {
                        this._CODE_SUBMISSION.reset();
                    }
        
                    this.setState({
                        SimpleLoader: false
                    })
                })
            }
        }
    }

    ProcessError(ErrorMessage) {
        switch (ErrorMessage) {
            case _GIFT_MESSAGES.NotWinner:
                this.setState({
                    ErrorMessage: _ERROR_MESSAGES.NotWinner
                })

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'code entered',
                    'dataLabel': 'email not winner'
                });

                break;
            case _GIFT_MESSAGES.Used:
                this.setState({
                    ErrorMessage: _ERROR_MESSAGES.Used
                })

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'code entered',
                    'dataLabel': 'code already used'
                });

                break;
            case _GIFT_MESSAGES.Expired:
                this.setState({
                    ErrorMessage: _ERROR_MESSAGES.Expired
                })

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'code entered',
                    'dataLabel': 'code expires'
                });

                break;
            case _GIFT_MESSAGES.CodeInvalid:
                this.setState({
                    ErrorMessage: _ERROR_MESSAGES.CodeInvalid
                })

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'code entered',
                    'dataLabel': 'code not match'
                });

                break;

            case _GIFT_MESSAGES.NOT_FRIEND:
                this.setState({
                    ErrorMessage: _ERROR_MESSAGES.CodeInvalid
                })

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'code entered',
                    'dataLabel': 'code not match'
                });

                break;

            case _GIFT_MESSAGES.CaptchaError:
                this.setState({
                    ErrorMessage: _ERROR_MESSAGES.CaptchaError
                })

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'code entered',
                    'dataLabel': 'Captcha Error'
                });

                break;
            case _GIFT_MESSAGES.TICKET_GAMEWEEK_EXPIRED:
                this.setState({
                    ErrorMessage: _ERROR_MESSAGES.TICKET_GAMEWEEK_EXPIRED
                })

                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'button-click',
                    'dataCategory': 'Click Action',
                    'dataAction': 'code entered',
                    'dataLabel': 'Captcha Error'
                });

                break;
            default:
                this.setState({
                    ErrorMessage: "GENERIC_ERROR"
                })
                break;
        }
    }

    OnCaptchaExpired () {
        this._CAPTCHA.reset();
    }

    OnCaptchaError () {
        this._CAPTCHA.reset();
    }

    ProcessTeams(TeamsArray) {
        var teams = [],
            teamIds = {};

        if(!!TeamsArray) {
            TeamsArray.forEach(team => {
                if(!!team.footballClubName) {
                    teams.push(team.footballClubName.replaceAll(/\r\n/g, ""))
                    teamIds[team.footballClubName.replaceAll(/\r\n/g, "")] = team.footballClubId;
                }

                if(!!team.teamName) {
                    teams.push(team.teamName.replaceAll(/\r\n/g, ""));
                    teamIds[team.teamName.replaceAll(/\r\n/g, "")] = team.teamId;
                }
            });
        }

        localStorage.setItem(_LOCAL.AvailableTeams, JSON.stringify(teams));
        localStorage.setItem(_LOCAL.AvailableTeamIDs, JSON.stringify(teamIds));
    }

    RemoveInvalid(event) {
        var _DOM = event.target,
            errorDOM = document.getElementById(_DOM.getAttribute("data-error-target").substring(1)),
            value = _DOM.value;

        if(value.length > 0) {
            _DOM.classList.remove("notvalid");
            errorDOM.innerHTML = "";
        }
    }
}

export default withRouter(UserCodeEntry);