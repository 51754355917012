import React from "react";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { mainLoaderToggle } from "../../../modules/helpers";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Loader from "../../../components/Overlays/Loader";
import parse from 'html-react-parser';
import GLOBAL_CONFIG from "../../../config/config";
import _LOCAL from "../../../Models/LocalStorageKeys";
import ParticipationService from "../../../Service/Participation";
import appGoogleTracking from "../../../modules/googletracking";
import _BUZZER_MESSAGE from "../../../Models/BuzzerErrorMessage";
import _TRIKOT_MESSAGE from "../../../Models/TrikotErrorMessage";
import VarietyTeaser from "./VarietyTeaser";
import _ERROR_MESSAGES from "../../../Models/ErrorMessages";
import SliderVariety from "./SliderVariety";

import VarietyTeaserV2 from "./varietyTeaserV2";
class Gamification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPrizeShow: false,
            simpleLoader: false,
            prizeValue: '0',
            ErrorMessage : "",
            isSubmitting: false
        }
        this.cardSection = this.cardSection.bind(this);
        this.prizeDetails = this.prizeDetails.bind(this);
        this.showPrize = this.showPrize.bind(this);
        this.submitParticipation = this.submitParticipation.bind(this);
    }

   

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        mainLoaderToggle('hide');
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    componentWillMount() {
        if(localStorage.getItem(_LOCAL.TrikotParticipationDetails) === null || localStorage.getItem(_LOCAL.ProductCode) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.buzzer.main);
        }
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="gamification-q3">
                <div className="gamification-q3__game">
                    {
                        !this.state.isPrizeShow ?
                            <>
                                {!this.state.ErrorMessage ? <h1 className="gamification-q3__game__text">ÖFFNE DEN SPIND, UM ZU SEHEN, OB DU GEWONNEN HAST!</h1> : <h1 className="error_message">{this.state.ErrorMessage}</h1>}
                                <div className="gamification-q3__game__clickable" onClick={this.submitParticipation}></div>
                                <picture className="gamification-q3__game__door" >
                                    <source srcSet="/resources/imagesBundesliga/gamification_23/door-locked.gif" media="(min-width: 1024px)" />
                                    <source srcSet="/resources/imagesBundesliga/gamification_23/door-locked-sm.gif" media="(min-width: 768px)" />
                                    <source srcSet="/resources/imagesBundesliga/gamification_23/door-locked-sm.gif" media="(min-width: 200px)" />
                                    <img src="/resources/imagesBundesliga/gamification_23/door-locked.gif" alt="door locked" />
                                </picture>
                            </>   
                            : null          
                    }

                     

                    {this.state.isPrizeShow && this.prizeDetails()  }
                </div>
              
                {
                    this.state.simpleLoader ? <Loader /> : null
                }
                <Footersocial />
            </div>
        );
    }

    prizeDetails() {
        //const prize ='LOSER';
        var prizeType = { image: '', title: '', paragraph: '' }
        switch (this.state.prizeValue) {
            case "0":
                prizeType = {
                    image: 'Loser_Q3',
                    title: "VERSUCH DEIN GLÜCK NOCH EINMAL!",
                    paragraph: "Heute hast du leider nicht gewonnen. Du kannst aber noch bis zum 01.10.2023 den Produktcode eines Milka oder TUC Produktes eingeben und dein Glück noch einmal versuchen! Viel Erfolg!"
                }
                break;
            case "1":
                prizeType = {
                    image: 'Shirt_Q3',
                    title: "DEIN OUTFIT FÜR DIE NEUE SAISON STEHT!",
                    paragraph: "Vielen Dank für deine Teilnahme an unserem Gewinnspiel. Du hast <span class='yellow'>1 von 630 signierten original Heimtrikots der Bundesliga / 2. Bundesliga</span> gewonnen.</br>Du erhältst von uns in den nächsten Tagen eine E-Mail mit weiteren Informationen zur Heimtrikot-Vergabe.</br>Bis dahin bitten wir dich um Geduld und wünschen dir noch viele schokoladige Momente mit unseren Milka Produkten. Übrigens: Denke daran, den Original-Kaufbeleg gut aufzubewahren."
                }
                break;
            case "2":
                prizeType = {
                    image: 'Bar_Q3',
                    title: "DEINE NERVENNAHRUNG FÜR DIE NEUE SAISON",
                    paragraph: "Vielen Dank für deine Teilnahme an unserem Gewinnspiel. Du hast <span class='yellow'> eines von 150 Milka Produktpaketen </span> gewonnen. Du erhältst von uns in den nächsten Tagen eine E- Mail mit weiteren Informationen zu deinem Preis.Bis dahin bitten wir dich um Geduld und wünschen dir noch viele zarte Momente mit unseren Milka Produkten. Übrigens: Denke daran, den Original - Kaufbeleg gut aufzubewahren."
                }
                break;
            case "3":
                prizeType = {
                    image: 'Voucher_Q3',
                    title: "DU KANNST LOSSHOPPEN",
                    paragraph: "Vielen Dank für deine Teilnahme an unserem Gewinnspiel.<br/>Du hast <span class='yellow'> 1 von 15.000 20%-Gutscheinen für 11teamsports </span> gewonnen.<br/>Du erhältst von uns in den nächsten Tagen eine E-Mail mit weiteren Informationen zu deinem Preis.<br/>Bis dahin bitten wir dich um Geduld und wünschen dir noch viele zarte Momente mit unseren Milka Produkten.<br/>Übrigens: Denke daran, den Original-Kaufbeleg gut aufzubewahren."
                }
                break;
            case "4":
                prizeType = {
                    image: 'Shirt_Q3',
                    title: "DEIN OUTFIT FÜR DIE NEUE SAISON STEHT!",
                    paragraph: "Vielen Dank für deine Teilnahme an unserem Gewinnspiel. Du hast <span class='yellow'>1 von 870 original Heimtrikots der Bundesliga / 2. Bundesliga</span> gewonnen.</br>Du erhältst von uns in den nächsten Tagen eine E-Mail mit weiteren Informationen zur Heimtrikot-Vergabe.</br>Bis dahin bitten wir dich um Geduld und wünschen dir noch viele schokoladige Momente mit unseren Milka Produkten.  Übrigens: Denke daran, den Original-Kaufbeleg gut aufzubewahren."
                }
                break;
            default:
                prizeType = {
                    image: 'Loser_Q3',
                    title: "VERSUCH DEIN GLÜCK NOCH EINMAL!",
                    paragraph: "Heute hast du leider nicht gewonnen. Du kannst aber noch bis zum 01.10.2023 den Produktcode eines Milka oder TUC Produktes eingeben und dein Glück noch einmal versuchen! Viel Erfolg!"
                }
                break;
        }
        _SCROLL_PAGE.ScrollToTop();
        return (
            <>
            <picture className="gamification-q3__game__door" >
                    <source srcSet={`/resources/imagesBundesliga/gamification_23/${prizeType.image}.gif`} media="(min-width: 1024px)" />
                    <source srcSet={`/resources/imagesBundesliga/gamification_23/${prizeType.image}-Mobile.gif`} media="(min-width: 768px)" />
                    <source srcSet={`/resources/imagesBundesliga/gamification_23/${prizeType.image}-Mobile.gif`} media="(min-width: 200px)" />
                    <img src={`/resources/imagesBundesliga/gamification_23/${prizeType.image}.gif`} alt="prize" />
            </picture>
            <div className="gamification-q3__prize-details">
                <div className="s-content-title">
                        <h1 className="heading__v1">{prizeType.title }</h1>
                        <p className='paragraph__v1 '>{ parse(prizeType.paragraph)}</p>
                </div>
                {this.cardSection()}
                </div>
            </>
        )
    }
    cardSection() {
        return (
            <VarietyTeaserV2/>
        );
    }

    showPrize() {
        this.setState({ isPrizeShow: true });
    }

    ErrorHandler(Message) {
        switch (Message) {
            case _TRIKOT_MESSAGE.PARTICIPATION_LIMIT:
                this.setState({
                    ErrorMessage: "Vielen Dank für deine Teilnahme, dein Glück ist für heute aufgebraucht. Bitte versuche es mit deinem nächsten Einkauf ab morgen erneut."
                })
                break;
            case _TRIKOT_MESSAGE.CODE_INVALID:
                this.setState({
                    CodeIsValid: false,
                    ErrorMessage: _ERROR_MESSAGES.Buzzer.INVALID_CODE
                })
                break;
            case _TRIKOT_MESSAGE.INVALID_CAPTCHA:
                this.setState({
                    ErrorMessage: "UNGÜLTIGE RECAPTCHA-ANTWORT"
                })
                break;
            case _TRIKOT_MESSAGE.REGISTRATION_FAILED:
                this.setState({
                    ErrorMessage: "E-MAIL BEREITS VERWENDET"
                })
                break;

            default:
                this.setState({
                    ErrorMessage: "SERVERFEHLER"
                })
                break;
        }
    }

    submitParticipation () {

        // If submission is already in progress, exit function early
        if (this.state.isSubmitting) {
            return;
        }

        // Set isSubmitting to true to indicate that submission is starting
        this.setState({
            isSubmitting: true
        });

        let participationData = JSON.parse(localStorage.getItem(_LOCAL.TrikotParticipationDetails));
        let productCodeData = localStorage.getItem(_LOCAL.ProductCode);
        participationData.productCode = productCodeData;

        this.setState({
            SimpleLoader: true
        })

        ParticipationService.ParticipateTrikot(participationData)
        .then((response) => {
            let { status, success, data } = response.data;
            this.setState({
                SimpleLoader: false
            })
            console.log("response data", response.data)

            if (success) {
                this.showPrize();
                this.setState ({
                    prizeValue: data.WinTier
                })
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 	'trikot_locker_select',
                    'dataCategory': 'Gamification Page',
                    'dataAction': 	'Click on Open Lucky Locker',
                    'dataLabel': 	'GAME_GM_OTHER'
                });
            } else {
                if (data.Error.includes(_TRIKOT_MESSAGE.EMAIL_INVALID)) {
                    var _message = "Ihre E-Mail-Adresse ist ungültig"
                    this.setState({
                        ErrorMessage: _message
                    })

                }
                if (!!data.Error && data.Error.length > 0) {
                    this.ErrorHandler(data.Error[0]);
                } else {
                    this.setState({
                        ErrorMessage: "SERVER ERROR"
                    })
                }
            }
        })
        .catch(() => {
            this.setState({
                SimpleLoader: false
            })
        })
    }

}

export default Gamification;