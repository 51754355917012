import React from 'react';
import parse from 'html-react-parser';
/* 
    Title content on all pages - to display the first part of all pages
    This gives uniformity in all pages in terms of CSS and HTML codes
*/

const TitleContentV1 = (props) => {

    let pModifier = !!props.pClass ? props.pClass : "";
    
    let heading = props.heading;
    if(typeof heading === 'string'){
        heading = parse(heading);
    }

    let RenderHeading = (props.heading !== undefined && props.heading !== null) ? <h1 className="heading__v1">{heading}</h1> : null,
        RenderSubheading = (props.subheading !== undefined && props.subheading !== null) ? <h2 className="subheading__v1 ">{props.subheading}</h2> : null,
        RenderParaText = (props.paragraph !== undefined && props.paragraph !== null) ? <p className={`paragraph__v1 `}>{props.paragraph}</p> : null,
        RenderSecondText = (props.paragraphsecond !== undefined && props.paragraphsecond !== null) ? <p className={`paragraph-two__v1 `}>{props.paragraphsecond}</p> : null;
       

    return (
        <div className="s-content-title">
            {RenderHeading}
            {RenderSubheading}
            {RenderParaText}
            {RenderSecondText}
        </div>
    );
}

export default TitleContentV1;
