import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from "../../../config/config";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { mainLoaderToggle } from "../../../modules/helpers";
import Loader from "../../../components/Overlays/Loader";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import { withRouter } from 'react-router-dom';
import TitleContentV1 from "../../../components/Content/TitleContent/TitleContentv1";
import BuzzerGeneratedScore from "../../../components/Content/BuzzerGeneratedScore/BuzzerGeneratedScore";
import buzzerScoreService from "../../../Service/Buzzer_score";
import _LOCAL from "../../../Models/LocalStorageKeys";
import BundesligaTeams from '../../../Models/Teams';
import ParticipationService from "../../../Service/Participation";
import appGoogleTracking from "../../../modules/googletracking";
import _BUZZER_MESSAGE from "../../../Models/BuzzerErrorMessage";

class Game23 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SimpleLoader: false,
            rudiGif: '/resources/imagesBundesliga/buzzer_23/buzzer/buzzer-rudi.gif',
            rudiLoaded: '/resources/imagesBundesliga/buzzer_23/buzzer/buzzer-rudi.gif',
            imageHash: Date.now(),
            druckeClass: 'drucke',
            clickCounter: 0,
            matchScore: [
                { team1: '0', team2: '0' }, { team1: '0', team2: '0' }, { team1: '0', team2: '0' }
            ],
            cardStatus: [{ status: '' }, { status: '' }, { status: '' }],
            selectedScore: '',
            teamsOpponent: { away: '', home: '' },
            ErrorMessage: ""
        }

        this.ReloadGif = this.ReloadGif.bind(this);
        this.showGeneratedScore = this.showGeneratedScore.bind(this);
        this.selectScore = this.selectScore.bind(this);
        this.initScore = this.initScore.bind(this);
        this.getTeamOpponents = this.getTeamOpponents.bind(this);
        this.submitParticipation = this.submitParticipation.bind(this);
        this.ValidateScore = this.ValidateScore.bind(this);
        this.GetScoresTagging = this.GetScoresTagging.bind(this);

    }

    componentDidMount() {
        mainLoaderToggle('hide');

        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
        this.initScore();

    }

    componentWillMount() {
        if (localStorage.getItem(_LOCAL.ProductCode) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.buzzer.main);
        }
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    ReloadGif() {
        this.setState({
            // SimpleLoader: true,
            rudiLoaded: '',
            druckeClass: 'drucke-hide',
            imageHash: Date.now()
        })

        this.setState({ rudiLoaded: this.state.rudiGif })
        this.showGeneratedScore();
        setTimeout(() => {
            this.setState({
                // SimpleLoader: false
                druckeClass: 'drucke',
            });
        }, 1000)
    }

    getTeamOpponents() {
        const teams = JSON.parse(localStorage.getItem(_LOCAL.MatchSelected));
        var teamOpponent = {
            away: teams.away,
            home: teams.home
        }
        this.setState({ teamsOpponent: teamOpponent });
    }

    showGeneratedScore() {
        const counter = this.state.clickCounter;
        console.log("Counter", counter);
        if (counter > 2) {
            return;
        }
        this.setState({ clickCounter: counter + 1 }, () => {
            console.log(this.state.clickCounter);
            switch (this.state.clickCounter) {
                case 1:
                    this.setState({ cardStatus: [{ status: 'generated' }, { status: '' }, { status: '' }] });
                    break
                case 2:
                    this.setState({ cardStatus: [{ status: 'generated' }, { status: 'generated' }, { status: '' }] });
                    break
                case 3:
                    this.setState({ cardStatus: [{ status: 'generated' }, { status: 'generated' }, { status: 'generated' }] });
                    setTimeout(() => { this.setState({ cardStatus: [{ status: 'select' }, { status: 'select' }, { status: 'select' }] }); }, 500)
                    break
                default:

            }
        });

    }
    selectScore(selected) {
        let cardStatus = this.state.cardStatus;
        let matchScore = this.state.matchScore
        if (this.state.clickCounter < 3) {
            return;
        }
        console.log("Selected", selected);
        for (let i = 0; i < cardStatus.length; i++) {
            cardStatus[i].status = 'generated';
            if (selected === i) {
                cardStatus[i].status = 'selected';

            }
        }
        this.setState({
            cardStatus: cardStatus,
            clickCounter: 4,
            selectedScore: matchScore[selected]
        })

    }
    initScore() {
        buzzerScoreService.GetMatchesScore()
            .then((response) => {
                this.setState({
                    SimpleLoader: true,
                });
                let scores = response.data.scores;
                console.log(scores);
                this.setState({ matchScore: scores });
                this.getTeamOpponents();
            })
            .catch(() => {
            })
            .finally(() => {
                this.setState({
                    SimpleLoader: false,
                });
            })
    }
    ValidateScore() {
        this.props.history.push(GLOBAL_CONFIG.Route.buzzer.closing);
    }

    GetScoresTagging() {
        return `${this.state.matchScore[0].team1}-${this.state.matchScore[0].team2}_${this.state.matchScore[1].team1}-${this.state.matchScore[1].team2}_${this.state.matchScore[2].team1}-${this.state.matchScore[2].team2}`;
    }

    render() {
        let cardStatus = this.state.cardStatus
        let matchScore = this.state.matchScore
        let gameOnlyClass = 'buzzer-game-section__game-only before-selected';
        if (this.state.clickCounter === 3) {
            gameOnlyClass = 'buzzer-game-section__game-only on-select'
        } else {
            gameOnlyClass = 'buzzer-game-section__game-only'
        }
        let teamOpponent = this.state.teamsOpponent
        return (
            <div id="game-buzzer-page">
                <div className="buzzer-title-section">
                    <TitleContentV1
                        heading={'Jetzt Buzzern für deine Gewinnchance'}
                        paragraph={'Wie geht die Partie aus? Drücke jetzt Rudis Buzzer für einen zufälligen Endstand. Du kannst insgesamt 3 zufällige Ergebnisse buzzern lassen. Wähle aus den 3 Möglichkeiten ein Spielergebnis aus und logge deine Wahl unterhalb der Ergebnisse ein.'}
                    />
                </div>
                <div className="buzzer-game-section">
                    <div className="buzzer-game-section__buzzer-man">
                        <img className="rudi" src={`${this.state.rudiLoaded}?${this.state.imageHash}`} onClick={this.ReloadGif} />
                        {<img className={this.state.clickCounter < 3 ? this.state.druckeClass : 'drucke-not-visible'} src={`/resources/imagesBundesliga/buzzer_23/buzzer/drucke_dein_buzzer.gif?${this.state.imageHash}`} />}
                    </div>
                    {/* <div className={this.state.clickCounter < 3 ? `buzzer-game-section__game-only before-selected` : `buzzer-game-section__game-only`}> */}
                    <div className={gameOnlyClass}>

                        <div className='buzzer-game-button-section'>
                            <div className='buzzer-game-button-section__number'> </div>
                            <div className='buzzer-game-button-section__details'>
                                {this.state.clickCounter === 3 ? <div className="buzzer-game-button-section__details-img"><img src="/resources/imagesBundesliga/buzzer_23/buzzer/Ergebnis_wählen_1.png" /></div> :
                                    <div className="buzzer-game-button-section__details-img-not-visible"><img src="/resources/imagesBundesliga/buzzer_23/buzzer/Ergebnis_wählen_1.png" /></div>
                                }
                            </div>
                        </div>

                        {cardStatus.map((item, index) => {
                            return (
                                <BuzzerGeneratedScore
                                    status={cardStatus[index].status}
                                    number={index + 1}
                                    firstTeamScore={matchScore[index].team1}
                                    secondTeamScore={matchScore[index].team2}
                                    firstTeamLogo={BundesligaTeams[teamOpponent.home.replace(/\r?\n|\r/g, '')]?.img}
                                    secondTeamLogo={BundesligaTeams[teamOpponent.away.replace(/\r?\n|\r/g, '')]?.img}
                                    onClick={() => { this.selectScore(index) }}
                                />
                            )
                        })
                        }

                        <div className='buzzer-game-button-section'>
                            <div className='buzzer-game-button-section__number'>
                                {this.state.selectedScore !== '' && <img className="buzzer-game-button-section__number__arrow" src='/resources/imagesBundesliga/buzzer_23/buzzer/arrow-selected-score.gif' />}
                            </div>
                            <div className='buzzer-game-button-section__details'>
                                <button type="submit" className={this.state.selectedScore === '' ? "btn__container btn--lilac disabled" : "btn__container btn--lilac"} disabled={this.state.selectedScore === '' ? "true" : ""} onClick={() => { this.submitParticipation(); appGoogleTracking.dataLayerPush({ 'dataEvent': 'buzzer_banner', 'dataCategory': 'Buzzer Page', 'dataAction': 'Display', 'dataLabel': `${this.GetScoresTagging()}` }); appGoogleTracking.dataLayerPush({ 'dataEvent': 'buzzer_log_result', 'dataCategory': 'Buzzer Page', 'dataAction': 'Click Log Result', 'dataLabel': `${this.state.selectedScore.team1} - ${this.state.selectedScore.team2}` }) }} >Ergebnis einloggen</button>
                                {
                                    this.state.ErrorMessage.length > 0 ?
                                        <p className="buzzer-error">{this.state.ErrorMessage}</p>
                                        : null
                                }
                            </div>
                        </div>
                        <div className='buzzer-game-text-section'>
                            <div className='buzzer-game-button-section__number'> </div>
                            <div className='buzzer-game-button-section__details'>
                                <p className="buzzer-game-text-inner-section">*In der Zeit von montags bis freitags nimmst du an der Auslosung für den kommenden Spieltag teil.
                                    Zur Auswahl stehen dir alle Spiele, die am Samstag und Sonntag des jeweiligen Spieltages ausgetragen werden.
                                    Gibst du deinen Tipp samstags ab 00:01 Uhr ab, nimmst du automatisch an der Auslosung für den Spieltag am darauffolgenden Wochenende teil.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buzzer-under-section">
                    <picture>
                        <img className="image-under" src="/resources/imagesBundesliga/buzzer-game/banner/under.png" alt="route" />
                    </picture>
                </div>
                {
                    this.state.SimpleLoader ? <Loader /> : null
                }
                <Footersocial />
            </div>
        );
    }

    submitParticipation() {
        let participationData = JSON.parse(localStorage.getItem(_LOCAL.BuzzerParticipationDetails));
        let matchData = JSON.parse(localStorage.getItem(_LOCAL.MatchSelected));
        let productCodeData = localStorage.getItem(_LOCAL.ProductCode);
        participationData.score = `${this.state.selectedScore.team1} - ${this.state.selectedScore.team2}`;
        participationData.matchDay = matchData.matchDay;
        participationData.homeTeam = matchData.home;
        participationData.awayTeam = matchData.away;
        participationData.matchId = matchData.id;
        participationData.productCode = productCodeData;
        participationData.salutation = participationData.salutation !== "" ? participationData.salutation : "1";

        this.setState({
            SimpleLoader: true
        })

        ParticipationService.ParticipateWeltmeister(participationData)
            .then((response) => {
                let { status, success, data } = response.data;
                this.setState({
                    SimpleLoader: false
                })
                console.log("response data", response.data)

                if (success) {
                    /**
                     * Sucess handler
                    */
                    this.props.history.push(GLOBAL_CONFIG.Route.buzzer.closing);

                } else {
                    if (data.Error.includes(_BUZZER_MESSAGE.PARTICIPATION_LIMIT)) {
                        var _message = "Du kannst max. 5x am Tag teilnehmen"
                        this.setState({
                            ErrorMessage: _message
                        })

                    }
                    if (!!data.Error && data.Error.length > 0) {
                        this.ErrorHandler(data.Error[0]);
                    } else {
                        this.setState({
                            ErrorMessage: "SERVER ERROR"
                        })
                    }
                }
            })
            .catch(() => {
                this.setState({
                    SimpleLoader: false
                })
            })
    }
}

export default withRouter(Game23);