import React from 'react';
import Aux from '../../../hoc/Auxiliare';
import Button from '../Button/Button';

const MatchCard = ({small = true, isActive = true, borderButton = true, cta = true, whiteBg = false, onclick, match ,withText=false}) => {
    const imagePath = "/resources/imagesBundesliga/teams-picture-new/";

    const getDayOfWeek = (date) => {
        const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
        const d = new window.Date(date);
        return days[d.getDay()];
    }

    match = !match ? {} : match; 

    const Day = match.MatchdayDateTime ? getDayOfWeek(match.MatchdayDateTime): 'Date';

    return (
        <Aux>
            <div className={['match-card', small ? 'match-card__small' : '', isActive ? '' : 'match-card__disabled', whiteBg ? 'whitebg' : ''].join(' ')}>
                <div className='match-card__top'>
                    <div className='match-card__top__image'>
                        <img src='/resources/imagesBundesliga/fanCenter/match-card-bg.png' alt= { 'banner' }/>
                    </div>
                    <div className='match-card__top__team'>
                        <div className='match-card__top__team__left'>
                            <img src={imagePath + match.Home +'.png'} alt= { match.Home }/>
                        </div>
                        <div className='match-card__top__team__divider'>
                            :
                        </div>
                        <div className='match-card__top__team__right'>
                            <img src={imagePath + match.Guest +'.png'} alt= { match.Guest }/>
                        </div>
                    </div>
                </div>            
                <div className='match-card__bottom'>
                    <div className='match-card__bottom__info'>
                        <p><span className='match-card__bottom__info__date good-headline-pro'>{Day},&nbsp;{match.Date}</span><span className='match-card__bottom__info__time good-headline-pro'>{match.Time}</span></p>
                    </div>  
                    <div className='match-card__bottom__team'>
                        <div className='match-card__bottom__team__left'>
                            <p>{ match.Home }</p>
                        </div>  
                        <div className='match-card__bottom__team__divider'>
                            <p>–</p>
                        </div>  
                        <div className='match-card__bottom__team__right'>
                            <p>{ match.Guest }</p>
                        </div>  
                    </div>  
                    { cta ? 
                    <div className='match-card__cta'>
                        <Button 
                            text={ isActive ? 'Tickets verfügbar' : 'Tickets nicht mehr verfügbar'}
                            border={ !small }
                            disabled={ !isActive }
                        /> 
                        {
                            (isActive && small) ?
                            <Button 
                                text={'Spiel auswählen'}
                                icon={'none'}
                                fill={ true }
                                onclick={onclick && onclick}
                            /> : null
                        }
                    </div>
                        : null}
                    <div className='match-card__custom-text'>
                        <p  >
                             {match.isFixed ? '' : withText}
                        </p>
                       
                    </div>
                </div>            
            </div>            
        </Aux>
    );
};

export default MatchCard;