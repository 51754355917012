import { createSlice } from '@reduxjs/toolkit'

// Slice
const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        isBannerShown: true,
        bannerImages: {
            desktop : null,
            mobile : null,
        }
    },
    reducers: {
        setIsBannerShown: (state, action) => {
            state.isBannerShown = action.payload;
        },
        setBannerImages: (state, action) => {
            state.bannerImages = action.payload;
        },
    }
});

export default bannerSlice.reducer;


// Actions
export const { setIsBannerShown, setBannerImages } = bannerSlice.actions;