import React, { useState } from 'react'
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import CardThankYou from '../component/Card_ThankYou/CardThankYou';
import '../style/Confirmation.scss'
import { useEffect } from 'react';
import { mainLoaderToggle } from '../../../../modules/helpers';
import _SCROLL_PAGE from '../../../../modules/ScrollPage';
import TitleContent from '../component/TitleContentConfirmation/TitleContentConfirmation';
export default function Confirmation(props) {

    const [isMobile, setIsMobile] = useState(false);
    const getDfl = sessionStorage.getItem('getDfl');

    useEffect(() => {
        const checkIsMobile = () => {
            const mobileWidthThreshold = 768;
            setIsMobile(window.innerWidth < mobileWidthThreshold);
        };
        const datenow = new Date();
        const dflDate = new Date('2024-07-30');
        checkIsMobile();
        mainLoaderToggle('hide');
        window.addEventListener("resize", checkIsMobile);
        _SCROLL_PAGE.ScrollToTop();
    
      return () => {
        window.removeEventListener('resize', checkIsMobile);
        mainLoaderToggle('show');
      }
    }, [])

    const img1 = isMobile ? '/resources/imagesBundesliga/sommer/thank-you/thanks1-sm.png': '/resources/imagesBundesliga/sommer/thank-you/thanks1.png';
    const img2 = isMobile ? '/resources/imagesBundesliga/sommer/thank-you/thanks2-sm.png': '/resources/imagesBundesliga/sommer/thank-you/thanks2.png';
    const img3 = isMobile ? '/resources/imagesBundesliga/sommer/thank-you/thanks3-sm.png': '/resources/imagesBundesliga/sommer/thank-you/thanks3.png';

    const tagging1 = {
        event: "subscribe_newsletter",
        category: "Thank You Page",
        action: "Go to Page",
        label: "ENG_TY_OTHER"
    }

    const tagging2 = {
        event: "go_to_products",
        category: "Thank You Page",
        action: "Go to Page",
        label: "ENG_TY_OTHER"
    }

    const tagging3 = {
        event: "discover_mmax_milka",
        category: "Thank You Page",
        action: "Go to Page",
        label: "ENG_TY_OTHER"
    }


    return (
        <div className='heimtrikot'>
            <div className='container_confirmation'>
                <div>
                    {
                        (getDfl === '1') ? 
                            <TitleContent 
                            heading='Vielen Dank' 
                            subheading1='Du hast erfolgreich an der Aktion „Gewinne dein Original Heimtrikot“ teilgenommen. Die Gewinnermittlung der Heimtrikots erfolgt während des Gewinnspielzeitraumes per Zufallsprinzip.'
                            subheading2='Übrigens: Denke daran, den <span style="font-family: Good Headline Pro Bold">Original-Kaufbeleg</span> gut aufzubewahren. Die Gewinner werden per E-Mail benachrichtigt.'
                            subheading3='Du hast dich für die Mitgliedschaft beim FC Milka angemeldet? Oder den Milka und/oder DFL Newsletter abonniert? Dann erhältst du weitere E-Mails von uns. Diese enthalten je einen Link, den du bitte anklickst, um die Anmeldung bzw. das Newsletter-Abonnement zu bestätigen.'
                            />
                            : 
                            <TitleContent 
                            heading='Vielen Dank' 
                            subheading1='Du hast erfolgreich an der Aktion „Gewinne dein original Heimtrikot“ teilgenommen. Die Gewinnermittlung der Heimtrikots erfolgt während des Gewinnspielzeitraumes per Zufallsprinzip.'
                            subheading2='Übrigens: Denke daran, den <span style="font-family: Good Headline Pro Bold">Original-Kaufbeleg</span> gut aufzubewahren. Die Gewinner werden per E-Mail benachrichtigt.'
                            subheading3='Du hast dich für die Mitgliedschaft beim FC Milka angemeldet? Oder den Milka Newsletter abonniert? Dann erhältst du weitere E-Mails von uns. Diese enthalten je einen Link, den du bitte anklickst, um die Anmeldung bzw. das Newsletter-Abonnement zu bestätigen.' 
                            />
                    }
                    <div className="confirmationReihe">
                        <div className="thank-you">
                            <CardThankYou img={img1} title='Milka Newsletter' text='Du willst keine spannenden Neuigkeiten mehr verpassen? Dann abonniere jetzt den kostenlosen Milka Newsletter! Regelmäßig informieren wir dich über neue Produkte, Gewinn-Aktionen und natürlich über alles, was beim FC Milka los ist. Worauf wartest du noch?' btn='NEWSLETTER ABONNIEREN' tagging={tagging1} link='https://www.milka.de/newsletter' />
                            <CardThankYou img={img2} title='Entdecke die Milka Favourites' text='Milka Favourites – das ist der leckere Mix, der alle zusammenbringt. Milka Favourites passen zum geselligen Zusammensein mit deinen Freunden und sind perfekt zum Teilen. Der einfach unwiderstehliche Snack in sechs beliebten Geschmacksrichtungen. Welche ist dein Favorit?' btn='MILKA FAVOURITES' tagging={tagging2} link='https://favourites.milka.de/' />
                            <CardThankYou img={img3} title='Milka Mmmax – mmmaximaler Genuss' text='Milka Mmmax Großtafeln bieten dir mehr von allem: mmmaximal viel zarte Alpenmilch Schokolade, mmmaximal großzügige Zutaten für ein mmmaximales Geschmackserlebnis. Entdecke den maximalen Milka Genuss.' btn='MILKA MMMAX' tagging={tagging3} link='https://mmmax.milka.de/' />
                        </div>
                    </div>
                    <Footersocial />
                </div>
            </div>
        </div>
    )
}
