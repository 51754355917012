import React, { Component } from "react";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import parse from "html-react-parser";
import Q4PopUpV1 from "../Weltmeister/Q4PopUpV1";
import WhiteSectionText from "../../../components/Content/Section/WhiteSectionText";
import { connect } from "react-redux";
import appGoogleTracking from "../../../modules/googletracking";
class BuzzerProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      gameStatus: {}
    }
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    const gameState = this.props.gameState;
    this.setState({ gameStatus: gameState });
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    return (
      <>
        {this.state.gameStatus.gameIsLive ? <WhiteSectionText
          paragraph={'Der FC Milka sorgt für ein unvergessliches Erlebnis. Nutze deine Chance, Rudi Völler persönlich kennenzulernen.Zusätzlich kannst du dir ein original Heimtrikot der Bundesliga / 2. Bundesliga der Saison 2022/23 sichern.'}
          textButton={'JETZT MITMACHEN'}
          handleChild={this.toggleModal.bind(this)}
          dataEvent={"buzzer_begin_participation"}
          dataCategory={"Product Page"}
          dataAction={"Click on Start"}
          dataLabel={"BUZZER_PROD_OTHER"}
        /> : null}
        {this.midSection()}
        <Q4PopUpV1 modal={this.state.modal} toggleModal={() => { this.toggleModal(); }} />
      </>

    );
  }

  midSection(props) {
    return (
      <>
        <div className="content-holding__heading closing product-wrapper weltmeister-product ticket-product prize-buzzer">
          <div className="content-end__text">
            <h1>LEGENDÄRER SCHOKOLADENGENUSS</h1>
            <p>
              Wenn die Milka All-Stars auflaufen, ist zarte Vielfalt garantiert: Ob die klassische Milka Tafelschokolade, cremige Milka Haselnusscreme aufs Brot oder die Milka Choco Mini Stars – hier drückt jeder den Lecker-Buzzer. Zusammen mit den tollen Produkten von OREO heißt es dann meisterlich OREO olé olé.
            </p>
          </div>

          <div className="chocolate">
            <img src="/resources/imagesBundesliga/buzzer_23/product/products.png" />
          </div>

          <div className="s-content-end">
            <div className="end-element">
              <div className="end-element__item">
                <div className="end-element__copy">
                  <h3>ZARTER GENUSS FÜR HERAUSRAGENDE HIGHLIGHTS</h3>
                  <p>
                    Entdecke jetzt die vielfältige Milka Top-Auswahl aus zarter Alpenmilch Schokolade und lass dich inspirieren. Welcher wird dein neuer Lieblingssnack?
                  </p>
                  <button
                    className="btn__container btn--secondary-lilac"
                    data-event="buzzer_more_from_MILKA"
                    data-category="Product Page"
                    data-action="Go to Page"
                    data-label="MILKA_PROD_OTHER"
                    onClick={appGoogleTracking.processEventCTANavLink}
                  >
                    <a href="https://www.milka.de/alle-produkte" target="_blank">
                      mehr von MILKA
                    </a>
                  </button>
                </div>
                <img src="/resources/imagesBundesliga/buzzer_23/product/teaser_1.png" />
              </div>
            </div>
          </div>

          <div className="s-content-end">
            <div className="end-element">
              <div className="end-element__item">
                <img src="/resources/imagesBundesliga/buzzer_23/product/teaser_2.png" />
                <div className="end-element__copy">
                  <h3>ZARTER KLASSIKER</h3>
                  <p>
                    Seit Generationen beliebt ist die Milka Tafel Schokolade. Ob ganz klassisch oder in vielfältigen Sorten – hier findet jeder seine Lieblingsschokolade.
                  </p>
                  <button
                    className="btn__container btn--secondary-lilac"
                    data-event="buzzer_more_from_MILKA"
                    data-category="Product Page"
                    data-action="Go to Page"
                    data-label="MILKA_PROD_OTHER"
                    onClick={appGoogleTracking.processEventCTANavLink} >
                    <a href="https://www.milka.de/alle-produkte" target="_blank">
                      mehr von MILKA
                    </a>
                  </button>
                </div>

              </div>
            </div>
          </div>
          <Footersocial />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  gameState: state.game
});
export default withTranslation()(withRouter(connect(mapStateToProps, null)(BuzzerProduct)));