
import React from 'react';
import GLOBAL_CONFIG from '../../../config/config';		        //Global Settings that contains URL etc
import BuzzerEndPenny from '../../Campaign/Buzzer/EndPenny';
import BuzzerPenny from '../../Campaign/Buzzer/Penny';
import HoldingPenny from '../../Campaign/Buzzer/HoldingPenny';
import BuzzerNetto from '../../Campaign/Buzzer/Netto';
import BuzzerKaufland from '../../Campaign/Buzzer/Kaufland';
import BuzzerEdeka from '../../Campaign/Buzzer/Edeka';
import BuzzerEndNetto from '../../Campaign/Buzzer/EndNetto';
import BuzzerEndKaufland from '../../Campaign/Buzzer/EndKaufland';
import BuzzerEndRewe from '../../Campaign/Buzzer/EndRewe';
import BuzzerRewe from '../../Campaign/Buzzer/Rewe';
import BuzzerGlobus from '../../Campaign/Buzzer/Globus';
import BuzzerEndEdeka from '../../Campaign/Buzzer/EndEdeka';
import HoldingEdeka from '../../Campaign/Buzzer/HoldingEdeka';
import HoldingKaufland from '../../Campaign/Buzzer/HoldingKaufland';
import HoldingKewe from '../../Campaign/Buzzer/HoldingKewe';
import HoldingNetto from '../../Campaign/Buzzer/HoldingNetto';
import BuzzerEndGlobus from '../../Campaign/Buzzer/EndGlobus';
import HoldingGlobus from '../../Campaign/Buzzer/HoldingGlobus';
import GlobusTerms from '../../Campaign/Buzzer/GlobusTerms';

const getKipsRouting = (props) => {
    const kipsRouting = [
        /* penny */
        {
            path : GLOBAL_CONFIG.Route.buzzer.penny,
            Component : () => ( <BuzzerPenny {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.endPenny,
            Component : () => ( <BuzzerEndPenny {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.holdingPenny,
            Component : () => ( <HoldingPenny {...props} /> )
        },

        /* netto */
        {
            path : GLOBAL_CONFIG.Route.buzzer.netto,
            Component : () => ( <BuzzerNetto {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.endnetto,
            Component : () => ( <BuzzerEndNetto {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.holdingnetto,
            Component : () => ( <HoldingNetto {...props} /> )
        },

        /* kaufland */
        {
            path : GLOBAL_CONFIG.Route.buzzer.kaufland,
            Component : () => ( <BuzzerKaufland {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.endkaufland,
            Component : () => ( <BuzzerEndKaufland {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.holdingkaufland,
            Component : () => ( <HoldingKaufland {...props} /> )
        },

        /* edeka */
        {
            path : GLOBAL_CONFIG.Route.buzzer.edeka,
            Component : () => ( <BuzzerEdeka {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.endEdeka,
            Component : () => ( <BuzzerEndEdeka {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.holdingEdeka,
            Component : () => ( <HoldingEdeka {...props} /> )
        },

        /* rewe */
        {
            path : GLOBAL_CONFIG.Route.buzzer.rewe,
            Component : () => ( <BuzzerRewe {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.endRewe,
            Component : () => ( <BuzzerEndRewe {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.holdingRewe,
            Component : () => ( <HoldingKewe {...props} /> )
        },

        /* globus */
        {
            path : GLOBAL_CONFIG.Route.buzzer.globus,
            Component : () => ( <BuzzerGlobus {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.endGlobus,
            Component : () => ( <BuzzerEndGlobus {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.holdingGlobus,
            Component : () => ( <HoldingGlobus {...props} /> )
        },
        {
            path : GLOBAL_CONFIG.Route.buzzer.globusTerms,
            Component : () => ( <GlobusTerms {...props} /> )
        },
    ];

    return kipsRouting;
}

export default getKipsRouting;