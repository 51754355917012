import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import { withTranslation } from "react-i18next";
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../../modules/googletracking";
import { mainLoaderToggle } from "../../../../modules/helpers";
import StepContent from "../../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../../config/config";
import _LOCAL from "../../../../Models/LocalStorageKeys";
import _SCROLL_PAGE from "../../../../modules/ScrollPage";

class SupercupGlobus extends React.Component {
    
    componentWillMount() {}

    componentDidMount(){
        this.props.loadGlobusCampaign();
        localStorage.clear();
    }
    
    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-netto kaufland edeka globus micro-globus">
                {this.headingSection(this.props)}
                {this.cardSection()}
                {this.footerSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection(props) {
        return (
            <div className="content-home endpage-rewe">
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <div className="micro-globus__title-container">
                            <h1>
                                Sichere dir jetzt mit Milka deine exklusive Gewinnchance
                            </h1>
                            <p>
                                Dich erwartet ein ganz besonderes Familienerlebnis mit bis zu 5 Personen beim Supercup. Inklusive Tickets, Rahmenprogramm, Übernachtung und Verpflegung. Dein Kind kann als Einlauf- oder Ballträgerkind die Bundesliga Stars hautnah erleben.
                            </p>
                        </div>
                        <div className="step-container">
                            <h3 className="step-title">
                                Mitmachen ist ganz einfach:
                            </h3>

                            <StepContent
                                step1 = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.1') }
                                step1sm = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.1') }
                                step1Text = {parse("Kaufe zwischen dem <span class=\"text--wheat\"><strong>02.05.2022</strong></span> und dem <span class=\"text--wheat\"><strong>05.06.2022 zwei Milka Produkte</strong></span> <strong>(Bitte den gut lesbaren Kaufbeleg aufbewahren)</strong>")}
                                step_arrow = "/resources/imagesBundesliga/Edeka/icons/arrow_right.png"
                                step2 = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.2') }
                                step2sm = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.2') }
                                step2Text = {parse("Lade <a href=\"/microsite/globus/mitmachen\">hier</a> bis zum <span class=\"text--wheat\"><strong>05.06.2022</strong></span> den <span class=\"text--wheat\"><strong>Kaufbeleg</strong></span> hoch. Ein Kaufbeleg pro Teilnahme.")}
                                step3 = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.3') }
                                step3sm = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.3') }
                                step3Text = {parse("Schon bist du im Gewinner-Lostopf! Nach Ablauf des Gewinnspiels am <span class=\"text--wheat\"><strong>05.06.2022</strong></span> teilen wir dir <span class=\"text--wheat\"><strong>per E-Mail</strong></span> mit, ob du gewonnen hast.")}
                            />

                            <NavLink to={GLOBAL_CONFIG.Route.globusMicroSite.participation} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                                <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                            </NavLink>
                            <p className="step-container--terms-link">
                                Hier findest du die  <a href="javascript:void(0)" onClick={this.scrollToTerms.bind(this)}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a> für unser Gewinnspiel.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    scrollToTerms(){
        _SCROLL_PAGE.ScrollSection(document.querySelector(".prizeRewe.campaign-footer"));
    }

    cardSection() {
        return (
            <div className="card-container">
                <div className="card-container__card">
                    <div className="card-container__card__tertiary">
                        <div className="image-container">
                            <img src="/resources/imagesBundesliga/buzzer_22/edeka/card/supercup_logo.png" alt="Dauerkarten der Saison"/>
                        </div>
                        <div className="text-container">
                            <h3>Der Supercup</h3>
                            <p>
                                Der Supercup bildet in der Regel den Auftakt der neuen Bundesliga Saison. Teilnehmer sind der Deutsche Meister und der Gewinner des DFB-Pokals der vorangegangenen Spielzeit.
                            </p>
                            <p>
                                Im Falle eines „Double-Siegers“ tritt der Bundesliga-Zweite gegen den Deutschen Meister und DFB-Pokalsieger an. Den Termin und Spielort für den Supercup legt der DFL Deutsche Fußball Liga e.V. fest.
                            </p>
                            <p>
                                Die Begegnungen erreichen nicht nur die Fans in Deutschland, sondern auch Zuschauer rund um den Globus. Seit der Supercup-Wiedereinführung im Jahr 2010 ehrt die DFL den Gewinner mit einer eigens dafür geschaffenen Trophäe.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <h3 className="terms-title">
                    Teilnahmebedingungen
                </h3>
                <p className="instruction">
                    <strong>Veranstalter:</strong> Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen. Teilnahmeberechtigt an der Verlosung sind Eltern von Kindern zwischen 8 und 12 Jahren mit Wohnsitz in Deutschland. Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen sowie jeweils deren Angehörige. Teilnahme durch Kauf von zwei Milka Produkten im Zeitraum vom 02.05. bis 05.06.2022 sowie Online-Registrierung und Hochladen eines Fotos des gut lesbaren, automatisch erstellten originalen Kaufbelegs (Kaufbeleg inkl. Einkaufsdatum, -uhrzeit und Belegsumme), Angabe der Milka Produkte, des Händlers/Kaufdatums auf einlaufkinder.fcmilka.de/mitmachen. Nicht teilnehmende Produkte unkenntlich machen. Kaufbeleg zur späteren Prüfung aufbewahren. Pro Kaufbeleg nur eine Teilnahme. Max. ein Gewinn pro Person. Gewinnermittlung der Verlosung durch Ziehung aus allen gültigen Teilnahmen am 07.06.2022. Die Gewinnbenachrichtigung erfolgt per E-Mail an die bei der Registrierung angegebene E-Mail-Adresse innerhalb einer Woche nach Ende des Teilnahmezeitraums.
                </p>
                <p className="instruction">
                    <strong>Gewinne:</strong> 12x Supercup Familienerlebnisse* (11x Einlaufkind, 1x Ballträgerkind für bis zu 5 Personen inkl. Tickets zum Spiel, Rahmenprogramm, An- und Abreise per Bahn 2. Klasse oder Kostenerstattung durch Tankgutscheine (Höhe abhängig von Gesamtstrecke), 2x Übernachtung im Doppel-/Familienzimmer inkl. Frühstück sowie Verpflegung). Voraussetzung, um an der Verlosung teilzunehmen und den Status des Einlaufkindes oder des Ballträgerkindes zu erhalten, ist die Berücksichtigung der oben aufgeführten Kriterien.
                </p>
                <p className="instruction">
                    <strong>Supercup-Familienerlebnis:</strong> Bei Nichtinanspruchnahme verfällt der Gewinn ersatzlos.<br/>
                    <strong>Supercup-Familienerlebnis:</strong> Die Gewinner von Supercup-Tickets erhalten von der zuständigen Agentur eine E-Mail mit den Informationen zum Gewinn sowie einer angehängten Einverständniserklärung. Die Eltern müssen die Einverständniserklärung ausfüllen, unterschreiben und per E-Mail an info@fc-milka.de senden, damit ihr Kind ein Einlaufkind beim Supercup Event werden kann. Dies muss innerhalb einer Frist von 10 Tagen nach Erhalt der E-Mail erfolgen.
                </p>
                <p className="instruction">
                    Die Angaben werden dann geprüft. Erfüllen die Gewinner die Teilnahmevoraussetzungen, erhalten diese anschließend einen Gutschein für das Familienerlebnis Supercup. Der Gutschein und das Anschreiben erklären genau, bis zu welchem Datum sich die Gewinner für die notwendigen Buchungen und Ticketsdirekt an die mit der Abwicklung und Organisation beauftragte Agentur Liga Travel wenden müssen.
                </p>
                <p className="instruction">
                    Wenn der Gewinner dies nicht pünktlich tut, kann der Preis nicht mehr beansprucht werden und der Veranstalter kann nach eigenem Ermessen an einen anderen Teilnehmer vergeben, der unter den gleichen Bedingungen bestimmt wird.
                </p>
                <p className="instruction">
                    Zum Zweck der öffentlichen Berichterstattung über das Fußballspiel oder die jeweilige Sportveranstaltung und das Einlaufen der Kinder können Bild- und Bildtonaufnahmen des teilnehmenden Kindes von der DFL, den mit ihr nach § 15 f. AktG verbundenen Unternehmen, den Fußballclubs/ ihren jeweiligen Verbänden und sonst befugten Dritten (z. B. Presse, Rundfunk) verarbeitet, verwertet und veröffentlicht werden. Mondelez kann diese Bild- und Bildtonaufnahmen und Fotos für die Nachberichterstattung für interne Zwecke sowie zu Werbezwecken nutzen. Hierzu holt Mondelez eine gesonderte Einwilligungserklärung der Teilnehmer ein. Die Rechtsgrundlage für die benannten Veröffentlichungen/ Nutzungen stellt das berechtigte Interesse der jeweiligen Unternehmen dar (Art. 6 Abs. 1 S. 1 Buchstabe f der DSGVO). Bei weitergehenden Nutzungen, die nicht von einem berechtigten Interesse abgedeckt sind, behält sich Mondelez vor, bei den Erziehungsberechtigten eine entsprechende Einwilligung (Art. 6 Abs. 1 S. 1 Buchstabe a der DSGVO) anzufragen. Es besteht kein Anspruch auf Veröffentlichung von Bild- und Bildtonaufnahmen, diese liegt im alleinigen Ermessen der benannten Unternehmen.
                </p>
                <p className="instruction">
                    Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID-19-Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation.
                </p>
                <p className="instruction">
                    *Barauszahlung und Rechtsweg ausgeschlossen. Nicht kombinierbar mit anderen Aktionen.
                </p>
            </div>
        )
    }
}
export default withTranslation()(SupercupGlobus);