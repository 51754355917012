import React from "react";
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';  
import { withRouter } from 'react-router-dom';
import Button from '../../../components/Content/Button/Button';
import FanCenterServices from "../../../Service/FanCenter";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import _LOCAL from "../../../Models/LocalStorageKeys";

import DropdownClub from "../../../components/Content/DropdownClub/DropdownClub";
import DynamicButton from "../../../components/Content/dynamicButton/DynamicBoutton";
import { mainLoaderToggle, optionListConstruct } from "../../../modules/helpers";
import JerseyStepIndicitor from "../ChooseJersey/StepIndicator";
import Loader from "../../../components/Overlays/Loader";
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial';
import _TICKET_TYPES from "../../../Models/TicketTypes";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import appGoogleTracking from "../../../modules/googletracking";
import Aux from "../../../hoc/Auxiliare";
import FanCenterSCBanner from "../Banner/FanCenterSCBanner";


const _DEFAULT_TEAMS = ["1. FC Köln","1. FC Union Berlin","1.FSV Mainz 05","Arminia Bielefeld","Bayer 04 Leverkusen","Borussia Dortmund","Borussia Mönchengladbach","Eintracht Frankfurt","FC Augsburg","FC Bayern München","FC Schalke 04","Hertha BSC Berlin","RB Leipzig","SC Freiburg","SV Werder Bremen","TSG 1899 Hoffenheim","VfB Stuttgart","Vfl Wolfsburg","1. FC Heidenheim 1846","1. FC Nürnberg","Eintracht Braunschweig","Erzgebirge Aue","FC St. Pauli","Fortuna Düsseldorf","Hamburger SV","Hannover 96","Holstein Kiel","Jahn Regensburg","Karlsruher SC","SC Paderborn 07","SpVgg Greuther Fürth","SV Darmstadt 98","SV Sandhausen","Vfl Osnabrück","Würzburger Kickers","1. FC Magdeburg","Eintracht Braunschweig","1. FC Kaiserslautern"];
class FavoriteClub extends React.Component {
    constructor() {
        super();

        this.StepList = [
            {
                display: "lieblingsclub",
                isInProgress: true,
                isComplete: false,
            },
            {
                display: "ticketauswahl",
                isInProgress: false,
                isComplete: false,
            },
            {
                display: "daten-check",
                isInProgress: false,
                isComplete: false,
            }
        ];

        this.state = {
            Season: "",
            TeamSelected: "",
            SimpleLoader: false,
            ErrorMessage: "",
            isMobile: false,
            activeButtom: "Bundesliga",
            GiftCategory: localStorage.getItem(_LOCAL.GiftCategory) || _TICKET_TYPES.CAT2,
            TypeTicket: "",
            allMatches: []
        }

        this.HeadingSection = this.HeadingSection.bind(this);
        this.resize = this.resize.bind(this);
        this.IsFavouriteTeamValid = this.IsFavouriteTeamValid.bind(this);
        this.getTeamIdSelected = this.getTeamIdSelected.bind(this);
        this.GetTypeTickets = this.GetTypeTickets.bind(this);
        this.TicketButtonTag = this.TicketButtonTag.bind(this);
    }
   
   

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    scrollTo(id) {
        setTimeout(() => {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
    }
      GetTypeTickets(_uid){
          var _data = {
              uid:_uid
            }
            // console.log("calling tickets");
      
        FanCenterServices.CheckStock(_data)
            .then((response) => {
            this.setState({
                SimpleLoader: true,
            });
            let {message, details} = response.data , {type}=details, {GameDays}=details;

            if (type === _TICKET_TYPES.TRICKOTSIGNED || type === _TICKET_TYPES.TRICKOTUNISIGNED) {
                    this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection + `?uid=${_uid}`);
            } else {
                // TYPE TICKETS IS CAT 2 OR VIP
                console.log("Set Type"+type);
            if(message === "OK"){
                localStorage.setItem(_LOCAL.TicketType,type);
                localStorage.setItem(_LOCAL.MatchesList,JSON.stringify(GameDays));
             
                this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType) || _TICKET_TYPES.CAT2});
                this.setState({allMatches: JSON.parse(localStorage.getItem(_LOCAL.MatchesList))});
            } else {
                // console.log("TYPE tickets:",type);
                if(message === "Order already received"){
                    localStorage.setItem(_LOCAL.ErrorTicketsType,"received");
                }
                else if(message === "Order too late"){
                    localStorage.setItem(_LOCAL.ErrorTicketsType,"late");
                }
                
                if (type === 'CAT2'){
                    localStorage.setItem(_LOCAL.ErrorTickets,type);
                } else if (type === 'VIP'){
                    localStorage.setItem(_LOCAL.ErrorTickets,type);
                }
                 this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.ticket.errorTickets);
            }
            }
                
            
            })
            .catch(() => {
            })
            .finally(() => {
            this.setState({
                SimpleLoader: false,
            });
            })


  }

    componentDidMount() {
        mainLoaderToggle('hide');
        _SCROLL_PAGE.ScrollToTop();
        window.addEventListener("resize", this.resize);
        this.resize();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const uid = urlParams.get('uid');
        if(!!uid)
        {
            localStorage.setItem('UID', uid.toString());
            console.log('UID', uid);
            this.GetTypeTickets(uid);
        }
    }

    componentWillMount() {
         localStorage.removeItem(_LOCAL.TicketSelected);
        if(localStorage.getItem(_LOCAL.AvailableTeams) === null) {
            // this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user);
        }
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return this.state.TypeTicket &&  this.state.TypeTicket !== "" ? this.affichage() : (this.activateLoader());
    }

    activateLoader(){
        // mainLoaderToggle('show');
        return "";
    }

    affichage() {
        mainLoaderToggle('hide');
            return(<Aux>
                <FanCenterSCBanner categorieImg={this.state.TypeTicket === _TICKET_TYPES.VIP ? "vip" : "cat"} />
                    <div className="buzzer-jersey favorite-club">
                            {this.HeadingSection()}

                            <form id="frm_team_selection"
                                className="form-team"
                                ref = { form => this._TEAM_SELECTION = form }
                                name="frm_team_selection" 
                                method="post" 
                                action="/"
                                onSubmit={this.SubmitHandler.bind(this)} >

                                {this.TeamSection(this.props)}
                                
                            </form>

                            {this.state.SimpleLoader ? <Loader /> : null}
                    </div>
                <Footersocial/>
            </Aux>)
    }

    TicketButtonTag = (dataLayerOption, dataLayerOptionVip) => {
        if(this.state.TypeTicket === _TICKET_TYPES.CAT2) {
            appGoogleTracking.dataLayerPush(dataLayerOption)
            console.log(dataLayerOption);
        }
        else if (this.state.TypeTicket === _TICKET_TYPES.VIP) {
            appGoogleTracking.dataLayerPush(dataLayerOptionVip)
            console.log(dataLayerOptionVip);
        }
    }

    HeadingSection() {
        return (
            <Aux>
              <div className="spaceTile-intropage">
                <div className="campaign-heading">
                  <JerseyStepIndicitor Steps={this.StepList} />
                    <div className="contenu">
                        {/* <h4 className="gobold-bold titre">von welchem club soll dein { this.state.TypeTicket === _TICKET_TYPES.VIP ? "vip-ticket" : "ticket"} der bundesliga / 
                            2. bundesliga der saison 2023/24 sein?</h4> */}

                            { this.state.TypeTicket === _TICKET_TYPES.VIP ? <h4 className="gobold-bold titre ticket-vip">von welchem club soll dein vip-ticket der bundesliga / 
                            2. bundesliga der saison 2024/25 sein?</h4> : <h4 className="gobold-bold titre ticket">von welchem club soll dein ticket der bundesliga / 
                            2. bundesliga der saison 2024/25 sein?</h4>}
                    </div>
                    <p className="p-fav" style={{ width: '100%' }}>Schnell sein lohnt sich, denn das Ticket-Kontingent ist begrenzt. Wähle jetzt deinen Lieblingsclub aus.</p>
                </div>
              </div>
            </Aux>
        );
    }

    TeamSelectionSection(props) {
        let teamArray = !!localStorage.getItem(_LOCAL.AvailableTeams) ? JSON.parse(localStorage.getItem(_LOCAL.AvailableTeams)) : _DEFAULT_TEAMS,
            footballClubContent = optionListConstruct(teamArray);

        return (
            <div className="form__container no-bottom">
                <DropdownClub
                    defaultImg={"/resources/imagesBundesliga/team-picture/team-default.png"}
                    divClass={"form-input__container dropdown-input__container form-input__dropdown full-width btn-bottom"}
                    class="team-picture"
                    id="team1"
                    title=""
                    image={props.t('Page.Participation.form.footballClub.images.image1')}
                    defaultValue={props.t('Page.Participation.form.footballClub.footballClubFieldText')}
                    team="team1"
                    content={parse(footballClubContent)}
                    noImage={true}
                    setFavouriteTeam={ this.FavouriteClubSelection.bind(this)}
                />

                { 
                    this.state.ErrorMessage.length > 0 ?
                    <p className="buzzer-error">{this.state.ErrorMessage}</p>
                    : null
                }
                <button type="submit" className="btn__container btn--red">WEITER</button>
            </div>
        );
    }

    FavouriteClubSelection( team ) {
        this.setState({
            TeamSelected: team,
            ErrorMessage: ""
        })
    }

    buttonSwiper(){
      const nextButton = () => {
        const next = document.getElementsByClassName('swiper-button-next')[0];
        this.setState({ activeButtom: "Bundesliga2" }, () =>{
            let a = document.getElementById('buttom-next');
            a.classList.remove('dynamic-button__child');
            a.classList.add('dynamic-button__child-active');
            let b = document.getElementById('bouton-dynamic-buttom-next');
            b.classList.remove('dynamic-button__child__first');
            b.classList.add('dynamic-button__child__second');
            
            let c = document.getElementById('buttom-prev');
            c.classList.remove('dynamic-button__child-active');
            c.classList.add('dynamic-button__child');
            let d = document.getElementById('bouton-dynamic-buttom-prev');
            d.classList.remove('dynamic-button__child__second');
            d.classList.add('dynamic-button__child__first');
        })
        next.click()
      } 

      const previousButton = () => {
        const prev = document.getElementsByClassName('swiper-button-prev')[0];
        this.setState({ activeButtom: "Bundesliga"}, () =>{
            let a = document.getElementById('buttom-prev');
            a.classList.remove('dynamic-button__child');
            a.classList.add('dynamic-button__child-active');
            let b = document.getElementById('bouton-dynamic-buttom-prev');
            b.classList.remove('dynamic-button__child__first');
            b.classList.add('dynamic-button__child__second');
            
            let c = document.getElementById('buttom-next');
            c.classList.remove('dynamic-button__child-active');
            c.classList.add('dynamic-button__child');
            let d = document.getElementById('bouton-dynamic-buttom-next');
            d.classList.remove('dynamic-button__child__second');
            d.classList.add('dynamic-button__child__first'); })
        prev.click()
        console.log('Prev -> '+this.state.activeButtom)
      } 

      return(
        <div className="group-bouton">
            <DynamicButton 
                id="buttom-prev" 
                onClick={() => { 
                    previousButton();
                    this.TicketButtonTag({
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "bundesliga",
                        dataLabel: "bundesliga_standard"}
                        ,{
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "bundesliga",
                        dataLabel: "bundesliga_vip"}
                    );}
                }
                isActive={this.state.activeButtom === "Bundesliga"} 
                text="Bundesliga" 
            />
            <DynamicButton 
                id="buttom-next" 
                onClick={() => { 
                    nextButton();
                    this.TicketButtonTag({
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "bundesliga2",
                        dataLabel: "bundesliga_standard"}
                        ,{
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "bundesliga2",
                        dataLabel: "bundesliga_vip"}
                    );}
                } 
                isActive={this.state.activeButtom === "Bundesliga2"} 
                text="2. Bundesliga" 
            />
        </div>
      )
    }

    IsNotAvalaibleTeam(list){
        let quota = 0;
        let result = false;
        if (list && list.length > 0) {
          list.forEach(element => {
            if (element.NumberAvailable <= 1) {
              quota++;
            }
          });
        }
        if(quota === list.length) result= true;
        return result;
    }

    TeamSection(props) {
        const imagePath = "/resources/bundesliga-logos/logos/";
        let footballClubContent = props.t('Page.Participation.form.footballClub.footballClubListName', { returnObjects: true });
        let teamsAvailable = !!localStorage.getItem(_LOCAL.AvailableTeams) ? JSON.parse(localStorage.getItem(_LOCAL.AvailableTeams)) : [];
            if(teamsAvailable.length > 0){
                footballClubContent = teamsAvailable;
            }
        let swiperSlides = [];
        let maxTeamToShow = this.state.isMobile ? 18 : 18;
        let count = 0;
        let teamsTemp = [];
        for(let i=0; i<footballClubContent.length; i++){
            let match = this.state.allMatches.filter( (item) => footballClubContent[i] === item.Home || footballClubContent[i] === item.Guest );
            teamsTemp.push((
                <div className={`team ${ this.IsNotAvalaibleTeam(match) ? "not-avalaible" : "" }  ${ this.state.TeamSelected === footballClubContent[i] ? "active" : "" }`} key={"team" +  i} 
                    onClick={()=>{
                        const favoriteTeamError = document.getElementById("favorite-team-error");
                            favoriteTeamError.style.display = "none";
                            favoriteTeamError.classList.remove("notvalid");
                        this.FavouriteClubSelection(footballClubContent[i]);
/*                         this.TicketButtonTag({
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "team",
                            dataLabel: `${footballClubContent[i]}_standard`}
                            ,{
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "team",
                            dataLabel: `${footballClubContent[i]}_vip`}
                        ) */
                    }}>
                    <div className={`team-selected${ this.state.TeamSelected === footballClubContent[i] ? "__active" : "" }`}>
                        <img src="/resources/imagesBundesliga/fanCenter/icons/close-team.png" alt="team-selected"/>
                    </div>
                    <div className="card-team">
                        <div className="team-logo">
                            <img src={imagePath+footballClubContent[i]+".svg"} className={`team-logo-${footballClubContent[i].replace(/\s/g, '').split('.').join("")}-img`} alt={footballClubContent[i]}/>
                        </div>
                        <p className="team-name">{footballClubContent[i]}</p>
                    </div>
                    { this.IsNotAvalaibleTeam(match) ? 
                    <div className="card-team__not-avalaible">
                        <ul><li></li></ul>
                        <span>Tickets nicht mehr verfügbar</span>
                    </div> : 
                    <button className="btn__container btn--red button-team" id={`submit-team${ this.state.TeamSelected === footballClubContent[i] ? "__active" : "" }`} onClick={() => {this.SubmitTeamSelected();this.TicketButtonTag({
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "to_tickets_section",
                            dataLabel: `to_tickets_section_standard`}
                            ,{
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "to_tickets_section",
                            dataLabel: `to_tickets_section_vip`}
                        )}}>{this.state.TypeTicket === "VIP" ? "Zu den VIP-Tickets" : "Zu den Tickets"}</button>
                    }
                </div>
            ));
            count++;
            if(count === maxTeamToShow || i === footballClubContent.length-1){
                const teamsSlide = teamsTemp;
                swiperSlides.push((
                    <SwiperSlide key={"team-slide-"+footballClubContent[i]} tag="li" style={{  width: '100%' }}>
                        <div className="chooseTeam--team-container" style={{ maxWidth: '100%' }}>
                            {teamsSlide}
                        </div>
                    </SwiperSlide>
                ));
                teamsTemp = [];
                count = 0;
            }
        }

        return (
            <div className="chooseTeam buzzer-form team-container__form" style={{ marginBottom: '0' }}>
                <h3 className="gobold-bold heading" id="top">wähle deinen lieblingsclub</h3>
                {this.buttonSwiper()}
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    pagination
                    wrapperTag="ul"
                    tag="section"
/*                     onSlideNextTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Right'
                        });
                    }}
                    onSlidePrevTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Left'
                        });
                    }} */
                >
                    {swiperSlides}
                </Swiper>
                <div className='match-list__cta-mobile'>
                    <Button
                        icon={'none'}
                        text={'Liga auswählen'}
                        image={'chevron-up.png'}
                        onclick={() => this.scrollTo('top')}
                    />
                </div>
                <p id="favorite-team-error">Bitte wähle deinen Lieblingsclub.</p>
            </div>
        );
    }

    IsFavouriteTeamValid(){
        const favoriteTeamError = document.getElementById("favorite-team-error");
        if(this.state.TeamSelected == ""){
            favoriteTeamError.style.display = "none";
            favoriteTeamError.classList.add("notvalid");
            return false;
        }
        favoriteTeamError.style.display = "none";
        favoriteTeamError.classList.remove("notvalid");
        return true;
    }

    getTeamIdSelected(){
        let teamIDs = !!localStorage.getItem(_LOCAL.AvailableTeamIDs) ? JSON.parse(localStorage.getItem(_LOCAL.AvailableTeamIDs)) : {};
            if(teamIDs.hasOwnProperty(this.state.TeamSelected)){
                return teamIDs[this.state.TeamSelected];
            } else {
                let listTeam = this.props.t('Page.Participation.form.footballClub.footballClubListObject', { returnObjects: true });
                for(let i = 0; i < listTeam.length; i++){
                    if(listTeam[i].name ==  this.state.TeamSelected){
                        return listTeam[i].id;
                    }
                }
            }
        return "id-unknown";
    }

    SubmitHandler(event) {
        event.preventDefault();
    }

    SubmitTeamSelected(){
        if(!!this.IsFavouriteTeamValid()) {
            localStorage.setItem(_LOCAL.FavoriteClub, this.state.TeamSelected);
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.teamSelection);
        } else {
            this.setState({
                ErrorMessage: "Bitte wähle deinen Lieblingsclub."
            })
        }
    }
}

export default withTranslation()(withRouter(FavoriteClub));