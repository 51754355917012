import React, { Component, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';			//Router component for React
import TagManager from 'react-gtm-module';					//GTM tag manager plugin
import axios from 'axios';  								//Ajax library
import GLOBAL_CONFIG from './config/config';
import _GAME_STATUS from './Models/GameStatus';		//Global Settings that contains URL etc
import Layout from './containers/Layout/Layout';			//Layout master component that includes : header, main, footer
import LayoutQ3 from './containers/Layout/Q3Promotion/LayoutQ3';
import LayoutReihe from './containers/Layout/ReihePromotion/LayoutReihe';
import LayoutHeimtrikot from './containers/Layout/HeimTrikotPromotion/LayoutHeimtrikot';
import LayoutQ4 from './containers/Layout/Q4Promotion/LayoutQ4';
import LayoutTickets from './containers/Layout/TicketsPromotion/LayoutTickets';
import LayoutBuzzer from './containers/Layout/BuzzerPromotion/LayoutBuzzer';
import LayoutTrikot from './containers/Layout/TrikotPromotion/LayoutTrikot';
import LayoutDoppelt from './containers/Layout/DoppeltPromotion/LayoutDoppelt';
import Q2Layout from './containers/Layout/Q2Promotion/Q2Layout';

import HamburgerMenu from './modules/hamburger-menu';		//Hamburegr menu mobile devices
import Scrolltop from './modules/scrolltop';				//Back to top Arrow on footer
import FooterAccordion from './modules/footeraccordion';	//Accordion on footer menu on mobile devices
import { servicePath, campaignSettings, is90minPromotion, isGlobusMicrosite, isPennyMicrosite, isWeltmeisterPromotion, isTicketsPromotion, isBuzzerPromotion, isTrikotPromotion, isDoppeltPromotion, isProd,isq2Promotion, isQ3Promotion, isReihePromotion, isHeimtrikotPromotion } from './modules/helpers';			//Helpers for Service path

import { I18nextProvider } from "react-i18next";
import i18next from './config/localisation';
import './styles/CONFIG/global.scss';						//Main CSS for all the pages
import { connect } from "react-redux";
import { setGameIsLive, setCountdown } from "./store/game";
import { setIsBannerShown } from "./store/banner";


//Process to get main settings for the site
const PROMOTION_SETTINGS = campaignSettings(GLOBAL_CONFIG);

window.PROMOTION_CAMPAIGN = PROMOTION_SETTINGS.campaignStatus;		//Set promotion campaign : rewe, kaufland or muller
window.GOOGLE_SITEKEY = PROMOTION_SETTINGS.siteKey;				//Sitekey for google recaptcha
window.PROMOTION = 'main';									//Set global variable for promotion : main, holding, end, extended
window.FILE_STATUS = false;									//Use on particpation page
window.PAGE_SECTION = '';
window.INNER_LINK = false;
window.PARTICIPATION_ID = null;
window.LAST_DAY_90MIN = false;

class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			campaign: true,										//True or false
			campaignStatus: 'holding',								//main, holding, end, extended
			localDev: false,											//Need to be false on staging and prod
			localDomain: 'localhost',								//Local domain : 3000 or 5000
			promotionCampaign: PROMOTION_SETTINGS.campaignStatus,	//Set promotional campaign : rewe, kaufland or muller
			is90minPromotion: false,	//Set promotional campaign : rewe, kaufland or muller
			isWeltmeisterPromotion: false,
			isTicketsPromotion: false,
			isBuzzerPromotion: false,
			// isTrikotPromotion: false,
			isq2Promotion: false,
			isReihePromotion: false,
			isHeimtrikotPromotion: false,
			isDoppeltPromotion: false,
			lastDay: false,
			GameStatus: true
		}

		this.is90minPromotion = is90minPromotion();
		this.isPennyMicrosite = isPennyMicrosite();
		this.isWeltmeisterPromotion = isWeltmeisterPromotion();
		this.isTicketsPromotion = isTicketsPromotion();
		this.isBuzzerPromotion = isBuzzerPromotion();
		// this.isTrikotPromotion = isTrikotPromotion();
		this.isq2Promotion = isq2Promotion();
		this.isReihePromotion = isReihePromotion();
		this.isHeimtrikotPromotion = isHeimtrikotPromotion();
		this.isDoppeltPromotion = isDoppeltPromotion();
		this.isProd = isProd();
	}

	domLoadModules() {
		new HamburgerMenu({
			menuSelector: '.js-hamburger',
			contentSelector: '.js-ham-content',
			modifier: 'header-menu--open',
			close: '.js-ham-close'
		});

		new Scrolltop({
			selector: '.js-scroll-top',
			heightActive: 600
		});

		new FooterAccordion();
	}

	localCampaignSimulation = () => {
		if (this.state.localDev === true && window.location.host.indexOf(this.state.localDomain) > -1) {
			this.setState({
				campaign: true,
				campaignStatus: 'main',	//main, holding, end  :  manual change,
				lastDay: false //set lastday of 90min promo
			});

			window.PROMOTION = 'main';
			window.LAST_DAY_90MIN = false
		}
	}

	getBaseName = () => {
		if (!this.isProd) {
			if (this.is90minPromotion) {
				return "/90min";
			}
			if (this.isWeltmeisterPromotion) {
				return "/weltmeister";
			}
			if (this.isDoppeltPromotion) {
				return "/doppeltgewinnen";
			}
			if (this.isTicketsPromotion) {
				return "/tickets";
			}
			if (this.isBuzzerPromotion) {
				return "/buzzer";
			}
			// if (this.isTrikotPromotion) {
			// 	return "/trikot";
			// }
			if (this.isq2Promotion) {
				return "/sommer";
			}
			if (this.isReihePromotion) {
				return "/reihe";
			}
			if (this.isHeimtrikotPromotion) {
				return "/heimtrikot";
			}
			
		}
		return "/";
	};

	loadCampaign = () => {
		let campaignStatus = 'global';
		if (this.is90minPromotion) {
			campaignStatus = 'global90min'
		}
		else if (this.isPennyMicrosite) {
			campaignStatus = 'globalPenny'
		}
		else if (this.isWeltmeisterPromotion) {
			campaignStatus = 'globalWeltmeister'
		}
		else if (this.isDoppeltPromotion) {
			campaignStatus = 'globalDoppelt'
		}
		else if (this.isTicketsPromotion) {
			campaignStatus = 'globalTickets'
		}
		else if (this.isBuzzerPromotion) {
			campaignStatus = 'globalBuzzer'
		}
		// else if (this.isTrikotPromotion) {
        //     campaignStatus = "globalTrikot";
        // }
		else if (this.isq2Promotion) {
            campaignStatus = "globalSommer";
        }
		else if (this.isReihePromotion) {
			return
        } 
		else if (this.isHeimtrikotPromotion) {
			console.log('campaignStatus')
			return
        }

		console.log('campaignStatus', campaignStatus)
		
		let getCampaignURL = servicePath({
			...GLOBAL_CONFIG.ServiceSettings,			//All url and webservice configuration
			campaign: this.state.promotionCampaign, 	//Set promotion campaign
			status: campaignStatus							//global as it must be loaded on all pages
		});

		axios.get(getCampaignURL)
			.then((response) => {
				// if (response.statusText === "OK") {
					
					if (response.data.success === true) {
						window.PROMOTION = response.data.status;	//Get promotional state : main, holding or end
						this.setState({
							campaign: true,
							campaignStatus: response.data.status,
							lastDay: response.data.lastday,
							GameStatus: response.data.gameIsLive
						});
						if (this.state.campaignStatus === "holding") {
							window.PROMOTION = "holding";
						}
						else if (this.state.campaignStatus === "main") {
							window.PROMOTION = "main";
						}
						else if (this.state.campaignStatus === "extended") {
							window.PROMOTION = "extended";
						}

						if (this.state.lastDay === true) {
							window.LAST_DAY_90MIN = true;
						}
						sessionStorage.setItem(GLOBAL_CONFIG.Session.campaignPlaceholder, response.data.placeholder);
						let countdown = response.data.countdown;
						if (typeof countdown === "string") {
							countdown = parseFloat(countdown);
						}
						if (countdown < 0) {
							countdown *= -1;
						}
						this.props.setCountdown(countdown);
						this.props.setGameIsLive(response.data.gameIsLive);
						localStorage.setItem(_GAME_STATUS.Game, this.state.GameStatus);
					} else {
						console.log('Error in response');
					}
				// } else {
				// 	console.log('Error in response - 2');
				// }
			})
			.catch((response) => {
				console.log('Error in response - 3', response);
			});

		this.localCampaignSimulation();
	}

	componentWillMount() {
		this.loadCampaign();
	}

	componentDidMount() {
		this.setState({
			is90minPromotion: this.is90minPromotion,
			isWeltmeisterPromotion: this.isWeltmeisterPromotion,
			isTicketsPromotion: this.isTicketsPromotion,
			isBuzzerPromotion: this.isBuzzerPromotion,
			// isTrikotPromotion: this.isTrikotPromotion,
			isq2Promotion: this.isq2Promotion,
			isReihePromotion: this.isReihePromotion,
			isHeimtrikotPromotion: this.isHeimtrikotPromotion,
			isDoppeltPromotion: this.isDoppeltPromotion
		});

		let gtmId = this.is90minPromotion ? 'GTM-K38CKGT' : 'GTM-57KW9HW';
		if (isGlobusMicrosite()) {
			gtmId = 'GTM-5PZDVKH';
		}
		if (isWeltmeisterPromotion()) {
			gtmId = 'GTM-NZVXH5S';
		}
		if (isTicketsPromotion()) {
			// gtmId = 'GTM-WHLKSQS'; /* Old GTM TAG */
			// gtmId = 'GTM-57KW9HW';
			gtmId = 'GTM-WHLKSQS';
		}
		if (isTrikotPromotion()) {
			gtmId = 'GTM-PHVF3X8';
		}
		if (isDoppeltPromotion()) {
			gtmId = 'GTM-T3TGFR3R';
		}
		if (isq2Promotion()) {
			gtmId = 'GTM-P4C5Z43F';
		}
		if(isReihePromotion()){
			gtmId = 'GTM-M6ST6TT8';
		}
		if(isHeimtrikotPromotion()){
			gtmId = 'GTM-KBRXCTPJ';
		}

		//modif eto
		const tagManagerArgs = { gtmId: gtmId };
		TagManager.initialize(tagManagerArgs);

		window.addEventListener('DOMContentLoaded', this.domLoadModules);
	}

	render() {
		return (
			<Suspense fallback="loading...">
				<I18nextProvider i18n={i18next}>
					<BrowserRouter basename={this.getBaseName()}>
						{this.getLayout()}
					</BrowserRouter>
				</I18nextProvider>
			</Suspense>
		);
	}

	getLayout = () => {
		const { campaign, campaignStatus } = this.state;
		const promotionCampaign = PROMOTION_SETTINGS.campaignStatus;
	  
		if (this.state.is90minPromotion) {
		  return <LayoutQ3 campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
		}

		if (this.state.isReihePromotion) {
			return <LayoutReihe campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
		}

		if (this.state.isHeimtrikotPromotion) {
			return <LayoutHeimtrikot campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
		}
	  
		if (this.state.isWeltmeisterPromotion) {
		  return <LayoutQ4 campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
		}
	  
		if (this.state.isTicketsPromotion) {
		  return <LayoutTickets campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
		}
	  
		if (this.state.isBuzzerPromotion) {
		  return <LayoutBuzzer campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
		}

		// if (this.state.isTrikotPromotion) {
		//   return <LayoutTrikot campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
		// }
		if (isq2Promotion()) {
		  return <Q2Layout campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
		}

		if (this.state.isDoppeltPromotion) {
		  return <LayoutDoppelt campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
		}
		return <Layout campaign={campaign} campaignStatus={campaignStatus} promotionCampaign={promotionCampaign} />;
	};
}

const mapStateToProps = state => ({
	gameState: state.game
});

const mapDispatchToProps = {
	setGameIsLive,
	setCountdown,
	setIsBannerShown
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
