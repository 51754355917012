import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import game from './game';
import banner from './banner';

const reducer = combineReducers({
    game,
    banner,
});

const store = configureStore({
  reducer,
});

export default store;