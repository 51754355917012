import React, { Component } from 'react'; 
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import GLOBAL_CONFIG from '../../../config/config';
import appGoogleTracking from '../../../modules/googletracking';
import { mainLoaderToggle } from '../../../modules/helpers';
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial';

class Q4End extends Component {
    
    componentDidMount(){
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');

        //Virtual page
        appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': GLOBAL_CONFIG.Route.end,
                'pageTitle': t('Meta.title')
            }
        });
    }

    componentWillUnmount(){
        //Show mainloader
        mainLoaderToggle('show');
    }

    render() { 

        const { t } = this.props;

        return (
            <div className="content-holding__heading closing weltmeister-end">
                <div className="content-end__text">
                    <h1>
                        Diese Aktion ist beendet.
                    </h1>
                    <p>
                        Freu dich auf viele weitere FC Milka Aktionen, die kommen werden.
                    </p>
                </div>
                <div className="s-content-end">

                    
                    <div className="end-element">
                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                            <div className="end-element__copy">
                                    <h3>FC Milka Mitglied werden</h3>
                                    <p>Jetzt kostenlos registrieren und viele Vorteile genießen. Sei dabei und melde dich an.</p>
                                <a href="https://fcmilka.de/account" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> REGISTRIEREN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                            <div className="end-element__copy">
                                    <h3>Nervennahrung nötig?</h3>
                                    <p>
                                        Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                                    </p>
                                <a href="https://www.milka.de/shop" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> SCHOKI SHOPPEN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/fancenter-box-3.png" />
                            <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                        Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                    </p>
                                <a href="https://www.milka.de/neues" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footersocial />
            </div>
        );
    }
  
}

export default withTranslation()(Q4End);