import React, { useEffect, useState } from "react";
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

const BannerVideo = (props) => {
    const basePath = "/resources/imagesBundesliga/buzzer-game/banner";
    const avalaible = props.avalaible;
    const images = {
        desktop: `banner${avalaible ? "-avalaible" : "-notavalaible"}.png`,
        desktoplarge: `banner${avalaible ? "-avalaible" : "-notavalaible"}-large.png`,
        mobile: `banner${avalaible ? "-avalaible" : "-notavalaible"}-sm.png`,
    }

    const imagesbanner = {
        desktop: `bannerleft.png`,
        mobile: `bannerleft-sm.png`,
    }

    const banner = {
        desktopImage: `${basePath}/${images.desktop}`,
        desktopImageLarge: `${basePath}/${images.desktoplarge}`,
        mobileImage: `${basePath}/${images.mobile}`
    }

    const leftbanner = {
        desktopImage: `${basePath}/${imagesbanner.desktop}`,
        mobileImage: `${basePath}/${imagesbanner.mobile}`
    }

    const [video, setVideo] = useState('');
    
    useEffect(() => {
        function handleResize() {
          const width = window.innerWidth;
          if (width > 1400) {
            setVideo(`${basePath}/thankyou-avalaible-sm.mp4`);
          } else {
            setVideo(`${basePath}/thankyou-avalaible-sm.mp4`);
          }
        }
    
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    const under = `${basePath}/under.png`;

    return (
        <>
            <div class="video-container">
                <div class={avalaible ? `video-container__play`: `video-container__play__image-left`}>
                    {   avalaible ? 
                        <div class="video-content">
                                    <Player 
                                        className="player-banner"
                                        autoPlay={true} 
                                        muted={true}
                                        loop={true}
                                        controls={true} 
                                        playsInline
                                        type="video/mp4"
                                        src={video} >
                                    </Player>
                        </div>
                        :    
                        <div className="bannerleft">
                            <picture>
                                <source srcSet={leftbanner.desktopImage} media="(min-width: 731px)" />
                                <source srcSet={leftbanner.mobileImage} media="(min-width: 481px)" />
                                <source srcSet={leftbanner.mobileImage} media="(min-width: 200px)" />
                                <img className="image-banner" src={leftbanner.mobileImage} alt="route" />
                            </picture>
                        </div>
                    }
                </div>
                <div class={avalaible ? `video-container__image`: `video-container__image image-left`}>
                    <picture>
                        <source srcSet={banner.desktopImageLarge} media="(min-width: 1620px)" />
                        <source srcSet={banner.desktopImage} media="(min-width: 731px)" />
                        <source srcSet={banner.mobileImage} media="(min-width: 481px)" />
                        <source srcSet={banner.mobileImage} media="(min-width: 200px)" />
                        <img className="image-overlay" src={banner.mobileImage} alt="route" />
                    </picture>
                    <div className="text-overlay">
                        <h2 className="title">Vielen Dank für deine Teilnahme</h2>
                        <p className="paragraph">{props.avalaible ? "Deine Teilnahme ist bei uns eingegangen. Wir drücken dir die Daumen, dass du ein original Bundesliga Heimtrikot der Bundesliga / 2.Bundesliga der Saison 2022/23 gewinnst (Werte der Trikots variieren je nach Club, Auswahl nach Verfügbarkeit über Milka-Fan-Center). Alle Teilnehmenden haben zusätzlich am Ende der Aktion die Chance, den exklusiven Manager-Tag mit Rudi Völler zu gewinnen. Übrigens: Denke daran, den Kaufbeleg gut aufzubewahren." : "Deine Teilnahme ist bei uns eingegangen. Wir drücken dir die Daumen, dass du gewinnst. Die Gewinnermittlung der original Bundesliga/ 2.Bundesliga Heimtrikots erfolgt innerhalb von 48 Stunden nach Ende des jeweiligen Wochenende. Die Gewinnermittlung des Manager-Tages mit Rudi Völler erfolgt am Aktionsende. Das Spiel Bundesliga Buzzer ist nicht länger verfügbar. Übrigens: Denke daran, den Original-Kaufbeleg gut aufzubewahren."}</p>
                    </div>
                </div>
            </div>
            {   avalaible ? 
                <div class="video-container__under">
                    <picture>
                        <img className="image-under" src={under} alt="route" />
                    </picture>
                </div>
                : null
            }
        </>
    )
}

export default BannerVideo;