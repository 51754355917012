import React from 'react'
const Notice= (props) => {

    let RenderParaText = (props.firstNotice !== undefined && props.firstNotice !== null) ? <p className="heading_dark-lilac">{props.firstNotice}</p> : null;
    let RenderParaText2 = (props.secondNotice!== undefined && props.secondNotice !== null) ? <p className="heading_dark-lilac headingSpace">{props.secondNotice}</p> : null;

    return (
        <div className="s-content-title notice">
            {RenderParaText}
            {RenderParaText2}
        </div>
    );
}

export default Notice;