import React  from 'react';
import ButtonSimple from '../ButtonSimple/ButtonSimple';

class CardValidation extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
            return (
                <div className="card-validation">
                    {this.props.withText ? (<p className='card-validation__text'>{ this.props.withText}</p>) : ''}
                    <ButtonSimple text={this.props.buttonText} onClick={this.props.onClick} />
             </div>
            )
        
    }
}
CardValidation.defaultProps = {
    buttonText: 'default',
};
export default CardValidation;