import React from "react";
import { NavLink, withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import { withTranslation } from "react-i18next";
//import BuzzerSlider from "./Slider";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import StepContent from "../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../config/config";
import KIPServices from "../../../Service/KIP_Pages";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import ParticipationNavigation from "../Buzzer/ParticipationNavigation";
import GiftContentFooter from "../../../components/Content/GiftContent/GiftContentFooter";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import _LOCAL from "../../../Models/LocalStorageKeys";


class PennyGondelkopfMain extends React.Component {
    componentDidMount() {
        if(this.props.isQ3){
            window.location = "https://fcmilka.de/penny";
        }
        this.LoadCampaign();
        localStorage.setItem(_LOCAL.CurrentKip, "Penny");
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-netto kaufland penny">
                {this.headingSection(this.props)}
                {/* this.cardSection() */}
                {this.footerSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection(props) {
        return (
            <div className="content-home endpage-rewe">
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <div className="title-container">
                            <h2>
                                Mitmachen & Match Attax Multipack erhalten
                            </h2>
                            <h4>
                                Jetzt die beliebten Spielkarten zur Bundesliga sammeln und Ausschau halten nach Star-Spielern, Helden von Morgen, Kreis der Besten und Bundesliga-Legenden. Jedes Match Attax Multipack enthält 30 Karten und 1 limitierte Karte.
                            </h4>
                        </div>
                        <div className="step-container">
                            <h3 className="step-title">
                                Mitmachen ist ganz einfach:
                            </h3>

                            <StepContent
                                step1 = { props.t('Page.Home.Steps.Buzzer-Main-Penny.1') }
                                step1sm = { props.t('Page.Home.Steps.Buzzer-Main-Penny.1') }
                                step1Text = {parse("Zwischen dem <span class=\"text--wheat\">05.09.</span> und dem <span class=\"text--wheat\">17.09.2022</span> Milka, TUC und/oder Wunderbar Produkte im Gesamtwert von mindestens 6€ bei PENNY kaufen.")}
                                step_arrow = "/resources/imagesBundesliga/Edeka/icons/arrow_right.png"
                                step2 = { props.t('Page.Home.Steps.Buzzer-Main-Penny.2') }
                                step2sm = { props.t('Page.Home.Steps.Buzzer-Main-Penny.2') }
                                step2Text = {parse("<a href=\"/penny-topps/mitmachen\">Hier</a> bis zum <span class=\"text--wheat\">18.09.2022</span> den <span class=\"text--wheat\">Kaufbeleg</span> hochladen. (Ein Kaufbeleg pro Teilnahme)")}
                                step3 = { props.t('Page.Home.Steps.Buzzer-Main-Penny.3') }
                                step3sm = { props.t('Page.Home.Steps.Buzzer-Main-Penny.3') }
                                step3Text = {parse("Wir prüfen jetzt den Kaufbeleg.")}
                                step4 = { props.t('Page.Home.Steps.Buzzer-Main-Penny.4') }
                                step4sm = { props.t('Page.Home.Steps.Buzzer-Main-Penny.4') }
                                step4Text = { parse("<span class=\"text--wheat\">Code</span> für ein Match Attax Multipack erhalten und unter de.topps.com einlösen.") }
                            />

                            
                            <button className="btn__container btn--secondary-lilac"><NavLink to={GLOBAL_CONFIG.Route.pennyMicrosite.participation} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}> JETZT MITMACHEN 
                            </NavLink></button>

                            <p className="step-container--terms-link">
                                Hier findest du die  <a href={GLOBAL_CONFIG.Route.pennyMicrosite.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    cardSection() {
        return (
            <div className="card-container">
                <div className="card-container__card">
                    <div className="card-container__card__primary">
                        <picture>
                            <source srcSet="/resources/imagesBundesliga/buzzer_22/penny/card/supercup-sm.png" media="(max-width: 480px)" />
                            <img src="/resources/imagesBundesliga/buzzer_22/penny/card/supercup.png" alt="Gold im Wert"/>
                        </picture>
                        <h3>100x 2 Tickets (CAT2) <br/> Saison 2022/23</h3>
                        <p>
                        Gewinne 1x 2 Tickets (Cat2) für ein Spiel der Bundesliga oder 2. Bundesliga für die Saison 2022/23 des Clubs der Wahl (Werte variieren je nach Club, Auswahl nach Verfügbarkeit über Milka Fan-Center, es besteht kein Anspruch auf Clubauswahl).
                        </p>
                    </div>
                    <div className="card-container__card__secondary">
                        <img src="/resources/imagesBundesliga/buzzer_22/penny/card/ball.png" alt="Dauerkarten der Saison"/>
                        <h3>Gewinne deinen Bundesliga Ball von DERBYSTAR</h3>
                        <p>Mit etwas Glück sicherst du dir einen von 100 Bundesliga Bällen Modell Bundesliga Brillant Replica, Saison 21/22 von DERBYSTAR.</p>
                    </div>
                </div>
            </div>
        );
    }


    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <button className="btn__container btn--secondary-lilac"><NavLink to={GLOBAL_CONFIG.Route.pennyMicrosite.terms} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}> zu den Teilnahmebedingungen </NavLink></button>
            </div>
        )
    }

    LoadCampaign = () => {
        KIPServices.PennyGondelkopf()
        .then( response => {
            var { status, success, message, placeholder } = response.data;

            if(success) {
                switch (status) {
                    case _STATUS_CAMPAIGN.holding:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.pennyMicrosite.holding
                        });
                        break;
                    case _STATUS_CAMPAIGN.closed:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.pennyMicrosite.end
                        });
                        break;
                
                    default:
                        mainLoaderToggle('hide');
                        break;
                }
            }
        })
        .catch( () => {
            mainLoaderToggle('hide');
        })
        .finally( () => {
            /*const introPage = document.querySelector(".content-home");
            if(introPage){
                _SCROLL_PAGE.ScrollSection(introPage);
            }else{
                _SCROLL_PAGE.ScrollToBanner();
            }*/

            _SCROLL_PAGE.ScrollToTop();
        })
    }

}

export default withTranslation()(withRouter(PennyGondelkopfMain));