import React from "react";
import { withRouter } from 'react-router-dom';
import _TICKET_TYPES from "../../../../Models/TicketTypes";
import TitleLogo from "../../../../components/Content/TitleLogo/TitleLogo";
import Loader from "../../../../components/Overlays/Loader";
import SizeCard from "../../../../components/SizeCard/SizeCard";
import { mainLoaderToggle } from "../../../../modules/helpers";
import JerseyNavigation from "../JerseyNavigation";
import JerseyStepIndicitor from "../StepIndicator";
import Footersocial from '../../../../components/Layout/FooterContent/FooterSocial';
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import GLOBAL_CONFIG from "../../../../config/config";
import _GIFT from "../../../../Models/GiftTypes";
import _LOCAL from "../../../../Models/LocalStorageKeys";
import _STATUS from "../../../../Models/ParticipationSteps";
import _TEAM_SEASONS from "../../../../Models/TeamSeasons";
import { NavLink } from 'react-router-dom';
import FanCenterSCBanner from "../../Banner/FanCenterSCBanner";
import appGoogleTracking from "../../../../modules/googletracking";
import Button from "../../../../components/Content/Button/Button";
class JerseySizeSelection extends React.Component {
    constructor() {
        super();

        this.StepList = [
            {
              display: "lieblingsclub",
              isInProgress: false,
              isComplete: true,
            },
            {
              display: "trikotauswahl",
              isInProgress: true,
              isComplete: false,
            },
            {
              display: "daten-check",
              isInProgress: false,
              isComplete: false,
            },
          ];

        this.state = { 
            TeamSelected: localStorage.getItem(_LOCAL.FavoriteClub) || "Borussia Mönchengladbach",
            SimpleLoader: false,
            SelectedSize: null,
            SelectedDOM: null,
            GiftType: _GIFT.Jersey,
            ErrorMessage: "",
            isJerseyAvailable: false,
            JerseySizes: {},
            IsCurrentSeason : true,
            isMobile: false,
            windowInnerWidth : window.innerWidth,
            SizeNotAvailable : "",
             TypeTicket: ""
        }

        this.HeadingSection = this.HeadingSection.bind(this);
        this.resize = this.resize.bind(this);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768, windowInnerWidth :  window.innerWidth});
    }

    componentDidMount() {
        if(localStorage.getItem(_LOCAL.FavoriteClub) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection)
        }
        window.addEventListener("resize", this.resize);
        this.resize();
        _SCROLL_PAGE.ScrollToTop();

        var size = JSON.parse(localStorage.getItem(_LOCAL.JerseySizes));

        if(size === null) {
            size = {
                M: {
                    code: "",
                    value: "M",
                    display: "M",
                    available: true
                },
                L: {
                    code: "",
                    value: "L",
                    display: "L",
                    available: true
                },
                XL: {
                    code: "",
                    value: "XL",
                    display: "XL",
                    available: true
                }
            }
        }else{
            size = size.sizesCurrent;
        }

        this.setState({
            JerseySizes: size,
        });
        mainLoaderToggle('hide');
        console.log("uid", localStorage.getItem(_LOCAL.UserId))
    }


    componentWillUnmount() {
          this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType)});
        let sizeSelectedStorage = localStorage.getItem(_LOCAL.SelectedSize);
        if (sizeSelectedStorage) {
            // alert("misy ao amin'localStorage")
            sizeSelectedStorage=JSON.parse(sizeSelectedStorage);
          this.setState({
            SelectedSize: {
                size: sizeSelectedStorage.size,
                code: sizeSelectedStorage.ode,
            }
        })  
        }
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div>
                <FanCenterSCBanner category={(this.state.TypeTicket === _TICKET_TYPES.TRICKOTUNISIGNED ? "-trikotauwal" : "-trikotauwal")}/>
                <div className="buzzer-jersey size-selection__container">
                    {this.HeadingSection()}
            
                    <form id="frm_jersey_size"
                        ref = { form => this._TEAM_SELECTION = form }
                        name="frm_jersey_size" 
                        method="post" 
                        action="/"
                    >

                        { this.JerseySizeSection() }

                    </form>

                    {
                        this.state.SimpleLoader ? <Loader /> : null
                    }

                    <Footersocial/>
                </div>
            </div>
        );
    }

    goBack() {
        const UID= localStorage.getItem(_LOCAL.UserId);
        this.props.history.push(
            GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection + "?uid=" + UID
        );
    }

    HeadingSection() {
        return (
            <div>
                {/* <JerseyNavigation />
                <TitleLogo />  */}
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <JerseyStepIndicitor Steps={this.StepList} /> 
                    </div>
                </div>
            </div>
        );
    }

    JerseySizeSection() {
      return (
            <div className="jersey-size-selection__container">
                <div className="jersey-size-selection__container__top">
                    <div className="contenu" style={{ marginTop: '5%' }}>
                        <div className="zuruck-button" >
                            <Button
                                text={'<  Zurück'}
                                    icon={'none'}
                                    fill={false}
                                onclick={() => this.goBack()} 
                            />
                        </div>
                        <h2 className="jersey-size-selection__container__top__title" id="top">wähle deine größe aus</h2>
                    </div>
                    
                    <p className="jersey-size-selection__container__top__text">Wähle ein Trikot aus und denk dran: Schnell sein lohnt sich. Falls das von dir ausgewählte Trikot zwischenzeitlich nicht mehr verfügbar sein sollte, wirst du wieder auf diese Seite geleitet.</p>
                </div>
                <div className="jersey-size-selection__container__bottom">
                   <SizeCard
                        favoriteClub = {this.state.TeamSelected}
                        JerseySizes = {this.state.JerseySizes}
                        onclick = {this.SubmitHandler.bind(this)}
                        SizeSelected = {this.SizeSelected.bind(this)}
                        GetTrikotbuttonTag = {this.TrikotButtonTag.bind(this)}
                   />
                </div>
            </div>
        );
    }

    TrikotButtonTag = (dataLayerOption, dataLayerOptionSigned) => {
        if(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED) {
            appGoogleTracking.dataLayerPush(dataLayerOption)
            console.log(dataLayerOption);
        }
        else {
            appGoogleTracking.dataLayerPush(dataLayerOptionSigned)
            console.log(dataLayerOptionSigned);
        }
    }

    

    OnHoverSizeAvailable(e){
        const textError = document.querySelector('.size-not-available');
        let classes = Array.from(e.target.classList);
        if(classes.includes("out-stock")){
            textError.classList.remove("none");
            this.setState({SizeNotAvailable : e.target.getAttribute("data-value")});
        }
        
    }

    OnLeaveSizeAvailable(e){
        const textError = document.querySelector('.size-not-available');
        textError.classList.add("none");
    }

    ChangeSizesBySeason(){
        let sizes = JSON.parse(localStorage.getItem(_LOCAL.JerseySizes));
        let size = {
            M: {
                code: "",
                value: "M",
                display: "M",
                available: true
            },
            L: {
                code: "",
                value: "L",
                display: "L",
                available: true
            },
            XL: {
                code: "",
                value: "X",
                display: "XL",
                available: true
            }
        };

        if(sizes === null ){
            sizes = {
                sizesCurrent : size,
                sizesPrevious : {
                    M: {
                        code: "",
                        value: "M",
                        display: "M",
                        available: true
                    },
                    L: {
                        code: "",
                        value: "L",
                        display: "L",
                        available: true
                    },
                    XL: {
                        code: "",
                        value: "X",
                        display: "XL",
                        available: true
                    }
                }
            }
        }

        let seasonStatus = this.state.IsCurrentSeason;
        if(this.state.IsCurrentSeason){
            size = sizes.sizesPrevious
            seasonStatus = false;
        }else{
            size = sizes.sizesCurrent
            seasonStatus = true;
        }

        this.setState(
            {
                IsCurrentSeason : seasonStatus,
                JerseySizes : size
            }
        );
    }

    displayLink(){
        return (
            <div className="jersey-sizes--link">
                <p className="lilac link">
                    <NavLink to={GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection} exact data-event="info-click" data-category="Team Selection" data-action="Team Selection" >
                    Zurück zur Clubauswahl
                    </NavLink>
                </p>
            </div>
            
        );
    }

    displaySubmit(){
        return (
            <div>
                { 
                    this.state.ErrorMessage.length > 0 ?
                    <p className="buzzer-error">{this.state.ErrorMessage}</p>
                    : null
                }

                {
                    this.state.isJerseyAvailable ?
                    <button type="submit" className="btn__container btn--red">WEITER</button>
                    : null
                }
            </div>
        );
    }

    SubmitHandler(event,tagging) {
        event.preventDefault();

        if(!!this.state.SelectedSize) {
            localStorage.setItem(_LOCAL.SelectedSize, JSON.stringify(this.state.SelectedSize));
            let [dataLayerOptionValue, dataLayerOptionSignedValue] = tagging;
            this.TrikotButtonTag(dataLayerOptionValue, dataLayerOptionSignedValue);
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey);
        } else {
            this.setState({
                ErrorMessage: "Please Select a Size"
            })
        }
    }

    SizeSelected(event,tagging) {
        let classes = Array.from(event.target.classList);
        if(classes.includes("out-stock")){
            return;
        }
        
        let _DOM = event.target,
            _selectedSize = _DOM.getAttribute("data-value"),
            _sizeCode = _DOM.getAttribute("data-code"),
            previouslySelected = this.state.SelectedDOM;
        
            localStorage.setItem(_LOCAL.SelectedTrikot, _sizeCode);
    
        if(!!previouslySelected) {
            previouslySelected.classList.remove("selected")
        }
    
        _DOM.classList.add("selected")
    
        this.setState({
            SelectedSize: {
                size: _selectedSize,
                code: _sizeCode,
            },
            SelectedDOM: _DOM
        })
        let [dataLayerOptionValue, dataLayerOptionSignedValue] = tagging;
        this.TrikotButtonTag(dataLayerOptionValue, dataLayerOptionSignedValue);
      }
}

export default withRouter(JerseySizeSelection);