import React from 'react';
import appGoogleTracking from '../../../modules/googletracking';

const footercopyright = () => (
    <div className="footer__copyright">
        <a href="https://www.milka.de/Static/mondelez-international" title="MDLZ International" target="_blank" rel="noopener noreferrer" data-event="go_to_mondelez" data-category="Footer" data-action="Go to Page" data-label="ENG_ALLPAGE_FOOTER" onClick={appGoogleTracking.processEventCTANavLink}> <span className="icon-mdlz"></span></a>
        <h4>
            2024 Mondelez Deutschland Services GmbH &amp; Co. KG – All rights reserved
        </h4>
    </div>
);

export default footercopyright;