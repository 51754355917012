import React from 'react'
import GLOBAL_CONFIG from '../../../config/config'
import Account from '../../Account/Account'
import AccountInformation from '../../AccountInformation/AccountInformation'
import Addresses from '../../Addresses/Addresses'
import Annoncememt from '../../Annoncement/Annoncememt'
import ChangePassword from '../../ChangePassword/ChangePassword'
import ConfirmationAccount from '../../ConfirmationAccount/ConfirmationAccount'
import LoginDetails from '../../LoginDetails/LoginDetails'
import PasswordChangeEmail from '../../PasswordChangeEmail/PasswordChangeEmail'
import Register from '../../Register/Register'
import RegisterDOI from '../../RegisterDOI/RegisterDOI'
import ResetPassword from '../../ResetPassword/ResetPassword'
import AccountDelete from '../../AccountDelete/AccountDelete'

const getAccountRouting = (context, props) => {
  const pagesMap = [
    { 
        path: GLOBAL_CONFIG.Route.account, 
        Component: () => <Account></Account> 
    },
    { 
        path: GLOBAL_CONFIG.Route.register, 
        Component: () => <Register /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.loginDetail, 
        Component: () => <LoginDetails /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.confirmationAccount, 
        Component: () => <ConfirmationAccount /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.resetPassword, 
        Component: () => <ResetPassword /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.announcement, 
        Component: () => <Annoncememt /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.changePassword, 
        Component: () => <ChangePassword /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.address, 
        Component: () => <Addresses /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.accountInformation, 
        Component: () => <AccountInformation /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.passwordChangeEmail, 
        Component: () => <PasswordChangeEmail /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.registerDOI, 
        Component: () => <RegisterDOI /> 
    },
    { 
        path: GLOBAL_CONFIG.Route.accountDeletion, 
        Component: () => <AccountDelete /> 
    },
  ];

  console.log(pagesMap);

  return pagesMap;
}

export default getAccountRouting;
