import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import StepContent from "../../../components/Content/StepContent/StepContent";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import BuzzerSlider from "./Slider";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import { withTranslation } from "react-i18next";
import ParticipationNavigation from "./ParticipationNavigation";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import WhiteSectionText from "../../../components/Content/Section/WhiteSectionText";
import TitleContentV1 from "../../../components/Content/TitleContent/TitleContentv1";
import StepBuzzerSpecific from "../../../components/Content/StepContent/StepSpecifiqBuzzer";
import Q4PopUpV1 from "../../../containers/Campaign/Weltmeister/Q4PopUpV1"

import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
class BuzzerMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            modal: false,
            gameStatus: {}
        }
        this.resize = this.resize.bind(this);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        window.addEventListener("resize", this.resize);
        this.resize();
        _SCROLL_PAGE.ScrollToTop();
        const gameState = this.props.gameState;
        this.setState({ gameStatus: gameState });

        const params = new URLSearchParams(window.location.search);
        if (params.get("status")?.includes("on")) {
            this.setState({ modal: true })
        }
    }

    componentWillMount() {
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }
    handleChild(callback) {
        // Here, you have the function from the child.
        callback();
    };

    render() {
        return (
            <div id="main-buzzer" className="buzzer23-main" style={{ backgroundColor: '#3B2772' }}>
                {this.headingSection(this.props)}
                {this.footerSection()}
                <Q4PopUpV1 modal={this.state.modal}
                    toggleModal={() => {
                        this.toggleModal();
                    }} />
                <Footersocial />
            </div>
        );
    }

    headingSection(props) {
        return (
            <div>
                <div className="content-home endpage-rewe buzzerGame">
                    <div className='mitmachen-heading' >
                        {this.state.gameStatus.gameIsLive ? <WhiteSectionText
                            paragraph={'Der FC Milka sorgt für ein unvergessliches Erlebnis: nutze deine Chance die Bundesliga Legende Rudi Völler persönlich kennenzulernen. Zusätzlich kannst du dir mit etwas Glück ein original Heimtrikot der Bundesliga / 2. Bundesliga der Saison 2022/23 sichern.'}
                            textButton={'JETZT MITMACHEN'}
                            handleChild={this.toggleModal.bind(this)}
                            dataEvent={"buzzer_begin_participation"}
                            dataCategory={"Start Page"}
                            dataAction={"Click Join Now"}
                            dataLabel={"BUZZER_OTHER_SP"}
                        /> : null
                        }
                    </div>
                    <div className="spaceTile-intropage">
                        <div className="campaign-heading buzzer23-container" >
                            <TitleContentV1 heading={'SO EINFACH GEHT’S:'} />
                            <StepBuzzerSpecific handleClick={this.toggleModal.bind(this)} />

                            <div className="cta-container">
                                <p className="text-section">*Hinweis: Befindet sich kein Produktcode auf dem Artikel, müssen die Zahlen des Barcodes eingegeben werden. Mehrfachteilnahmen mit neuem Produktcode innerhalb der Aktionsprodukte erlaubt. Bitte Original-Kaufbeleg als Nachweis aufbewahren.</p>
                                {/* <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}> */}
                                <button className="btn__container btn--secondary-lilac" style={{ border: '2px solid white' }} onClick={(e) => {
                                    this.setState({ modal: true });
                                    appGoogleTracking.processEventCTANavLink(e)
                                }}
                                    data-event="buzzer_begin_participation"
                                    data-category="Start Page"
                                    data-action="Click Join Now"
                                    data-label="BUZZER_OTHER_SP"> JETZT MITMACHEN </button>
                                {/* </NavLink> */}
                                <p className="edeka-terms" id="edeka-terms">Hier findest du die <a href={GLOBAL_CONFIG.Route.terms} target="_blank" style={{ color: "white", fontFamily: "Good Headline Pro Bold" }} onClick={appGoogleTracking.processEventCTA}
                                    data-event="buzzer_click_conditions"
                                    data-category="Start Page"
                                    data-action="Click on Conditions of Participation"
                                    data-label="BUZZER_OTHER_SP"> Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" style={{ color: "white", fontFamily: "Good Headline Pro Bold" }} onClick={appGoogleTracking.processEventCTA}
                                        data-event="buzzer_click_privacy_policy"
                                        data-category="Start Page"
                                        data-action="Click on Conditions of Participation"
                                        data-label="BUZZER_OTHER_SP"> Datenschutzbestimmungen</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    footerSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <TitleContentV1 heading={'diese gewinne warten auf dich'} />
                <div className="prizeRewe-card">
                    <div className="prizeRewe-card__primary">
                        <picture>
                            <source srcSet="/resources/imagesBundesliga/buzzer_23/konfetti.png" media="(min-width: 1024px)" />
                            <source srcSet="/resources/imagesBundesliga/buzzer_23/konfetti-sm.png" media="(min-width: 769px)" />
                            <source srcSet="/resources/imagesBundesliga/buzzer_23/konfetti-sm.png" media="(max-width: 480px)" />
                            <img src="/resources/imagesBundesliga/buzzer_23/konfetti.png" alt="Gold im Wert" />
                        </picture>
                        <h3>GEWINNE DEINEN MANAGER-TAG MIT RUDI VÖLLER</h3>
                        <p>
                            {this.state.gameStatus.gameIsLive ? "Kröne deine Saison 2022/23 und triff die Fussball-Legende. Unter allen Teilnehmenden wird ein exklusiver Manager-Tag mit Rudi Völler für 2 Personen verlost inkl. An- und Abreise, 2x Übernachtung im 5-Sterne-Hotel, Verpflegung und attraktivem Rahmenprogramm bei einem Bundesliga Club in Deutschland." :
                                "Es gibt nur einen Rudi Völler! Erlebe die Bundesliga Legende hautnah und gewinne einen exklusiven Manager-Tag mit Rudi Völler für 2 Personen inkl. An- und Abreise, 2x Übernachtung im 5-Sterne-Hotel, Verpflegung, attraktivem Rahmenprogramm bei einem Bundesliga Club in Deutschland." }</p>
                    </div>
                    <div className="prizeRewe-card__secondary">
                        <picture>
                            <source srcSet="/resources/imagesBundesliga/buzzer_23/season-trickots.png" media="(min-width: 1024px)" />
                            <source srcSet="/resources/imagesBundesliga/buzzer_23/season-trickots-sm.png" media="(min-width: 769px)" />
                            <source srcSet="/resources/imagesBundesliga/buzzer_23/season-trickots-sm.png" media="(min-width: 480px)" />
                        <img src="/resources/imagesBundesliga/buzzer_23/season-trickots.png" alt="Dauerkarten der Saison" style={{ 'width': '91%' }} />
                        </picture>
                        <h3>1 VON 1.000 ORIGINAL HEIMTRIKOTS</h3>
                        <p>
                            {this.state.gameStatus.gameIsLive ? "Buzzere die Spielergebnisse der Rückrunde und nutze zusätzlich deine Chance auf 1 von 1.000 original Bundesliga Heimtrikots der Bundesliga / 2. Bundesliga der Saison 2022/23. (Werte der Trikots variieren je nach Club, Auswahl nach Verfügbarkeit über Milka-Fan-Center)."
                                : "Nutze zusätzlich deine Chance auf 1 von 1.000 original Heimtrikots der Bundesliga / 2. Bundesliga der Saison 2022/23. (Werte der Trikots variieren je nach Club, Auswahl nach Verfügbarkeit über Milka-Fan-Center). " }
                            </p>
                    </div>
                </div>
            </div>
        )
    }
    oldStep(props) {
        return (
            <StepContent
                step1={props.t('Page.Home.Steps.Buzzer-Main.1')}
                step1sm={props.t('Page.Home.Steps.Buzzer-Main.1')}
                step1Text={parse("Kaufe zwischen dem 03.01.2022 und dem 27.02.2022 ein Milka Aktionsprodukt. (Bitte Kassenbon aufbewahren)")}
                step2={props.t('Page.Home.Steps.Buzzer-Main.2')}
                step2sm={props.t('Page.Home.Steps.Buzzer-Main.2')}
                step2Title={parse("Lade den <span class=\"text--wheat\"> Kassenbon </span>den bis zum 23.05.2021 hoch und gib deine persönlichen Daten ein.")}
                step3={props.t('Page.Home.Steps.Buzzer-Main.3')}
                step3sm={props.t('Page.Home.Steps.Buzzer-Main.3')}
                step3Title={parse("​<span class=\"text--wheat\">Rubbellos</span> auswählen, freirubbeln und direkt erfahren, ob du zu den Champions gehörst.")}
                step4={props.t('Page.Home.Steps.Buzzer-Main.4')}
                step4sm={props.t('Page.Home.Steps.Buzzer-Main.4')}
                step5={props.t('Page.Home.Steps.Buzzer-Main.5')}
                step5sm={props.t('Page.Home.Steps.Buzzer-Main.5')}
            />

        )
    }
    toggleModal() {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    }

}
const mapStateToProps = state => ({
    gameState: state.game
});
/* export default withTranslation()(BuzzerMain); */
export default withTranslation()(withRouter(connect(mapStateToProps, null)(BuzzerMain)));