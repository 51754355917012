import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import appGoogleTracking from '../../../modules/googletracking';
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial';
import { mainLoaderToggle } from '../../../modules/helpers';
import GLOBAL_CONFIG from '../../../config/config';

class End23 extends Component {

    componentDidMount() {
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');

        //Virtual page
        appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': GLOBAL_CONFIG.Route.end,
                'pageTitle': t('Meta.title')
            }
        });
    }

    componentWillUnmount() {
        //Show mainloader
        mainLoaderToggle('show');
    }

    render() {

        const { t } = this.props;

        return (
            <div className="content-holding__heading closing weltmeister-end">
                <div className="content-end__text">
                    <h1>
                        Diese Aktion ist beendet.
                    </h1>
                    <p>
                        Freu dich auf viele weitere FC Milka Aktionen, die kommen werden!
                    </p>
                </div>

                <div className="content-home endpage-buzzer-q3">
                    <div className="s-content-end">
                        <div className="end-element">
                            <div className="end-element__item">
                                <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                                <div className="end-element__copy">
                                    <h3>Zarte Vielfalt</h3>
                                    <p>Für jeden Anlass bietet dir Milka eine große Auswahl zum Naschen, Teilen und Verschenken.</p>
                                    <a href="https://www.milka.de/shop" target="_blank" exact data-event="custom_event_click" data-category="click_action" data-action="subscribe" data-label="milka_cocoa_life_subscription" onClick={appGoogleTracking.processEventCTANavLink}>
                                        <button className="btn__container btn--secondary-lilac"> JETZT VERSCHENKEN </button>
                                    </a>
                                </div>
                            </div>


                            <div className="end-element__item">
                                <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                                <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                        Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                    </p>
                                    <a href="https://www.milka.de/neues" target="_blank" exact data-event="custom_event_click" data-category="click_action" data-action="shop_page" data-label="delicate_pauses" onClick={appGoogleTracking.processEventCTANavLink}>
                                        <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                    </a>
                                </div>
                            </div>


                            <div className="end-element__item">
                                <img src="/resources/imagesBundesliga/buzzer/end/fancenter-box-3.png" />
                                <div className="end-element__copy">
                                    <h3>Milka Engagement</h3>
                                    <p>
                                        Du möchtest mehr über das Engagement von Milka im Kakaoanbau erfahren? Oder wissen, warum Milka so zart ist?
                                    </p>
                                    <a href="https://www.milka.de/uber-milka/engagement" target="_blank" exact data-event="custom_event_click" data-category="click_action" data-action="discover" data-label="new_from_milka_end" onClick={appGoogleTracking.processEventCTANavLink}>
                                        <button className="btn__container btn--secondary-lilac"> MEHR ÜBER MILKA </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footersocial />
            </div>
        );
    }

}

export default withTranslation()(End23);