// import React, {Component} from 'react';

import React from 'react';
import GLOBAL_CONFIG from '../../config/config';
import _LOCAL from '../../Models/LocalStorageKeys';
import Button from '../Content/Button/Button';
import ButtonSimple from '../Content/ButtonSimple/ButtonSimple';
import { withRouter } from 'react-router';


class SizeCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toggle: false,
      favoriteClub: this.props.favoriteClub || "Borussia Mönchengladbach",
      JerseySizes: {},
      SelectedDOM: null,
      SelectedSize: null,
      allJersey: JSON.parse(localStorage.getItem(_LOCAL.TrikotsList)),
      UserId: ""
		};
		
	  this.toggleState = this.toggleState.bind(this);
	  this.isAvailable = this.isAvailable.bind(this);

	}

  isAvailable(size, list) {
    let result = false;
    if (list && list.length > 0) {
      list.forEach(element => {
        if (element.size === size && element.NumberAvailable > 0) {
          result = [true, element.ID];
        }
      });
    }
    return result;
  }

  componentDidMount() {
    const club = localStorage.getItem(_LOCAL.FavoriteClub);
    let jersey = this.state.allJersey.filter( (item) => club === item.Team );
    console.log('jersey',jersey)
    var size = JSON.parse(localStorage.getItem(_LOCAL.JerseySizes));

    if(size === null) {
        size = {
            M: {
                code: this.isAvailable("M",jersey)[1],
                value: "M",
                display: "M",
                available:  this.isAvailable("M",jersey)[0]
            },
            L: {
                code: this.isAvailable("L",jersey)[1],
                value: "L",
                display: "L",
                available: this.isAvailable("L",jersey)[0]
            },
            XL: {
                code: this.isAvailable("XL",jersey)[1],
                value: "XL",
                display: "XL",
                available: this.isAvailable("XL",jersey)[0]
            }
        }
        }else{
            size = size.sizesCurrent;
        }

        this.setState({
            JerseySizes: size
        });
      this.setState({UserId: localStorage.getItem(_LOCAL.UserId)});
    }

	toggleState() {
		this.setState({
			toggle: !this.state.toggle
		});
	}

  selectTeam() {
    localStorage.removeItem(_LOCAL.FavoriteClub);
    localStorage.removeItem(_LOCAL.SelectedSize);
    this.props.history.push(
      GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection+ `?uid=${ this.state.UserId }`
    );
  }

  GetTrikotbuttonTag(dataLayerOption, dataLayerOptionSigned) {
    this.props.GetTrikotbuttonTag(dataLayerOption, dataLayerOptionSigned);
  }
  
  SizeButtonTag(size) {
    let sizes = size.toLowerCase();
    if (sizes === "m") {
      return (
        [{
          dataEvent: "custom_event_click",
          dataCategory: "click_action",
          dataAction: "medium_size",
          dataLabel: `medium_size_original_heimtrikots`}
          ,{
          dataEvent: "custom_event_click",
          dataCategory: "click_action",
          dataAction: "medium_size",
          dataLabel: `medium_size_signierte_trikots`}]
      )
    }
    else if (sizes === "l") {
      return (
[        {
          dataEvent: "custom_event_click",
          dataCategory: "click_action",
          dataAction: "large_size",
          dataLabel: `large_size_original_heimtrikots`}
          ,{
          dataEvent: "custom_event_click",
          dataCategory: "click_action",
          dataAction: "large_size",
          dataLabel: `large_size_signierte_trikots`}]
      )
    }
    else if (sizes === "xl") {
      return (
[        {
          dataEvent: "custom_event_click",
          dataCategory: "click_action",
          dataAction: "extra_large_size",
          dataLabel: `extra_large_size_original_heimtrikots`}
          ,{
          dataEvent: "custom_event_click",
          dataCategory: "click_action",
          dataAction: "extra_large_size",
          dataLabel: `extra_large_size_signierte_trikots`}]
      )
    }
  }
/*   SizeSelected(e, tagging) {
    this.props.SizeSelected(e,tagging);
  } */

  OnClick(e,tagging) {
    this.props.onclick(e,tagging);
  }

	render() {
		return (
      <div className="sizeCard">
        <div className="sizeCard__left">
          <div className="sizeCard__left__choices">
          <div className="size-boxes">
          {
              Object.keys(this.state.JerseySizes).map((key, index) => {
                  return (
                      <button 
                        key={key+index} 
                        className={ this.state.JerseySizes[key].available ? "" : "out-stock"} 
                        type="button" 
                        onClick={(e)=> { this.props.SizeSelected(e,this.SizeButtonTag(this.state.JerseySizes[key].value));}} 
                        data-code={this.state.JerseySizes[key].code} 
                        data-value={this.state.JerseySizes[key].value} 
                      >
                        {this.state.JerseySizes[key].display}
                      </button>
                  );
              })
          }
          </div>
          </div>
          <div className="sizeCard__left__cta">
            <ButtonSimple text="GRÖßE AUSWÄHLEN" onClick={(e)=>{this.OnClick(e,[{
                      dataEvent: "custom_event_click",
                      dataCategory: "click_action",
                      dataAction: "choose_size",
                      dataLabel: `choose_size_original_heimtrikots`}
                      ,{
                      dataEvent: "custom_event_click",
                      dataCategory: "click_action",
                      dataAction: "choose_size",
                      dataLabel: `choose_size_signierte_trikots`}]);}} />
          </div>
        </div>
        <div className="sizeCard__right">
            <div className='match-list__top__club'>
              <div className='match-list__top__club__top'>
                Dein Lieblingsclub
              </div>
              <div className='match-list__top__club__bottom'>
                <div className='match-list__top__club__selected'>
                  <div className='match-list__top__club__selected__image'>
                    <img src={'/resources/imagesBundesliga/team-picture/'+this.state.favoriteClub+'.png'} alt= { 'team' }/>
                  </div>
                  <div className='match-list__top__club__selected__name'>
                    {this.state.favoriteClub}
                  </div>
                </div>
              </div>
              <div className='sizeCard__right__button'>
                  <Button
                    text={'×  Ändern'}
                    icon={'none'}
                    onclick={() => {this.selectTeam();this.GetTrikotbuttonTag({
                      dataEvent: "custom_event_click",
                      dataCategory: "click_action",
                      dataAction: "andern",
                      dataLabel: `andern_original_heimtrikots`}
                      ,{
                      dataEvent: "custom_event_click",
                      dataCategory: "click_action",
                      dataAction: "andern",
                      dataLabel: `andern_signierte_trikots`})}}
                  />
                </div>
            </div>
        </div>
      </div>
		);
	}
}
export default withRouter(SizeCard);