import React, { Component } from 'react';
import { NavLink,useLocation } from 'react-router-dom';
import Aux from '../../../hoc/Auxiliare';
import GLOBAL_CONFIG from '../../../config/config';
import appGoogleTracking from '../../../modules/googletracking';

/* 
    NavLink is use as internal link for Routing : working as SPA - no page load
    Google tagging plan for NavLink : appGoogleTracking.processEventCTANavLink
*/

class NavigationIntropage extends Component {
  
  
    render() {


                
            return (
                <div className="fanCenter-menu">
                    <div className="page__menu">
                        <ul className="navigation main-nav menu-lilac">
                            <li>
                                <NavLink to={GLOBAL_CONFIG.Route.intropage} exact  data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink}>ÜBERSICHT</NavLink>
                            </li>
                            <li>
                                <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink}>GEWINNSPIEL</NavLink>
                            </li>
                            <li>
                                <NavLink to={GLOBAL_CONFIG.Route.fanCenter.main} exact className="active" data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink}>FAN-CENTER</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
               

            );
    }
}
export default NavigationIntropage;