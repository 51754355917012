const _TEAM_SEASONS = {
    "1. FC Heidenheim 1846": "2021/22",
    "1. FC Kaiserslautern": "2021/22",
    "1. FC Köln": "2021/22",
    "1. FC Magdeburg": "2021/22",
    "1. FC Nürnberg": "2021/22",
    "1. FC Union Berlin": "2021/22",
    "1. FSV Mainz 05": "2021/22",
    "1.FSV Mainz 05": "2021/22",
    "Arminia Bielefeld": "2021/22",
    "Bayer Leverkusen": "2021/22",
    "Borussia Dortmund": "2021/22",
    "Borussia Mönchengladbach": "2021/22",
    "Eintracht Braunschweig": "2020/21",
    "Eintracht Frankfurt": "2021/22",
    "Erzgebirge Aue": "2021/22",
    "FC Augsburg": "2021/22",
    "FC Bayern München": "2021/22",
    "VFL Osnabrück": "2020/21",
    "Vfl Osnabrück": "2020/21",
    "FC Schalke 04": "2021/22",
    "FC St. Pauli": "2021/22",
    "Fortuna Düsseldorf": "2021/22",
    "Hamburger SV": "2021/22",
    "Hannover 96": "2021/22",
    "Würzburger Kickers": "2020/21",
    "Hertha BSC Berlin": "2021/22",
    "Hertha BSC": "2021/22",
    "Holstein Kiel": "2021/22",
    "Jahn Regensburg": "2021/22",
    "Karslruher SC": "2021/22",
    "Karlsruher SC": "2021/22",
    "RB Leipzig": "2021/22",
    "SC Freiburg": "2021/22",
    "SC Paderborn 07": "2021/22",
    "SpVgg Greuther Fürth": "2021/22",
    "SV Darmstadt 98": "2021/22",
    "SV Sandhausen": "2021/22",
    "SV Werder Bremen": "2021/22",
    "TSG 1899 Hoffenheim": "2021/22",
    "VFB Stuttgart": "2021/22",
    "VfB Stuttgart": "2021/22",
    "VFL Bochum": "2021/22",
    "VFL Wolfsburg": "2021/22",
    "Vfl Wolfsburg": "2021/22",
    "Dynamo Dresden": "2021/22",
    "FC Ingolstadt 04": "2021/22",
    "FC Hansa Rostock": "2021/22",
    "Bayer 04 Leverkusen": "2021/22",
    "Borussia M'gladbach": "2021/22",
    "FC Erzgebirge Aue": "2021/22",
    "Erzgebirge Aue": "2021/22",
    "Hertha BSC ": "2021/22",
    "SSV Jahn Regensburg": "2021/22",
    "Jahn Regensburg": "2021/22",
    "TSG Hoffenheim": "2021/22",
    "VFL Bochum 1848": "2021/22"
};

export default _TEAM_SEASONS;