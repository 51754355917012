import React, { Component } from 'react';
import { mainLoaderToggle } from "../../modules/helpers";
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config'; 
import Footersocial from "../../components/Layout/FooterContent/FooterSocial";


class NoProductCode extends Component {

    componentDidMount() {
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div>
                {this.headingSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <div>
                <div className="no-product-code__wrapper">
                    <h2>Du hast noch keinen<br/> Produktcode eingegeben</h2>
                    <p>Damit du beim FC Milka Gewinnspiel “Gewinne jeden Tag in 90 Minuten” teilnehmen kannst, musst du zunächst das Teilnahmeformular ausfüllen und deinen Produktcode eingeben. Klicke dazu ganz einfach auf den Button “JETZT TEILNEHMEN”. Nach erfolgreicher Teilnahme erhältst du von uns eine E-Mail mit einem Link zur Gewinnvergabe für den darauf folgenden Tag.</p>
                    <div className="form-button register-button">
                        <NavLink to={GLOBAL_CONFIG.Route.participation} exact data-event="info-click" data-category="Informational Action" data-action="T&amp;C" >
                            <button className="btn__container btn--red">
                                JETZT TEILNEHMEN
                            </button>                                            
                        </NavLink>
                    </div>     
                </div>
            </div>
        );
    }
}

export default NoProductCode;