import axios from "axios";
import GLOBAL_CONFIG from "../config/config";
import { servicePath, ServicesUrl } from "../modules/helpers";

const participationService = () => {
    const _URL = servicePath({
        ...GLOBAL_CONFIG.ServiceSettings,
        status: 'participation'
    }),
        _SERVICE_URL = ServicesUrl();

    const participate = (_form_data) => {
        return axios({
            method: 'post',
            url: _URL,
            data: _form_data,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    };

    const validateWinner = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/CampaignGame/SubmitInstantWin`,
            data: _data
        })
    };

    const submitCode = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/CampaignGame/SubmitParticipation`,
            data: _data,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }

    const participateWeltmeister = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/CampaignGame/SubmitParticipation`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    const participateTrikot = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/TrikotGame/SubmitParticipation`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    const participateGewinnen = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Gewinnen/SubmitParticipation`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    const participateTickets = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Tickets/SubmitParticipation`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }
    const participateSommer = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Sommer/SubmitParticipation`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    const participateReihe = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Reihe1/SubmitParticipation`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }
    const participateHeimtrikot = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Heimtrikot/SubmitParticipation`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    const submitCodePennyMicroSite = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Kips/GondelkopfParticipation`,
            data: _data,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }

    const submitCodeWeltmeister = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/CampaignGame/SubmitLotCode`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    const submitCodeTickets = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Tickets/SubmitLotCode`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    const Q3 = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Participation/SubmitQ3`,
            data: _data
        })
    };

    const participateGlobusMicroSite = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Globus/SubmitGlobusParticipation`,
            data: _data,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }

    const sendMail = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/CampaignGame/NewsletterReg`,
            data: _data
        })
    }

    const sendMailTickets = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Tickets/NewsletterReg`,
            data: _data
        })
    }

    const sendMailGewinnen = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Gewinnen/NewsletterReg`,
            data: _data
        })
    }

    const sendMailTrikotPromo = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/TrikotGame/NewsletterReg`,
            data: _data
        })
    }

    const sendMailPennyMicroSite = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Kips/GondelkopfNewsletterReg`,
            data: _data
        })
    }

    const sendMailSelfie = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/CampaignGame/NewsletterReg`,
            data: _data
        })
    }

    const submitSelfie = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Selfie/SubmitSelfie`,
            data: _data,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }

    const supportingProfile = (_data) => {
        return axios({
            method: 'post',
            url: `https://milka-supporting.azurewebsites.net/supporting/crm/profile`,
            data: _data
        })
    }
    const submitLotCode = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Sommer/SubmitLotCode`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    const submitLotCodeReihe = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Reihe1/SubmitLotCode`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }
    const submitLotCodeHeimtrikkot = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/Heimtrikot/SubmitLotCode`,
            data: _data,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    return {
        Participate: Q3,
        ValidateCode: submitCode,
        ParticipateWeltmeister: participateWeltmeister,
        ParticipateTrikot: participateTrikot,
        ParticipateGewinnen: participateGewinnen,
        ParticipateTickets: participateTickets,
        ValidateCodePennyMicroSite: submitCodePennyMicroSite,
        ValidateCodeweltmeister: submitCodeWeltmeister,
        ValidateWinner: validateWinner,
        SubmitCodeTickets: submitCodeTickets,
        SendMail: sendMail,
        SendMailTrikotPromo: sendMailTrikotPromo,
        SendMailGewinnen: sendMailGewinnen,
        SendMailTickets: sendMailTickets,
        SendSelfie: submitSelfie,
        SendMailSelfie: sendMailSelfie,
        SupportingProfile: supportingProfile,
        ParticipateGlobusMicroSite: participateGlobusMicroSite,
        SendMailPennyMicroSite: sendMailPennyMicroSite,
        SubmitLotCode: submitLotCode,
        SubmitLotCodeReihe: submitLotCodeReihe,
        submitLotCodeHeimtrikkot: submitLotCodeHeimtrikkot,
        ParticipateSommer: participateSommer,
        ParticipateReihe: participateReihe,
        participateHeimtrikot: participateHeimtrikot
    }
}

const ParticipationService = participationService();

export default ParticipationService;