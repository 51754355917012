import React from 'react';


class BuzzerGeneratedScore extends React.Component {

    constructor(props) {
        super(props);
        this.ShowIfNotDefault = this.ShowIfNotDefault.bind(this);
    }

    render() {
        return (
            <div onClick={this.props.onClick} className={"buzzer-generated-score " + this.props.status}>
                {/* {this.props.status === 'select' &&
                    <div className='buzzer-generated-score__arrow'><img src='/resources/imagesBundesliga/buzzer_23/buzzer/arrow-selected-score.gif' />
                    </div>
                } */}
                <div className='buzzer-generated-score__number'>
                    <p>{this.props.number}</p>
                </div>
                {this.ShowIfNotDefault()}
            </div>
        )
    }
    ShowIfNotDefault() {

        if (this.props.status === 'generated' || this.props.status === 'select' || this.props.status === 'selected') {

            return (
                <div className='buzzer-generated-score__details'>
                    <div className='buzzer-generated-score__details__team'>
                        <img src={this.props.firstTeamLogo} alt='team-image' />
                    </div>
                    <div className='buzzer-generated-score__details__match-score'>
                        <p> {this.props.firstTeamScore} <span className='buzzer-generated-score__details__match-score__separator'>:</span>  {this.props.secondTeamScore} </p>
                    </div>
                    <div className='buzzer-generated-score__details__team'>
                        <img src={this.props.secondTeamLogo} alt='team-image' />
                    </div>
                </div>
            )
        } else {
            return (<div className='buzzer-generated-score__details'></div>)
        }
    }
}
export default BuzzerGeneratedScore;
BuzzerGeneratedScore.defaultProps = {
    status: "", // , generated , select , selected
    number: "1",
    firstTeamLogo: '/resources/bundesliga-logos/svg/logo-35.svg',
    firstTeamScore: '0',
    secondTeamLogo: '/resources/bundesliga-logos/svg/logo-36.svg',
    secondTeamScore: '0',
}

