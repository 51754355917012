import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import { withTranslation } from "react-i18next";
import BuzzerSlider from "./Slider";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import StepContent from "../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../config/config";
import KIPServices from "../../../Service/KIP_Pages";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import ParticipationNavigation from "./ParticipationNavigation";
import GiftContentFooter from "../../../components/Content/GiftContent/GiftContentFooter";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import _LOCAL from "../../../Models/LocalStorageKeys";

class BuzzerGlobus extends React.Component {
    componentWillMount() {
        if(this.props.isQ3){
            window.location = "https://fcmilka.de/globus";
        }
        this.LoadCampaign();
        //localStorage.setItem(_LOCAL.CurrentKip, "Globus");
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-netto kaufland edeka globus">
                {this.headingSection(this.props)}
                {this.cardSection()}
                {this.footerSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection(props) {
        let hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://weltmeister.fcmilka.de" : "/weltmeister";
        return (
            <div className="content-home endpage-rewe">
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <div className="title-container">
                            <h2>
                                Sichere dir jetzt deine doppelte Gewinnchance mit Milka und Oreo bei der Globus Zusatzverlosung
                            </h2>
                            <h4>
                                Im Aktionszeitraum vom 03.10.2022 bis 18.12.2022 ein Produkt von Milka oder Oreo in deinem Globus Markt kaufen, Produktcode bis zum 31.12.2022 auf www.weltmeister.fcmilka.de/mitmachen eingeben und mit etwas Glück ein exklusives VIP-Coaching mit einer Bundesliga Legende gewinnen.
                            </h4>
                            <div className="legend-wrapper">
                                <div className="legend-img"><img src="/resources/imagesBundesliga/weltmeister/globus/legends-new.png" alt="legends" className="img-legend"/></div>
                                <div className="legend-content">
                                    <h2>Gewinne 1x VIP-Coaching</h2>
                                    <p>Dich erwartet ein VIP-Besuch der Extraklasse, denn eine Bundesliga Legende kommt als VIP-Coach zu einem deiner wichtigsten Fußballspiele. Mit wertvollen Tricks & Taktiktipps hilft euch die Legende, das Beste aus euch herauszuholen. </p>
                                </div>
                            </div>
                        </div>
                        <div className="step-container">
                            <h3 className="step-title">
                                Mitmachen ist ganz einfach:
                            </h3>

                            <StepContent
                                step1 = { parse('/resources/imagesBundesliga/weltmeister/globus/step-1.png') }
                                step1sm = { parse('/resources/imagesBundesliga/weltmeister/globus/step-1.png') }
                                step1Text = {parse("Kaufe zwischen dem <span class=\"text--wheat\">03.10.2022</span> und dem <span class=\"text--wheat\">18.12.2022</span> ein Milka oder Oreo Produkt. <span class=\"text--wheat\">(Bitte den gut lesbaren Kaufbeleg aufbewahren.)</span")}
                                step_arrow = "/resources/imagesBundesliga/Edeka/icons/arrow_right.png"
                                step2 = { parse('/resources/imagesBundesliga/weltmeister/globus/step-2.png') }
                                step2sm = { parse('/resources/imagesBundesliga/weltmeister/globus/step-2.png') }
                                step2Text = {parse("Gib <a href=\"https://weltmeister.fcmilka.de/#participate\">hier</a> bis zum <span class=\"text--wheat\">31.12.2022</span> den <span class=\"text--wheat\">Produktcode</span> von der Produktverpackung ein*. (Ein Produktcode pro Teilnahme.)")}
                                step3 = { props.t('/resources/imagesBundesliga/weltmeister/globus/step-3.png') }
                                step3sm = { props.t('/resources/imagesBundesliga/weltmeister/globus/step-3.png') }
                                step3Text = {parse("Schon bist du im Gewinner-Lostopf! Nach Ablauf des Gewinnspiels am <span class=\"text--wheat\">31.12.2022</span> teilen wir dir <span class=\"text--wheat\">per E-Mail</span> mit, ob du gewonnen hast.")}
                             
                            />

                            
                            <button className="btn__container btn--secondary-lilac"><a href={`${baseDomaine}${GLOBAL_CONFIG.Route.home}?kip=globus`} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>JETZT MITMACHEN</a></button>
                            
                            <p className="step-container--terms-link">
                                Hier findest du die  <a href={`${GLOBAL_CONFIG.Route.buzzer.globusTerms}`}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a> für unser Gewinnspiel.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    cardSection() {
        let hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://weltmeister.fcmilka.de" : "/weltmeister";
        return (
            <div className="card-container">
                <h2>
                Sichere dir jetzt deine doppelte Gewinnchance:<br/>
                Neben dem VIP-Coaching mit einer Bundesliga Legende kannst du beim FC Milka Gewinnspiel „Du und der Weltmeister“ einen Tag mit deinem Weltmeister Lukas Podolski oder Rudi Völler gewinnen.
                </h2>

                
                <button className="btn__container btn--secondary-lilac"><a href={`${baseDomaine}${GLOBAL_CONFIG.Route.home}?kip=globus`} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>JETZT MITMACHEN</a></button>
                
                <p className="terms-link">
                    Hier findest du die <a href={`${GLOBAL_CONFIG.Route.buzzer.globusTerms}`}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a> für unser Gewinnspiel.
                </p>
                <p className="terms-faq">
                    Noch Fragen zur Aktion? Hier geht es zu den <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.buzzer.faq}`}>FAQ</a>.
                </p>
            </div>
        );
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">

                <h3 className="terms-title">
                    Teilnahmebedingungen
                </h3>
                <h4 className="terms-subtitle">
                    Ergänzend zu den vollständigen <a href={GLOBAL_CONFIG.Route.buzzer.globusTerms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzhinweisen</a> zu dem Gewinnspiel „Du & der Weltmeister“ gelten für die Zusatzverlosung bei Globus die folgenden Teilnahmebedingungen:
                </h4>
                <div className="instruction-wraper">
                    <p className="instruction">
                        <strong>Veranstalter:</strong> Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen. Teilnahmeberechtigt: Natürliche Personen ab 18 Jahren mit Wohnsitz in Deutschland. Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen sowie jeweils deren Angehörige. Teilnahme durch Kauf eines Produktes von Milka und/oder OREO im Zeitraum vom 03.10. bis 18.12.2022 sowie Eingabe des Produktcodes (auf der Produktverpackung), Angabe der Aktionsmarke, des Händlers/Kaufdatums auf www.weltmeister.fcmilka.de/globus bis zum 31.12.2022. Die Gewinnermittlung erfolgt unter allen Teilnehmern per Zufallsprinzip nach Ende des Aktionszeitraums am 03.01.2023. Die Gewinner werden im Anschluss innerhalb von 48 Stunden per E-Mail informiert.
                    </p>
                    <p className="instruction">
                        <strong>Gewinne:</strong> 1x VIP-Coaching mit einer Bundesliga Legende <br/>(inkl. An- & Abreise, attraktivem Rahmenprogramm und Legendentreffen)
                    </p>
                    <p className="instruction">
                        Der Gewinn steht unter der aufschiebenden Bedingung, dass der Gewinner nach Kontaktaufnahme der vom Veranstalter mit der Ausführung des Events beauftragten Agentur per Post zunächst die bei der Teilnahme angegebenen Daten bestätigt sowie das Anmeldeformular vollständig unterschrieben innerhalb einer Frist von 10 Tagen einsendet. Sofern der Gewinner dies unterlässt, kann der Gewinn nicht mehr in Anspruch genommen werden und der Veranstalter kann den Gewinn nach eigenem Ermessen verfallen lassen oder an einen weiteren Teilnehmer, der unter denselben Bedingungen ermittelt wird, vergeben. Sofern die Teilnehmer die Teilnahmevoraussetzungen erfüllen, d. h. die bei der Teilnahme angegebenen Daten werden fristgemäß bestätigt, werden die Gewinner bei der Organisation der Veranstaltung durch das Agenturpersonal begleitet. Der Veranstaltungstag wird nach Absprache mit den Gewinnern und nach Verfügbarkeit der Bundesliga Legende definiert. Es besteht kein Anspruch auf Auswahl einer Bundesliga Legende. 
                    </p>
                    <p className="instruction">
                    Von dem Event können Filme und Fotos von dem Veranstalter, Globus, der DFL, den mit ihr verbundenen Unternehmen, den beteiligten Fußballclubs / ihren jeweiligen Verbänden und sonst befugten Dritten (z. B. Presse, Rundfunk) im Rahmen der Werbung der Marke „Milka“ oder „OREO“ oder Globus oder der Bundesliga in verschiedenen Medien (online einschließlich Sozialer Medien, Print und TV) veröffentlicht werden. Mondelez kann diese Bild- und Bildtonaufnahmen und Fotos für die Nachberichterstattung für interne Zwecke sowie zu Werbezwecken nutzen. Hierzu holt Mondelez eine gesonderte Einwilligungserklärung der Teilnehmer ein. Die Rechtsgrundlage für die benannten Veröffentlichungen/Nutzungen stellt das berechtigte Interesse der jeweiligen Unternehmen dar (Art. 6 Abs. 1 S. 1 Buchstabe f der DSGVO). Bei weitergehenden Nutzungen, die nicht von einem berechtigten Interesse abgedeckt sind, behält sich Mondelez vor, bei den Gewinnern, ihren Begleitpersonen sowie den jeweiligen Erziehungsberechtigten eine entsprechende Einwilligung (Art. 6 Abs. 1 S. 1 Buchstabe a der DSGVO) anzufragen.
                    </p>
                    <p className="instruction">
                    Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen, und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID-19-Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation. 
Rechtsweg und Barauszahlung ausgeschlossen. 
                    </p>
                    <p className="instruction">
                    <strong>Sonstiges:</strong> Im Übrigen gelten die Teilnahmebedingungen des Gewinnspiels „Du & der Weltmeister“. Weitere Informationen, vollständige Teilnahmebedingungen und Datenschutzhinweise unter <a href="https://www.weltmeister.fcmilka.de/teilnahmebedingungen" rel="noopener noreferrer" target="_blank">https://www.weltmeister.fcmilka.de/teilnahmebedingungen</a>. 
                    </p>
{/*                     <p className="instruction">
                        Weitere Informationen, vollständige Teilnahmebedingungen und Datenschutzhinweise unter <a href="https://www.90min.fcmilka.de/teilnahmebedingungen" rel="noopener noreferrer" target="_blank">www.90min.fcmilka.de/teilnahmebedingungen</a>. 
                    </p> */}
                </div>
                
            </div>
        )
    }

    LoadCampaign() {
        KIPServices.Globus()
        .then( response => {
            var { status, success, message, placeholder } = response.data;

            if(success) {
                switch (status) {
                    case _STATUS_CAMPAIGN.holding:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.holdingGlobus
                        });
                        break;
                    case _STATUS_CAMPAIGN.closed:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.endGlobus
                        });
                        break;
                
                    default:
                        mainLoaderToggle('hide');
                        break;
                }
            }
        })
        .catch( () => {
            mainLoaderToggle('hide');
        })
        .finally( () => {
            _SCROLL_PAGE.ScrollToTop();
        })
    }

}
export default withTranslation()(BuzzerGlobus);