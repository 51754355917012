import React from "react";
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import flatpickr from "flatpickr";
import ReCAPTCHA from "react-google-recaptcha";

// User defined components
import StepContent from "../../../../components/Content/StepContent/StepContent";
import { mainLoaderToggle, ServerDate } from "../../../../modules/helpers";
import appGoogleTracking from "../../../../modules/googletracking";
import AccountServices from "../../../../Service/Account";
import AccountModel from "../../../../Models/Account";
import AccountModels from "../../../../Models/Account";
import Salutations from "../../../../Models/Salutation";
import Checkbox from "../../../../components/Controls/Checkbox";
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import Loader from "../../../../components/Overlays/Loader";

// Date selector STYLES
import 'flatpickr/dist/flatpickr.css';
import ValidateForm from "../../../../modules/validate";
import GLOBAL_CONFIG from "../../../../config/config";
import ParticipationService from "../../../../Service/Participation";
import _ERROR_MESSAGES from "../../../../Models/ErrorMessages";
import _LOCAL from "../../../../Models/LocalStorageKeys";
import _BUZZER_MESSAGE from "../../../../Models/BuzzerErrorMessage";

class SupercupParticipationGlobus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SimpleLoader: false,
            CodeIsValid: true,
            ShowMoreTerms: false,
            ErrorMessage: "",
            isMobile: false,
            displayFormDesktop : false,
        }
        var limit = new Date();
        limit.setFullYear(limit.getFullYear() - 18);
        this.maxDOB = limit;
        this.setMinMaxPurchaseDate(); 

        this.ErrorHandler = this.ErrorHandler.bind(this);
        this.resize = this.resize.bind(this);
        this.ProcessForm = this.ProcessForm.bind(this);
        window.FILE_STATUS = false;
    }

    setMinMaxPurchaseDate(){
        var now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        var lowerLimit = new Date(2022, 3, 11);
        lowerLimit = now < lowerLimit ? now : lowerLimit;
        var maxLimit = new Date(2022, 5, 5);
        this.minPurchaseDate = lowerLimit;
        this.maxPurchaseDate = now < maxLimit ? now : maxLimit;
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ 
            isMobile: window.innerWidth <= 768,
            displayFormDesktop: window.innerWidth > 1024
        });
        
    }

    componentDidMount() {
        this.props.loadGlobusCampaign();
        this.FlatpikerDateInit(this._PRODUCT_DATE, { max: this.maxPurchaseDate, min: this.minPurchaseDate });
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillMount() {}

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div id="buzzer-participation" className="micro-globus">
                {this.HeadingSection(this.props)}
                <div className="content-participation__form">
                    {this.DetailsSection(this.props)}
                    <p className="special">
                        *Gib deine persönlichen Daten (Vor- und Nachnamen sowie die E-Mail-Adresse, Geburtsdatum, Postadresse) ein. Lies dir bitte vorher unsere <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" style={{ color: "white" }}>Datenschutzhinweise</a> aufmerksam durch, damit du weißt, wie wir mit deinen personenbezogenen Daten umgehen und welche Rechte dir zustehen.
                    </p>
                </div>
                <Footersocial />
                {
                    this.state.SimpleLoader ? <Loader /> : null
                }
            </div>
        );
    }

    HeadingSection(props) {
        return (
            <div className="micro-globus__title-container">
                <h1>
                    Deine Chance auf 1 von 12 exklusiven Familienerlebnissen
                </h1>
                <StepContent
                    step1 = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.1') }
                    step1sm = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.1') }
                    step1Text = {parse("Kaufe zwischen dem <strong class=\"text--wheat\">02.05.2022</strong> und dem <strong class=\"text--wheat\">05.06.2022 zwei Milka Produkte </strong><strong>(Bitte den gut lesbaren Kaufbeleg aufbewahren)</strong>")}
                    step_arrow = "/resources/imagesBundesliga/Edeka/icons/arrow_right.png"
                    step2 = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.2') }
                    step2sm = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.2') }
                    step2Text = {parse("Lade <a href=\"/microsite/globus/mitmachen\">hier</a> bis zum <strong class=\"text--wheat\">05.06.2022</strong> den <strong class=\"text--wheat\">Kaufbeleg</strong> hoch. Ein Kaufbeleg pro Teilnahme.")}
                    step3 = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.3') }
                    step3sm = { props.t('Page.Home.Steps.Buzzer-Main-GlobusMicro.3') }
                    step3Text = {parse("Schon bist du im Gewinner-Lostopf! Nach Ablauf des Gewinnspiels am <strong class=\"text--wheat\">05.06.2022</strong> teilen wir dir <strong class=\"text--wheat\">per E-Mail</strong> mit, ob du gewonnen hast.")}
                />
            </div>
        );
    }

    displayInput(name){
        if(name === "salutation"){
            return (
                <div id="salutation" className="input-container">
                    <p id="pflichtfelder">*Pflichtfelder</p>
                    <div className="form-input__container form-input__dropdown">
                        <select ref={select => this._DETAILS_salutation = select} className="form-input__input js-event-type-in js-event-ab"
                            type="text" name="salutation"
                            id="salutation"
                            placeholder="Arende*"
                            data-require="true"
                            data-type="ddl"
                            data-error-target="#error-salutation"
                            data-required-message="Anrede ist erforderlich."
                            data-event-fieldname="Salutation"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Salutation"
                            data-label="Type In">
                            {
                                Object.keys(Salutations.dropdown).map(key => {
                                    var i = 0;
                                    return <option value={key} key={`${i++}-${key}`} >{Salutations.dropdown[key]}</option>
                                })
                            }
                        </select>
                        <label className="form-input__label" htmlFor="salutation">{Salutations.dropdown["default"]}</label>
                    </div>
                </div>
            );
        }
        
        if(name === "firstName") {
            return (
                <div id="firstName" className="input-container">
                    <div className="form-input__container">
                        <label className="form-input__label" htmlFor="firstname">Vorname*</label>
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="firstname"
                            id="firstname"
                            placeholder="Vorname*"
                            data-require="true"
                            data-type="regex"
                            data-error-target="#error-firstname"
                            data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                            data-required-message="Firstname is required."
                            data-pattern-message="Firstname not valid."
                            data-event-fieldname="Firstname"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Firstname"
                            data-label="Type In"
                            ref={input => this._DETAILS_firstName = input}
                        />
                    </div>
                </div>
            );
        }

        if(name === "lastName") {
            return (
                <div id="lastName" className="input-container">
                    <div className="form-input__container">
                        <label className="form-input__label" htmlFor="lastname">Nachname*</label>
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="lastname"
                            id="lastname"
                            placeholder="Nachname*"
                            data-require="true"
                            data-type="regex"
                            data-error-target="#error-lastname"
                            data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                            data-required-message="Lastname is required."
                            data-pattern-message="Lastname not valid."
                            data-event-fieldname="Lastname"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Lastname"
                            data-label="Type In"
                            ref={input => this._DETAILS_lastName = input}
                        />
                    </div>
                </div>
            );
        }

        if(name === "email-container") {
            return (
                <>
                    <p id="error-email" style={{ color: "red" }}></p>
                    <div id="email-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="email">Email*</label>
                            <input className="form-input__input js-event-type-in js-event-ab"
                                type="text"
                                name="email"
                                id="email"
                                placeholder="E-Mail*"
                                data-require="true"
                                data-type="regex"
                                data-error-target="#error-email"
                                data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                data-required-message=""
                                data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift"
                                data-event-fieldname="Email"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Email"
                                data-label="Type In"
                                ref={input => this._DETAILS_email = input}
                            />
                            <span id="error-email" className="form-input__error active"></span>
                        </div>
                    </div>
                </>
            );
        }
        if(name === "dob-container") {
            return (
                <div id="dob-container" className="input-container">
                    <Checkbox id="birth-terms"
                    required={true}
                    errorMessage="Ungültiges Alter"
                    text='Bitte bestätige, dass du mindestens 18 Jahre alt bist und ein Familienmitglied hast, das zwischen 8 und 12 Jahre alt ist und gerne als Einlauf- oder Trophäenkind am Supercup aktiv teilnehmen möchte.'
                    onChangeHandler={this.CheckboxChangeHandler.bind(this)}
                    />
                </div>
            );
        }
        if(name === "street") {
            return (
                <div id="street" className="input-container">
                    <div className="form-input__container">
                        <label className="form-input__label" htmlFor="street_name">Straße*</label>
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="street_name"
                            id="street_name"
                            placeholder="Straße*"
                            data-require="true"
                            data-type="text"
                            data-error-target="#error-street-number"
                            data-required-message="Street number is required."
                            data-event-fieldname="Street number"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Street number"
                            data-label="Type In"
                            ref={input => this._DETAILS_street_name = input}
                        />
                    </div>
                </div>
            )
        }
        if(name === "houseNumber") {
            return (
                <div id="houseNumber" className="input-container">
                    <div className="form-input__container">
                        <label className="form-input__label" htmlFor="street_number">Hausnummer*</label>
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="street_number"
                            id="street_number"
                            placeholder="Hausnummer*"
                            data-require="true"
                            data-type="text"
                            data-error-target="#error-house-number"
                            data-required-message="House number is required."
                            data-event-fieldname="House number"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="House number"
                            data-label="Type In"
                            ref={input => this._DETAILS_street_number = input}
                        />
                    </div>
                </div>
            )
        }

        if(name === "zipCode") {
            return (
                <div id="zipCode" className="input-container">
                    <div className="form-input__container space-right">
                        <label className="form-input__label" htmlFor="zip_code">PLZ*</label>
                        <input className="form-input__input js-event-type-in js-event-ab js-zip-code"
                            type="text"
                            name="zip_code"
                            id="zip_code"
                            placeholder="PLZ*"
                            maxLength="5"
                            data-require="true"
                            data-type="regex"
                            data-error-target="#error-zip-code"
                            data-regex-pattern="^[0-9]{5}$"
                            data-required-message="Zip code is required."
                            data-pattern-message="Zip code not valid."
                            data-event-fieldname="Zip code"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Zip code"
                            data-label="Type In"
                            ref={input => this._DETAILS_zipCode = input}
                        />
                    </div>
                </div>
            );
        }

        if(name === "city-container") {
            return (
                <div id="city-container" className="input-container">
                    <div className="form-input__container">
                        <label className="form-input__label" htmlFor="city">Stadt*</label>
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="city"
                            id="city"
                            placeholder="Stadt*"
                            data-require="true"
                            data-type="text"
                            data-error-target="#error-city"
                            data-required-message="City is required."
                            data-event-fieldname="City"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="City"
                            data-label="Type In"
                            ref={input => this._DETAILS_city = input}
                        />
                    </div>
                </div>
            );
        }

        if(name === "address-container") {
            return (
                <div id="address-container" className="input-container">
                    <div className="form-input__container">
                        <label className="form-input__label" htmlFor="adress">Adresszusatz</label>
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Adresszusatz"
                            data-require="false"
                            data-event-fieldname="Address"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Address"
                            data-label="Type In"
                            ref={input => this._DETAILS_address = input}
                        />
                    </div>
                </div>
            );
        }

        if(name === "country-container") {
            return (
                <div id="country-container" className="input-container">
                    <div className="form-input__container dropdown-input__container active">
                        <input className="form-input__input"
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Land*"
                            value="Deutschland"
                            data-require="false"
                            disabled
                            ref={input => this._DETAILS_country = input}
                        />
                    </div>
                </div>
            );
        }

        if(name === "phone") {
            return (
                <div id="phone" className="input-container">
                    <div className="form-input__container space-right">
                        <label className="form-input__label" htmlFor="phoneNumber">Telefonnummer*</label>
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder=" Telefonnummer*"
                            data-require="true"
                            data-type="text"
                            data-error-target="#error-phoneNumber"
                            data-regex-pattern="^[0-9]{1,}$"
                            data-required-message="Phone Number is required."
                            data-pattern-message="Phone Number not valid."
                            data-event-fieldname="phoneNumber"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="phoneNumber"
                            data-label="Type In"
                            ref={input => this._DETAILS_phoneNumber = input}
                        />
                    </div>
                </div>
            );
        }
        return null;
    }
    DisplayUserDetailsDesktop(){
        return (
            this.state.displayFormDesktop &&

            <div className="micro-globus__user-details">
                <div className="micro-globus__inputs-container">
                    {this.displayInput("salutation")}
                    {this.displayInput("firstName")}
                    {this.displayInput("lastName")}
                    {this.displayInput("street")}
                    {this.displayInput("houseNumber")}
                    {this.displayInput("zipCode")}
                    {this.displayInput("city-container")}
                    {this.displayInput("address-container")}
                </div>
                <div className="micro-globus__inputs-container secondary">
                    {this.displayInput("email-container")}
                    {this.displayInput("dob-container")}
                    {this.displayInput("country-container")}
                </div>
            </div>
        );
    }

    DisplayUserDetailsMobile() {
        return  (
                !this.state.displayFormDesktop &&
                <div className="form-container" id="userDetails">
                    {this.displayInput("salutation")}
                    {this.displayInput("firstName")}
                    {this.displayInput("lastName")}
                    {this.displayInput("email-container")}
                    {this.displayInput("dob-container")}
                    {this.displayInput("street")}
                    {this.displayInput("houseNumber")}
                    {this.displayInput("zipCode")}
                    {this.displayInput("city-container")}
                    {this.displayInput("address-container")}
                    {this.displayInput("country-container")}
                </div>
            );
    }

    DetailsSection(props) {
        return (
            <form id="frm_participation"
                name="frm_participation"
                method="post" action="/"
                ref={form => this._FORM_DETAILS = form}
                onSubmit={this.SubmitHandler.bind(this)} noValidate>
                <div className="buzzer-form" id="participant-info">
                    <h2>Trage hier deine persönlichen Daten ein</h2>
                    {this.DisplayUserDetailsDesktop()}
                    {this.DisplayUserDetailsMobile()}
                </div>

                <hr className="separator" />

                <div className="team-retailer__form">
                    <div className="buzzer-form retailer-container__form">
                        {this.DisplayRecieptForm(props)}
                        {this.RetailerSection(props)}
                    </div>
                </div>
                
                <hr className="separator" />

                {this.ConsentSection(props)}

                <div className="form-container centered captcha">
                    <ReCAPTCHA
                        ref={ref => this._CAPTCHA = ref}
                        hl={GLOBAL_CONFIG.Captcha.language}
                        size="invisible"
                        sitekey={GLOBAL_CONFIG.Captcha.publish}
                        onChange={this.OnCaptchaResolved.bind(this)}
                        onExpired={this.OnCaptchaExpired.bind(this)}
                        onErrored={this.OnCaptchaError.bind(this)}
                    />
                </div>

                {
                    this.state.ErrorMessage.length > 0 ?
                        <p className="buzzer-error">{this.state.ErrorMessage}</p>
                        : null
                }

                <button type="submit" className="btn__container btn--secondary-lilac">JETZT TEILNEHMEN</button>
            </form>
        );
    }

    DisplayRecieptForm(t) {
        const messageFileRequired = `Du hast noch keinen Kaufbeleg hochgeladen. Wähle die entsprechende Datei aus und lade deinen Kassenbon hoch. Bitte denke dabei daran, die nicht an der Aktion teilnehmenden Artikel auf dem Kassenbon unkenntlich zu machen.`;
        return (
            <div className="campaign-form-receipt">
                <h3>Kaufbeleg hochladen*</h3>
                <img src="/resources/images/upload-receipt-imgage.png" width="156" height="173" alt="upload receipt" />
                <label className="btn__container btn--secondary-lilac btn--hover custom-file-btn" htmlFor="file_to_upload" > 
                    <span>DATEI AUSWÄHLEN</span>
                    <input ref={ fileInput => this._RECEIPT_file = fileInput } type="file" id="file_to_upload" name="file_to_upload" data-require="true" data-file-required="true" data-type="file" data-error-target="#error-file" data-required-message={messageFileRequired} data-oversize-error="Ungültige Dateigröße. Bitte Dateigröße Vorgabe beachten." data-format-error="Ungültiges Dateiformat. Bitte nur gültiges Format hochladen." 
                    onChange={(e)=>{
                            ValidateForm.checkfileUpload(e.target);
                        }}/>
                </label>
                <p className="info-receipt">
                    Du kannst das Foto direkt über dein Smartphone aufnehmen und hochladen.
                    <br/>
                    <br/>
                    Du kannst deinen Kaufbeleg als .jpg, .png oder .pdf hochladen.
                </p>
                <div className="form-container results">
                    <span className="form-input__error js-file-error">Error</span>
                    <span className="form-input__success js-file-success">Success</span>
                </div>
            </div>
        );
    }

    RetailerSection(props) {
        return (
            <div className="form-container">
                <div className="input-container">
                    <div className="form-input__container active js-datepicker-dob">
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="product-purchase" id="product-purchase"
                            placeholder="Kaufdatum*"
                            autoComplete="off"
                            readOnly
                            data-require="true"
                            data-type="regex"
                            data-error-target="#product-purchase-error"
                            data-regex-pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$"
                            data-required-message="Datum ist erforderlich."
                            data-pattern-message="Um an der Aktion teilnehmen zu können, musst du zwischen dem 11.04.2022 und 05.06.2022 ein Milka Produkt gekauft haben."
                            data-event-fieldname="Date"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Date"
                            data-label="Type In"
                            ref={input => this._PRODUCT_DATE = input}
                            onBlur={(e) => {
                                const dateStr = e.target.value.split(".");
                                const date = new Date(dateStr[2], dateStr[1] - 1, dateStr[0]);
                                
                                if (+date < +this.minPurchaseDate || +date > +this.maxPurchaseDate) {
                                    this.unvalidInput(e.target);
                                } else {
                                    this.validInput(e.target);
                                }
                            }
                            }
                        />
                    </div>
                    <p id="product-purchase-error" style={{ color: "#D93333" }}></p>
                </div>
                <div className="input-container">
                    <div className="form-input__container">
                        <label className="form-input__label" htmlFor="product_type">Anzahl der gekauften Milka Produkte*</label>
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="product_type"
                            id="product_type"
                            placeholder="Anzahl der gekauften Milka Produkte*"
                            data-require="true"
                            data-type="regex"
                            maxLength="50"
                            data-regex-pattern="^[0-9]{1,}$"
                            data-error-target="#error-product-type"
                            data-required-message="Product Type is required."
                            data-event-fieldname="Product Type is required."
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="House number"
                            data-label="Type In"
                            ref={input => this._PRODUCT_count = input}
                        />
                    </div>
                </div>
            </div>

        );
    }

    ConsentSection(props) {
        return (
            <div className="campaign-consent">
                <Checkbox id="terms"
                    required={true}
                    errorMessage={_ERROR_MESSAGES.Terms}
                    text='Ich habe die  <a class="js-event-cta" href="/teilnahmebedingungen" target="_blank" rel="noopener noreferrer" title="Teilnahmebedingungen" data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="Dark Milk">Teilnahmebedingungen</a> gelesen und akzeptiert.*'
                    onChangeHandler={this.CheckboxChangeHandler.bind(this)}
                />
                {
                    this.state.ShowMoreTerms ?
                            <p className="buzzer-tcs">
                                Wir schätzen dein Vertrauen in unseren Umgang mit deinen personenbezogenen Daten. Verantwortliche Stelle für die Verarbeitung deiner personenbezogenen Daten im Rahmen dieser Aktion ist die Mondelēz Deutschland GmbH. Wir speichern personenbezogene Daten von dir nach Maßgabe der rechtlichen Vorschriften und ausschließlich zum Zweck der Abwicklung dieser Aktion (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. f) der Datenschutzgrundverordnung EU 2016/679). Es werden die E-Mail-Adresse, Vor- und Nachname sowie das Geburtsdatum und die Adressdaten der Teilnehmer erfasst und ausschließlich zur Verifizierung der Teilnahme gespeichert. Name und Adressdaten werden für den Versand der Gewinne verarbeitet (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. b) der Datenschutzgrundverordnung EU 2016/679, DSGVO). Weitere Information zu dem Umgang mit deinen personenbezogenen Daten im Rahmen dieser Aktion findest du in unseren <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen.</a>
                            </p>
                        :
                         <a id="moreInfo" href="#" style={{ textDecoration: "underline" }} onClick={(event) => { event.preventDefault(); this.setState({ ShowMoreTerms: true }) }}>
                            <strong>{"„Mehr Informationen >>“"}</strong>
                        </a>
                }

                <Checkbox id="newsletter"
                    text={props.t('Page.Participation.form.newsletter')}
                    onChangeHandler={this.CheckboxChangeHandler.bind(this)}
                />
            </div>
        );
    }

    CheckboxChangeHandler(event, _ref) {
        if (_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if (_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    validInput(...list) {
        for (let i = 0; i < list.length; i++) {
            list[i].classList.add('valid');
            list[i].classList.remove('notvalid');
            if (list[i].hasAttribute("data-error-target")) {
                const element = document.querySelector(list[i].getAttribute("data-error-target"));
                if (element) {
                    element.innerHTML = "";
                }
            }
        }
    }

    unvalidInput(...list) {
        for (let i = 0; i < list.length; i++) {
            list[i].classList.add('notvalid');
            list[i].classList.remove('valid');

            if (list[i].hasAttribute("data-error-target")) {
                const element = document.querySelector(list[i].getAttribute("data-error-target"));
                if (element) {
                    element.innerHTML = list[i].getAttribute("data-pattern-message");
                }
            }
        }
    }

    SubmitHandler(event) {
        event.preventDefault();

        const isValid = ValidateForm.validateForm('frm_participation'),
            isAccepted = this.state.terms;
        if (isAccepted
            && isValid) {
            this.TriggerCaptcha();
            //this.ProcessForm(null);
        } else {
            /**
             * Scroll To first error
             */
            let firstError = this._FORM_DETAILS.querySelector(".notvalid");

            if(window.FILE_STATUS === false){
                firstError = this._FORM_DETAILS.querySelector(".campaign-form-receipt");
            } 
            
            if (!!firstError) {
                const yOffset = - 110,
                    y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            } 
        }
    }

    ProcessForm(token) {
        let salutation = this._DETAILS_salutation.value,
            { UpdateParticipationStatus } = this.props;

        switch (salutation) {
            case "Herr":
                salutation = "1";
                break;
            case "Frau":
                salutation = "2";
                break;
            case "Divers":
                salutation = "10";
                break;

            default:
                break;
        }

        //Process form data
        let _data = {
            Receipt: this._RECEIPT_file.files[0],
            parentApproval: this.state['birth-terms'] ? true : false,
            salutation: salutation,
            firstname: this._DETAILS_firstName.value,
            lastname: this._DETAILS_lastName.value,
            email: this._DETAILS_email.value,
            street1: this._DETAILS_street_name.value,
            streetnumber: this._DETAILS_street_number.value,
            addressAdditionalInfo: this._DETAILS_address.value,
            zipcode: this._DETAILS_zipCode.value,
            city: this._DETAILS_city.value,
            newsletter: this.state.newsletter ? "1" : "0",
            productCount : this._PRODUCT_count.value,
            purchaseDate : ServerDate(this._PRODUCT_DATE.value),
            captcha: token,
            referrer : "Website",
        };

        this.setState({
            SimpleLoader: true
        });

        this._CAPTCHA.reset();
        let _Data = new FormData();
        for(let key in _data){
            _Data.append(key, _data[key]);
        }

        ParticipationService.ParticipateGlobusMicroSite(_Data)
            .then((response) => {
                let { status, success, data } = response.data;
                this.setState({
                    SimpleLoader: false
                })

                if (success) {
                    if (typeof UpdateParticipationStatus === "function") {
                        UpdateParticipationStatus();
                    }
                } else {
                    if (!!data.Error && data.Error.length > 0) {
                        this.ErrorHandler(data.Error[0]);
                    } else {
                        this.setState({
                            ErrorMessage: "SERVER ERROR"
                        })
                    }
                }
            })
            .catch(() => {
                this.setState({
                    SimpleLoader: false
                })
            })
    }

    ErrorHandler(Message) {
        switch (Message) {
            case _BUZZER_MESSAGE.PARTICIPATION_LIMIT:
                this.setState({
                    ErrorMessage: _ERROR_MESSAGES.Buzzer.CODE_LIMIT
                })
                break;
            case _BUZZER_MESSAGE.CODE_INVALID:
                this.setState({
                    CodeIsValid: false,
                    ErrorMessage: _ERROR_MESSAGES.Buzzer.INVALID_CODE
                })
                break;
            case _BUZZER_MESSAGE.INVALID_CAPTCHA:
                this.setState({
                    ErrorMessage: Message
                })
                break;
            case _BUZZER_MESSAGE.REGISTRATION_FAILED:
                this.setState({
                    ErrorMessage: Message
                })
                break;

            default:
                this.setState({
                    ErrorMessage: "SERVER ERROR"
                })
                break;
        }
    }

    OnCaptchaResolved(token) {
        this.setState({
            SimpleLoader: true
        })
        this.ProcessForm(token);
    }

    OnCaptchaError() {
        this._CAPTCHA.reset();
        this.setState({
            SimpleLoader: false
        })
        this.setState({
            ErrorMessage: "CAPTCHA ERROR"
        })
    }

    OnCaptchaExpired() {
        this._CAPTCHA.reset();
        this.setState({
            SimpleLoader: false
        })
        this.setState({
            ErrorMessage: "CAPTCHA EXPIRED" //ABGELAUFEN
        })
    }

    TriggerCaptcha() {
        this._CAPTCHA.execute();
    }

    FlatpikerDateInit(_REF, { max, min }, _def = null) {
        flatpickr(_REF, {
            dateFormat: "d.m.Y",
            maxDate: max,
            minDate: min,
            disableMobile: true,
            defaultDate: !!_def ? new Date(_def) : null
        });
    }
}

export default withTranslation()(SupercupParticipationGlobus);