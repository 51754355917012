import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config'; 
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle } from '../../modules/helpers';

class LoginDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {                   
            navbar: null
        };
    }
    componentDidMount(){
        mainLoaderToggle('hide');
       
    }
    componentWillUnmount(){
        mainLoaderToggle('show');
        
    }
    close=()=>{
        console.log("press");
        let navigationAccount= document.getElementById('navigationAccount');
        let navigationAccount_sm = document.getElementById('navigationAccount-sm');
        if (navigationAccount.style.display === "block") {
            navigationAccount.style.display = "none";
            navigationAccount_sm.style.display = "block";
            console.log("show")
          } else {
            navigationAccount.style.display = "block";
            navigationAccount_sm.style.display = "none";
            console.log("hide")
          }

    }
    // logout= ()=>{
    //     console.log("logout")
    //     if(getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null){
    //         sessionStorage.setItem(GLOBAL_CONFIG.Session.tokenstatus,null);
    //         console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
    //         this.props.history.push({pathname: GLOBAL_CONFIG.Route.account});

    //     }
    // }
    render() {
        return(
            <div>
                <div className="navigationAccount">
                    <ul>
                        <li>
                        Mein Account
                            {/* <NavLink to={GLOBAL_CONFIG.Route.home} exact activeClassName="active" data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Start" >Account Informationen</NavLink> */}
                        </li>
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.accountInformation} exact activeClassName="active" data-event="go_to_account_information" data-category="Inline Menu" data-action="Go to Page" data-label="ENG_AD_NAVBAR" onClick={(e)=>{appGoogleTracking.processEventCTANavLink(e)}}>ACCOUNT informationen</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to={GLOBAL_CONFIG.Route.loginDetail} exact activeClassName="active" data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Start" >Login details</NavLink>
                        </li> */}
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.resetPassword} exact activeClassName="active" data-event="change_password" data-category="Inline Menu" data-action="Go to Page" data-label="ENG_AD_NAVBAR" onClick={(e) => { appGoogleTracking.processEventCTANavLink(e) }}>PASSWORT ÄNDERN</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to={GLOBAL_CONFIG.Route.home} exact activeClassName="active" data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Start" >BESTELLUNGEN</NavLink>
                        </li> */}
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.loginDetail} exact activeClassName="active" data-event="go_to_FCMilka" data-category="Inline Menu" data-action="Go to Page" data-label="MAIN_SITE_AD_NAVBAR" onClick={(e) => { appGoogleTracking.processEventCTANavLink(e) }}>FC Milka</NavLink>
                        </li>
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.accountDeletion} exact activeClassName="active" data-event="delete_account" data-category="Inline Menu" data-action="Go to Page" data-label="ENG_AD_NAVBAR" onClick={(e) => { appGoogleTracking.processEventCTANavLink(e) }}>ACCOUNT LÖSCHEN</NavLink>
                        </li>
                    </ul> 
                </div>
                
                <div className="navigationAccount-sm" id="navigationAccount-sm">
                    <div className="left-arrow" >
                        Mein account 
                    </div>
                    <div className="right-arrow" onClick={this.close}> 
                        <img src="/resources/imagesBundesliga/icons/single_arrow-white-down.png"></img>
                    </div>
                </div>
               <div>
               <div className="js-header-md-content header-md__content navigationAccount" id="navigationAccount">
                    <div className="navigationAccount-sm" >
                        <div className="left-arrow" >
                            Mein Account 
                        </div>
                        <div className="right-arrow" onClick={this.close}> 
                            <img src="/resources/imagesBundesliga/icons/single_arrow-white-down.png"></img>
                        </div>
                    </div>
                        <ul className="header-md__navigations navigationAccount-sm-list">
                            <li>
                                <NavLink to={GLOBAL_CONFIG.Route.accountInformation} exact activeClassName="active" data-event="go_to_account_information" data-category="Inline Menu" data-action="Go to Page" data-label="ENG_AD_NAVBAR" onClick={(e) => { appGoogleTracking.processEventCTANavLink(e) }}>Account informationen</NavLink>
                            </li>
                            <li>
                                <NavLink to={GLOBAL_CONFIG.Route.resetPassword} exact activeClassName="active" data-event="change_password" data-category="Inline Menu" data-action="Go to Page" data-label="ENG_AD_NAVBAR" onClick={(e) => { appGoogleTracking.processEventCTANavLink(e) }}>PASSWORT ÄNDERN</NavLink>
                            </li>
                            <li>
                                <NavLink to={GLOBAL_CONFIG.Route.loginDetail} exact activeClassName="active" data-event="go_to_FCMilka" data-category="Inline Menu" data-action="Go to Page" data-label="MAIN_SITE_AD_NAVBAR" onClick={(e) => { appGoogleTracking.processEventCTANavLink(e) }}>FC Milka</NavLink>
                            </li>
                            <li>
                                <NavLink to={GLOBAL_CONFIG.Route.accountDeletion} exact activeClassName="active" data-event="delete_account" data-category="Inline Menu" data-action="Go to Page" data-label="ENG_AD_NAVBAR" onClick={(e) => { appGoogleTracking.processEventCTANavLink(e) }}>ACCOUNT LÖSCHEN</NavLink>
                            </li>
                            
                        </ul>
                    </div>
                   
               </div>
               
            </div>
            
        )
    }
}
export default withTranslation()(LoginDetail);