import axios from "axios";
import { ServicesUrl } from "../modules/helpers";


const buzzerScoreservice = () => {
    const _SERVICE_URL = ServicesUrl();

    var score = () => {
        return axios({
            method: 'get',
            url: `${_SERVICE_URL}/BuzzerGame/Scores`
        })
    }

    return {
        GetMatchesScore: score
    }
}

var buzzerScoreService = buzzerScoreservice();

export default buzzerScoreService;