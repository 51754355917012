import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import { withTranslation } from "react-i18next";
import BuzzerSlider from "./Slider";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import StepContent from "../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../config/config";
import KIPServices from "../../../Service/KIP_Pages";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import ParticipationNavigation from "./ParticipationNavigation";
import GiftContentFooter from "../../../components/Content/GiftContent/GiftContentFooter";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import _LOCAL from "../../../Models/LocalStorageKeys";

class BuzzerKaufland extends React.Component {
    componentWillMount() {
        if(this.props.isQ3){
            window.location = "https://fcmilka.de/kaufland";
        }
        this.LoadCampaign();
        localStorage.setItem(_LOCAL.CurrentKip, "Kaufland");
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-netto kaufland">
                {this.headingSection(this.props)}
                {this.imageSection()}
                {this.cardSection()}
                {this.footerSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection(props) {
        return (
            <div className="content-home endpage-rewe">
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <div className="title-container">
                            <h2>
                                Sichere dir jetzt deine doppelte
                                Gewinnchance mit Milka
                                bei der <br /><img src="/resources/imagesBundesliga/buzzer/kaufland/kaufland.png" alt="Kaufland Logo" className="tablet-up"/> Zusatzverlosung
                            </h2>
                            <h4>
                                Im Aktionszeitraum vom 11.04.2022 bis 05.06.2022 ein Produkt von Milka in deinem Kaufland Markt kaufen, Kaufbeleg bis zum 05.06.2022 auf 
                                <a href="/mitmachen" ><strong> www.gold.fcmilka.de/mitmachen </strong></a>hochladen und mit etwas Glück deine exklusive VIP-Loge für die komplette Bundesliga Saison 2022/23 gewinnen. (*Die Auswahl der VIP-Loge und des Clubs erfolgt nach Verfügbarkeit.)
                                
                            </h4>
                            <h4>
                                Deine Chance auf den doppelten Gewinn: Mit der Teilnahme an der Kaufland Zusatzverlosung nimmst du automatisch am Gewinnspiel „Jetzt Meister werden!“ teil und hast darüber hinaus die Chance auf zusätzliche Gewinne. 
                            </h4>
                        </div>
                        <div className="step-container">
                            <h3 className="step-title">
                                Mitmachen ist ganz einfach:
                            </h3>

                            <StepContent
                                step1 = { props.t('Page.Home.Steps.Buzzer-Main-Kaufland.1') }
                                step1sm = { props.t('Page.Home.Steps.Buzzer-Main-Kaufland.1') }
                                step1Text = {parse("Kaufe zwischen dem <span class=\"text--wheat\">11.04.2022</span> und dem <span class=\"text--wheat\">05.06.2022</span> ein Milka Aktionsprodukt. <strong>(Bitte den gut lesbaren Kaufbeleg aufbewahren.)</strong>")}
                                step_arrow = "/resources/imagesBundesliga/Edeka/icons/arrow_right.png"
                                step2 = { props.t('Page.Home.Steps.Buzzer-Main-Kaufland.2') }
                                step2sm = { props.t('Page.Home.Steps.Buzzer-Main-Kaufland.2') }
                                step2Text = {parse("Lade <a href=\"/mitmachen\">hier</a> bis zum <span class=\"text--wheat\">05.06.2022</span> den <span class=\"text--wheat\">Kaufbeleg</span> hoch. (Ein Kaufbeleg pro Teilnahme)")}
                                step3 = { props.t('Page.Home.Steps.Buzzer-Main-Kaufland.3') }
                                step3sm = { props.t('Page.Home.Steps.Buzzer-Main-Kaufland.3') }
                                step3Text = {parse("Schon bist du im Gewinner-Lostopf! Nach Ablauf des Gewinnspiels am <span class=\"text--wheat\">05.06.2022</span> teilen wir dir <span class=\"text--wheat\">per E-Mail</span> mit, ob du gewonnen hast.")}
                                step4 = { props.t('Page.Home.Steps.Buzzer-Main-Kaufland.4') }
                                step4sm = { props.t('Page.Home.Steps.Buzzer-Main-Kaufland.4') }
                                step4Text = { parse("Zusätzlich sicherst du dir über die Teilnahme am FC Milka Gewinnspiel „Jetzt Meister werden!“ die Chance auf eine Dauerkarte für die Saison 2022/23 der <span style='white-space: nowrap; font-weight: normal;'>Bundesliga / 2. Bundesliga</span> oder Gold im Wert von 25.000 €.") }
                            />

                            <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                                <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                            </NavLink>
                            <p className="step-container--terms-link">
                                Hier findest du die  <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a> für unser Gewinnspiel.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    imageSection() {
        return (
            <div className="">
                <a href="https://filiale.kaufland.de/kaufland-card.html?cid=DE:coop:website_1649942566506#registrierung" target="_blank" rel="noopener noreferrer">
                    <picture>
                        <source srcSet="/resources/imagesBundesliga/buzzer_22/kaufland/banner-2022-milka-mobile.jpg" media="(max-width: 750px)" />
                        <img src="/resources/imagesBundesliga/buzzer_22/kaufland/banner-2022-milka.jpg" alt="Gold im Wert"/>
                    </picture>
                </a>
            </div>
        );
    }

    cardSection() {
        return (
            <div className="card-container">
                <div className="card-container__card">
                    <div className="card">
                        <div className="card--image">
                            <picture>
                                <source srcSet="/resources/imagesBundesliga/buzzer_22/kaufland/card/konfetti-sm.png" media="(max-width: 768px)" />
                                <img src="/resources/imagesBundesliga/buzzer_22/kaufland/card/konfetti.png" alt="Gold im Wert"/>
                            </picture>
                        </div>
                        <div className="card--text">
                            <h3>Jetzt Bundesliga VIP-Loge gewinnen</h3>
                            <p>
                            Gewinne mit etwas Glück 1x eine VIP-Loge* und erlebe die komplette Bundesliga Saison 2022/23 hautnah! Lade deine Freunde und Familie ein und verfolgt gemeinsam jedes Heimspiel deines Lieblingsvereins live im Stadion, genießt dabei das exklusive Catering und natürlich jede Menge Milka Schokolade – mehr Fußball all-inclusive geht nicht! (*Die Auswahl der VIP-Loge und des Clubs erfolgt nach Verfügbarkeit.)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <h2>
                    Sichere dir jetzt deine doppelte Gewinnchance
                </h2>
                <h3>
                    Neben der Bundesliga VIP-Loge der Saison 22/23 warten beim FC Milka Gewinnspiel “Jetzt Meister werden!” viele weitere tolle Fußball-Preise auf dich: 1x Gold im Wert von 25.000 € oder 120x 1 Dauerkarte der Bundesliga / 2. Bundesliga.
                </h3>

                <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                    <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                </NavLink>
                <p className="terms-link">
                    Hier findest du die <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a>.
                </p>
                <p className="terms-faq">
                    Noch Fragen zur Aktion? Hier geht es zu den <a href={GLOBAL_CONFIG.Route.buzzer.faq}>FAQ</a>.
                </p>
                <h3 className="terms-title">
                    Teilnahmebedingungen
                </h3>
                <h4 className="terms-subtitle">
                    Ergänzend zu den vollständigen <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzhinweisen</a> zu dem Gewinnspiel „Jetzt Meister werden!“ gelten für die Zusatzverlosung bei Kaufland die folgenden Teilnahmebedingungen:
                </h4>
                <p className="instruction">
                    <strong>Veranstalter:</strong> Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen. Teilnahmeberechtigt: Natürliche Personen ab 18 Jahren mit Wohnsitz in Deutschland. Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen sowie jeweils deren Angehörige. Teilnahme durch Kauf eines Milka-Produkts im Zeitraum vom 11.04.-05.06.2022 sowie Online Registrierung und Hochladen eines Fotos des gut lesbaren, automatisch erstellten, originalen Kaufbelegs (Kaufbeleg inkl. Einkaufsdatum, -uhrzeit und Belegsumme) bis zum 05.06.2022, Angabe des Milka Produkts, des Händlers / Kaufdatums auf www.gold.fcmilka.de/kaufland. Nicht teilnehmende Produkte unkenntlich machen. Kaufbeleg zur späteren Prüfung aufbewahren. Pro Kaufbeleg nur eine Teilnahme. Max. ein Gewinn pro Person. Gewinnermittlung der Zusatzverlosung bei Kaufland durch Ziehung aus allen gültigen Teilnahmen am 07.06.2022. Die Gewinnbenachrichtigung erfolgt per E-Mail an die bei der Registrierung angegebenen E-Mail-Adresse innerhalb einer Woche nach Ende des Teilnahmezeitraums.
                </p>
                <p className="instruction">
                    <strong>Gewinn:</strong> 1 x eine VIP-Loge der Saison 2022/23 für bis zu 10 Personen inklusive Verpflegung vor Ort. Auswahl der VIP-Loge und des Clubs erfolgt nach Verfügbarkeit. Es besteht kein Anspruch auf Auswahl einer bestimmten Loge oder eines bestimmten Bundesliga Stadions.
                </p>
                <p className="instruction">
                    Der Gewinn steht unter der aufschiebenden Bedingung, dass der Gewinner nach Kontaktaufnahme der vom Veranstalter mit der Ausführung des Events beauftragten Agentur per Post zunächst die bei der Teilnahme angegebenen Daten bestätigt sowie das Anmeldeformular vollständig unterschrieben innerhalb einer Frist von 10 Tagen einsendet. Sofern der Gewinner dies unterlässt, kann der Gewinn nicht mehr in Anspruch genommen werden und der Veranstalter kann den Gewinn nach eigenem Ermessen verfallen lassen oder an einen weiteren Teilnehmer, der unter denselben Bedingungen ermittelt wird, vergeben. Sofern die Teilnehmer die Teilnahmevoraussetzungen erfüllen, d.h. die bei der Teilnahme angegebenen Daten werden fristgemäß bestätigt, werden die Gewinner bei der Organisation der Veranstaltung durch das Agenturpersonal begleitet.
                </p>
                <p className="instruction">
                    Von dem Event können Filme und Fotos von dem Veranstalter, Kaufland, der DFL, den mit ihr verbundenen Unternehmen, den beteiligten Fußballclubs / ihren jeweiligen Verbänden und sonst befugten Dritten (z.B. Presse, Rundfunk) im Rahmen der Werbung der Marke „Milka“ oder Kaufland oder der Bundesliga in verschiedenen Medien (online einschließlich Sozialer Medien, Print und TV) veröffentlicht werden. Mondelez wird diese Filme und Fotos etwaig für die interne Nachberichterstattung nutzen.
                </p>
                <p className="instruction">
                    Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID 19 Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation. Es gelten zudem die jeweils aktuellen gültigen Zutrittsregelungen des Clubs, über die sich der Gewinner informieren muss.   
                </p>
                <p className="instruction">
                    Rechtsweg und Barauszahlung ausgeschlossen.
                </p>
                <p className="instruction">
                    <strong>Sonstiges:</strong> Im Übrigen gelten die Teilnahmebedingungen des Gewinnspiels „Jetzt Meister werden“: Weitere Informationen, vollständige Teilnahmebedingungen und Datenschutzhinweise unter <a href={GLOBAL_CONFIG.Route.terms}>https://www.gold.fcmilka.de/teilnahmebedingungen</a>.
                </p>
            </div>
        )
    }

    LoadCampaign() {
        KIPServices.Kaufland()
        .then( response => {
            var { status, success, message, placeholder } = response.data;

            if(success) {
                switch (status) {
                    case _STATUS_CAMPAIGN.holding:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.holdingkaufland
                        });
                        break;
                    case _STATUS_CAMPAIGN.closed:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.endkaufland
                        });
                        break;
                
                    default:
                        mainLoaderToggle('hide');
                        break;
                }
            }
        })
        .catch( () => {
            mainLoaderToggle('hide');
        })
        .finally( () => {
            _SCROLL_PAGE.ScrollToTop();
        })
    }

}

export default withTranslation()(BuzzerKaufland);