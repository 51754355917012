import React from "react";

import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import KIPServices from "../../../Service/KIP_Pages";
import GLOBAL_CONFIG from "../../../config/config";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import appGoogleTracking from "../../../modules/googletracking";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Aux from "../../../hoc/Auxiliare";


class BuzzerEndKaufland extends React.Component {
    componentWillMount() {
        this.LoadCampaign();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div className="kaufland">
                {this.headingSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <Aux>
                <div className="s-content-end">
                    <h1>
                        Diese Aktion ist leider beendet. Freue dich auf viele weitere FC Milka Aktionen, die kommen werden!
                    </h1>
                    <div className="end-element">
                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                            <div className="end-element__copy">
                                    <h3>FC Milka Mitglied werden</h3>
                                    <p>Jetzt kostenlos registrieren und viele Vorteile genießen. Sei dabei und melde dich an.</p>
                                <a href="https://fcmilka.de/account" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> REGISTRIEREN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                            <div className="end-element__copy">
                                    <h3>Nervennahrung nötig?</h3>
                                    <p>
                                        Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                                    </p>
                                <a href="https://www.milka.de/shop" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> SCHOKI SHOPPEN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-3.png" />
                            <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                        Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                    </p>
                                <a href="https://www.milka.de/neues" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footersocial /> */}
            </Aux>
        );
    }

    LoadCampaign() {
        KIPServices.Kaufland()
        .then( response => {
            var { status, success, message, placeholder } = response.data;

            if(success) {
                switch (status) {
                    case _STATUS_CAMPAIGN.holding:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.holdingkaufland
                        });
                        break;
                    case _STATUS_CAMPAIGN.open:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.Kaufland
                        });
                        break;

                    default:
                        mainLoaderToggle('hide');
                        break;
                }
            }
        })
        .catch( () => {
            mainLoaderToggle('hide');
        })
        .finally( () => {
            _SCROLL_PAGE.ScrollToBanner()
        })
    }
}

export default BuzzerEndKaufland;