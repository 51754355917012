import React from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';     // Import Swiper React components
import 'swiper/swiper.scss';    // Import Swiper styles
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import appGoogleTracking from '../../../modules/googletracking';

// install Swiper components
SwiperCore.use([Navigation, Pagination, EffectFade]);

class BuzzerSlider extends React.Component {
    render() {
        const   { t, sliderName , isNewSlider} = this.props,
                sliderObj = t(sliderName, { returnObjects: true });
            if(isNewSlider){
                return this.displayNewSlides(sliderObj);
            }
        return this.displaySlides(sliderObj);
    }

    displayNewSlides(sliderObj){
        const swiperSlides = this.newSlidesSection(sliderObj);
        return (
            <div className="slidernew-container">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: false }}
                    effect="fade"
                    onSlideNextTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Right'
                        });
                    }}
                    onSlidePrevTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Left'
                        });
                    }}
                >
                    {swiperSlides}
                </Swiper>
            </div>
        );
    }

    displaySlides(sliderObj){
        const swiperSlides = this.slidesSection(sliderObj);
        return (
            <div className="content-slider-wrapper">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: false }}
                    effect="fade"
                    onSlideNextTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Right'
                        });
                    }}
                    onSlidePrevTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Left'
                        });
                    }}
                >
                    {swiperSlides}
                </Swiper>
            </div>
        );
    }

    newSlidesSection(sliderObj){
        let swiperSlides = sliderObj.map((obj) => {
            return (
                <SwiperSlide key={obj.index}>
                    <div className="slidernew-image"> 
                        <picture>
                            <source srcSet={obj.imageDesktop} media="(min-width: 1060px)" />
                            <source srcSet={obj.imageMobile} media="(min-width: 1059px)" />
                            <source srcSet={obj.imageMobile} media="(min-width: 200px)" />
                            <img className="slider__image" src={obj.imageDesktop} alt={obj.index} />
                        </picture>
                    </div>
                    <div className="slidernew-copy">
                        <div className="slidernew-copy__header">{parse(obj.header)}</div>
                        <div className="slidernew-copy__description">{parse(obj.text)}</div>
                    </div>
                </SwiperSlide>
            );
        });

        return swiperSlides;
    }
    
    slidesSection(sliderObj) {
        let swiperSlides = sliderObj.map((obj) => {
            return (
                <SwiperSlide key={obj.index}>
                    <div className="slider__background"> 
                        <div className='content-slider__image'>
                        <picture>
                            <source srcSet={obj.imageDesktop} media="(min-width: 800px)" />
                            <source srcSet={obj.imageMobile} media="(min-width: 481px)" />
                            <source srcSet={obj.imageMobile} media="(min-width: 200px)" />
                            <img className="slider__image" src={obj.imageDesktop} alt={obj.index} />
                        </picture>
                        </div>
                    </div>
                    <div className="content-slider">
                        <div className="content-slider__header">{parse(obj.header)}</div>
                        <div className="content-slider__description">{parse(obj.text)}</div>
                    </div>
                </SwiperSlide>
            );
        });

        return swiperSlides;
    }
}

export default withTranslation()(BuzzerSlider);