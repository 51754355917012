import React from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import {mainLoaderToggle, optionListConstruct} from "../../../modules/helpers";
import JerseyStepIndicitor from "../ChooseJersey/StepIndicator";
import DropdownClub from "../../../components/Content/DropdownClub/DropdownClub";
import Loader from "../../../components/Overlays/Loader";
import MobileBuzzerGame from "../../../components/Layout/Game/Buzzer/Mobile";
import DesktopBuzzerGame from "../../../components/Layout/Game/Buzzer/Desktop";
import Match from "../../../Models/Match";
import _LOCAL from "../../../Models/LocalStorageKeys";
import FanCenterServices from "../../../Service/FanCenter";
import _GIFT from "../../../Models/GiftTypes";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import _STATUS from "../../../Models/ParticipationSteps";
import _USER_TYPES from "../../../Models/UserTypes";
import _TICKET_TYPES from "../../../Models/TicketTypes";
import Aux from "../../../hoc/Auxiliare";
import FanCenterSCBanner from "../Banner/FanCenterSCBanner";
import MatchList from "../../../components/Layout/MatchList/MatchList";

class TeamSelection extends React.Component {
  constructor() {
    super();

    this.StepList = [
      {
        display: "Lieblingsclub",
        isInProgress: false,
        isComplete: true,
      },
      {
        display: "Ticketauswahl",
        isInProgress: true,
        isComplete: false,
      },
      {
        display: "Daten-Check",
        isInProgress: false,
        isComplete: false,
      },
    ];

    this.state = {
      TeamSelected: null,
      CovidInfo: true,
      SimpleLoader: false,
      matches: [],
      allMatches: [],
      SelectedMatch: null,
      isSelectedMatch: false,
      GiftCategory:
        localStorage.getItem(_LOCAL.GiftCategory) || _TICKET_TYPES.CAT2,
      UserType: _USER_TYPES.User,
      IsRemainingTicket: true,
      TicketChoice: "2",
      isSingleTicket: false,
      selectionError: "",
      matchDetail : false,
      TypeTicket: ""
    };

    this.getMatchsDirectSelection = this.getMatchsDirectSelection.bind(this);
    this.setMatchesButFavouriteClub =
      this.setMatchesButFavouriteClub.bind(this);
    this.TicketsChoice = this.TicketsChoice.bind(this);
    this.GetMatchForTeam = this.GetMatchForTeam.bind(this);
  }

  componentDidMount() {
    _SCROLL_PAGE.ScrollToTop();
    this.getMatchsDirectSelection();
    mainLoaderToggle("hide");
  }

  componentWillMount() {
    if (localStorage.getItem(_LOCAL.UserDetails) === null) {
      // this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user);
    }
  }

  getMatchsDirectSelection() {
    let matches = [];
    const favoriteClub = localStorage.getItem(_LOCAL.FavoriteClub);

    // localStorage.setItem(_LOCAL.UserDetails,JSON.stringify({ "email": "Jane@doe.com", "anrede": 2, "name": "Doe", "vorname": "Jane", "strasse": "Teststreet", "hausNr": "123", "plz": "22459", "ort": "Hamburg", "land": "DE", "favTeam": "FC Bayern München", "telefonnummer": null, "external_guid": "234D340D-DCA7-4288-9789-64A0EAA97898" }));

  //   matches = [
  //     {
  //         ID: 393,
  //         Category: "CAT2",
  //         NumberAvailable: 11,
  //         Gameday: "25",
  //         Date: "18.03.2023",
  //         Time: "18:30",
  //         Home: "FC Bayern München",
  //         Guest: "1. FC Köln",
  //         header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //         availableVIP: 0,
  //         homeID: 171,
  //         guestID: 176,
  //         MatchdayDateTime: "2023-03-18T18:30:00",
  //         Saison: "22/23"
  //     },
  //     {
  //         ID: 394,
  //         Category: "CAT2",
  //         NumberAvailable: 11,
  //         Gameday: "25",
  //         Date: "19.03.2023",
  //         Time: "17:30",
  //         Home: "FC Bayern München",
  //         Guest: "SC Freiburg",
  //         header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //         availableVIP: 0,
  //         homeID: 169,
  //         guestID: 174,
  //         MatchdayDateTime: "2023-03-19T17:30:00",
  //         Saison: "22/23"
  //     },
  //     {
  //         ID: 395,
  //         Category: "CAT2",
  //         NumberAvailable: 11,
  //         Gameday: "25",
  //         Date: "19.03.2023",
  //         Time: "15:30",
  //         Home: "1. FC Union Berlin",
  //         Guest: "FC Bayern München",
  //         header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //         availableVIP: 0,
  //         homeID: 181,
  //         guestID: 182,
  //         MatchdayDateTime: "2023-03-19T15:30:00",
  //         Saison: "22/23"
  //     },
  //     {
  //         ID: 396,
  //         Category: "CAT2",
  //         NumberAvailable: 11,
  //         Gameday: "25",
  //         Date: "19.03.2023",
  //         Time: "19:30",
  //         Home: "Borussia M'gladbach",
  //         Guest: "SC Freiburg",
  //         header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //         availableVIP: 0,
  //         homeID: 163,
  //         guestID: 161,
  //         MatchdayDateTime: "2023-03-19T19:30:00",
  //         Saison: "22/23"
  //     },
  //     {
  //         ID: 397,
  //         Category: "CAT2",
  //         NumberAvailable: 11,
  //         Gameday: "25",
  //         Date: "18.03.2023",
  //         Time: "15:30",
  //         Home: "TSG Hoffenheim",
  //         Guest: "Borussia M'gladbach",
  //         header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //         availableVIP: 0,
  //         homeID: 185,
  //         guestID: 168,
  //         MatchdayDateTime: "2023-03-18T15:30:00",
  //         Saison: "22/23"
  //     },
  //     {
  //         ID: 398,
  //         Category: "CAT2",
  //         NumberAvailable: 11,
  //         Gameday: "25",
  //         Date: "17.03.2023",
  //         Time: "20:30",
  //         Home: "1.FSV Mainz 05",
  //         Guest: "Arminia Bielefeld",
  //         header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //         availableVIP: 0,
  //         homeID: 175,
  //         guestID: 159,
  //         MatchdayDateTime: "2023-03-17T20:30:00",
  //         Saison: "22/23"
  //     },
  //     {
  //         ID: 399,
  //         Category: "CAT2",
  //         NumberAvailable: 11,
  //         Gameday: "25",
  //         Date: "18.03.2023",
  //         Time: "15:30",
  //         Home: "Arminia Bielefeld",
  //         Guest: "RB Leipzig",
  //         header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //         availableVIP: 0,
  //         homeID: 162,
  //         guestID: 170,
  //         MatchdayDateTime: "2023-03-18T15:30:00",
  //         Saison: "22/23"
  //     },
  //     {
  //         ID: 400,
  //         Category: "CAT2",
  //         NumberAvailable: 11,
  //         Gameday: "25",
  //         Date: "18.03.2023",
  //         Time: "15:30",
  //         Home: "Borussia M'gladbach",
  //         Guest: "FC Schalke 04",
  //         header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //         availableVIP: 0,
  //         homeID: 167,
  //         guestID: 180,
  //         MatchdayDateTime: "2023-03-18T15:30:00",
  //         Saison: "22/23"
  //     },
  //     {
  //         ID: 401,
  //         Category: "CAT2",
  //         NumberAvailable: 11,
  //         Gameday: "25",
  //         Date: "18.03.2023",
  //         Time: "15:30",
  //         Home: "VfB Stuttgart",
  //         Guest: "Bayer 04 Leverkusen",
  //         header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //         availableVIP: 0,
  //         homeID: 164,
  //         guestID: 172,
  //         MatchdayDateTime: "2023-03-18T15:30:00",
  //         Saison: "22/23"
  //     },
  //     {
  //       ID: 402,
  //       Category: "CAT2",
  //       NumberAvailable: 11,
  //       Gameday: "25",
  //       Date: "18.03.2023",
  //       Time: "18:30",
  //       Home: "Bayer 04 Leverkusen",
  //       Guest: "1. FC Köln",
  //       header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //       availableVIP: 0,
  //       homeID: 171,
  //       guestID: 176,
  //       MatchdayDateTime: "2023-03-18T18:30:00",
  //       Saison: "22/23"
  //   },
  //   {
  //       ID: 403,
  //       Category: "CAT2",
  //       NumberAvailable: 11,
  //       Gameday: "25",
  //       Date: "19.03.2023",
  //       Time: "17:30",
  //       Home: "Bayer 04 Leverkusen",
  //       Guest: "FC Bayern München",
  //       header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //       availableVIP: 0,
  //       homeID: 169,
  //       guestID: 174,
  //       MatchdayDateTime: "2023-03-19T17:30:00",
  //       Saison: "22/23"
  //   },
  //   {
  //       ID: 404,
  //       Category: "CAT2",
  //       NumberAvailable: 11,
  //       Gameday: "25",
  //       Date: "19.03.2023",
  //       Time: "15:30",
  //       Home: "Borussia Dortmund",
  //       Guest: "Borussia M'gladbach",
  //       header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //       availableVIP: 0,
  //       homeID: 181,
  //       guestID: 182,
  //       MatchdayDateTime: "2023-03-19T15:30:00",
  //       Saison: "22/23"
  //   },
  //   {
  //       ID: 405,
  //       Category: "CAT2",
  //       NumberAvailable: 11,
  //       Gameday: "25",
  //       Date: "19.03.2023",
  //       Time: "19:30",
  //       Home: "FC Augsburg",
  //       Guest: "SC Freiburg",
  //       header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //       availableVIP: 0,
  //       homeID: 163,
  //       guestID: 161,
  //       MatchdayDateTime: "2023-03-19T19:30:00",
  //       Saison: "22/23"
  //   },
  //   {
  //       ID: 406,
  //       Category: "CAT2",
  //       NumberAvailable: 11,
  //       Gameday: "25",
  //       Date: "18.03.2023",
  //       Time: "15:30",
  //       Home: "FC Augsburg",
  //       Guest: "Hertha BSC",
  //       header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //       availableVIP: 0,
  //       homeID: 185,
  //       guestID: 168,
  //       MatchdayDateTime: "2023-03-18T15:30:00",
  //       Saison: "22/23"
  //   },
  //   {
  //       ID: 407,
  //       Category: "CAT2",
  //       NumberAvailable: 11,
  //       Gameday: "25",
  //       Date: "17.03.2023",
  //       Time: "20:30",
  //       Home: "Borussia M'gladbach",
  //       Guest: "SV Werder Bremen",
  //       header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //       availableVIP: 0,
  //       homeID: 175,
  //       guestID: 159,
  //       MatchdayDateTime: "2023-03-17T20:30:00",
  //       Saison: "22/23"
  //   },
  //   {
  //       ID: 408,
  //       Category: "CAT2",
  //       NumberAvailable: 11,
  //       Gameday: "25",
  //       Date: "18.03.2023",
  //       Time: "15:30",
  //       Home: "VfL Bochum 1848",
  //       Guest: "Borussia M'gladbach",
  //       header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //       availableVIP: 0,
  //       homeID: 162,
  //       guestID: 170,
  //       MatchdayDateTime: "2023-03-18T15:30:00",
  //       Saison: "22/23"
  //   },
  //   {
  //       ID: 409,
  //       Category: "CAT2",
  //       NumberAvailable: 11,
  //       Gameday: "25",
  //       Date: "18.03.2023",
  //       Time: "15:30",
  //       Home: "FC Augsburg",
  //       Guest: "Borussia M'gladbach",
  //       header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //       availableVIP: 0,
  //       homeID: 167,
  //       guestID: 180,
  //       MatchdayDateTime: "2023-03-18T15:30:00",
  //       Saison: "22/23"
  //   },
  //   {
  //       ID: 410,
  //       Category: "CAT2",
  //       NumberAvailable: 11,
  //       Gameday: "25",
  //       Date: "18.03.2023",
  //       Time: "15:30",
  //       Home: "Hertha BSC Berlin",
  //       Guest: "VfL Wolfsburg",
  //       header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //       availableVIP: 0,
  //       homeID: 164,
  //       guestID: 172,
  //       MatchdayDateTime: "2023-03-18T15:30:00",
  //       Saison: "22/23"
  //   },
  //   {
  //     ID: 411,
  //     Category: "CAT2",
  //     NumberAvailable: 11,
  //     Gameday: "25",
  //     Date: "19.03.2023",
  //     Time: "17:30",
  //     Home: "1. FC Heidenheim 1846",
  //     Guest: "FC Bayern München",
  //     header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //     availableVIP: 0,
  //     homeID: 169,
  //     guestID: 174,
  //     MatchdayDateTime: "2023-03-19T17:30:00",
  //     Saison: "22/23"
  // },
  // {
  //     ID: 412,
  //     Category: "CAT2",
  //     NumberAvailable: 11,
  //     Gameday: "25",
  //     Date: "19.03.2023",
  //     Time: "15:30",
  //     Home: "1. FC Union Berlin",
  //     Guest: "Borussia M'gladbach",
  //     header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //     availableVIP: 0,
  //     homeID: 181,
  //     guestID: 182,
  //     MatchdayDateTime: "2023-03-19T15:30:00",
  //     Saison: "22/23"
  // },
  // {
  //     ID: 413,
  //     Category: "CAT2",
  //     NumberAvailable: 11,
  //     Gameday: "25",
  //     Date: "19.03.2023",
  //     Time: "19:30",
  //     Home: "Hertha BSC Berlin",
  //     Guest: "SC Freiburg",
  //     header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //     availableVIP: 0,
  //     homeID: 163,
  //     guestID: 161,
  //     MatchdayDateTime: "2023-03-19T19:30:00",
  //     Saison: "22/23"
  // },
  // {
  //     ID: 414,
  //     Category: "CAT2",
  //     NumberAvailable: 11,
  //     Gameday: "25",
  //     Date: "18.03.2023",
  //     Time: "15:30",
  //     Home: "Borussia M'gladbach",
  //     Guest: "Hertha BSC",
  //     header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //     availableVIP: 0,
  //     homeID: 185,
  //     guestID: 168,
  //     MatchdayDateTime: "2023-03-18T15:30:00",
  //     Saison: "22/23"
  // },
  // {
  //     ID: 415,
  //     Category: "CAT2",
  //     NumberAvailable: 11,
  //     Gameday: "25",
  //     Date: "17.03.2023",
  //     Time: "20:30",
  //     Home: "Borussia M'gladbach",
  //     Guest: "SV Werder Bremen",
  //     header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //     availableVIP: 0,
  //     homeID: 175,
  //     guestID: 159,
  //     MatchdayDateTime: "2023-03-17T20:30:00",
  //     Saison: "22/23"
  // },
  // {
  //     ID: 416,
  //     Category: "CAT2",
  //     NumberAvailable: 11,
  //     Gameday: "25",
  //     Date: "18.03.2023",
  //     Time: "15:30",
  //     Home: "VfL Bochum 1848",
  //     Guest: "Borussia M'gladbach",
  //     header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //     availableVIP: 0,
  //     homeID: 162,
  //     guestID: 170,
  //     MatchdayDateTime: "2023-03-18T15:30:00",
  //     Saison: "22/23"
  // },
  // {
  //     ID: 417,
  //     Category: "CAT2",
  //     NumberAvailable: 11,
  //     Gameday: "25",
  //     Date: "18.03.2023",
  //     Time: "15:30",
  //     Home: "FC Augsburg",
  //     Guest: "Borussia M'gladbach",
  //     header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //     availableVIP: 0,
  //     homeID: 167,
  //     guestID: 180,
  //     MatchdayDateTime: "2023-03-18T15:30:00",
  //     Saison: "22/23"
  // },
  // {
  //     ID: 418,
  //     Category: "CAT2",
  //     NumberAvailable: 11,
  //     Gameday: "25",
  //     Date: "18.03.2023",
  //     Time: "15:30",
  //     Home: "Borussia M'gladbach",
  //     Guest: "VfL Wolfsburg",
  //     header: "https://milkatickets.digital-promo.de/content/banners/dummy.jpg",
  //     availableVIP: 0,
  //     homeID: 164,
  //     guestID: 172,
  //     MatchdayDateTime: "2023-03-18T15:30:00",
  //     Saison: "22/23"
  // }];

    this.GetMatchForTeam();
        
    let selectedMatch = matches.filter(
      (item) => favoriteClub === item.Home || favoriteClub === item.Guest
    );

    this.InitMatches(selectedMatch);
  }

  componentWillUnmount() {
    this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType) || _TICKET_TYPES.CAT2});
    mainLoaderToggle("show");
  }

  render() {
    return (
      <Aux>
        <FanCenterSCBanner category={this.state.matchDetail ? "-ticket-detail" : "-ticket-choice"}/>
        <div className="buzzer-jersey team-selection">
          {this.HeadingSection()}

          <form
            id="frm_team_selection"
            className="ticket"
            ref={(form) => (this._TEAM_SELECTION = form)}
            name="frm_team_selection"
            method="post"
            action="/"
          ></form>

          <div className={['match-list', this.state.matchDetail ? 'match-list__detail' : ''].join(' ')} >
            <MatchList
              matches={this.state.matches}
              checkedOneMatch={this.checkedOneMatch.bind(this)}
              selectionUpdate={this.UpdateSelected.bind(this)}
              homeMatch={this.homeMatch.bind(this)}
              awayMatch={this.awayMatch.bind(this)}
              selectionAllowed={true}
              showDetail={this.showDetail.bind(this)}
              getTicketButtonTag={this.TicketButtonTag.bind(this)}
            />
          </div>

          <Footersocial />
          {this.state.SimpleLoader ? <Loader /> : null}
        </div>
      </Aux>
    );
  }

  showDetail(isShown) {
    _SCROLL_PAGE.ScrollToTop();
    this.setState({
      matchDetail: isShown,
    });
  }

  homeMatch() {
    const favoriteClub = localStorage.getItem(_LOCAL.FavoriteClub);
    let selectedMatch = this.state.allMatches.filter(
      (item) => favoriteClub === item.Home
    );
    this.setState({
      matches: selectedMatch,
    });
  }
  
  awayMatch() {
    const favoriteClub = localStorage.getItem(_LOCAL.FavoriteClub);
    let selectedMatch = this.state.allMatches.filter(
      (item) => favoriteClub === item.Guest
    );
    this.setState({
      matches: selectedMatch,
    });
  }

  checkedOneMatch() {
    this.setState({
      isSelectedMatch: true,
    });
  }

  TicketButtonTag = (dataLayerOption, dataLayerOptionVip) => {
    if(this.state.TypeTicket === _TICKET_TYPES.CAT2) {
        appGoogleTracking.dataLayerPush(dataLayerOption)
        console.log(dataLayerOption);
    }
    else if (this.state.TypeTicket === _TICKET_TYPES.VIP) {
        appGoogleTracking.dataLayerPush(dataLayerOptionVip)
        console.log(dataLayerOptionVip);
    }
  }

  
  HeadingSection() {
    return (
      <Aux>
        <div className="spaceTile-intropage">
          <div className="campaign-heading">
            <JerseyStepIndicitor Steps={this.StepList} />
            {this.state.matchDetail ?
            null : (
              <div className="text">
                <h2>welche partie möchtest du live im stadion erleben?</h2>
                <p className="lilac">
                  Heimspiel? Auswärtsspiel? Wähle ein Spiel aus und denk dran: Schnell sein lohnt sich. Falls die von dir ausgewählten Tickets zwischenzeitlich nicht mehr verfügbar sein sollten, wirst du wieder auf diese Seite geleitet.
                </p>
            </div>
            )}
          </div>
        </div>
      </Aux>
    );
  }

  SetChoice(event) {
    let _DOM = event.target,
      value = _DOM.value;
    let tousMatchs = [];
    if (value === "1") {
      tousMatchs = this.state.allMatches;
    } else if (value === "2") {
      tousMatchs = this.state.allMatches.filter(
        (elt) => elt.isSingleTicket == false
      );
    }
    this.setState(
      {
        TicketChoice: value,
        matches: tousMatchs,
        isSelectedMatch: false,
        SelectedMatch: null,
      },
      () => {
        const allCheckMarks = document.querySelectorAll(".checkmark");
        for (let i = 0; allCheckMarks && i < allCheckMarks.length; i++) {
          allCheckMarks[i].setAttribute("data-checked", "false");
        }
      }
    );
  }

  TicketsChoice() {
    return (
      <div className="tickets-choice-wrapper">
        {this.state.GiftCategory === _TICKET_TYPES.VIP ? (
          <h2>Wähle 1 oder 2 VIP-Tickets</h2>
        ) : (
          <h2>Wähle ein oder zwei CAT2 Tickets</h2>
        )}
        <div className="tickets-select js-purchase-options">
          <label className="custom-inputs" htmlFor="ticket_1">
            {" "}
            {this.state.GiftCategory === _TICKET_TYPES.VIP
              ? "1 VIP- Ticket"
              : "1 Ticket"}
            <input
              type="radio"
              name="ticket"
              id="ticket_1"
              value="1"
              className="js-event-type-in js-event-ab"
              data-require="true"
              data-type="radio"
              data-event-target=".js-purchase-options"
              data-error-target="#error-ticket"
              data-required-message=""
              data-event-fieldname="ticket_1"
              data-event="form-click"
              data-category="Form Action"
              data-action="ticket"
              data-label="Type In"
              onClick={this.SetChoice.bind(this)}
            />
            <span className="radiobtn"></span>
          </label>
          <label className="custom-inputs" htmlFor="ticket_2">
            {" "}
            {this.state.GiftCategory === _TICKET_TYPES.VIP
              ? "2 VIP- Tickets"
              : "2 Tickets"}
            <input
              type="radio"
              name="ticket"
              id="ticket_2"
              value="2"
              className="js-event-type-in js-event-ab"
              data-require="true"
              data-type="radio"
              data-event-target=".js-purchase-options"
              data-error-target="#error-purchase"
              data-required-message=""
              data-event-fieldname="ticket_2"
              data-event="form-click"
              data-category="Form Action"
              data-action="ticket"
              data-label="Type In"
              onClick={this.SetChoice.bind(this)}
            />
            <span className="radiobtn"></span>
          </label>
        </div>
      </div>
    );
  }

  FooterSection() {
    return (
      <div className="prizeRewe campaign-footer">
        <h2>Du hast schon einen Gewinncode?</h2>
        <h3>
          Wow! Wir gratulieren dir zu deinem FC Milka Fußball-Gewinn. Du bist
          nur noch wenige Klicks von deinem Gewinn entfernt.
        </h3>
        <NavLink to={GLOBAL_CONFIG.Route.fanCenter.codeEntry.user}>
          <button className="btn__container btn--red">
            HIER GEHT'S WEITER
          </button>
        </NavLink>
      </div>
    );
  }

  GetMatchForTeam(){
    var team = localStorage.getItem(_LOCAL.FavoriteClub);
    var _data = {
      uid: localStorage.getItem(_LOCAL.UserId) ? localStorage.getItem(_LOCAL.UserId)  :'B5E18727-1208-4D6C-A88C-31C2E5F74736'
    }
    var matches = [];

    FanCenterServices.CheckStock(_data)
        .then((response) => {
          this.setState({
            SimpleLoader: true,
          });
          let {message, details} = response.data, {GameDays,participant} = details;

          if(message === "OK"){
            GameDays.forEach((match) => {
              matches.push(match);
            });

            localStorage.setItem(_LOCAL.UserDetails,JSON.stringify(participant));

            let teamMatches = [];

            teamMatches = matches.filter((elt) => (elt.Home === team || elt.Guest === team) );

            console.log(teamMatches);

            this.InitMatches(teamMatches);

          }
        })
        .catch(() => {
          let matches = [];
        })
        .finally(() => {
          this.setState({
            SimpleLoader: false,
          });
        })


  }

  setMatchesButFavouriteClub(team) {
    this.setState({
      TeamSelected: team,
      SimpleLoader: true,
      isSelectedMatch: false,
    });

    /**
     * Get Matches
     */
    var teamIDs = JSON.parse(localStorage.getItem(_LOCAL.AvailableTeamIDs)),
      _data = {
        GiftCategory: localStorage.getItem(_LOCAL.GiftCategory),
        GiftType: _GIFT.Ticket,
        TeamId: !!teamIDs ? teamIDs[team] : "id-unknown",
      };

    FanCenterServices.GetMatches(_data)
      .then((response) => {
        let { success, message, data } = response.data,
          { Tickets } = data;

        if (success) {
          var matches = [];

          Tickets.forEach((match) => {
            let options = {
                weekday: "short",
                year: "numeric",
                month: "numeric",
                day: "numeric",
              },
              matchLocation = `${match.location} (${match.groundName})`;
            let matchDate = null,
              matchTime = null;
            if (match.weekendDate) {
              matchDate = `Spieltag ${match.gameweek} (${match.weekendDate})`;
              matchTime = "";
            } else {
              matchDate = new Date(match.matchDate).toLocaleString(
                "de-DE",
                options
              );
              matchTime = new Date(match.matchDate).toLocaleTimeString(
                "de-DE",
                { hour: "2-digit", minute: "2-digit" }
              );
            }
            matches.push(
              new Match({
                id: match.footballMatchId,
                date: `${matchDate} ${matchTime}`,
                home: match.home,
                away: match.away,
                outStock:
                  match.outOfStock === "True" || match.outOfStock === true
                    ? true
                    : false,
                location: matchLocation || "Match Location",
                matchDay: "0",
                gameweek: match.gameweek,
                weekendDate: match.weekendDate,
                isSingleTicket: match.isSingleTicket,
              })
            );
          });

          this.InitMatches(matches);
        }
      })
      .catch(() => {
        let matches = [];
      })
      .finally(() => {
        this.setState({
          SimpleLoader: false,
        });
      });
  }

  UpdateSelected(selected) {
    this.setState({
      SelectedMatch: selected,
      TicketChoice: this.state.TicketChoice,
    });
  }

  SubmitMatchChoice(event) {
    var {
      SelectedMatch,
      TeamSelected,
      IsRemainingTicket,
      TicketChoice,
      isSelectedMatch,
    } = this.state;
    if (!TicketChoice) {
      this.setState({
        selectionError:
          "Bitte wähle die Anzahl der Tickets aus, die wir dir zuschicken sollen.",
      });
      return;
    } else if (!this.state.isSelectedMatch) {
      this.setState({
        selectionError:
          "Bitte wähle ein Spiel deiner Wahl aus, das du besuchen möchtest",
      });
      return;
    }

    if (
      isSelectedMatch &&
      SelectedMatch.isSingleTicket == "true" &&
      TicketChoice === "2"
    ) {
      this.setState({
        TeamSelected: null,
        TicketChoice: "",
        selectionError:
          "Bitte wähle die Anzahl der Tickets aus, die wir dir zuschicken sollen.",
      });
      return;
    }

    var _data = {
      ...JSON.parse(localStorage.getItem(_LOCAL.UserDetails)),
      Match: SelectedMatch,
      TicketChoice: TicketChoice,
    };
    localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(_data));
    this.props.UpdateFancenterStatus(_STATUS.Fancenter.teamSelected);

    if (IsRemainingTicket) {
      this.props.history.push(
        GLOBAL_CONFIG.Route.fanCenter.tickets.userDetails
      );
    } else if (!!SelectedMatch && TeamSelected) {
      switch (this.state.UserType) {
        case _USER_TYPES.User:
          this.props.history.push(
            GLOBAL_CONFIG.Route.fanCenter.tickets.userDetails
          );
          break;
        case _USER_TYPES.Comp1:
          this.props.history.push(
            GLOBAL_CONFIG.Route.fanCenter.tickets.companion.userDetails
          );
          break;

        default:
          break;
      }
    }
  }

  InitMatches(matches) {
    /**
     * Preselect first match in list & drop down
     */
    const favoriteClub = localStorage.getItem(_LOCAL.FavoriteClub);
    let matchList = [...matches],
      index = 0,
      selectIndex = null;
    let match2Tickets = matchList;
    let selectedMatch = matchList.filter(
      (item) => favoriteClub === item.Home
    );
    if (selectIndex) {
      this.setState({
        matches: selectedMatch,
        allMatches: match2Tickets,
        selected: match2Tickets[selectIndex],
      });
    } else {
      this.setState({
        matches: selectedMatch,
        allMatches: match2Tickets,
      });
    }
  }

  ScrollCovidInfo(e) {
    this.setState({
      CovidInfo: true,
    });

    _SCROLL_PAGE.ScrollSection(document.getElementById("Covid-Details"));
  }
}

export default withTranslation()(withRouter(TeamSelection));
