import React, { Component } from 'react';

import { withTranslation } from "react-i18next";
import GLOBAL_CONFIG from '../../config/config';
import NavigationAccount from '../NavigationAccount/NavigationAccount';
import { servicePath, mainLoaderToggle, optionListConstruct, timeStamp, getCookie } from '../../modules/helpers';
import axios from 'axios';
import { withRouter } from 'react-router';

import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import appGoogleTracking from '../../modules/googletracking';

class AccountDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            milka: true,
            tokenLogin: null,
            loaderSubmit: null,
            accountDeleted: null
        };

    }

    componentWillMount() {
        if (getCookie(GLOBAL_CONFIG.Session.tokenstatus)) {
            let getLoginDetailURL = servicePath({
                ...GLOBAL_CONFIG.ServiceSettings,
                status: 'details'
            });
            let tokenInfo = getCookie(GLOBAL_CONFIG.Session.tokenstatus)
            console.log(tokenInfo);
            axios({
                method: 'POST',
                url: getLoginDetailURL,
                data: {
                    "token": tokenInfo
                },
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    console.log(response)
                    let _data = response.data;
                    if (_data.success) {
                        this.setState({
                            tokenLogin: response.data.token,
                        });

                        // this.props.history.push({pathname: GLOBAL_CONFIG.Route.loginDetail});
                        if (_data.isFcAccUser === true) {
                            this.setState({
                                milka: true,
                            })
                            console.log("fc account true");
                        }
                        else if (_data.isFcAccUser === false) {
                            console.log("fc account false");
                            this.setState({
                                milka: false,
                            })

                            console.log("fc account false");
                            mainLoaderToggle('hide');
                            console.log(this.state.milka);
                        }
                    }

                })
                .catch((response) => {
                    console.log(response);
                    console.log("catch getprofile loginDEtails")
                    //this.props.history.push({ pathname: GLOBAL_CONFIG.Route.loginDetail });
                });
        }
        else {
            this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
        }
    }

    componentDidMount() {
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    processFormData = () => {
        //Open loader
        this.setState({
            loaderSubmit: true
        });
        // token=sessionStorage.getItem(GLOBAL_CONFIG.Session.loginStatus) 
        //Process form data
        let data = {
            token: getCookie(GLOBAL_CONFIG.Session.tokenstatus),
        };

        //Get webservice path
        let getUpdateProfileURL = servicePath({
            ...GLOBAL_CONFIG.ServiceSettings,
            campaign: this.state.promotionCampaign,
            status: 'deleteProfile'
        });

        //Ajax call via axios
        axios({
            method: 'post',
            url: getUpdateProfileURL,
            data: data,
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                console.log(response)
                if (response.status === 200 || response.status === 201) {
                    if (response.data.success === true) {


                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 'proceed_deletion',
                            'dataCategory': 'Delete Account Page',
                            'dataAction': 'Click on Delete ',
                            'dataLabel': 'ENG_DA_OTHER'
                        })

                        this.setState({
                            loaderSubmit: false
                        })
                        
                        this.setState({
                            accountDeleted: true
                        })
                        document.cookie = GLOBAL_CONFIG.Session.tokenstatus + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                    } else {
                        let errorStatus = response.data.data.Error[0];
                        // this.recaptcha.reset();     //Reset captcha when error occurs - deploy
                    }
                } else {
                    //Show error messages
                    console.log('Error 404 or 500');
                    //Close Loader
                    this.setState({
                        loaderSubmit: false,
                        loaderGeneralError: true
                    });

                    // this.recaptcha.reset();     //Reset captcha when error occurs
                }
            })
            .catch((response) => {
                //handle error
                // //Close Loader
                this.setState({
                    loaderSubmit: false,
                    loaderGeneralError: true,
                });
                //  this.props.history.push({pathname: GLOBAL_CONFIG.Route.loginDetail});
                // this.recaptcha.reset();     //Reset captcha when error occurs
            });
    }

    deleteProfile = (event) => {
        event.preventDefault();
        this.processFormData();
    }

    logout = () => {
        console.log("logout")
        appGoogleTracking.dataLayerPush({
            'dataEvent': 'user_logout',
            'dataCategory': 'Inline Menu',
            'dataAction': 'Click on Logout ',
            'dataLabel': 'ENG_AD_NAVBAR'
        })
        if (getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null) {
            console.log("nor null");
            sessionStorage.setItem(GLOBAL_CONFIG.Session.tokenstatus, null);
            document.cookie = GLOBAL_CONFIG.Session.tokenstatus + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            //document.cookie = GLOBAL_CONFIG.Session.tokenstatus + '=' + null + ';domain=' + GLOBAL_CONFIG.DomainName;
            console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
            this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
        }
        this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
    }

    onClosePopupHandler = () => {
        this.setState({
            loaderGeneralError: false
        });
        if(this.state.accountDeleted) {
            this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
        }
    }

    render() {
        let accountDeleted = null,
            milka = null,
            errorLoaderGeneral = null;

        if (this.state.milka) {
            milka = (
                <div className="profile_detail account-delete">
                    <div className="left">
                        <NavigationAccount />
                        <button className="btnLogout logout-lg" onClick={this.logout}>
                            <span className="btn__logout">Ausloggen</span>
                        </button>
                    </div>
                    <div className="form-container grid-8-m  grid-s-2 logout-sm">
                        <button className="btnLogout" onClick={this.logout}>
                            <span className="btn__logout">Ausloggen</span>
                        </button>

                    </div>
                    <div className="right">
                        <div className="account-delete__wrapper">
                            <h1>FC Milka Account löschen</h1>
                            <p>
                                Du kannst deinen FC Milka Account jederzeit löschen. Bitte beachte, dass du es möglicherweise nicht mehr wiederherstellen kannst, falls du deine Meinung änderst.
                            </p>
                            <p>
                                Möchtest du wirklich deinen FC Milka Account löschen?
                            </p>
                            <div className="form-button deleteProfile">
                                <button className="noSpace btn__container" onClick={this.deleteProfile}>
                                    JA, LÖSCHEN
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }

        if (this.state.accountDeleted) {
            accountDeleted = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                        <p id="js-error-msg">Dein Account wurde erfolgreich gelöscht.</p>
                    </div>
                </div>
            );
        }

        if (this.state.loaderGeneralError) {
            errorLoaderGeneral = (
                <div className="js-overlay overlay overlay--black">
                    <div className="overlay__container overlay__content">
                        <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                        <p id="js-error-msg">Ein Fehler ist aufgetreten. Bitte versuchen <br />Sie es später noch einmal.</p>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {milka}
                {accountDeleted}
                {errorLoaderGeneral}
                <Footersocial/>
            </div>
        );
    }
}


export default withTranslation()(withRouter(AccountDelete));