import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import Aux from '../../../hoc/Auxiliare';     

class MetaHeader extends Component {
    
    render() {
        const { t, og_title, og_description, og_sitename, description, title } = this.props;

        return (
            <Aux>
                <Helmet>
                    <title>{title}</title>
                    <meta name="title" content={title} />
                    <meta name="description" content={description} />
                    {/* <meta property="og:url" content={t('Meta.ogUrl')} />  */}
                    <meta property="og:title" content={og_title} />
                    <meta property="og:description" content={og_description} />
                    <meta property="og:site_name" content={og_sitename} />
                    {/* <meta property="og:image" content={t('Meta.ogImage')} /> */}
                    <body className={this.props.promotionCampaign} />
                </Helmet>
            </Aux>
        );
    }
}

export default withTranslation()(MetaHeader);