import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import appGoogleTracking from '../../../modules/googletracking';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';     // Import Swiper React components
import 'swiper/swiper.scss';    // Import Swiper styles
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, EffectFade]);

class SliderContentEdeka extends Component {

    
    render() {
        
        const { t } = this.props;

        let sliderObj = t('Page.Home.sliderEdeka', { returnObjects: true });

        let swiperSlides = sliderObj.map((obj) => {
            return (
                <SwiperSlide key={obj.index}>
                    <div className="slider__background kaufland-background"> 
                        <div className='content-slider__image'>
                        <picture>
                            <source srcSet={obj.imageDesktop} media="(min-width: 800px)" />
                            <source srcSet={obj.imageMobile} media="(min-width: 481px)" />
                            <source srcSet={obj.imageMobile} media="(min-width: 200px)" />
                            <img className="slider__image" src={obj.imageDesktop} alt="Milka Christmas product" />
                        </picture>
                        </div>
                    </div>
                    <div className="content-slider kaufland-slider ">
                        <div className="content-slider__header content-text edeka-copy-medium">{obj.header}</div>
                        <div className="content-slider__description ">{parse(obj.text)}</div>
                        {/* <div className={'content-slider__image '+obj.index}>
                            <img src={obj.image} alt="prodcut image" />
                        </div> */}
                    </div>
                </SwiperSlide>
            );
        });

        return (
            <div className="content-slider-wrapper" >
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: false }}
                    effect="fade"
                    /*onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}*/
                    onSlideNextTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Right'
                        });
                    }}
                    onSlidePrevTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Left'
                        });
                    }}
                >
                    {swiperSlides}
                </Swiper>
          </div>
        );
    }
}

export default withTranslation()(SliderContentEdeka);