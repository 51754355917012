import getGlobusMicroSiteRouting from './globus-microSite/GlobusMicroSiteRouting';
import getKipsRouting from './kip/KipsRouting';
import getPennyRouting from './penny-microsite/PennyRouting';
import getFancenterJerseyRouting from './fancenter-jersey/FancenterJerseyRouting';
import getAccountRouting from './account/AccountRouting';


const getPageRouting = ({ context, props }) => {
    const pageProps = {
        history : context.props.history,
        ...props
    }
    const pennyRouting = getPennyRouting({ ...pageProps});
    const kipsRouting = getKipsRouting({...pageProps});
    const globusMicrositeRoutes = getGlobusMicroSiteRouting(context, {...pageProps});
    const fancenterJerseyRoutes = getFancenterJerseyRouting(context, {...pageProps});
    const accountRoutes = getAccountRouting(context, {...pageProps});

    const pagesMap = [
        ...pennyRouting,
        ...kipsRouting,
        ...globusMicrositeRoutes,
        ...fancenterJerseyRoutes,
        ...accountRoutes
    ];

    return pagesMap;
}

export default getPageRouting;