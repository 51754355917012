import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { mainLoaderToggle } from '../../../modules/helpers'
import TitleLogo from '../../../components/Content/TitleLogo/TitleLogo'
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial'
import GLOBAL_CONFIG from '../../../config/config'
import JerseyNavigation from '../ChooseJersey/JerseyNavigation'
import _TICKET from '../../../Models/TicketImages'
import _LOCAL from '../../../Models/LocalStorageKeys'
import _SCROLL_PAGE from '../../../modules/ScrollPage'
import _STATUS from '../../../Models/ParticipationSteps'
import _USER_TYPES from '../../../Models/UserTypes'
import _TICKET_TYPES from '../../../Models/TicketTypes'
import { withRouter } from 'react-router-dom'
import Aux from '../../../hoc/Auxiliare'
import FanCenterSCBanner from '../Banner/FanCenterSCBanner'
import SectionTextButton from '../../../components/Content/SectionTextButton/SectionTextButton'
import CardThankYou from '../../Campaign/Reihe/component/Card_ThankYou/CardThankYou'

class Confirmation extends Component {
  constructor() {
    super()

    this.state = {
      CovidInfo: false,
      WinnerName: 'Winner Name',
      MatchDate: 'Sa. 01.01.2021',
      MatchLocation: 'Match Location',
      HomeTeam: 'Home Team',
      AwayTeam: 'Away Team',
      UserType: '',
      Gift: _TICKET.STD,
      GiftCategory: localStorage.getItem(_LOCAL.GiftCategory),
      TicketChoice: '1',
      TypeTicket: "",
    }

    this.HeadingSection = this.HeadingSection.bind(this)
  }

  componentDidMount() {
    let UserDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails))

    if (!!UserDetails) {
      this.setState({
        UserType: UserDetails.UserType,
        TicketChoice: UserDetails.TicketChoice,
        Gift: _TICKET[localStorage.getItem(_LOCAL.GiftCategory)] || _TICKET.STD,
      })

      if (!!UserDetails.Match) {
        this.setState({
          MatchDate: UserDetails.Match.date,
          MatchLocation: UserDetails.Match.location,
          HomeTeam: UserDetails.Match.home,
          AwayTeam: UserDetails.Match.away,
          WinnerName: UserDetails.Name,
        })
      }
    }

    _SCROLL_PAGE.ScrollToTop();
    mainLoaderToggle('hide')
  }

  componentWillMount() {
    this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType) || _TICKET_TYPES.CAT2});
    if (localStorage.getItem(_LOCAL.UserDetails) === null) {
      // this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user)
    }
  }

  componentWillUnmount() {
    setTimeout(() => {
      localStorage.clear()
    }, 15000);
    mainLoaderToggle('show')
  }

  render() {
    return (
      <Aux>
        <FanCenterSCBanner category={(this.state.TypeTicket === _TICKET_TYPES.CAT2 ? "-confirmation" : "-confirmation")}/>
        <div className="buzzer-jersey lottery" id="confirmation">
          {this.HeadingSection()}
          {this.FooterSection()}
          <Footersocial />
        </div>
      </Aux>
    )
  }

  HeadingSection() {
    const Gift = this.state.Gift ? this.state.Gift : _TICKET.CAT2
    const desktopImage = typeof Gift === 'string' ? Gift : Gift.desktop
    const mobileImage = typeof Gift === 'string' ? Gift : Gift.mobile
    let ticketImage = {
      desktop: desktopImage,
      mobile: mobileImage,
    }

    return (
      <div className="spaceTile-intropage">
        <div className="campaign-heading">
          <div className='section-heading'>
          <SectionTextButton/>

          </div>
        </div>
      </div>
    )
  }

  FooterSection() {

    const tagging1 = {
      event: "subscribe_newsletter",
      category: "Thank You Page",
      action: "Go to Page",
      label: "ENG_TY_OTHER"
  }
  const tagging2 = {
      event: "go_to_products",
      category: "Thank You Page",
      action: "Go to Page",
      label: "ENG_TY_OTHER"
  }

  const tagging3 = {
      event: "discover_mmax_milka",
      category: "Thank You Page",
      action: "Go to Page",
      label: "ENG_TY_OTHER"
  }
    return (
      
      <div className="confirmationFancenter">
                    <div className="thank-you">
                        <CardThankYou img={'/resources/imagesBundesliga/sommer/thank-you/thanks1.png'} title='Milka Newsletter' text='Du willst keine spannenden Neuigkeiten mehr verpassen? Dann abonniere jetzt den kostenlosen Milka Newsletter! Regelmäßig informieren wir dich über neue Produkte, Gewinn-Aktionen und natürlich über alles, was beim FC Milka los ist. Worauf wartest du noch?' btn='NEWSLETTER ABONNIEREN' tagging={tagging1} link='https://www.milka.de/newsletter' />
                        <CardThankYou img={'/resources/imagesBundesliga/sommer/thank-you/thanks2.png'} title='Entdecke die Milka Favourites' text='Milka Favourites – das ist der leckere Mix, der alle zusammenbringt. Milka Favourites passen zum geselligen Zusammensein mit deinen Freunden und sind perfekt zum Teilen. Der einfach unwiderstehliche Snack in sechs beliebten Geschmacksrichtungen. Welche ist dein Favorit?' btn='MILKA FAVOURITES' tagging={tagging2} link='https://favourites.milka.de/' />                                        
                        <CardThankYou img={'/resources/imagesBundesliga/sommer/thank-you/thanks3.png'} title='Milka Mmmax – mmmaximaler Genuss' text='Milka Mmmax Großtafeln bieten dir mehr von allem: mmmaximal viel zarte Alpenmilch Schokolade, mmmaximal großzügige Zutaten für ein mmmaximales Geschmackserlebnis. Entdecke den maximalen Milka Genuss.' btn='MILKA MMMAX' tagging={tagging3} link='https://mmmax.milka.de/' />
                  </div>
      </div>
     
      // <div className="s-content-end">
      //   <div className="end-element">
      //     <div className="end-element__item">
      //       <img src="/resources/imagesBundesliga/buzzer/end/fancenter-23-24/box-1.png" />
      //       <div className="end-element__copy">
      //         <h3 className="end-element__copy__title">fc milka mitglied werden</h3>
      //         <p>
      //           Jetzt kostenlos registrieren und viele Vorteile genießen. Sei
      //           dabei und melde dich an.
      //         </p>
      //         <a href="https://fcmilka.de/account" target="_blank">
      //           <button className="btn__container btn--secondary-lilac">
      //             {' '}
      //             REGISTRIEREN{' '}
      //           </button>
      //         </a>
      //       </div>
      //     </div>

      //     <div className="end-element__item">
      //       <img src="/resources/imagesBundesliga/buzzer/end/fancenter-23-24/box-2.png" />
      //       <div className="end-element__copy">
      //         <h3 className="end-element__copy__title">nervennahrung nötig?</h3>
      //         <p>
      //           Die Bundesliga ist spannend wie eh und je – versüße dir die
      //           aufregenden Spiele mit zarter Milka Schokolade.
      //         </p>
      //         <a href="https://www.milka.de/shop" target="_blank">
      //           <button className="btn__container btn--secondary-lilac">
      //             {' '}
      //             SCHOKI SHOPPEN{' '}
      //           </button>
      //         </a>
      //       </div>
      //     </div>

      //     <div className="end-element__item">
      //       <img src="/resources/imagesBundesliga/buzzer/end/fancenter-23-24/box-3.png" />
      //       <div className="end-element__copy">
      //         <h3 className="end-element__copy__title">neues von milka</h3>
      //         <p>
      //           Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte
      //           Produktnews oder Infos zum Milka Engagement.
      //         </p>
      //         <a href="https://www.milka.de/neues" target="_blank">
      //           <button className="btn__container btn--secondary-lilac">
      //             {' '}
      //             NEUES ENTDECKEN{' '}
      //           </button>
      //         </a>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    )
  }

  ScrollCovidInfo(e) {
    this.setState({
      CovidInfo: true,
    })

    _SCROLL_PAGE.ScrollSection(document.getElementById('Covid-Details'))
  }
}

export default withRouter(Confirmation)
