
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { mainLoaderToggle } from '../../../modules/helpers';
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial';
import _SCROLL_PAGE from '../../../modules/ScrollPage';
import _LOCAL from '../../../Models/LocalStorageKeys';

class Faq extends React.Component {
    
        componentDidMount() {
        mainLoaderToggle('hide');
        // setTimeout(()=>{
        //     _SCROLL_PAGE.ScrollToTop();
        // }, 200)
        if(this.typeFaq() === 'trikots') this.renderScroll();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div id="buzzer-faqs">
                 { this.faqContent(this.props) }
                <Footersocial />
            </div>
        );
    }

    renderScroll(){
        const trikots = document.querySelector("#trikots"),
            yOffset = - 110,
            y = trikots.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({
        top: y,
        behavior: 'smooth'
    });
    }

    typeFaq(){
        let typeTickects = localStorage.getItem(_LOCAL.TicketType);
        let link='';
        if(typeTickects === 'CAT2' || typeTickects === 'VIP')link = 'tickets'
        else if(typeTickects === 'TrikotUnSigned' || typeTickects === 'TrikotSigned')link = 'trikots';
        console.log('TYPE', link);
        return link;
    }

    faqContent(props) {
        const iconPlus = "/resources/imagesBundesliga/buzzer/plus.png";
        const iconMinus = "/resources/imagesBundesliga/buzzer/minus.png";

        return (
            <div className='faq-v1'>
                <div className="content-faq" id='tickets'>
                    <div className='content-faq__title'>
                        <h5> TICKETS</h5>
                    </div>
                      <div className="content-faq__item">
                    <h6>Bekomme ich garantiert Tickets für ein Spiel meines Lieblingsclubs?</h6>
                    <img src={iconMinus} className="content-faq__img shown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content show">
                            <p>Schnell sein lohnt sich! Beliebte Spielpartien sind immer schnell vergriffen und wir
                            können keine Garantie auf Ticket-Wünsche geben (Auswahl der Spielpartie nur im
                                Fan-Center und ausschließlich nach Verfügbarkeit)</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Wie werden die Tickets verschickt?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>Die Tickets werden digital verschickt – per E-Mail oder Club App</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wann kommen die Tickets an? Wo bleiben meine Tickets?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>Die Tickets werden <strong>spätestens</strong> 2-3 Tage vor Spielbegegnung verschickt</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich meine Tickets verschenken?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>Tickets können an Familie / Freunde weitergegeben werden</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Dürfen Tickets verkauft werden?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Nein, der Weiterverkauf der Tickets an Dritte ist ausdrücklich untersagt und wird
vorbehaltlich rechtlich verfolgt
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Wie viele Tickets habe ich bestellt?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Der Gewinn umfasst immer 2 Tickets
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Kann ich die beiden Tickets auf zwei Spielpartien aufteilen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Nein, die beiden Tickets sind immer für dasselbe Spiel
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Kann ich die Tickets für das ausgewählte Spiel nachträglich ändern?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Nein, Änderungen & Umtausch sind ausgeschlossen
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Wie lange habe ich Zeit zu bestellen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Nach Erhalt des Gewinnlinks hast du 7 Tage Zeit um die Tickets über den Link auszuwählen
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Was beinhaltet das Cat2 / VIP Ticket?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Das ist von Club zu Club unterschiedlich, daher bitte auf der Website des Clubs
                            nachschauen oder die Ticket-Hotline des Clubs anrufen
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Gibt es spezielle Auflagen für den Stadionbesuch oder Fragen hierzu?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                           Informiere dich bitte auf der auf der Website des Clubs zu deinem Anliegen
                        </p>
                    </div>
                </div>
                    <div className="content-faq__item-last">                        
                     <p> Für weitere Fragen, die hier noch nicht beantwortet werden konnten, wende dich bitte an unseren <span className='Verbraucherservice'><a href="mailto:Verbraucherservice@mdlz.com" target="_blank" className="btn--anchor no-decoration">Verbraucherservice</a></span>.</p>      
                    </div>
                </div>
                {/* test testse */}
                <div className="content-faq" id='trikots'>
                    <div className='content-faq__title'>
                        <h5>TRIKOTS</h5>
                    </div>
                      <div className="content-faq__item">
                    <h6>Wie und wann nach der Auswahl werden die Trikots verschickt?</h6>
                    <img src={iconMinus} className="content-faq__img shown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content show">
                        <p>
                          Die Trikots werden auf dem Postweg an die angegebene Adresse unmittelbar nach der Auswahl verschickt
                        </p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Kann ich mein Trikot verschenken?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>Das Trikot kann an Familie / Freunde weitergegeben werden</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Darf das Trikot verkauft werden?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>Nein, der Weiterverkauf an Dritte ist ausdrücklich untersagt und wird vorbehaltlich rechtlich verfolgt</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich das ausgewählte Trikot nachträglich ändern?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>Nein, Änderungen & Umtausch sind ausgeschlossen</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Wie lange habe ich Zeit zu bestellen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                           Nach Erhalt des Gewinnlinks hast du 25 Tage Zeit um das Trikot über den Link auszuwählen
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Sind die signierten Trikots waschbar?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                           Bitte beachte, dass es sich um eine Sporttextilie handelt und wir aufgrund der
                            Materialzusammensetzung nicht gewährleisten können, dass die Unterschriften auf der Textile erhalten bleiben.
                        </p>
                    </div>
                </div>
                    <div className="content-faq__item-last">                        
                        <p> Für weitere Fragen, die hier noch nicht beantwortet werden konnten, wende dich
                            bitte an unseren <span className='Verbraucherservice'><a href="mailto:Verbraucherservice@mdlz.com" target="_blank" className="btn--anchor no-decoration">Verbraucherservice</a></span>.</p>      
                    </div>

                </div>
            </div>
        );
    }

    showItemContent(e, iconMinus, iconPlus) {
        let classes = Array.from(e.target.classList);
        let itemContent = e.target.parentNode.children[2];

        if(classes.includes("notshown")){
            e.target.setAttribute( 'src', iconMinus);
            e.target.classList.remove("notshown");
            e.target.classList.add("shown");
            itemContent.classList.add("show");
        }else{
            e.target.setAttribute( 'src', iconPlus);
            e.target.classList.remove("shown");
            e.target.classList.add("notshown");
            itemContent.classList.remove("show");
        }
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <div className="content-separator">
                    <h2>
                        Mit deiner Lieblingsschokolade von Milka gewinnen!
                    </h2>
                    <h3 className="special">
                        Welches ist dein Lieblingsprodukt von Milka? Bist du Fan der zarten Milka Alpenmilch Schokolade? Stehst du auf die Milka Mmmax mit knackigen Erdnüssen und cremigem Karamell? Kannst du den luftig gebackenen Milka Waffelinis nicht widerstehen? Sind unsere Milka Cookie Sensations mit OREO Creme-Füllung vielleicht dein Favorit? Oder naschst du doch am liebsten aus unserem Milka Zarte Momente Mix – damit du dich nie für eine Sorte entscheiden musst?
                    </h3>
                
                    <img src="\resources\imagesBundesliga\buzzer\products.png" alt="Products" />
                
                    <div className="cta-container">
                        <a href="https://www.milka.de/alle-produkte" target="_blank" className="btn--anchor no-decoration">
                            <button className="btn__container btn--lilac">
                                MILKA SCHOKOLADE ENTDECKEN
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
 export default withTranslation()(Faq);