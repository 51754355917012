import React from "react";
import { mainLoaderToggle } from "../../../../modules/helpers";
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import Aux from "../../../../hoc/Auxiliare";

class SupercupEndGlobus extends React.Component {
    componentWillMount() {
        this.props.loadGlobusCampaign();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div className="kaufland micro-globus">
                {this.headingSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <Aux>
                <div className="micro-globus__title-container">
                    <h1>
                        Diese Aktion ist leider beendet.
                    </h1>
                    <h2>
                        Freue dich auf viele weitere FC Milka Aktionen, die kommen werden.
                    </h2>
                </div>
                <div className="s-content-end">
                    <div className="end-element">
                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                            <div className="end-element__copy">
                                    <h3>FC Milka Mitglied werden</h3>
                                    <p>Jetzt kostenlos registrieren und viele Vorteile genießen. Sei dabei und melde dich an.</p>
                                <a href="https://fcmilka.de/account" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> REGISTRIEREN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                            <div className="end-element__copy">
                                    <h3>Nervennahrung nötig?</h3>
                                    <p>
                                        Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                                    </p>
                                <a href="https://www.milka.de/shop" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> SCHOKI SHOPPEN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-3.png" />
                            <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                        Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                    </p>
                                <a href="https://www.milka.de/neues" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footersocial /> */}
            </Aux>
        );
    }
}

export default SupercupEndGlobus;