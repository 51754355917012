const accountModel = () => {
    const ErrorStatus = {
        _INVALID_DATA: "Username or Password invalid",
        _INVALID_CAPTCHA: "INVALID_RECAPTCHA_RESPONSE",
        _GENERIC_ERROR: "AN_ERROR_HAS_OCCURED",
        _INACTIVE: "Account was not activated"
    }

    const ErrorHTML = {
        _DEFAULT: "Ein Fehler ist aufgetreten. Bitte versuchen <br />Sie es später noch einmal."
    }

    return {
        ErrorStatus,
        ErrorHTML
    }
}

const AccountModels = accountModel();

export default AccountModels;