import React, { Component } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';               //Routing component - to display the corresponding containers
import GLOBAL_CONFIG from '../../../config/config';		        //Global Settings that contains URL etc
import Aux from '../../../hoc/Auxiliare';                          //Vitual container 
import MetaHeader from '../../../components/Content/MetaHeader/MetaHeader';
import Header from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import Banner from '../../../components/Layout/Banner';  //End Campaign page content
import Error from '../../Error/Error';                         //Error page content  
//import Cookie from '../../Cookie/Cookie';                      //Cookie page content
import Cookie from '../../Campaign/HeimTrikot/page/Cookie';

import _STATUS from '../../../Models/ParticipationSteps';

import KIPServices from '../../../Service/KIP_Pages';
import _SCROLL_PAGE from '../../../modules/ScrollPage';
import PasswordChangeEmail from '../../PasswordChangeEmail/PasswordChangeEmail';

import { mainLoaderToggle } from '../../../modules/helpers';
import { connect } from "react-redux";
import { setIsBannerShown } from "../../../store/banner";

import getPageRouting from '../PagesRouting';
import Main from '../../Campaign/HeimTrikot/page/Main';
import Holding from '../../Campaign/HeimTrikot/page/Holding';
import HeimtrikotFaq from '../../Campaign/HeimTrikot/page/HeimtrikotFaq';
import HeimtrikotParticipation from '../../Campaign/HeimTrikot/page/HeimtrikotParticipation';
import Confirmation from '../../Campaign/HeimTrikot/page/Confirmation';
import End from '../../Campaign/HeimTrikot/page/End';
import BuzzerTerms from '../../Campaign/Buzzer/Terms';



class LayoutHeimtrikot extends Component {

    constructor() {
        super();

        this.state = {
            ParticipationStatus: _STATUS.noParticipation,
            FancenterStatus: _STATUS.Fancenter.noCodeEntry,
            ParticipationData: JSON.stringify({}),
            gameIsLive: false,
            campaignStatusLocal : 'holding',
            status: 'main'
        }

        this._LOCAL = {
            status: "__STAT",
            statusFan: "__STAT_FAN",
            data: "__DAT"
        }

    }

    componentDidMount() {
        KIPServices.Heimtrikot()
            .then(response => {
                var { success } = response.data;
                if (success) {
                    this.setState({ gameIsLive: response.data.gameIsLive, campaignStatusLocal : response.data.status });
                    //this.setState({ gameIsLive: false, campaignStatusLocal : 'main' });
                }
            })
            .catch((err) => {
                console.log('error',err)
            })
            .finally(() => {
                _SCROLL_PAGE.ScrollToTop();
                mainLoaderToggle('hide');
            })
        // }
    }

    render() {
        let RouteRender = null,
            NavContent = null,
            { campaignStatus } = this.props;

        this.props.setIsBannerShown(true);

        this.pagesRouting = getPageRouting({ context: this, props: { isQ4: true, CampaignStatus: this.props.CampaignStatus } });

        if (this.props.campaign === true) {

            if (this.state.campaignStatusLocal === 'main') {

                RouteRender = (
                    <Switch>
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.main} exact component={() => <Main />} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.faq} exact component={() => <HeimtrikotFaq />} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.participation} exact component={() => (<HeimtrikotParticipation gameIsLive={this.state.gameIsLive} />)} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.confirmation} exact component={() => <Confirmation />} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.terms} exact component={() => <BuzzerTerms />} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.resetPassword} exact component={() => <PasswordChangeEmail />} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.cookie} exact component={Cookie} />
                    </Switch>
                );
            } else if (this.state.campaignStatusLocal === 'holding') {
                RouteRender = (
                    <Switch>
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.holding} exact component={Holding} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.faq} exact component={() => <HeimtrikotFaq />} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.cookie} exact component={Cookie} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.terms} exact component={() => <BuzzerTerms />} />
                        <Route component={Error} />
                    </Switch>
                );
            } else if (this.state.campaignStatusLocal === 'end') {
                RouteRender = (
                    <Switch>
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.end} exact component={End} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.faq} exact component={() => <HeimtrikotFaq />} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.terms} exact component={() => <BuzzerTerms />} />
                        <Route path={GLOBAL_CONFIG.Route.heimtrikot.cookie} exact component={Cookie} />
                        <Route component={Error} />
                    </Switch>
                );

                NavContent = null;
            }
        }


        const ChangeTracker = () => {
            const { pathname } = useLocation();
            const pathnameLower = pathname.toLowerCase().replace(/[\\/\\\\]+$/, '');

            const whiteClassMap = new Map([
                ['/confirm', 'main-content main-content--white'],
                ['/cookies', 'main-content main-content--white'],
                ['/teilnahmebedingungen', 'main-content main-content--white'],
                ['/fancenter/teilnahmebedingungen', 'main-content main-content--white'],
                ['/Lieferadressen', 'main-content main-content--white'],
                ['/game', 'main-content main-content--white'],
                ['/passwortzuruecksetzen', 'main-content main-content--white'],
                ['/endrewe', 'main-content main-content--white'],
                ['/endkaufland', 'main-content main-content--white'],
                ['/endedeka', 'main-content main-content--white'],
                ['/dein-gewinn', 'main-content main-content--white'],
                ['/fancenter/confirm', 'main-content main-content--white'],
                ['/endnetto', 'main-content main-content--white'],
                ['/endpenny', 'main-content main-content--white'],
                ['/fairplay', 'main-content main-content--white fairplay'],
                ['/account', 'main-content main-content--white account-layout'],
                ['/register', 'main-content main-content--white register-layout'],
                ['/loginDetail', 'main-content main-content--white login-detail_layout'],
                ['/resetPassword', 'main-content main-content--white reset-password_layout'],
                ['/passwordChangeEmail', 'main-content main-content--white password-change-email_layout'],
                ['/AccountInformationen', 'main-content main-content--white account-info_layout'],
                ['/confirmationAccount', 'main-content account-confirmation_layout'],
                ['/no-product-code', 'main-content no-product-code_layout'],
                [GLOBAL_CONFIG.Route.sommer.cookie.toLowerCase(), 'main-content main-content--white'],
                [GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.main.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.codeEntry.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.teamSelection.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.jerseySizeSelection.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.jerseyUserDetails.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.ticketSelection.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.ticketOptions.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.confirmation.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.jersey.addressForm.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.fanCenter.confirmation.user.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.fanCenter.confirmation.companion.toLowerCase(), 'main-content'],
                [GLOBAL_CONFIG.Route.fanCenter.giftChoice.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.codeEntry.user.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.codeEntry.companion.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.userDetails.user.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.userDetails.companion.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend.toLowerCase(), 'main-content main-content--white fancenter-layout'],
                [GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase(), 'main-content main-content--white fancenter-layout main-fancenter'],
                [GLOBAL_CONFIG.Route.participation.toLowerCase(), 'main-content--secondary-lilac'],
                ['', 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.home.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.game.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.prize.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.faq.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.end.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.holding.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingkaufland.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingEdeka.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingPenny.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingnetto.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.holdingGlobus.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.main.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.participation.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.confirmation.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.end.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.globusMicroSite.faq.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.product.toLowerCase(), 'main-content main-content--dark-lilac'],
                [GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase(), 'main-content main-content--dark-lilac'],
            ]);

            const whiteClass = whiteClassMap.get(pathnameLower) || 'main-content';

            return (
                <main className={whiteClass + " sommer"} id="main" >
                    {
                        window.location.pathname.toLowerCase().includes("mitmachen") ? null :
                        <Banner campaignStatus={this.state.campaignStatusLocal} promotionCampaign={this.props.promotionCampaign} isq2Promotion={true} />
                    }
                    {NavContent}
                    {RouteRender}
                </main>
            );
        }

        const ChangeMeta = () => {
            return (
                <MetaHeader
                    title="Gewinne mit dem FC Milka Heimtrikots"
                    description="Lass dein Fan-Herz höher schlagen. Nutze jetzt deine Chance auf original Heimtrikots der Bundesliga und 2. Bundesliga der aktuellen Saison"
                    og_title="Gewinne original Heimtrikots der aktuellen Bundesliga Saison"
                    og_description="Ein Aktionsprodukt von Milka und/oder OREO kaufen und mit etwas Glück original Heimtrikots der Bundesliga / 2. Bundesliga gewinnen"
                    og_sitename="www.heimtrikot.fcmilka.de"
                />
            );
        }

        return (
            <Aux>
                <ChangeMeta campaignStatus={this.props.campaignStatus} />
                <Header campaignStatus={this.props.campaignStatus} />
                <ChangeTracker campaignStatus={this.props.campaignStatus} />
                <Footer campaignStatus={this.props.campaignStatus} />
            </Aux>
        );
    }

}

const mapDispatchToProps = {
    setIsBannerShown
}

export default withRouter(connect(null, mapDispatchToProps)(LayoutHeimtrikot));