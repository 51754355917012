import React, { Component } from 'react'; 
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import GLOBAL_CONFIG from '../../../config/config';
import appGoogleTracking from '../../../modules/googletracking';
import { mainLoaderToggle } from '../../../modules/helpers';
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial';

class TicketsPromoEnd extends Component {
    
    componentDidMount(){
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');

        //Virtual page
        appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': GLOBAL_CONFIG.Route.end,
                'pageTitle': t('Meta.title')
            }
        });
    }

    componentWillUnmount(){
        //Show mainloader
        mainLoaderToggle('show');
    }

    render() { 

        const { t } = this.props;

        return (
            <div className="tickets-promo-emd">
                <div className="content-end__text">
                    <h1>
                        die aktion ist beendet.
                    </h1>
                    <p>
                        Freu dich auf viele weitere FC Milka Aktionen, die kommen werden!
                    </p>
                </div>
                <div className="s-content-end">
          <div className="end-element">
            <div className="end-element__item">
              <img src="/resources/imagesBundesliga/tickets-promo/teaser/1.png" />
              <div className="end-element__copy">
                <h3>Aufregend dunkel …</h3>
                <p>
                Entdecke die Vielfalt von zarter dunkler Alpenmilchschokolade. Diese sechs aufregenden Milka Dark Milk Sorten warten darauf, von dir probiert zu werden:
Milka Darkmilk Dunkle Alpenmilch, Kakao Splitter, Gesalzenes Karamell, Himbeere und Haselnuss. Und jetzt ganz neu auch in der Sorte Nougat-Crème.
                </p>
                <a
                  href="https://www.milka.de/shop/personalisierte-pralinen"
                  target="_blank"
                  exact data-event="discover_more" 
                  data-category="End Page" 
                  data-action="Go to Page" 
                  data-label="ENG_EP_OTHER" 
                  onClick={appGoogleTracking.processEventCTANavLink}
                  >
                  <button className="btn__container btn--secondary-lilac">
                    milka darkmilk
                  </button>
                </a>
                
              </div>
            </div>

            <div className="end-element__item">
              <img src="/resources/imagesBundesliga/tickets-promo/teaser/2.png" />
              <div className="end-element__copy">
                <h3>Milka zum Snacken</h3>
                <p>
                Milka Favourites ist dein perfekter Begleiter für gesellige Runden. Hier ist von fruchtigem Erdbeer- und Cheesecake, Karamell und Haselnuss, knusprigen Waffeln und leckeren Milka Oreos sowie klassischen Milkinis für jeden Geschmack etwas dabei.
                </p>
                <a 
                    href="https://www.milka.de/neues" 
                    target="_blank"
                    exact data-event="go_to_products" 
                    data-category="End Page" 
                    data-action="Go to Page" 
                    data-label="ENG_EP_OTHER" 
                    onClick={appGoogleTracking.processEventCTANavLink}>
                  <button className="btn__container btn--secondary-lilac">
                  MILKA FAVOURITES
                  </button>
                </a>
              </div>
            </div>

            <div className="end-element__item">
              <img src="/resources/imagesBundesliga/tickets-promo/teaser/3.png" />
              <div className="end-element__copy">
                <h3>Nachhaltigkeit bei Milka</h3>
                <p>
                  Wusstest du, dass Milka mit Cacao Life ein globales Kakao-Nachhaltigkeitsprogramm hat? Und dass Milka sich gegen Kinderarbeit stark macht? Denn Milka will die Lebens- und Arbeitsbedingungen von Kakaobauern und ihren Familien langfristig verbessern.
                </p>
                <a 
                    href="https://www.milka.de/uber-milka" 
                    target="_blank"
                    exact data-event="go_to_explore_new" 
                    data-category="End Page" 
                    data-action="Go to Page" 
                    data-label="ENG_EP_OTHER" 
                    onClick={appGoogleTracking.processEventCTANavLink}>
                  <button className="btn__container btn--secondary-lilac">
                    NEUES ENTDECKEN
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
                <Footersocial />
            </div>
        );
    }
  
}

export default withTranslation()(TicketsPromoEnd);