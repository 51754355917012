import React from 'react';
import appGoogleTracking from '../../../modules/googletracking';
import { isDoppeltPromotion, isReihePromotion, isTeaser, isTicketsPromotion, isq2Promotion, isHeimtrikotPromotion } from '../../../modules/helpers';

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/
function Tag(label) {
    if (isDoppeltPromotion() || isTicketsPromotion() || isq2Promotion() || isTeaser()|| isReihePromotion() || isHeimtrikotPromotion() ) {
        appGoogleTracking.dataLayerPush({
            'dataEvent': 	'footer_contact_menu',
            'dataCategory': 'Footer',
            'dataAction': 	'Select Menu',
            'dataLabel': 	label
        });
    } else {
        appGoogleTracking.dataLayerPush({
            'dataEvent': 	'info-click',
            'dataCategory': 'Informational Action',
            'dataAction': 	'Footer Link',
            'dataLabel': 	label
        });
    }

}
const footerlinks = () => (
    <div className="footer__legal">
        <span className="legal__link">
            <a className="js-event-cta" href="https://fcmilka.de/fancenter/teilnahmebedingungen" target="_blank" rel="noopener noreferrer" title="Teilnahmebedingungen" onClick={()=>Tag('Teilnahmebedingungen')}>Teilnahmebedingungen
                <span className="legal__link--separator">|</span>
            </a>
        </span>
        <span className="legal__link">
            <a className="js-event-cta" href="https://www.milka.de/Static/bedingungen-zur-nutzung" target="_blank" rel="noopener noreferrer" title="BEDINGUNGEN ZUR NUTZUNG" /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="BEDINGUNGEN ZUR NUTZUNG" */ onClick={()=>Tag('Bedingungen zur Nutzung')}>Bedingungen zur Nutzung
                <span className="legal__link--separator">|</span>
            </a>
        </span>
       
        <span className="legal__link">
            <a className="js-event-cta" href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" rel="noopener noreferrer" title="DATENSCHUTZERKLÄRUNG" /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="DATENSCHUTZERKLÄRUNG" */ onClick={()=>Tag('Datenschutzerklärung')}>Datenschutzerklärung
                <span className="legal__link--separator">|</span>
            </a>
        </span>
        
        <span className="legal__link">
            <a className="js-event-cta" href="https://www.milka.de/Static/impressum" title="IMPRESSUM" target="_blank" rel="noopener noreferrer" /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="IMPRESSUM" */ onClick={()=>Tag('Impressum')}>Impressum
                <span className="legal__link--separator">|</span>
            </a>
        </span>
        
        <span className="legal__link">
            <a className="js-event-cta" href="https://www.milka.de/Static/nutzungsbasierte-online-werbung" target="_blank" rel="noopener noreferrer" title=" NUTZUNGSBASIERTE ONLINE-WERBUNG" /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="NUTZUNGSBASIERTE ONLINE-WERBUNG" */ onClick={()=>Tag('Nutzungsbasierte Online-Werbung')}>Nutzungsbasierte Online-Werbung
                <span className="legal__link--separator">|</span>
            </a>
        </span>
        
        <span className="legal__link">
            <a className="js-event-cta" href="https://www.milka.de/Static/mondelez-international" target="_blank" rel="noopener noreferrer" title="MONDELEZ INTERNATIONAL" /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="MONDELEZ INTERNATIONAL" */ onClick={()=>Tag('Mondelez International')}>Mondelez International
                <span className="legal__link--separator">|</span>
            </a>
        </span>
        
        <span className="legal__link">
            <a className="js-event-cta" href="/cookie" title=" COOKIE RICHTLINIE" /* data-event="info-click" data-category="Informational Action" data-action="Footer Link" data-label="COOKIE RICHTLINIE"  */onClick={()=>Tag('Cookie Richtlinie')}>Cookie Richtlinie
                <span className="legal__link--separator">|</span>
            </a>
        </span>
        
        <span className="legal__link">
            <a className="js-event-cta" href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank" rel="noopener noreferrer" title="Kontakt" data-event="info-click" /* data-category="Informational Action" data-action="Footer Link" data-label="KONTAKT" */ onClick={()=>Tag('Kontakt')}>Kontakt
            </a>
        </span>
    </div>      
);

export default footerlinks;