import React from 'react'
import appGoogleTracking from "../../../modules/googletracking";

function VarietyTeaser({
  dataEvent = '',
  dataCategory = '',
  dataAction = '',
  dataLabel = ''
}) {
  return (
    <div class="milka-variety-wrapper">                    
        <div class="variety-container">
            <h2>
                Entdecke alle milka sorten
            </h2>
            <a href="https://www.milka.de/alle-produkte/tafel-schokolade" target='_blank' rel="noopener noreferrer" onClick={appGoogleTracking.processEventCTA}
              data-event={dataEvent}
              data-category={dataCategory}
              data-action={dataAction}
              data-label={dataLabel}>                                       
              <picture>
                <source srcSet="/resources/imagesBundesliga/trikot-promo/Milka_Sorten.png" media="(min-width: 481px)" />
                <source srcSet="/resources/imagesBundesliga/trikot-promo/abbinder-sorten-mobile.png" media="(min-width: 200px)" />
                <img src="/resources/imagesBundesliga/trikot-promo/Milka_Sorten.png" alt="FC Milka Logo" />
              </picture>
            </a>
        </div>
    </div>
  )
}

export default VarietyTeaser;