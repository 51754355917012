import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import flatpickr from "flatpickr";
import { withRouter } from 'react-router-dom';

import TitleLogo from "../../../../components/Content/TitleLogo/TitleLogo";
import Loader from "../../../../components/Overlays/Loader";
import GLOBAL_CONFIG from "../../../../config/config";
import Salutations from "../../../../Models/Salutation";
import { mainLoaderToggle, ServerDate } from "../../../../modules/helpers";
import ValidateForm from "../../../../modules/validate";
import JerseyNavigation from "../JerseyNavigation";
import JerseyStepIndicitor from "../StepIndicator";
import Footersocial from '../../../../components/Layout/FooterContent/FooterSocial';
import Checkbox from "../../../../components/Controls/Checkbox";
import _GIFT from "../../../../Models/GiftTypes";
import _LOCAL from "../../../../Models/LocalStorageKeys";
import FanCenterServices from "../../../../Service/FanCenter";
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import _STATUS from "../../../../Models/ParticipationSteps";
import _ERROR_MESSAGES from "../../../../Models/ErrorMessages";
import _GIFT_MESSAGES from "../../../../Models/GiftErrorMessages";

class UserDetails extends React.Component {
    constructor() {
        super();

        var limit = new Date();
        limit.setFullYear(limit.getFullYear() - 18);
        this.maxDOB = limit;

        this.state = {
            ConvidInfo: false,
            SimpleLoader: false,
            ErrorMessage: "",
            GiftType: _GIFT.Ticket,
            IsDirectTicketSelection : false,
            TicketChoice : null
        }

        this.StepList = [
            {
                display: "Code-Eingabe",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "Lieblingsclub",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "Gewinnauswahl",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "Daten-Check",
                isInProgress: true,
                isComplete: false,
            }
        ];

        var limit = new Date();
        limit.setFullYear(limit.getFullYear() - 18);
        this.maxDOB = limit;
        this.ProcessError = this.ProcessError.bind(this);
        this.ProcessForm = this.ProcessForm.bind(this);   
    }

    componentDidMount() {
        if(localStorage.getItem(_LOCAL.UserDetails) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user)
        }
        this.FlatpikerDateInit(this._DETAILS_dob, {max: this.maxDOB});
        _SCROLL_PAGE.ScrollToBanner();
        let UserDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));
        
        if(UserDetails){
            this.setState({
                IsDirectTicketSelection : UserDetails.IsDirectTicketSelection,
                TicketChoice : UserDetails.TicketChoice,
            });
        }

        UserDetails = {};
        UserDetails.Type = _GIFT.Ticket;

        if(!!UserDetails && UserDetails.Type.toLowerCase() === _GIFT.Ticket) {
            this.StepList = [
                {
                    display: "Code-Eingabe",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "Lieblingsclub",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "Gewinnauswahl",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "Daten-Check",
                    isInProgress: true,
                    isComplete: false,
                }
            ];
        } else {
            this.StepList = [
                {
                    display: "Code-Eingabe",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "Lieblingsclub",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "Gewinnauswahl",
                    isInProgress: false,
                    isComplete: true,
                },
                {
                    display: "Daten-Check",
                    isInProgress: true,
                    isComplete: false,
                }
            ];
        }

        mainLoaderToggle('hide');
    }


    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div className="buzzer-jersey">
                {this.HeadingSection()}
                
                <form id="frm_user_details_ticket"
                    ref = { form => this._DETAILS_TICKET = form }
                    name="frm_user_details_ticket" 
                    method="post" 
                    action="/"
                    onSubmit={this.SubmitHandler.bind(this)} >

                { this.UserDetailsSection() }

                <ReCAPTCHA
                    ref={ref => this._CAPTCHA = ref}
                    hl={GLOBAL_CONFIG.Captcha.language}
                    size="invisible"
                    sitekey={GLOBAL_CONFIG.Captcha.publish}
                    onChange={this.OnCaptchaResolved.bind(this)}
                    onExpired={this.OnCaptchaExpired.bind(this)}
                    onErrored={this.OnCaptchaError.bind(this)}
                />

                </form>

                <div id="Covid-Details">
                    {
                        this.state.CovidInfo ? 
                        <div className="back--red">
                            <p>
                                Auf Grund der aktuellen COVID-19 Situation erhalten wir die Tickets sehr kurzfristig vor dem Spieltag von den Clubs.
                                <br />
                                Das Milka Team hat keinen Einfluss auf die Ticketausstellung, das wird seitens der einzelnen Clubs geregelt. Es ist uns darum nicht möglich, nähere Angaben zum genauen Versandzeitpunkt zu machen.
                                Bitte berücksichtige auch, dass einige Clubs insbesondere bei steigenden Inzidenzzahlen überlegen, den Zugang zum Stadion nur vollständig Geimpften oder genesenen Zuschauern zu gestatten. Diese Entscheidung und Verantwortung obliegt ausschließlich den jeweiligen Clubs.
                                Wir bitten um deine Geduld und dein Verständnis. Bitte informiere dich vor dem Stadionbesuch bei den entsprechenden Stellen.
                            </p>
                        </div> : null
                    }
                </div>


                {
                    this.state.SimpleLoader ? <Loader /> : null
                }

                <Footersocial />
            </div>
        );
    }

    HeadingSection() {
        return (
            <div>
                {/* <JerseyNavigation /> */}
                {/* <TitleLogo /> */}
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        {
                            this.state.GiftType === _GIFT.Jersey ?
                            <h2 className="lilac">An diese Adresse schicken wir dein<br /> original Bundesliga Heimtrikot</h2>
                            : null
                        }
                        {
                            this.state.GiftType === _GIFT.SignedJersey ?
                            <h2 className="lilac">An diese Adresse schicken wir dein<br /> handsigniertes original Bundesliga Heimtrikot</h2>
                            : null
                        }
                        {/* <button className="btn__container btn--secondary-dark" onClick={this.ScrollCovidInfo.bind(this)}>COVID-19 Infos</button> */}
                        <JerseyStepIndicitor Steps={this.StepList} /> 
                        {
                            this.state.GiftType === _GIFT.Jersey ?
                            <>
                                <p className="lilac">
                                    Wenn dein Gewinn an eine andere Adresse geschickt werden soll, kannst du die Adressangaben hier direkt ändern. 
                                </p>
                                <p className="lilac">
                                    Nicht vergessen: Lies dir bitte vorher unsere <a href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank" rel="noopener noreferrer">Datenschutzhinweise</a> aufmerksam durch, damit du weißt, wie wir mit deinen personenbezogenen Daten umgehen und welche Rechte dir zustehen.
                                </p>
                            </>: null
                        }

                        {
                            this.state.GiftType === _GIFT.SignedJersey ?
                            <>
                                <p className="lilac">
                                    Wenn dein Gewinn an eine andere Adresse geschickt werden soll, kannst du die Adressangaben hier direkt bearbeiten.
                                </p>
                                <p className="lilac">
                                    Nicht vergessen: Lies dir bitte vorher unsere <a href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank" rel="noopener noreferrer">Datenschutzhinweise</a> aufmerksam durch, damit du weißt, wie wir mit deinen personenbezogenen Daten umgehen und welche Rechte dir zustehen.
                                </p>
                            </>: null
                        }

                        {
                            this.state.GiftType === _GIFT.Ticket ?
                            <>  
                                <p className="lilac recoleta-medium ticket">
                                    An diese Adresse schicken wir dein original Bundesliga Heimtrikot
                                </p>
                                <p className="lilac">
                                    Wenn dein Gewinn an eine andere Adresse geschickt werden soll, kannst du die Adressangaben hier direkt editieren.
                                </p>
                                <p className="lilac">
                                    Nicht vergessen: Lies dir bitte vorher unsere <a href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank" rel="noopener noreferrer">Datenschutzhinweise</a> aufmerksam durch, damit du weißt, wie wir mit deinen personenbezogenen Daten umgehen und welche Rechte dir zustehen.
                                </p>
                            </>: null
                        }
                    </div>
                </div>
            </div>
        );
    }

    UserDetailsSection() {
        return(
            <div className="buzzer-form">
                <div className="form-container"  id="companionUserDetails">
                    <p>*Pflichtfelder</p>
                    <div id="salutation" className="input-container">
                        <div className="form-input__container form-input__dropdown">
                            <select ref={ select => this._DETAILS_salutation = select} className="form-input__input js-event-type-in js-event-ab" 
                                type="text" name="salutation" 
                                id="salutation" 
                                placeholder="Arende*" 
                                data-require="true" 
                                data-type="ddl" 
                                data-error-target="#error-salutation" 
                                data-required-message="" 
                                data-event-fieldname="Salutation" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Salutation" 
                                data-label="Type In">
                                {
                                    Object.keys(Salutations.dropdown).map( key => {
                                        var i = 0;
                                        return <option value={ key } key={ `${i++}-${key}`} >{ Salutations.dropdown[key] }</option>
                                    })
                                }
                            </select>
                            <label className="form-input__label" htmlFor="salutation">{ Salutations.dropdown["default"] }</label>
                        </div>
                    </div>

                    <div id="firstName" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="firstname">Vorname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="firstname" 
                                id="firstname" 
                                placeholder="Vorname*" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-firstname" 
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$" 
                                data-required-message="" 
                                data-pattern-message="" 
                                data-event-fieldname="Firstname" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Firstname" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_firstName = input }
                                />
                        </div>
                    </div>

                    <div id="lastName" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="lastname">Nachname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="lastname" 
                                id="lastname" 
                                placeholder="Nachname*" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-lastname" 
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$" 
                                data-required-message="" 
                                data-pattern-message="" 
                                data-event-fieldname="Lastname" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Lastname" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_lastName = input } 
                                />
                        </div>
                    </div>

                    <div id="street" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="street_name">Straße*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="street_name" 
                                id="street_name" 
                                placeholder="Straße*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-street-number" 
                                data-required-message="" 
                                data-event-fieldname="Street number" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Street number" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_street_name = input } 
                                />
                        </div>
                    </div>

                    <div id="houseNumber" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="street_number">Hausnummer*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="street_number" 
                                id="street_number" 
                                placeholder="Hausnummer*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-house-number" 
                                data-required-message="" 
                                data-event-fieldname="House number" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="House number" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_street_number = input } 
                                />
                        </div>
                    </div>

                    <div id="address-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="adress">Adresszusatz</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="address" 
                                id="address" 
                                placeholder="Zusätzliche Adressinformation (optional)" 
                                data-require="false" 
                                data-event-fieldname="Address" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Address" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_address = input }
                                />
                        </div>
                    </div>

                    <div id="dob-container" className="input-container">
                        <div className="form-input__container active js-datepicker-dob">
                            <label className="form-input__label" htmlFor="dob">Geburtsdatum*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="dob" id="dob" 
                                placeholder="TT.MM.JJJJ" 
                                autoComplete="off" 
                                readOnly 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-dob" 
                                data-regex-pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" 
                                data-required-message="Birthday is required." 
                                data-pattern-message="Birthday is not valid." 
                                data-event-fieldname="Birthday" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Birthday" 
                                data-label="Type In" 
                                ref={ input => this._DETAILS_dob = input } 
                                />
                        </div>
                    </div>

                    <div id="zipCode" className="input-container">
                        <div className="form-input__container space-right">
                            <label className="form-input__label" htmlFor="zip_code">PLZ*</label>
                            <input className="form-input__input js-event-type-in js-event-ab js-zip-code" 
                                type="text" 
                                name="zip_code" 
                                id="zip_code" 
                                placeholder="PLZ*" 
                                maxLength="5" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-zip-code" 
                                data-regex-pattern="^[0-9]{5}$" 
                                data-required-message="" 
                                data-pattern-message="" 
                                data-event-fieldname="Zip code" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="Zip code" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_zipCode = input }
                                />
                        </div>
                    </div>

                    <div id="city-container" className="input-container">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="city">Stadt*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="city" 
                                id="city" 
                                placeholder="Stadt*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-city" 
                                data-required-message="" 
                                data-event-fieldname="City" 
                                data-event="form-click" 
                                data-category="Form Action" 
                                data-action="City" 
                                data-label="Type In"
                                ref={ input => this._DETAILS_city = input }
                                />
                        </div>
                    </div>
                </div>

                {
                    !!this.state && this.state.ErrorMessage.length > 0 ?
                    <p className="buzzer-error">{this.state.ErrorMessage}</p>
                    : null
                }

                <button className="btn__container btn--red" type="submit">ABSENDEN</button>
            </div>
        );
    }

    OnCaptchaResolved (token) {
        this.setState({
            SimpleLoader: true
        })
        this.ProcessForm(token)
    }

    ProcessForm(CaptchaToken) {
        let salutation = this._DETAILS_salutation.value,
            giftCategory = localStorage.getItem(_LOCAL.GiftCategory),
            userDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));

        switch (salutation) {
            case "Herr":
                salutation = "1";
                break;
            case "Frau":
                salutation = "2";
                break;
            case "Divers":
                salutation = "10";
                break;
        
            default:
                break;
        }

        if(!!userDetails) {
            const _data = {
                captcha: CaptchaToken,
                Email: userDetails.Email || "default-mail",
                City: this._DETAILS_city.value,
                Firstname:  this._DETAILS_firstName.value,
                Salutation: salutation,
                Lastname: this._DETAILS_lastName.value,
                Street1: this._DETAILS_street_name.value,
                Street2: this._DETAILS_address.value,
                Streetnumber: this._DETAILS_street_number.value,
                Zipcode: this._DETAILS_zipCode.value,
                CodeEntered: userDetails.Code || "default-code",
                MatchUp: `${userDetails.Match.home} − ${userDetails.Match.away}`,
                TicketType: giftCategory,
                ConsumerId: userDetails.Id || "default-id",
                MatchId: userDetails.Match.id,
                GiftType: userDetails.Type || "default-type",
                GiftCategory: giftCategory,
                GameDate: userDetails.Match.date,
                Birthday: ServerDate(this._DETAILS_dob.value),
                Gameweek : userDetails.GameWeek
            }

            const { IsDirectTicketSelection, TicketChoice } = this.state;
            if(IsDirectTicketSelection){
                _data.NumberOfTickets = TicketChoice;
            }

            const userDetailsAPI = IsDirectTicketSelection ? FanCenterServices.UserDetails.UserTicket : FanCenterServices.UserDetails.User ;

            userDetailsAPI(_data)
            .then( response => {
                var {success, message, data} = response.data;
    
                if(success) {
                    this.props.UpdateFancenterStatus(_STATUS.Fancenter.detailsInserted)
                    switch (userDetails.Type.toLowerCase()) {
                        case _GIFT.Ticket:
                            /**
                             * Check if user or companion
                             */
                            var _data_new = {
                                ...JSON.parse(localStorage.getItem(_LOCAL.UserDetails)),
                                Name: `${_data.Firstname} ${_data.Lastname}`
                            }
                            localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(_data_new))
    
                            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.confirmation.user);
                            break;
                            
                        default:
                            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey);
                            break;
                    }
                } else {
                    this.ProcessError(message);
                }
            })
            .catch( () => {
                if(!!this._CAPTCHA) {
                    this._CAPTCHA.reset()
                }
            })
            .finally( () => {
                if(!!this._CAPTCHA) {
                    this._CAPTCHA.reset();
                }
    
                if(!!this._DETAILS_TICKET) {
                    this._DETAILS_TICKET.reset();
                }
    
                this.setState({
                    SimpleLoader: false
                });
            })
        } else {
            this.setState({
                SimpleLoader: false
            });
        }
    }

    ProcessError(ErrorMessage) {
        if(!!ErrorMessage) {
            switch (ErrorMessage) {
                case _GIFT_MESSAGES.OUT_OF_STOCK:
                    this.setState({
                        ErrorMessage: _ERROR_MESSAGES.Ticket.Unvailable
                    })
                    break;
            
                default:
                    this.setState({
                        ErrorMessage
                    })
                    break;
            }

        } else {
            this.setState({
                ErrorMessage: "SERVER ERROR"
            })
        }
    }

    OnCaptchaExpired () {
        this._CAPTCHA.reset();
        this.setState({
            SimpleLoader: false,
            ErrorMessage: "CAPTCHA ERROR"
        })
    }

    OnCaptchaError () {
        this._CAPTCHA.reset();
        this.setState({
            SimpleLoader: false,
            ErrorMessage: "CAPTCHA ERROR"
        })
    }

    FlatpikerDateInit( _REF, {max}, _def = null ) {
        flatpickr(_REF, {
            dateFormat: "d.m.Y",
            maxDate: max,
            disableMobile: true,
            defaultDate: _def
        });
    }

    CheckboxChangeHandler(event, _ref) {
        if(_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if(_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    SubmitHandler(event) {
        event.preventDefault();        
        var isValid = ValidateForm.validateForm('frm_user_details_ticket');
        
        if(isValid ) {
            this._CAPTCHA.execute();
        } else {
            /**
             * Display Error
             */
        }
    }

    ScrollCovidInfo(e) {
        this.setState({
            CovidInfo: true
        })

        _SCROLL_PAGE.ScrollSection(document.getElementById("Covid-Details"))
    } 
}

export default withRouter(UserDetails);