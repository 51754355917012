import React, { useEffect } from 'react';
import parse from 'html-react-parser';                                              //Parse HTML data from string
import './TitleContent.scss';

export default function TitleContent(props) {
    useEffect(() => {
        
    }, [])
  return (
    <div className='container_title'>
      <p className="title">{props.heading}</p>
      <p className="description">{parse(props.subheading)}</p>
    </div>
  )
}
