import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config'; 
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import { servicePath, mainLoaderToggle, getCookie} from '../../modules/helpers'; 
import ValidateForm from '../../modules/validate';  
import InputPassword from "../InputPassword/InputPassword";
import axios from 'axios';
import { connect } from "react-redux";
import { setIsBannerShown  } from "../../store/banner";
import appGoogleTracking from '../../modules/googletracking';


class Account extends Component {
    constructor(props) {
        super(props);
        
        this.state = {                   
            birthdayAge: 18,                          //User need to be at least 18 - use in flapickr
            loaderSubmit: false,
            loaderError: false,
            loaderGeneralError: false,
            promotionCampaign: window.PROMOTION_CAMPAIGN,
            emailError: null,
            loginError:null,
            accountActivated:null
            
        };
    }
    componentWillMount(){
        // console.log(sessionStorage.getItem(GLOBAL_CONFIG.Session.tokenstatus));
        console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
        
        if(getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null){
            let getLoginDetailURL = servicePath({
                ...GLOBAL_CONFIG.ServiceSettings,
                campaign: this.state.promotionCampaign,
                status: 'details'
            });
            let tokenInfo = getCookie(GLOBAL_CONFIG.Session.tokenstatus)
            
            console.log(tokenInfo);
            axios({
                method: 'POST',
                url: getLoginDetailURL,
                data: {
                    "token":tokenInfo
                },
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                console.log(response)
                let _data = response.data;
                if(_data.success) {
                    this.setState({
                        tokenLogin:response.data.token,
                        loaderSubmit: false,
                        loaderGeneralError: false,
                    });
                    this.props.history.push({pathname: GLOBAL_CONFIG.Route.loginDetail});
                }
            })
            .catch((response) => {
            //Go to particaption page
            this.props.history.push({pathname: GLOBAL_CONFIG.Route.account});
        });
    } 
    }
    componentDidMount(){
        const { setIsBannerShown } = this.props;
        setIsBannerShown(false);

        mainLoaderToggle('hide');
          //Assign Validation function
        ValidateForm.validateField('frm_login'); 
       
    }
    componentWillUnmount(){
        mainLoaderToggle('show');

        
    }
      //Close error message popup
      onClosePopupHandler = () => {
        this.setState({
            loaderError: false,
            loaderGeneralError: false
        });
    }
   //Two way binding for input fields
   onChangeHandler = (event) => {
    let e = event.target,
        val = e.value;

    setTimeout(() => {
       
            //sumSelector = document.getElementById('js-sum-display');

        //Add sum of text
        /*if(e.classList.contains('valid')){
            sumSelector.innerHTML = Math.ceil(val) + '€';
        } else {
            sumSelector.innerHTML = sumSelector.getAttribute('data-text');
        }*/
    },300);
}

    //Form for login
    loginHandler = (event) =>{
        event.preventDefault();
        let validateFormInfo = ValidateForm.validateForm('frm_login');
        if(validateFormInfo === true){
            this.setState({
                loaderSubmit: true
            });

            let data = {
                UserName: document.getElementById('email').value,
                Password: document.getElementById('password').value
            },
            getLoginURL = servicePath({
                ...GLOBAL_CONFIG.ServiceSettings,
                campaign: this.state.promotionCampaign,
                status: 'login'
            });

            this._loginRequest(getLoginURL, data)
        }
    }

    _loginRequest = (url, reqData) => {
        axios({
            method: 'post',
            url: url,
            data: reqData,
            headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {
            console.log(response);
            let _data = response.data;
            if(_data.success === true) {
                this.setState({
                    loaderGeneralError: false,
                    tokenLogin: _data.token
                });
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'user_login',
                    'dataCategory': 'Start Page',
                    'dataAction': 'Click on Login',
                    'dataLabel': 'LOGIN_SP_PART'
                })
                //Set user participation
                // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, 'participate'); 
                // let userStatus = response.data.data.IsWinner ? 'win' : 'lose';
                // //Set user as Winner or Loser
                // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, userStatus);    //win or lose
                // let loginStatus = response.data.data.IsLogin ? 'login' : 'notlogin';
                // sessionStorage.setItem(GLOBAL_CONFIG.Session.loginstatus, tokenLogin);

                sessionStorage.setItem(GLOBAL_CONFIG.Session.tokenstatus,_data.token);
                document.cookie = GLOBAL_CONFIG.Session.tokenstatus + '=' + _data.token /* + ';domain=' + GLOBAL_CONFIG.DomainName */;

                // //SET Participation ID
                // window.PARTICIPATION_ID = response.data.ParticipationId;

                // Datalayer push for Success participation
                // this.triggerDatalayers({
                //     status: 'success',
                //     participationID: response.data.ParticipationId
                // });
                // //Go to Thank you page
                this.props.history.push({pathname: GLOBAL_CONFIG.Route.loginDetail});

            } else {
                let errorStatus = _data.message;

                switch (errorStatus) {
                    case 'Username or Password invalid':
                        console.log('username error');
                        this.setState({
                            loaderSubmit: false,
                            loginError:true
                        });
                        break;
                    case 'Account was not activated':
                        console.log('account activation error');
                        this.setState({
                            loaderSubmit: false,
                            accountActivated:true
                        });
                    break;
                    case 'INVALID_RECAPTCHA_RESPONSE':
                        console.log('Captcha error message');

                        //Datalayer push for Error
                        this.triggerDatalayers({
                            status: 'error',
                            type: 'general'
                        });

                        this.setState({
                            loaderSubmit: false,
                            loaderGeneralError: true
                        });
                        break;
                    case 'AN_ERROR_HAS_OCCURED':
                        console.log('An error has occured');

                        this.setState({
                            loaderSubmit: false,
                            loaderGeneralError: true
                        });
                        break;
                
                    default:
                        break;
                }

                // this.recaptcha.reset();     //Reset captcha when error occurs - deploy
            }
        })
        .catch((response) => {
            // this.getinfo();
            //Close Loader
            console.log("catch");
            this.setState({
                loaderSubmit: false,
                loaderGeneralError: true,
                loginError:false,
                accountActivated:false
            });

            // this.recaptcha.reset();     //Reset captcha when error occurs
        });
    }
    
    render() {
        
        //Loader rendering for form submit and error handling
        let submitLoader = null,
        errorLoader = null,
        loaderSubmit= null,
        errorLoaderGeneral = null,
        siteKey = null,
        fillField=true,
        inputElement = null,
        loginError = null,
        active=null
        //paraElement = null;

            //paraElement = null;
            if(this.state.loaderError === true){
                errorLoader = (
                    <div className="js-overlay overlay overlay--black">
                        <div className="overlay__container overlay__content">
                            <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                            <p className="overlay__title">Du hast bereits 3x an der Aktion teilgenommen.</p>
                            <p id="js-error-msg">Über den gesamten Gewinnspielzeitraum ist eine dreimalige Teilnahme mit jeweils neuem gültigem Kassenbon erlaubt. Eine weitere Teilnahme ist leider nicht möglich.</p>
                            <div className="overlay__footer">
                                <p>Du möchtest wissen, welche Neuigkeiten es bei Milka gibt?</p>
                                <a href="https://www.milka.de/neues" target="_blank" rel="noopener noreferrer">
                                    <div className="btn__container btn--primary">
                                        <span className="btn__text">NEUES VON MILKA</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            }
    
            if(this.state.loaderGeneralError === true){ 
                errorLoaderGeneral = (
                    <div className="js-overlay overlay overlay--black">
                        <div className="overlay__container overlay__content">
                            <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                            <p id="js-error-msg">Ein Fehler ist aufgetreten. Bitte versuchen <br />Sie es später noch einmal.</p>
                        </div>
                    </div>
                );
            }
            if(this.state.loginError === true){
                loginError=(
                    <span className="errorMsg">
                    Benutzername oder Passwort ist ungültig</span>
                )
            }
            if(this.state.accountActivated === true){
                loginError=(
                    <span className="errorMsg">
                        E-Mail wurde nicht aktiviert
                    </span>
                )
            }

        return(
            <div>
                <div className="account">
                        <div className="content-upload-receipt form-line row ">
                            <div className="form-container">
                                <div className="input-container half-width">
                                    <h2 className="title">Ich bin neu hier</h2>
                                    <p className="subtitle">Erstelle einfach und schnell deinen kostenlosen Account.</p>
                                    <div className="form-button register-button">
                                        <NavLink to={GLOBAL_CONFIG.Route.register} exact data-event="info-click" data-category="Informational Action" data-action="T&amp;C" 
                                        onClick={() => this.googleTag('register_now', 'Start Page', 'Click on Join Now', 'ACC_SP_OTHER')}
                                        >
                                            <button className="btn__container btn--red">
                                                    JETZT REGISTRIEREN
                                            </button>                                            
                                        </NavLink>
                                    </div>
                                    <img className="picture_register"src="/resources/imagesBundesliga/fcAccount/logo-sm.png"></img>
                                    <img className="picture_register-mobile"src="/resources/imagesBundesliga/fcAccount/logo-sm.png"></img>      
                                </div>
                            </div>
                            <div className="vl"></div>
                            <div className="form-container">
                            <form id="frm_login" name="frm_login" method="post" action="/" onSubmit={this.loginHandler}noValidate>
                                <div className="input-container space-left">
                                    <h2 className="title">du hast bereits einen fc milka account?</h2>
                                    <p className="subtitle">Dann logge dich hier ein, um deinen Account einfach upzugraden.</p>
                                    <div className="input-container">
                                        <p className="required-field">Pflichtfelder*</p>
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="email">Email*</label>
                                            <input className="form-input__input js-event-type-in js-event-ab" type="text" name="email" id="email" placeholder="E-mail-Adresse*" data-require="true" data-type="regex" data-error-target="#error-emailname" data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$" data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail." data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail." data-event-fieldname="Email" data-event="form-click" data-category="Form Action" data-action="Email" data-label="Type In" />
                                            <span id="error-emailname" className="form-input__error active"></span>
                                        </div>
                                    </div>
                                    <div className="input-container">
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="password">Passwort*</label>
                                            {/* <input className="form-input__input js-event-type-in js-event-ab" type="password" name="password" id="password" placeholder="Passwort*" data-require="true" data-type="regex" data-error-target="#error-passwordAccount" data-regex-pattern="" data-required-message="Passwort wird benötigt" data-pattern-message="" data-event-fieldname="Password" data-event="form-click" data-category="Form Action" data-action="Password" data-label="Type In" /> */}
                                            <InputPassword
                                                className="form-input__input js-event-type-in js-event-ab"
                                                name="password"
                                                id="password"
                                                placeholder="Passwort*"
                                                data-require="true"
                                                data-type="regex"
                                                data-error-target="#error-passwordAccount"
                                                data-regex-pattern=""
                                                data-pattern-message=""
                                                data-event-fieldname="Password"
                                                data-event="form-click"
                                                data-category="Form Action"
                                                data-required-message="Passwort wird benötigt"
                                                data-action="Password"
                                                data-label="Type In"
                                            />
                                            <span id="error-passwordAccount" className="form-input__error active"></span>
                                        </div>
                                    </div>
                                    <div className="linkPassword"> 
                                        <NavLink to={GLOBAL_CONFIG.Route.passwordChangeEmail} 
                                            exact 
                                            data-event="forgot_password"
                                            data-category="Start Page"
                                            data-action="Click on Forgot Password"
                                            data-label="LOGIN_SP_PART"
                                            onClick={appGoogleTracking.processEventCTANavLink}
                                        >
                                            Passwort vergessen?
                                        </NavLink>
                                    </div>
                                    {loginError}
                                    <div className="form-button ">
                                        <button type="submit"
                                            className="btn__container btn--red small-btn"
                                          
                                        >
                                            EINLOGGEN
                                        </button>
                                    </div>      
                                </div>
                                </form>
                            </div>
                        </div>
                        {submitLoader}
                {errorLoader}  
                {errorLoaderGeneral}
               </div>
               <Footersocial/>
            </div>
        );
     }
    googleTag = (event, category, action, label) => {
        appGoogleTracking.dataLayerPush({
            'dataEvent': event,
            'dataCategory': category,
            'dataAction': action,
            'dataLabel': label
        })
    }
}

const mapStateToProps = state => ({
    bannerState: state.banner
});

const mapDispatchToProps = {
	setIsBannerShown
}


export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Account)));