import React from "react";

const WinnerLayout = (props) => {
  const { title, subtitle, imgSrc } = props;
  return (
    <div className="winner-wrapper">
      <div className="winner-wrapper__content">
        <div className="winner-title">
          <h1>Gewonnen!</h1>
        </div>
        <img
          alt="prize"
          src={imgSrc}
        />
        <div className="winner-subtitle">
          <h2>Herzlichen Glückwunsch</h2>
        </div>
        <div className="winner-text-one">
          <p>
            {title}
          </p>
        </div>
        <div className="winner-text-two">
          <p>
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WinnerLayout;
