import React from "react";
import { NavLink } from 'react-router-dom';

import { mainLoaderToggle } from "../../../modules/helpers";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Aux from "../../../hoc/Auxiliare";
import ParticipationNavigation from "../Buzzer/ParticipationNavigation";

class PennyPrize extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');
        setTimeout(()=>{
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div id="buzzer-prizes">
                { this.midSection() }
                { this.footerSection() }
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <Aux>
                <div className="navigation-newsletter">
                    <a id="js-newsletter-optins" href="https://www.milka.de/newsletter?utm_source=von-milka-fuer-dich.milka.de&utm_medium=website&utm_campaign=timetogiveback" target="_blank" rel="noopener noreferrer" data-event="button-click" data-category="Click Action" data-action="Newsletter Optin" onClick={appGoogleTracking.processEventCTANavLink}>Newsletter abonnieren</a>
                    <img src="/resources/images/icons/newsletter-icon.png" width="48" height="48" alt="Newsletter icon" />
                </div>
                <div className="banner-edeka">
                    <img src="/resources/imagesBundesliga/buzzer/prize.png"/>
                </div>
                <ParticipationNavigation />
                <div className="content-home endpage-rewe">
                    <TitleLogo classModifier="desktop"/>
                    <div className="campaign-heading">
                        <h2>Mit dem FC Milka Star-Gewinnspiel tolle Fußball-Preise gewinnen!</h2>
                        <h3 className="recoleta-medium">
                            Deine Chance: ein VIP-Tag als Bundesliga Star und viele attraktive Tickets der Bundesliga / 2. Bundesliga warten auf dich.
                        </h3>
                    </div>
                </div>
            </Aux>
        );
    }

    midSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <div className="prizeRewe-card">
                    <div className="prizeRewe-card__primary">
                        <img src="/resources/imagesBundesliga/buzzer_22/konfetti.png" alt="Gold im Wert"/>
                        <h3>Gold im Wert von 25.000 €</h3>
                        <p>Das ist wirklich meisterlich! Mit FC Milka winken dir goldene Zeiten: Gewinne als Hauptpreis 1x Gold im Wert von ca. 25.000€ und erfülle dir deine Träume.</p>
                    </div>
                    <div className="prizeRewe-card__secondary">
                        <img src="/resources/imagesBundesliga/buzzer_22/season-ticket.png" alt="Dauerkarten der Saison"/>
                        <h3>Dauerkarten der Saison 2022/23</h3>
                        <p>Erlebe mit FC Milka Fußball hautnah! Nutze deine Chance auf eine Dauerkarte* für die  Saison 2022/23 der Bundesliga / 2. Bundesliga. (Werte variieren je nach Club, Auswahl nach Verfügbarkeit. Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga.)</p>
                    </div>
                </div>

                <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                    <button className="btn__container btn--lilac"> JETZT MITMACHEN </button>
                </NavLink>
            </div>
        );
    }

    footerSection() {
        return(
            <div className="campaign-footer campaign-prize-footer">
                <p>
                    Die Gewinner werden schriftlich per E-Mail über den Gewinn benachrichtigt.
                    <br />
                    Übrigens: Während des Aktionszeitraums vom 11.04.2022 bis 05.06.2022 kannst du mehrfach teilnehmen. Pro Kaufbeleg eine Teilnahme möglich.
                </p>
                <br/>
                <p>
                    *Kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga. Ausschluss oder Einschränkungen von Gewinnen aus Gründen der vorherrschenden Corona Lage vorbehalten. Es gelten zudem die jeweils aktuell gültigen Zutrittsregelungen der Clubs, über die sich der Gewinner informieren muss. Barauszahlung und anderweitige Kompensation sowie Rechtsweg ausgeschlossen.
                </p>
            </div>
        )
    }
}

export default PennyPrize;