import React from "react";
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';  
import { withRouter } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination} from 'swiper';
import 'swiper/swiper-bundle.css';

import DropdownClub from "../../../../components/Content/DropdownClub/DropdownClub";
import TitleLogo from "../../../../components/Content/TitleLogo/TitleLogo";
import { mainLoaderToggle, optionListConstruct } from "../../../../modules/helpers";
import JerseyNavigation from "../JerseyNavigation";
import JerseyStepIndicitor from "../StepIndicator";
import Loader from "../../../../components/Overlays/Loader";
import Footersocial from '../../../../components/Layout/FooterContent/FooterSocial';
import _LOCAL from "../../../../Models/LocalStorageKeys";
import FanCenterServices from "../../../../Service/FanCenter";
import _GIFT from "../../../../Models/GiftTypes";
import GLOBAL_CONFIG from "../../../../config/config";
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import appGoogleTracking from "../../../../modules/googletracking";
import _STATUS from "../../../../Models/ParticipationSteps";
import _TEAM_SEASONS from "../../../../Models/TeamSeasons";
import DynamicButton from "../../../../components/Content/dynamicButton/DynamicBoutton";
import Button from "../../../../components/Content/Button/Button";
import FanCenterSCBanner from "../../Banner/FanCenterSCBanner";
import _TICKET_TYPES from "../../../../Models/TicketTypes";
import Aux from "../../../../hoc/Auxiliare";
const _DEFAULT_TEAMS = ["1. FC Köln","1. FC Union Berlin","1.FSV Mainz 05","Arminia Bielefeld","Bayer 04 Leverkusen","Borussia Dortmund","Borussia Mönchengladbach","Eintracht Frankfurt","FC Augsburg","FC Bayern München","FC Schalke 04","Hertha BSC Berlin","RB Leipzig","SC Freiburg","SV Werder Bremen","TSG 1899 Hoffenheim","VfB Stuttgart","Vfl Wolfsburg","1. FC Heidenheim 1846","1. FC Nürnberg","Eintracht Braunschweig","Erzgebirge Aue","FC St. Pauli","Fortuna Düsseldorf","Hamburger SV","Hannover 96","Holstein Kiel","Jahn Regensburg","Karlsruher SC","SC Paderborn 07","SpVgg Greuther Fürth","SV Darmstadt 98","SV Sandhausen","Vfl Osnabrück","Würzburger Kickers"];
class JerseyTeamSelection extends React.Component {
    constructor() {
        super();

       this.StepList = [
            {
                display: "lieblingsclub",
                isInProgress: true,
                isComplete: false,
            },
            {
                display: "trikotauswahl",
                isInProgress: false,
                isComplete: false,
            },
            {
                display: "daten-check",
                isInProgress: false,
                isComplete: false,
            }
        ];

        this.state = {
            Season: "",
            TeamSelected: "",
            SimpleLoader: false,
            GiftType: _GIFT.Jersey,
            activeButtom: "Bundesliga",
            ErrorMessage: "",
            isMobile: false,
            TypeTicket: "",
            allJersey: [],
        }

        this.HeadingSection = this.HeadingSection.bind(this);
        this.resize = this.resize.bind(this);
        this.IsFavouriteTeamValid = this.IsFavouriteTeamValid.bind(this);
        this.getTeamIdSelected = this.getTeamIdSelected.bind(this);
        this.GetTypeTickets = this.GetTypeTickets.bind(this);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        if(localStorage.getItem(_LOCAL.AvailableTeams) === null) {
            // this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user);
        }
        mainLoaderToggle('hide');
        _SCROLL_PAGE.ScrollToTop();
        window.addEventListener("resize", this.resize);
        this.resize();
        
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const uid = urlParams.get('uid');
        if(!!uid)
        {
            localStorage.setItem('UID', uid.toString());
            console.log('UID', uid);
            this.GetTypeTickets(uid);
        }
    }


    componentWillUnmount() {
        mainLoaderToggle('show');
    }
         GetTypeTickets(_uid){
      
        var _data = {
            uid:_uid
        }
      
        FanCenterServices.CheckStock(_data)
            .then((response) => {
            this.setState({
                SimpleLoader: true,
            });
            let {message, details} = response.data , {type}=details, {participant}=details, {trikots}=details;
            if(message === "OK"){
                localStorage.setItem(_LOCAL.TicketType,type);
                localStorage.setItem(_LOCAL.UserDetails,JSON.stringify(participant));
                localStorage.setItem(_LOCAL.TrikotsList,JSON.stringify(trikots));
                // this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType) || _TICKET_TYPES.CAT2});
                // console.log("Set Type"+type);
                this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType)});
                this.setState({allJersey: JSON.parse(localStorage.getItem(_LOCAL.TrikotsList))});
            } else {
                if (message === "Order already received"){
                        localStorage.setItem(_LOCAL.ErrorTicketsType,"received");
                    }
                    else if(message === "Order too late"){
                        localStorage.setItem(_LOCAL.ErrorTicketsType,"late");
                    }
                     this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.jersey.errorJersey);
            }
            })
            .catch(() => {
            })
            .finally(() => {
            this.setState({
                SimpleLoader: false,
            });
            })
  }
    affichage(){
         mainLoaderToggle('hide');
         return(<Aux>
            <div>        
               <FanCenterSCBanner category={ (this.state.TypeTicket === _TICKET_TYPES.TRICKOTUNISIGNED ? "-fancenter-teamselection" : "-fancenter-teamselection")}/>
                <div className="buzzer-jersey favorite-club">
                    {this.HeadingSection()}
                    <form id="frm_team_selection"
                        className="form-team"
                        ref = { form => this._TEAM_SELECTION = form }
                        name="frm_team_selection" 
                        method="post" 
                        action="/"
                        onSubmit={this.SubmitHandler.bind(this)} >

                        {this.TeamSection(this.props)}

                    </form>

                    {this.state.SimpleLoader ? <Loader /> : null}

                    <Footersocial/>
                </div>
            </div>
        </Aux>);
    }
     render() {
        return this.state.TypeTicket &&  this.state.TypeTicket !== "" ? this.affichage() : (this.activateLoader());
    }

    activateLoader(){
        mainLoaderToggle('show');
        return "";
    }

    TrikotButtonTag = (dataLayerOption, dataLayerOptionSigned) => {
        if(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED) {
            appGoogleTracking.dataLayerPush(dataLayerOption)
            console.log(dataLayerOption);
        }
        else {
            appGoogleTracking.dataLayerPush(dataLayerOptionSigned)
            console.log(dataLayerOptionSigned);
        }
    }

    HeadingSection() {
        return (
            <div>
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <JerseyStepIndicitor Steps={this.StepList} />
                        <div className="contenu">
                            
                        {
                            localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ?
                            <h3 className="gobold-bold paragraph-heading titre">
                                von welchem club soll dein original heimtrikot der bundesliga / 2. bundesliga der saison 2024/25 sein?                         
                            </h3>
                            : 
                            <h3 className="gobold-bold paragraph-heading titre"> 
                               von welchem club soll dein signiertes original <br /> heimtrikot der bundesliga / 2. bundesliga der <br/>saison 2024/25 sein?
                            </h3>
                        }
                        </div>
                        {
                            localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ?
                            <p className="p-fav" style={{ width: '100%' }}>
                               Schnell sein lohnt sich, denn das Trikot-Kontingent ist begrenzt. Wähle jetzt deinen Lieblingsclub aus.
                            </p>
                            : 
                            <p className="p-fav" style={{ width: '100%' }}>
                               Schnell sein lohnt sich, denn das Trikot-Kontingent ist begrenzt. Wähle jetzt deinen Lieblingsclub aus.
                            </p>
                        }
                    </div>
                </div>
            </div>
        );
    }
    buttonSwiper(){
        const nextButton = () => {
          const next = document.getElementsByClassName('swiper-button-next')[0];
          this.setState({ activeButtom: "Bundesliga2" }, () =>{
              let a = document.getElementById('buttom-next');
              a.classList.remove('dynamic-button__child');
              a.classList.add('dynamic-button__child-active');
              let b = document.getElementById('bouton-dynamic-buttom-next');
              b.classList.remove('dynamic-button__child__first');
              b.classList.add('dynamic-button__child__second');
              
              let c = document.getElementById('buttom-prev');
              c.classList.remove('dynamic-button__child-active');
              c.classList.add('dynamic-button__child');
              let d = document.getElementById('bouton-dynamic-buttom-prev');
              d.classList.remove('dynamic-button__child__second');
              d.classList.add('dynamic-button__child__first');
          })
          next.click()
        } 
  
        const previousButton = () => {
          const prev = document.getElementsByClassName('swiper-button-prev')[0];
          this.setState({ activeButtom: "Bundesliga"}, () =>{
              let a = document.getElementById('buttom-prev');
              a.classList.remove('dynamic-button__child');
              a.classList.add('dynamic-button__child-active');
              let b = document.getElementById('bouton-dynamic-buttom-prev');
              b.classList.remove('dynamic-button__child__first');
              b.classList.add('dynamic-button__child__second');
              
              let c = document.getElementById('buttom-next');
              c.classList.remove('dynamic-button__child-active');
              c.classList.add('dynamic-button__child');
              let d = document.getElementById('bouton-dynamic-buttom-next');
              d.classList.remove('dynamic-button__child__second');
              d.classList.add('dynamic-button__child__first'); })
          prev.click()
          console.log('Prev -> '+this.state.activeButtom)
        } 
  
        return(
            <div className="group-bouton">
                <DynamicButton 
                    id="buttom-prev" 
                    onClick={() => { 
                        previousButton();
                        this.TrikotButtonTag({
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "bundesliga",
                            dataLabel: "bundesliga_original_heimtrikots"}
                            ,{
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "bundesliga",
                            dataLabel: "bundesliga_signierte_trikots"}
                        );}
                    } 
                    isActive={this.state.activeButtom === "Bundesliga"} 
                    text="Bundesliga" 
                />

                <DynamicButton 
                    id="buttom-next" 
                    onClick={() => { 
                        nextButton();
                        this.TrikotButtonTag({
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "bundesliga2",
                            dataLabel: "bundesliga2_original_heimtrikots"}
                            ,{
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "bundesliga2",
                            dataLabel: "bundesliga2_signierte_trikots"}
                        );}
                    } 
                    isActive={this.state.activeButtom === "Bundesliga2"} 
                    text="2. Bundesliga" 
                />
            </div>
        )
      }


    TeamSelectionSection(props) {
        let teamArray = !!localStorage.getItem(_LOCAL.AvailableTeams) ? JSON.parse(localStorage.getItem(_LOCAL.AvailableTeams)) : _DEFAULT_TEAMS,
            footballClubContent = optionListConstruct(teamArray);

        return (
            <div className="form__container no-bottom">
                <DropdownClub
                    defaultImg={"/resources/imagesBundesliga/team-picture/team-default.png"}
                    divClass={"form-input__container dropdown-input__container form-input__dropdown full-width btn-bottom"}
                    class="team-picture"
                    id="team1"
                    title=""
                    image={props.t('Page.Participation.form.footballClub.images.image1')}
                    defaultValue={props.t('Page.Participation.form.footballClub.footballClubFieldText')}
                    team="team1"
                    content={parse(footballClubContent)}
                    noImage={true}
                    setFavouriteTeam={ this.FavouriteClubSelection.bind(this)}
                />

                { 
                    this.state.ErrorMessage.length > 0 ?
                    <p className="buzzer-error">{this.state.ErrorMessage}</p>
                    : null
                }
                <button type="submit" className="btn__container btn--red">WEITER</button>
            </div>
        );
    }

    FavouriteClubSelection( team ) {
        this.setState({
            TeamSelected: team,
            ErrorMessage: ""
        })
    }

    IsNotAvalaibleTeam(list){
        let quota = 0;
        let result = false;
        if (list && list.length > 0) {
          list.forEach(element => {
            if (element.NumberAvailable == 0) {
              quota++;
            }
          });
        }
        if(quota === list.length) result= true;
        return result;
    }

    GetSizesJerseys(Jerseys){
        var availableMedium = false,
            availableLarge = false,
            availableXLarge = false,
            codeMedium = "",
            codeLarge = "",
            codeXLarge = "";

        Jerseys.forEach(jersey => {
            switch (jersey.giftSize.toUpperCase()) {
                case "M":
                    codeMedium = jersey.giftCode;
                    availableMedium = true;
                    break;
                case "L":
                    codeLarge = jersey.giftCode;
                    availableLarge = true;
                    break;
                case "X":
                    codeXLarge = jersey.giftCode;
                    availableXLarge = true;
                    break;

                default:
                    break;
            }
        });

        return {
            M: {
                code: codeMedium,
                value: "M",
                display: "M",
                available: availableMedium
            },
            L: {
                code: codeLarge,
                value: "L",
                display: "L",
                available: availableLarge
            },
            XL: {
                code: codeXLarge,
                value: "X",
                display: "XL",
                available: availableXLarge
            }
        };
    }


    TeamSection(props) {
        const imagePath = "/resources/bundesliga-logos/logos/";
        let footballClubContent = props.t('Page.Participation.form.footballClub.footballClubListName', { returnObjects: true });
        let teamsAvailable = !!localStorage.getItem(_LOCAL.AvailableTeams) ? JSON.parse(localStorage.getItem(_LOCAL.AvailableTeams)) : [];
            if(teamsAvailable.length > 0){
                footballClubContent = teamsAvailable;
            }
        let swiperSlides = [];
        let maxTeamToShow = this.state.isMobile ? 18 : 18;
        let count = 0;
        let teamsTemp = [];
        for(let i=0; i<footballClubContent.length; i++){
            const club = footballClubContent[i];
            let jersey = this.state.allJersey.filter( (item) => club === item.Team );
            teamsTemp.push((
                <div className={`team ${ this.IsNotAvalaibleTeam(jersey) ? "not-avalaible" : "" } ${ this.state.TeamSelected === footballClubContent[i] ? "active" : "" }`} key={"team" +  i} 
                    onClick={()=>{
                        const favoriteTeamError = document.getElementById("favorite-team-error");
                            favoriteTeamError.style.display = "none";
                            favoriteTeamError.classList.remove("notvalid");
                        this.FavouriteClubSelection(footballClubContent[i]);
                        /* this.TrikotButtonTag({
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "team",
                            dataLabel: `${footballClubContent[i]}_original_heimtrikots`}
                            ,{
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "team",
                            dataLabel: `${footballClubContent[i]}_signierte_trikots`}
                        ); */
                    }}>
                    <div className={`team-selected${ this.state.TeamSelected === footballClubContent[i] ? "__active" : "" }`}>
                        <img src="/resources/imagesBundesliga/fanCenter/icons/close-team.png" alt="team-selected"/>
                    </div>
                    <div className="card-team">
                        <div className="team-logo">
                            <img src={imagePath+footballClubContent[i]+".svg"} className={`team-logo-${footballClubContent[i].replace(/\s/g, '').split('.').join("")}-img`} alt={footballClubContent[i]}/>
                        </div>
                        <p className="team-name">{footballClubContent[i]}</p>
                    </div>
                    { this.IsNotAvalaibleTeam(jersey) ? 
                        <div className="card-team__not-avalaible">
                            <ul><li></li></ul>
                            <span> Trikots nicht mehr verfügbar</span>
                        </div> : 
                        <button className="btn__container btn--red button-team" id={`submit-team${ this.state.TeamSelected === footballClubContent[i] ? "__active" : "" }`} onClick={() => {this.SubmitTeamSelected();this.TrikotButtonTag({
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "to_tickets_section",
                            dataLabel: `to_tickets_section_original_heimtrikots`}
                            ,{
                            dataEvent: "custom_event_click",
                            dataCategory: "click_action",
                            dataAction: "to_tickets_section",
                            dataLabel: `to_tickets_section_signierte_trikots`}
                        );}}>Zu den Trikots</button>
                    }
                </div>
            ));
            count++;
            if(count === maxTeamToShow || i === footballClubContent.length-1){
                const teamsSlide = teamsTemp;
                swiperSlides.push((
                    <SwiperSlide key={"team-slide-"+footballClubContent[i]} tag="li" style={{  width: '100%' }}>
                        <div className="chooseTeam--team-container" style={{ maxWidth: '100%' }}>
                            {teamsSlide}
                        </div>
                    </SwiperSlide>
                ));
                teamsTemp = [];
                count = 0;
            }
        }

        return (
            <div className="chooseTeam buzzer-form team-container__form" style={{ marginBottom: '0' }}>
                <h3 className="gobold-bold heading" id="top">wähle deinen lieblingsclub</h3>
                {this.buttonSwiper()}
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    pagination
                    wrapperTag="ul"
                    tag="section"
                    /* onSlideNextTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Right'
                        });
                    }}
                    onSlidePrevTransitionStart={() => {
                        appGoogleTracking.dataLayerPush({
                            'dataEvent': 	'info-click',
                            'dataCategory': 'Informational Action',
                            'dataAction': 	'Carousel Click',
                            'dataLabel': 	'Left'
                        });
                    }} */
                >
                    {swiperSlides}
                </Swiper>
                <div className='match-list__cta-mobile'>
                    <Button
                        icon={'none'}
                        text={'Liga auswählen'}
                        image={'chevron-up.png'}
                        onclick={() => this.scrollTo('top')}
                    />
                </div>
                <p id="favorite-team-error">Bitte wähle deinen Lieblingsclub.</p>
            </div>
        );
    }

    IsFavouriteTeamValid(){
        const favoriteTeamError = document.getElementById("favorite-team-error");
        if(this.state.TeamSelected == ""){
            favoriteTeamError.style.display = "none";
            favoriteTeamError.classList.add("notvalid");
            return false;
        }
        favoriteTeamError.style.display = "none";
        favoriteTeamError.classList.remove("notvalid");
        return true;
    }

    getTeamIdSelected(){
        let teamIDs = !!localStorage.getItem(_LOCAL.AvailableTeamIDs) ? JSON.parse(localStorage.getItem(_LOCAL.AvailableTeamIDs)) : {};
            if(teamIDs.hasOwnProperty(this.state.TeamSelected)){
                return teamIDs[this.state.TeamSelected];
            } else {
                let listTeam = this.props.t('Page.Participation.form.footballClub.footballClubListObject', { returnObjects: true });
                for(let i = 0; i < listTeam.length; i++){
                    if(listTeam[i].name ==  this.state.TeamSelected){
                        return listTeam[i].id;
                    }
                }
            }
        return "id-unknown";
    }

    scrollTo(id) {
        setTimeout(() => {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
    }

    SubmitHandler(event) {
        event.preventDefault();
    }

    SubmitTeamSelected(){
        if(!!this.IsFavouriteTeamValid()) {
            localStorage.setItem(_LOCAL.FavoriteClub, this.state.TeamSelected);
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection);

        } else {
            this.setState({
                ErrorMessage: "Bitte wähle deinen Lieblingsclub."
            })
        }
    }
}

export default withTranslation()(withRouter(JerseyTeamSelection));