import React from "react";
import { withTranslation } from "react-i18next";
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import _SCROLL_PAGE from "../../../modules/ScrollPage";

class TrikotPromoFaq extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div id="buzzer-faqs" className="weltmeister-faqs tickets-faq trikot-faq">
                {this.faqContent(this.props)}
                <Footersocial />
            </div>
        );
    }

    faqContent(props) {
        const iconPlus = "/resources/imagesBundesliga/buzzer/plus.png";
        const iconMinus = "/resources/imagesBundesliga/buzzer/minus.png";

        return (
            <div className="content-faq">
                <div className="content-faq__item">
                    <h6>Wie läuft das Gewinnspiel ab?</h6>
                    <img
                        src={iconMinus}
                        className="content-faq__img shown"
                        data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_1"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content show">
                        <ul>
                            <li>Teilnahme erfolgt durch Kauf von Produkten der Marke Milka und/oder TUC im Zeitraum 03.07.2023 bis 01.10.2023 sowie Eingabe des Produktcodes (auf der Produktverpackung), Angabe der Aktionsmarke, des Händlers/Kaufdatums auf <a href="https://www.trikot.fcmilka.de">www.trikot.fcmilka.de</a> bis zum 01.10.2023.</li>
                            
                            <li>Bitte Original-Kaufbeleg zum Nachweis aufbewahren. Online-Registrierung erforderlich.</li>
                            
                            <li>Nach der Eingabe des Produktcodes öffnet der Teilnehmer den Glücksspind und erfährt sofort, ob er gewonnen hat oder nicht.</li>
                        </ul>
                    </div>
                </div>



                <div className="content-faq__item">
                    <h6>Mit welchen Produkten kann ich teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_2"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Die Aktion ist gültig für alle Produkte der Marken Milka und/oder TUC.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Wo finde ich die Produkte für das Gewinnspiel?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_3" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Du findest die Produkte überall dort im Handel, wo du auch sonst deine Lieblingsprodukte von Milka und/oder TUC findest.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich mehrere Produktcodes hochladen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_4" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Ja, Mehrfachteilnahmen mit neuem Produktcode innerhalb der Aktionsprodukte sind erlaubt. Max. ein Sachpreis pro Person im Gewinnspielzeitraum.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Wie lange kann ich an der Aktion teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_5" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                        Du hast vom 03.07.2023 bis 01.10.2023 die Möglichkeit, deinen Produktcode einzugeben. Es nehmen aber nur Aktionsprodukte teil, die in diesem Zeitraum gekauft wurden.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann jeder an der Aktion teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_6" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                        Teilnehmen kann jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland.
Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen sowie jeweils deren Angehörige.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>In welchen Ländern kann man teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_7" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Die Teilnahme ist in Deutschland möglich.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Was kann gewonnen werden?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_8" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Insgesamt gibt es folgende Gewinne* im Aktionszeitraum:
                        </p>
                        <ul>
                            <li>
                                <strong>1.500 original Heimtrikots </strong> der Bundesliga / 2. Bundesliga, Saison 2023/24 (Werte variieren je nach Club, Auswahl nach Verfügbarkeit über FC Milka Fan-Center) 
                            </li>
                            <li>
                                <strong>150 Milka Produktpakete </strong>
                            </li>
                            <li>
                                <strong>15.000 Rabattgutscheine </strong>Rabattgutscheine in Höhe von 20% von 11teamsports
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie erfahre ich, ob ich gewonnen habe?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_9" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                        Die Gewinnermittlung erfolgt innerhalb des Gewinnspielzeitraumes per Zufallsgenerator. Man erfährt sofort, ob man gewonnen hat oder nicht.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Was muss ich machen, nachdem ich meinen Produktcode eingegeben habe?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_10" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                        Es erscheint direkt nach Eingabe des Produktcodes eine Spind-Tür, die durch Anklicken geöffnet werden muss. Hinter der Tür erfährt man sofort, ob man gewonnen oder ob man nicht gewonnen hat.  
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich meine Daten löschen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_11" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                        Nach Ende der Aktion werden alle Teilnehmerdaten gelöscht. Soweit diese aus gesetzlichen Gründen nicht gelöscht werden dürfen, werden deine Daten gesperrt und nach Ablauf der handels- und steuerrechtlichen Aufbewahrungsfrist gelöscht. Aber falls du deine Daten bereits während des Aktionszeitraums löschen möchtest, ist das natürlich möglich. Dazu wendest du dich bitte direkt an unseren Verbraucherservice*. Eine Teilnahme am Gewinnspiel ist dann leider nicht mehr möglich.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>An wen kann ich mich wenden, wenn ich weitere Fragen habe?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="trikot_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="FAQ_NUMBER_12" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Vielleicht hast du noch weitere Fragen, die hier nicht beantwortet worden sind. Dann wende dich am besten direkt an unseren Verbraucherservice*.
*Falls der hier hinterlegte Link zum Verbraucherservice nicht funktioniert, kannst du über den Kontaktlink im Bereich „Service“ den <a href="https://protect-eu.mimecast.com/s/mFnQCwEBpT0BBY8kTVzC4F?domain=contactus.mdlzapps.com" target="_blank" rel="noopener noreferrer">Verbraucherservice</a> erreichen.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    showItemContent(e, iconMinus, iconPlus) {
        let classes = Array.from(e.target.classList);
        let itemContent = e.target.parentNode.children[2];

        if (classes.includes("notshown")) {
            e.target.setAttribute('src', iconMinus);
            e.target.classList.remove("notshown");
            e.target.classList.add("shown");
            itemContent.classList.add("show");
            appGoogleTracking.processEventCTANavLink(e);
        } else {
            e.target.setAttribute('src', iconPlus);
            e.target.classList.remove("shown");
            e.target.classList.add("notshown");
            itemContent.classList.remove("show");
        }
    }
}

export default withTranslation()(TrikotPromoFaq);