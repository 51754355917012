import React from "react";
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import flatpickr from "flatpickr";
import ReCAPTCHA from "react-google-recaptcha";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination} from 'swiper';
import 'swiper/swiper-bundle.css';
//import './styles.css';

// User defined components
import StepContent from "../../../components/Content/StepContent/StepContent";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import { mainLoaderToggle, optionListConstruct, ServerDate } from "../../../modules/helpers";
import appGoogleTracking from "../../../modules/googletracking";
import AccountServices from "../../../Service/Account";
import AccountModel from "../../../Models/Account";
import LoginForm from "../../../components/Account/LoginForm";
import AccountModels from "../../../Models/Account";
import Salutations from "../../../Models/Salutation";
import Checkbox from "../../../components/Controls/Checkbox";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import Loader from "../../../components/Overlays/Loader";

// Date selector STYLES
import 'flatpickr/dist/flatpickr.css';
import { Code } from "../../../modules/Code";
import ValidateForm from "../../../modules/validate";
import GLOBAL_CONFIG from "../../../config/config";
import ParticipationService from "../../../Service/Participation";
import DropdownClub from "../../../components/Content/DropdownClub/DropdownClub";
import ParticipationNavigation from "./ParticipationNavigation";
import _ERROR_MESSAGES from "../../../Models/ErrorMessages";
import _LOCAL from "../../../Models/LocalStorageKeys";
import _BUZZER_MESSAGE from "../../../Models/BuzzerErrorMessage";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import { withRouter } from "react-router";
import _GAME_STATUS from "../../../Models/GameStatus";


SwiperCore.use([Navigation, Pagination]);

class BuzzerParticipation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            LoginForm: {
                IsLoggedIn: false,
                PopinMessage: AccountModel.ErrorHTML._DEFAULT
            },
            DisplayLogin: true,
            SimpleLoader: false,
            CodeIsValid: true,
            ShowMoreTerms: false,
            FavouriteTeam: "",
            FavouriteTeamName: "",
            ErrorMessage: "",
            teamImage: "",
            isMobile: false,
            isMedium: false,
            isTablet: false,
            modifyFormLayout: false,
            isBigSwiper: false,
            otherCheckbox : null,
            currentKip : null,
            termsCheckbox : null,
            memberCheckbox : null,
            newsletterCheckbox : null,
            productType: "",
            brands: [],
            brandError: '',
            gameIsLive:null
        }
        var limit = new Date();
        limit.setFullYear(limit.getFullYear() - 18);
        this.maxDOB = limit;

        var now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        var lowerLimit = new Date(2023, 3, 3);
        lowerLimit = now < lowerLimit ? now : lowerLimit;
        var maxLimit = new Date(2023, 5, 25);
        this.minPurchaseDate = lowerLimit;
        this.maxPurchaseDate = now < maxLimit ? now : maxLimit;

        this.FormFiller = this.FormFiller.bind(this);
        this.ErrorHandler = this.ErrorHandler.bind(this);
        this.DisplayBirthInputs = this.DisplayBirthInputs.bind(this);
        this.resize = this.resize.bind(this);
        this.checkMinimumAge = this.checkMinimumAge.bind(this);
        this.ProcessForm = this.ProcessForm.bind(this);
        this.IsFavouriteTeamValid = this.IsFavouriteTeamValid.bind(this);
        window.FILE_STATUS = false;
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768, isMedium: window.innerWidth > 768 && window.innerWidth <= 1024, isTablet: window.innerWidth < 600,modifyFormLayout: window.innerWidth <= 1024});
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        this.FlatpikerDateInit(this._PRODUCT_DATE, { max: this.maxPurchaseDate, min: this.minPurchaseDate });
        window.addEventListener("resize", this.resize);
        this.resize();
        _SCROLL_PAGE.ScrollToTop();
        
        this.setState({gameIsLive :  localStorage.getItem(_GAME_STATUS.Game) === true || localStorage.getItem(_GAME_STATUS.Game) === "true" ? true : false});
        let currentKip = localStorage.getItem(_LOCAL.CurrentKip);
        if(currentKip){
            this.setState({
                currentKip : currentKip.toLowerCase()
            });
            // if(currentKip.toLowerCase() === "globus"){
            //     this.setState({
            //         otherCheckbox : currentKip.toLowerCase()
            //     });
            // }
        }
        this.CheckboxLinkTag();
    }

    componentWillMount() {
        if(localStorage.getItem(_LOCAL.ProductCode) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.buzzer.main);
        }
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div id="buzzer-participation">
                <div className={`content-participation__login ${this.state.DisplayLogin ? "" : "none"}`}>
                    <div className="buzzer-form">
                        {this.state.DisplayLogin ? this.LoginSection() : null}
                    </div>
                </div>
                <div className="content-participation__form">
                    {this.DetailsSection(this.props)}

                    <p className="special buzzer-special">
                        *Gib deine persönlichen Daten ein (Vor- und Nachname sowie die E-Mail-Adresse, Geburtsdatum, Postadresse). Lies dir bitte vorher unsere <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" style={{ color: "white" }}>Datenschutzerklärung</a> aufmerksam durch, damit du weißt, wie wir mit deinen personenbezogenen Daten umgehen und welche Rechte dir zustehen.
                    </p>
                </div>
                <Footersocial />
                {
                    this.state.SimpleLoader ? <Loader /> : null
                }
            </div>
        );
    }

    HeadingSection(props) {
        return (
            <div className="content-home endpage-rewe">
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <StepContent
                            step1={props.t('Page.Home.Steps.Buzzer-Participation.1')}
                            step1sm={props.t('Page.Home.Steps.Buzzer-Participation.1')}
                            step1Text={parse("Kaufe zwischen dem  10.05.-16.05.2021 <span class=\"text--wheat\">  Milka und/oder OREO Produkte  </span>im Wert von mindestens <span class=\"text--wheat\"> 5€ </span>innerhalb eines Einkaufs.")}
                            step_arrow="/resources/imagesBundesliga/kaufland/icons/arrow_right.png"
                            step2={props.t('Page.Home.Steps.Buzzer-Participation.2')}
                            step2sm={props.t('Page.Home.Steps.Buzzer-Participation.2')}
                            step2Title={parse("Lade den <span class=\"text--wheat\"> Kassenbon </span>den bis zum 23.05.2021 hoch und gib deine persönlichen Daten ein.")}
                            step3={props.t('Page.Home.Steps.Buzzer-Participation.3')}
                            step3sm={props.t('Page.Home.Steps.Buzzer-Participation.3')}
                            step3Title={parse("​<span class=\"text--wheat\">Rubbellos</span> auswählen, freirubbeln und direkt erfahren, ob du zu den Champions gehörst.")}
                            step4={props.t('Page.Home.Steps.Buzzer-Participation.4')}
                            step4sm={props.t('Page.Home.Steps.Buzzer-Participation.4')}
                        />
                    </div>
                </div>
            </div>
        );
    }

    LoginSection() {
        return <LoginForm PreRequest={() => this.setState({ SimpleLoader: true })} SuccessHandler={this.LoginSuccess.bind(this)} ErrorHandler={this.LoginError.bind(this)} ServiceFailedHandler={this.LoginServiceError.bind(this)} />
    }

    DetailsSection(props) {
        return (
            <form id="frm_participation"
                name="frm_participation"
                method="post" action="/"
                ref={form => this._FORM_DETAILS = form}
                onSubmit={this.SubmitHandler.bind(this)} noValidate>
                <div className="buzzer-form" id="participant-info">
                    <h2>KEIN FC MILKA MITGLIED?</h2>
                    <h3 className="part-sub">Dann hier registrieren</h3>

                    <div className="form-container" id="userDetails">
                        <div id="salutation" className="input-container">
                            <p className="form-anrede">*Pflichtfelder</p>
                            <div className="form-input__container form-input__dropdown">
                                <select ref={select => this._DETAILS_salutation = select} className="form-input__input js-event-type-in js-event-ab"
                                    type="text" name="salutation"
                                    id="salutation"
                                    placeholder="Arende*"
                                    data-require="true"
                                    data-type="ddl"
                                    data-error-target="#error-salutation"
                                    data-required-message="Anrede ist erforderlich."
                                    data-event-fieldname="Salutation"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Salutation"
                                    data-label="Type In">
                                    {
                                        Object.keys(Salutations.dropdown).map(key => {
                                            var i = 0;
                                            return <option value={key} key={`${i++}-${key}`} >{Salutations.dropdown[key]}</option>
                                        })
                                    }
                                </select>
                                <label className="form-input__label" htmlFor="salutation">{Salutations.dropdown["default"]}</label>
                            </div>
                        </div>

                        <div id="firstName" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="firstname">Vorname*</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    placeholder="Vorname*"
                                    data-require="true"
                                    data-type="regex"
                                    data-error-target="#error-firstname"
                                    data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                                    data-required-message="Firstname is required."
                                    data-pattern-message="Firstname not valid."
                                    data-event-fieldname="Firstname"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Firstname"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_firstName = input}
                                />
                            </div>
                        </div>

                        <div id="lastName" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="lastname">Nachname*</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    placeholder="Nachname*"
                                    data-require="true"
                                    data-type="regex"
                                    data-error-target="#error-lastname"
                                    data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                                    data-required-message="Lastname is required."
                                    data-pattern-message="Lastname not valid."
                                    data-event-fieldname="Lastname"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Lastname"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_lastName = input}
                                />
                            </div>
                        </div>
                        
                        <div id="email-container" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="email">Email*</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="E-Mail*"
                                    data-require="true"
                                    data-type="regex"
                                    data-error-target="#error-email"
                                    data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                    data-required-message=""
                                    data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift"
                                    data-event-fieldname="Email"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Email"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_email = input}
                                />
                                <span id="error-email" className="form-input__error active"></span>
                            </div>
                        </div>

                        {this.state.modifyFormLayout ? 
                        <>
                        <div id="street" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="street_name">Straße*</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="street_name"
                                    id="street_name"
                                    placeholder="Straße*"
                                    data-require="true"
                                    data-type="text"
                                    data-error-target="#error-street-number"
                                    data-required-message="Street number is required."
                                    data-event-fieldname="Street number"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Street number"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_street_name = input}
                                />
                            </div>
                        </div>
                        <div id="houseNumber" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="street_number">Hausnummer*</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="street_number"
                                    id="street_number"
                                    placeholder="Hausnummer*"
                                    data-require="true"
                                    data-type="text"
                                    data-error-target="#error-house-number"
                                    data-required-message="House number is required."
                                    data-event-fieldname="House number"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="House number"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_street_number = input}
                                />
                            </div>
                        </div>
                        <div id="address-container" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="adress">Adresszusatz</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="address"
                                    id="address"
                                    placeholder="Adresszusatz"
                                    data-require="false"
                                    data-event-fieldname="Address"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Address"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_address = input}
                                />
                            </div>
                        </div>
                        </> 
                        : ""}

                        <div id="zipCode" className="input-container">
                            <div className="form-input__container space-right">
                                <label className="form-input__label" htmlFor="zip_code">PLZ*</label>
                                <input className="form-input__input js-event-type-in js-event-ab js-zip-code"
                                    type="text"
                                    name="zip_code"
                                    id="zip_code"
                                    placeholder="PLZ*"
                                    maxLength="5"
                                    data-require="true"
                                    data-type="regex"
                                    data-error-target="#error-zip-code"
                                    data-regex-pattern="^[0-9]{5}$"
                                    data-required-message="Zip code is required."
                                    data-pattern-message="Zip code not valid."
                                    data-event-fieldname="Zip code"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Zip code"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_zipCode = input}
                                />
                            </div>
                        </div>

                        <div id="city-container" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="city">Stadt*</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="city"
                                    id="city"
                                    placeholder="Ort*"
                                    data-require="true"
                                    data-type="text"
                                    data-error-target="#error-city"
                                    data-required-message="City is required."
                                    data-event-fieldname="City"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="City"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_city = input}
                                />
                            </div>
                        </div>
                        <p id="error-email" className="email-error-participation" style={{ color: "red" }}></p>
                       {!this.state.modifyFormLayout ? 
                       <>
                         <div id="street" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="street_name">Straße*</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="street_name"
                                    id="street_name"
                                    placeholder="Straße*"
                                    data-require="true"
                                    data-type="text"
                                    data-error-target="#error-street-number"
                                    data-required-message="Street number is required."
                                    data-event-fieldname="Street number"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Street number"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_street_name = input}
                                />
                            </div>
                        </div>

                        <div id="houseNumber" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="street_number">Hausnummer*</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="street_number"
                                    id="street_number"
                                    placeholder="Hausnummer*"
                                    data-require="true"
                                    data-type="text"
                                    data-error-target="#error-house-number"
                                    data-required-message="House number is required."
                                    data-event-fieldname="House number"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="House number"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_street_number = input}
                                />
                            </div>
                        </div>
                       </>
                        : ""}

                        <div id="country-container" className="input-container">
                            <div className="form-input__container dropdown-input__container active">
                                <input className="form-input__input"
                                    type="text"
                                    name="country"
                                    id="country"
                                    placeholder="Land*"
                                    value="Deutschland"
                                    data-require="false"
                                    disabled
                                    ref={input => this._DETAILS_country = input}
                                />
                            </div>
                        </div>

                        {!this.state.modifyFormLayout ? <div id="address-container" className="input-container">
                            <div className="form-input__container">
                                <label className="form-input__label" htmlFor="adress">Adresszusatz</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="address"
                                    id="address"
                                    placeholder="Adresszusatz"
                                    data-require="false"
                                    data-event-fieldname="Address"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Address"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_address = input}
                                />
                            </div>
                        </div> : ""}

                        

                        {/* <div id="phone" className="input-container">
                            <div className="form-input__container space-right">
                                <label className="form-input__label" htmlFor="phoneNumber">Telefonnummer*</label>
                                <input className="form-input__input js-event-type-in js-event-ab"
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    placeholder=" Telefonnummer*"
                                    data-require="true"
                                    data-type="regex"
                                    data-error-target="#error-phoneNumber"
                                    data-regex-pattern="\+[0-9]+"
                                    data-required-message="Phone Number is required."
                                    data-pattern-message="Phone Number not valid."
                                    data-event-fieldname="phoneNumber"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="phoneNumber"
                                    data-label="Type In"
                                    ref={input => this._DETAILS_phoneNumber = input}
                                />
                            </div>
                        </div> */}

                        {
                            !this.state.isMobile && this.DisplayBirthInputs(1)
                        }


                        {
                            this.state.isMobile && this.DisplayBirthInputs(2)
                        }
                    </div>
                </div>

                <hr className="separator" />

                <div className="team-retailer__form">
                    <div className="buzzer-form retailer-container__form">
                        {this.RetailerSection(props)}
                    </div>
                    {this.TeamSection(props)}
                </div>

                {this.ConsentSection(props)}

                <div className="form-container centered captcha">
                    <ReCAPTCHA
                        ref={ref => this._CAPTCHA = ref}
                        hl={GLOBAL_CONFIG.Captcha.language}
                        size="invisible"
                        sitekey={GLOBAL_CONFIG.Captcha.publish}
                        onChange={this.OnCaptchaResolved.bind(this)}
                        onExpired={this.OnCaptchaExpired.bind(this)}
                        onErrored={this.OnCaptchaError.bind(this)}
                    />
                </div>

                {
                    this.state.ErrorMessage.length > 0 ?
                        <p className="buzzer-error bz-err">{this.state.ErrorMessage}</p>
                        : null
                }

                <button type="submit" className="btn__container btn--secondary-lilac buzzer-button">Teilnahme absenden</button>
            </form>
        );
    }

    DisplayRecieptForm(t) {
        const messageFileRequired = `Du hast noch keinen Kaufbeleg hochgeladen. Wähle die entsprechende Datei aus und lade deinen Kassenbon hoch. Bitte denke dabei daran, die nicht an der Aktion teilnehmenden Artikel auf dem Kassenbon unkenntlich zu machen.`;
        return (
            <div className="campaign-form-receipt">
                <h3>Kaufbeleg hochladen*</h3>
                <img src="/resources/images/upload-receipt-imgage.png" width="156" height="173" alt="upload receipt" />
                <label className="btn__container btn--secondary-lilac btn--hover custom-file-btn" htmlFor="file_to_upload" > 
                    <span>DATEI AUSWÄHLEN</span>
                    <input ref={ fileInput => this._RECEIPT_file = fileInput } type="file" id="file_to_upload" name="file_to_upload" data-require="true" data-file-required="true" data-type="file" data-error-target="#error-file" data-required-message={messageFileRequired} data-oversize-error="Ungültige Dateigröße. Bitte Dateigröße Vorgabe beachten." data-format-error="Ungültiges Dateiformat. Bitte nur gültiges Format hochladen." 
                    onChange={(e)=>{
                            ValidateForm.checkfileUpload(e.target);
                        }}/>
                </label>
                <p className="info-receipt">Du kannst deinen Kaufbeleg als .jpg, .png oder .pdf hochladen.</p>
                <div className="form-container results">
                    <span className="form-input__error js-file-error">Error</span>
                    <span className="form-input__success js-file-success">Success</span>
                </div>
            </div>
        );
    }

    ValidParticipantInfo(e){
        const isValid = ValidateForm.validateForm('participant-info');
        if(isValid){
            _SCROLL_PAGE.ScrollSection(document.querySelector(".team-retailer__form"));
        }else{
            let firstError = this._FORM_DETAILS.querySelector(".notvalid");

            if (!!firstError) {
                const yOffset = - 110,
                    y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }
    }

    DisplayBirthInputs(type) {
            return (
                <div id="dob-container" className={ "input-container"}>
                    <Checkbox id="birth-terms"
                    required={true}
                    errorMessage="Ungültiges Alter"
                    text='Bitte bestätige hier, dass du mindestens 18 Jahre alt bist.'
                    onChangeHandler={this.CheckboxChangeHandler.bind(this)}

                />
                </div>
            );
    }

    GetMaxTeamShow(){
        if(this.state.isMobile){
            return 2;
        }
        else if(this.state.isMedium){
            return 4;
        }
        return 8;
    }

    TeamSection(props) {
        const imagePath = "/resources/imagesBundesliga/teams-pictures-buzzer/" ;
        let footballClubContent = props.t('Page.Participation.form.footballClub.footballClubListObject', { returnObjects: true });
        let swiperSlides = [];
        let maxTeamToShow = this.GetMaxTeamShow();
        let count = 0;
        let teamsTemp = [];
        for(let i=0; i<footballClubContent.length; i++){
            teamsTemp.push((
                <div className={`team ${ this.state.FavouriteTeam === footballClubContent[i].id ? "active" : "" }`} key={"team" +  i} 
                    onClick={()=>{
                        const favoriteTeamError = document.getElementById("favorite-team-error");
                            favoriteTeamError.style.display = "none";
                            favoriteTeamError.classList.remove("notvalid");
                        this.FavouriteClubSelection(footballClubContent[i].id, footballClubContent[i].name)
                    }}>
                    <div className="team-logo">
                        <img src={imagePath+footballClubContent[i].name+".png"} alt={footballClubContent[i].name}/>
                    </div>
                    <p>{footballClubContent[i].name}</p>
                </div>
            ));
            count++;
            if(count === maxTeamToShow || i === footballClubContent.length-1){
                const teamsSlide = teamsTemp;
                swiperSlides.push((
                    <SwiperSlide key={"team-slide-"+footballClubContent[i].name} tag="li" style={{ listStyle: 'none' }}>
                        <div className="chooseTeam--team-container">
                            {teamsSlide}
                        </div>
                    </SwiperSlide>
                ));
                teamsTemp = [];
                count = 0;
            }
        }

        return (
            <div className="chooseTeam buzzer-form team-container__form">
                {this.state.isTablet ? <div> <h2 className="heading">VERRATE UNS DEINEN LIEBLINGSCLUB*</h2>
                        <h3 style={this.state.FavouriteTeam ? {visibility: 'visible'} : {visibility: 'hidden'}}>Deine Auswahl ist : <span>{this.state.FavouriteTeamName ? this.state.FavouriteTeamName : ""}</span></h3></div> : ""}
                <div className="content-swiper">
                    <div className="content-swiper-image">
                        <img src={this.state.isMobile ? "/resources/imagesBundesliga/buzzer/rudi-cutout-1-sm.png" : "/resources/imagesBundesliga/buzzer/rudi-cutout-1.png"} alt="Rudi" />
                    </div>
                    <div className="content-swiper-container">
                        {!this.state.isTablet ? <div><h2 className="heading">VERRATE UNS DEINEN LIEBLINGSCLUB*</h2>
                        <h3 style={this.state.FavouriteTeam ? {visibility: 'visible'} : {visibility: 'hidden'}}>Deine Auswahl ist : <span>{this.state.FavouriteTeamName ? this.state.FavouriteTeamName : ""}</span></h3></div> : ""}
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            navigation
                            pagination
                            wrapperTag="ul"
                            tag="section"
                            onSlideNextTransitionStart={() => {
                                appGoogleTracking.dataLayerPush({
                                    'dataEvent': 	'info-click',
                                    'dataCategory': 'Informational Action',
                                    'dataAction': 	'Carousel Click',
                                    'dataLabel': 	'Right'
                                });
                            }}
                            onSlidePrevTransitionStart={() => {
                                appGoogleTracking.dataLayerPush({
                                    'dataEvent': 	'info-click',
                                    'dataCategory': 'Informational Action',
                                    'dataAction': 	'Carousel Click',
                                    'dataLabel': 	'Left'
                                });
                            }}
                        >
                            {swiperSlides}
                        </Swiper>
                        <p id="favorite-team-error">Verpflichtend !</p>
                    </div>
                </div>
            </div>
        );
    }

    RetailerSection(props) {
        let retailerList = props.t('Page.Participation.form.retailerList.retailers', { returnObjects: true }),
            brandList = props.t('Page.Participation.form.brands', { returnObjects: true });
        const defaultRetrailer = retailerList.shift();
        retailerList.sort((a, b) => a.display.localeCompare(b.display));
        retailerList.unshift(defaultRetrailer);

        return (
            <div className="form-container">
                <h6 className="centered gekauft">Welches Milka Produkt hast du gekauft?*</h6>
                {this.state.brandError && (
                    <p style={{ color: '#D93333' }}>{this.state.brandError}</p>
                    )}
                <div className="input-container">
                    <div className="products-checkbox">
                        <Checkbox 
                            text="Milka" id="brand-milka"
                            className="brand"
                            required={false}
                            errorMessage={''}
                            onChangeHandler={(event, _ref) => {
                                this.CheckBrand(_ref, 'milka')
                                this.CheckboxChangeHandler(event, _ref)
                            
                            }}
                            ref={(input) => (this._PRODUCT_type_Milka = input)}
                        />
                        <Checkbox 
                         id="brand-oreo"
                         className="brand"
                         required={false}
                         errorMessage={''}
                         text="Oreo"
                         onChangeHandler={(event, _ref) => {
                             this.CheckBrand(_ref, 'oreo')
                             this.CheckboxChangeHandler(event, _ref)
                         }}
                         ref={(input) => (this._PRODUCT_type_Oreo = input)}
                        />
                    </div>
                </div>

                <h6 className="centered ">Wo hast du das Produkt gekauft?*</h6>
                <div className="input-container">
                    <div className="form-input__container form-input__dropdown">
                        <select ref={select => this._PRODUCT_retailer = select} className="form-input__input js-event-type-in js-event-ab"
                            type="text" name="brand"
                            id="brand"
                            placeholder="Arende*"
                            data-require="true"
                            data-type="ddl"
                            data-error-target="#error-salutation"
                            data-required-message="Salutation is required."
                            data-event-fieldname="Salutation"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Salutation"
                            data-label="Type In"
                            onChange={(e) => {
                                // if(     e.target.value.toLowerCase() === "globus" 
                                //     || e.target.value.toLowerCase() === "globus haus halle" 
                                // ){
                                //     this.setState({
                                //         otherCheckbox : "globus"
                                //     });
                                // }else {
                                    this.setState({
                                        otherCheckbox : null
                                    });
                                // }
                            }}>

                            {
                                
                                retailerList.map(retailer => {
                                    let { value, display, limit } = retailer;
                                    if (!!limit) {
                                        let { upper, lower } = limit,
                                            currentDate = new Date(),
                                            lowerLimitDate = new Date(lower.year, lower.month - 1, lower.day - 1),
                                            upperLimitDate = new Date(upper.year, upper.month - 1, upper.day + 1);

                                        if (currentDate > lowerLimitDate && currentDate < upperLimitDate) {
                                            return <option value={value} key={value}>{display}</option>
                                        } else {
                                            return null;
                                        }
                                    }
                                    let selected = false;
                                    let currentKip = localStorage.getItem(_LOCAL.CurrentKip);
                                    if(currentKip && value.toLowerCase() == currentKip.toLowerCase()){
                                        selected = true;
                                    }else if(currentKip != null && currentKip.toLowerCase() == "netto" && value.toLowerCase() == "Netto Marken-Discount / Süd".toLowerCase()){
                                        selected = true;
                                    }
                                    return <option value={value} key={value} selected={selected}>{display}</option>
                                })
                            }
                        </select>
                        <label className="form-input__label" htmlFor="salutation">{Salutations.dropdown["default"]}</label>
                    </div>
                </div>

                <h6 className="centered">Wann hast du das Produkt gekauft?*</h6>
                <div className="input-container">
                    <div className="form-input__container active js-datepicker-dob">
                        <input className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="product-purchase" id="product-purchase"
                            placeholder="TT.MM.JJJJ"
                            autoComplete="off"
                            readOnly
                            data-require="true"
                            data-type="regex"
                            data-error-target="#product-purchase-error"
                            data-regex-pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$"
                            data-required-message="Datum ist erforderlich."
                            data-pattern-message="Um an der Aktion teilnehmen zu können, musst du zwischen dem 11.04.2022 und 05.06.2022 ein Milka Produkt gekauft haben."
                            data-event-fieldname="Date"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Date"
                            data-label="Type In"
                            ref={input => this._PRODUCT_DATE = input}
                            onBlur={(e) => {
                                const dateStr = e.target.value.split(".");
                                const date = new Date(dateStr[2], dateStr[1] - 1, dateStr[0]);
                                
                                if (+date < +this.minPurchaseDate || +date > +this.maxPurchaseDate) {
                                    this.unvalidInput(e.target);
                                } else {
                                    this.validInput(e.target);
                                }
                            }
                            }
                        />
                    </div>
                    <p id="product-purchase-error" className="date-error-participation" style={{ color: "#D93333" }}></p>
                </div>
            </div>

        );
    }

    CodeEntrySection() {
        /** onBlur = { this.ValidateCode.bind(this) } */
        return (
            <div className="campaign-code">
                <h4>Bitte gib deinen Produktcode ein.*</h4>
                <p>
                    Du ﬁndest den Produktcode auf der Packungsrückseite
                </p>
                <div className="campaign-code-container">

                    <picture>
                        <source srcSet="/resources/imagesBundesliga/buzzer/code-notice-big.png" media="(min-width: 1024px)" />
                        <source srcSet="/resources/imagesBundesliga/buzzer/code-notice-big.png" media="(min-width: 481px)" />
                        <source srcSet="/resources/imagesBundesliga/buzzer/code-notice.png" media="(min-width: 200px)" />
                        <img src="/resources/imagesBundesliga/buzzer/code-notice.png" alt="Produktcode" />
                    </picture>
                    <p id="info">Der Produktcode besteht aus Zahlen und Buchstaben. (Hinweis: Befindet sich kein Produktcode auf dem Artikel, so müssen die Zahlen beim Barcode eingegeben werden.)</p>
                    <input className={this.state.CodeIsValid ? "campaign-code-input" : "campaign-code-input notvalid"}
                        type="text"
                        ref={input => this._CODE_input = input}
                    />
                </div>
            </div>
        );
    }

    ConsentSection(props) {
        return (
            <div className="campaign-consent buzzer-consent">
            {!this.state.gameIsLive  && <div className='text-content'>*Mehrfachteilnahmen mit neuem Produktcode innerhalb der Aktionsprodukte sind erlaubt.</div>
             
            }
                <Checkbox id="terms"
                    required={true}
                    errorMessage={_ERROR_MESSAGES.Terms}
                    text='Ich habe die  <a class="js-event-cta" href="/teilnahmebedingungen" target="_blank" rel="noopener noreferrer" title="Teilnahmebedingungen" data-event="buzzer_click_conditions" data-category="Participation Page" data-action="Click on Conditions" data-label="BUZZER_TPP_PART" id="terms-link">Teilnahmebedingungen</a> gelesen und akzeptiert.*'
                    onChangeHandler={(event, _ref)=>{
                        this.CheckboxChangeHandler.bind(event, _ref);
                        this.CheckboxTag(_ref, {
                            dataEvent: "custom_event_click",
                            dataCategory: "form_action",
                            dataAction: "accept_conditions",
                            dataLabel: "terms_and_conditions",
                        })
                    }}
                />
                {
                    this.state.ShowMoreTerms ?
                        <p className="buzzer-tcs">
                            Wir schätzen dein Vertrauen in unseren Umgang mit deinen personenbezogenen Daten. Verantwortliche Stelle für die Verarbeitung deiner personenbezogenen Daten im Rahmen dieses Gewinnspiels ist die Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen (Mondelez Deutschland). Mit der Durchführung des Gewinnspiels sind die folgenden Agenturen beauftragt: Consultix GmbH, Wachtstr. 17–24, 28195 Bremen, Baudek & Schierhorn Shopper Marketing GmbH, Hermann-Maul-Str. 2, 21073 Hamburg und bürozwei GmbH & Co KG, Theodor-Heuss-Allee 21, 28215 Bremen auf einem Server in Deutschland. Die Agenturen unterliegen sämtlichen anwendbaren Datenschutzgesetzen. Sie sind im Rahmen einer Auftragsverarbeitung für uns tätig und an die Anforderungen entsprechender Verträge gebunden. Eine darüber hinausgehende Weitergabe deiner personenbezogenen Daten an Dritte erfolgt nicht.
                            <br />
                            <br />
                            Wir speichern deine personenbezogenen Daten nach Maßgabe der rechtlichen Vorschriften und ausschließlich zum Zweck der Abwicklung dieses Gewinnspiels (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. b) der Datenschutz-Grundverordnung EU 2016/679, DS-GVO). Es werden die E-Mail-Adresse, der Name, Adressdaten und das Geburtsdatum der Teilnehmer erfasst und ausschließlich zur Verifizierung der Teilnahme/Gewinner gespeichert. Du kannst deine Teilnahme an dem Gewinnspiel jederzeit ohne Angabe von Gründen durch Widerruf aufheben. Hierzu genügt eine formlose E-Mail an <a href="mailto:verbraucherservice@mdlz.com">verbraucherservice@mdlz.com</a>.
                            <br />
                            <br />
                            Im Falle eines Widerrufs werden deine personenbezogenen Daten selbstverständlich gelöscht.
                            <br />
                            <br />
                            Soweit keine gesetzlichen Aufbewahrungspflichten bestehen, erfolgt eine umgehende Löschung der Daten, nachdem das Gewinnspiel abgeschlossen ist. Eine umgehende Löschung erfolgt bezüglich der Daten der Nichtgewinner. Gewinnerdaten sind aus gesetzlichen und buchhalterischen Gründen nach Maßgabe der §§ 257 HGB, 147 AO, 14b UStG aufzubewahren. Für diese Speicherung der Gewinnerdaten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. c) DS-GVO, da die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der Mondelez Deutschland unterliegt. Weitere Informationen zu dem Umgang von Mondelez Deutschland mit deinen personenbezogenen Daten und zu deinen gesetzlichen Rechten und dazu, wie du diese ausüben kannst, findest du in unserer <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" data-event="buzzer_data_protection" data-category="Participation Page" data-action="Click on Data Protection" data-label="'BUZZER_TPP_PART" onClick={(event)=> appGoogleTracking.processEventCTANavLink(event)}>Datenschutzerklärung</a>.
                        </p>
                        :
                        <a id="moreInfo" className="buzzer-info" href="#" style={{ textDecoration: "underline" }}  data-event="buzzer_more_information" data-category="Participation Page" data-action="Click on More Information" data-label="'BUZZER_TPP_PART" onClick={(event) => { event.preventDefault(); this.setState({ ShowMoreTerms: true });appGoogleTracking.processEventCTANavLink(event) }}>
                            <strong>{"„Mehr Informationen >>“"}</strong>
                        </a>
                }

                {
                    typeof this.state.otherCheckbox === "string" && this.state.otherCheckbox !== null &&
                    (
                        <Checkbox id="globus-terms"
                            required={false}
                            errorMessage={_ERROR_MESSAGES.Terms}
                            text={props.t(`Page.Participation.form.Terms.${this.state.otherCheckbox}`)}
                            onChangeHandler={this.CheckboxChangeHandler.bind(this)}
                        />
                    )
                }

                {
                    !this.state.LoginForm.IsLoggedIn ?
                        <Checkbox
                        // Don't change id Name of this field. It's linked in the css.
                         id="member"
                            text='Ich möchte kostenlos FC Milka Mitglied werden.'
                            onChangeHandler={(event, _ref)=>{
                                this.CheckboxChangeHandler(event, _ref);
                                this.CheckboxTag(_ref, {
                                    dataEvent: "buzzer_checkbox_become_member_fc-milka",
                                    dataCategory: "Participation Page",
                                    dataAction: "Click on Checkbox",
                                    dataLabel: "ENG_TPP_PART",
                                })
                            }}
                        />
                        : null
                }

                {
                    this.state.member ?
                        <div id="passWordReg">
                            <h4>Bitte gib dein Passwort ein</h4>
                            <div className="row">
                                <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                    <div className="input-container">
                                        <label className="form-input__label" htmlFor="passwordRegister">Passwort*</label>
                                        <input ref={input => this._DETAILS_pswd = input} className="form-input__input js-event-type-in js-event-ab" type="password" name="passwordRegister" id="passwordRegister" placeholder="Passwort*" data-require="true" data-type="regex" data-error-target="#error-password" data-regex-pattern="" data-required-message="Passwort erforderlich" data-pattern-message="" data-event-fieldname="PasswordRegister" data-event="form-click" data-category="Form Action" data-action="PasswordRegister" data-label="Type In" />
                                    </div>
                                </div>
                                <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                                    <div className="input-container">
                                        <label className="form-input__label" htmlFor="confirmPassword">Passwort wiederholen*</label>
                                        <input onChange={this.ConfirmPassword.bind(this)} ref={input => this._DETAILS_confirm_pswd = input} className="form-input__input js-event-type-in js-event-ab" type="password" name="confirmPassword" id="confirmPassword" placeholder="Passwort wiederholen*" data-require="true" data-type="regex" data-error-target="#error-passwordConfirm" data-regex-pattern="" data-required-message="Passwort erforderlich" data-pattern-message="" data-event-fieldname="Password" data-event="form-click" data-category="Form Action" data-action="Password" data-label="Type In" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                <Checkbox id="newsletter"
                    text={props.t('Page.Participation.form.newsletter')}
                    onChangeHandler={(event, _ref)=>{
                        this.CheckboxChangeHandler(event, _ref);
                        this.CheckboxTag(_ref, {
                            dataEvent: "buzzer_checkbox_subscribe_newsletter",
                            dataCategory: "Participation Page",
                            dataAction: "Click on Checkbox",
                            dataLabel: "ENG_TPP_PART",
                        })
                    }}
                />
            </div>
        );
    }

    ConfirmPassword() {
        let password = this._DETAILS_pswd.value,
            confirm = this._DETAILS_confirm_pswd.value;

        if (password.length < 1 || password !== confirm) {
            this._DETAILS_confirm_pswd.classList.add("notvalid")
            return false
        }

        this._DETAILS_confirm_pswd.classList.remove("notvalid")
        return true;
    }

    CheckboxTag = (ref, dataLayerOption) => { 
        let refName = ref.name;
        refName = refName.replace("name-", "");

        if(ref.checked) {
            this.setState({
                [`${refName}Checkbox`]: dataLayerOption
            });
        } else {
            this.setState({
                [`${refName}Checkbox`]: ""
            })
        }
    }
    
    
    /* Analytic tag for term checkbox text */
    CheckboxLinkTag() {
        console.log("CheckboxLinkTag");
        let termsTags = document.querySelectorAll('#terms-link, #terms-link2');
        for (const tag of termsTags) {
            tag.addEventListener("click",
                function (event) {
                    appGoogleTracking.processEventCTANavLink(event);
                }
            );
        }
    }

    CheckboxChangeHandler(event, _ref) {
        if (_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if (_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    /* Analytic tag for term checkbox text */
    CheckboxLinkTag() {
        let termsTags = document.querySelectorAll('#terms-link, #terms-link2');
        for (const tag of termsTags) {
            tag.addEventListener("click",
                function (event) {
                    appGoogleTracking.processEventCTANavLink(event);
                }
            );
        }
    }

    CheckBrand = (ref, value) => {
        if (ref.checked && !this.state.brands.includes(value)) {
            let br = this.state.brands
            br.push(value)
            this.setState({
                brands: br,
            }, () => {
            if(this.state.brands.length > 0){
                this.setState({
                    brandError: ""
                })
            }else{
                this.setState({
                    brandError: "Wähle mindestens eine Marke aus"
                })
            }
            })
        } else if (!ref.checked && this.state.brands.includes(value)) {
            let br = this.state.brands
            br.splice(br.indexOf(value),1)
            this.setState({
                brands: br,
            }, () => {
            if(this.state.brands.length > 0){
                this.setState({
                    brandError: ""
                })
            }else{
                this.setState({
                    brandError: "Wähle mindestens eine Marke aus"
                })
            }
            })
        }
    }

    CheckBoxProductTypeHandler(event, _ref){
        if (_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if (_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
            this.setState({productType: _ref.getAttribute("id")})
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    FavouriteClubSelection(team, name) {
        console.log('NAME' , name)
        this.setState({
            FavouriteTeam: team,
            FavouriteTeamName: name
        })
    }

    FormFiller(UserData) {
        var { salutation, firstname, lastname, street1, street2, zipcode, city, email, birthday, streetnumber, favclub, isFcAccUser, phoneNumber } = UserData;

        if (isFcAccUser) {
            switch (salutation) {
                case "1":
                    salutation = Salutations._MALE
                    break;
                case "2":
                    salutation = Salutations._FEMALE
                    break;
                case "10":
                    salutation = Salutations._OTHERS
                    break;

                default:
                    break;
            }

            this._DETAILS_salutation.value = salutation ? salutation : "default";
            // this._DETAILS_salutation.disabled = true;
            this._DETAILS_email.value = email;
            this._DETAILS_email.readOnly = true;
            this._DETAILS_firstName.value = firstname;
            this._DETAILS_firstName.readOnly = true;
            this._DETAILS_lastName.value = lastname;
            this._DETAILS_lastName.readOnly = true;
            this._DETAILS_street_name.value = street1;
            this._DETAILS_street_number.value = streetnumber;
            this._DETAILS_address.value = street2;
            this._DETAILS_zipCode.value = zipcode;
            this._DETAILS_city.value = city;
            // this._DETAILS_phoneNumber.value = phoneNumber;
            // this._DETAILS_dob.value = birthday.split(" ")[0];

            /*if (!!favclub && favclub.length > 0) {
                this.setState({
                    teamImage: "/resources/imagesBundesliga/team-picture/" + favclub + ".png",
                })

                this.FavouriteClubSelection(favclub);
            }*/

            /*if(this._DETAILS_dob){
                this.FlatpikerDateInit(this._DETAILS_dob, { max: this.maxDOB }, birthday.split(" ")[0]);
            }else{
                let births = birthday.split(" ")[0].split("-");
                if(this._DETAILS_dob_year){
                    this._DETAILS_dob_year.value = births[0];
                    this._DETAILS_dob_month.value = births[1];
                    this._DETAILS_dob_day.value = births[2];
                }
            }*/
        }

        this.setState({
            SimpleLoader: false,
            DisplayLogin: false
        })
    }

    LoginSuccess({ LoginToken, FavouriteClub }) {
        AccountServices.UserDetails({ LoginToken })
            .then(response => {
                const { success, isFcAccUser } = response.data;
                if (success) {
                    var preLoginForm = { ...this.state.LoginForm };
                    preLoginForm.IsLoggedIn = true;
                    console.log('UserDetail : ', response.data);
                    this.FormFiller(response.data);

                    this.setState({
                        LoginForm: preLoginForm,
                        SimpleLoader: false
                    })
                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 	'buzzer_login',
                        'dataCategory': 'Participation Page',
                        'dataAction': 	'Click Login',
                        'dataLabel': 	'ENG_TPP_PART'
                    })
                }
            })
            .catch(error => { })
    }

    LoginError(ErrorMessage, { emailRef, passRef }) {
        switch (ErrorMessage) {
            case AccountModels.ErrorStatus._INVALID_DATA:
                emailRef.classList.add("notvalid")
                passRef.classList.add("notvalid")
                return AccountModels.ErrorStatus._INVALID_DATA

            case AccountModels.ErrorStatus._INVALID_CAPTCHA:
                console.log('Captcha error message');

                //Datalayer push for Error
                this.triggerDatalayers({
                    status: 'error',
                    type: 'general'
                });
                return AccountModels.ErrorStatus._INVALID_CAPTCHA;

            case AccountModels.ErrorStatus._GENERIC_ERROR:
                return AccountModels.ErrorStatus._GENERIC_ERROR;

            case AccountModel.ErrorStatus._INACTIVE:
                return _ERROR_MESSAGES.Account.INACTIVE;

            default:
                break;
        }

        this.setState({
            SimpleLoader: false
        })
    }

    LoginServiceError() {
        this.setState({
            SimpleLoader: false
        })
    }

    calculateAge(birthday) {
        const ageDifMs = Date.now() - birthday;
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    validInput(...list) {
        for (let i = 0; i < list.length; i++) {
            list[i].classList.add('valid');
            list[i].classList.remove('notvalid');
            if (list[i].hasAttribute("data-error-target")) {
                const element = document.querySelector(list[i].getAttribute("data-error-target"));
                if (element) {
                    element.innerHTML = "";
                }
            }
        }
    }

    unvalidInput(...list) {
        for (let i = 0; i < list.length; i++) {
            list[i].classList.add('notvalid');
            list[i].classList.remove('valid');

            if (list[i].hasAttribute("data-error-target")) {
                const element = document.querySelector(list[i].getAttribute("data-error-target"));
                if (element) {
                    element.innerHTML = list[i].getAttribute("data-pattern-message");
                }
            }
        }
    }

    checkMinimumAge(e, regex) {
        if (!e.target.value.match(regex)) {
            this.unvalidInput(e.target);
            return;
        } else {
            this.validInput(e.target);
        }

        let birthday = null;
        let inputs = [];

        if (this._DETAILS_dob) {
            let date = this._DETAILS_dob.value.split(".");
            birthday = new Date(date[2], date[1] - 1, date[0]);
            inputs = [e.target];
        } else {
            const day = document.querySelector("input#day"),
                month = document.querySelector("input#month"),
                year = document.querySelector("input#year");
            inputs = [day, month, year];
            const dayValue = day.value,
                monthValue = month.value,
                yearValue = year.value;
            if (dayValue && monthValue && yearValue) {
                birthday = new Date(yearValue, monthValue - 1, dayValue);
                day.value = birthday.getDate() < 10 ? "0" + birthday.getDate() : birthday.getDate();
                month.value = birthday.getMonth() + 1 < 10 ? "0" + (birthday.getMonth() + 1) : birthday.getMonth() + 1;
                year.value = birthday.getFullYear();
            }
        }

        if (birthday != null) {
            const age = this.calculateAge(birthday);
            if (age < 18) {
                this.unvalidInput(...inputs);
            } else {
                this.validInput(...inputs);
            }
        }
    }

    redirectToSelfieGame(UpdateParticipationStatus, UpdateParticipationData) {
        UpdateParticipationStatus();
        UpdateParticipationData(null);
    }

    IsFavouriteTeamValid(){
        const favoriteTeamError = document.getElementById("favorite-team-error");
        if(this.state.FavouriteTeam == ""){
            favoriteTeamError.style.display = "block";
            favoriteTeamError.classList.add("notvalid");
            return false;
        }
        favoriteTeamError.style.display = "none";
        favoriteTeamError.classList.remove("notvalid");
        return true;
    }

    SubmitHandler(event) {
        event.preventDefault();

        const isValid = ValidateForm.validateForm('frm_participation'),
            isAccepted = this.state.terms,
            isFavouriteTeamValid = this.IsFavouriteTeamValid(),
            passwordValid = this.state.member ? this.ConfirmPassword() : true,
            hasSelectedBrand = this.state.brands.length > 0,
            { UpdateParticipationStatus, UpdateParticipationData } = this.props;
            if (!hasSelectedBrand) {
                this.setState({
                    brandError: 'Wähle mindestens eine Marke aus',
                })
            }
        if (isValid
            && passwordValid 
            && isFavouriteTeamValid) {
            // this.TriggerCaptcha();
            this.ProcessForm(null);
        } else {
            /**
             * Scroll To first error
             */
            let firstError = this._FORM_DETAILS.querySelector(".notvalid");

            if(window.FILE_STATUS === false){
                firstError = this._FORM_DETAILS.querySelector(".campaign-form-receipt");
            } 
            
            if (!!firstError) {
                const yOffset = - 110,
                    y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            } 
        }
    }

    ProcessForm(token) {
        let salutation = this._DETAILS_salutation.value,
            { UpdateParticipationStatus, UpdateParticipationData } = this.props;

        switch (salutation) {
            case "Herr":
                salutation = "1";
                break;
            case "Frau":
                salutation = "2";
                break;
            case "Divers":
                salutation = "10";
                break;

            default:
                break;
        }
        
        //Process form data
        let _data = {
            // Receipt: this._RECEIPT_file.files[0],
            salutation: salutation,
            firstname: this._DETAILS_firstName.value,
            lastname: this._DETAILS_lastName.value,
            email: this._DETAILS_email.value,
            birthday: "1999-03-20",
            street1: this._DETAILS_street_name.value,
            streetnumber: this._DETAILS_street_number.value,
            addressAdditionalInfo: this._DETAILS_address.value,
            zipcode: this._DETAILS_zipCode.value,
            city: this._DETAILS_city.value,
            favouriteTeam: this.state.FavouriteTeam,
            newsletter: this.state.newsletter ? "1" : "0",
            retailer : this._PRODUCT_retailer.value,
            brand: this.state.brands.join(", "),
            purchaseDate : ServerDate(this._PRODUCT_DATE.value),
            captcha: token,
            referrer : "Website",
            register: this.state.member ? "1" : "0",
            password: !!this._DETAILS_pswd ? this._DETAILS_pswd.value : "",
            islogin: this.state.LoginForm.IsLoggedIn ? "1" : "0",
            kip : this.state.currentKip,
            // phoneNumber:  this._DETAILS_phoneNumber.value,
        };
        const globusTerms = document.getElementById("globus-terms");
        if(!!globusTerms){
            _data.parentApproval = globusTerms.checked;
        }
        this.setState({
            SimpleLoader: true
        })
        this._CAPTCHA.reset();

        let _Data = new FormData();

        for(let key in _data){
            _Data.append(key, _data[key]);
        }

        let formTags = [
            {'dataEvent': 'buzzer_product_select', 'dataCategory':'Participation Page', 'dataAction': 'Select Product', 'dataLabel': this.state.brands.join(", ")},
            {'dataEvent': 'buzzer_product_location_select', 'dataCategory':'Participation Page', 'dataAction': 'Select Location', 'dataLabel': this._PRODUCT_retailer.value},
            {'dataEvent': 'buzzer_product_date_select', 'dataCategory':'Participation Page', 'dataAction': 'Select Date', 'dataLabel': ServerDate(this._PRODUCT_DATE.value)},
            {'dataEvent': 'buzzer_team_select', 'dataCategory':'Participation Page', 'dataAction': 'Click On Team', 'dataLabel': this.state.FavouriteTeam},
            JSON.stringify(this.state.termsCheckbox),
            JSON.stringify(this.state.memberCheckbox),
            JSON.stringify(this.state.newsletterCheckbox),
            {'dataEvent': 'buzzer_submit_participation', 'dataCategory':'Participation Page', 'dataAction': 'Click Submit', 'dataLabel': 'BUZZER_TPP_PART'}
        ]
        console.log("formTagValue:",formTags)
        const tagItems = () => {
            formTags.map(
                (formTag) => {
                    return appGoogleTracking.dataLayerPush(formTag);
                }
                           
            )
        }
        console.log("Form Data : ", _Data);
        
        let formData = {};
        _Data.forEach(function(value, key){
            formData[key] = value;
        });
        let objectParticipation = JSON.stringify(formData);
        /* Analytics tags */
        tagItems();
        if(this.state.gameIsLive){
            localStorage.setItem(_LOCAL.BuzzerParticipationDetails, objectParticipation);
            this.props.history.push(GLOBAL_CONFIG.Route.buzzer.game);
        }else{
            // ------------------------- Send API here ----------------------------
            this.setState({
                SimpleLoader: true
            })
            let productCodeData = localStorage.getItem(_LOCAL.ProductCode);
            formData.productCode = productCodeData;

            ParticipationService.ParticipateWeltmeister(formData)
                .then((response) => {
                    let { status, success, data } = response.data;
                    this.setState({
                        SimpleLoader: false
                    })
                    console.log("response data", response.data)

                    if (success) {
                        /**
                         * Sucess handler
                        */
                        this.props.history.push(GLOBAL_CONFIG.Route.buzzer.closing);

                    } else {
                        if (data.Error.includes(_BUZZER_MESSAGE.PARTICIPATION_LIMIT)) {
                            var _message = "Du kannst max. 5x am Tag teilnehmen"
                            this.setState({
                                ErrorMessage: _message
                            })

                        }
                        if (!!data.Error && data.Error.length > 0) {
                            this.ErrorHandler(data.Error[0]);
                        } else {
                            this.setState({
                                ErrorMessage: "SERVER ERROR"
                            })
                        }
                    }
                })
                .catch(() => {
                    this.setState({
                        SimpleLoader: false
                    })
                })
        }
        // ParticipationService.ValidateCode(_Data)
        //     .then((response) => {
        //         let { status, success, data } = response.data;
        //         this.setState({
        //             SimpleLoader: false
        //         })
        //         console.log("response data",response.data)
        //         if (success) {
        //             /**
        //              * Sucess handler
        //              */
        //             localStorage.setItem(_LOCAL.Register, _data.register)
        //             if (typeof UpdateParticipationData === "function") {
        //                 UpdateParticipationData({...data, ..._data});
        //             }

        //             if (typeof UpdateParticipationStatus === "function") {
        //                 UpdateParticipationStatus();
        //             }
        //             
        //         } else {
        //             if (!!data.Error && data.Error.length > 0) {
        //                 this.ErrorHandler(data.Error[0]);
        //             } else {
        //                 this.setState({
        //                     ErrorMessage: "SERVER ERROR"
        //                 })
        //             }
        //         }
        //     })
        //     .catch(() => {
        //         this.setState({
        //             SimpleLoader: false
        //         })
        //     })
    }

    ErrorHandler(Message) {
        switch (Message) {
            case _BUZZER_MESSAGE.PARTICIPATION_LIMIT:
                this.setState({
                    ErrorMessage: _ERROR_MESSAGES.Buzzer.CODE_LIMIT_GAMENOTALIVE
                })
                break;
            case _BUZZER_MESSAGE.CODE_INVALID:
                this.setState({
                    CodeIsValid: false,
                    ErrorMessage: _ERROR_MESSAGES.Buzzer.INVALID_CODE
                })
                break;
            case _BUZZER_MESSAGE.INVALID_CAPTCHA:
                this.setState({
                    ErrorMessage: Message
                })
                break;
            case _BUZZER_MESSAGE.REGISTRATION_FAILED:
                this.setState({
                    ErrorMessage: Message
                })
                break;

            default:
                this.setState({
                    ErrorMessage: "SERVER ERROR"
                })
                break;
        }
    }

    OnCaptchaResolved(token) {
        this.setState({
            SimpleLoader: true
        })
        this.ProcessForm(token);
    }

    OnCaptchaError() {
        this._CAPTCHA.reset();
        this.setState({
            SimpleLoader: false
        })
        this.setState({
            ErrorMessage: "CAPTCHA ERROR"
        })
    }

    OnCaptchaExpired() {
        this._CAPTCHA.reset();
        this.setState({
            SimpleLoader: false
        })
        this.setState({
            ErrorMessage: "CAPTCHA EXPIRED" //ABGELAUFEN
        })
    }

    TriggerCaptcha() {
        this._CAPTCHA.execute();
    }

    FlatpikerDateInit(_REF, { max, min }, _def = null) {
        flatpickr(_REF, {
            dateFormat: "d.m.Y",
            maxDate: max,
            minDate: min,
            disableMobile: true,
            defaultDate: !!_def ? new Date(_def) : null
        });
    }
}

export default withTranslation()(withRouter(BuzzerParticipation));