import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class GridQuote extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="GridFairplay">
        <div className="GridFairplay__wrapper">
          <div className="GridFairplay__itemwrapper GridFairplay__itemwrapper--text">
            <div className="GridFairplay__contentText">
              <div className="GridFairplay__text">
                <i className="GridFairplay__corner-icon-left icon-quote-left"></i>
                <div>
                  Fair Play bedeutet, den Gegner unabhängig von Sieg oder
                  Niederlage immer mit Respekt zu behandeln.
                </div>
                <i className="GridFairplay__corner-icon-right icon-quote-right"></i>
              </div>
              <div className="GridFairplay__name">“DANNY DA COSTA”</div>
            </div>
          </div>
          <div className="GridFairplay__itemwrapper">
            <div className="GridFairplay__contentImage">
              <picture>
                <source srcSet="/resources/imagesBundesliga/fairplay/Lahm-Handshake-1-920x920.jpg" />
                <img
                  src="/resources/imagesBundesliga/fairplay/Lahm-Handshake-1-920x920.jpg"
                  alt=""
                  className="GridFairplay__image"
                />
              </picture>
            </div>
          </div>
          <div className="GridFairplay__itemwrapper GridFairplay__itemwrapper--text">
            <div className="GridFairplay__contentText">
              <div className="GridFairplay__text">
                <i className="GridFairplay__corner-icon-left icon-quote-left"></i>
                <div>Fair Play ist die Basis unseres Sports.</div>
                <i className="GridFairplay__corner-icon-right icon-quote-right"></i>
              </div>
              <div className="GridFairplay__name">“JOSHUA KIMMICH”</div>
            </div>
          </div>
          <div className="GridFairplay__itemwrapper">
            <div className="GridFairplay__contentImage">
              <picture>
                <source srcSet="/resources/imagesBundesliga/fairplay/2021_MD34_TSGBSC_LS_0278-920x920.jpg" />
                <img
                  src="/resources/imagesBundesliga/fairplay/2021_MD34_TSGBSC_LS_0278-920x920.jpg"
                  alt=""
                  className="GridFairplay__image"
                />
              </picture>
            </div>
          </div>
          <div className="GridFairplay__itemwrapper GridFairplay__itemwrapper--text">
            <div className="GridFairplay__contentText">
              <div className="GridFairplay__text">
                <i className="GridFairplay__corner-icon-left icon-quote-left"></i>
                <div>
                  Fair Play ist das, worum es im Sport geht und wenn man nicht
                  fair handelt, ist der Erfolg nichts wert.
                </div>
                <i className="GridFairplay__corner-icon-right icon-quote-right"></i>
              </div>
              <div className="GridFairplay__name">“PHILIPP LAHM”</div>
            </div>
          </div>
          <div className="GridFairplay__itemwrapper">
            <div className="GridFairplay__contentImage">
              <picture>
                <source srcSet="/resources/imagesBundesliga/fairplay/2122_MD01_BMGFCB_LS_0186-920x920.jpg" />
                <img
                  src="/resources/imagesBundesliga/fairplay/2122_MD01_BMGFCB_LS_0186-920x920.jpg"
                  alt=""
                  className="GridFairplay__image"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(GridQuote);
