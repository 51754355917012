import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import SliderOverview from "../../../components/Content/SliderOverview/SliderOverview";
class InstaPage extends Component {
    constructor(props) {
        super(props);

        /* this.handleResize = this.handleResize.bind(this); */
    }

    componentDidMount() {
        mainLoaderToggle("hide");
        let hostName = window.location.hostname,
            isProdWelmeister = hostName.toLowerCase().indexOf("weltmeister.fcmilka") > -1 ? true : false,
            isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false,
            baseNamePath = "";

        if (isProdWelmeister) {
            baseNamePath = "";
        } else if (isProd) {
            baseNamePath = "https://weltmeister.fcmilka.de";
        } else {
            baseNamePath = "/weltmeister";
        }
    }

    componentWillUnmount() {
        mainLoaderToggle("show");
    }

    
    render() {
        return (
            <div>
                <iframe src="https://insta-awoh.onrender.com/" width="100%" height="1000px"></iframe>
            </div>
        );
    }

    
}
export default withTranslation()(InstaPage);
