import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import { withTranslation } from "react-i18next";
import BuzzerSlider from "./Slider";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import StepContent from "../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../config/config";
import KIPServices from "../../../Service/KIP_Pages";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import ParticipationNavigation from "./ParticipationNavigation";
import GiftContentFooter from "../../../components/Content/GiftContent/GiftContentFooter";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import _LOCAL from "../../../Models/LocalStorageKeys";


class BuzzerPenny extends React.Component {
    componentWillMount() {
        if(this.props.isQ3){
            window.location = "https://fcmilka.de/penny";
        }
        this.LoadCampaign();
        localStorage.setItem(_LOCAL.CurrentKip, "Penny");
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-netto kaufland penny">
                {this.headingSection(this.props)}
                {this.cardSection()}
                {this.footerSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection(props) {
        return (
            <div className="content-home endpage-rewe">
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        <div className="title-container">
                            <h2>
                                Sichere dir jetzt deine doppelte Gewinnchance mit Milka bei der <br/> <img src="/resources/imagesBundesliga/buzzer/penny/logo.png" alt="Kaufland Logo" className="tablet-up"/> Zusatzverlosung
                            </h2>
                            <h4>
                                Im Aktionszeitraum vom 11.04.2022 bis 05.06.2022 ein Produkt von Milka in deinem Penny Markt kaufen, Kaufbeleg bis zum 05.06.2022 auf <a href="/mitmachen" ><strong>www.gold.fcmilka.de/mitmachen</strong></a> hochladen und mit etwas Glück meisterhafte Bundesliga Preise gewinnen.
                            </h4>
                            <h4>
                                Deine Chance auf den doppelten Gewinn: Mit der Teilnahme an der Penny Zusatzverlosung nimmst du automatisch am Gewinnspiel „Jetzt Meister werden!“ teil und hast darüber hinaus die Chance auf zusätzliche Gewinne. 
                            </h4>
                        </div>
                        <div className="step-container">
                            <h3 className="step-title">
                                Mitmachen ist ganz einfach:
                            </h3>

                            <StepContent
                                step1 = { props.t('Page.Home.Steps.Buzzer-Main-Penny.1') }
                                step1sm = { props.t('Page.Home.Steps.Buzzer-Main-Penny.1') }
                                step1Text = {parse("Kaufe zwischen dem <span class=\"text--wheat\">11.04.2022</span> und dem <span class=\"text--wheat\">05.06.2022</span> ein Milka Produkt. <strong>(Bitte den gut lesbaren Kaufbeleg aufbewahren.)</strong>")}
                                step_arrow = "/resources/imagesBundesliga/Edeka/icons/arrow_right.png"
                                step2 = { props.t('Page.Home.Steps.Buzzer-Main-Penny.2') }
                                step2sm = { props.t('Page.Home.Steps.Buzzer-Main-Penny.2') }
                                step2Text = {parse("Lade <a href=\"/mitmachen\">hier</a> bis zum <span class=\"text--wheat\">05.06.2022</span> den <span class=\"text--wheat\">Kaufbeleg</span> hoch. (Ein Kaufbeleg pro Teilnahme)")}
                                step3 = { props.t('Page.Home.Steps.Buzzer-Main-Penny.3') }
                                step3sm = { props.t('Page.Home.Steps.Buzzer-Main-Penny.3') }
                                step3Text = {parse("Schon bist du im Gewinner-Lostopf! Nach Ablauf des Gewinnspiels am <span class=\"text--wheat\">05.06.2022</span> teilen wir dir <span class=\"text--wheat\">per E-Mail</span> mit, ob du gewonnen hast.")}
                                step4 = { props.t('Page.Home.Steps.Buzzer-Main-Penny.4') }
                                step4sm = { props.t('Page.Home.Steps.Buzzer-Main-Penny.4') }
                                step4Text = { parse("Zusätzlich sicherst du dir über die Teilnahme am FC Milka Gewinnspiel „Jetzt Meister werden!“ die Chance auf eine Dauerkarte für die Saison 2022/23 der <span style='white-space: nowrap; font-weight: normal;'>Bundesliga / 2. Bundesliga</span> oder Gold im Wert von 25.000 €.") }
                            />

                            <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                                <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                            </NavLink>
                            <p className="step-container--terms-link">
                                Hier findest du die  <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a> für unser Gewinnspiel.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    cardSection() {
        return (
            <div className="card-container">
                <div className="card-container__card">
                    <div className="card-container__card__primary">
                        <picture>
                            <source srcSet="/resources/imagesBundesliga/buzzer_22/penny/card/supercup-sm.png" media="(max-width: 480px)" />
                            <img src="/resources/imagesBundesliga/buzzer_22/penny/card/supercup.png" alt="Gold im Wert"/>
                        </picture>
                        <h3>100x 2 Tickets (CAT2) <br/> Saison 2022/23</h3>
                        <p>
                        Gewinne 1x 2 Tickets (Cat2) für ein Spiel der Bundesliga oder 2. Bundesliga für die Saison 2022/23 des Clubs der Wahl (Werte variieren je nach Club, Auswahl nach Verfügbarkeit über Milka Fan-Center, es besteht kein Anspruch auf Clubauswahl).
                        </p>
                    </div>
                    <div className="card-container__card__secondary">
                        <img src="/resources/imagesBundesliga/buzzer_22/penny/card/ball.png" alt="Dauerkarten der Saison"/>
                        <h3>Gewinne deinen Bundesliga Ball von DERBYSTAR</h3>
                        <p>Mit etwas Glück sicherst du dir einen von 100 Bundesliga Bällen Modell Bundesliga Brillant Replica, Saison 21/22 von DERBYSTAR.</p>
                    </div>
                </div>
            </div>
        );
    }


    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <h2>
                    Sichere dir jetzt deine doppelte Gewinnchance
                </h2>
                <h3>
                    Neben den 100x 2 Tickets (CAT2) für die Bundesliga / 2. Bundesliga der Saison 22/23 und 100x 1 Bundesliga Ball von DERBYSTAR warten beim FC Milka Gewinnspiel „Jetzt Meister werden!“ viele weitere tolle Fußball-Preise auf dich: 1x Gold im Wert von 25.000 € oder 120x 1 Dauerkarte der Bundesliga / 2. Bundesliga.
                </h3>

                <NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>
                    <button className="btn__container btn--secondary-lilac"> JETZT MITMACHEN </button>
                </NavLink>
                <p className="terms-link">
                    Hier findest du die <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzbestimmungen</a>.
                </p>
                <p className="terms-faq">
                    Noch Fragen zur Aktion? Hier geht es zu den <a href={GLOBAL_CONFIG.Route.buzzer.faq}>FAQ</a>.
                </p>
                <h3 className="terms-title">
                    Teilnahmebedingungen
                </h3>
                <h4 className="terms-subtitle">
                    Ergänzend zu den vollständigen <a href={GLOBAL_CONFIG.Route.terms}>Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzhinweisen</a> zu dem Gewinnspiel „Jetzt Meister werden!“ gelten für die Zusatzverlosung bei Penny die folgenden Teilnahmebedingungen:
                </h4>
                <p className="instruction">
                    <strong>Veranstalter:</strong> Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen. Teilnahmeberechtigt: Natürliche Personen ab 18 Jahren mit Wohnsitz in Deutschland. Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen sowie jeweils deren Angehörige. Teilnahme durch Kauf eines Milka-Produkts im Zeitraum vom 11.04.-05.06.2022 sowie Online Registrierung und Hochladen eines Fotos des gut lesbaren, automatisch erstellten, originalen Kaufbelegs (Kaufbeleg inkl. Einkaufsdatum, -uhrzeit und Belegsumme) bis zum 05.06.2022, Angabe des Milka Produkts, des Händlers / Kaufdatums auf www.gold.fcmilka.de/penny. Nicht teilnehmende Produkte unkenntlich machen. Kaufbeleg zur späteren Prüfung aufbewahren. Pro Kaufbeleg nur eine Teilnahme. Max. ein Gewinn pro Person. Gewinnermittlung der Zusatzverlosung bei Penny durch Ziehung aus allen gültigen Teilnahmen am 07.06.2022. Die Gewinnbenachrichtigung erfolgt per E-Mail an die bei der Registrierung angegebenen E-Mail-Adresse innerhalb einer Woche nach Ende des Teilnahmezeitraums.
                </p>
                <p className="instruction">
                    <strong>Gewinne:</strong> 100x 2 Tickets (CAT2) Saison 2022/23 für ein Spiel der Bundesliga oder 2. Bundesliga des Clubs der Wahl (Werte variieren je nach Club, Auswahl nach Verfügbarkeit über Milka-Fan-Center), 100x Bundesliga Ball Modell Bundesliga Brillant Replica, Saison 21/22 von DERBYSTAR.
                </p>
                <p className="instruction">
                    <strong>Tickets:</strong> Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga.
                </p>
                <p className="instruction">
                    Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID 19 Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein.
                </p>
                <p className="instruction">
                    Maximal ein Hauptgewinn pro Person. Rechtsweg und Barauszahlung ausgeschlossen.    
                </p>
                <p className="instruction">
                    <strong>Sonstiges:</strong> Im Übrigen gelten die Teilnahmebedingungen des Gewinnspiels „Jetzt Meister werden“: Weitere Informationen, vollständige Teilnahmebedingungen und Datenschutzhinweise unter 
                </p>
                <p className="instruction">
                <a href={GLOBAL_CONFIG.Route.terms}>https://www.gold.fcmilka.de/teilnahmebedingungen</a>. 
                </p>
            </div>
        )
    }

    LoadCampaign() {
        KIPServices.Penny()
        .then( response => {
            var { status, success, message, placeholder } = response.data;

            if(success) {
                switch (status) {
                    case _STATUS_CAMPAIGN.holding:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.holdingPenny
                        });
                        break;
                    case _STATUS_CAMPAIGN.closed:
                        this.props.history.push({
                            pathname: GLOBAL_CONFIG.Route.buzzer.endPenny
                        });
                        break;
                
                    default:
                        mainLoaderToggle('hide');
                        break;
                }
            }
        })
        .catch( () => {
            mainLoaderToggle('hide');
        })
        .finally( () => {
            /*const introPage = document.querySelector(".content-home");
            if(introPage){
                _SCROLL_PAGE.ScrollSection(introPage);
            }else{
                _SCROLL_PAGE.ScrollToBanner();
            }*/

            _SCROLL_PAGE.ScrollToTop();
        })
    }

}

export default withTranslation()(BuzzerPenny);