import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import appGoogleTracking from "../../../modules/googletracking";
import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper React components
import Video from "./Video";

// install Swiper components
SwiperCore.use([Navigation, Pagination, EffectFade]);

class SliderVideo extends Component {
  render() {
    const { t } = this.props;

    let sliderObj = t("Page.Fairplay.slider", { returnObjects: true });

    let swiperSlides = sliderObj.map((obj) => {
      return (
        <SwiperSlide key={obj.index}>
          <div className="Slider__wrapper">
            <div className="Slider__left">
              <div className="Slider__left__wrapper">
                <Video
                  imageMobile={obj.imageMobile}
                  imageDesktop={obj.imageDesktop}
                  imageDefault={obj.imageDefault}
                  videoLink={obj.videoLink}
                />
              </div>
            </div>
            <div className="Slider__right">
              <div className="Slider__excerpt">
                <div dangerouslySetInnerHTML={{ __html: obj.text }} />
                <div dangerouslySetInnerHTML={{ __html: obj.header }} />
              </div>
            </div>
          </div>
        </SwiperSlide>
      );
    });

    return (
      <div className="fairplay-slider-wrapper">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: false }}
          effect="flip"
          onSlideNextTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: "info-click",
              dataCategory: "Informational Action",
              dataAction: "Carousel Click",
              dataLabel: "Right",
            });
          }}
          onSlidePrevTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: "info-click",
              dataCategory: "Informational Action",
              dataAction: "Carousel Click",
              dataLabel: "Left",
            });
          }}
        >
          {swiperSlides}
        </Swiper>
      </div>
    );
  }
}

export default withTranslation()(SliderVideo);
