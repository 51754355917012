import React, { Component } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';               //Routing component - to display the corresponding containers
import GLOBAL_CONFIG from '../../../config/config';		        //Global Settings that contains URL etc
import Aux from '../../../hoc/Auxiliare';                          //Vitual container 
import MetaHeader from '../../../components/Content/MetaHeader/MetaHeader';
import Header from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import Banner from '../../../components/Layout/Banner';
import End from '../../End/End';                               //End Campaign page content
import Error from '../../Error/Error';                         //Error page content  
import Cookie from '../../Cookie/Cookie';                      //Cookie page content
import Account from '../../Account/Account';
import Register from '../../Register/Register';
import LoginDetail from '../../LoginDetails/LoginDetails'
import ConfirmationAccount from '../../ConfirmationAccount/ConfirmationAccount';
import ResetPassword from '../../ResetPassword/ResetPassword';
import Annoncememt from '../../Annoncement/Annoncememt';
import ChangePassword from '../../ChangePassword/ChangePassword';
import Addresses from '../../Addresses/Addresses';
import AccountInformation from '../../AccountInformation/AccountInformation';
import PasswordChangeEmail from '../../PasswordChangeEmail/PasswordChangeEmail';
import RegisterDOI from '../../RegisterDOI/RegisterDOI';
import FootballGame from '../../Game/FootballGame';
import Fairplay from '../../Fairplay/Fairplay';
import Q4Holding from '../../Campaign/Weltmeister/Q4Holding';
import Q4Main from '../../Campaign/Weltmeister/Q4Main';
import Q4Prize from '../../Campaign/Weltmeister/Q4Prize';
import Q4Participation from '../../Campaign/Weltmeister/Q4Participation';
import BuzzerIntroPage from '../../Campaign/Buzzer/Intro';
import Q4Confirmation from '../../Campaign/Weltmeister/Q4Confirmation';
import Q4Faq from '../../Campaign/Weltmeister/Q4Faq';
import Q4Product from '../../Campaign/Weltmeister/Q4Product';
import BuzzerClosing from '../../Campaign/Buzzer/Closing';
import Q4Terms from '../../Campaign/Weltmeister/Q4Terms';
import Q4End from '../../Campaign/Weltmeister/Q4End';
import FancenterTerms from '../../Campaign/Buzzer/FancenterTerms';
import _STATUS from '../../../Models/ParticipationSteps';
import _STATUS_CAMPAIGN from '../../../Models/CampaignStatus';
import SupercupGlobus from '../../Campaign/Supercup/Globus/Main';
import SupercupEndGlobus from '../../Campaign/Supercup/Globus/End';
import SupercupConfimrationGlobus from '../../Campaign/Supercup/Globus/Confirmation';
import SupercupParticipationGlobus from '../../Campaign/Supercup/Globus/Participation';
import SupercupFAQGlobus from '../../Campaign/Supercup/Globus/Faq';
import JerseyMain from '../../FanCenter/ChooseJersey/Main';
import UserCodeEntry from '../../FanCenter/ChooseJersey/CodeEntry/User';
import FancenterScenario from '../../FanCenter/ChooseJersey/GiftChoice';
import UserDetails from '../../FanCenter/ChooseJersey/UserDetails/User';
import CompanionUserDetails from '../../FanCenter/ChooseJersey/UserDetails/Companion';
import JerseyConfirmation from '../../FanCenter/ChooseJersey/Confirmation/Jersey';
import JerseySizeSelection from '../../FanCenter/ChooseJersey/Jersey/SizeSelection';
import JerseyTeamSelection from '../../FanCenter/ChooseJersey/Jersey/TeamSelection';
import JerseyUserDetails from '../../FanCenter/ChooseJersey/Jersey/UserDetails';
import CompanionConfirmation from '../../FanCenter/ChooseJersey/Confirmation/TicketFriend';
import UserConfirmation from '../../FanCenter/ChooseJersey/Confirmation/TicketUser';
import TicketTeamSelection from '../../FanCenter/ChooseJersey/Ticket/TeamSelection';
import KIPServices from '../../../Service/KIP_Pages';
import _SCROLL_PAGE from '../../../modules/ScrollPage';
import ProductCodeVerification from '../../ProductCodeVerification/ProductCodeVerification';
import NoProductCode from '../../ProductCodeVerification/NoProductCode';
import Product from '../../Product/Product';
import NoParticipation from '../../NoParticipation/NoParticipation';
import { mainLoaderToggle, isGlobusMicrosite } from '../../../modules/helpers';
import TicketsRoutes from '../fancenter/TicketsRoutes';
import { connect } from "react-redux";
import { setIsBannerShown   } from "../../../store/banner";
import KipsRoutes from '../kip/KipsRoutes';
import Q4Selection from '../../Campaign/Weltmeister/Q4Selection';
import getPageRouting from '../PagesRouting';

class LayoutQ4 extends Component {

    constructor() {
        super();

        this.state = {
            ParticipationStatus: _STATUS.noParticipation,
            FancenterStatus: _STATUS.Fancenter.noCodeEntry,
            ParticipationData: JSON.stringify({}),
        }

        this._LOCAL = {
            status: "__STAT",
            statusFan: "__STAT_FAN",
            data: "__DAT"
        }

        this.ParticipationUpdate = this.ParticipationUpdate.bind(this);
        this.SetParticipation = this.SetParticipation.bind(this);
        this.loadGlobusCampaign = this.loadGlobusCampaign.bind(this);
    }

    componentWillMount() {
        this.SetParticipation();
        this.SetFancenter();
    }

    loadGlobusCampaign(isMain){
        KIPServices.MicroGlobus()
        .then( response => {
            var { status, success, message, placeholder } = response.data;

            if(success) {
                switch (status) {
                    case _STATUS_CAMPAIGN.closed:
                        if(isMain){
                            this.props.history.push({
                                pathname: GLOBAL_CONFIG.Route.globusMicroSite.end
                            });
                        }
                        break;
                
                    default:
                        if(!isMain){
                            this.props.history.push({
                                pathname: GLOBAL_CONFIG.Route.globusMicroSite.main
                            });
                        }
                        break;
                }
            }
        })
        .catch( () => {

        })
        .finally( () => {
            _SCROLL_PAGE.ScrollToTop();
            mainLoaderToggle('hide');
        })
    }

    render() {
        let RouteRender = null,
            NavContent = null,
            { history } = this.props;
        
        this.props.setIsBannerShown(true);

        this.pagesRouting = getPageRouting({context: this, props: {isQ4: true, CampaignStatus: this.props.CampaignStatus}});

        if (this.props.campaign === true) {

            if (this.props.campaignStatus === 'main') {
                RouteRender = (
                    <Switch>
                        <Route path={GLOBAL_CONFIG.Route.home} exact component={()=> <Q4Main />} />
                        {
                            this.pagesRouting.map(( page, index) => (
                                <Route key={`page-${index}`} path={page.path} exact component={page.Component} />
                            ))
                        }
                        
                        <Route path={GLOBAL_CONFIG.Route.intropage} exact component={BuzzerIntroPage} />
                        { /**<Route path = {GLOBAL_CONFIG.Route.intropageHolding} exact component={BuzzerIntroHolding}/>*/}
                        <Route path={GLOBAL_CONFIG.Route.participation} exact
                            component={() => {
                                return (
                                    <Q4Participation
                                        UpdateParticipationData={this.ParticipationDataUpdate.bind(this)}
                                        UpdateParticipationStatus={() => {
                                            this.ParticipationUpdate(_STATUS.participated);
                                            history.push(GLOBAL_CONFIG.Route.buzzer.confirmation)
                                        }}
                                    />
                                );
                            }}
                        />
                        <Route path={GLOBAL_CONFIG.Route.footballGame} exact component={FootballGame} />
                        <Route path={GLOBAL_CONFIG.Route.fairplay} exact component={Fairplay} />
                        <Route path={GLOBAL_CONFIG.Route.terms} exact component={Q4Terms} />
                        <Route path={GLOBAL_CONFIG.Route.fancenterTerms} exact component={FancenterTerms} />
                        <Route path={GLOBAL_CONFIG.Route.cookie} exact component={Cookie} />

                        {/** Q4 Routes */}
                        <Route path={GLOBAL_CONFIG.Route.buzzer.holding} exact component={Q4Holding} />
                        <Route path={GLOBAL_CONFIG.Route.buzzer.main} exact component={Q4Main} />
                        <Route path={GLOBAL_CONFIG.Route.buzzer.closing} exact component={BuzzerClosing} />
                        {
                            this.state.ParticipationStatus === _STATUS.participated ?
                                <Route path={GLOBAL_CONFIG.Route.buzzer.confirmation} exact
                                    component={() => {
                                        return (
                                            <Q4Confirmation
                                                ParticipationData={JSON.parse(this.state.ParticipationData)}
                                                UpdateParticipationStatus={() => {
                                                    this.ParticipationUpdate(_STATUS.noParticipation);
                                                    window.localStorage.removeItem(this._LOCAL.data);
                                                }}
                                            />
                                        );
                                    }}
                                />
                                : null
                        }

                        <Route path={GLOBAL_CONFIG.Route.buzzer.prize} exact component={Q4Prize} />
                        <Route path={GLOBAL_CONFIG.Route.buzzer.intro} exact component={BuzzerIntroPage} />
                        <Route path={GLOBAL_CONFIG.Route.buzzer.faq} exact component={Q4Faq} />
                        <Route path={GLOBAL_CONFIG.Route.ProductCodeVerification} exact component={() => (
                            <ProductCodeVerification 
                                UpdateParticipationData={this.ParticipationDataUpdate.bind(this)}
                                UpdateParticipationStatus={() => {
                                    this.ParticipationUpdate(_STATUS.participated);
                                    history.push(GLOBAL_CONFIG.Route.buzzer.confirmation)
                                }}
                            />
                        )} />
                        <Route path={GLOBAL_CONFIG.Route.NoProductCode} exact component={NoProductCode} />
                        <Route path={GLOBAL_CONFIG.Route.product} exact component={Q4Product} />
                        <Route path={GLOBAL_CONFIG.Route.noParticipation} exact component={NoParticipation} />
                        <Route path={GLOBAL_CONFIG.Route.buzzer.selection} exact component={Q4Selection} />

                        {/* FANCENTER SCENARIO C */}
                        <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.baseName} component={()=>(<TicketsRoutes UpdateFancenterStatus={this.FancenterStatusUpdate.bind(this)} />)} />
                        <Route component={Error} />
                    </Switch>
                );
            } else if (this.props.campaignStatus === 'holding') {
                RouteRender = (
                    <Switch>
                        <Route path={GLOBAL_CONFIG.Route.holding} exact component={Q4Holding} />
                        <Route path={GLOBAL_CONFIG.Route.intropage} exact component={BuzzerIntroPage} />
                        <Route path={GLOBAL_CONFIG.Route.cookie} exact component={Cookie} />
                        {/*<Route path = {GLOBAL_CONFIG.Route.prize} exact component={Prize} />*/}
                        <Route path={GLOBAL_CONFIG.Route.footballGame} exact component={FootballGame} />
                        <Route path={GLOBAL_CONFIG.Route.fairplay} exact component={Fairplay} />
                        <Route path={GLOBAL_CONFIG.Route.terms} exact component={Q4Terms} />
                        
                        <Route path={GLOBAL_CONFIG.Route.fancenterTerms} exact component={FancenterTerms} />

                        {/* FANCENTER SCENARIO C */}
                        <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.baseName} component={()=>(<TicketsRoutes UpdateFancenterStatus={this.FancenterStatusUpdate.bind(this)} />)} />
                        <Route component={Error} />
                    </Switch>
                );

                NavContent = null;      
            } else if (this.props.campaignStatus === 'end') {
                RouteRender = (
                    <Switch>
                        <Route path={GLOBAL_CONFIG.Route.end} exact component={Q4End} />
                        <Route path={GLOBAL_CONFIG.Route.fancenterTerms} exact component={FancenterTerms} />

                        <Route path={GLOBAL_CONFIG.Route.cookie} exact component={Cookie} />
                        <Route path = {GLOBAL_CONFIG.Route.intropage} exact component={BuzzerIntroPage}/>

                        <Route path = {GLOBAL_CONFIG.Route.footballGame} exact component={FootballGame}/>
                        <Route path = {GLOBAL_CONFIG.Route.fairplay} exact component={Fairplay}/>
                        <Route path={GLOBAL_CONFIG.Route.terms} exact component={Q4Terms} />
                        <Route path={GLOBAL_CONFIG.Route.participation} exact component={() => {
                            return (
                                <Q4Participation
                                    UpdateParticipationData={this.ParticipationDataUpdate.bind(this)}
                                    UpdateParticipationStatus={() => {
                                        this.ParticipationUpdate(_STATUS.participated);
                                        history.push(GLOBAL_CONFIG.Route.buzzer.confirmation)
                                    }}
                                />
                            );
                        }} />

                        {/* FANCENTER SCENARIO C */}
                        <Route path={GLOBAL_CONFIG.Route.fanCenter.tickets.baseName} component={()=>(<TicketsRoutes UpdateFancenterStatus={this.FancenterStatusUpdate.bind(this)} />)} />
                        <Route component={Error} />
                    </Switch>
                );

                NavContent = null;
            }
        }
        let main;
        let whiteClass;
        let meta;

        const ChangeTracker = () => {
            const location = useLocation();
            let pathname = location.pathname,
                pathnameLower = pathname.toLowerCase();

                pathnameLower = pathnameLower.replace(/[\/\\]+$/,'');

            if (
                (pathname === "/confirm")
                || (pathname === "/cookie")
                || (pathname === "/teilnahmebedingungen")
                || (pathname === "/fancenter/teilnahmebedingungen")
                || (pathname === "/Lieferadressen")
                || (pathname === "/game")
                || (pathname === "/passwortzuruecksetzen")
                || (pathnameLower === "/endrewe")
                || (pathnameLower === "/endkaufland")
                || (pathnameLower === "/endedeka")
                || (pathnameLower === "/dein-gewinn")
                || (pathnameLower === "/fancenter/confirm")
                || (pathnameLower === "/endnetto")
                || (pathnameLower === "/endpenny")) {
                whiteClass = "main-content main-content--white";
            }
            else if ((pathnameLower === "/fairplay")) {
                whiteClass = "main-content main-content--white fairplay";
            }
            else if ((pathname === "/account")) {
                whiteClass = "main-content main-content--white account-layout";
            }
            else if ((pathname === "/register")) {
                whiteClass = "main-content main-content--white register-layout";
            }
            else if ((pathname === "/loginDetail")) {
                whiteClass = "main-content main-content--white login-detail_layout";
            }
            else if ((pathname === "/resetPassword")) {
                whiteClass = "main-content main-content--white reset-password_layout";
            }
            else if ((pathname === "/passwordChangeEmail")) {
                whiteClass = "main-content main-content--white password-change-email_layout";
            }
            else if ((pathname === "/AccountInformationen")) {
                whiteClass = "main-content main-content--white account-info_layout";
            }
            else if ((pathname === "/confirmationAccount")) {
                whiteClass = "main-content account-confirmation_layout";
            }
            else if ((pathname === "/no-product-code")) {
                whiteClass = "main-content no-product-code_layout";
            }
            // Buzzer routes update
            else if (
                    pathnameLower === GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.jersey.main.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.jersey.codeEntry.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.jersey.teamSelection.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.jersey.jerseySizeSelection.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.jersey.jerseyUserDetails.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.jersey.ticketSelection.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.jersey.ticketOptions.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.jersey.confirmation.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.jersey.addressForm.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.fanCenter.confirmation.user.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.fanCenter.confirmation.companion.toLowerCase()
            ) {
                whiteClass = "main-content";
            } else if (
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.giftChoice.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.codeEntry.user.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.codeEntry.companion.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.userDetails.user.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.userDetails.companion.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend.toLowerCase() || pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend.toLowerCase()
            ) {
                whiteClass = "main-content main-content--white fancenter-layout";
            }
            else if (pathnameLower === GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase()) {
                whiteClass = "main-content main-content--white fancenter-layout main-fancenter";
            }
            else if (pathname === GLOBAL_CONFIG.Route.participation.toLowerCase() && this.props.campaignStatus !== 'end') {
                whiteClass = "main-content--secondary-lilac";
            }else if(
                pathnameLower === ""
                || pathnameLower === GLOBAL_CONFIG.Route.home.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.game.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.prize.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.faq.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.end.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.holding.toLowerCase() 
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingkaufland.toLowerCase() 
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase() 
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase() 
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingEdeka.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingPenny.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingnetto.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingGlobus.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.main.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.participation.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.confirmation.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.end.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.faq.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.product.toLowerCase()
                || pathnameLower === GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase()
            ){
                whiteClass = "main-content main-content--dark-lilac";
            }else {
                whiteClass = "main-content";
            }
            main = (
                <main className={whiteClass} id="main">
                    <Banner campaignStatus={this.props.campaignStatus} promotionCampaign={this.props.promotionCampaign} isWeltmeisterPromotion={true}/>
                    {NavContent}
                    {RouteRender}
                </main>
            )
            return main;
        }
        const ChangeMeta = () => {
            const location = useLocation(),
                pathname = location.pathname,
                pathnameLower = pathname.toLowerCase();

            if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingnetto.toLowerCase()
            ) {
                return (
                    <MetaHeader
                        title="Familienzeit gewinnen mit Milka bei Netto"
                        description="Jetzt an unserer Zusatzverlosung teilnehmen und exklusives Familienerlebnis beim Supercup gewinnen!"
                        og_title="Gewinne unvergessliche Familienzeit beim Supercup"
                        og_description="Milka Produkte bei Netto kaufen, Kaufbeleg hochladen und mit etwas Glück exklusives Familienerlebnis bei den Bundesliga Stars gewinnen."
                        og_sitename="www.gold.fcmilka.de/netto"
                    />
                );
            }
            else if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingkaufland.toLowerCase()
            ) {
                return (
                    <MetaHeader
                        title="Gewinne deine Bundesliga VIP-Loge"
                        description="Jetzt an unserer Zusatzverlosung teilnehmen und deine exklusive VIP-Loge für die komplette Bundesliga Saison 2022/23 gewinnen!"
                        og_title="Gewinne deine Bundesliga VIP-Loge"
                        og_description="Milka Produkte bei Kaufland kaufen, Kaufbeleg hochladen und mit etwas Glück exklusive VIP-Loge für die komplette Bundesliga Saison 2022/23 gewinnen."
                        og_sitename="www.gold.fcmilka.de/kaufland"
                    />
                );
            }
            else if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingEdeka.toLowerCase()
            ) {
                return (
                    <MetaHeader
                        title="Familienzeit gewinnen mit Milka bei EDEKA"
                        description="Jetzt an unserer Zusatzverlosung teilnehmen und exklusives Familienerlebnis beim Supercup gewinnen!"
                        og_title="Gewinne unvergessliche Familienzeit beim Supercup"
                        og_description="Milka Produkte bei EDEKA kaufen, Kaufbeleg hochladen und mit etwas Glück ein exklusives Familienerlebnis bei den Bundesliga Stars gewinnen."
                        og_sitename="www.gold.fcmilka.de/edeka"
                    />
                );
            }
            else if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase()
            ) {
                return (
                    <MetaHeader
                        title="Spenden mit Lukas Podolski"
                        description="Jetzt an unserer Spendenaktion für die Lukas Podolski Stiftung teilnehmen und Fußballträume wahr werden lassen!"
                        og_title="Spenden mit Lukas Podolski"
                        og_description="Milka Produkte, TUC oder Wunderbar bei Rewe kaufen, Produktcode eingeben und für die Lukas Podolski Stiftung spenden!"
                        og_sitename="www.90min.fcmilka.de/Rewe"
                    />
                );
            }
            else if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingPenny.toLowerCase()
            ) {
                return (
                    <MetaHeader
                        title="Spenden mit Lukas Podolski"
                        description="Jetzt an unserer Spendenaktion für die Lukas Podolski Stiftung teilnehmen und Fußballträume wahr werden lassen!"
                        og_title="Spenden mit Lukas Podolski"
                        og_description="Milka Produkte, TUC oder Wunderbar bei Penny kaufen, Produktcode eingeben und für die Lukas Podolski Stiftung spenden!"
                        og_sitename="www.90min.fcmilka.de/Penny"
                    />
                );
            }
            else if (pathnameLower === GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase() ||
                pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingGlobus.toLowerCase()
            ) {
                return (
                    <MetaHeader
                        title="VIP-Coaching von einer Bundesliga Legende mit Milka und Oreo gewinnen"
                        description="Jetzt an unserer Zusatzverlosung teilnehmen und ein exklusives VIP-Coaching mit
                        einer Bundesliga Legende zu einem deiner Fußballspiele gewinnen"
                        og_title="Gewinne ein exklusives VIP-Coaching mit einer Bundesliga Legende"
                        og_description="Milka oder Oreo Produkte bei Globus kaufen, Produktcode
                        eingeben und mit etwas Glück ein exklusives VIP-Coaching mit einer
                        Bundesliga Legende gewinnen."
                        og_sitename="www.fcmilka.de/globus"
                    />
                );
            }else if (pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.main.toLowerCase() 
                    || pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.participation.toLowerCase()
                    || pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.confirmation.toLowerCase()
                    || pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.end.toLowerCase()
            ) {
                return (
                    <MetaHeader
                        title="Fußball-Familienerlebnis mit Milka gewinnen"
                        description="Jetzt teilnehmen und mit etwas Glück ein exklusives Familienerlebnis mit den Bundesliga Stars beim Supercup gewinnen!"
                        og_title="Gewinne unvergessliche Familienzeit beim Supercup"
                        og_description="2 Milka Produkte kaufen, Kaufbeleg hochladen und mit etwas Glück als Einlauf- oder Ballträgerkind die Bundesliga Stars hautnah erleben."
                        og_sitename="einlaufkinder.fcmilka.de"
                    />
                );
            } else {
                return (
                    <MetaHeader
                        title="Milka: Du & der Weltmeister"
                        description="FC Milka verlost zwei Treffen mit einem Fußballprofi. Triff mit Glück Lukas Podolski oder Rudi Völler. Jetzt teilnehmen"
                        og_title="Du & der Weltmeister"
                        og_description="Kaufe 1 Produkt von Milka oder OREO und gewinne mit etwas
                        Glück ein Treffen mit Lukas Podolski oder Rudi Völler."
                        og_sitename="www.weltmeister.fcmilka.de"
                    />
                );
            }
        }

        return (
            <Aux>
                <ChangeMeta campaignStatus={this.props.campaignStatus} />
                <Header campaignStatus={this.props.campaignStatus}/>
                <ChangeTracker campaignStatus={this.props.campaignStatus} />
                <Footer campaignStatus={this.props.campaignStatus} />
            </Aux>
        );
    }

    ParticipationDataUpdate(data) {
        window.localStorage.setItem(this._LOCAL.data, JSON.stringify(data))

        this.setState({
            ParticipationData: JSON.stringify(data)
        })
    }

    SetParticipation() {
        let status = window.localStorage.getItem(this._LOCAL.status),
            data = window.localStorage.getItem(this._LOCAL.data);

        if (!!status) {
            this.setState({
                ParticipationStatus: status
            })
        }

        if (!!data) {
            this.setState({
                ParticipationData: data
            })
        }
    }

    SetFancenter() {
        let status = window.sessionStorage.getItem(this._LOCAL.statusFan);

        if (!!status) {
            this.setState({
                FancenterStatus: status
            })
        }
    }

    ParticipationUpdate(status) {
        window.localStorage.setItem(this._LOCAL.status, status)

        this.setState({
            ParticipationStatus: status
        })
    }

    FancenterStatusUpdate(status) {
        window.sessionStorage.setItem(this._LOCAL.statusFan, status)

        if (status === _STATUS.Fancenter.noCodeEntry) {
            window.sessionStorage.clear();
        }

        this.setState({
            FancenterStatus: status
        })
    }
}

const mapDispatchToProps = {
	setIsBannerShown
}

export default withRouter(connect(null, mapDispatchToProps)(LayoutQ4));