import React, { useState, useEffect } from "react";
import Aux from "../../../hoc/Auxiliare";

const Video = (props) => {
  const vidRef = props.refVideo;
  const [playingVideo, setPlayingVideo] = useState(false);
  const [videoPause, setVideoPause] = useState(props.setVideoPause);

  const playVideo = () => {
    setPlayingVideo(true);
    vidRef.current.play();
  };

  const pauseVideo = () => {
    console.log("pause video called");
    vidRef.current.pause();
  };

  useEffect(()=>{
    console.log(videoPause);
    if(videoPause) {
      pauseVideo();
    }
  }, [videoPause]);

  return (
    <Aux>
        <picture
          className="Slider__left__wrapper__picture"
          style={{ display: playingVideo ? "none" : "" }}
        >
          <source media="(max-width: 480px)" srcSet={props.imageMobile} />
          <source srcSet={props.imageDesktop} />
          <img
            src={props.imageDefault}
            alt=""
            class="Slider__left__wrapper__image"
          />
        </picture>
        <video
          preload="auto"
          style={{ display: playingVideo ? "" : "none" }}
          class="Slider__left__wrapper__video"
          ref={vidRef}
          controls="controls"
          src={props.videoLink}
          type="video/mp4"
        />
        <button
          style={{ display: playingVideo ? "none" : "" }}
          onClick={playVideo}
          class="Slider__left__wrapper__overlay"
        ></button>
    </Aux>
  )
}

export default Video;
