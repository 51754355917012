import React, { Component } from "react";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import parse from "html-react-parser";
import appGoogleTracking from "../../../modules/googletracking";
import VarietyTeaser from "./VarietyTeaser";
import SliderVariety from "./SliderVariety";
import VarietyTeaserV2 from "./varietyTeaserV2";
class TrikotPromoProduct extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    return (
      <>
        <div className="content-holding__heading closing product-wrapper weltmeister-product ticket-product trikot-product">
          <div className="content-end__text">
            <h1>Zarter Schokoladengenuss</h1>
            <p>
            Schokoladiger Genuss ist für uns immer ein Heimspiel! Die zarte Vielfalt von Milka begeistert ihre Fans, egal wo sie aufläuft: Die streichzarte Milka Haselnusscreme fürs Frühstücksbrot oder die klassische Tafelschokolade als Nervennahrung am Fußball-Wochenende. Zusammen mit den tollen Produkten von TUC habt ihr ganz sicher ein tolles Fußball-Event.
            </p>
          </div>

{/*           <div className="chocolate">
            <img src="/resources/imagesBundesliga/trikot-promo/product/products.png" />
          </div> */}
{/*           <VarietyTeaser
            dataEvent="trikot_milka_varieties"
            dataCategory="Product Page"
            dataAction="Go to Page"
            dataLabel="MAIN_SITE_PROD_OTHER"
          /> */}

          {/* <div className="s-content-end">
            <div className="end-element">
              <div className="end-element__item">
                <div className="end-element__copy">
                  <h3>Die ganze Milka Genussvielfalt entdecken</h3>
                  <p>
                  Zarte Genuss-Inspiration gefällig? Die Milka Top-Auswahl aus zarter Alpenmilch Schokolade hält immer etwas Neues bereit. Probier’ dich durch und finde deinen neuen Lieblingssnack!
                  </p>
                  <button 
                    className="btn__container btn--secondary-lilac">
                    <a href="https://www.milka.de/alle-produkte" 
                      target="_blank"
                      data-event="trikot_more_from_MILKA"
                      data-category="Product Page"
                      data-action="Go to Page"
                      data-label="MAIN_SITE_PROD_OTHER" 
                      onClick={appGoogleTracking.processEventCTA}>
                      mehr von MILKA
                    </a>
                  </button>
                </div>
                <img src="/resources/imagesBundesliga/trikot-promo/product/teaser.png" />
              </div>
            </div>
          </div>

          <div className="s-content-end">
            <div className="end-element">
              <div className="end-element__item">
                <div className="end-element__copy">
                  <h3>Klassiker in zarter vielfalt</h3>
                  <p>
                  Die Milka Tafelschokolade ist ein echter Genuss-Klassiker. Aber hast du schon die vielen verschiedenen Sorten probiert? Da kommt niemals Langeweile auf!
                  </p>
                  <button className="btn__container btn--secondary-lilac">
                    <a href="https://www.milka.de/alle-produkte" 
                      target="_blank"
                      data-event="trikot_more_from_MILKA"
                      data-category="Product Page"
                      data-action="Go to Page"
                      data-label="MAIN_SITE_PROD_OTHER" 
                      onClick={appGoogleTracking.processEventCTA}>
                      mehr von MILKA
                    </a>
                  </button>
                </div>
                <img src="/resources/imagesBundesliga/trikot-promo/product/teaser.png" />
              </div>
            </div>
          </div> */}
          <VarietyTeaserV2/>
          {/* <SliderVariety/> */}
          <Footersocial />
        </div>
      </>
    );
  }
}

export default TrikotPromoProduct;
