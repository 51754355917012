import React from "react";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import ValidateForm from "../../../modules/validate";
import ParticipationService from "../../../Service/Participation";
import { withCookies } from 'react-cookie';
import Loader from "../../../components/Overlays/Loader";
import Checkbox from "../../../components/Controls/Checkbox";
import Aux from "../../../hoc/Auxiliare";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import TitleContentV1 from "../../../components/Content/TitleContent/TitleContentv1";
import VarietyTeaser from "./VarietyTeaser";


class TrikotHolding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEmailAlredySent: false,
            simpleLoader: false,
            isMobile: false
        }
        this.ProcessForm = this.ProcessForm.bind(this);
        this.resize = this.resize.bind(this);

    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
        mainLoaderToggle('hide');
        const { cookies } = this.props;
        if (cookies.get("_isPress") == "true") {
            this.setState({ isEmailAlredySent: true });
        }
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div>
                {this.headingSection()}
                <Footersocial />
                {
                    this.state.simpleLoader ? <Loader /> : null
                }
            </div>
        );
    }

    headingSection() {
        return (
            <div className="content-holding__heading trikot-promo">
                <div className="content-home endpage-rewe">
                    <div className="spaceTile-intropage">
                        <div className="s-content-title">
                            <h1 className="heading__v1">
                                Gewinne dein original Heimtrikot
                            </h1>
                
                            <p className="paragraph__v1 ">
                                Neuer Spind, neues Glück: Ab dem 03.07.2023 hast du die Möglichkeit, die Spind-Türen der FC Milka Kabine zu öffnen. Nutze deine Chance auf einen großartigen Gewinn: <span class="good-headline-gold">1 von 1.500 Original Heimtrikots der Bundesliga / 2. Bundesliga der Saison 2023/24, 1 von 150 Milka Produktpaketen</span> oder <span class="good-headline-gold">1 von 15.000 Rabattgutscheinen in Höhe von 20% von 11teamsports</span>.
                            </p>
                        </div>
                    </div>
                    <form id="frm_holding"
                        name="frm_holding"
                        method="post" action="/"
                        ref={form => this._FORM_DETAILS = form}
                        onSubmit={this.SubmitHandler.bind(this)} noValidate>
                        <div className={`remindMe-container ${this.state.isEmailAlredySent ? "sent" : ""}`}>
                            <div className="form__container">
                                <p>
                                Du möchtest den Start des FC Milka Gewinnspiels auf keinen Fall verpassen? Wir erinnern dich rechtzeitig per E-Mail!
                                </p>

                                <div id="email-container" className="input-container" style={{ marginBottom: '11px' }}>
                                    <div className="form-input__container">
                                        <label className="form-input__label" htmlFor="Vorname">Vorname*</label>
                                        <input className="form-input__input js-event-type-in js-event-ab"
                                            type="text"
                                            name="Vorname"
                                            id="Vorname"
                                            placeholder="Vorname"
                                            data-require="true"
                                            data-type="regex"
                                            data-error-target="#error-name"
                                            data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß '-]+$"
                                            data-required-message="Huch – da hat sich wohl ein Fehler in das vorname eingeschlichen. Bitte überprüfe das vorname."
                                            data-pattern-message="Huch – da hat sich wohl ein Fehler in das vorname eingeschlichen. Bitte überprüfe das vorname."
                                            data-event-fieldname="Vorname"
                                            data-event="form-click"
                                            data-category="Form Action"
                                            data-action="Vorname"
                                            data-label="Type In"
                                            ref={input => this._DETAILS_firstname = input}
                                        />
                                        <span id="error-name" className="form-input__error active"></span>
                                    </div>
                                </div>
                                <div id="email-container" className="input-container">
                                    <div className="form-input__container">
                                        <label className="form-input__label" htmlFor="email">Email*</label>
                                        <input className="form-input__input js-event-type-in js-event-ab"
                                            type="text"
                                            name="email"
                                            id="email"
                                            placeholder="E-Mail-Adresse eingeben"
                                            data-require="true"
                                            data-type="regex"
                                            data-error-target="#error-email"
                                            data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                            data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                            data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail-Anschrift."
                                            data-event-fieldname="Email"
                                            data-event="form-click"
                                            data-category="Form Action"
                                            data-action="Email"
                                            data-label="Type In"
                                            ref={input => this._DETAILS_email = input}
                                        />
                                        <span id="error-email" className="form-input__error active"></span>
                                    </div>
                                </div>
                            </div>

                            <div className="img__container">
                                <picture>
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo.png" media="(min-width: 1024px)" />
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 481px)" />
                                    <source srcSet="/resources/imagesBundesliga/buzzer/FCMilka-logo-sm.png" media="(min-width: 200px)" />
                                    <img src="/resources/imagesBundesliga/buzzer/FCMilka-logo.png" alt="FC Milka Logo" />
                                </picture>
                            </div>
                            {
                                !this.state.isEmailAlredySent ?
                                    (
                                        <Aux>
                                            <Checkbox id="terms"
                                                required={true}
                                                errorMessage={""}
                                                text='Ich möchte den regelmäßigen Newsletter mit Informationen zu Milka Produkten, Rezepten und Aktionen per E-Mail von der Mondelez Deutschland Services GmbH & Co. KG oder von verbundenen Unternehmen der Mondelez International Unternehmensgruppe erhalten. In diesem Zusammenhang wird auch mein Kauf- und Klickverhalten auf dieser Milka-Website analysiert. Diese Einwilligung kann jederzeit mit zukünftiger Wirkung hier widerrufen werden. Bitte beachten Sie auch unsere Datenschutzerklärung für weitere Informationen.'
                                                onChangeHandler={!this.state.isEmailAlredySent ? this.CheckboxChangeHandler.bind(this) : () => { }}
                                            />
                                            <button type="submit" className={this.state.isEmailAlredySent ? "btn__container btn--lilac btn-desabled" : "btn__container btn--lilac"} disabled={this.state.isEmailAlredySent}> ERINNERE MICH </button>
                                        </Aux>
                                    )
                                    :
                                    (
                                        <p className="email-sent email-buzzer23">Deine Mail Adresse wurde erfolgreich gespeichert. Wir erinnern dich, sobald die FC Milka Aktion beginnt.</p>
                                    )
                            }
                        </div>
                    </form>
                    <VarietyTeaser
                        dataEvent="go_to_milka_varieties"
                        dataCategory="Announcement Page"
                        dataAction="Go to Page"
                        dataLabel="MAIN_SITE_AP_OTHER"
                    />
                </div>
            </div>
        );
    }

    CheckboxChangeHandler(event, _ref) {
        if (_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if (_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    SubmitHandler(event) {
        event.preventDefault();
        if (this.state.isEmailAlredySent) {
            return null;
        }
        const isValid = ValidateForm.validateForm('frm_holding');

        if (isValid) {
            this.ProcessForm();
        } else {
            /**
             * Scroll To first error
             */
            let firstError = this._FORM_DETAILS.querySelector(".notvalid");
            if (!!firstError) {
                const yOffset = - 110,
                    y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }
    }

    ProcessForm() {
        const { cookies } = this.props;
        let _data = {
            firstname: this._DETAILS_firstname.value,
            email: this._DETAILS_email.value
        }

        this.setState({
            simpleLoader: true
        })
        ParticipationService.SendMailTrikotPromo(_data)
            .then((response) => {
                let { success } = response.data;
                this.setState({
                    simpleLoader: false
                })
                if (success) {
                    this.setState({ isEmailAlredySent: true });
                    const expireDate = new Date();
                    expireDate.setMonth(expireDate.getMonth() + 3);
                    cookies.set("_isPress", "true", { path: "/", expires: expireDate });
                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 'remind_me',
                        'dataCategory': 'Announcement Page',
                        'dataAction': 'Click on Remind Me',
                        'dataLabel': 'ENG_AP_OTHER'
                    });
                } else {
                    console.log("KO");
                }
            })
            .catch((error) => {
                this.setState({
                    simpleLoader: false
                })
                console.log("catch : KO", error);
            });
    }

    footerSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <p>
                    *Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga / 2. Bundesliga. Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen, und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID-19-Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation. Maximal ein Hauptpreis pro Person.
                </p>

                {
                    this.state.isMobile && (
                        <a href="https://www.milka.de/alle-produkte" target="_blank">
                            <button className="btn__container btn--red"> MEHR ENTDECKEN </button>
                        </a>
                    )
                }

            </div>
        )
    }
}

export default withCookies(TrikotHolding);