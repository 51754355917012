import React from "react";

import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import BannerVideo from "../../../components/Layout/BannerVideo";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import appGoogleTracking from "../../../modules/googletracking";
import _LOCAL from "../../../Models/LocalStorageKeys";
import GLOBAL_CONFIG from "../../../config/config";
import _GAME_STATUS from "../../../Models/GameStatus";


class BuzzerClosing extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');

        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    componentWillMount() {
        if (localStorage.getItem(_LOCAL.ProductCode) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.buzzer.main);
        }
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div>
                { localStorage.getItem(_GAME_STATUS.Game) === true || localStorage.getItem(_GAME_STATUS.Game) === "true" ? <BannerVideo avalaible={true} /> : null }
                {this.headingSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <div>
                <div className="content-home endpage-buzzer-q3">
                    <div className="s-content-end">
                        <div className="end-element">
                            <div className="end-element__item">
                                <img src="/resources/imagesBundesliga/buzzer/end/box-1-new.png" />
                                <div className="end-element__copy">
                                    <h3>100% Genuss</h3>
                                    <p>Milka Mmmax Großtafeln bieten dir mehr Schokolade, großzügige Zutaten und multidimensionale Geschmackserlebnisse.</p>
                                    <a href="https://mmmax.milka.de/" target="_blank" data-event="buzzer_give_away" data-category="Thank You Page" data-action="Go to Page" data-label="MILKA_OTHER_TYP" onClick={appGoogleTracking.processEventCTA}>
                                        <button className="btn__container btn--secondary-lilac"> JETZT ENTDECKEN </button>
                                    </a>
                                </div>
                            </div>


                            <div className="end-element__item">
                                <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                                <div className="end-element__copy">
                                    <h3>NEUES VON MILKA</h3>
                                    <p>Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.</p>
                                    <a href="https://www.milka.de/neues" target="_blank" data-event="buzzer_discover_new" data-category="Thank You Page" data-action="Go to Page" data-label="MILKA_OTHER_TYP" onClick={appGoogleTracking.processEventCTA}>
                                        <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                    </a>
                                </div>
                            </div>


                            <div className="end-element__item">
                                <img src="/resources/imagesBundesliga/buzzer/end/fancenter-box-3.png" />
                                <div className="end-element__copy">
                                    <h3>MILKA ENGAGEMENT</h3>
                                    <p>Du möchtest mehr über das Engagement von Milka im Kakaoanbau erfahren? Oder wissen, warum Milka so zart ist?</p>
                                    <a href=" https://www.milka.de/uber-milka/engagement" target="_blank" data-event="buzzer_more_about_MILKA" data-category="Thank You Page" data-action="Go to Page" data-label="MILKA_OTHER_TYP" onClick={appGoogleTracking.processEventCTA}>
                                        <button className="btn__container btn--secondary-lilac"> MEHR ÜBER MILKA </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BuzzerClosing;