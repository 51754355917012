import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import GLOBAL_CONFIG from '../../config/config';
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle } from '../../modules/helpers';
import Notice from '../../components/Content/Notice/Notice';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import TitleLogo from '../../components/Content/TitleLogo/TitleLogo';
import { NavLink, withRouter } from 'react-router-dom';
import SliderContentAnnouncement from '../../components/Content/SliderContent/SliderContentAnnouncement';

class Annoncement extends Component {

    componentDidMount(){
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');

         //Virtual page
        appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': GLOBAL_CONFIG.Route.holding,
                'pageTitle': t('Meta.title')
            }
        });
    }

    componentWillUnmount(){
        //Show mainloader 
        mainLoaderToggle('show');
    }

    render() {

        const { t } = this.props;

        let image = {
            desktop: '/resources/images/milka-product.png',
            mobile: '/resources/images/'+window.PROMOTION_CAMPAIGN+'/milka-product-mobile.png'
        };

        return (
            <div className="holding">
            {/* <NavigationContent/> */}
            <div className="navigation-newsletter">
                        <a id="js-newsletter-optins" href="https://www.milka.de/newsletter?utm_source=von-milka-fuer-dich.milka.de&utm_medium=website&utm_campaign=timetogiveback" target="_blank" rel="noopener noreferrer" data-event="button-click" data-category="Click Action" data-action="Newsletter Optin" onClick={appGoogleTracking.processEventCTANavLink}>Newsletter abonnieren</a>
                        <img src="/resources/images/icons/newsletter-icon.png" width="48" height="48" alt="Newsletter icon" />
                    </div>
                    <div className="page__menu">
                        <ul className="navigation main-nav">
                        <li>
                        <NavLink to={GLOBAL_CONFIG.Route.home} exact activeClassName="active" data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink}>Start</NavLink>
                        </li>
                        <li>
                            <NavLink to={GLOBAL_CONFIG.Route.prize} exact data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink}>Gewinne</NavLink>
                        </li>
                        </ul>
                    </div>
            <div className="content-holding">
                <TitleLogo/>
                <div className="text-white">
                    <TitleContent 
                        heading={t('Page.Holding.title')} 
                        subheading={parse(t('Page.Holding.subTitle'))}
                        paragraph={t('Page.Holding.paragraph')}
                    />
                </div>
                {/* <GiftContent imageoption={image} primaryoption={true} /> */}
                {/* need to complet the media query */}
                <SliderContentAnnouncement />
                <div className="bg-white">
                    <Notice
                        firstNotice={t('Page.Holding.firstNotice')}
                        secondNotice={t('Page.Holding.secondNotice')}
                    />
                    <div className="content-link">
                        <a href="https://www.milka.de/" target="_blank" rel="noopener noreferrer" data-event="info-click" data-category="Informational Action" data-action="See Prizes" data-label={t('Meta.title')} onClick={appGoogleTracking.processEventCTA}>
                            <div className="btn__container ">
                                <span className="btn__text">{t('Page.Holding.linkText')}</span>
                            </div>
                        </a>
                    </div>
                </div> 
               
                {/* <GiftContentFooter /> */}
                <Footersocial/>
                
            </div>
            </div>
            
        );
    }
}

export default withTranslation()(withRouter(Annoncement));