const _SCROLL_PAGE = {
    ScrollToBanner: () => {
        let banner = document.querySelector(".banner__container");

        if(!!banner) {
            banner.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'start',
            })
        }
    },
    ScrollToTop: () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    ScrollSection: (section) => {
        if(!!section) {
            section.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'start'
            })
        }
    },
    ScrollToElement: (element) => {
        if(!element){
            return;
        }
        const yOffset = - 110,
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({
            top: y,
            behavior: 'smooth'
        });
    }
}

export default _SCROLL_PAGE;