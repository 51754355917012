import axios from 'axios';
import GLOBAL_CONFIG from '../config/config';
import { servicePath, ServicesUrl } from '../modules/helpers';

const accountService = () => { 

    const Login = ({Username, Password}) => {
        const _URL = servicePath({
            ...GLOBAL_CONFIG.ServiceSettings,
            status: 'login'
        });

        return axios({
            method: 'post',
            url: _URL,
            data: {Username, Password},
            headers: { 'Content-Type': 'application/json' }
        })
    }

    
    const UserDetails = ( { LoginToken } ) => {
        const _URL = servicePath({
            ...GLOBAL_CONFIG.ServiceSettings,
            status: 'details'
        });

        return axios({
            method: 'POST',
            url: _URL,
            data: {
                "token": LoginToken
            },
            headers: { 'Content-Type': 'application/json' }
        })
    }
    const UpdateAccount = (_data) => {
        const _SERVICE_URL = ServicesUrl();
        const { Token,salutation, street1, street2, streetNumber, city, zipcode, firstname, lastname } = _data;
        const formData = new FormData();
        formData.append('Token', Token);
        formData.append('salutation', salutation);
        formData.append('firstname', firstname);
        formData.append('lastname', lastname);
        formData.append('street1', street1);
        formData.append('streetNumber', streetNumber);
        formData.append('street2', street2);
        formData.append('city', city);
        // formData.append('email', email);
        formData.append('zipcode', zipcode);
        return axios({
            method: 'POST',
            url: `${_SERVICE_URL}/UserManagement/UpdateProfileInfo`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }
    return {
        Login,
        UserDetails,
        UpdateAccount
    }
}

const AccountServices = accountService();


export default AccountServices;