import axios from "axios";
import { ServicesUrl } from "../modules/helpers";


const matchService = () => {
    const _SERVICE_URL = ServicesUrl();

    var matches = () => {
        return axios({
            method: 'get',
            url: `${_SERVICE_URL}/Participation/GetMatches`,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }

    const buzzerMatches = () => {
        return axios({
            method: 'get',
            url: `${_SERVICE_URL}/Participation/GetGameWeekMatches`
        })
    }

    return {
        GetMatches: matches,
        GetBuzzerMatches: buzzerMatches
    }
}

var MatchServices = matchService();

export default MatchServices;