import React from 'react';
import Aux from '../../../hoc/Auxiliare';
/**
 * 
 * @param icon [dot, times, none, text]
 * 
 */
const Button = ({ icon = 'dot', text, border = true, disabled = false , fill = false, onclick, iconText, image=''} ) => {

    return (
        <Aux>
            <div 
                className={['btn-icon', border ? 'btn-icon__border' : '',  fill ? 'btn-icon__fill' : ''].join(' ')} 
                onClick={onclick && onclick}
            >
                <div className={['btn-icon__color', 'btn-icon__color__'+ icon, disabled ? 'btn-icon__color__disabled' : '' ].join(' ')}>
                    {iconText && iconText}
                </div>  
                <div className='btn-icon__text'>
                    {image !== '' && <img src={'/resources/images/icons/'+ image } alt={'icon'}></img>}
                    {text}
                </div>  
            </div>        
        </Aux>
    );
};

export default Button;