import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { mainLoaderToggle, timeStamp, scrollParticipate } from '../../modules/helpers';
import Step from '../../components/Content/StepContent/StepContent';
import Notice from '../../components/Content/Notice/Notice';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import Product from '../Product/Product';
import TitleLogo from '../../components/Content/TitleLogo/TitleLogo';
import TitleContentV1 from '../../components/Content/TitleContent/TitleContentv1';
class Home23 extends Component {


    constructor() {
        super();

    }

    componentDidMount() {

        //Remove main loader
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        //Show mainloader
        mainLoaderToggle('show');
    }
    getAnnouncementDomain() {
        let hostName = window.location.hostname,
            isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
        // const baseDomaine = isProd ? "https://tickets.fcmilka.de/" : "/tickets";
        // const baseDomaine = isProd ? "https://buzzer.fcmilka.de/" : "/buzzer";
        //const baseDomaine = isProd ? "https://doppeltgewinnen.fcmilka.de/" : "/doppeltgewinnen";
        const baseDomaine = isProd ? "https://tickets.fcmilka.de/" : "/tickets";
        return baseDomaine;
    }

    render() {
        return (
            <div >
                {this.errorBanner()}
                <Footersocial />
            </div>
        );
    }
    errorBanner() {
        const basePath = "/resources/imagesBundesliga/fanCenter/banner/home";
        let desktopImage = `${basePath}/header.png`
        let mobileImage = `${basePath}/header-sm.png`
        return (
            <div id="home-page-23">
                <div className="fancenter-banner">
                    <picture>
                        <source srcSet={desktopImage} media="(min-width: 1024px)" />
                        <source srcSet={mobileImage} media="(min-width: 481px)" />
                        <source srcSet={mobileImage} media="(min-width: 200px)" />
                        <img className="fancenter-banner__image" src={mobileImage} alt="Milka FanCenter" />
                    </picture>
                </div>
                <div className='fancenter-home-page-23'>
                    <TitleContentV1
                        paragraph={'Über unsere laufenden Gewinnspiele kannst du regelmäßig tolle Fußballpreise gewinnen. Trikots, Tickets und vieles mehr wartet auf dich. Nimm am besten gleich an unserer aktuellen Aktion teil und freue dich mit etwas Glück auf einen unserer Gewinne.'}
                        paragraphsecond={'Wir drücken dir die Daumen.'}
                    />
                    <a
                        href={`${this.getAnnouncementDomain()}`}
                        target="_blank"
                    >
                        <button className="btn__container "> ZUR AKTUELLEN AKTION </button>
                    </a>
                </div>
            </div>
        );
    }
}




export default withTranslation()(Home23);