import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from 'react-router-dom';
import parse from 'html-react-parser'; 
import GLOBAL_CONFIG from '../../config/config'; 
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle } from '../../modules/helpers';
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import GiftContent from '../../components/Content/GiftContent/GiftContent';
import GiftContentFooter from '../../components/Content/GiftContent/GiftContentFooter';
import Aux from '../../hoc/Auxiliare';
import TitleLogo from '../../components/Content/TitleLogo/TitleLogo';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';

class Account extends Component {

    componentDidMount(){
        mainLoaderToggle('hide');
       
    }
    componentWillUnmount(){
        mainLoaderToggle('show');
        
    }
    
    render() {
        return(
            <div className="main-confirmation_account">
                <TitleContent 
                    heading="Dein FC Milka Account"
                />
                <div className="confirmation_account">
                <div className="confirmation_account-text">
                    <TitleContent
                        paragraph="Schön, dass du Mitglied im FC Milka Club werden möchtest. Es warten viele spannende Aktionen von Milka und der deutschen Bundesliga auf dich."
                        paragraph2="
                        Ihr FcMilka-Konto ist jetzt aktiviert"
                    />
                    <img src="/resources/imagesBundesliga/fcAccount/ShieldFlat.png"></img>
                    <div className="form-button">
                    {/* <button> */}
                        <div className="btn__container">
                        <NavLink to={GLOBAL_CONFIG.Route.account} exact data-event="info-click" data-category="Informational Action" data-action="T&amp;C" >
                        <span className="btn__text">FC Milka Account</span></NavLink>
                            
                        </div>
                    {/* </button> */}
                    </div>  
                    
                </div>
               
                </div>
                <TitleLogo/>
                <Footersocial/>
            </div>
        )
    }
    }
        export default withTranslation()(withRouter(Account));