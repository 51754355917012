import React from "react";
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import { NavLink } from 'react-router-dom';
// import Aux from '../../hoc/Auxiliare';

import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import StepContent from "../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import ParticipationNavigation from "./ParticipationNavigation";

class BuzzerFAQ extends React.Component {
    componentDidMount() {
        mainLoaderToggle('hide');
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200)
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div id="buzzer-faqs" className="buzzer-game">
                {this.faqContent(this.props)}
                <Footersocial />
            </div>
        );
    }

    faqContent(props) {
        const iconPlus = "/resources/imagesBundesliga/buzzer/plus.png";
        const iconMinus = "/resources/imagesBundesliga/buzzer/minus.png";

        return (
            <div className="content-faq">
                <div className="content-faq__item">
                    <h6>Wie läuft das Gewinnspiel ab?</h6>
                    <img
                        src={iconMinus}
                        className="content-faq__img shown"
                        data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_1"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content show">
                        <ul>
                            <li>
                                Kaufe innerhalb des Aktionszeitraumes vom 03.04.2023 – 25.06.2023
                                Aktionsprodukte von Milka und/oder OREO.
                            </li>
                            <li>
                                Gib den Produktcode (auf der Produktverpackung) auf dieser Seite <a href="https://www.buzzer.fcmilka.de" /* data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} */>www.buzzer.fcmilka.de</a> bis zum 25.06.2023 ein und gib deine Kontaktdaten
                                an. (Hinweis: Befindet sich kein Produktcode auf der Produktverpackung, so
                                müssen die Zahlen beim Barcode eingegeben werden.)
                            </li>
                            <li style={{ listStyle: 'none' }}>
                                Zusätzlich benötigen wir noch die Angabe des Händlers, dein Einkaufsdatum
                                und die Aktionsmarke, die du eingekauft hast. Online-Registrierung
                                erforderlich.
                            </li>
                            <li>
                                Nach der Eingabe des Produktcodes erhält der Teilnehmer bis einschließlich zum 04.06.2023 mit
                                Klick auf den Bundesliga Buzzer einen zufallsbasierten Spielstand
                                (bis zu 4-4) zum Ausgang einer Bundesliga Partie des kommenden
                                Spieltagwochenendes/Relegationsspieles.
                            </li>
                            <li>
                                Ab dem 05.06.2023 gibt der Teilnehmer nur noch den Produktcode für die
                                Teilnahme ein und nimmt damit an der Verlosung teil, das Bundesliga Buzzer Spiel
                                steht dann nicht mehr zur Verfügung.
                            </li>
                            <li>Nach Eingabe aller Daten erhältst du eine Bestätigungsmail, dass deine
                                Teilnahme eingegangen ist. Zudem bekommst du die Information, dass du
                                am Ende der Aktion eine weitere Chance auf den Hauptgewinn hast.</li>

                            <li>
                                Die Gewinner der original Heimtrikots der Bundesliga / 2.Bundesliga werden innerhalb von 48
                                Stunden nach Ende des jeweiligen  Spieltages / Wochenendes per E-Mail informiert und können sich ihr
                                Trikot im Milka-Fan-Center auswählen.
                                (Werte der Trikots variieren je nach Club, Auswahl nach Verfügbarkeit über Milka-Fan-Center.)
                            </li>
                            <li>Die Gewinnermittlung des Manager-Tags mit Rudi Völler erfolgt unter allen
                                im Aktionszeitraum 03.04.2023 – 25.06.2023 Teilnehmenden, die einen
                                gültigen Produktcode eingegeben haben. Die Ziehung erfolgt nach dem
                                Zufallsprinzip nach Ende des Aktionszeitraums. Der Gewinner wird per
                                E-Mail benachrichtigt.</li>
                            <li>Bitte bewahre den Original-Kaufbeleg als Nachweis gut auf.</li>
                        </ul>
                    </div>
                </div>



                <div className="content-faq__item">
                    <h6>Mit welchen Produkten kann ich teilnehmen?</h6>
                    <img
                        src={iconPlus}
                        className="content-faq__img notshown"
                        data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_2"
                        onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Die Aktion ist gültig für alle Produkte der Marken Milka und/oder OREO.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Wo finde ich die Produkte für das Gewinnspiel?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_3" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Du findest die Produkte überall dort im Handel, wo du auch sonst deine
                            Lieblingsprodukte von Milka und/oder OREO findest.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich mehrere Produktcodes hochladen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_4" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Ja, Mehrfachteilnahmen mit neuem Produktcode innerhalb der Aktionsprodukte
                            sind erlaubt. Max. ein Gewinn pro Person im Gewinnspielzeitraum.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Wie lange kann ich an der Aktion teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_5" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Du hast vom 03.04.2023 bis 25.06.2023 die Möglichkeit deinen Produktcode
                            einzugeben. Es nehmen aber nur Aktionsprodukte teil, die im Zeitraum vom
                            03.04.2023 bis 25.06.2023 gekauft wurden.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann jeder an der Aktion teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_6" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Teilnehmen kann jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland.
                            Ausgeschlossen sind Mitarbeiter des Veranstalters und der beteiligten Unternehmen
                            sowie jeweils deren Angehörige.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>In welchen Ländern kann man teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_7" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Die Teilnahme ist in Deutschland möglich.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Was kann gewonnen werden?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_8" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Insgesamt gibt es folgende Gewinne* im Aktionszeitraum:
                        </p>
                        <ul>
                            <li>
                                <strong>1.000x 1 original Bundesliga Heimtrikot</strong>(Werte der Trikots variieren je nach
                                Club, Auswahl nach Verfügbarkeit über Milka-Fan-Center)
                            </li>
                            <li>
                                <strong>1x 1 Manager Tag mit Rudi Völler für 2 Personen</strong> (inkl. An- und Abreise, 2x
                                Übernachtung 5-Sterne-Hotel, Verpflegung, attraktivem Rahmenprogramm
                                bei einem Bundesliga Club in Deutschland, Austragungsort wird zeitnah
                                bekanntgegeben).
                            </li>
                        </ul>
                        <p>
                            *Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga.
                        </p>
                        <p>
                            Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen und kann ihn
                            durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn
                            aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere
                            die derzeitige COVID 19 Situation und sich aus ihr ergebende Restriktionen oder
                            Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit
                            des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch
                            auf eine Barauszahlung oder eine anderweitige Kompensation.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie erfahre ich, ob und was ich gewonnen habe?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_9" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Die Gewinnermittlung der Trikots erfolgt durch Verlosung im Aktionszeitraum nach
                            den jeweiligen Spieltagwochenenden bzw. nach den jeweiligen Relegationsspielen
                            im Abgleich mit den zugewiesenen und tatsächlichen Ergebnissen.
                        </p>
                        <p>
                            Innerhalb der Teilnehmer, die ein korrektes Ergebnis erbuzzert haben, werden die
                            Trikotkontingente wöchentlich verlost. Die Gewinner werden im Anschluss nach
                            dem Spieltagwochenende innerhalb von 48 Stunden per E-Mail informiert.
                            Gewinnbenachrichtigung per E-Mail erfolgt an die bei der Registrierung
                            angegebene E-Mail-Adresse.
                        </p>
                        <p>
                            Die Teilnehmer die außerhalb des Buzzer-Zeitraums (ab dem 05.06.2023) teilnehmen
                            werden im Zufallsprinzip wöchentlich gezogen und im Anschluss 48h danach
                            informiert.
                        </p>
                        <p>
                            Der Gewinner/ die Gewinnerin des Hauptpreises wird am Ende der Aktion unter
                            allen gültigen Teilnehmern ermittelt und per E-Mail informiert. Für weitere
                            Informationen zu dem Event wird sich unsere Agentur telefonisch bei dem
                            Gewinner/der Gewinnerin melden.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich nur gewinnen, wenn ich das richtige Spielergebnis erbuzzere?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_10" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Du hast auch dann die Chance zu gewinnen, wenn dein Ergebnis nicht das korrekte
                            Ergebnis ist. Die Gewinnermittlung erfolgt durch Verlosung im Aktionszeitraum
                            nach dem Spieltag-Wochenende im Abgleich mit den zugewiesenen und
                            tatsächlichen Ergebnissen. Innerhalb der Teilnehmer, die ein korrektes Ergebnis
                            erbuzzert haben, werden die Trikotkontingente wöchentlich verlost. Sollten es nicht
                            genügend richtig erbuzzerte Spielergebnisse geben, so wird eine richtige Tendenz
                            hinzugezogen oder ansonsten tritt das Zufallsprinzip ein.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Warum wird kein höheres Spielergebnis als 4:4 angezeigt?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_11" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Nach der Eingabe des Produktcodes erhält der Teilnehmer mit Klick auf den
                            Bundesliga-Buzzer einen zufallsbasierten Spielstand (bis zu 4:4) zum Ausgang einer
                            Bundesliga Partie des darauffolgenden Spieltagwochenendes.
                        </p>
                        <p>
                            Die vorgeschlagenen Spielergebnisse basieren nicht auf historischen Daten oder
                            gewissen Wahrscheinlichkeiten, sondern sind rein zufällig. Da Spielergebnisse über
                            4:4 sehr unwahrscheinlich sind (kleiner 1% Wahrscheinlichkeit) werde diese nicht
                            angeboten.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Für welche Spiele erbuzzere ich mir das Ergebnis?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_12" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>Für die Bundesliga Rückrunde:
                        </p>

                        <p>
                            Du nimmst immer montags – freitags für die Spiele am Wochenende teil.
                        </p>

                        <p>
                            Eine Teilnahme am Samstag bedeutet, dass du für die Spiele am folgenden
                            Wochenende (nächster Samstag & Sonntag) teilnimmst.
                        </p>

                        <p>
                            Für die Relegationsspiele:
                        </p>

                        <p>
                            Bei den Relegationsspielen kannst du immer bis einschließlich zum Vortag des
                            Relegationsspieles an dem Buzzerspiel teilnehmen.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich meine Daten löschen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_13" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Nach Ende der Aktion werden alle Teilnehmerdaten gelöscht. Soweit diese aus
                            gesetzlichen Gründen nicht gelöscht werden dürfen, werden deine Daten gesperrt
                            und nach Ablauf der handels- und steuerrechtlichen Aufbewahrungsfrist gelöscht.
                            Aber falls du deine Daten bereits während des Aktionszeitraums löschen möchtest,
                            ist das natürlich möglich. Dazu wendest du dich bitte direkt an unseren <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank" rel="noopener noreferrer">Verbraucherservice*</a>. Eine Teilnahme am Gewinnspiel ist dann leider nicht
                            mehr möglich
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>An wen kann ich mich wenden, wenn ich weitere Fragen habe?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" data-event="buzzer_faq"
                        data-category="FAQ Page"
                        data-action="Click on FAQ"
                        data-label="faq_number_14" onClick={(e) => { this.showItemContent(e, iconMinus, iconPlus) }} />
                    <div className="item-content">
                        <p>
                            Vielleicht hast du noch weitere Fragen, die hier nicht beantwortet worden sind.
                            Dann wende dich am besten direkt an unseren <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank">Verbraucherservice*</a>.

                            *Falls der hier hinterlegte Link zum Verbraucherservice nicht funktioniert, kannst
                            du über den Kontaktlink im Bereich „Service“ den Verbraucherservice erreichen.
                        </p>
                    </div>
                </div>

            </div>
        );
    }

    showItemContent(e, iconMinus, iconPlus) {
        let classes = Array.from(e.target.classList);
        let itemContent = e.target.parentNode.children[2];

        if (classes.includes("notshown")) {
            e.target.setAttribute('src', iconMinus);
            e.target.classList.remove("notshown");
            e.target.classList.add("shown");
            itemContent.classList.add("show");
            appGoogleTracking.processEventCTANavLink(e);
        } else {
            e.target.setAttribute('src', iconPlus);
            e.target.classList.remove("shown");
            e.target.classList.add("notshown");
            itemContent.classList.remove("show");
        }
    }
}

export default withTranslation()(BuzzerFAQ);