import React from "react";
import { mainLoaderToggle } from "../../../modules/helpers"; 
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import KIPServices from "../../../Service/KIP_Pages";
import GLOBAL_CONFIG from "../../../config/config";
import _STATUS_CAMPAIGN from "../../../Models/CampaignStatus";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Aux from "../../../hoc/Auxiliare";
import PopUp from "../../../components/Content/PopUp/PopUp";

class PennyConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pennyPopup: false
        }
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        this.displayPopup();
    }

    displayPopup(){
        setTimeout(() => {
          this.setState({
            pennyPopup: true
          })
        }, 1000)
    }

    render() {
        return(
            <div className="penny-confirmation">
                {this.headingSection()}
                <Footersocial />
            </div>
        );
    }

    headingSection() {
        return (
            <Aux>
                {this.state.pennyPopup ? <PopUp desktopImg = "/resources/imagesBundesliga/penny/pop-up.png" mobileImg="/resources/imagesBundesliga/penny/pop-up-sm.png" /> : null}
                <div className="confirmation-wrapper">
                    <div className="confirmation-text">
                        <h2>
                            Vielen Dank für deine Teilnahme!
                        </h2>
                        <p>Wir wünschen dir viel Glück!</p>
                    </div>
                </div>

                <div className="s-content-end">
                    <div className="end-element">
                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                            <div className="end-element__copy">
                                    <h3>FC Milka Mitglied werden</h3>
                                    <p>Jetzt kostenlos registrieren und viele Vorteile genießen. Sei dabei und melde dich an.</p>
                                <a href="https://www.fcmilka.de/account" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> REGISTRIEREN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                            <div className="end-element__copy">
                                    <h3>Nervennahrung nötig?</h3>
                                    <p>
                                        Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                                    </p>
                                <a href="https://www.milka.de/shop" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> SCHOKI SHOPPEN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/fancenter-box-3.png" />
                            <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                        Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                    </p>
                                <a href=" https://www.milka.de/neues" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footersocial /> */}
            </Aux>
        );
    }
}

export default PennyConfirmation;