import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from "react-router-dom";
import parse from "html-react-parser";
import GLOBAL_CONFIG from "../../config/config";
import appGoogleTracking from "../../modules/googletracking";

import Aux from "../../hoc/Auxiliare";
import TitleLogo from "../../components/Content/TitleLogo/TitleLogo";
import {
  servicePath,
  mainLoaderToggle,
  timeStamp,
  optionListConstruct,
  dateConfig,
  getCookie,
} from "../../modules/helpers";
import TitleContent from "../../components/Content/TitleContent/TitleContent";
import GiftContent from "../../components/Content/GiftContent/GiftContent";
import GiftContentFooter from "../../components/Content/GiftContent/GiftContentFooter";

import Footersocial from "../../components/Layout/FooterContent/FooterSocial";
import DropdownClub from "../../components/Content/DropdownClub/DropdownClub";
import axios from "axios"; //Axios library to handle AJAX request                                              //Parse HTML data from string
import ReCaptcha from "react-google-invisible-recaptcha"; //Google Recaptcha
import flatpickr from "flatpickr"; //Date Picker
import ValidateForm from "../../modules/validate";
import NavigationAccount from "../NavigationAccount/NavigationAccount";
import MenuBarAccount from "../MenuBarAccount/MenuBarAccount";
import InputPassword from "../InputPassword/InputPassword";
//Form validation module                      //Google tracking module

class ResetPassword extends Component {
  // componentDidMount(){
  //     mainLoaderToggle('hide');

  // }
  // componentWillUnmount(){
  //     mainLoaderToggle('show');

  // }
  // state = {
  //     tokenState: '',
  // }
  constructor(props) {
    super(props);

    // this.handleUnload = this.handleUnload.bind(this);       //Binding to check for Abondanment form for Google tracking

    this.state = {
      //User need to be at least 18 - use in flapickr
      loaderSubmit: false,
      loaderError: false,
      loaderGeneralError: false,
      message: false,
      tokenState: null,
      authorization: null,
      confirmPassword: null,
      wrongOLdPassword: null,
      showPassword: false,
    };
  }
  componentWillMount() {
    // this.setState({
    //     // tokenState: getCookie(GLOBAL_CONFIG.Session.tokenstatus)
    //     tokenState: "jikjioj",

    // });
    console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
    if (getCookie(GLOBAL_CONFIG.Session.tokenstatus) === null) {
      //Go to particaption page
      this.props.history.push({pathname: GLOBAL_CONFIG.Route.account});
    } else {
      //Get session state
      this.setState({
        tokenState: getCookie(GLOBAL_CONFIG.Session.tokenstatus),
      });
      console.log(this.state.tokenState);
    }
  }

  componentDidMount() {
    const { t } = this.props;
    //Remove main loader
    mainLoaderToggle("hide");
  }

  logout = () => {
    console.log("logout")
    appGoogleTracking.dataLayerPush({
      'dataEvent': 'user_logout',
      'dataCategory': 'Inline Menu',
      'dataAction': 'Click on Logout ',
      'dataLabel': 'ENG_AD_NAVBAR'
    })
    if (getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null) {
        console.log("nor null");
        sessionStorage.setItem(GLOBAL_CONFIG.Session.tokenstatus, null);
        document.cookie = GLOBAL_CONFIG.Session.tokenstatus + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        //document.cookie = GLOBAL_CONFIG.Session.tokenstatus + '=' + null + ';domain=' + GLOBAL_CONFIG.DomainName;
        console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
        this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
    }
    this.props.history.push({ pathname: GLOBAL_CONFIG.Route.account });
  }

  //Close error message popup
  onClosePopupHandler = () => {
    this.setState({
      loaderError: false,
      loaderGeneralError: false,
      authorization: false,
    });
  };

  OnCloseConfirmPasswordReset = () => {
    this.setState({
      confirmPassword: false
    });
    this.props.history.push({ pathname: GLOBAL_CONFIG.Route.resetPassword });
  }

  //Form submit event
  onSubmitHandler = (event) => {
    // console.log("sds");
    event.preventDefault();

    // //Validation of fields
    let validateFormInfo = ValidateForm.validateForm("frm_resetPassword");

    if (validateFormInfo === true) {
      //Check if file is valid
      console.log("1111");
      this.processFormData();
    } else {
      console.log("njh");
    }
  };

  messageError = () => {
    console.log("1251");
    this.setState({
      message: true,
    });
  };

  //Password hide and Show
  togglePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  //Process form when Captcha is OK
  processFormData = () => {
    //Open loader
    this.setState({
      loaderSubmit: true,
    });
    // token=sessionStorage.getItem(GLOBAL_CONFIG.Session.loginStatus)
    //Process form data
    let data = {
      // fileinput:             document.getElementById('file_to_upload').files[0],
      OldPassword: document.getElementById("oldPassword").value,
      token: this.state.tokenState,
    };
    if (
      document.getElementById("password").value !=
      document.getElementById("confirmPassword").value
    ) {
      this.messageError();
    } else {
      console.log(data);
      data.Password = document.getElementById("password").value;
      data.ConfirmPassword = document.getElementById("confirmPassword").value;

      //Get webservice path
      let getResetPasswordURL = servicePath({
        ...GLOBAL_CONFIG.ServiceSettings,
        campaign: this.state.promotionCampaign,
        status: "resetPassword",
      });

      //Ajax call via axios
      axios({
        method: "post",
        url: getResetPasswordURL,
        data: data,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (response.data.success === true) {
              //Set user participation
              // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, 'participate');
              // let userStatus = response.data.data.IsLogin? 'win' : 'lose';
              appGoogleTracking.dataLayerPush({
                'dataEvent': 'reset_password',
                'dataCategory': 'Password Change Page',
                'dataAction': 'Click on Reset Password',
                'dataLabel': 'LOGIN_PC_PART'
              })
              let loginstatus;
              //Set user as Winner or Loser
              sessionStorage.setItem(
                GLOBAL_CONFIG.Session.loginstatus,
                loginstatus
              ); //win or lose

              // //SET Participation ID
              // window.PARTICIPATION_ID = response.data.ParticipationId;

              // Datalayer push for Success participation
              // this.triggerDatalayers({
              //     status: 'success',
              //     participationID: response.data.ParticipationId
              // });

              //Go to Thank you page
/*               this.props.history.push({
                pathname: GLOBAL_CONFIG.Route.confirmationAccount,
              }); */

              //confirmpassword overlay
              this.setState({
                loaderSubmit: false,
                confirmPassword: true,
              });
            } else {
              let errorStatus = response.data.data.Error[0];

              if (
                errorStatus ===
                "Authorization has been denied for this request."
              ) {
                console.log("Participation error");

                this.setState({
                  loaderSubmit: false,
                  authorization: true,
                });

                // this.triggerDatalayers({
                //     status: 'error',
                //     type: 'participate'
                // });
              } else {
                if (errorStatus === "OldPassword is wrong") {
                  console.log("Captcha error message");
                  this.setState({
                    wrongOLdPassword: true,
                  });
                  //Datalayer push for Error
                  // this.triggerDatalayers({
                  //     status: 'error',
                  //     type: 'general'
                  // });
                } else if (errorStatus === "AN_ERROR_HAS_OCCURED") {
                  console.log("An error has occured");
                }

                this.setState({
                  loaderSubmit: false,
                  loaderGeneralError: true,
                });
              }

              // this.recaptcha.reset();     //Reset captcha when error occurs - deploy
            }
          } else {
            //Show error messages
            console.log("Error 404 or 500");
            //Close Loader
            this.setState({
              loaderSubmit: false,
              loaderGeneralError: true,
            });

            // this.recaptcha.reset();     //Reset captcha when error occurs
          }
        })
        .catch((response) => {
          this.props.history.push({
            pathname: GLOBAL_CONFIG.Route.changePassword,
          });
          //handle error
          console.log(response);
          console.log("hello");
          // //Close Loader
          this.setState({
            loaderSubmit: true,
            loaderGeneralError: true,
          });

          // this.recaptcha.reset();     //Reset captcha when error occurs
        });
    }
  };
  render() {
    //Loader rendering for form submit and error handling
    let submitLoader = null,
      loaderGeneralError = null,
      errorLoaderGeneral = null,
      siteKey = null,
      loaderSubmit = null,
      message = null,
      wrongOLdPassword = null,
      authorization = null,
      confirmPassword = null;
    //paraElement = null;
    if (this.state.message === true) {
      message = (
        <div>
          <p className="error-msg">Passwörter stimmen nicht überein!</p>
        </div>
      );
    }
    if (this.state.wrongOLdPassword === true) {
      wrongOLdPassword = (
        <div>
          <p className="error-msg">falsches Passwort</p>
        </div>
      );
    }
    if (this.state.loaderSubmit === true) {
      submitLoader = (
        <div className="simple-loader__container active">
          <div className="simple-loader__indicator"></div>
        </div>
      );
    }

    if (this.state.loaderGeneralError === true) {
      loaderGeneralError = (
        <div className="js-overlay overlay overlay--black">
          <div className="overlay__container overlay__content">
            <div
              className="overlay__close"
              onClick={this.onClosePopupHandler}
            ></div>
            <p id="js-error-msg">
              Ein Fehler ist aufgetreten. Bitte versuchen <br />
              Sie es später noch einmal.
            </p>
          </div>
        </div>
      );
    }
    if (this.state.authorization === true) {
      authorization = (
        <div className="js-overlay overlay overlay--black">
          <div className="overlay__container overlay__content">
            <div
              className="overlay__close"
              onClick={this.onClosePopupHandler}
            ></div>
            <p id="js-error-msg">
              Die Autorisierung für diese Anfrage wurde verweigert
            </p>
          </div>
        </div>
      );
    }
    if (this.state.confirmPassword === true) {
      confirmPassword = (
        <div className="js-overlay overlay overlay--black">
          <div className="overlay__container overlay__content">
            <div
              className="overlay__close"
              onClick={this.OnCloseConfirmPasswordReset}
            ></div>
            <p id="js-error-msg">
            Die Änderung deines Passworts war erfolgreich.
            </p>
          </div>
        </div>
      );
    }
    return (
      <div>
        <MenuBarAccount />
        <div className="profile_detail reset_password">
          <div className="left">
            <NavigationAccount />
            <button className="btnLogout logout-lg" onClick={this.logout}>
                <span className="btn__logout">Ausloggen</span>
            </button>
          </div>
          <div className="form-container grid-8-m  grid-s-2 logout-sm">
            <button className="btnLogout" onClick={this.logout}>
                <span className="btn__logout">Ausloggen</span>
            </button>
          </div>
          <div className="right">
            <div className="account content-upload-receipt form-line row ">
              <div className="content-upload-receipt form-container grid-6-m form-line row ">
                <div className="form-container">
                  <form
                    id="frm_resetPassword"
                    name="frm_resetPassword"
                    method="post"
                    action="/"
                    onSubmit={this.onSubmitHandler}
                    noValidate
                  >
                    <div className="input-container resetPassword">
                      <h2 className="title">Passwort ändern</h2>
                      <p className="small">*Pflichtfelder</p>
                      <p>Füge ein sicheres Passwort hinzu.</p>
                      <div className="input-container">
                        <div className="form-input__container">
                          <label
                            className="form-input__label"
                            htmlFor="password"
                          >
                            Altes Passwort*
                          </label>
                          <InputPassword
                            className="form-input__input js-event-type-in js-event-ab"
                            label="Altes Passwort*"
                            htmlFor="password"
                            name="oldPassword"
                            id="oldPassword"
                            placeholder="Altes Passwort*"
                            data-require="true"
                            data-type="regex"
                            data-error-target="#error-oldPassword"
                            data-regex-pattern=""
                            data-pattern-message=""
                            data-event-fieldname="Password"
                            data-event="form-click"
                            data-category="Form Action"
                            data-required-message="altes Passwort wird benötigt"
                            data-action="Password"
                            data-label="Type In"
                          />
                          <span
                            id="error-oldPassword"
                            className="form-input__error active"
                          ></span>
                        </div>
                        {/* <div className="form-input__container">
                              <label
                                className="form-input__label"
                                htmlFor="password"
                              >
                                Altes Passwort*
                              </label>
                              <input
                                className="form-input__input js-event-type-in js-event-ab"
                                type={
                                  this.state.showPassword ? "text" : "password"
                                }
                                name="oldPassword"
                                id="oldPassword"
                                placeholder="Altes Passwort*"
                                data-require="true"
                                data-type="regex"
                                data-error-target="#error-oldPassword"
                                data-regex-pattern=""
                                data-pattern-message=""
                                data-event-fieldname="Password"
                                data-event="form-click"
                                data-category="Form Action"
                                data-required-message="altes Passwort wird benötigt"
                                data-action="Password"
                                data-label="Type In"
                              />
                              <i
                                onClick={this.togglePassword}
                                className={
                                  this.state.showPassword
                                    ? "show-password"
                                    : "hide-password"
                                }
                              ></i>
                              <span
                                id="error-oldPassword"
                                className="form-input__error active"
                              ></span>
                            </div> */}
                      </div>
                      {wrongOLdPassword}
                      <div className="input-container">
                        <div className="form-input__container">
                          <label
                            className="form-input__label"
                            htmlFor="password"
                          >
                            Passwort*
                          </label>
                          <InputPassword
                            className="form-input__input js-event-type-in js-event-ab"
                            name="password"
                            id="password"
                            placeholder="Neues Passwort (mind. 8 Zeichen)*"
                            data-require="true"
                            data-type="regex"
                            data-error-target="#error-password"
                            data-regex-pattern=".{8,}"
                            data-required-message="Passwort wird benötigt"
                            data-pattern-message="Das Passwort muss aus 8 Zeichen bestehen"
                            data-event-fieldname="Password"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Password"
                            data-label="Type In"
                          />
                          <span
                            id="error-password"
                            className="form-input__error active"
                          ></span>
                        </div>
                      </div>

                      {/*                           <div className="input-container">
                            <div className="form-input__container">
                              <label
                                className="form-input__label"
                                htmlFor="password"
                              >
                                Passwort*
                              </label>
                              <input
                                className="form-input__input js-event-type-in js-event-ab"
                                type={
                                  this.state.showPassword ? "text" : "password"
                                }
                                name="password"
                                id="password"
                                placeholder="Neues Passwort (mind. 6 Zeichen)*"
                                data-require="true"
                                data-type="regex"
                                data-error-target="#error-password"
                                data-regex-pattern=".{8,}"
                                data-required-message="Passwort wird benötigt"
                                data-pattern-message="Das Passwort muss aus 8 Zeichen bestehen"
                                data-event-fieldname="Password"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Password"
                                data-label="Type In"
                              />
                              <i
                                onClick={this.togglePassword}
                                className={
                                  this.state.showPassword
                                    ? "show-password"
                                    : "hide-password"
                                }
                              ></i>
                              <span
                                id="error-password"
                                className="form-input__error active"
                              ></span>
                            </div>
                          </div> */}

                      <div className="input-container">
                        <div className="form-input__container">
                          <label
                            className="form-input__label"
                            htmlFor="confirmPassword"
                          >
                            Confirm Passwort*
                          </label>
                          <InputPassword
                            className="form-input__input js-event-type-in js-event-ab"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Neues Passwort wiederholen*"
                            data-require="true"
                            data-type="regex"
                            data-error-target="#error-confirmPassword"
                            data-regex-pattern=""
                            data-required-message="Passwort wird benötigt"
                            data-pattern-message=""
                            data-event-fieldname="Password"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Password"
                            data-label="Type In"
                          />
                          <span
                            id="error-confirmPassword"
                            className="form-input__error active"
                          ></span>
                        </div>
                      </div>

                      {/*                           <div className="input-container">
                            <div className="form-input__container">
                              <label
                                className="form-input__label"
                                htmlFor="confirmPassword"
                              >
                                Confirm Passwort*
                              </label>
                              <input
                                className="form-input__input js-event-type-in js-event-ab"
                                type={
                                  this.state.showPassword ? "text" : "password"
                                }
                                name="confirmPassword"
                                id="confirmPassword"
                                placeholder="Neues Passwort wiederholen*"
                                data-require="true"
                                data-type="regex"
                                data-error-target="#error-confirmPassword"
                                data-regex-pattern=""
                                data-required-message="Passwort wird benötigt"
                                data-pattern-message=""
                                data-event-fieldname="Password"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Password"
                                data-label="Type In"
                              />
                              <i
                                onClick={this.togglePassword}
                                className={
                                  this.state.showPassword
                                    ? "show-password"
                                    : "hide-password"
                                }
                              ></i>
                              <span
                                id="error-confirmPassword"
                                className="form-input__error active"
                              ></span>
                            </div>
                          </div> */}
                      {message}
                      <div className="form-button">
                        <button type="submit">
                          <div className="btn__container small-btn-resetPasword">
                            <span className="btn__text">
                              Passwort zurücksetzen
                            </span>
                          </div>
                          <p>&nbsp;</p>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {authorization}
          {confirmPassword}
          {loaderGeneralError}
          {loaderSubmit}
        </div>

        <div className="clear">
          <Footersocial />
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ResetPassword));
