import axios from "axios";
import { ServicesUrl } from "../modules/helpers";

const fancenterService = () => {
    const _SERVICE_URL = ServicesUrl();

    const CodeEntryTicket = (_data) => {
        /**
         * {
                "winningCode": "J22J2DLX",
                "email": "qatest6015@consultix.net",
                "capcha": ""
            }
         */
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/fancenter/submitRemainingTicket`,
            data: _data
        })
    };

    const CodeEntry = (_data) => {
        /**
         * {
                "winningCode": "J22J2DLX",
                "email": "qatest6015@consultix.net",
                "capcha": ""
            }
         */
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/FanCenter/Submit`,
            data: _data
        })
    };

    const FriendCodeEntry = (_data) => {
        /**
         * {
                "consumerId": "d3a50132-c0f0-4360-8dd8-502710143d46",
                "voucherCode": "testvoucher", **Note: Code typed in by user**
                "giftCode": "CGKELEM1" **Note: Order Code in link**,
                "capcha": ""
            }
         */
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/fancenter/friendsubmit`,
            data: _data
        })
    };

    const ChosenTeam = (_data) => {
        /**
         * {
                "teamId": "9e075f6a-8a6c-4230-ac8d-d39ef6a3630c",
                "giftCategory": "string",
                "giftType": "ticket"
            }
         */
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/fancenter/selectAll`,
            data: _data
        })
    };

    const GetMatches = (_data) => {
        /**
         * 
            {
                "giftCategory": "CAT2",
                "giftType": "Ticket",
                "teamId": "689690b4-3127-42af-9ae0-181f101ad345"
            }
         */
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/FanCenter/SelectTicket`,
            data: _data
        })
    };

    const JerseyDetails = (_data) => {
        return axios({
            method: "post",
            url: `${_SERVICE_URL}/FanCenter/Confirm`,
            data: _data
        })
    }

    const UserDetailsTicket = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/fancenter/confirmRemainingTicket`,
            data: _data
        })
    }

    const UserDetailsTicketSC = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/FanCenter/confirmSCTicket`,
            data: _data
        })
    }

    const UserDetailsCompanionTicketSC = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/FanCenter/ConfirmFriendTicketSC`,
            data: _data
        })
    }

    const UserDetails = (_data) => {
        /**
         * 
            {
                "Email": "testqa@proximity",
                "City": "city",
                "Firstname": "testfirst",
                "Salutation":"1",
                "Lastname":"testlast",
                "Street1":"main road",
                "Streetnumber":"3",
                "Zipcode":"23531",
                "CodeEntered":"OENFNFKEWF",
                "MatchUp":"bayern-dortmund",
                "TicketType":"CAT2",
                "ConsumerId":"14cc4f67-5076-4294-9117-d6fd09eae375",
                "MatchId":"B4EF9223-D4E8-4714-ADCC-59483A2C338B",
                "GiftType":"Ticket",
                "GiftCategory":"CAT2",
                "GameDate":"2021-08-12"
            }
         */

        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/FanCenter/ConfirmTicket`,
            data: _data
        })
    }

    const ConfirmFirstFriendTicket = (_data) => {
        /**
         * 
            {
                "Email": "testqa@proximity",
                "City": "city",
                "Firstname": "testfirst",
                "Salutation":"1",
                "Lastname":"testlast",
                "Street1":"main road",
                "Streetnumber":"3",
                "Zipcode":"23531",
                "CodeEntered":"OENFNFKEWF",
                "MatchUp":"bayern-dortmund",
                "TicketType":"CAT2",
                "ConsumerId":"14cc4f67-5076-4294-9117-d6fd09eae375",
                "MatchId":"B4EF9223-D4E8-4714-ADCC-59483A2C338B",
                "GiftType":"Ticket",
                "GiftCategory":"CAT2",
                "GameDate":"2021-08-12"
            }
         */

        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/FanCenter/ConfirmFirstFriendTicket`,
            data: _data
        })
    }


    const FriendUserDetails = (_data) => {
        /**
         * 
            {
                "Email": "testqa@proximity", **Note: Will be present in response from FriendSubmit**
                "City": "city",
                "Firstname": "testfirst",
                "Salutation":"1",
                "Lastname":"testlast",
                "Street1":"main road",
                "Streetnumber":"3",
                "Zipcode":"23531",
                "CodeEntered":"OENFNFKEWF", **Code entered by friend**
                "MatchUp":"bayern-dortmund",
                "TicketType":"CAT2",
                "ConsumerId":"14cc4f67-5076-4294-9117-d6fd09eae375", **Note: Consumer Id in mail**
                "MatchId":"5fec5734-5e53-4569-bfaf-00f48b835af3",
                "GiftCategory":"CAT2",
                "GameDate":"2021-08-12",
                "GiftCode":"CGKELEM1" **Note: Order code in link**
            }
         */

        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/FanCenter/ConfirmFriendTicket`,
            data: _data
        })
    }

    const GiftTicketToFriend = (_data) => {
        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/FanCenter/GiftTicketsToFriend`,
            data: _data
        })
    }

    const GiftTicketToFriendSC = (_data) => {
        /**
         * {
                "WinningCode":"testvoucher",
                "GiftCategory":"CAT2",
                "Email":"testqa@proximity"
            }
         */

        return axios({
            method: 'post',
            url: `${_SERVICE_URL}/FanCenter/GiftTicketsToFriendSC`,
            data: _data
        })
    }

    const TransferredWinner = (_data) => {
        /**
         *  
            {
                "VoucherCode": "TodL7R4M", **Code entered by user**
                "ConsumerId": "ad648e3d-553e-4721-9af5-7844cfb6858a"
            }
         */
        return axios({
            method: "post",
            url: `${_SERVICE_URL}/FanCenter/FirstfriendSubmit`,
            data: _data
        })
    }

    const CheckStock = (_data) => {
        return axios({
            method: "get",
            url: `${_SERVICE_URL}/fancenter/CheckStock/${_data.uid}`
        })
    }

    const Order = (_data) => {
        return axios({
            method: "post",
            headers : {
                'Content-Type' : 'application/json'
            },
            url: `${_SERVICE_URL}/fancenter/Order`,
            data: _data
        })
    }

    const OrderJersey = (_data) => {
        return axios({
            method: "post",
            headers : {
                'Content-Type' : 'application/json'
            },
            url: `${_SERVICE_URL}/fancenter/OrderJersey`,
            data: _data
        })
    }

    return {
        CodeEntry: {
            User: CodeEntry,
            FirstCompanion: TransferredWinner,
            Companion: FriendCodeEntry,
            UserTicket : CodeEntryTicket
        },
        GiveToFriend: GiftTicketToFriend,
        GiveToFriendSC: GiftTicketToFriendSC,
        SelectTeam: ChosenTeam,
        GetMatches: GetMatches,
        CheckStock: CheckStock,
        Order: Order,
        OrderJersey: OrderJersey,
        UserDetails: {
            Jersey: JerseyDetails,
            User: UserDetails,
            UserTicket: UserDetailsTicket,
            UserTicketSC: UserDetailsTicketSC,
            UserTicketCompanionSC: UserDetailsCompanionTicketSC,
            FirstCompanion: ConfirmFirstFriendTicket,
            Companion: FriendUserDetails
        }
    }
}

const FanCenterServices = fancenterService();

export default FanCenterServices;