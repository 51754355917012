import React from "react";
import { withRouter } from 'react-router-dom';

import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import Loader from "../../../components/Overlays/Loader";
import GLOBAL_CONFIG from "../../../config/config";
import _GIFT_CHOICES from "../../../Models/GiftChoices";
import _LOCAL from "../../../Models/LocalStorageKeys";
import { mainLoaderToggle } from "../../../modules/helpers";
import FanCenterServices from "../../../Service/FanCenter";
import JerseyNavigation from "../ChooseJersey/JerseyNavigation";
import JerseyStepIndicitor from "../ChooseJersey/StepIndicator";
import _USER_TYPE from "../../../Models/UserTypes";
import _TICKET_TYPES from "../../../Models/TicketTypes";
import _ERROR_MESSAGES from "../../../Models/ErrorMessages";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Aux from "../../../hoc/Auxiliare";
import FanCenterSCBanner from "../Banner/FanCenterSCBanner";

class ForMeOrFriend extends React.Component {
    constructor() {
        super();

        this.StepList = [
            {
                display: "Code-Eingabe",
                isInProgress: true,
                isComplete: false,
            },
            {
                display: "Lieblingsclub",
                isInProgress: false,
                isComplete: false,
            },
            {
                display: "Gewinnauswahl",
                isInProgress: false,
                isComplete: false,
            },
            {
                display: "Daten-Check",
                isInProgress: false,
                isComplete: false,
            }
        ];

        this.state = {
            SimpleLoader: false,
            CovidInfo: false,
            GiftChoice: "",
            GiftCategory: "",
            ErrorMessage: ""
        }

        this.ProcessError = this.ProcessError.bind(this);
    }

    componentDidMount() {
        if(localStorage.getItem(_LOCAL.UserDetails) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user);
        }

        this.setState({
            GiftChoice: _GIFT_CHOICES.USER,
            GiftCategory: localStorage.getItem(_LOCAL.GiftCategory)
        })
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return (
            <Aux>
                <div className="buzzer-jersey tickets-choice">
                    { this.HeadingSection() }
                    { this.MidSection() }
                    { this.FooterSection() }

                    <div id="Covid-Details">
                        {
                            this.state.CovidInfo ? 
                            <div className="back--red">
                                <p>
                                    Auf Grund der aktuellen COVID-19 Situation erhalten wir die Tickets sehr kurzfristig vor dem Spieltag von den Clubs.
                                    <br />
                                    Das Milka Team hat keinen Einfluss auf die Ticketausstellung, das wird seitens der einzelnen Clubs geregelt. Es ist uns darum nicht möglich, nähere Angaben zum genauen Versandzeitpunkt zu machen.
                                    Bitte berücksichtige auch, dass einige Clubs insbesondere bei steigenden Inzidenzzahlen überlegen, den Zugang zum Stadion nur vollständig Geimpften oder genesenen Zuschauern zu gestatten. Diese Entscheidung und Verantwortung obliegt ausschließlich den jeweiligen Clubs.
                                    Wir bitten um deine Geduld und dein Verständnis. Bitte informiere dich vor dem Stadionbesuch bei den entsprechenden Stellen.
                                </p>
                            </div> : null
                        }
                    </div>

                    <Footersocial />
                    {
                        this.state.SimpleLoader ? <Loader /> : null
                    }
                </div>
            </Aux>
        )
    }

    HeadingSection() {
        return (
            <div>
                <JerseyStepIndicitor Steps={this.StepList}/> 
            </div>
        );
    }

    MidSection() {
        return (
            <div>
                <div className="campaign-heading">
                    <h3 className="recoleta"> 
                        Tickets für dich und deine Begleitung - oder verschenkst du deinen Gewinn an einen Freund?
                    </h3>

                    <div className="vip-text">
                        <p className="lilac">
                            Du hast die Wahl: Möchtest du nur ein Ticket selbst nutzen oder dir zwei Tickets holen und mit einer Begleitperson den perfekten Stadionbesuch erleben? Oder gibst du deinen Gewinn sogar an jemanden weiter, dem du damit eine große Freude machen kannst? 
                        </p>
                        <p className="lilac">
                            Ein einmaliges Erlebnis wird es in jedem Fall.
                        </p>
                        <p className="lilac">
                            Bitte hab Verständnis, dass wir aufgrund der Corona Pandemie nur personalisierte Tickets verschicken können und wir daher eure Adressdaten benötigen.
                        </p>
                        <p className="lilac">
                            <strong>Wenn du die Tickets für einen Freund wählst, trittst du den Gewinn vollständig an die benannte Person ab.</strong>
                        </p>
                        
                    </div>
                </div>
            </div>
        );
    }

    FooterSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <form onSubmit={this.SubmitHandler.bind(this)}
                    id="frm_gift_choice"
                >
                    <div className="selection-options js-purchase-options">
                        <label className="custom-inputs" htmlFor="ticket_for_me"> { this.state.GiftCategory === _TICKET_TYPES.VIP ? "Die VIP-Tickets sind für mich!" : "Die Tickets sind für mich!"}
                            <input type="radio" name="ticket" id="ticket_for_me" value={_GIFT_CHOICES.USER} className="js-event-type-in js-event-ab" data-require="true" data-type="radio" data-event-target=".js-purchase-options"  data-error-target="#error-ticket" data-required-message="" data-event-fieldname="ticket_for_me" data-event="form-click" data-category="Form Action" data-action="ticket" data-label="Type In" checked={this.state.GiftChoice === _GIFT_CHOICES.USER}
                                onChange={this.SetChoice.bind(this)}
                            />
                            <span className="radiobtn"></span>
                        </label>
                        <label className="custom-inputs" htmlFor="ticket_for_friend">{ this.state.GiftCategory === _TICKET_TYPES.VIP ? "Beide VIP-Tickets sind für einen Freund! " : "Beide Tickets sind für einen Freund!"}
                            <input type="radio" name="ticket" id="ticket_for_friend" value={_GIFT_CHOICES.FRIEND} className="js-event-type-in js-event-ab" data-require="true" data-type="radio" data-event-target=".js-purchase-options"  data-error-target="#error-purchase" data-required-message="" data-event-fieldname="ticket_for_friend" data-event="form-click" data-category="Form Action" data-action="ticket" data-label="Type In" checked={this.state.GiftChoice === _GIFT_CHOICES.FRIEND}
                                onChange={this.SetChoice.bind(this)}
                            />
                            <span className="radiobtn"></span>
                        </label>
                    </div>

                    {
                        this.state.ErrorMessage.length > 0 ?
                        <p className="buzzer-error">{this.state.ErrorMessage}</p>
                        : null
                    }

                    <button type="submit" className="btn__container btn--red">
                            WEITER
                    </button>
                </form>
            </div>
        );
    }

    SubmitHandler(event) {
        event.preventDefault();

        this.setState({
            SimpleLoader: true,
            ErrorMessage: ""
        })

        switch (this.state.GiftChoice) {
            case _GIFT_CHOICES.USER:
                this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.favoriteClub)
                break;
            case _GIFT_CHOICES.FRIEND:
                var category = localStorage.getItem(_LOCAL.GiftCategory),
                    userDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails)),
                    _data = {
                        WinningCode: userDetails.Code,
                        Email: userDetails.Email,
                        GiftCategory: category
                    }

                userDetails = {
                    ...userDetails,
                    UserType: _USER_TYPE.UserGifted
                };

                localStorage.setItem(_LOCAL.UserDetails, JSON.stringify(userDetails));

                FanCenterServices.GiveToFriendSC(_data)
                .then( response => {
                    let { success, message, data } = response.data;

                    if(success) {
                        localStorage.setItem(_LOCAL.GiftCategory, category)
                        this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend)
                    } else {
                        let {Error, ErrorMessage} = data;
                        this.ProcessError(Error, ErrorMessage);
                    }
                })
                .catch()
                .finally( () => {
                    this.setState({
                        SimpleLoader: false
                    })
                })
                break;
        
            default:
                break;
        }
    }

    ProcessError(ErrorCode, Message = null) {
        if(!!_ERROR_MESSAGES[ErrorCode]) {
            this.setState({
                ErrorMessage: _ERROR_MESSAGES[ErrorCode]
            })
        } else {
            if(Message === null) {
                this.setState({
                    ErrorMessage: "Oops! Something went wrong"
                })
            } else {
                this.setState({
                    ErrorMessage: Message
                })
            }
        }
    }

    ScrollCovidInfo(e) {
        this.setState({
            CovidInfo: true
        })

        _SCROLL_PAGE.ScrollSection(document.getElementById("Covid-Details"))
    } 

    SetChoice(event) {
        let _DOM = event.target,
            value = _DOM.value;

        this.setState({
            GiftChoice: value
        })
    }
}

export default withRouter(ForMeOrFriend);