import { createSlice } from '@reduxjs/toolkit'

// Slice
const gameSlice = createSlice({
  name: 'game',
  initialState: {
    countdown: 5400000,
    gameIsLive: false
  },
  reducers: {
    setGameIsLive: (state, action) => {
      state.gameIsLive = action.payload;
    },
    setCountdown: (state, action) => {
      state.countdown = action.payload;
    },
  }
});

export default gameSlice.reducer;


// Actions
export const { setGameIsLive, setCountdown } = gameSlice.actions;