import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import appGoogleTracking from "../../../modules/googletracking";
import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper React components
import Video from "./Video";

// install Swiper components
SwiperCore.use([Navigation, Pagination, EffectFade]);

class SliderOverview extends Component {
  constructor(props) {
    super(props);
    this.vidRef = React.createRef();
    this.state = { 
      popUp: false,
      videoLink: "",
      thumbnail: "",
      playingVideo: false,
      videoPause: null
    };
    this.showPopUp = this.showPopUp.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setVideoPause = this.setVideoPause.bind(this);
  }
  componentDidUpdate () {
    console.log("video state in overview",this.state.videoPause)
  }
  
  showPopUp(videoLink, thumbnail) {
    this.setState({
      popUp: true,
      videoLink,
      thumbnail
    });
    appGoogleTracking.dataLayerPush({
      'dataEvent': 'video_select',
      'dataCategory': 'Overview Page',
      'dataAction': 'Click on Video',
      'dataLabel': 'OTHER_OP_OTHER'
    });
  }

  toggleModal () {
    this.setVideoPause()
    this.setState(({ popUp }) => ({ popUp: !popUp }));
  };

  setVideoPause () {
    this.vidRef.current.pause();
    this.setState({ videoPause: true });
  }

  render() {
    const { t } = this.props;

    let sliderObj = t("Page.Overview.slider", { returnObjects: true });

    let swiperSlides = sliderObj.map((obj) => {
      return (
        <SwiperSlide key={obj.index}>
          <div className="Slider__wrapper">
            <div className="Slider__right">
              <div className="image__container" onClick={() => this.showPopUp(obj.videoLink,obj.imageDefault)}>
                <img className="banner__image" src={ obj.imageDesktop } alt="Event" />
              </div>
              <div className="Slider__excerpt">
                <div dangerouslySetInnerHTML={{ __html: obj.header }} />
              </div>
            </div>
          </div>
        </SwiperSlide>
      );
    });

    return (
      <div className="overview-slider-wrapper">
        <h2>Die FC Milka Erlebnisse</h2>
        <Swiper
          spaceBetween={20}
          slidesPerView={2}
          breakpoints= {{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              width: 248
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
              width: 348,
              spaceBetween :20
            },
            768: {
              slidesPerView: 3,
              width: 1048
            },
          }}
          autoResize="false"
          navigation
          pagination={{ clickable: false }}
          effect="flip"
          onSlideNextTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: "info-click",
              dataCategory: "Informational Action",
              dataAction: "Carousel Click",
              dataLabel: "Right",
            });
          }}
          onSlidePrevTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: "info-click",
              dataCategory: "Informational Action",
              dataAction: "Carousel Click",
              dataLabel: "Left",
            });
          }}
        >
          {swiperSlides}
        </Swiper>
        
        <div className="popup-caroussel" style={{ display: this.state.popUp ? "" : "none" }}>
          <div className="caroussel-content">
            <Video
              imageMobile={this.state.thumbnail}
              imageDesktop={this.state.thumbnail}
              imageDefault={this.state.thumbnail}
              videoLink={this.state.videoLink}
              setVideoPause={this.state.videoPause}
              refVideo = { this.vidRef }
            />
          </div>
        </div>
        <div onClick={this.toggleModal} className="popup-overlay" style={{ display: this.state.popUp ? "" : "none" }}></div>
      </div>
    );
  }
}

export default withTranslation()(SliderOverview);
