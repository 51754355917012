import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { mainLoaderToggle } from "../../../modules/helpers";
import ParticipationService from "../../../Service/Participation";
import Loader from "../../../components/Overlays/Loader";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import { withRouter } from 'react-router-dom';
import Aux from "../../../hoc/Auxiliare";
import TitleContentV1 from "../../../components/Content/TitleContent/TitleContentv1";
import Match from "../../../Models/Match";
import MatchServices from "../../../Service/Matches";
import Button from "../../../components/Content/Button/Button";
import MobileBuzzerGame2023 from "../../../components/Layout/Game/Buzzer/Mobile2023";
import _LOCAL from "../../../Models/LocalStorageKeys";

class ChooseMatch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isWinner: false,
            isAnimationEnd: false,
            isPressed: false,
            isSelectedMatch: false,
            SelectedMatch: null,
            matches: [],
            TicketChoice: "2",
            SimpleLoader: true,
            isSingleTicket: false
        }

        this.resize = this.resize.bind(this);
        this.InitMatches = this.InitMatches.bind(this);

    }

    componentDidMount() {
        const _LOCAL = {
            status: "__STAT",
            statusFan: "__STAT_FAN",
            data: "__DAT"
        }
        this.InitMatches();

        mainLoaderToggle('hide');
        window.addEventListener("resize", () => {
            this.resize();
        });
        this.resize();
        if (localStorage.getItem(_LOCAL.data) === null) {
            // this.props.history.push(GLOBAL_CONFIG.Route.home);
        } else {
            const data = JSON.parse(localStorage.getItem(_LOCAL.data));
            this.setState({
                isWinner: data.Winner
            });
        }
        setTimeout(() => {
            _SCROLL_PAGE.ScrollToTop();
        }, 200);
    }

    componentWillMount() {
        if(localStorage.getItem(_LOCAL.ProductCode) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.buzzer.main);
        }
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 1023 });
        this.reloadBuzzerVideo();
    }
    
    setGame() {
        this.props.history.push(GLOBAL_CONFIG.Route.buzzer.randomDraw);
    }

    reloadBuzzerVideo() {
        const video = document.getElementById("buzzer-video");
        if (video) {
            video.pause();
            video.load();
        }
    }

    checkedOneMatch() {
        this.setState({
            isSelectedMatch: true
        })
    }

    UpdateSelected(selected) {
        const isSingle = !!selected.isSingleTicket && (selected.isSingleTicket === "true" || selected.isSingleTicket === true) ? true : false;

        this.setState({
            SelectedMatch: selected,
            isSingleTicket: isSingle
        });
        
        localStorage.setItem(_LOCAL.MatchSelected, JSON.stringify(selected));
    }

    InitMatches() {
        // Call API
        MatchServices.GetBuzzerMatches()
            .then(response => {
                this.setState({
                    SimpleLoader: true
                });
                let _data = response.data, succes = response.statusText;
                const matches = [];

                const getDayOfWeek = (date) => {
                    const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
                    const d = new window.Date(date);
                    return days[d.getDay()];
                }

                if (succes === "OK") {
                    _data.forEach(match => {
                        const day = match.fixtureMatchDate ? getDayOfWeek(match.fixtureMatchDate) : '',
                            dateObj = new Date(match.fixtureMatchDate),
                            date = dateObj.getDate().toString().padStart(2, '0') + "." + (dateObj.getMonth() + 1).toString().padStart(2, '0') + "." + dateObj.getFullYear(),
                            matchDate = day + ", " + date;
                        matches.push(
                            new Match({
                                id: match.matchId,
                                date: matchDate,
                                home: match.homeTeam,
                                away: match.awayTeam,
                                outStock: false,
                                location: "Match Location",
                                matchDay: match.gameWeek,
                                gameweek: match.gameWeek,
                                weekendDate: true,
                                isSingleTicket: false
                            })
                        )
                    });

                    this.setState({
                        matches: matches
                    });
                }
            })
            .catch(() => {
                const matches = []
            })
            .finally(() => {
                this.setState({
                    SimpleLoader: false
                })
            })

        // const _data = [
        //     {
        //         "matchId": "393",
        //         "homeTeam": "FC Bayern München",
        //         "awayTeam": "1. FC Köln",
        //         "fixtureStartDate": "Sa, 11.09.2021",
        //         "fixtureEndDate": "Sa, 11.09.2021",
        //         "fixtureMatchDate": "Sa, 11.09.2021",
        //         "gameWeek": "1"
        //     },
        //     {
        //         "matchId": "394",
        //         "homeTeam": "FC Bayern München",
        //         "awayTeam": "Sport-Club Freiburg",
        //         "fixtureStartDate": "Sa, 11.09.2021",
        //         "fixtureEndDate": "Sa, 11.09.2021",
        //         "fixtureMatchDate": "Sa, 11.09.2021",
        //         "gameWeek": "2"
        //     },
        //     {
        //         "matchId": "395",
        //         "homeTeam": "1. FC Union Berlin",
        //         "awayTeam": "FC Bayern München",
        //         "fixtureStartDate": "Sa, 11.09.2021",
        //         "fixtureEndDate": "Sa, 11.09.2021",
        //         "fixtureMatchDate": "Sa, 11.09.2021",
        //         "gameWeek": "1"
        //     },
        //     {
        //         "matchId": "393",
        //         "homeTeam": "Borussia M'gladbach",
        //         "awayTeam": "Sport-Club Freiburg",
        //         "fixtureStartDate": "Sa, 11.09.2021",
        //         "fixtureEndDate": "Sa, 11.09.2021",
        //         "fixtureMatchDate": "Sa, 11.09.2021",
        //         "gameWeek": "1"
        //     }];

    }

    render() {
        return (
            <div id="game-buzzer" className="buzzer-choose">
                <div className="chooseMatch-section">
                    <TitleContentV1
                        heading={'Das Buzzern geht los: Spiel auswählen'}
                        paragraph={'Welches Bundesliga Spiel möchtest du tippen? Einfach eine Partie auswählen und mit etwas Glück ein original Heimtrikot der Bundesliga / 2. Bundesliga der Saison 2022/23 und den Manager-Tag mit Rudi Völler gewinnen.'}
                    />
                </div>
                <div className="listMatch-section">
                    <MobileBuzzerGame2023 matches={this.state.matches} checkedOneMatch={this.checkedOneMatch.bind(this)} selectionUpdate={this.UpdateSelected.bind(this)} selectionAllowed={true}/>
                </div>
                <div className={`listMatch-bouton ${ this.state.isSelectedMatch ? "" : "listMatch-bouton__disabled" }`}>
                    <Button
                        icon={'none'}
                        text={'Weiter'}
                        fill={true}
                        onclick={() => {this.state.isSelectedMatch && this.setGame(); appGoogleTracking.dataLayerPush({'dataEvent': 'buzzer_select_match', 'dataCategory':'Match Page', 'dataAction': 'Select Match', 'dataLabel': `${this.state.SelectedMatch.date}_${this.state.SelectedMatch.home}_${this.state.SelectedMatch.away}`})}}
                    >
                    </Button>
                </div>

                {this.state.SimpleLoader ? <Loader /> : null}

                <Footersocial />
            </div>
        );
    }
}

export default withRouter(ChooseMatch);