import React  from 'react';
import GLOBAL_CONFIG from '../../../config/config';
import ButtonSimple from '../ButtonSimple/ButtonSimple';
import { withRouter } from 'react-router';

import appGoogleTracking from "../../../modules/googletracking";

class SectionTextButton extends React.Component {

    constructor(props) {
        super(props);
    }

    goToFaq() {
        console.log("go to FAQ !!!");
        this.props.history.push(
            this.props.isJersey ? GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faqjersey : GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faq
        );
    }
    componentDidMount() { 
    }

    render(){
        return (
            <div className='section-text-button'>
                <h3 className='section-text-button__text'>{/* Deine Bestätigungs-Mail ist auf dem Weg.<br/> */}du hast noch fragen zur aktion? dann schau einfach in unsere faqs.</h3>
                {/* <button onclick={() => this.goToFaq()}>Go to FAQ</button> */}
                <ButtonSimple text="ZU DEN FAQS"  
                onClick={() => 
                    {
                        this.goToFaq();
                        appGoogleTracking.dataLayerPush({
                                'dataEvent': 	'custom_event_click',
                                'dataCategory': 'click_action',
                                'dataAction': 	'to_faq',
                                'dataLabel': 	'redeem_prize_trikots'
                        });
                    }
                } />
           </div>
        )
    }
}
export default  withRouter(SectionTextButton);