import React from 'react';
import MatchCard from '../../Content/MatchCard/MatchCard'
import CardValidation from '../../Content/CardValidation/CardValidation';
import Button from '../../Content/Button/Button';
import Match from '../../../Models/Match';
import _LOCAL from '../../../Models/LocalStorageKeys';
import GLOBAL_CONFIG from '../../../config/config';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router';
import { withRouter } from 'react-router';
import DynamicButton from '../../Content/dynamicButton/DynamicBoutton';
import { mainLoaderToggle } from '../../../modules/helpers';
import _TICKET_TYPES from "../../../Models/TicketTypes";
import appGoogleTracking from "../../../modules/googletracking";

class MatchList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      SelectedMatch: null,
      matches: {},
      isHome: true,
      itemsToShow: 9,
      next: 9,
      favoriteClub: '',
      UserId: "",
    }

    this.homeList = this.homeList.bind(this);
    this.awayList = this.awayList.bind(this);
  }

  componentDidMount() {
  }

  componentWillMount() {
      this.setState({UserId: localStorage.getItem(_LOCAL.UserId)});
    const club = localStorage.getItem(_LOCAL.FavoriteClub);
    const selectedMatch = localStorage.getItem(_LOCAL.TicketSelected);
    if (!!selectedMatch) {
      this.selectMatch(JSON.parse(selectedMatch));

    }
    this.setState({
      favoriteClub: club,
    })
  }

  selectMatch(match) {
    this.setState({
      SelectedMatch: match,
    })
    this.props.showDetail(true);
  }

  selectTeam() {
    this.props.history.push(
      GLOBAL_CONFIG.Route.fanCenter.tickets.favoriteClub + `?uid=${ this.state.UserId }`
    );
    localStorage.removeItem(_LOCAL.TicketSelected);
  }

  goBack() {
    this.setState({
      SelectedMatch: null,
    })
    this.props.showDetail(false);
  }

  scrollTo(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }, 0);
  }

  confirmMatch(){
    console.log('Match confirmed')
    localStorage.setItem(_LOCAL.TicketSelected, JSON.stringify(this.state.SelectedMatch));
    this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.tickets.userDetails);
  }
  
  homeList () {
    this.setState({
      isHome: true,
    })
    this.props.homeMatch();
  } 
  
  awayList () {
    this.setState({
      isHome: false,
    })
    this.props.awayMatch();
  } 

  showMore () {
    this.setState({
      itemsToShow: this.state.itemsToShow + this.state.next,
    })
  }

  getTicketButtonTag (dataLayerOption, dataLayerOptionVip) {
    this.props.getTicketButtonTag(dataLayerOption, dataLayerOptionVip);
    if(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.CAT2) {
      appGoogleTracking.dataLayerPush(dataLayerOption)
      console.log(dataLayerOption);
  }
  else if (localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.VIP) {
      appGoogleTracking.dataLayerPush(dataLayerOptionVip)
      console.log(dataLayerOptionVip);
  }
    console.log("test",dataLayerOption);
  }

  render() {
    let { matches } = this.props;
    matches = !!matches ? matches : [];
    return (
      <div className='match-list__display'>
        {!this.state.SelectedMatch &&
        <div>
          <div className='match-list__top' id='top'>
          {
            this.state.isHome ? 
            <div>
              <div className="match-list__top__buttons" >
                  <DynamicButton 
                    id={'match-list__button'} 
                    onClick={()=>{
                      this.homeList(this);
                      this.getTicketButtonTag({
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "heimspiele",
                        dataLabel: "heimspiele_standard"}
                        ,{
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "heimspiele",
                        dataLabel: "heimspiele_vip"
                      });
                    }} 
                    isActive={true} text="Heimspiele" />
                  <DynamicButton 
                    id={'match-list__button'} 
                    onClick={()=>{
                      this.awayList(this);
                      this.getTicketButtonTag({
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "auswartsspiele",
                        dataLabel: "auswartsspiele_standard"}
                        ,{
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "auswartsspiele",
                        dataLabel: "auswartsspiele_vip"
                      });
                    }}
                    isActive={false} 
                    text="Auswärtsspiele" 
                  />
              </div>
            </div> :
            <div className="match-list__top__buttons">
                <DynamicButton id={'match-list__button'} onClick={()=>{
                      this.homeList(this);
                      this.getTicketButtonTag({
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "heimspiele",
                        dataLabel: "heimspiele_standard"}
                        ,{
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "heimspiele",
                        dataLabel: "heimspiele_vip"
                      });
                    }}  isActive={false} text="Heimspiele" />
                <DynamicButton id={'match-list__button'} onClick={()=>{
                      this.awayList(this);
                      this.getTicketButtonTag({
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "auswartsspiele",
                        dataLabel: "auswartsspiele_standard"}
                        ,{
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "auswartsspiele",
                        dataLabel: "auswartsspiele_vip"
                      });
                    }} isActive={true} text="Auswärtsspiele" />
            </div>
          }
            <div className='match-list__top__club'>
              <div className='match-list__top__club__top'>
                Dein Lieblingsclub
              </div>
              <div className='match-list__top__club__bottom'>
                <div className='match-list__top__club__selected'>
                  <div className='match-list__top__club__selected__image'>
                    <img src={'/resources/imagesBundesliga/team-picture/'+this.state.favoriteClub+'.png'} alt= { 'team' }/>
                  </div>
                  <div className='match-list__top__club__selected__name'>
                    {this.state.favoriteClub}
                  </div>
                </div>
                <div className='match-list__top__club__button'>
                  <Button
                    text={'×  Ändern'}
                    icon={'none'}
                    onclick={() => {
                      this.selectTeam();
                      this.getTicketButtonTag({
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "andern",
                        dataLabel: "andern_standard"}
                        ,{
                        dataEvent: "custom_event_click",
                        dataCategory: "click_action",
                        dataAction: "andern",
                        dataLabel: "andern_vip"
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='match-list__container'>
            {matches?.slice(0, this.state.itemsToShow)?.map((item, index) => {
              return (
              <MatchCard 
                isActive={(item.NumberAvailable <=1 ? false : true)}
                match={item}
                onclick={() => {
                  this.selectMatch(item);
                  this.getTicketButtonTag({
                    dataEvent: "custom_event_click",
                    dataCategory: "click_action",
                    dataAction: "spiel_auswahlen",
                    dataLabel: "spiel_auswahlen_standard"}
                    ,{
                    dataEvent: "custom_event_click",
                    dataCategory: "click_action",
                    dataAction: "spiel_auswahlen",
                    dataLabel: "spiel_auswahlen_vip"
                  });
                }}
                key={item.ID}
              />
              )
            })}
          </div>
          <div className='match-list__cta'>
            {this.state.itemsToShow < matches?.length && (
              <Button
                icon={'none'}
                text={'Weitere Spiele +'}
                fill={true}
                onclick={() => this.showMore()}
              >
                Load more
              </Button>
            )}
          </div>
          <div className='match-list__cta-mobile'>
              <Button
                icon={'none'}
                text={'Heim- oder Auswärtsspiele auswählen'}
                image={'chevron-up.png'}
                onclick={() => this.scrollTo('top')}
              />
          </div>
        </div>
        }
        { this.state.SelectedMatch ? this.showSelectedPage(matches):""  }
      </div>
    )
  }
  showSelectedPage(matches) {
    if (  matches.length === 0) {
      mainLoaderToggle('show'); 
      return "";
    } else {
      
      mainLoaderToggle('hide')
    }
    return (
      (this.state.SelectedMatch && matches.length !== 0 ) &&
       <div>
             <div className='match-detail__top'>
                <Button 
                    text={'<  Zurück'}
                    icon={'none'}
                    onclick={() => this.goBack()}
                /> 
                <h1 className='match-detail__top__title'>
                  dein ausgewähltes spiel
                </h1>
              </div>
                {
                  matches.filter(
                    (item) => item.ID === this.state.SelectedMatch.ID
                  ).map((item, index) => 
                  { return (
                      <MatchCard 
                        small={false}
                        match={item}
                        key={item.ID }
                      />
                    )} )
                }
              <div className='match-detail__bottom'>
                <CardValidation
                  withText={'2 Tickets für dieses Spiel'}
                  buttonText={'BESTÄTIGEN'}
                  onClick={() => {
                    this.confirmMatch();
                    this.getTicketButtonTag({
                      dataEvent: "custom_event_click",
                      dataCategory: "click_action",
                      dataAction: "bestatigen",
                      dataLabel: "bestatigen_standard"}
                      ,{
                      dataEvent: "custom_event_click",
                      dataCategory: "click_action",
                      dataAction: "bestatigen",
                      dataLabel: "bestatigen_vip"
                    });
                  }}
                />
                <p>
                (Hiermit bestätigst du deine Auswahl: Klick auf den Button)
                </p>
              </div>
            </div>
    )
  }
}

export default withRouter(MatchList);
