import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { mainLoaderToggle } from '../../../../modules/helpers';
import TitleLogo from '../../../../components/Content/TitleLogo/TitleLogo';
import Footersocial from '../../../../components/Layout/FooterContent/FooterSocial';
import GLOBAL_CONFIG from '../../../../config/config';
import JerseyNavigation from '../JerseyNavigation';
import _TICKET from '../../../../Models/TicketImages';
import _SCROLL_PAGE from '../../../../modules/ScrollPage';
import _LOCAL from '../../../../Models/LocalStorageKeys';
import _STATUS from '../../../../Models/ParticipationSteps';
import _USER_TYPES from '../../../../Models/UserTypes';
import { withRouter } from 'react-router-dom';

class CompanionConfirmation extends Component {
    constructor() {
        super();

        this.state = {
            CovidInfo: false,
            UserType: "",
            WinnerName: "Winner Name",
            MatchDate: "Sa. 01.01.2021",
            MatchLocation: "Match Location",
            HomeTeam: "Home Team",
            AwayTeam: "Away Team",
            Gift: _TICKET.STD
        }
    }
    componentDidMount() {
        if(localStorage.getItem(_LOCAL.UserDetails) === null) {
            this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user)
        }
        let UserDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));

        if(!!UserDetails) {
            this.setState({
                UserType: UserDetails.UserType,
                WinnerName: UserDetails.Name,
            });

            if(!!UserDetails.Match) {
                this.setState({
                    MatchDate: UserDetails.Match.weekendDate ? `Spieltag ${UserDetails.Match.gameweek} (${UserDetails.Match.weekendDate})` : `${UserDetails.Match.date} Uhr`,
                    MatchLocation: UserDetails.Match.location,
                    HomeTeam: UserDetails.Match.home,
                    AwayTeam: UserDetails.Match.away,
                    Gift: _TICKET[localStorage.getItem(_LOCAL.GiftCategory)] || _TICKET.STD
                });
            }
        }
        _SCROLL_PAGE.ScrollToBanner();
        mainLoaderToggle('hide');
    }

    
    componentWillUnmount() {
        localStorage.clear()
        mainLoaderToggle('show');
    }

    render() {
        return (
            <div className="buzzer-jersey" id="confirmation">
                { this.HeadingSection() }
                <hr className="separator" />
                { this.FooterSection() }

                <div id="Covid-Details">
                    {
                        this.state.CovidInfo ? 
                        <div className="back--red">
                            <p>
                                Auf Grund der aktuellen COVID-19 Situation erhalten wir die Tickets sehr kurzfristig vor dem Spieltag von den Clubs.
                                <br />
                                Das Milka Team hat keinen Einfluss auf die Ticketausstellung, das wird seitens der einzelnen Clubs geregelt. Es ist uns darum nicht möglich, nähere Angaben zum genauen Versandzeitpunkt zu machen.
                                Bitte berücksichtige auch, dass einige Clubs insbesondere bei steigenden Inzidenzzahlen überlegen, den Zugang zum Stadion nur vollständig Geimpften oder genesenen Zuschauern zu gestatten. Diese Entscheidung und Verantwortung obliegt ausschließlich den jeweiligen Clubs.
                                Wir bitten um deine Geduld und dein Verständnis. Bitte informiere dich vor dem Stadionbesuch bei den entsprechenden Stellen.
                            </p>
                        </div> : null
                    }
                </div>

                <Footersocial />
            </div>
        )
    }

    HeadingSection() {
        return (
            <div>
                <JerseyNavigation />
                <TitleLogo />
                <div className="spaceTile-intropage">
                    <div className="campaign-heading">
                        {
                            this.state.UserType === _USER_TYPES.Comp1 ?
                            <>
                                <h2 className="lilac">
                                    Deine Eingabe war erfolgreich, <br />
                                    <span id="winner-name">{this.state.WinnerName}</span>!
                                </h2>
                                <button className="btn__container btn--secondary-dark" onClick={this.ScrollCovidInfo.bind(this)}>COVID-19 Infos</button>
                                <h4 className="lilac recoleta-medium">
                                    Dein gewähltes Spiel der 
                                    <br />Bundesliga / 2. Bundesliga ist am {this.state.MatchDate} in {this.state.MatchLocation} für
                                    <br />die Begegnung {this.state.HomeTeam} gegen {this.state.AwayTeam}.
                                </h4>

                                <p className="lilac">
                                    Bitte berücksichtige, dass einige Clubs bei steigenden Inzidenzzahlen überlegen, den Zugang zum Stadion nur vollständig Geimpften oder genesenen Zuschauern zu gestatten.
                                    Bitte informiere dich vor dem Stadionbesuch bei den entsprechenden Stellen.
                                </p>
                                <br />

                                <p className="lilac">
                                    Zu diesem Spiel verschicken wir zwei Tickets. 
                                    Diese Tickets müssen corona-bedingt personalisiert sein. 
                                    Deshalb erhältst du in Kürze eine Mail vom FC Milka mit einem neuen Zugangscode für das Milka Fan-Center für deine Begleitperson. 
                                    <strong>Wichtig: Leite diese E-Mail gleich weiter an die Begleitung deiner Wahl.</strong> 
                                    Deine Begleitperson muss ihre Daten im Fan-Center selbst eingeben. 
                                    Wenn du keine Begleitperson auswählst, verfällt dein Anspruch auf das 2. Ticket ersatzlos. 
                                    Die personalisierten Tickets werden dir und deiner Begleitung in der Regel per E-Mail zugeschickt, alternativ per Post an die angegebene Adresse.
                                </p>
                            </>
                            : 
                            <>
                                <h2 className="lilac">
                                    Deine Eingabe war erfolgreich!
                                </h2>
                                <button className="btn__container btn--secondary-dark" onClick={this.ScrollCovidInfo.bind(this)}>COVID-19 Infos</button>
                                <h4 className="lilac recoleta-medium">
                                    Dich erwartet ein Ticket für das Spiel der 
                                    <br /> Bundesliga / 2. Bundesliga am {this.state.MatchDate} in {this.state.MatchLocation} für
                                    <br /> die Begegnung {this.state.HomeTeam} gegen {this.state.AwayTeam}.
                                </h4>

                                <p className="lilac">
                                    Bitte berücksichtige, dass einige Clubs bei steigenden Inzidenzzahlen überlegen, den Zugang zum Stadion nur vollständig Geimpften oder genesenen Zuschauern zu gestatten.
                                    Bitte informiere dich vor dem Stadionbesuch bei den entsprechenden Stellen.
                                </p>
                                <br />

                                <p className="lilac">
                                    Dein personalisiertes Ticket wird dir in der Regel per E-Mail zugeschickt, alternativ per Post an die angegebene Adresse. 
                                </p>
                            </>
                        }

                        <img id="ticket-img" src={`${this.state.Gift}`} alt="Ticket"/>

                        {
                            this.state.UserType === _USER_TYPES.Comp1 ?
                            <p className="lilac">
                                Du freust dich und möchtest gerne Danke sagen? Dann besuch doch gleich den Milka Shop und überrasche deinen Freund oder deine Freundin mit einem tollen personalisierbaren Geschenk von Milka.
                            </p>
                            : null
                        }
                        {
                            this.state.UserType === _USER_TYPES.Comp1 ?
                            <p className="lilac">
                                Hinweis: Sollten Tickets wegen eines Ausschlusses oder einer Begrenzung der Öffentlichkeit aufgrund von andauernden Beschränkungen durch die COVID 19 Pandemie in der Saison 22/23, nicht genutzt werden können, obliegt die Kompensation dieser den aktuellen Auflagen der Clubs. Wir weisen noch einmal darauf hin, dass für die Inanspruchnahme der Tickets dann ausschließlich die Allgemeinen Geschäftsbedingungen sowie Datenschutzhinwiese des jeweiligen Clubs gelten. Diese sind dem Ticket und dem Online Auftritt des Clubs zu entnehmen.
                            </p>
                            :
                            <p className="lilac">
                                Hinweis: Sollten Tickets wegen eines Ausschlusses oder einer Begrenzung der Öffentlichkeit aufgrund von andauernden Beschränkungen durch die COVID 19 Pandemie in der Saison 22/23, nicht genutzt werden können, obliegt die Kompensation dieser den aktuellen Auflagen der Clubs. Wir weisen noch einmal darauf hin, dass für die Inanspruchnahme der Tickets dann ausschließlich die Allgemeinen Geschäftsbedingungen sowie Datenschutzhinwiese des jeweiligen Clubs gelten. Diese sind dem Ticket und dem Online Auftritt des Clubs zu entnehmen.
                            </p>
                        }
                    </div>
                </div>
            </div>
        );
    }

    FooterSection() {
        return (
            <div className="prizeRewe campaign-footer">
                <h3>Du freust dich und möchtest gerne Danke sagen? Dann besuch doch gleich den Milka Shop und überrasche deinen Freund oder deine Freundin mit einem tollen personalisierbaren Geschenk von Milka.</h3>
                
                <img id="milka-products" src="/resources/imagesBundesliga/fancenter/milka-products.png" alt="Milka Product" />
                
                <a href="https://www.milka.de/shop" className="no-decoration" target="_blank">
                    <button className="btn__container btn--red">
                        ZUM MILKA SHOP
                    </button>
                </a>
            </div>
        );
    }

    ScrollCovidInfo(e) {
        this.setState({
            CovidInfo: true
        })

        _SCROLL_PAGE.ScrollSection(document.getElementById("Covid-Details"))
    } 

}

export default withRouter(CompanionConfirmation);