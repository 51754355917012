import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import appGoogleTracking from "../../../modules/googletracking";
import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper React components



// install Swiper components
SwiperCore.use([Navigation, Pagination, EffectFade]);

class SliderVariety extends Component {
  constructor(props) {
    super(props);
    this.vidRef = React.createRef();
/*     this.state = { 
      popUp: false,
      videoLink: "",
      thumbnail: "",
      playingVideo: false,
      videoPause: null
    }; */
/*     this.showPopUp = this.showPopUp.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setVideoPause = this.setVideoPause.bind(this); */
  }
  componentDidUpdate () {
    /* console.log("video state in overview",this.state.videoPause) */
  }
  
/*   showPopUp(videoLink, thumbnail) {
    this.setState({
      popUp: true,
      videoLink,
      thumbnail
    });
  } */

/*   toggleModal () {
    this.setVideoPause()
    this.setState(({ popUp }) => ({ popUp: !popUp }));
  };

  setVideoPause () {
    this.vidRef.current.pause();
    this.setState({ videoPause: true });
  } */

  render() {
    const { t } = this.props;

    let sliderObj = t("Page.trikotPromo.slider", { returnObjects: true });

    let swiperSlides = sliderObj.map((obj) => {
      return (
        <SwiperSlide key={obj.index}>
          <div className="Slider__wrapper" style={{backgroundColor: obj.backgroundColor}}>
            <div className="Slider__right">
                <div className="Slider__excerpt">
                    <div dangerouslySetInnerHTML={{ __html: obj.header }} style={{color: obj.textColor}}/>
                </div>
                <div className="image_container" /* onClick={() => this.showPopUp(obj.videoLink,obj.imageDefault)} */>
                    <img className="banner__image" src={ obj.imageDesktop } alt="Event" />
                </div>
                <button className="btn__container btn--secondary-lilac">
                    <a href={ obj.Link }
                      target="_blank">
                      jetzt entdecken
                    </a>
                </button>
            </div>
          </div>
        </SwiperSlide>
      );
    });

    return (
      <div className="overview-slider-wrapper trikot">
        <Swiper
          spaceBetween={20}
          slidesPerView={2}
          breakpoints= {{
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                width: 300,
                centeredSlides : true
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
              width: 350,
              spaceBetween :20,
              centeredSlides : true
            },
            859: {
              slidesPerView: 1,
              width: 674,
              spaceBetween :20,
              centeredSlides : true
            },
          }}
          autoResize="false"
          navigation
          pagination={{ clickable: false }}
          effect="flip"
          centeredSlides = "true"
          onSlideNextTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: "info-click",
              dataCategory: "Informational Action",
              dataAction: "Carousel Click",
              dataLabel: "Right",
            });
          }}
          onSlidePrevTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: "info-click",
              dataCategory: "Informational Action",
              dataAction: "Carousel Click",
              dataLabel: "Left",
            });
          }}
        >
          {swiperSlides}
        </Swiper>
        
{/*         <div className="popup-caroussel" style={{ display: this.state.popUp ? "" : "none" }}>
          <div className="caroussel-content">
            <Video
              imageMobile={this.state.thumbnail}
              imageDesktop={this.state.thumbnail}
              imageDefault={this.state.thumbnail}
              videoLink={this.state.videoLink}
              setVideoPause={this.state.videoPause}
              refVideo = { this.vidRef }
            />
          </div>
        </div> */}
        {/* <div onClick={this.toggleModal} className="popup-overlay" style={{ display: this.state.popUp ? "" : "none" }}></div> */}
      </div>
    );
  }
}

export default withTranslation()(SliderVariety);
