import React from "react";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import PopUp from "./PopUp";
import { isDoppeltPromotion } from "../../../modules/helpers";
import _LOCAL from "../../../Models/LocalStorageKeys";
import { isProd } from "../../../modules/helpers";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      modal: false,
      baseNamePath: null,
    };
    this.resize = this.resize.bind(this);
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  resize() {
    // set isMobile to true if the device is a mobile and false otherwise
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    window.addEventListener("resize", this.resize);
    this.resize();
    //_SCROLL_PAGE.ScrollToTop();
  }

  componentWillMount() {
    //_SCROLL_PAGE.ScrollToTop();
    /*         localStorage.removeItem(_LOCAL.PlayerValue);
        localStorage.removeItem(_LOCAL.ProductCode); */
    localStorage.clear();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const kipGlobus = urlParams.get("kip");
    const poupOpen = urlParams.get("status");

    if (kipGlobus) {
      localStorage.setItem(_LOCAL.CurrentKip, "Globus");
    }

    if (poupOpen) {
      this.setState({
        modal: true,
      });
    }
    console.log("poupOpen:", poupOpen)
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }
  
  render() {
    return (
      <div id="main-buzzer" className="weltmeister-main tickets-main trikot-main doppelt-main">
        {this.headingSection(this.props)}
        {this.midSection()}
        {this.lastSection()}
        <Footersocial />
      </div>
    );
  }

/*   productCodeLink() {
    const product_code_link = document.getElementById("product-code-link");
    product_code_link.addEventListener("click", () => {
      this.setState({ modal: true });
    });
  } */

  modalTracking(event) {
    this.setState({ modal: true });
    appGoogleTracking.processEventCTANavLink(event);
  }

  headingSection(props) {
    return (
      <div>
        <div className="content-home">
          <div className="spaceTile-intropage">
            <div className="campaign-heading">
              {/* <h1>Mach mit beim großen <br />FC Milka Ticket Gewinnspiel!</h1> */}
              <p>
               Gib jetzt deinen Milka Produktcode ein und erfahre sofort, ob und was du gewonnen hast. Doch das ist noch nicht alles. Dank deiner Teilnahme siehst du gleichzeitig, für welchen guten Zweck der FC Milka spendet. 
              </p>
            </div>
            <div id="participate"></div>
            <h2>So einfach geht’s:</h2>

            <div className="s-content-steps step-weltmeister step-tickets-promo">
              <div className="s-content-steps__item">
                  <picture>
                      <img width="119px" src="/resources/imagesBundesliga/doppeltgewinnen/steps/1.png" alt="Milka product" />
                  </picture>
                  <p>
                    Kaufe zwischen dem <span class="text--wheat">16.10.2023</span> und dem <span class="text--wheat">31.12.2023</span> ein Aktionsprodukt von Milka.
                  </p>
              </div>
              <div className="s-content-steps__item">
                  <picture>
                      <img width="119px" className="step_image step_image--website" src="/resources/imagesBundesliga/doppeltgewinnen/steps/2.png" alt="Milka product" />
                  </picture>
                  <p>
                    Gib <div id="product-code-link"  
                      data-event="begin_participation_link"
                      data-category="Start Page"
                      data-action="Click on Link"
                      data-label="GAME_SP_OTHER" 
                      onClick={(e) => {this.modalTracking(e)}}>hier
                    </div> bis zum <span class="text--wheat">31.12.2023</span> den <span class="text--wheat"> Produktcode </span>von der Produktverpackung ein*.           
                  </p>
              </div>
              <div className="s-content-steps__item">
                  <picture>
                      <img width="119px" className="step_image step_image--ball" src="/resources/imagesBundesliga/doppeltgewinnen/steps/3.png" alt="Milka product" />
                  </picture>
                  <p> 
                    Mitmachen und mit etwas Glück gewinnen.
                  </p>
              </div>
            </div>

            <p className="note-promo-code">
            *Hinweis: Befindet sich kein Produktcode auf dem Artikel, müssen die Zahlen des Barcodes eingegeben werden. Mehrfachteilnahmen mit neuem Produktcode innerhalb der Aktionsprodukte erlaubt. Bitte Original-Kaufbeleg als Nachweis aufbewahren.
            </p>

            <div className="cta-container">
              <button
                className="btn__container btn--secondary-lilac"
                data-event="begin_participation_middle"
                data-category="Start Page"
                data-action="Click on Join Now"
                data-label="GAME_SP_OTHER"
                onClick={(e) => {
                  this.modalTracking(e)
                }}
              >
                JETZT MITMACHEN
              </button>
            </div>
            <PopUp
              modal={this.state.modal}
              toggleModal={() => {
                this.toggleModal();
              }}
            />

            <p id="edeka-terms">
              Hier findest du die{" "}
              <a
                href={!isProd() ? `/doppeltgewinnen${GLOBAL_CONFIG.Route.terms}` : `${GLOBAL_CONFIG.Route.terms}`}
                target="_blank"
                style={{ color: "white" }}
                onClick={appGoogleTracking.processEventCTA}
                data-event="click_conditions"
                data-category="Start Page"
                data-action="Click on Conditions of Participation"
                data-label="ENG_SP_OTHER"
              >
                {" "}
                Teilnahmebedingungen
              </a>{" "}
              und{" "}
              <a
                href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/"
                target="_blank"
                style={{ color: "white" }}
                onClick={appGoogleTracking.processEventCTA}
                data-event="click_privacy_policy"
                data-category="Start Page"
                data-action="Click on Data Protection Regulations"
                data-label="ENG_SP_OTHER"
              >
                {" "}
                Datenschutzbestimmungen
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }

  midSection() {
    return (
      <div className="trikot-promo-container">
        <h3>Über 25.000 Preise warten auf dich</h3>

        <div className="trikot-promo-container-wrapper">
          <div className="trikot-promo-card">
            <div className="trikot-promo__content primary">
              <h4>
                <span class="good-headline-gold">100 x</span> 2 VIP-Tickets
              </h4>
              <p>
                Sei beim Spiel-Anpfiff hautnah dabei und lass dich von der Live-Atmosphäre mitreißen. Mit dem VIP-Ticket für ein Spiel der Bundesliga / 2. Bundesliga der Saison 2023/24 für dich und eine weitere Person sorgst du für einen unvergesslichen Stadionbesuch.
              </p>
            </div>
            <div className="trikot-promo__content secondary">
              <img src="/resources/imagesBundesliga/\doppeltgewinnen/card/tickets.png" alt="tickets"/>
            </div>
          </div>
        </div>

        <div className="trikot-promo-container-wrapper">
          <div className="trikot-promo-card reverse">
            <div className="trikot-promo__content primary">
              <h4>
                Die perfekte Fussball-Ausstattung
              </h4>
              <p>
                Hier schlägt das Fußballherz höher: Gewinne 1 von 100 Fanshop Gutscheinen à 100€ der Bundesliga / 2. Bundesliga für einen Club deiner Wahl. Des Weiteren wartet auf dich 1 von 10.000 Rabattgutscheinen in Höhe von 20 % für 11teamsports. Dort wirst du garantiert fündig, um deine Ausrüstung zu komplettieren.
              </p>
            </div>
            <div className="trikot-promo__content secondary">
              <img src="/resources/imagesBundesliga/\doppeltgewinnen/card/t-shirt.png" alt="t-shirt"/>
            </div>
          </div>
        </div>

        <div className="trikot-promo-container-wrapper">
          <div className="trikot-promo-card">
            <div className="trikot-promo__content primary">
              <h4>
                <span class="good-headline-gold">1.000 x</span> 1 Bundesliga Ball von DERBYSTAR
              </h4>
              <p>
              Gewinne einen <strong>Bundesliga Ball</strong>, Modell Bundesliga Brilliant Replica, Saison 23/24 von DERBYSTAR.<br/>Dein perfekter Begleiter für eine Fußball Partie mit Freunden.
              </p>
            </div>
            <div className="trikot-promo__content secondary">
              <img src="/resources/imagesBundesliga/\doppeltgewinnen/card/derbystar.png" alt="derbystar"/>
            </div>
          </div>
        </div>
        <div className="trikot-promo-container-wrapper last">
          <div className="trikot-promo-card reverse">
            <div className="trikot-promo__content primary">
              <h4>
                KICKER EMAGAZINE JAHRESABO ODER EINZELAUSGABEN GEWINNEN
              </h4>
              <p>
                Mit dem kicker bist du über alles, was in der Fußballwelt passiert, bestens informiert. Gewinne jetzt 1 von 150 digitalen Jahresabos oder 1 von 15.000 digitalen Einzelausgaben in der kicker eMagazine App.
              </p>
            </div>
            <div className="trikot-promo__content secondary">
              <img src="/resources/imagesBundesliga/\doppeltgewinnen/card/magazine.png" alt="k eMagazine"/>
            </div>
          </div>
        </div>
        <div className="cta-container">
              <button
                className="btn__container btn--secondary-lilac"
                data-event="begin_participation_bottom"
                data-category="Start Page"
                data-action="Click on Join Now"
                data-label="GAME_SP_OTHER"
                onClick={(e) => {
                  this.modalTracking(e)
                }}
              >
                JETZT MITMACHEN
              </button>
            </div>

      </div>
    );
  }
  lastSection() {
    return (
      <>
        <h4 className="white-title">
          Projekte für den guten Zweck
        </h4>
        <div className="charity-icon">
          <img src="/resources/imagesBundesliga/\doppeltgewinnen/charity-icon.png" alt="charity-icon"/>
        </div>
        <div className="player-wrapper">   
          <div className="player-wrapper__content">   
            <img src="/resources/imagesBundesliga/\doppeltgewinnen/rudi.png" alt="rudi"/>
            <h4>
              Gutes tun mit Rudi Völler
            </h4>
            <p>
              Doppelt gewinnen – für dich und einen guten Zweck. Dank deiner Teilnahme unterstützen der FC Milka und Rudi Völler die Fußball-Ferien-Freizeiten der DFB-Stiftung Egidius Braun. Für das Jahr 2024 wird die Völkerverständigung und Integration das Schwerpunktthema sein. 1.000 Teilnehmende werden an 18 abwechslungsreichen Programmen teilnehmen und neben verschiedenen Fußballangeboten, Ausflüge in Kletterparks, Besuche verschiedener Bundesliga-Clubs, Wertedialoge sowie Besuche prominenter Gäste erleben.
            </p>
            <img className="logo-association" src="/resources/imagesBundesliga/\doppeltgewinnen/FuFeFrei_Logo.png" alt="FuFeFrei_Logo"/>
          </div>
          <div class="player-wrapper__content">   
            <img src="/resources/imagesBundesliga/\doppeltgewinnen/lukas.png" alt="lukas"/>
            <h4>
              Gutes tun mit Lukas Podolski
            </h4>
            <p>
              Doppelt gewinnen – für dich und einen guten Zweck. Dank deiner Teilnahme unterstützt der FC Milka zusammen mit der Lukas Podolski Stiftung das Projekt „Mutmacher“ der Arche. Dieses schenkt Jugendlichen Mut und Hoffnung. Durch Bildung, Workshops und persönliche Betreuung wird das Selbstvertrauen gestärkt und die Selbständigkeit erhöht. Lukas Podolski & Milka schaffen so neue Möglichkeiten für junge Menschen und unterstützen die Chancengleichheit in Deutschland.
            </p>
            <img className="logo-association" src="/resources/imagesBundesliga/\doppeltgewinnen/LP_Stiftung.png" alt="LP_Stiftung"/>
          </div> 
        </div>
      </>
    )
  }
}

export default withTranslation()(Main);
