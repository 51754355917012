import React, { Component } from 'react'; 
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import GLOBAL_CONFIG from '../../config/config';
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle } from '../../modules/helpers';
import TitleLogo from "../../components/Content/TitleLogo/TitleLogo";
import Footersocial from "../../components/Layout/FooterContent/FooterSocial";

class End extends Component {
    
    componentDidMount(){
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');

        //Virtual page
        appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': GLOBAL_CONFIG.Route.end,
                'pageTitle': t('Meta.title')
            }
        });
    }

    componentWillUnmount(){
        //Show mainloader
        mainLoaderToggle('show');
    }

    render() { 

        const { t } = this.props;

        return (
            <div className="content-holding__heading closing">
                <div className="content-end__text">
                    <h2>
                        {parse(t('Page.End.title'))}
                    </h2>
                    <p>
                        {parse(t('Page.End.subtitle'))}
                    </p>
                </div>
                <div className="s-content-end">

                    
                    <div className="end-element">
                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-1.png" />
                            <div className="end-element__copy">
                                    <h3>Zarte Vielfalt</h3>
                                    <p>Für jeden Anlass bietet dir Milka eine große Auswahl zum Naschen, Teilen und Verschenken.</p>
                                <a href="https://www.milka.de/shop" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> JETZT VERSCHENKEN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/box-2.png" />
                            <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                    Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                    </p>
                                <a href="https://www.milka.de/neues" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/fancenter-box-3.png" />
                            <div className="end-element__copy">
                                    <h3>Milka Engagement</h3>
                                    <p>
                                    Du möchtest mehr über das Engagement von Milka im Kakaoanbau erfahren? Oder wissen, warum Milka so zart ist?
                                    </p>
                                <a href="https://www.milka.de/uber-milka" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> MEHR ÜBER MILKA </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footersocial />
            </div>
        );
    }
  
}

export default withTranslation()(End);