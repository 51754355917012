import React from 'react'
import './Button.scss'
export default function Button(props) {
    return (
        <div className="heimtrikot">
             <button className='_btnreihe' onClick={props.onClick}>
            <p className='content_Btn'>
                {props.children}
            </p>
        </button>
        </div>
       
    )
}
