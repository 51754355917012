import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config'; 
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import { servicePath, mainLoaderToggle, getCookie} from '../../modules/helpers'; 
import ValidateForm from '../../modules/validate';  
import InputPassword from "../InputPassword/InputPassword";
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import ParticipationService from '../../Service/Participation';
import { connect } from "react-redux";
import Loader from '../../components/Overlays/Loader';

class ProductCodeVerification extends Component {
    constructor(props) {
        super(props);
        
        this.state = {                   
            birthdayAge: 18,                          //User need to be at least 18 - use in flapickr
            loaderSubmit: false,
            loaderError: false,
            loaderGeneralError: false,
            promotionCampaign: window.PROMOTION_CAMPAIGN,
            emailError: null,
            loginError:null,
            accountActivated:null,
            isGameOver: false,
            SimpleLoader : false,
            isWin: true,
        };

        this.checkEmail = this.checkEmail.bind(this);
        this.processErrorEmail = this.processErrorEmail.bind(this);
    }

    componentWillMount(){
        console.log(getCookie(GLOBAL_CONFIG.Session.tokenstatus));
        
        if(getCookie(GLOBAL_CONFIG.Session.tokenstatus) != null){
            let getLoginDetailURL = servicePath({
                ...GLOBAL_CONFIG.ServiceSettings,
                campaign: this.state.promotionCampaign,
                status: 'details'
            });
            let tokenInfo = getCookie(GLOBAL_CONFIG.Session.tokenstatus)
            
            console.log(tokenInfo);
            axios({
                method: 'POST',
                url: getLoginDetailURL,
                data: {
                    "token":tokenInfo
                },
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                console.log(response)
                let _data = response.data;
                if(_data.success) {
                    this.setState({
                        tokenLogin:response.data.token,
                        loaderSubmit: false,
                        loaderGeneralError: false,
                    });
                    this.props.history.push({pathname: GLOBAL_CONFIG.Route.loginDetail});
                }
            })
            .catch((response) => {
            //Go to particaption page
            this.props.history.push({pathname: GLOBAL_CONFIG.Route.account});
        });
    } 
    }
    componentDidMount(){
        mainLoaderToggle('hide');
          //Assign Validation function
        ValidateForm.validateField('frm_login'); 
       
    }
    componentWillUnmount(){
        mainLoaderToggle('show');

        
    }
      //Close error message popup
      onClosePopupHandler = () => {
        this.setState({
            loaderError: false,
            loaderGeneralError: false
        });
    }

    //Form for login
    loginHandler = (event) =>{
        event.preventDefault();
        let validateFormInfo = ValidateForm.validateForm('frm_login');
        if(validateFormInfo === true){
            let data = {
                Email: document.getElementById('email').value
            };
            this.checkEmail(data);
        }
    }

    checkEmail(_data){
        const { gameIsLive } = this.props.gameState;
        const { UpdateParticipationData, UpdateParticipationStatus} = this.props;
        if(!gameIsLive){
            this.setState({
                loginError : true,
                isGameOver : !gameIsLive
            });
        } else {
            this.setState({
                SimpleLoader: true
            });
    
            ParticipationService.ValidateWinner(_data).then((response)=>{
                console.log(response);
                let { success, data } = response.data;
                    if (success) {
                        const {IsWinner, PrizeWon} = data;
                        const _Data = {
                            Winner: IsWinner,
                            PrizeWon : PrizeWon,
                        };
                        UpdateParticipationData(_Data);
                        UpdateParticipationStatus();
                    } else {
                        const { Error } = data;
                        const errorMessage = !!Error && Error.length > 0 ? Error[0] : '';
                        this.processErrorEmail(errorMessage);
                    }
            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{
                this.setState({
                    SimpleLoader: false
                });
            });
        }
    }

    processErrorEmail(error){
        switch(error) {
            case "NO_PARTICIPATION_FOUND":
                this.props.history.push(GLOBAL_CONFIG.Route.NoProductCode);
                break;
            case "CONSUMERID_NOT_FOUND":
                this.props.history.push(GLOBAL_CONFIG.Route.NoProductCode);
                break;
            default :
                this.props.history.push(GLOBAL_CONFIG.Route.NoProductCode);
                break;
        }
    }

    _loginRequest = (url, reqData) => {
        axios({
            method: 'post',
            url: url,
            data: reqData,
            headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {
            console.log(response);
            let _data = response.data;
            if(_data.success === true) {
                this.setState({
                    loaderGeneralError: false,
                    tokenLogin: _data.token
                });
                //Set user participation
                // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, 'participate'); 
                // let userStatus = response.data.data.IsWinner ? 'win' : 'lose';
                // //Set user as Winner or Loser
                // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, userStatus);    //win or lose
                // let loginStatus = response.data.data.IsLogin ? 'login' : 'notlogin';
                // sessionStorage.setItem(GLOBAL_CONFIG.Session.loginstatus, tokenLogin);

                sessionStorage.setItem(GLOBAL_CONFIG.Session.tokenstatus,_data.token);
                document.cookie = GLOBAL_CONFIG.Session.tokenstatus + '=' + _data.token + ';domain=' + GLOBAL_CONFIG.DomainName;

                // //SET Participation ID
                // window.PARTICIPATION_ID = response.data.ParticipationId;

                // Datalayer push for Success participation
                // this.triggerDatalayers({
                //     status: 'success',
                //     participationID: response.data.ParticipationId
                // });
                // //Go to Thank you page
                this.props.history.push({pathname: GLOBAL_CONFIG.Route.loginDetail});

            } else {
                let errorStatus = _data.message;

                switch (errorStatus) {
                    case 'Username or Password invalid':
                        console.log('username error');
                        this.setState({
                            loaderSubmit: false,
                            loginError:true
                        });
                        break;
                    case 'Account was not activated':
                        console.log('account activation error');
                        this.setState({
                            loaderSubmit: false,
                            accountActivated:true
                        });
                    break;
                    case 'INVALID_RECAPTCHA_RESPONSE':
                        console.log('Captcha error message');

                        //Datalayer push for Error
                        this.triggerDatalayers({
                            status: 'error',
                            type: 'general'
                        });

                        this.setState({
                            loaderSubmit: false,
                            loaderGeneralError: true
                        });
                        break;
                    case 'AN_ERROR_HAS_OCCURED':
                        console.log('An error has occured');

                        this.setState({
                            loaderSubmit: false,
                            loaderGeneralError: true
                        });
                        break;
                
                    default:
                        break;
                }

                // this.recaptcha.reset();     //Reset captcha when error occurs - deploy
            }
        })
        .catch((response) => {
            // this.getinfo();
            //Close Loader
            console.log("catch");
            this.setState({
                loaderSubmit: false,
                loaderGeneralError: true,
                loginError:false,
                accountActivated:false
            });

            // this.recaptcha.reset();     //Reset captcha when error occurs
        });
    }
    
    render() {
        
        //Loader rendering for form submit and error handling
        let submitLoader = null,
        errorLoader = null,
        loaderSubmit= null,
        errorLoaderGeneral = null,
        siteKey = null,
        fillField=true,
        inputElement = null,
        loginError = null,
        GameOverError = null,
        active=null
        //paraElement = null;

            //paraElement = null;
            if(this.state.loaderError === true){
                errorLoader = (
                    <div className="js-overlay overlay overlay--black">
                        <div className="overlay__container overlay__content">
                            <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                            <p className="overlay__title">Du hast bereits 3x an der Aktion teilgenommen.</p>
                            <p id="js-error-msg">Über den gesamten Gewinnspielzeitraum ist eine dreimalige Teilnahme mit jeweils neuem gültigem Kassenbon erlaubt. Eine weitere Teilnahme ist leider nicht möglich.</p>
                            <div className="overlay__footer">
                                <p>Du möchtest wissen, welche Neuigkeiten es bei Milka gibt?</p>
                                <a href="https://www.milka.de/neues" target="_blank" rel="noopener noreferrer">
                                    <div className="btn__container btn--primary">
                                        <span className="btn__text">NEUES VON MILKA</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            }
    
            if(this.state.loaderGeneralError === true){ 
                errorLoaderGeneral = (
                    <div className="js-overlay overlay overlay--black">
                        <div className="overlay__container overlay__content">
                            <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                            <p id="js-error-msg">Ein Fehler ist aufgetreten. Bitte versuchen <br />Sie es später noch einmal.</p>
                        </div>
                    </div>
                );
            }
            if(this.state.loginError === true){
                loginError=(
                    <span className="errorMsg">
                    Benutzername oder Passwort ist ungültig</span>
                )
            }
            if(this.state.accountActivated === true){
                loginError=(
                    <span className="errorMsg">
                        E-Mail wurde nicht aktiviert
                    </span>
                )
            }
            if(this.state.isGameOver){
                GameOverError=(
                    <div className="gameover-errorMsg">
                        Dein Code ist älter als ein Tag. Teilgenommen werden kann immer nur am Tag nach deiner Codeeingabe. Bitte nimm erneut an unserer Aktion teil. Vielen Dank
                    </div>
                )
            }

        return(
            <div>
                {
                    this.state.SimpleLoader ? <Loader /> : null
                }
                <div className="account code-verification">
                    <div className="code-verification__text">
                        <h2>Hast du schon deinen Produktcode eingegeben?</h2>

                        <p>Wir schicken eine Bestätigungsmail mit einem Link an alle, die bereits Ihren Code im Teilnahmeformular eingegeben haben. Anscheinend bist du nicht über den Link in der Bestätigungsmail auf diese Seite gekommen.</p>

                        <p>Bitte gib deine E-Mail-Adresse ein, damit wir sicherstellen können, dass du bereits einen Code eingegeben hast und nun die Chance auf deinen Gewinn hast.</p>
                    
                        <p>Alternativ kannst du auch deinen Code über das Teilnahmeformular eingeben und bekommst automatisch eine Bestätigungsmail mit einem Link für die morgige Gewinnchance.</p>
                    </div>
                        <div className="content-upload-receipt form-line row ">
                            <div className="form-container grid-6-m grid-s-2">
                                <div className="input-container half-width">
                                    <h2 className="title">Du hast noch keinen Produktcode eingegeben? </h2>
                                    <p className="subtitle">Das ist kein Problem. Nimm einfach über den Button an unserer Aktion teil, indem du deinen Produktcode eingibst. Schon morgen hast du dann die Chance auf deinen Gewinn.</p>
                                    <div className="form-button register-button">
                                        <NavLink to={GLOBAL_CONFIG.Route.participation} exact data-event="info-click" data-category="Informational Action" data-action="T&amp;C" >
                                            <button className="btn__container btn--red">
                                                    JETZT MITMACHEN
                                            </button>                                            
                                        </NavLink>
                                    </div>  
                                </div>
                            </div>
                            <div className="vl"></div>
                            <div className="form-container grid-6-m grid-s-2">
                            <form id="frm_login" name="frm_login" method="post" action="/" onSubmit={this.loginHandler}noValidate>
                                <div className="input-container space-left">
                                    <h2 className="title">Ich habe den Produktcode bereits eingegeben</h2>
                                    <p className="subtitle">Bitte gib deine E-Mail-Adresse ein, damit wir überprüfen können, ob du deinen Produktcode bereits eingegeben hast.</p>
                                    <div className="input-container">
                                        <p className="required-field">*Pflichtfelder</p>
                                        <div className="form-input__container">
                                            <label className="form-input__label" htmlFor="email">Email*</label>
                                            <input className="form-input__input js-event-type-in js-event-ab" type="text" name="email" id="email" placeholder="E-mail-Adresse*" data-require="true" data-type="regex" data-error-target="#error-emailname" data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$" data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail." data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail." data-event-fieldname="Email" data-event="form-click" data-category="Form Action" data-action="Email" data-label="Type In" />
                                            <span id="error-emailname" className="form-input__error active"></span>
                                        </div>
                                    </div>
                                    {loginError}
                                    <div className="form-button ">
                                        <button type="submit" className="btn__container btn--red small-btn">
                                        ÜBERPRÜFEN
                                        </button>
                                    </div> 
                                    {GameOverError}     
                                </div>
                                </form>
                            </div>
                        </div>
                        {submitLoader}
                {errorLoader}  
                {errorLoaderGeneral}
               </div>
               <Footersocial/>
            </div>
        );
     }
}

const mapStateToProps = state => ({
    gameState: state.game
});


export default withTranslation()(withRouter(connect(mapStateToProps, null)(ProductCodeVerification)));