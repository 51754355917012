import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config'; 
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle } from '../../modules/helpers';
import TitleLogo from '../../components/Content/TitleLogo/TitleLogo';
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
import SliderVideo from "../../components/Content/FairplayContent/SliderVideo";
import SliderEdeka from "../../components/Content/SliderContent/SliderEdeka";
import GridQuote from "../../components/Content/FairplayContent/GridQuote";
import { servicePath, campaignSettings } from "../../modules/helpers"; //Helpers for Service path
import axios from "axios"; //Ajax library
import { Helmet } from "react-helmet";
import _SCROLL_PAGE from "../../modules/ScrollPage";
class footballGame extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}
  componentDidMount() {
    mainLoaderToggle("hide");
    setTimeout(()=>{
      _SCROLL_PAGE.ScrollToTop();
    }, 200)
  }
  
  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    const { t } = this.props;
    //Footer content options

    return (
      <div>
        {/* <div className="page__menu intropage_menu intropage_fairplay">
          <ul className="navigation main-nav intropage_nav" id="navigation">
            <li>
              <NavLink
                to={GLOBAL_CONFIG.Route.intropage}
                exact
                activeClassName="active"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="Start"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                ÜBERSICHT
              </NavLink>
            </li>
            <li>
              <NavLink
                to={GLOBAL_CONFIG.Route.home}
                exact
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="Mitmachen"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                GEWINNSPIEL
              </NavLink>
            </li>
            <li>
              <NavLink
                to={GLOBAL_CONFIG.Route.account}
                exact
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="Gewinne"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                FC MILKA
              </NavLink>
            </li>
            <li>
              <NavLink
                to={GLOBAL_CONFIG.Route.fanCenter.main}
                exact
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="Fairplay"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                FAN CENTER
              </NavLink>
            </li>
            <li>
              <NavLink
                to={GLOBAL_CONFIG.Route.footballGame}
                exact
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="FanCenter"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                11-METER-SCHIEßEN
              </NavLink>
            </li>
            <li>
              <NavLink
                to={GLOBAL_CONFIG.Route.fairplay}
                exact
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="FanCenter"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                Fairplay
              </NavLink>
            </li>
          </ul>
        </div> */}
        {/* <TitleLogo /> */}
        <div className="fairplay-text">
          <div className="s-content-title">
            <h1 className="heading_dark-lilac">Fairplay </h1>
            {/* <p className="subheading heading_dark-lilac" id="endpage-Text">Stelle deine 11-Meter-Fertigkeiten unter Beweis! Du hast 5 Schüsse auf das Tor – wie viele versenkst du?</p> */}
            <p id="endpage-Text">
              Wir, zusammen mit der Bundesliga, glauben an Fairness & Respekt - auf
              und neben dem Fußballplatz! Unser Ziel ist gemeinsam Fair Play zu
              fördern.
            </p>
          </div>
        </div>
        <SliderVideo />
        <GridQuote />

        {/*<div>
                    <iframe allowfullscreen id="wallsio-iframe" src="https://my.walls.io/s5jdx?nobackground=1&amp;show_header=0&amp;initial_posts=5&amp;lang=de_DE&amp;columns=3"  loading="lazy" title="FC Milka Fairplay"></iframe>
                </div>*/}
        <div>
          <iframe
            allowfullscreen
            id="wallsio-iframe"
            src="https://my.walls.io/fairplay?nobackground=1&amp;show_header=0"
            loading="lazy"
            title="FC Milka blfairplay"
          ></iframe>
        </div>

        <Footersocial />
      </div>
    );
  }
}
export default withTranslation()(footballGame);