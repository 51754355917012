import React from 'react';
import Aux from '../../../hoc/Auxiliare';
import { NavLink, useLocation } from 'react-router-dom';
import GLOBAL_CONFIG from '../../../config/config';
import { isGlobusMicrosite } from '../../../modules/helpers';
import appGoogleTracking from '../../../modules/googletracking';
/*
    Contains the step that a user must do to participate in the campaign
*/

const StepBuzzerSpecific = (props) => {
    return (

        <div className="s-content-steps">
            <Aux>
                <div className="s-content-steps__step first">
                    <picture>
                        <img src="/resources/imagesBundesliga/buzzer_23/kaufland/steps/step-1.png" className="s-content-steps__image" alt="Participation Steps" style={{ width: '81%' }} />
                    </picture>
                    <div className="s-content-steps__copy first">
                        <p>
                            Kaufe zwischen dem  <span className='s-content-steps__copy__span'> 03.04.2023 </span> und dem <span className='s-content-steps__copy__span'>25.06.2023 </span> ein Aktionsprodukt von Milka und/oder OREO.
                        </p>
                    </div>
                </div>

                <div className="s-content-steps__step second">
                    <picture>
                        <img src="/resources/imagesBundesliga/buzzer_23/kaufland/steps/step-2.png" className="s-content-steps__image" alt="Participation Steps" />
                    </picture>
                    <div className="s-content-steps__copy second">
                        <p>
                            Gib <button onClick={(e)=>{props.handleClick();appGoogleTracking.processEventCTANavLink(e)}} 
                                data-event= "buzzer_begin_participation" 
                                data-category= "Start Page" 
                                data-action= "Click Join Now" 
                                data-label= "BUZZER_OTHER_SP">hier</button> bis zum  <span className='s-content-steps__copy__span'> 25.06.2023 </span> den  <span className='s-content-steps__copy__span'>Produktcode </span> von der Produktverpackung ein*.
                        </p>
                    </div>
                </div>

                <div className="s-content-steps__step third">
                    <picture>
                        <img src="/resources/imagesBundesliga/buzzer_23/kaufland/steps/step-3.png" className="s-content-steps__image" alt="Participation Steps" style={{ width: '79%' }} />
                    </picture>
                    <div className="s-content-steps__copy third">
                        <p>
                            Mitmachen und mit etwas Glück gewinnen.
                        </p>
                    </div>
                </div>
            </Aux>
        </div>

    );
}

export default StepBuzzerSpecific;