import React from 'react';
import { isTrikotPromotion } from '../../../modules/helpers';
import GLOBAL_CONFIG from '../../../config/config';
import appGoogleTracking from '../../../modules/googletracking';

function displayTrikotNavigation() {
    let hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://trikot.fcmilka.de" : "/trikot";
    return isTrikotPromotion() && (
        <ul>
            <li>
                <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.home}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">START</a>
            </li>
            <li>
                <a href={window.PROMOTION === "holding" ? `${baseDomaine}${GLOBAL_CONFIG.Route.participation}` : `${baseDomaine}${GLOBAL_CONFIG.Route.home}`+`?status=on`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">MITMACHEN</a>
            </li>
            <li>
                <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.prize}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Gewinne" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">GEWINNE</a>
            </li>
            <li>
                <a href={'https://fcmilka.de/account'} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">KONTO</a>
            </li>
            <li>
                <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.faq}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">FAQ</a>
            </li>
            <li>
                <a href={`https://fcmilka.de/fairplay`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">FAIRPLAY</a>
            </li>
            <li>
                <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.product}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="PRODUKTSEITE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">PRODUKTSEITE</a>
            </li>
        </ul>
    );
}

export default displayTrikotNavigation