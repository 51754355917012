import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import {withRouter} from 'react-router-dom';
import StepContent from "../../../components/Content/StepContent/Q3StepContent";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Countdown, { zeroPad } from "react-countdown";
import queryString from "query-string";
import { connect } from "react-redux";
import ParticipationService from "../../../Service/Participation";
import Aux from "../../../hoc/Auxiliare";
import Loader from "../../../components/Overlays/Loader";
import _LOCAL from "../../../Models/LocalStorageKeys";

class Q3Extended extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        }
    }
    
    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({isMobile: window.innerWidth <= 768});
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        _SCROLL_PAGE.ScrollToTop();

    }

    componentWillMount(){
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }



    render() {
        return (
            <Aux>
                <div id="main-buzzer">
                    {this.headingSection( this.props )}
                    <Footersocial />
                </div>
            </Aux>
        );
    }
    headingSection(props) {
        return (
            <div>
               <div className="content-home game-90-min extended-promo">
                    <div className="spaceTile-intropage">
                        <div className="campaign-heading">
                            <h1>Die Aktion „Gewinne in 90 Minuten“ ist beendet. </h1>
                            <p className="sub">
                                Aber du hast hier die Möglichkeit an unserer Verlosung teilzunehmen und am Ende der Promotion einen von 40 11teamsports-Gutscheinen im Wert von 50€ zu gewinnen.
                            </p>
                            
                            
                            <StepContent
                                step1 = { props.t('/resources/imagesBundesliga/90_min/steps/step-1.png') }
                                step1sm = { props.t('/resources/imagesBundesliga/90_min/steps/step-1.png') }
                                step1Text = {parse("Kaufe dafür einfach zwischen dem <span class=\"text--wheat\">11.09. - 06.11.2022</span> ein Aktionsprodukt von Milka, Wunderbar und/oder TUC.")}

                                step_arrow = "/resources/imagesBundesliga/kaufland/icons/arrow_right.png"
                                step2 = { props.t('/resources/imagesBundesliga/90_min/steps/email.png') }
                                step2sm = { props.t('/resources/imagesBundesliga/90_min/steps/email.png') }
                                step2Text = {parse("Sende bis zum <span class=\"text--wheat\">06.11.2022</span> eine <span class=\"text--wheat\">Email</span> mit dem Betreff <span class=\"text--wheat\">„Verlängerung</span>“ und deinem <span class=\"text--wheat\">Kaufbeleg</span> an info@fc-milka.de.")}

                                step3 = { props.t('/resources/imagesBundesliga/90_min/steps/step-4.png') }
                                step3sm = { props.t('/resources/imagesBundesliga/90_min/steps/step-4.png') }
                                step3Text = {parse("Die Gewinner werden per Zufallsgenerator am Ende der Aktion ermittelt und per E-Mail informiert.")}
                            />

                            
                            <p id="edeka-terms">Hier findest du die <NavLink to={GLOBAL_CONFIG.Route.terms} style={{color: "white"}}> Teilnahmebedingungen</NavLink> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" style={{color: "white"}}> Datenschutzbestimmungen</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    gameState: state.game
});

export default withTranslation()(withRouter(connect(mapStateToProps, null)(Q3Extended)));