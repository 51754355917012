import axios from 'axios';
import { ServicesUrl } from '../modules/helpers';

const kipService = () => {
    const _SERVICE_URL = ServicesUrl();

    const Reihe = () => {
        return axios.get(`${_SERVICE_URL}/CampaignStatusReihe1`)
    }
    const Heimtrikot = () => {
        return axios.get(`${_SERVICE_URL}/CampaignStatusHeimtrikot`)
    }
    const Sommer = () => {
        return axios.get(`${_SERVICE_URL}/CampaignStatusSommer`)
    }
    const Kaufland = () => {
        return axios.get(`${_SERVICE_URL}/CampaignstatusKaufland`)
    }

    const Netto = () => {
        return axios.get(`${_SERVICE_URL}/CampaignstatusNetto`)
    }

    const Edeka = () => {
        return axios.get(`${_SERVICE_URL}/CampaignStatusEdeka`)
    }

    const Rewe = () => {
        return axios.get(`${_SERVICE_URL}/CampaignStatusRewe`)
    }

    const Penny = () => {
        return axios.get(`${_SERVICE_URL}/CampaignStatusPenny`)
    }

    const PennyGondelkopf = () => {
        return axios.get(`${_SERVICE_URL}/CampaignStatusPennyGondelkopf`)
    }

    const Globus = () => {
        return axios.get(`${_SERVICE_URL}/CampaignStatusGlobus`)
    }

    const MicroGlobus = () => {
        return axios.get(`${_SERVICE_URL}/CampaignStatusGlobusFamilyEvent`)
    }

    return {
        Sommer,
        Kaufland,
        Netto,
        Edeka,
        Rewe,
        Penny,
        PennyGondelkopf,
        Globus,
        MicroGlobus, 
        Reihe,
        Heimtrikot
    }
}

const KIPServices = kipService();


export default KIPServices;