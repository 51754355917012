import React from "react";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import {withRouter} from 'react-router-dom';
import StepContent from "../../../components/Content/StepContent/Q3StepContent";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import Countdown, { zeroPad } from "react-countdown";
import queryString from "query-string";
import { connect } from "react-redux";
import ParticipationService from "../../../Service/Participation";
import Aux from "../../../hoc/Auxiliare";
import Loader from "../../../components/Overlays/Loader";
import _LOCAL from "../../../Models/LocalStorageKeys";

class Q3Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            countdownData: {},
            partId : null,
            SimpleLoader : false,
            gameLiveRedirection : GLOBAL_CONFIG.Route.ProductCodeVerification,
            currentKip : null
        }

        this.checkPartId = this.checkPartId.bind(this);
        this.gameLive = this.gameLive.bind(this);
        this.gameNotLive = this.gameNotLive.bind(this);
        this.onClickGameLiveButton = this.onClickGameLiveButton.bind(this); 
        this.processPartId = this.processPartId.bind(this);
        this.processErrorPartId = this.processErrorPartId.bind(this);
    }
    
    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({isMobile: window.innerWidth <= 768});
    }

    componentDidMount() {
        const gameState = this.props.gameState;
        this.setState({countdownData : gameState });
        this.checkPartId(gameState);
        mainLoaderToggle('hide');
        _SCROLL_PAGE.ScrollToTop();

        const QueryString = queryString.parse(window.location.search);
        if(Object.keys(QueryString).includes("currentKip")    
        ) {
            localStorage.setItem(_LOCAL.CurrentKip, QueryString.currentKip);
            this.setState({
                currentKip : QueryString.currentKip
            });
        }
    }

    checkPartId(data){
        const QueryString = queryString.parse(window.location.search);

        if(data.gameIsLive
            && Object.keys(QueryString).includes("partId")    
        ) {
            this.setState({
                partId : QueryString.partId,
                gameLiveRedirection : GLOBAL_CONFIG.Route.buzzer.confirmation
            });
        }
    }

    onClickGameLiveButton(event){
        event.preventDefault();
        appGoogleTracking.processEventCTANavLink(event);
        if(this.state.partId){
            this.processPartId();
        } else {
            this.props.history.push(this.state.gameLiveRedirection);
        }   
    }

    processPartId(){
        const { UpdateParticipationData, UpdateParticipationStatus} = this.props;
        const _data = {
            ParticipationId: this.state.partId,
        };

        this.setState({
            SimpleLoader: true
        });

        ParticipationService.ValidateWinner(_data).then((response)=>{
            console.log(response);
            let { success, data } = response.data;
                if (success) {
                    const {IsWinner, PrizeWon} = data;
                    UpdateParticipationData({
                        Winner: IsWinner,
                        PrizeWon : PrizeWon,
                        ParticipationId: this.state.partId,
                    });
                    UpdateParticipationStatus();
                } else {
                    const { Error } = data;
                    const errorMessage = !!Error && Error.length > 0 ? Error[0] : '';
                    this.processErrorPartId(errorMessage);
                }
        }).catch((error)=>{
            console.log(error);
        }).finally(()=>{
            this.setState({
                SimpleLoader: false
            });
        });
    }

    processErrorPartId(error){
        switch(error) {
            case "NO_PARTICIPATION_FOUND":
                this.props.history.push(GLOBAL_CONFIG.Route.NoProductCode);
                break;
            case "CONSUMERID_NOT_FOUND":
                this.props.history.push(GLOBAL_CONFIG.Route.NoProductCode);
                break;
            default :
                this.props.history.push(GLOBAL_CONFIG.Route.NoProductCode);
                break;
        }
    }

    componentWillMount(){
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    gameNotLive ({ hours, minutes, seconds, completed }) {
        if (completed) {
            return window.location.reload();
        } else {
          return (
            <div className= "game-text__container">
                <div className= "game-text__content">
                    <div className="game-title__content">
                        <h1>
                            Gewinne jeden Tag in 90 Minuten mit Milka, TUC oder Wunderbar
                        </h1>
                    </div>
                    <div className="coutdown-wrapper">
                        <div className="timer">
                            <span className="stunden">
                                {zeroPad(hours)}
                                <br />
                                <p>stunden</p>
                            </span>
                            :
                            <span className="minuten">
                                {zeroPad(minutes)} 
                                <br />
                                <p>minuten</p>
                            </span>
                            :
                            <span className="sekunden">
                                {zeroPad(seconds)}
                                <br />
                                <p>sekunden</p>
                            </span>
                        </div>
                    </div>
                    {window.LAST_DAY_90MIN ? null : 
                        <div className= "form-button form-button-main">
                            <NavLink to={GLOBAL_CONFIG.Route.participation} exact
                                className= "participate-section" 
                                data-event= "button-click" 
                                data-category= "Click Action" 
                                data-action= "Participate" 
                                data-label={timeStamp()} 
                                onClick={appGoogleTracking.processEventCTANavLink}>

                                <button className= "btn__container btn--secondary-lilac"> 
                                    JETZT MITMACHEN
                                </button>
                            </NavLink>
                        </div>
                    }
                </div>
            </div>
            
          );
        }
    };

    gameLive ({ hours, minutes, seconds, completed }) {
        if (completed) {
            return window.location.reload();
        } else {
          return (
            <div className= "live-game-text__container">
                <div className="coutdown-dark__wrapper">
                    <div className="timer-dark">
                        <span>
                            {zeroPad((hours * 60 ) + minutes)}:{zeroPad(seconds)}
                        </span>
                    </div>
                </div>
                <div className="live-game-title__content">
                    <h1>
                        Kaufen und gewinnen
                    </h1>
                    <h2>
                        Tolle Preise der Bundesliga / 2. Bundesliga warten auf dich.
                    </h2>
                    <p>
                        Du hast bereits ein Milka, Wunderbar und/oder TUC Produkt gekauft und deinen Code am Vortag eingegeben? Dann hast du hier die Chance auf deinen Gewinn!
                    </p>
                </div>
                <div className= "form-button-participate">
                    <NavLink to={this.state.gameLiveRedirection} exact
                        className= "participate-section" 
                        data-event= "button-click" 
                        data-category= "Click Action" 
                        data-action= "Participate" 
                        data-label={timeStamp()} 
                        onClick={this.onClickGameLiveButton}>

                        <button className= "btn__container btn--secondary-lilac"> 
                            ZUM GEWINN
                        </button>
                    </NavLink>
                </div>
            </div>
          );
        }
    };

    render() {
        
        return (
            <Aux>
                {
                    this.state.SimpleLoader ? <Loader /> : null
                }
                <div id="main-buzzer">
                    {this.BannerSection()}
                    {this.headingSection( this.props )}
                    {this.footerSection()}
                    <Footersocial />
                </div>
            </Aux>
        );
    }

    BannerSection() {
        return (
            <div className= "banner__container q3-game">            
                <div className = "image__container">
                    <div className= "image__img">

                        {this.state.countdownData.countdown && <Countdown date={Date.now() + this.state.countdownData.countdown} renderer={ this.state.countdownData.gameIsLive ? this.gameLive : this.gameNotLive} /> }               

                        {this.state.countdownData.gameIsLive ? 
                        
                        <picture>
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/game-live-banner.png" media="(min-width: 1024px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main-live-sm.png" media="(min-width: 481px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main-live-sm.png" media="(min-width: 200px)" />
                            <img className="banner__image" src="/resources/imagesBundesliga/90_min/banner/game-live-banner.png" alt="Milka Christmas" />
                        </picture> 
                        : 
                        <picture>
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main.png" media="(min-width: 1024px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main-sm.png" media="(min-width: 481px)" />
                            <source srcSet="/resources/imagesBundesliga/90_min/banner/banner-main-sm.png" media="(min-width: 200px)" />
                            <img className="banner__image" src="/resources/imagesBundesliga/90_min/banner/banner-main.png" alt="Milka Christmas" />
                        </picture>
                        }
                    </div>
                </div>
            </div>
        )
    }

    headingSection(props) {
        return (
            <div>
               <div className="content-home game-90-min">
                    <div className="spaceTile-intropage">
                        <div className="campaign-heading">
                            {
                                this.state.countdownData.gameIsLive ? <h1>Du hast noch keinen Produktcode eingegeben? 
                                Dann jetzt ganz einfach mitmachen!</h1>  : <h1>Mitmachen ist ganz einfach:</h1>
                            }
                            
                            <StepContent
                                step1 = { props.t('/resources/imagesBundesliga/90_min/steps/step-1.png') }
                                step1sm = { props.t('/resources/imagesBundesliga/90_min/steps/step-1.png') }
                                step1Text = {parse("Kaufe zwischen dem <span class=\"text--wheat\">11.07.2022</span> und dem <span class=\"text--wheat\">10.09.2022</span> ein Aktionsprodukt von Milka, Wunderbar und/oder TUC.")}

                                step_arrow = "/resources/imagesBundesliga/kaufland/icons/arrow_right.png"
                                step2 = { props.t('/resources/imagesBundesliga/90_min/steps/step-2-2.png') }
                                step2sm = { props.t('/resources/imagesBundesliga/90_min/steps/step-2-2.png') }
                                step2Text = {parse("Gib <a href=\"/mitmachen\">hier</a> bis zum 10.09.2022 den Produktcode von der Produktverpackung ein.*")}

                                step3 = { props.t('/resources/imagesBundesliga/90_min/steps/step-3.png') }
                                step3sm = { props.t('/resources/imagesBundesliga/90_min/steps/step-3.png') }
                                step3Text = {parse("Fülle das Teilnahmeformular aus und erfahre dein 90-Minuten-Gewinn-Zeitfenster für den Folgetag.")}

                                step4 = { props.t('/resources/imagesBundesliga/90_min/steps/step-4.png') }
                                step4sm = { props.t('/resources/imagesBundesliga/90_min/steps/step-4.png') }
                                step4Text = {parse("Während des 90 Minuten Gewinn-Zeitfensters am Folgetag erfährst du direkt, ob du gewonnen hast. Der oder die Gewinner*in wird per Zufallsgenerator ermittelt.")}
                            />
                            {/* <p className="note-promo-code">*(Hinweis: Befindet sich kein Produktcode auf dem Artikel, so müssen die Zahlen beim Barcode eingegeben werden. Mehrfachteilnahmen mit neuem Produktcode innerhalb der Aktionsprodukte erlaubt.) </p> */}
                            <div className="cta-container">
                                {window.LAST_DAY_90MIN ? null : <button className="btn__container btn--secondary-lilac"><NavLink to={GLOBAL_CONFIG.Route.buzzer.participate} exact className="no-decoration" data-event="button-click" data-category="Click Action" data-action="Something" data-label="Something" onClick={appGoogleTracking.processEventCTANavLink}>JETZT MITMACHEN</NavLink></button>}
                                    
                                
                                <p id="edeka-terms">Hier findest du die <a href={GLOBAL_CONFIG.Route.terms} target="_blank" style={{color: "white"}}> Teilnahmebedingungen</a> und <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" style={{color: "white"}}> Datenschutzbestimmungen</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer reward_90min">
                <div className="prizeRewe-card">
                    <div className="prizeRewe-card__primary">
                        <img src="/resources/imagesBundesliga/90_min/prizes/tickets.png" alt="Gold im Wert"/>
                        <p>Abbildung unverbindlich</p>
                        <h3>Tickets für die Saison 2022/23</h3>
                        <p>Erlebe mit dem FC Milka die einmalige Live-Atmosphäre vor Ort im Stadion. Gewinne aus 244 x 2 CAT2-Tickets Plätze für dich und deine Begleitperson für die Saison 2022/23 für die Bundesliga / 2. Bundesliga. (Werte variieren je nach Club, Auswahl nach Verfügbarkeit. Es besteht kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga.)</p>
                    </div>
                    <div className="prizeRewe-card__wrapper">
                        <div className="prizeRewe-card__secondary">
                            <div className="prizeRewe-card__img">
                                <img src="/resources/imagesBundesliga/90_min/prizes/trikots.png" alt="Dauerkarten der Saison"/>
                                <p>Abbildung unverbindlich</p>
                            </div>
                            <div className="prizeRewe-card__text">
                                <h3>Originale Heimtrikots</h3>
                                <p>Fußball geht nicht nur unter, sondern auch über die Haut. Nutze deine Chance auf eins von 183 <span className="bold-text">signierten</span> original Bundesliga Heimtrikots oder 1 von 183 original Bundesliga Heimtrikots.</p>
                            </div>
                        </div>
                        <div className="prizeRewe-card__secondary secon">
                            <div className="prizeRewe-card__img">
                                <img src="/resources/imagesBundesliga/90_min/prizes/starter_pack.png" alt="Dauerkarten der Saison"/>
                                <p>Abbildung unverbindlich</p>
                            </div>
                            
                            <div className="prizeRewe-card__text">
                                <h3>Bundesliga Starterpack</h3>
                                <p>Sammeln, tauschen und die Bundesliga immer dabeihaben. Gewinne eins von 4.880 Match Attax Starterpacks. Dein spannendes Trading Card Game inklusive Sammelmappe, Sammlermagazin und Spielfeld. </p>
                            </div>
                        </div>                                  
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    gameState: state.game
});

export default withTranslation()(withRouter(connect(mapStateToProps, null)(Q3Main)));