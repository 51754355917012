import React, { useState, useEffect,useRef } from "react";
import ValidateForm from "../../../modules/validate";
import ParticipationService from "../../../Service/Participation";
import _LOCAL from "../../../Models/LocalStorageKeys";
import _BUZZER_MESSAGE from "../../../Models/BuzzerErrorMessage";
import _ERROR_MESSAGES from "../../../Models/ErrorMessages";
import GLOBAL_CONFIG from '../../../config/config'; 
import { withRouter } from 'react-router-dom';

const Q4PopUp = (props) => {
  const [modal, setModal] = useState({...props.modal});

  const [SimpleLoader, setSimpleLoader] = useState(false);
  const [ErrorMessage, SetErrorMessage] = useState("");
  const [CodeIsValid, SetCodeIsValid] = useState(true)
  const _PRODUCT_type = useRef(null);

  useEffect(() => {
    setModal(props.modal);
  }, [props.modal])

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  function SubmitHandler(event) {
    event.preventDefault();
    const isValid = ValidateForm.validateForm('frm_participation')
    if ( isValid) {
      ProcessForm(null);
    } 
  }

  function ErrorHandler(Message) {
    switch (Message) {
        case _BUZZER_MESSAGE.PARTICIPATION_LIMIT:
            SetErrorMessage(_ERROR_MESSAGES.Buzzer.CODE_LIMIT); 
            break;
        case _BUZZER_MESSAGE.CODE_INVALID:
            SetCodeIsValid(false);
            SetErrorMessage("Dein Code ist nicht korrekt. Bitte gib ihn erneut ein.");
            break;
        case _BUZZER_MESSAGE.INVALID_CAPTCHA:
            SetErrorMessage("UNGÜLTIGE RECAPTCHA-ANTWORT");
            break;
        case _BUZZER_MESSAGE.REGISTRATION_FAILED:
            SetErrorMessage("E-MAIL BEREITS VERWENDET");
            break;

        default:
            SetErrorMessage("SERVERFEHLER");
            break;
    }
  }

  function ProcessForm(token) {
    //Process form data
    let _data = {
        Code : _PRODUCT_type.current.value
    };

    setSimpleLoader(true);

    ParticipationService.ValidateCodeweltmeister(_data)
      .then((response) => {
          let { status, success, data } = response.data;
          setSimpleLoader(true);

          if (success) {
              /**
               * Sucess handler
               */
              localStorage.setItem(_LOCAL.ProductCode, _data.Code);

              //redirect to player selection page
              props.history.push({pathname: GLOBAL_CONFIG.Route.buzzer.selection});
          } else {
              if (!!data.Error && data.Error.length > 0) {
                ErrorHandler(data.Error[0]);
              } else {
                SetErrorMessage("SERVER ERROR");
              }
          }
      })
      .catch(() => {
        setSimpleLoader(true);
      })
  }

  return (
    <>
      {modal && (
        <div className="modal penny">
          <div onClick={() => {props.toggleModal()}} className="popup-overlay"></div>
          <div className="modal-content">
            <div className="modal-wrapper">
              <div className="modal-heading"> 
              <h1>
                Produktcode eingeben und dabei sein! 
              </h1>
              <p>
                Du findest den Produktcode auf der Packungsrückseite. Gib nur die ersten 3 Buchstaben und die folgenden Ziffern ein, auch wenn der Code dann mit "-"... weitergeht.
              </p>
              <img width="119px" class="product-code-instruction" src="/resources/imagesBundesliga/weltmeister/instruction.png" alt="product instruction"/>
              <p>
                (Hinweis: Befindet sich kein Produktcode auf dem Artikel, so müssen die Zahlen beim Barcode eingegeben werden.)
              </p>
              </div>
              <form id="frm_participation"
                name="frm_participation"
                method="post" action="/"
                onSubmit={(event) => {SubmitHandler(event)}} noValidate>
                  
                <div className="form-container">
                  <div className="buzzer-form retailer-container__form">
                    <picture>
                      <source srcSet="/resources/imagesBundesliga/90_min/steps/step-2-2.png" media="(min-width: 1024px)"/>
                      <source srcSet="/resources/imagesBundesliga/90_min/steps/step-2-2.png" media="(min-width: 481px)"/>
                      <source srcSet="/resources/imagesBundesliga/90_min/steps/step-2-2.png" media="(min-width: 200px)"/>
                      <img width="119px" class="step_image step_image--website" src="/resources/imagesBundesliga/90_min/steps/step-2-2.png" alt="Milka product"/>
                    </picture>
                    <div className="form-container">
                      <div className="input-container">
                        <div className="form-input__container">
                          <label className="form-input__label" htmlFor="product_type">Produktart</label>
                            <input className="form-input__input js-event-type-in js-event-ab"
                              type="text"
                              name="product_type"
                              id="product_type"
                              placeholder="Produktcode*"
                              data-require="true"
                              data-type="text"
                              maxLength="50"
                              data-error-target="#error-product-code"
                              data-required-message="Du hast noch keinen Produktcode eingegeben."
                              data-event-fieldname="Product Type is required."
                              data-event="form-click"
                              data-category="Form Action"
                              data-action="House number"
                              data-label="Type In"
                              ref={_PRODUCT_type}
                            />
                            <span id="error-product-code" className="form-input__error active notvalid"></span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="error-code">{ErrorMessage}</p>
                <button type="submit" className="btn__container btn--secondary-lilac">JETZT MITMACHEN
                </button>
              </form>
            </div>



            <div onClick={() => {props.toggleModal()}} className="close-modal">
                <img  src="/resources/imagesBundesliga/buzzer_22/ham-close.png" alt="Popup Close"/>
            </div>
          </div>
        </div>
      )}
    </>
  )
}



export default withRouter(Q4PopUp);