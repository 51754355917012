import React  from 'react';

class ButtonSimple extends React.Component {

    constructor(props) {
    super(props);
    }

    render(){
        return (
            <button className={`btn-simple ${ this.props.text === "GRÖßE AUSWÄHLEN" ? "no-uppercase" : "" }`} onClick={this.props.onClick}>{ this.props.text}</button>
        )
    }
}
export default ButtonSimple;
ButtonSimple.defaultProps = {
  text: "default"
}

