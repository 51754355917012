import React from 'react'
import './Button.scss'
export default function Button(props) {
    return (
        <button className='_btn' onClick={props.onClick}>
            <p className='content_Btn'>
                {props.children}
            </p>
        </button>
    )
}
