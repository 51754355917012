import React from "react";
import Button from "../../components/Content/Button/Button";
import GLOBAL_CONFIG from "../../config/config";
import _SCROLL_PAGE from "../../modules/ScrollPage";
import { withRouter } from 'react-router';
import _LOCAL from "../../Models/LocalStorageKeys";
import appGoogleTracking from "../../modules/googletracking";
import _TICKET_TYPES from "../../Models/TicketTypes";

export const onChangeCheckboxBtn = (e, element) => {
    const checkboxElement = e?.target || element;
    const parentElement = checkboxElement.parentElement;
    const ticketType = localStorage.getItem(_LOCAL.TicketType);
    if(checkboxElement){
        if(!checkboxElement.checked){
            parentElement.classList.add('notvalid');
            _SCROLL_PAGE.ScrollToElement(checkboxElement);
        } else {
            parentElement.classList.remove('notvalid');
            if(ticketType === _TICKET_TYPES.CAT2) {
                appGoogleTracking.dataLayerPush({
                    dataEvent: "custom_event_click",
                    dataCategory: "form_action",
                    dataAction: "accept_conditions",
                    dataLabel: "terms_and_conditions_standard",
                })
            }
            else if (ticketType === _TICKET_TYPES.VIP) {
                appGoogleTracking.dataLayerPush({
                    dataEvent: "custom_event_click",
                    dataCategory: "form_action",
                    dataAction: "accept_conditions",
                    dataLabel: "terms_and_conditions_vip",
                })
            }
        }
        return checkboxElement.checked;
    }
    return false;
};



const CardTableau = (props) => {

    const goBack = () => {
        const redirect = GLOBAL_CONFIG.Route.fanCenter.tickets.teamSelection;
        props.history.push({
            pathname: redirect,
            state:
            {
                SelectedMatch: JSON.parse(localStorage.getItem(_LOCAL.TicketSelected)),
            },

        }
        );
    }

    return (
        <>
            <div className="container__cardtableau cardbutton-details">
                <Button
                    text={'<  Zurück'}
                    icon={'none'}
                    fill={false}
                    onclick={() => goBack()}
                />
                <div className="titre-card">
                    Deine Kontaktdaten</div>
                <div className="card-tableau">
                    <div className="text__container">
                        <p className="texte">
                            <span className="texte__span1">Anrede</span>
                            <span className="texte__span2">{props.anrede}</span>
                        </p>
                        <hr className="tiret" />
                        <p className="texte">
                            <span className="texte__span1">Vorname</span>
                            <span className="texte__span2">{props.vorname}</span>
                        </p>
                        <hr className="tiret" />
                        <p className="texte">
                            <span className="texte__span1">Nachname</span>
                            <span className="texte__span2">{props.nachname}</span>
                        </p>
                        <hr className="tiret" />
                        <p className="texte">
                            <span className="texte__span1">E-mail</span>
                            <span className="texte__span2">{props.email}</span>
                        </p>
                    </div>
                    <p className="texte info">
                        <span className="texte__paragraphe good-headline-pro">{props.texteParagraphe}
                        </span>
                    </p>
                    <p className="texte">
                        <span className="texte__checkedbtn">
                            <input className="btncheck" type="checkbox" id={props.checkboxId} onChange={(e)=>{onChangeCheckboxBtn(e);}}/>
                            <span className="texte__textcheck not-valid good-headline-pro">
                                Ich habe die <a style={{textDecoration:'underline'}} class="js-event-cta" target="_blank" href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/">Dateschutzbestimmungen</a> & <a style={{textDecoration:'underline'}} class="js-event-cta" target="_blank" href="/fancenter/teilnahmebedingungen">Teilnahmebedingungen</a> gelesen und akzeptiert.*
                            </span>
                        </span>

                    </p>
                </div>
            </div>
        </>
        );
    }
export default withRouter(CardTableau);
