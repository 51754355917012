import React, { Component } from "react";

export default class InputPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }
  //Password hide and Show
  togglePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };
  render() {
    const { showPassword } = this.state;
    return (
      <>
        <input 
          type={showPassword ? "text" : "password"} 
          {...this.props} 
        />
        <i
          onClick={this.togglePassword}
          className={showPassword ? "show-password" : "hide-password"}
        ></i>
      </>
    );
  }
}
