import React from "react";
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import { NavLink } from 'react-router-dom';

import { mainLoaderToggle } from "../../../../modules/helpers";
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import StepContent from "../../../../components/Content/StepContent/StepContent";
import GLOBAL_CONFIG from "../../../../config/config";
import appGoogleTracking from "../../../../modules/googletracking";
import _SCROLL_PAGE from "../../../../modules/ScrollPage";

class SupercupFAQGlobus extends React.Component {
    componentDidMount() {
        this.props.loadGlobusCampaign();
        mainLoaderToggle('hide');
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div id="buzzer-faqs">
                { this.faqContent(this.props) }
                <Footersocial />
            </div>
        );
    }

    faqContent(props) {
        const iconPlus = "/resources/imagesBundesliga/buzzer/plus.png";
        const iconMinus = "/resources/imagesBundesliga/buzzer/minus.png";

        return (
            <div className="content-faq">
                <div className="content-faq__item">
                    <h6>Wie läuft das Gewinnspiel ab?</h6>
                    <img src={iconMinus} className="content-faq__img shown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content show">
                        <ul>
                            <li>
                                Kaufe innerhalb des Zeitraumes vom 02.05.2022 bis 05.06.2022 zwei Produkte von Milka.
                            </li>
                            <li>
                                Lade den Kaufbeleg auf <NavLink to={GLOBAL_CONFIG.Route.globusMicroSite.participation} exact data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close"> dieser Seite </NavLink> bis zum 05.06.2022 hoch. Hierfür ist eine Online-Registrierung erforderlich. Zusätzlich benötigen wir noch das Datum deines Einkaufs und eine Angabe darüber, wie viele Milka Produkte du gekauft hast.
                            </li>
                            <li>
                                Im Anschluss an deine Teilnahme wird dein Kaufbeleg überprüft. Wenn alles seine Richtigkeit hat, wird der Gewinner nach Ende des Gewinnspiels am 05.06.2022 per Zufallsgenerator gezogen und per E-Mail über seinen Gewinn informiert.
                            </li>
                            <li>
                                Bitte bewahre den gut leserlichen Original-Kaufbeleg gut auf. Du kannst mit einem Kaufbeleg pro Einkauf teilnehmen. Viel Glück!
                            </li>
                        </ul>
                    </div>
                </div>



                <div className="content-faq__item">
                    <h6>Mit welchen Produkten kann ich teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>Die Aktion ist gültig für alle Produkte der Marke Milka.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Wo finde ich die Produkte für das Gewinnspiel?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>Du findest die Produkte überall dort im Handel, wo du auch sonst deine Lieblingsprodukte von Milka findest.</p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie lange kann ich an der Aktion teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>Du hast vom 02.05. bis 05.06.2022 die Möglichkeit, deinen Kaufbeleg hochzuladen. Es nehmen nur Produkte teil, die zwischen dem 02.05. und dem 05.06.2022 gekauft wurden.</p>
                    </div>
                </div>


                <div className="content-faq__item">
                    <h6>Kann jeder an der Aktion teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Teilnehmen kann jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland mit einem Familienmitglied, das zwischen 8 und 12 Jahre alt ist und gerne als Einlauf-oder Ballträgerkind am Supercup aktiv teilnehmen möchte.
                        </p>
                    </div>
                </div>
                
                <div className="content-faq__item">
                    <h6>In welchen Ländern kann man teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Die Teilnahme ist in Deutschland möglich.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Was kann gewonnen werden?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Insgesamt gibt es folgende Gewinnchancen im Aktionszeitraum:
                        </p>
                        <p>
                            12x Supercup Familienerlebnisse* (11x Einlaufkind, 1x Ballträgerkind für bis zu 5 Personen inkl. Tickets zum Spiel, Rahmenprogramm, An- und Abreise per Bahn 2. Klasse oder Kostenerstattung durch Tankgutscheine (Höhe abhängig von Gesamtstrecke), 2x Übernachtung im Doppel-/Familienzimmer inkl. Frühstück sowie Verpflegung).
                        </p>
                        <p>
                            Es ist maximal ein Gewinn pro Person möglich.
                        </p>
                        <p>
                            *Es besteht kein Anspruch auf Clubauswahl. Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen, und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des Veranstalters liegen, wie insbesondere die derzeitige COVID-19-Situation und sich aus ihr ergebende Restriktionen oder Hindernisse für die Inanspruchnahme des Gewinns, nicht verfügbar oder die Verfügbarkeit des Gewinns eingeschränkt sein. Der Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine anderweitige Kompensation.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Wie erfahre ich, ob und was ich gewonnen habe?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Die Verlosung der Gewinne erfolgt unter allen Teilnehmern nach Ende der Aktion am 05.06.2022. Die Gewinnbenachrichtigung erfolgt per E-Mail an die bei der Registrierung angegebene E-Mail-Adresse.
                        </p>
                    </div>
                </div>

                <div className="content-faq__item">
                    <h6>Kann ich einen Kaufbeleg mehrfach nutzen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Nein, jeder Kaufbeleg ist nur 1 Mal gültig.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Kann ich mehrere Kaufbelege pro Teilnahme hochladen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Pro Kaufbeleg ist nur eine Teilnahme möglich.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Muss ich den Kaufbeleg aufbewahren?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Bitte hebe den Kaufbeleg auf jeden Fall auf.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>In welchen Ländern kann man teilnehmen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Eine Teilnahme ist nur in Deutschland möglich.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>Kann ich meine Daten löschen?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Nach Ende der Aktion werden alle Teilnehmerdaten gelöscht. Soweit diese aus gesetzlichen Gründen nicht gelöscht werden dürfen, werden deine Daten gesperrt und nach Ablauf der handels- und steuerrechtlichen Aufbewahrungsfrist gelöscht. Aber falls du deine Daten bereits während des Aktionszeitraums löschen möchtest, ist das natürlich möglich. Dazu wendest du dich bitte direkt an unseren <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank">Verbraucherservice*</a>. Eine Teilnahme am Gewinnspiel ist dann leider nicht mehr möglich.
                        </p>
                    </div>
                </div>
                <div className="content-faq__item">
                    <h6>An wen kann ich mich wenden, wenn ich weitere Fragen habe?</h6>
                    <img src={iconPlus} className="content-faq__img notshown" onClick={(e)=>{this.showItemContent(e, iconMinus, iconPlus)}} />
                    <div className="item-content">
                        <p>
                            Vielleicht hast du noch weitere Fragen, die hier nicht beantwortet worden sind. Dann wende dich am besten direkt an unseren <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" target="_blank">Verbraucherservice*</a>.
                        <br/>
                            *Falls der hier hinterlegte Link zum Verbraucherservice nicht funktioniert, kannst du über den Kontaktlink im Bereich „Service“ den Verbraucherservice erreichen.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    showItemContent(e, iconMinus, iconPlus) {
        let classes = Array.from(e.target.classList);
        let itemContent = e.target.parentNode.children[2];

        if(classes.includes("notshown")){
            e.target.setAttribute( 'src', iconMinus);
            e.target.classList.remove("notshown");
            e.target.classList.add("shown");
            itemContent.classList.add("show");
        }else{
            e.target.setAttribute( 'src', iconPlus);
            e.target.classList.remove("shown");
            e.target.classList.add("notshown");
            itemContent.classList.remove("show");
        }
    }

    footerSection() {
        return(
            <div className="prizeRewe campaign-footer">
                <div className="content-separator">
                    <h2>
                        Mit deiner Lieblingsschokolade von Milka gewinnen!
                    </h2>
                    <h3 className="special">
                        Welches ist dein Lieblingsprodukt von Milka? Bist du Fan der zarten Milka Alpenmilch Schokolade? Stehst du auf die Milka Mmmax mit knackigen Erdnüssen und cremigem Karamell? Kannst du den luftig gebackenen Milka Waffelinis nicht widerstehen? Sind unsere Milka Cookie Sensations mit OREO Creme-Füllung vielleicht dein Favorit? Oder naschst du doch am liebsten aus unserem Milka Zarte Momente Mix – damit du dich nie für eine Sorte entscheiden musst?
                    </h3>
                
                    <img src="\resources\imagesBundesliga\buzzer\products.png" alt="Products" />
                
                    <div className="cta-container">
                        <a href="https://www.milka.de/alle-produkte" target="_blank" className="btn--anchor no-decoration">
                            <button className="btn__container btn--lilac">
                                MILKA SCHOKOLADE ENTDECKEN
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
    
export default withTranslation()(SupercupFAQGlobus);