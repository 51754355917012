import React, { useState } from 'react'
import Footersocial from "../../../../components/Layout/FooterContent/FooterSocial";
import CardThankYou from '../component/Card_ThankYou/CardThankYou';
import '../style/Confirmation.scss'
import { useEffect } from 'react';
import { mainLoaderToggle } from '../../../../modules/helpers';
import _SCROLL_PAGE from '../../../../modules/ScrollPage';
import TitleContent from '../component/TitleContent/TitleContent';
export default function End(props) {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            const mobileWidthThreshold = 768;
            setIsMobile(window.innerWidth < mobileWidthThreshold);
        };
        checkIsMobile();
        mainLoaderToggle('hide');
        window.addEventListener("resize", checkIsMobile);
        _SCROLL_PAGE.ScrollToTop();

        return () => {
            window.removeEventListener('resize', checkIsMobile);
            mainLoaderToggle('show');
        }
    }, [])

    const img1 = isMobile ? '/resources/imagesBundesliga/sommer/thank-you/thanks1-sm.png' : '/resources/imagesBundesliga/sommer/thank-you/thanks1.png';
    const img2 = isMobile ? '/resources/imagesBundesliga/sommer/thank-you/thanks2-sm.png' : '/resources/imagesBundesliga/sommer/thank-you/thanks2.png';
    const img3 = isMobile ? '/resources/imagesBundesliga/sommer/thank-you/thanks3-sm.png' : '/resources/imagesBundesliga/sommer/thank-you/thanks3.png';

    const tagging1 = {
        event: "subscribe_newsletter",
        category: "End Page",
        action: "Go to Page",
        label: "ENG_EP_OTHER"
    }

    const tagging2 = {
        event: "go_to_products",
        category: "End Page",
        action: "Go to Page",
        label: "ENG_EP_OTHER"
    }

    const tagging3 = {
        event: "discover_mmax_milka",
        category: "End Page",
        action: "Go to Page",
        label: "ENG_EP_OTHER"
    }


    return (
        <div>
            <TitleContent heading='Die Aktion ist beendet.' subheading='Freu dich auf viele weitere FC Milka Aktionen, die in Kürze kommen werden.  Abonniere den Newsletter und verpasse keine Aktion mehr.' />
            <div className="confirmationReihe">
                <div className="thank-you">
                    <CardThankYou img={img1} title='Milka Newsletter' text='Du willst keine spannenden Neuigkeiten mehr verpassen? Dann abonniere jetzt den kostenlosen Milka Newsletter! Regelmäßig informieren wir dich über neue Produkte, Gewinn-Aktionen und natürlich über alles, was beim FC Milka los ist. Worauf wartest du noch?' btn='NEWSLETTER ABONNIEREN' tagging={tagging1} link='https://www.milka.de/newsletter' />
                    <CardThankYou img={img2} title='Entdecke die Milka Favourites' text='Milka Favourites – das ist der leckere Mix, der alle zusammenbringt. Milka Favourites passen zum geselligen Zusammensein mit deinen Freunden und sind perfekt zum Teilen. Der einfach unwiderstehliche Snack in sechs beliebten Geschmacksrichtungen. Welche ist dein Favorit?' btn='MILKA FAVOURITES' tagging={tagging2} link='https://favourites.milka.de/' />
                    <CardThankYou img={img3} title='Milka Mmmax – mmmaximaler Genuss' text='Milka Mmmax Großtafeln bieten dir mehr von allem: mmmaximal viel zarte Alpenmilch Schokolade, mmmaximal großzügige Zutaten für ein mmmaximales Geschmackserlebnis. Entdecke den maximalen Milka Genuss.' btn='MILKA MMMAX' tagging={tagging3} link='https://mmmax.milka.de/' />
                </div>
            </div>
            <Footersocial />
        </div>
    )
}
