import React from "react";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";

import StepContent from "../../../components/Content/StepContent/StepContent";
import TitleLogo from "../../../components/Content/TitleLogo/TitleLogo";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import GLOBAL_CONFIG from "../../../config/config";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import TicketsPromoPopUp from "../TicketsPromo/TicketsPromoPopUp";
import TrikotPromoPopUp from "./TrikotPromoPopUp";
import { isTicketsPromotion } from "../../../modules/helpers";
import _LOCAL from "../../../Models/LocalStorageKeys";
import { isProd } from "../../../modules/helpers";

class TrikotMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      modal: false,
      baseNamePath: null,
    };
    this.resize = this.resize.bind(this);
    this.isTicketsPromotion = isTicketsPromotion();
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  resize() {
    // set isMobile to true if the device is a mobile and false otherwise
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    window.addEventListener("resize", this.resize);
    this.resize();
    //_SCROLL_PAGE.ScrollToTop();
    //this.productCodeLink();
    let baseNamePath = "";
    if (this.isTicketsPromotion) {
      baseNamePath = "/tickets";
    } else {
      baseNamePath = "";
    }

    this.setState({
      baseNamePath: baseNamePath,
    });
  }

  componentWillMount() {
    //_SCROLL_PAGE.ScrollToTop();
    /*         localStorage.removeItem(_LOCAL.PlayerValue);
        localStorage.removeItem(_LOCAL.ProductCode); */
    localStorage.clear();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const kipGlobus = urlParams.get("kip");
    const poupOpen = urlParams.get("status");

    if (kipGlobus) {
      localStorage.setItem(_LOCAL.CurrentKip, "Globus");
    }

    if (poupOpen) {
      this.setState({
        modal: true,
      });
    }
    console.log("poupOpen:", poupOpen)
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    return (
      <div id="main-buzzer" className="weltmeister-main tickets-main trikot-main">
        {this.headingSection(this.props)}
        {this.footerSection()}
        <Footersocial />
      </div>
    );
  }

/*   productCodeLink() {
    const product_code_link = document.getElementById("product-code-link");
    product_code_link.addEventListener("click", () => {
      this.setState({ modal: true });
    });
  } */

  modalTracking(event) {
    this.setState({ modal: true });
    appGoogleTracking.processEventCTANavLink(event);
  }

  headingSection(props) {
    return (
      <div>
        <div className="content-home">
          <div className="spaceTile-intropage">
            <div className="campaign-heading">
              {/* <h1>Mach mit beim großen <br />FC Milka Ticket Gewinnspiel!</h1> */}
              <p>
              Auf die Trikots, fertig, los! Öffne den FC Milka Spind und lass dich überraschen, ob sich dahinter <span class="good-headline-gold">
                ein original Heimtrikot der Bundesliga / 
                2. Bundesliga
              </span> oder ein anderer attraktiver Preis für dich verbirgt.
              </p>
            </div>
            <div id="participate"></div>
            <h2>So einfach geht’s:</h2>

            <div className="s-content-steps step-weltmeister step-tickets-promo">
              <div className="s-content-steps__item">
                  <picture>
                      <source srcSet="/resources/imagesBundesliga/trikot-promo/steps/1.png" media="(min-width: 1024px)" />
                      <source srcSet="/resources/imagesBundesliga/trikot-promo/steps/1.png" media="(min-width: 481px)" />
                      <source srcSet="/resources/imagesBundesliga/trikot-promo/steps/1.png" media="(min-width: 200px)" />
                      <img width="119px" src="/resources/imagesBundesliga/trikot-promo/steps/1.png" alt="Milka product" />
                  </picture>
                  <p>
                    Kaufe zwischen dem <span class="text--wheat">03.07.2023</span> und dem <span class="text--wheat">01.10.2023</span> ein Aktionsprodukt von Milka und/oder TUC.
                  </p>
              </div>
              <div className="s-content-steps__item">
                  <picture>
                      <source srcSet="/resources/imagesBundesliga/trikot-promo/steps/2.png" media="(min-width: 1024px)" />
                      <source srcSet="/resources/imagesBundesliga/trikot-promo/steps/2.png" media="(min-width: 481px)" />
                      <source srcSet="/resources/imagesBundesliga/trikot-promo/steps/2.png" media="(min-width: 200px)" />
                      <img width="119px" className="step_image step_image--website" src="/resources/imagesBundesliga/trikot-promo/steps/1.png" alt="Milka product" />
                  </picture>
                  <p>
                    Gib <div id="product-code-link"  
                      data-event="trikot_begin_participation_link"
                      data-category="Start Page"
                      data-action="Click on Link"
                      data-label="GAME_SP_OTHER" 
                      onClick={(e) => {this.modalTracking(e)}}>hier
                    </div> bis zum <span class="text--wheat">01.10.2023</span> den <span class="text--wheat"> Produktcode </span>von der Produktverpackung ein*.           
                  </p>
              </div>
              <div className="s-content-steps__item">
                  <picture>
                      <source srcSet="/resources/imagesBundesliga/trikot-promo/steps/3.png" media="(min-width: 1024px)" />
                      <source srcSet="/resources/imagesBundesliga/trikot-promo/steps/3.png" media="(min-width: 481px)" />
                      <source srcSet="/resources/imagesBundesliga/trikot-promo/steps/3.png" media="(min-width: 200px)" />
                      <img width="119px" className="step_image step_image--ball" src="/resources/imagesBundesliga/trikot-promo/steps/3.png" alt="Milka product" />
                  </picture>
                  <p> 
                  Mitmachen und mit etwas Glück gewinnen.
                  </p>
              </div>
            </div>

            <p className="note-promo-code">
              *Hinweis: Befindet sich kein Produktcode auf dem Artikel, müssen
              die Zahlen des Barcodes eingegeben werden. Mehrfachteilnahmen mit
              neuem Produktcode innerhalb der Aktionsprodukte erlaubt. Bitte Original-Kaufbeleg als Nachweis aufbewahren.
            </p>

            <div className="cta-container">
              <button
                className="btn__container btn--secondary-lilac"
                data-event="trikot_begin_participation_middle"
                data-category="Start Page"
                data-action="Click on Join Now"
                data-label="GAME_SP_OTHER"
                onClick={(e) => {
                  this.modalTracking(e)
                }}
              >
                JETZT MITMACHEN
              </button>
            </div>
            <TrikotPromoPopUp
              modal={this.state.modal}
              toggleModal={() => {
                this.toggleModal();
              }}
            />

            <p id="edeka-terms">
              Hier findest du die{" "}
              <a
                href={!isProd() ? `/trikot${GLOBAL_CONFIG.Route.terms}` : `${GLOBAL_CONFIG.Route.terms}`}
                target="_blank"
                style={{ color: "white" }}
                onClick={appGoogleTracking.processEventCTA}
                data-event="trikot_click_conditions"
                data-category="Start Page"
                data-action="Click on Conditions of Participation"
                data-label="ENG_SP_OTHER"
              >
                {" "}
                Teilnahmebedingungen
              </a>{" "}
              und{" "}
              <a
                href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/"
                target="_blank"
                style={{ color: "white" }}
                onClick={appGoogleTracking.processEventCTA}
                data-event="trikot_click_privacy_policy"
                data-category="Start Page"
                data-action="Click on Data Protection Regulations"
                data-label="ENG_SP_OTHER"
              >
                {" "}
                Datenschutzbestimmungen
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }

  footerSection() {
    return (
      <div className="trikot-promo-container">
        <h3>diese Gewinne warten auf dich</h3>

        <div className="trikot-promo-container-wrapper">
          <div className="trikot-promo-card">
            <div className="trikot-promo__content primary">
              <h4>
                <span class="good-headline-gold">1.500x</span> original heimtrikots der Bundesliga / 2. Bundesliga, Saison 2023/24
              </h4>
              <p>
              So bist du für die Bundesliga Saison 2023/24 perfekt gekleidet! Schon bald kann ein original Heimtrikot der Bundesliga / 2. Bundesliga dir gehören. Zeige allen, welcher Club dein Herz höherschlagen lässt und dir gut steht. (Werte der Trikots variieren je nach Club, Auswahl nach Verfügbarkeit über FC Milka Fan-Center.)
              </p>
            </div>
            <div className="trikot-promo__content secondary">
              <img src="/resources/imagesBundesliga/trikot-promo/card/T-Shirt.png" alt="Gold im Wert"/>
            </div>
          </div>
        </div>

        <div className="trikot-promo-container-wrapper-secondary">
          <div className="trikot-promo-card primary">

              <img
                src="/resources/imagesBundesliga/trikot-promo/card/secondary-one.png"
                alt="Gold im Wert"
              />
              <h4>
                <span class="good-headline-gold">150x</span> Milka Produktpakete
              </h4>
              <p>
              Du kannst vor Spannung gerade nicht hinsehen? Die passende Nervennahrung für fesselnde Fußball-Partien bekommst du mit einem Milka Produktpaket. Zum Selber-Naschen oder zum Teilen mit deinen Fußballfreunden.
              </p>

          </div>

          <div className="trikot-promo-card secondary">             
              <img
                src="/resources/imagesBundesliga/trikot-promo/card/secondary-two.png"
                alt="Gold im Wert"
              />
              <h4>
                <span class="good-headline-gold">15.000x</span> die chance auf fussball-Ausstattung
              </h4>
              <p>
              Bekenne Farbe zu deinem Lieblingsverein – der Fanshop von 11teamsports bietet dir die perfekte Möglichkeit, dich im Dress deines Lieblingsclubs zu kleiden. Profitiere von einem von 15.000 Rabattgutscheinen in Höhe von 20% auf alle Artikel der Bundesliga und 2.<br/>Fussball-Bundesliga.
              </p>
          </div>
        </div>

        <div className="cta-container">
              <button
                className="btn__container btn--secondary-lilac"
                data-event="trikot_begin_participation_bottom"
                data-category="Start Page"
                data-action="Click on Join Now"
                data-label="GAME_SP_OTHER"
                onClick={(e) => {
                  this.modalTracking(e)
                }}
              >
                JETZT MITMACHEN
              </button>
            </div>

      </div>
    );
  }
}

export default withTranslation()(TrikotMain);
