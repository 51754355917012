import React from "react";
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import CardThankYou from "./component/Card_ThankYou/CardThankYou";
import "./thankyou.scss";
import GLOBAL_CONFIG from "../../../config/config";

// import PromoPopup from "./component/Popup/PromoPopup";
class ThankYou extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        }
        this.resize = this.resize.bind(this);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        window.addEventListener("resize", this.resize);
        this.resize();
        _SCROLL_PAGE.ScrollToTop();
        console.log(window.location.pathname)

        const params = new URLSearchParams(window.location.search);
        if (params.get("status")?.includes("on")) {
            this.setState({ modal: true })
        }
    }

    componentWillMount() {
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }
    handleChild(callback) {
        // Here, you have the function from the child.
        callback();
    };

    render() {

        const img1 = this.state.isMobile ? this.props.t('Page.Home.Thank-You.1.img-sm') : this.props.t('Page.Home.Thank-You.1.img')
        const img2 = this.state.isMobile ? this.props.t('Page.Home.Thank-You.2.img-sm') : this.props.t('Page.Home.Thank-You.2.img')
        const img3 = this.state.isMobile ? this.props.t('Page.Home.Thank-You.3.img-sm') : this.props.t('Page.Home.Thank-You.3.img')

        const tagging1 = window.location.pathname == GLOBAL_CONFIG.Route.sommer.thankyou.toLowerCase() ? {
                event:"subscribe_newsletter",
                category:"Thank You Page",
                action:"Go to Page",
                label:"ENG_TY_OTHER"
            } : {
                event: "subscribe_newsletter",
                category: "End Page",
                action: "Go to Page",
                label: "ENG_EP_OTHER"
            }

        const tagging2 = window.location.pathname == GLOBAL_CONFIG.Route.sommer.thankyou.toLowerCase() ? {
                event: "go_to_products",
                category: "Thank You Page",
                action: "Go to Page",
                label: "ENG_TY_OTHER"
            } : {
                event: "go_to_products",
                category: "End Page",
                action: "Go to Page",
                label: "ENG_EP_OTHER"
            }

        const tagging3 = window.location.pathname == GLOBAL_CONFIG.Route.sommer.thankyou.toLowerCase() ? {
                event: "discover_mmax_milka",
                category: "Thank You Page",
                action: "Go to Page",
                label: "ENG_TY_OTHER"
            } : {
                event: "discover_mmax_milka",
                category: "End Page",
                action: "Go to Page",
                label: "ENG_EP_OTHER"
            }

        return (
            <div>
                <div className="thank-you-content">
                    <div className="thank-you">
                        <CardThankYou img={img1} title={this.props.t('Page.Home.Thank-You.1.title')} text={this.props.t('Page.Home.Thank-You.1.text')} btn={this.props.t('Page.Home.Thank-You.1.btn')} tagging={tagging1} link='https://www.milka.de/newsletter'/>
                        <CardThankYou img={img2} title={this.props.t('Page.Home.Thank-You.2.title')} text={this.props.t('Page.Home.Thank-You.2.text')} btn={this.props.t('Page.Home.Thank-You.2.btn')} tagging={tagging2} link='https://favourites.milka.de/'/>
                        <CardThankYou img={img3} title={this.props.t('Page.Home.Thank-You.3.title')} text={this.props.t('Page.Home.Thank-You.3.text')} btn={this.props.t('Page.Home.Thank-You.3.btn')} tagging={tagging3} link='https://mmmax.milka.de/'/>
                    </div>
                </div>
                <Footersocial />
            </div>
        );
    }

}
const mapStateToProps = state => ({
    gameState: state.game
});
/* export default withTranslation()(SommerMain); */
export default withTranslation()(withRouter(connect(mapStateToProps, null)(ThankYou)));