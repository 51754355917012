import React, { Component } from "react";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import parse from "html-react-parser";

class TicketsPromoProduct extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    return (
      <>
        <div className="content-holding__heading closing product-wrapper weltmeister-product ticket-product">
          <div className="content-end__text">
            <h1>Meisterlicher Schokoladengenuss</h1>
            <p>
              Zarte Vielfalt nach deinem Geschmack: Ob Milka Tafelschokolade,
              Riegel, Pralinen oder vieles mehr – bei Milka findet jeder das
              Richtige. Wunderbar: der Schokoladenriegel mit leckerer Füllung –
              einfach Wunderbar.
            </p>
          </div>

          <div className="chocolate">
            <img src="/resources/imagesBundesliga/tickets-promo/product/Wunderbar_Products_&_Milka_LET.png" />
          </div>

          <div className="s-content-end">
            <div className="end-element">
              <div className="end-element__item">
                <div className="end-element__copy">
                  <h3>Zarter Genuss zu spannenden Momenten</h3>
                  <p>
                    Entdecke jetzt das vielfältige Milka Schokoladen-Sortiment
                    und lass dich inspirieren. Welcher wird dein neuer
                    Lieblingssnack?
                  </p>
                  <button className="btn__container btn--secondary-lilac">
                    <a href="https://www.milka.de/alle-produkte" target="_blank">
                      mehr von MILKA
                    </a>
                  </button>
                </div>
                <img src="/resources/imagesBundesliga/tickets-promo/product/teaser.png" />
              </div>
            </div>
          </div>
          <Footersocial />
        </div>
      </>
    );
  }
}

export default TicketsPromoProduct;
