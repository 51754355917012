import React, { useState } from 'react'
import Button from '../Button/Button'
import PromoPopup from '../Popup/PromoPopup';
import './FirstContent.scss'
import appGoogleTracking from '../../../../../modules/googletracking';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import GLOBAL_CONFIG from '../../../../../config/config';
import Circle from '../imageTranslate/Circle';
export default function FirstContent(props) {
    const [openModal, setopenModal] = useState(false)

    function toggleModal() {
        setopenModal(!openModal)
    }
    function handleModal() {
        appGoogleTracking.dataLayerPush({
            'dataEvent': "join_now",
            'dataCategory': "Start Page",
            'dataAction': "Click on Join Now",
            'dataLabel': "GAME_SP_PART"
        });
        setopenModal(true);
    }


    const scrollToSection = (sectionName) => {
        appGoogleTracking.dataLayerPush({
            'dataEvent': "go_to_winnings",
            'dataCategory': "Start Page",
            'dataAction': "Click on All Winnings",
            'dataLabel': "GAME_SP_PART"
        });
        const targetElement = document.getElementById(sectionName);

        if (targetElement)
        {
            // Ensure smooth scrolling and potential offset adjustments
            window.scrollTo({
                top: targetElement.offsetTop - ( // Optional offset for header/navbar height
                    document.querySelector('header')?.offsetHeight || 0
                ),
                behavior: 'smooth',
            });
        } else
        {
            console.warn(`Element with ID "${sectionName}" not found for scrolling.`);
        }
    };

    return (
        <div className='container_firstContent'>

            <PromoPopup modal={openModal} toggleModal={() => toggleModal()}></PromoPopup>
            <div className="first">
                <h1 className='titleP'>Mehr als 1.000 Preise zu gewinnen – wöchentliche Verlosung</h1>
                <p className='description'>Spannende Monate voller Extase, Begeisterung und Euphorie stehen vor der Tür, bei
                    dem ein Highlight das nächste jagt: Auf die letzten Spieltage der Bundesliga folgt der
                    Fußballsommer in Deutschland. Der FC Milka gibt dir die Möglichkeit, den
                    Fußballsommer deines Lebens mit einem unvergesslichen Fußballerlebnis zu
                    krönen. Sichere dir deine Gewinnchance. Auf dich warten unter anderem:</p>
                <br></br><br></br>
                {props.game ?
                    <><p className='description bold nocenter'><img className="mini" src="resources/imagesBundesliga/sommer/circle/mini.png" alt="Description of your image" /> Ein Meet & Greet mit deinem Fußballstar</p>
                        <p className='description  bold nocenter'><img className="mini" src="resources/imagesBundesliga/sommer/circle/mini.png" alt="Description of your image" /> VIP Fan-Event in Berlin</p></>
                    :
                    <><p className='description bold nocenter'><img className="mini" src="resources/imagesBundesliga/sommer/circle/mini.png" alt="Description of your image" /> Bundesliga Dauerkarten der Saison 2024/25</p>
                        <p className='description  bold nocenter'><img className="mini" src="resources/imagesBundesliga/sommer/circle/mini.png" alt="Description of your image" /> Ein Meet & Greet mit deinem Fußballstar</p></>
                }


                <br></br>
                <Button onClick={() => handleModal()}>JETZT MITMACHEN</Button>
                <br></br><br></br>
                <Button onClick={() => { scrollToSection('priceSection') }}>
                    ALLE GEWINNE
                </Button>

            </div>
            <div className="second">
                <Circle phase={props.game} ></Circle>
            </div>

        </div>
    )
}
