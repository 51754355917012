import React from "react";
import { mainLoaderToggle } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import { withTranslation } from "react-i18next";
import _SCROLL_PAGE from "../../../modules/ScrollPage";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import "./end.scss";
import ThankYou from "./ThankYou";

class SommerEnd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        }
        this.resize = this.resize.bind(this);
    }

    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        window.addEventListener("resize", this.resize);
        this.resize();
        _SCROLL_PAGE.ScrollToTop();

        const params = new URLSearchParams(window.location.search);
        if (params.get("status")?.includes("on")) {
            this.setState({ modal: true })
        }
    }

    componentWillMount() {
        _SCROLL_PAGE.ScrollToTop();
    }

    componentWillUnmount() {
        mainLoaderToggle('show');
    }
    handleChild(callback) {
        // Here, you have the function from the child.
        callback();
    };

    render() {
        return (
            <div className="sommer_end">
                <div className="title-end">
                    <h1 className="titre">Diese Aktion ist beendet.</h1>
                    <p className="text-end">Freu dich auf viele weitere FC Milka Aktionen, die in Kürze kommen werden.
                        Abonniere den Newsletter und verpasse keine Aktion mehr.</p>
                </div>
                <div className="thanks">
                    <ThankYou/>
                </div>
            </div>
        );
    }

}
const mapStateToProps = state => ({
    gameState: state.game
});
export default withTranslation()(withRouter(connect(mapStateToProps, null)(SommerEnd)));