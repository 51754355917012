const CodeRules = {
    "AAA-D{2}":{
        type: "REGEX",
        "OOT": /^OOT\d{2}\b$/i,
    },
    "AAA-D{7}": {
        type: "REGEX",
        "OOT": /^OOT\d{7}\b$/i,
        "OSV": /^OSV\d{7}\b$/i, 
        "OOT": /^OOT\d{7}\b$/i, 
        "OUV": /^OUV\d{7}\b$/i,
        "OLO": /^OLO\d{7}\b$/i,
        "OTM": /^OTM\d{7}\b$/i,
        "OSZ": /^OSZ\d{7}\b$/i,
        "OCA": /^OCA\d{7}\b$/i,
        "LXB": /^LXB\d{7}\b$/i
    },
    "AAA-D{8}": {
        type: "REGEX",
        "OHT": /^OHT\d{8}\b$/i
    },
    "AAA-D{9}": {
        type: "REGEX",
        "ZBW": /^ZBW\d{9}\b$/i
    },
    "AAA-D{7-8}": {
        type: "REGEX",
        "OOA": /^OOA\d{7}\b|^OOA\d{8}\b$/i,
        "OOC": /^OOC\d{7}\b|^OOC\d{8}\b$/i,
        "OOT": /^OOT\d{7}\b|^OOT\d{8}\b$/i,
        "OON": /^OON\d{7}\b|^OON\d{8}\b$/i,
        "OTM": /^OTM\d{7}\b|^OTM\d{8}\b$/i,
        "OSV": /^OSV\d{7}\b|^OSV\d{8}\b$/i,
        "OSZ": /^OSZ\d{7}\b|^OSZ\d{8}\b$/i,
        "OSK": /^OSK\d{7}\b|^OSK\d{8}\b$/i,
        "CWS": /^CWS\d{7}\b|^CWS\d{8}\b$/i,
        "ZBO": /^ZBO\d{7}\b|^ZBO\d{8}\b$/i,
        "OPJ": /^OPJ\d{7}\b|^OPJ\d{8}\b$/i,
        "OPM": /^OPM\d{7}\b|^OPM\d{8}\b$/i,
        "ZAB": /^ZAB\d{7}\b|^ZAB\d{8}\b$/i,
        "OPA": /^OPA\d{7}\b|^OPA\d{8}\b$/i,
        "OGR": /^OGR\d{7}\b|^OGR\d{8}\b$/i,
        "OCE": /^OCE\d{7}\b|^OCE\d{8}\b$/i,
        "OPA": /^OPA\d{7}\b|^OPA\d{8}\b$/i,
        "OFR": /^OFR\d{7}\b|^OFR\d{8}\b$/i,
        "OJU": /^OJU\d{7}\b|^OJU\d{8}\b$/i,
        "ZPP": /^ZPP\d{7}\b|^ZPP\d{8}\b$/i,
        "OMO": /^OMO\d{7}\b|^OMO\d{8}\b$/i,
        "OCO": /^OCO\d{7}\b|^OCO\d{8}\b$/i
    },
    "DATE": {
        type: "REGEX",
        "0": /^\d{2}.\d{4}LMI\d{4}\d{2}:\d{2}$/i,                    // MM.YYYY L MI 0000 00:00
        "1": /^\d{2}.\d{4}LMI\d{4}\d{2}:\d{2}X$/i,                   // MM.YYYY L MI 0000 00:00 X
        "2": /^\d{2}.\d{4}LMI\d{4}\d{2}:\d{2}X([0-2][0-4])$/i,       // MM.YYYY L MI 0000 00:00 X R (R - row number 1-24)
        "3": /^\d{2}\/\d{4}LPL\d{4}\d{2}:\d{2}$/i,                   // MM/YYYY LPL 0000 00:00
        "4": /^DE\d{2}\/\d{2}\/\d{2}LOSYDDD-BD\d{2}:\d{2}$/i,        // DE DD/MM/YY L OSYDDD-BD 00:00 
        "5": /^DE\d{2}\/\d{2}\/\d{2}LOSYDDD-2\d{2}:\d{2}$/i,         // DE DD/MM/YY L OSYDDD-2 00:00 
        "6": /^DE\d{2}\/\d{2}\/\d{2}LOSYDDD\d{2}:\d{2}$/i,           // DE DD/MM/YY L OSYDDD 00:00
        "7": /^LST0000\d{2}.\d{4}\d{2}:\d{2}$/i,              // L ST0000 MM.YYYY 00:00
    },
    "CONTAINS": {
        type: "CONTAINS",
        "1": "SN016",
        "2": "SN016.08",
        "3": "BY71806", // BY718 06
    },
    "default": {
        type: "EXACT",
        "1": "4017040223807",
        "2": "4017040023209",
        "3": "4017040222923",
        "4": "4017040223067"
    }
}

export default CodeRules;