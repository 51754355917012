import React from 'react';
import { isDoppeltPromotion } from '../../../modules/helpers';
import GLOBAL_CONFIG from '../../../config/config';
import appGoogleTracking from '../../../modules/googletracking';
import { NavLink } from 'react-router-dom';

function DoppeltNavigation() {
    const hostName = window.location.hostname,
        local = hostName.toLowerCase().indexOf("localhost") > -1 ? true : false;
        let baseDomain = "";
        if (local) {
            baseDomain = "/doppeltgewinnen"
        }
        const baseDomaine = baseDomain;
    return isDoppeltPromotion() && (
        <ul>
            <li>
                {/* <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.home}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="START" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">START</a> */}
                
                <NavLink to={`${GLOBAL_CONFIG.Route.home}`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="START" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>
            </li>
            <li>
                {/* <a href={window.PROMOTION === "holding" ? `${baseDomaine}${GLOBAL_CONFIG.Route.participation}` : `${baseDomaine}${GLOBAL_CONFIG.Route.home}`+`?status=on`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="MITMACHEN" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">MITMACHEN</a> */}
                <NavLink to={window.PROMOTION === "holding" ? `${GLOBAL_CONFIG.Route.participation}` : `${GLOBAL_CONFIG.Route.home}`+`?status=on`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="MITMACHEN" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</NavLink>
            </li>
            <li>
                {/* <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.prize}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="GEWINNE" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">GEWINNE</a> */}
                <NavLink to={`${GLOBAL_CONFIG.Route.prize}`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="GEWINNE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</NavLink>
            </li>
            <li>
                {/* <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.charity}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="DER GUTE ZWECK" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">DER GUTE ZWECK</a> */}
                <NavLink to={`${GLOBAL_CONFIG.Route.charity}`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="DER GUTE ZWECK" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">DER GUTE ZWECK</NavLink>
            </li>
            <li>
                <a href={'https://fcmilka.de/account'} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">KONTO</a>
            </li>
            <li>
                {/* <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.faq}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">FAQ</a> */}
                <NavLink to={`${GLOBAL_CONFIG.Route.faq}`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink>
            </li>
            <li>
                <a href={`https://fcmilka.de/fairplay`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAIRPLAY" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">FAIRPLAY</a>
            </li>
        </ul>
    );
}

export default DoppeltNavigation